import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Form, ModalFooter } from 'react-bootstrap';
import { CardBody, Col, FormGroup, Label } from 'reactstrap';
import ControllerValidation from '../commons/ControllerValidation';
import { useForm } from 'react-hook-form';
import checklistService from '../../services/checklist.service';
import AutoComplete from '../AutoComplete';
import {
  DATE_FORMAT_EJS,
  DATE_FORMAT_EJS_UPDATED,
  overflowing,
} from '../../utils/Utils';
import ReactDatepicker from '../inputs/ReactDatpicker';
import ButtonIcon from '../commons/ButtonIcon';
import { AssignContacts } from './AssignContacts';
import organizationService from '../../services/organization.service';
import { ChecklistOwnerSelect } from './ChecklistOwnerSelect';

export const AddChecklistOrg = ({
  organizationId,
  isChecklistShow,
  setChecklistShow,
  getChecklistsData,
  setSuccessMessage,
  checklistData = [],
  organization,
  activeTab,
  setErrorMessage,
  switchTab,
}) => {
  const checklistForm = {
    checklistId: '',
    initialDueDate: new Date(),
  };
  const {
    handleSubmit,
    reset,
    setValue,
    getFieldState,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: checklistForm,
  });
  const [loading, setLoading] = useState(false);
  const [orgChecklist, setOrgChecklist] = useState(checklistForm);
  const [searchState, setSearchState] = useState({
    search: '',
  });

  const [selectedContact, setSelectedContact] = useState([]);
  const [checklists, setCheckLists] = useState([]);
  const [getOrganizations, setGetOrganizations] = useState([]);

  const [searchOrg, setSearchOrg] = useState({
    search: '',
  });
  const getOrganizationData = async () => {
    const { data } = await organizationService.getOrganizations(
      { search: searchOrg?.search },
      { limit: 20, page: 1 }
    );
    setGetOrganizations(data?.organizations);
  };

  useEffect(() => {
    if (activeTab) {
      getOrganizationData();
    }
  }, [activeTab, searchOrg?.search]);

  const getChecklistData = async () => {
    try {
      const { data } = await checklistService.getCheckLists({
        limit: 100,
        page: 1,
      });
      let updatedData = data?.data?.filter((item) => {
        return item?.global === false && item?.status === 'active';
      });
      if (checklistData.length > 0) {
        const checklistIds = checklistData.map(
          (item) => item.checklistId || item?.checklist?.checklistId
        );
        updatedData = updatedData.filter((updatedItem) => {
          return !checklistIds.includes(updatedItem.checklistId);
        });
      }
      setCheckLists(updatedData);
    } catch (error) {
      console.error('Error fetching and filtering checklist data:', error);
    }
  };
  useLayoutEffect(() => {
    if (isChecklistShow) {
      getChecklistData();
    }
  }, [isChecklistShow]);
  const stateOrgChange = (e) => {
    setSearchOrg({
      ...searchOrg,
      search: e.target.value,
    });
  };

  const stateChange = (e) => {
    setSearchState({
      ...searchState,
      search: e.target.value,
    });
  };
  const clearState = (name) => {
    if (name === 'checklistId') {
      setCheckLists([]);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const orgList = {
        checklistId: orgChecklist?.checklist?.checklistId,
        initialDueDate: orgChecklist?.initialDueDate,
        organizationId: orgChecklist?.organizationId || organizationId,
      };

      const data = await checklistService.addOrgChecklist(orgList);
      if (
        data?.data?.organizationChecklistId &&
        (selectedContact?.length > 0 || orgChecklist?.user_id)
      ) {
        await checklistService.ownerAttachWithChecklist(
          data?.data?.organizationChecklistId,
          orgChecklist?.user_id
        );
        createContact(data?.data?.organizationChecklistId);
      }
      if (data?.status === 200) {
        setSuccessMessage(
          `Checklist assign to ${
            organization?.name ? organization?.name : 'a Company'
          }`
        );
      }
      getChecklistsData();
      switchTab && switchTab();
    } catch (e) {
      setErrorMessage(e.response?.data?.errors[0]?.message);
    } finally {
      setChecklistShow(false);
      overflowing();
      reset({
        checklistId: '',
        initialDueDate: '',
      });
      setOrgChecklist({
        checklistId: '',
        initialDueDate: '',
      });
      setLoading(false);
    }
  };

  const handleClose = () => {
    setChecklistShow(false);
    reset({
      checklistId: '',
      initialDueDate: '',
    });
    setOrgChecklist({
      checklistId: '',
      initialDueDate: '',
    });
    overflowing();
  };

  const updatedDate = new Date(orgChecklist?.initialDueDate);

  const createContact = async (organizationChecklistId) => {
    try {
      const contactData = selectedContact?.map(async (contact) => {
        const data = await checklistService.addContactChecklist({
          organizationChecklistId,
          contactId: contact?.id,
        });
        return data;
      });
      await Promise.all(contactData);
    } catch (e) {
      if (e.response?.status === 409) {
        setErrorMessage('Please Select the Other Contact');
      }
    } finally {
      setSelectedContact([]);
    }
  };
  return (
    <>
      <CardBody>
        <h5 className="pb-0">Checklist Information</h5>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {activeTab === 'checklist' && (
            <FormGroup row className="align-items-center mb-3">
              <Label md={4} className="text-right font-size-sm col-form-label">
                Related To
              </Label>
              <Col md={8} className="pl-0">
                <ControllerValidation
                  name={'organizationId'}
                  errors={errors}
                  form={orgChecklist}
                  errorDisplay="mb-0"
                  control={control}
                  validationConfig={{
                    required: 'Company is Required',
                  }}
                  renderer={({ field }) => (
                    <AutoComplete
                      id="organizationId"
                      name="organizationId"
                      placeholder="Select Company"
                      showAvatar={false}
                      fieldState={getFieldState('organizationId')}
                      customKey="name"
                      customTitle={''}
                      onChange={(e) => stateOrgChange(e)}
                      validationConfig={{
                        required: true,
                        inline: false,
                      }}
                      clearState={(e) => clearState(e)}
                      search={searchOrg?.search}
                      data={getOrganizations}
                      onHandleSelect={(item) => {
                        setOrgChecklist({
                          ...orgChecklist,
                          organization: item,
                          organizationId: item?.id,
                        });
                        setValue('organizationId', item?.id);
                      }}
                      selected={orgChecklist?.organization?.name}
                    />
                  )}
                />
              </Col>
            </FormGroup>
          )}
          <FormGroup
            row
            className={`align-items-center ${
              activeTab === 'checklist' ? 'mt-0' : 'mt-3'
            }`}
          >
            <Label md={4} className="text-right font-size-sm col-form-label">
              Select Checklist
            </Label>
            <Col md={8} className="pl-0">
              <ControllerValidation
                name={'checklistId'}
                errors={errors}
                form={orgChecklist}
                errorDisplay="mb-0"
                control={control}
                validationConfig={{
                  required: 'ChecklistId is Required',
                }}
                renderer={({ field }) => (
                  <AutoComplete
                    id="checklistId"
                    name="checklistId"
                    placeholder="Select Checklist"
                    showAvatar={false}
                    fieldState={getFieldState('checklistId')}
                    customKey="title"
                    customTitle={''}
                    onChange={(e) => stateChange(e)}
                    validationConfig={{
                      required: true,
                      inline: false,
                    }}
                    clearState={(e) => clearState(e)}
                    search={searchState?.search}
                    data={checklists}
                    onHandleSelect={(item) => {
                      setOrgChecklist({
                        ...orgChecklist,
                        checklist: item,
                        checklistId: item?.checklistId,
                      });
                      setValue('checklistId', item?.checklistId);
                    }}
                    selected={orgChecklist?.checklist?.title}
                  />
                )}
              />
            </Col>
          </FormGroup>
          <AssignContacts
            organizationId={organizationId || orgChecklist?.organizationId}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
          />
          <FormGroup row className="align-items-center mt-3">
            <Label md={4} className="font-size-sm col-form-label text-right">
              Due Date
            </Label>
            <Col md={8} className="pl-0">
              <ControllerValidation
                name={'initialDueDate'}
                errors={errors}
                form={orgChecklist}
                errorDisplay="mb-0"
                control={control}
                validationConfig={{
                  required: `Due Date is required.`,
                }}
                renderer={({ field }) => (
                  <ReactDatepicker
                    id={'initialDueDate'}
                    name={'initialDueDate'}
                    format={DATE_FORMAT_EJS}
                    autoComplete="off"
                    todayButton="Today"
                    validationConfig={{
                      required: `Due Date is required.`,
                    }}
                    fieldState={getFieldState('initialDueDate')}
                    value={updatedDate}
                    className="form-control"
                    placeholder={DATE_FORMAT_EJS_UPDATED}
                    onChange={(date) => {
                      setOrgChecklist({
                        ...orgChecklist,
                        initialDueDate: date,
                      });
                      setValue('initialDueDate', date);
                    }}
                  />
                )}
              />
            </Col>
          </FormGroup>
          <ChecklistOwnerSelect
            orgChecklist={orgChecklist}
            setOrgChecklist={setOrgChecklist}
          />
        </Form>
      </CardBody>
      <ModalFooter>
        <ButtonIcon
          label="Cancel"
          type="button"
          color="white"
          classnames="btn-white mx-1 btn-sm"
          onclick={handleClose}
        />
        <ButtonIcon
          classnames="btn-sm"
          type="button"
          onClick={handleSubmit(onSubmit)}
          label={'Save'}
          color={`primary`}
          loading={loading}
        />
      </ModalFooter>
    </>
  );
};

import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import React, { useState } from 'react';
import { isModuleAllowed, isPermissionAllowed } from '../../utils/Utils';
import { useTenantContext } from '../../contexts/TenantContext';
import { Badge } from 'react-bootstrap';
import TooltipComponent from '../lesson/Tooltip';

const Counter = ({ moduleName, active, count }) => {
  return (
    <>
      {count > 0 ? (
        <Badge
          style={{ width: 22, height: 22 }}
          className={`nav-count bg-primary-soft rounded-circle d-flex align-items-center justify-content-center ml-1 fs-9 p-1
          `}
        >
          {count > 100 ? (
            <TooltipComponent title={`Total ${moduleName}: ${count}`}>
              <span>99+</span>
            </TooltipComponent>
          ) : (
            count
          )}
        </Badge>
      ) : null}
    </>
  );
};

const TabItem = ({
  item,
  activeTab,
  toggle,
  animateStyle,
  setAnimateStyle,
  itemClasses = 'px-3 py-2 relative d-flex align-items-center',
  tabActiveClass = 'active',
  showCount,
  tabCounts,
}) => {
  return (
    <NavItem
      onMouseOver={(e) => {
        setAnimateStyle({
          transform: `translateX(${e.target.offsetLeft}px)`,
          width: e.target.offsetWidth,
          height: e.target.offsetHeight,
        });
      }}
      onMouseLeave={() => setAnimateStyle({ ...animateStyle, width: 0 })}
      key={item.tabId}
      className="py-0 tab-title"
    >
      <NavLink
        className={`${itemClasses} ${item.clazz} ${classnames({
          [tabActiveClass]: activeTab === item.tabId,
        })}`}
        onClick={() => {
          toggle(item);
        }}
      >
        {item.title}
        {showCount && (
          <Counter
            active={activeTab === item.tabId}
            moduleName={item.title}
            count={tabCounts[item.tabId || item.id] || 0}
          />
        )}
      </NavLink>
    </NavItem>
  );
};

const AnimatedTabs = ({
  tabClasses,
  tabItemClasses,
  tabActiveClass,
  tabsData,
  activeTab,
  toggle,
  permissionCheck,
  requiredAdminAccess,
  borderClasses = 'border-bottom-0',
  showCount = false,
  tabCounts = {},
}) => {
  const [animateStyle, setAnimateStyle] = useState({});
  const { tenant } = useTenantContext();

  return (
    <Nav className={`${borderClasses} position-relative ${tabClasses}`} tabs>
      {animateStyle.width > 0 && (
        <div
          className="position-absolute rounded bg-primary-soft"
          style={{
            transition: 'all ease-in 150ms',
            ...animateStyle,
          }}
        ></div>
      )}
      {tabsData.map((item) => (
        <>
          {permissionCheck && item.permission ? (
            <>
              {isPermissionAllowed(
                item.permission.collection,
                item.permission.action
              ) &&
                isModuleAllowed(tenant?.modules, item?.module) && (
                  <TabItem
                    showCount={showCount}
                    activeTab={activeTab}
                    item={item}
                    toggle={toggle}
                    animateStyle={animateStyle}
                    setAnimateStyle={setAnimateStyle}
                    itemClasses={tabItemClasses}
                    tabCounts={tabCounts}
                    tabActiveClass={tabActiveClass}
                  />
                )}
            </>
          ) : (
            <>
              {requiredAdminAccess === true ? (
                <TabItem
                  showCount={showCount}
                  activeTab={activeTab}
                  item={item}
                  toggle={toggle}
                  tabCounts={tabCounts}
                  animateStyle={animateStyle}
                  setAnimateStyle={setAnimateStyle}
                  itemClasses={tabItemClasses}
                  tabActiveClass={tabActiveClass}
                />
              ) : (
                <>
                  {item?.byModule ? (
                    <>
                      {isModuleAllowed(tenant?.modules, item.byModule) ? (
                        <TabItem
                          showCount={showCount}
                          activeTab={activeTab}
                          item={item}
                          toggle={toggle}
                          tabCounts={tabCounts}
                          animateStyle={animateStyle}
                          setAnimateStyle={setAnimateStyle}
                          itemClasses={tabItemClasses}
                          tabActiveClass={tabActiveClass}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <TabItem
                      showCount={showCount}
                      activeTab={activeTab}
                      item={item}
                      toggle={toggle}
                      tabCounts={tabCounts}
                      animateStyle={animateStyle}
                      setAnimateStyle={setAnimateStyle}
                      itemClasses={tabItemClasses}
                      tabActiveClass={tabActiveClass}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      ))}
    </Nav>
  );
};

export default AnimatedTabs;

import React from 'react';

import Table from '../GenericTable';
import MoreActions from '../MoreActions';
import MaterialIcon from '../commons/MaterialIcon';
import TooltipComponent from '../lesson/Tooltip';

const RoleTable = ({
  dataSource = [],
  selectAll,
  setSelectAll,
  selectedData,
  setSelectedData,
  paginationInfo,
  setSelectedItem,
  onPageChange,
  dataInDB,
  setShowCreateRoleModal,
  sortingTable,
  setShowModal,
  sortingOrder,
  hasAdminAccess,
  handleEditRole,
  handleDeleteRole,
  handleViewUsers,
}) => {
  const handleEditAction = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  const handleViewAction = (item) => {
    handleViewUsers(item);
  };
  const handleDeleteAction = (item) => {
    handleDeleteRole(item);
  };
  const columns = [
    {
      key: 'roleName',
      orderBy: 'name',
      component: 'Profile Name',
    },
    {
      key: 'description',
      orderBy: 'description',
      component: 'Profile Description',
    },
    {
      key: 'users_count',
      orderBy: '',
      component: 'Total Users',
    },
    {
      key: 'action',
      component: '',
    },
  ];

  const data = dataSource.map((dataItem) => {
    const optionsList = [
      {
        id: 'edit',
        icon: 'task_alt',
        name: 'Clone',
        className: dataItem.id,
      },
      {
        id: 'open',
        icon: 'group',
        name: 'View Users',
        className: dataItem.id,
      },
    ];

    if (dataItem?.totalUsers < 1) {
      optionsList.push({
        id: 'remove',
        icon: 'delete',
        name: 'Delete',
        className: 'bg-hover-danger',
      });
    }

    const actionComponent = !hasAdminAccess ? (
      <div className="d-flex hover-actions align-items-center">
        <a
          onClick={() => handleEditRole(dataItem)}
          className="icon-hover-bg cursor-pointer action-items d-flex align-items-center justify-content-center mr-2"
        >
          <MaterialIcon icon="edit" />
        </a>
        <a className={`icon-hover-bg action-items cursor-pointer`}>
          <MoreActions
            icon="more_vert"
            items={optionsList}
            onHandleEdit={() => handleEditAction(dataItem)}
            onHandleOpen={() => handleViewAction(dataItem)}
            onHandleRemove={() =>
              dataItem?.totalUsers < 1 && handleDeleteAction(dataItem)
            }
            toggleClassName="w-auto p-0 h-auto"
          />
        </a>
      </div>
    ) : (
      '-'
    );
    const isLongDescription = dataItem?.description?.length > 100;
    return {
      ...dataItem,
      dataRow: [
        {
          key: 'name',
          component: (
            <h5 onClick={() => handleEditRole(dataItem)}>{dataItem.name}</h5>
          ),
          id: dataItem.id,
        },
        {
          key: 'description',
          component: (
            <>
              <span
                onClick={() => handleEditRole(dataItem)}
                className={`role-desc ${isLongDescription ? 'clamp-text' : ''}`}
              >
                {dataItem.description}
              </span>
              <div className="role-desc-more">
                {isLongDescription && (
                  <TooltipComponent
                    tooltipTextFont
                    titleLeftAlign
                    title={dataItem.description}
                  >
                    <span className="more">More</span>
                  </TooltipComponent>
                )}
              </div>
            </>
          ),
        },
        {
          key: 'users_count',
          component: <span>{dataItem?.totalUsers}</span>,
        },
        {
          key: 'action',
          component: actionComponent,
        },
      ],
    };
  });

  return (
    <div className="table-responsive-md datatable-custom table-roles">
      <Table
        checkbox={false}
        columns={columns}
        data={data}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        selectAll={selectAll}
        headClass="bg-gray-table-head border-top"
        setSelectAll={setSelectAll}
        paginationInfo={paginationInfo}
        onPageChange={onPageChange}
        clickableCell
        emptyDataText="No Profiles available yet."
        title="Profile"
        dataInDB={dataInDB}
        toggle={() => setShowCreateRoleModal(true)}
        sortingTable={sortingTable}
        sortingOrder={sortingOrder}
      />
    </div>
  );
};

export default RoleTable;

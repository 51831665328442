import React, { useContext, useState, useEffect } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';

import Heading from '../components/heading';
import { TabsContext } from '../contexts/tabsContext';
import AnimatedTabs from '../components/commons/AnimatedTabs';
import Organizations from './Organizations';
import PageTitle from '../components/commons/PageTitle';
import { PermissionsConstants } from '../utils/permissions.constants';
import { isModuleAllowed } from '../utils/Utils';
import { useTenantContext } from '../contexts/TenantContext';
import Contacts from './Contacts';

const CAccountsTabs = {
  Companies: 1,
  Contacts: 2,
};

const CAccounts = () => {
  const history = useHistory();
  const location = useLocation();
  const { tenant } = useTenantContext();

  const [activeTab, setActiveTab] = useState(CAccountsTabs.Companies);
  const { setActivatedTab } = useContext(TabsContext);

  const tabsData = [
    {
      title: 'Companies',
      tabId: CAccountsTabs.Companies,
      byModule: PermissionsConstants.CAccounts.Companies,
    },
    {
      title: 'Contacts',
      tabId: CAccountsTabs.Contacts,
      byModule: PermissionsConstants.CAccounts.Contacts,
    },
  ];

  useEffect(() => {
    if (tenant?.id) {
      const isCompaniesAllowed = isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.CAccounts.Companies
      );
      const isContactsAllowed = isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.CAccounts.Contacts
      );
      setActiveTab(
        isCompaniesAllowed
          ? CAccountsTabs.Companies
          : isContactsAllowed
          ? CAccountsTabs.Contacts
          : -1
      );
    }
  }, [tenant]);

  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
      history.replace({ search: '' });
      setActivatedTab({
        [location.pathname]: tab.tabId,
      });
    }
  };

  return (
    <>
      <PageTitle page={tabsData.find((t) => t.tabId === activeTab)?.title} />
      <div className="resources-title mb-1">
        <Heading title="" useBc showGreeting={false}>
          <AnimatedTabs
            tabsData={tabsData}
            activeTab={activeTab}
            toggle={toggle}
            permissionCheck={true}
          />
        </Heading>
      </div>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={CAccountsTabs.Companies}>
          <Organizations />
        </TabPane>
        <TabPane tabId={CAccountsTabs.Contacts}>
          <Contacts />
        </TabPane>
      </TabContent>
    </>
  );
};

export default CAccounts;

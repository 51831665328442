import React, { useEffect, useState } from 'react';
import './style.css';
import './responsive.css';
import MaterialIcon from '../commons/MaterialIcon';
import useEngagementReportTabs from '../reports/EngagementReportTabs';
import useChecklist from '../checklist/useChecklist';
import useHash from '../../hooks/useHash';
import StatusReview from '../commons/StatusReview';
import { getClientPortalToken } from '../../layouts/constants';
import Skeleton from 'react-loading-skeleton';
import useIsTenant from '../../hooks/useIsTenant';
import useWistiaEmbedPlayer from '../../hooks/useWistiaEmbedPlayer';
import { useTenantContext } from '../../contexts/TenantContext';

const ModuleHeader = ({ data }) => {
  return (
    <div className="d-flex align-items-center gap-1 mb-3">
      <MaterialIcon icon={data.icon} rounded />
      <h5 className="mb-0">{data.name}</h5>
    </div>
  );
};

const Visited = JSON.parse(localStorage.getItem('menu_visited') || '{}');
const clientPortalToken = getClientPortalToken();

const ModuleItem = ({ data, active, onClick }) => {
  const key = `${data.key}-${clientPortalToken?.contact_id}`;
  return (
    <div
      onClick={() => {
        if (!Visited[key]) {
          Visited[key] = {
            date: Date.now(),
          };
        }
        localStorage.setItem('menu_visited', JSON.stringify(Visited));
        onClick(data);
      }}
      className={`rounded d-flex align-items-center justify-content-between cursor-pointer portal-module-item fs-7 p-2_1 my-2 ${
        active
          ? 'primary-selected-item bordered font-weight-semi-bold'
          : 'border border-gray-300'
      }`}
    >
      {data.name}
      <StatusReview
        show={!Visited[key] || data.type !== 'Report' ? '' : 'hide'}
        text={
          data.type === 'Report'
            ? 'New'
            : data?.progress?.clientProgress === 100
            ? 'Completed'
            : 'Review'
        }
        icon={data?.progress?.clientProgress === 100 ? 'check_circle' : 'info'}
        styleClass={
          data?.progress?.clientProgress === 100
            ? 'bg-soft-success text-success'
            : 'bg-orange-5 text-orange'
        }
      />
    </div>
  );
};
const YourChecklists = ({ checklists, selectedMenu, handleSelectMenu }) => {
  const [checklistMenu, setChecklistMenu] = useState([]);
  useEffect(() => {
    if (checklists) {
      setChecklistMenu(checklists);
    }
  }, [checklists]);
  return (
    <div className="pt-5 flex-item pb-3 px-3">
      <ModuleHeader data={{ icon: 'checklist', name: 'Your Checklists' }} />
      {checklistMenu?.map((checklist) => (
        <ModuleItem
          key={checklist?.checklistId}
          data={checklist}
          active={selectedMenu?.checklistId === checklist?.checklistId}
          onClick={() => handleSelectMenu(checklist)}
        />
      ))}
    </div>
  );
};
const YourReports = ({ reports, selectedMenu, handleSelectMenu }) => {
  return (
    <div className="px-3 flex-item mt-3">
      <ModuleHeader data={{ icon: 'lab_profile', name: 'Your Reports' }} />
      {reports.map((rpt) => (
        <ModuleItem
          key={rpt.name}
          data={{ ...rpt, type: 'Report', isVisited: false }}
          active={selectedMenu?.name === rpt?.name}
          onClick={() => handleSelectMenu({ ...rpt, type: 'Report' })}
        />
      ))}
    </div>
  );
};
const PortalModuleNavigation = ({
  owner,
  organization,
  organizationId,
  selectedMenu,
  setSelectedMenu,
}) => {
  const { enabledReports } = useEngagementReportTabs({
    organizationId: organization?.id || organizationId,
  });
  const { tenant } = useTenantContext();
  const { hash, updateHash } = useHash();
  const { Checklist = [], loading } = useChecklist(selectedMenu);
  const { isForwardBank } = useIsTenant();
  const { WistiaEmbedModal, shouldShowModal, setShowModal } =
    useWistiaEmbedPlayer('y1kfd18hrc', false, true);

  const handleSelectMenu = (menu) => {
    updateHash(menu.key);
  };
  const getSelectedMenuByHash = () => {
    // if the hash found in reports module or in checklist then get that
    return (
      enabledReports?.find((rpt) => rpt.key === hash) ||
      Checklist?.find((check) => check.key === hash) ||
      Checklist[0] ||
      {}
    );
  };
  useEffect(() => {
    let menu = getSelectedMenuByHash();
    if (hash || menu) {
      try {
        if (Object.entries(menu).length === 0) {
          menu = Checklist?.length ? Checklist[0] : enabledReports[0];
        }
      } catch (e) {
        console.log('e', e);
      }
      setSelectedMenu(menu);
      // if there are checklist assigned and is forward bank and the video is not closed once
      if (
        Checklist?.length &&
        (isForwardBank || tenant?.name === 'Forward Bank') && // thats a temp solution just forward bank
        !shouldShowModal()
      ) {
        setShowModal(true);
      }
    }
  }, [hash, Checklist]);

  return (
    <div className="left-module-navigation d-flex flex-column with-separator">
      <>
        <WistiaEmbedModal />
        {loading ? (
          <div className="p-3">
            <Skeleton height={10} className="d-block w-100 mb-2" />
            <Skeleton height={10} className="d-block w-100 mb-2" />
            <Skeleton height={10} className="d-block w-100 mb-2" />
          </div>
        ) : (
          <>
            {Checklist?.length > 0 ? (
              <YourChecklists
                selectedMenu={selectedMenu}
                handleSelectMenu={handleSelectMenu}
                checklists={Checklist}
              />
            ) : null}
          </>
        )}
      </>
      {enabledReports.length ? (
        <YourReports
          reports={enabledReports}
          selectedMenu={selectedMenu}
          handleSelectMenu={handleSelectMenu}
        />
      ) : null}
    </div>
  );
};

export default PortalModuleNavigation;

import React from 'react';
import InsightStatItem from '../InsightStatItem';

export const LearnDashboardWidget = (results) => {
  const { data } = results[0];
  return (
    <InsightStatItem
      tooltipData={{
        'Total Lessons Completed':
          'Total number of lessons taken by all users combined',
      }}
      heading="Total Lessons Completed"
      headingClass="font-size-md"
      countClass={'font-size-4em text-left'}
      subHeadingClass="font-size-xl"
      customClass="col-md-12 pb-3 px-0"
      stat={data[0]?.['LessonProgress.countOfCompleted']}
      hideAverage
    />
  );
};

export const initialFilters = {
  global: {
    type: 'company',
    text: '',
  },
  industry: {
    category: {
      Agriculture: [],
      Mining: [],
      Construction: [],
      Transportation: [],
      Manufacturing: [],
      Wholesale: [],
      Retail: [],
      'Finance, Insurance, Real Estate': [],
      Services: [],
      'Public Administration': [],
      'Nonclassifiable  Establishments': [],
    },
    industries: [],
    sic_codes: [],
    naics_codes: [],
  },
  title: {
    title_search_mode: 'include',
    titles: '',
    job_functions: [],
    management_levels: [],
  },
  location: {
    location_target: 'company',
    cities: [],
    states: [],
  },
  revenue: {
    range: [],
  },
  employees: {
    range: [],
  },
};

export const industryCategory = {
  Agriculture: [
    { name: 'Agricultural Production Crops', id: '01' },
    { name: 'Agriculture Production Livestock', id: '02' },
    { name: 'Agricultural Services', id: '07' },
    { name: 'Forestry', id: '08' },
    { name: 'Fishing, Hunting & Trapping', id: '09' },
  ],
  Mining: [
    { name: 'Metal Mining', id: '10' },
    { name: 'Coal Mining', id: '12' },
    { name: 'Oil & Gas Extraction', id: '13' },
    {
      name: 'Mining & Quarrying of Nonmetallic Minerals, Except Fuels',
      id: '14',
    },
  ],
  Construction: [
    {
      name: 'Building Construction General Contractors & Operative Builders',
      id: '15',
    },
    {
      name: 'Heavy Construction Other Than Building Construction Contractors',
      id: '16',
    },
    { name: 'Construction Special Trade Contractors', id: '17' },
  ],
  Manufacturing: [
    { name: 'Food & Kindred Products', id: '20' },
    { name: 'Tobacco Products', id: '21' },
    { name: 'Textile Mill Products', id: '22' },
    {
      name: 'Apparel & Other Finished Products Made From Fabrics & Similar Materials',
      id: '23',
    },
    { name: 'Lumber & Wood Products, Except Furniture', id: '24' },
    { name: 'Furniture & Fixtures', id: '25' },
    { name: 'Paper & Allied Products', id: '26' },
    { name: 'Printing, Publishing & Allied Industries', id: '27' },
    { name: 'Chemicals & Allied Products', id: '28' },
    { name: 'Petroleum Refining & Related Industries', id: '29' },
    { name: 'Rubber & Miscellaneous Plastics Products', id: '30' },
    { name: 'Leather & Leather Products', id: '31' },
    { name: 'Stone, Clay, Glass & Concrete Products', id: '32' },
    { name: 'Primary Metal Industries', id: '33' },
    {
      name: 'Fabricated Metal Products, Except Machinery & Transportation Equipment	',
      id: '34',
    },
    {
      name: 'Industrial & Commercial Machinery & Computer Equipment',
      id: '35',
    },
    {
      name: 'Electronic & Other Electrical Equipment & Components, Except Computer Equipment',
      id: '36',
    },
    { name: 'Transportation Equipment', id: '37' },
    {
      name: 'Measuring, Analyzing & Controlling Instruments; Photographic, Medical & Optical Goods; Watches & Clocks',
      id: '38',
    },
    { name: 'Miscellaneous Manufacturing Industries', id: '39' },
  ],
  Transportation: [
    { name: 'Railroad Transportation', id: '40' },
    {
      name: 'Local & Suburban Transit & Interurban Highway Passenger Transportation',
      id: '41',
    },
    { name: 'Motor Freight Transportation & Warehousing', id: '42' },
    { name: 'United States Postal Service', id: '43' },
    { name: 'Water Transportation', id: '44' },
    { name: 'Transportation By Air', id: '45' },
    { name: 'Pipelines, Except Natural Gas', id: '46' },
    { name: 'Transportation Services', id: '47' },
    { name: 'Communications', id: '48' },
    { name: 'Electric, Gas & Sanitary Services', id: '49' },
  ],
  Wholesale: [
    { name: 'Wholesale Trade-durable Goods', id: '50' },
    { name: 'Wholesale Trade-non-durable Goods', id: '51' },
  ],
  Retail: [
    {
      name: 'Building Materials, Hardware, Garden Supply & Mobile Home Dealers',
      id: '52',
    },
    { name: 'General Merchandise Stores', id: '53' },
    { name: 'Food Stores', id: '54' },
    { name: 'Automotive Dealers & Gasoline Service Stations', id: '55' },
    { name: 'Apparel & Accessory Stores', id: '56' },
    { name: 'Home Furniture, Furnishings & Equipment Stores', id: '57' },
    { name: 'Eating & Drinking Places', id: '58' },
    { name: 'Miscellaneous Retail', id: '59' },
  ],
  'Finance, Insurance, Real Estate': [
    { name: 'Depository Institutions', id: '60' },
    { name: 'Non-depository Credit Institutions', id: '61' },
    {
      name: 'Security & Commodity Brokers, Dealers, Exchanges & Services',
      id: '62',
    },
    { name: 'Insurance Carriers', id: '63' },
    { name: 'Insurance Agents, Brokers & Service', id: '64' },
    { name: 'Real Estate', id: '65' },
    { name: 'Holding & Other Investment Offices', id: '67' },
  ],
  Services: [
    {
      name: 'Hotels, Rooming Houses, Camps & Other Lodging Places',
      id: '70',
    },
    { name: 'Personal Services', id: '72' },
    { name: 'Business Services', id: '73' },
    { name: 'Automotive Repair, Services & Parking', id: '75' },
    { name: 'Miscellaneous Repair Services', id: '76' },
    { name: 'Motion Pictures', id: '78' },
    { name: 'Amusement & Recreation Services', id: '79' },
    { name: 'Health Services', id: '80' },
    { name: 'Legal Services', id: '81' },
    { name: 'Educational Services', id: '82' },
    { name: 'Social Services', id: '83' },
    {
      name: 'Museums, Art Galleries & Botanical & Zoological Gardens',
      id: '84',
    },
    { name: 'Membership Organizations', id: '86' },
    {
      name: 'Engineering, Accounting, Research, Management & Related Services',
      id: '87',
    },
    { name: 'Private Households', id: '88' },
    { name: 'Miscellaneous Services', id: '89' },
  ],
  'Public Administration': [
    {
      name: 'Executive, Legislative & General Government, Except Finance',
      id: '91',
    },
    { name: 'Justice, Public Order & Safety', id: '92' },
    { name: 'Public Finance, Taxation & Monetary Policy', id: '93' },
    { name: 'Administration Of Human Resource Programs', id: '94' },
    {
      name: 'Administration Of Environmental Quality & Housing Programs',
      id: '95',
    },
    { name: 'Administration Of Economic Programs', id: '96' },
    { name: 'National Security & International Affairs', id: '97' },
  ],
  'Nonclassifiable  Establishments': [
    { name: 'Nonclassifiable Establishments', id: '99' },
  ],
};

export const filterItems = [
  {
    name: 'Industry',
    icon: 'business',
  },
  {
    name: 'Title',
    icon: 'subtitles',
  },
  {
    name: 'Location',
    icon: 'place',
  },
  {
    name: 'Revenue',
    subName: 'By Sales Revenue',
    icon: 'paid',
  },
  {
    name: 'Employees',
    subName: 'By Number of Employees',
    icon: 'groups',
  },
];

export const columnsTableProspecting = [
  {
    key: 'Company',
    component: 'Company',
  },
  {
    key: 'Contact',
    component: 'Contact',
  },
  {
    key: 'Title',
    component: 'Title',
  },
  {
    key: 'Email',
    component: 'Email',
  },
  {
    key: 'Phone',
    component: 'Phone',
  },
  {
    key: 'Contact Location',
    component: 'Contact Location',
  },
  {
    key: 'Location',
    component: 'Company Location',
  },
  {
    key: 'Industry',
    component: 'Industry',
  },
];

export const columnsTablePeople = [
  {
    key: 'Name',
    component: 'Name',
  },
  {
    key: 'Company',
    component: 'Company',
  },
  {
    key: 'Location',
    component: 'Location',
  },
  {
    key: 'ContactInfo',
    component: 'Available Details',
  },
  {
    key: '',
    component: '',
  },
];

export const columnsTableCompany = [
  {
    key: 'Name',
    component: 'Company',
  },
  {
    key: 'Location',
    component: 'Location',
  },
  {
    key: 'Details',
    component: 'Details',
  },
  {
    key: '',
    component: '',
  },
];

export const industries = [
  '3D Printing',
  '3D Technology',
  'A/B Testing',
  'ABDOMINAL SUPPORTS (WHOLESALE)',
  'ABDOMINAL SUPPORTS-MANUFACTURERS',
  'ABORTION ALTERNATIVES ORGANIZATIONS',
  'ABORTION INFORMATION & SERVICES',
  'ABRASIVE CUTTING',
  'Abrasive Product Manufacturing',
  'ABRASIVE PRODUCTS',
  'ABRASIVE PRODUCTS (MANUFACTURERS)',
  'ABRASIVES (WHOLESALE)',
  'ABSORBENTS (WHOLESALE)',
  'ABSORBENTS-MANUFACTURERS',
  'ABSTRACTERS',
  'ABUSE INFORMATION & TREATMENT CENTERS',
  'ACADEMIC CAREER COORDINATION',
  'ACCESS PANELS',
  'ACCESSORIES-FASHION',
  'ACCIDENT & HEALTH INS (UNDERWRITERS)',
  'ACCIDENT AND HEALTH INSURANCE',
  'ACCIDENT RECONSTRUCTION SERVICE',
  'ACCOMMODATIONS',
  'ACCORDIONS',
  'ACCOUNTANTS',
  'ACCOUNTANTS REFERRAL SERVICE',
  'ACCOUNTANTS-CERTIFIED-MANAGEMENT',
  'ACCOUNTING',
  'Accounting',
  'ACCOUNTING & BOOKKEEPING GENERAL SVC',
  'ACCOUNTING & BOOKKEEPING SYSTEMS (WHOL)',
  'ACCOUNTING/BOOKKEEPING MACH/SUPLS (WHOL)',
  'ACCUMULATORS (MANUFACTURERS)',
  'ACETYLENE (WHOLESALE)',
  'ACIDS (WHOLESALE)',
  'ACOUSTICAL CONSULTANTS',
  'ACOUSTICAL CONTRACTORS',
  'ACOUSTICAL MATERIALS',
  'ACOUSTICAL MATERIALS-MANUFACTURERS',
  'ACOUSTICAL MATERIALS-WHOLESALE',
  'ACRYLIC (MANUFACTURERS)',
  'ACTUARIES',
  'ACUPUNCTURE',
  'ACUPUNCTURE INFORMATION & REFERRAL SVC',
  'ACUPUNCTURE SCHOOLS',
  'Ad Exchange',
  'Ad Network',
  'Ad Retargeting',
  'Ad Server',
  'Ad Targeting',
  'ADDICTION TREATMENT CENTERS',
  'ADDRESSING MACHINES & SUPPLIES (WHOL)',
  'ADDRESSING PLATE SERVICE',
  'Adhesive Manufacturing',
  'ADHESIVES & GLUES (WHOLESALE)',
  'ADHESIVES & GLUES-MANUFACTURERS',
  'ADHESIVES & GLUING EQUIPMENT-MFRS',
  'ADHESIVES & SEALANTS (MANUFACTURERS)',
  'ADHESIVES AND SEALANTS',
  'ADJUSTERS-PUBLIC',
  'ADJUSTMENT AND COLLECTION SERVICES',
  'Administration of Air and Water Resource and Solid Waste Management Programs',
  'Administration of Conservation Programs',
  'Administration of Education Programs',
  'ADMINISTRATION OF EDUCATIONAL PROGRAMS',
  'ADMINISTRATION OF GENERAL ECONOMIC PROGRAMS',
  'Administration of General Economic Programs',
  'Administration of Housing Programs',
  "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs) ",
  'ADMINISTRATION OF PUBLIC HEALTH PROGRAMS',
  'Administration of Public Health Programs',
  'ADMINISTRATION OF SOCIAL AND MANPOWER PROGRAMS',
  'Administration of Urban Planning and Community and Rural Development',
  "ADMINISTRATION OF VETERANS' AFFAIRS",
  "Administration of Veterans' Affairs",
  'Administrative Management and General Management Consulting Services',
  'Administrative Services',
  'ADOBE BLOCKS & SHAPES (MANUFACTURERS)',
  'ADOBE MATERIALS & CONTRACTORS',
  'ADOPTION AGENCIES',
  'Adult',
  'ADULT ABUSE HOTLINE',
  'ADULT CARE FACILITIES',
  'ADULT CONGREGATE LIVING FACILITIES',
  'Advanced Materials',
  'Adventure Travel',
  'ADVERTISING',
  'Advertising',
  'Advertising Agencies',
  'ADVERTISING AGENCIES',
  'ADVERTISING AGENCIES & RELATED-NEC',
  'Advertising Material Distribution Services',
  'ADVERTISING NEC',
  'Advertising Platforms',
  'ADVERTISING-AERIAL',
  'ADVERTISING-AGENCIES & COUNSELORS',
  'ADVERTISING-ART LAYOUT & PRODUCTION SVC',
  'ADVERTISING-CHECKING SERVICE',
  'ADVERTISING-COMPUTER',
  'ADVERTISING-COUPONS',
  'ADVERTISING-DIRECT MAIL',
  'ADVERTISING-DIRECTORY & GUIDE',
  'ADVERTISING-DISTRIBUTORS',
  'ADVERTISING-FAX',
  'ADVERTISING-INDOOR',
  'ADVERTISING-JEWELRY AWARDS & INCENTIVES',
  'ADVERTISING-MAGAZINE',
  'ADVERTISING-MOTION PICTURE',
  'ADVERTISING-NEWSPAPER',
  'ADVERTISING-OUTDOOR',
  'ADVERTISING-PERIODICAL',
  'ADVERTISING-PERSONNEL RECRUITMENT',
  'ADVERTISING-POINT OF PURCHASE',
  'ADVERTISING-PROMOTIONAL',
  'ADVERTISING-RADIO',
  'ADVERTISING-SCHOOLS',
  'ADVERTISING-SEARCHLIGHTS',
  "ADVERTISING-SHOPPERS' GUIDES",
  'ADVERTISING-SOUNDTRACK & JINGLES',
  'ADVERTISING-SPECIALTIES (WHOLESALE)',
  'ADVERTISING-SPECIALTIES-MANUFACTURERS',
  'ADVERTISING-TELEVISION',
  'ADVERTISING-THEATRICAL',
  'ADVERTISING-TRANSIT & TRANSPORTATION',
  'ADVERTISING-TRAVELING SIGNBOARDS',
  'ADVERTISING-VIDEO PRODUCTION SERVICES',
  'Advice',
  'ADVOCACY RESOURCES',
  'AERIAL APPLICATORS',
  'AERIAL PATROL & INSPECTION SERVICE',
  'AERIAL PERSONNEL LIFTS',
  'AERIAL TOWERS',
  'AEROBIC INSTRUCTION',
  'AERONAUTICAL SUPPLIES',
  'AEROSOLS (MANUFACTURERS)',
  'Aerospace',
  'AEROSPACE INDUSTRIES',
  'AEROSPACE SUPPORT SERVICES-TECHNICAL',
  'Affiliate Marketing',
  'AFRICAN GOODS-RETAIL',
  'AGATES',
  'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
  'AGRICULTURAL CHEMICALS',
  'AGRICULTURAL CHEMICALS (WHOLESALE)',
  'AGRICULTURAL CONSULTANTS',
  'AGRICULTURAL IMPLEMENTS & SUPPLIES-WHOL',
  'AGRICULTURAL MARKETING',
  'AGRICULTURAL MINERALS',
  'AGRICULTURAL PRODUCTS',
  'AGRICULTURAL RESEARCH',
  'AGRICULTURAL TECHNICIANS',
  'Agriculture',
  'Agriculture and Farming',
  'AgTech',
  'AIDS INFORMATION & TESTING',
  'AIR',
  'AIR & GAS COMPRESSORS (MANUFACTURERS)',
  'AIR AMBULANCE SERVICE',
  'Air and Gas Compressor Manufacturing',
  'AIR AND GAS COMPRESSORS',
  'AIR BALANCING',
  'AIR BEDS',
  'AIR BOATS',
  'AIR BRUSHES (WHOLESALE)',
  'AIR CARGO SERVICE',
  'AIR CLEANING & PURIFYING EQUIP (WHOL)',
  'AIR CLEANING & PURIFYING EQUIPMENT-MFRS',
  'AIR CONDITIONING & HEATING SERVICE/RPR',
  'AIR CONDITIONING CONTRACTORS & SYSTEMS',
  'AIR CONDITIONING EQUIP & SYSTEMS-WHOL',
  'AIR CONDITIONING EQUIP TEST & BALANCING',
  'AIR CONDITIONING EQUIP-ROOM UNITS-REPAIR',
  'AIR CONDITIONING EQUIPMENT-MANUFACTURERS',
  'AIR CONDITIONING EQUIPMENT-REPAIR',
  'AIR CONDITIONING EQUIPMENT-ROOM UNITS',
  'AIR CONDITIONING ROOM UNITS-MFRS',
  'AIR CONDITIONING ROOM UNITS-RENTING',
  'AIR CONDITIONING SUPPLIES & PARTS (WHOL)',
  'AIR CONDITIONING SUPPLIES & PARTS-MFRS',
  'AIR CONDITIONING SYSTEMS-CLEANING',
  'AIR CONDITIONING/HTG/REFRIG EQUIP (MFRS)',
  'AIR COURIER SERVICES',
  'AIR COURIER SERVICES',
  'AIR CURTAINS & SCREENS (WHOLESALE)',
  'AIR CURTAINS & SCREENS-MANUFACTURERS',
  'AIR CUSHION VEHICLES',
  'AIR FLIGHT ATTENDANTS',
  'AIR FRAGRANCES',
  'AIR FRESHENERS-MANUFACTURERS',
  'AIR MOTORS (WHOLESALE)',
  'AIR POLLUTION CONTROL',
  'AIR POLLUTION MEASURING EQUIP (WHOL)',
  'AIR POLLUTION MEASURING SERVICE',
  'AIR PURIFICATION & FILTRATION EQUIPMENT',
  'AIR QUALITY MEASURING EQUIPMENT',
  'Air Traffic Control',
  'AIR TRAFFIC CONTROL TOWER',
  'AIR TRANSPORTATION',
  'AIR TRANSPORTATION',
  'Air Transportation',
  'AIR TRANSPORTATION-NONSCHEDULED',
  'AIR TRANSPORTATION-SCHEDULED',
  'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing',
  'AIRCRAFT',
  'AIRCRAFT AVIONICS-SALES & SERVICE (WHOL)',
  'AIRCRAFT BROKERS',
  'AIRCRAFT CHARTER RENTAL & LEASING SVC',
  'AIRCRAFT COMPONENTS-MANUFACTURERS',
  'AIRCRAFT DESIGNING (MANUFACTURERS)',
  'Aircraft Engine and Engine Parts Manufacturing',
  'AIRCRAFT ENGINES (WHOLESALE)',
  'AIRCRAFT ENGINES & ENGINE PARTS-MFRS',
  'AIRCRAFT ENGINES AND ENGINE PARTS',
  'AIRCRAFT ENGINES-MANUFACTURERS',
  'AIRCRAFT ENGINES-SERVICING & MAINTENANCE',
  'AIRCRAFT EQUIPMENT PARTS & SUPLS-MFRS',
  'AIRCRAFT EQUIPMENT PARTS & SUPLS-WHOL',
  'AIRCRAFT EQUIPMENT PARTS & SUPPLIES',
  'AIRCRAFT FERRYING & TRANSPORTING SVC',
  'AIRCRAFT FLIGHT TRAINING EQUIP (MFRS)',
  'AIRCRAFT GROUND SUPPORT & SERVICE EQUIP',
  'AIRCRAFT GROUND SUPPORT & SVC EQUIP-MFRS',
  'AIRCRAFT HANGARS-RENTAL & SALES',
  'AIRCRAFT INDUSTRIAL FASTENERS (MFRS)',
  'AIRCRAFT INSPECTION',
  'AIRCRAFT INSTRUMENTS (WHOLESALE)',
  'AIRCRAFT INTERIOR REDESIGNING (MFRS)',
  'AIRCRAFT MACHINE WORK',
  'AIRCRAFT MANAGEMENT SERVICES',
  'Aircraft Manufacturing',
  'AIRCRAFT MODIFICATIONS (MANUFACTURERS)',
  'AIRCRAFT OXYGEN SYSTEMS (MANUFACTURERS)',
  'AIRCRAFT PARTS AND EQUIPMENT',
  'AIRCRAFT PARTS-ASSEMBLERS',
  'AIRCRAFT PARTS-RECLAIMING (WHOLESALE)',
  'AIRCRAFT RADIO COMMUNICATION',
  'AIRCRAFT RADIO EQUIPMENT (MANUFACTURERS)',
  'AIRCRAFT RADIO SERVICING',
  'AIRCRAFT REBUILDING (WHOLESALE)',
  'AIRCRAFT REFINISHING & PAINTING',
  'AIRCRAFT RESEARCH & DEVELOPMENT (MFRS)',
  'AIRCRAFT SALVAGE (WHOLESALE)',
  'AIRCRAFT SCHOOLS',
  'AIRCRAFT SERVICING & MAINTENANCE',
  'AIRCRAFT STORAGE',
  'AIRCRAFT SUPPORT SERVICES',
  'AIRCRAFT TOOLING (MANUFACTURERS)',
  'AIRCRAFT UPHOLSTERERS',
  'AIRCRAFT WASHING & POLISHING',
  'AIRCRAFT-DEALERS',
  'AIRCRAFT-MANUFACTURERS',
  'AIRCRAFT/AIRCRAFT EQUIP TESTING (MFRS)',
  'AIRLINE COMPANIES',
  'AIRLINE SUPPORT SERVICES',
  'AIRLINE TICKET AGENCIES',
  'AIRLINE TRAINING SCHOOLS',
  'Airlines/Aviation',
  'AIRPLANES-MODEL',
  'AIRPORT DESIGNERS',
  'AIRPORT EQUIPMENT & SUPPLIES (MFRS)',
  'AIRPORT PARKING SERVICE',
  'AIRPORT TRANSPORTATION SERVICE',
  'AIRPORTS',
  'AIRPORTS',
  'ALBUMS (PHOTO)-MANUFACTURERS',
  'ALBUMS (PHOTO)-WHOLESALE',
  'ALCOHOL (WHOLESALE)',
  'ALCOHOL BREATH DETECTORS (MANUFACTURERS)',
  'ALCOHOL PERMIT CONSULTANTS',
  'ALCOHOLISM INFORMATION & TREATMENT CTRS',
  'ALEXANDER TECHNIQUE',
  'ALFALFA & ALFALFA PRODUCTS (WHOLESALE)',
  'ALKALIES (MANUFACTURERS)',
  'ALKALIES & CHLORINE (MANUFACTURERS)',
  'ALKALIES AND CHLORINE',
  'All Other Amusement and Recreation Industries',
  'All Other Animal Production',
  'All Other Automotive Repair and Maintenance',
  'All Other Basic Organic Chemical Manufacturing',
  'All Other Business Support Services',
  'All Other Consumer Goods Rental',
  'All Other Converted Paper Product Manufacturing',
  'All Other General Merchandise Stores',
  'All Other Grain Farming',
  'All Other Health and Personal Care Stores',
  'All Other Home Furnishings Stores',
  'All Other Information Services',
  'All Other Insurance Related Activities',
  'All Other Leather Good and Allied Product Manufacturing',
  'All Other Legal Services',
  'All Other Metal Ore Mining',
  'All Other Miscellaneous Ambulatory Health Care Services',
  'All Other Miscellaneous Chemical Product and Preparation Manufacturing',
  'All Other Miscellaneous Crop Farming',
  'All Other Miscellaneous Electrical Equipment and Component Manufacturing',
  'All Other Miscellaneous Fabricated Metal Product Manufacturing',
  'All Other Miscellaneous Food Manufacturing',
  'All Other Miscellaneous General Purpose Machinery Manufacturing',
  'All Other Miscellaneous Manufacturing',
  'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing',
  'All Other Miscellaneous Schools and Instruction',
  'All Other Miscellaneous Store Retailers (except Tobacco Stores)',
  'All Other Miscellaneous Textile Product Mills',
  'All Other Miscellaneous Waste Management Services',
  'All Other Miscellaneous Wood Product Manufacturing',
  'All Other Nondepository Credit Intermediation',
  'All Other Nonmetallic Mineral Mining',
  'All Other Outpatient Care Centers',
  'All Other Personal Services',
  'All Other Petroleum and Coal Products Manufacturing',
  'All Other Pipeline Transportation',
  'All Other Plastics Product Manufacturing',
  'All Other Professional, Scientific, and Technical Services',
  'All Other Publishers',
  'All Other Rubber Product Manufacturing',
  'All Other Specialty Food Stores',
  'All Other Specialty Trade Contractors',
  'All Other Support Activities for Transportation',
  'All Other Support Services',
  'All Other Telecommunications',
  'All Other Transit and Ground Passenger Transportation',
  'All Other Transportation Equipment Manufacturing',
  'All Other Travel Arrangement and Reservation Services',
  'All Other Traveler Accommodation',
  'ALL TERRAIN VEHICLES',
  'ALL TERRAIN VEHICLES-MANUFACTURERS',
  'ALLERGY EQUIPMENT & SUPPLIES',
  'ALLERGY LABORATORIES',
  'ALLERGY RESISTANT PRODUCTS',
  'ALLIGATORS',
  'ALLOYS (WHOLESALE)',
  'ALTERATIONS-CLOTHING',
  'Alternative Dispute Resolution',
  'ALTERNATIVE MEDICINE',
  'Alternative Medicine',
  'ALTERNATORS & GENERATORS-AUTOMOTIVE',
  'ALTERNATORS & GENERATORS-AUTOMOTIVE-MFRS',
  'ALTERNATORS & GENERATORS-AUTOMOTIVE-RPR',
  'ALTERNATORS & GENERATORS-AUTOMOTIVE-WHOL',
  'ALTERNATORS & STARTERS-MARINE (MFRS)',
  'ALUM-MANUFACTURERS',
  'Alumina Refining and Primary Aluminum Production',
  'ALUMINUM (WHOLESALE)',
  'ALUMINUM DIE CASTINGS (MANUFACTURERS)',
  'ALUMINUM DIE-CASTINGS',
  'ALUMINUM EXTRUDED PRODUCTS',
  'ALUMINUM EXTRUDED PRODUCTS (MFRS)',
  'ALUMINUM FABRICATORS',
  'ALUMINUM FOUNDRIES',
  'Aluminum Foundries (except Die-Casting)',
  'ALUMINUM ORNAMENTAL WORK (MFRS)',
  'ALUMINUM PRODUCTS-RETAIL',
  'ALUMINUM ROLLING & DRAWING NEC (MFRS)',
  'ALUMINUM ROLLING AND DRAWING',
  'ALUMINUM SHEET',
  'ALUMINUM SHEET PLATE & FOIL (MFRS)',
  'Aluminum Sheet, Plate, and Foil Manufacturing ',
  'ALUMINUM WOOL-MANUFACTURERS',
  'Alumni',
  'ALZHEIMERS EDUCATION/REFERRAL/SUPPORT',
  'AMATEUR RADIO EQUIPMENT (WHOLESALE)',
  'AMBULANCE SERVICE',
  'Ambulance Services',
  'AMBULANCES & HEARSES (WHOLESALE)',
  'AMBULANCES & HEARSES EQUIP/SUPLS (WHOL)',
  'AMBULANCES & HEARSES-MANUFACTURERS',
  'AMBULANCES & HEARSES-RENTING',
  'AMBULETTE SERVICE',
  'American Football',
  'American Indian and Alaska Native Tribal Governments',
  'AMMONIA (WHOLESALE)',
  'AMMONIA-MANUFACTURERS',
  'AMMUNITION',
  'AMMUNITION',
  'Ammunition (except Small Arms) Manufacturing',
  'AMMUNITION DISASSEMBLY WST/RECYCLE (WHL)',
  'AMMUNITION RELOADING EQUIP & SUPLS-MFRS',
  'AMMUNITION RELOADING EQUIPMENT & SUPLS',
  'AMMUNITION-EXCEPT FOR SMALL ARMS (MFRS)',
  'AMMUNITION-WHOLESALE',
  'AMPHIBIOUS VEHICLES (WHOLESALE)',
  'AMPLIFIERS',
  'AMPLIFIERS-MANUFACTURERS',
  'AMUSEMENT & RECREATION NEC',
  'AMUSEMENT AND RECREATION',
  'Amusement and Theme Parks',
  'Amusement Arcades',
  'AMUSEMENT DEVICES',
  'AMUSEMENT DEVICES-RENTING',
  'AMUSEMENT DEVICES-WHOLESALE',
  'Amusement Park and Arcade',
  'AMUSEMENT PARK RIDES EQUIPMENT (WHOL)',
  'AMUSEMENT PARK RIDES EQUIPMENT-MFRS',
  'AMUSEMENT PARKS',
  'AMUSEMENT PLACES',
  'ANALYTICAL INSTRUMENTS',
  'Analytical Laboratory Instrument Manufacturing',
  'Analytics',
  'ANALYZERS-INDUSTRIAL CONTROL-MFRS',
  'ANCHORS',
  'ANCHORS-EXPANSION (MANUFACTURERS)',
  'ANCHORS-MARINE',
  'ANCSA NATIVE CORPORATIONS',
  'Android',
  'ANEMOMETERS (MANUFACTURERS)',
  'ANESTHETISTS',
  'Angel Investment',
  'ANGLE IRONS (MANUFACTURERS)',
  'Animal (except Poultry) Slaughtering',
  'ANIMAL & MARINE FATS & OILS (MFRS)',
  'ANIMAL AND MARINE FATS AND OILS',
  'ANIMAL AQUACULTURE',
  'ANIMAL BREEDING-SMALL',
  'ANIMAL BROKERS & DEALERS-ZOO CIRCUS ETC',
  'ANIMAL CAGES & RACKS-WHOLESALE',
  'ANIMAL CARCASS REMOVAL',
  'Animal Feed',
  'ANIMAL FOODS-ZOO & CIRCUS & ETC (WHOL)',
  'ANIMAL HEALTH PRODUCTS (WHOLESALE)',
  'ANIMAL HEALTH PRODUCTS-MANUFACTURERS',
  'ANIMAL HOSPITALS',
  'ANIMAL NUTRITIONIST CONSULTANTS',
  'ANIMAL PROTECTION ORGANIZATIONS',
  'ANIMAL REGISTRATION & IDENTIFICATION SVC',
  'ANIMAL RESCUE RELOCATION & TRANSPORTING',
  'ANIMAL SHELTERS',
  'ANIMAL SHOWS & ORGANIZATIONS',
  'ANIMAL SPECIALTIES',
  'ANIMAL SPECIALTIES NEC',
  'ANIMAL SPECIALTY SERVICES',
  'ANIMAL SUPPLIES-ZOO CIRCUS ETC (WHOL)',
  'ANIMAL TRANSPORTING-ZOO CIRCUS ETC',
  'ANIMALS-LABORATORY USE',
  'ANIMALS-PEST TRAPPERS',
  'ANIMATED FILM SERVICES',
  'Animation',
  'ANIMATION MECHANISMS-DISPLAY SIGN (MFRS)',
  'ANNOUNCEMENTS (MANUFACTURERS)',
  'ANNUNCIATORS-MANUFACTURERS',
  'ANNUNCIATORS-WHOLESALE',
  'ANODES (WHOLESALE)',
  'ANODES-MANUFACTURERS',
  'ANODIZING (MANUFACTURERS)',
  'ANSWERING MACHINES-AUTOMATIC',
  'ANTENNAS',
  'ANTENNAS-MANUFACTURERS',
  'ANTENNAS-TELEVISION COMMUNITY SYSTEMS',
  'ANTENNAS-WHOLESALE',
  'ANTHRACITE MINING',
  'ANTHRACITE MINING',
  'Anthracite Mining',
  'ANTI-FOGGING COMPOUNDS-MANUFACTURERS',
  'ANTI-FREEZE COMPOUNDS-MANUFACTURERS',
  'ANTI-FREEZE COMPOUNDS-WHOLESALE',
  'ANTIQUE JEWELRY',
  'ANTIQUES-DEALERS',
  'ANTIQUES-REPAIRING & RESTORING',
  'ANTIQUES-REPRODUCTIONS',
  'ANTIQUES-RESTORATION SUPPLIES & HARDWARE',
  'ANTIQUES-WHOLESALE',
  'ANVILS (MANUFACTURERS)',
  'APARTMENT BUILDING OPERATORS',
  'APARTMENT FINDING & RENTAL SERVICE',
  'APARTMENT FURNISHINGS',
  'APARTMENT HOUSE SUPPLIES (WHOLESALE)',
  'APARTMENT RENTAL AGENCIES',
  'APARTMENT SHARING SERVICE',
  'APARTMENTS',
  'Apiculture',
  'App Discovery',
  'App Marketing',
  'APPAREL & ACCESSORIES NEC (MFRS)',
  'Apparel & Fashion',
  'APPAREL & GARMENTS-RETAIL',
  'Apparel Accessories and Other Apparel Manufacturing',
  'APPAREL AND ACCESSORIES',
  'APPAREL BELTS',
  'APPAREL DESIGN & MFG',
  'APPAREL-BELTS (MANUFACTURERS)',
  'APPAREL-CHLDRN/TEEN HEADWEAR WHOL/MFRS',
  'APPAREL-MENS-WHOLESALE',
  'APPETIZERS & SNACKS ETC',
  'APPLE BROKERS',
  'APPLE GROWERS',
  'Apple Orchards',
  'APPLE PACKERS',
  'APPLE PRODUCTS',
  'Appliance Repair and Maintenance',
  'APPLIANCES-COMMERCIAL/IND-SALES/RPR',
  'APPLIANCES-HOUSEHOLD-MAJOR-DEALERS',
  'APPLIANCES-HOUSEHOLD-MAJOR-MANUFACTURERS',
  'APPLIANCES-HOUSEHOLD-MAJOR-RENTING',
  'APPLIANCES-HOUSEHOLD-MAJOR-REPAIRING',
  'APPLIANCES-HOUSEHOLD-MAJOR-SUPLS (WHOL)',
  'APPLIANCES-HOUSEHOLD-MAJOR-USED',
  'APPLIANCES-HOUSEHOLD-MAJOR-WHOLESALE',
  'APPLIANCES-HOUSEHOLD-REFINISHING',
  'APPLIANCES-HOUSEHOLD-SMALL-DEALERS',
  'APPLIANCES-HOUSEHOLD-SMALL-REPAIRING',
  'APPLIANCES-HOUSEHOLD-SMALL-USED',
  'APPLIANCES-HOUSEHOLD-SMALL-WHOLESALE',
  'APPLIANCES-HSHLD-SMALL-SUPLS/PRTS (WHOL)',
  'Application Performance Management',
  'Application Specific Integrated Circuit (ASIC)',
  'APPLICATORS (MANUFACTURERS)',
  'APPRAISERS',
  'APPRAISERS-INDUSTRIAL & COMMERCIAL',
  'Apprenticeship Training',
  'APPRENTICESHIP TRAINING PROGRAMS',
  'Apps',
  'APRONS-DOMESTIC & FANCY',
  'APRONS-DOMESTIC & FANCY-WHOLESALE & MFRS',
  'APRONS-INDUSTRIAL',
  'APRONS-INDUSTRIAL-MANUFACTURERS',
  'APRONS-INDUSTRIAL-WHOLESALE',
  'AQUACULTURE',
  'Aquaculture',
  'AQUACULTURE CONSULTANTS',
  'AQUARIUMS & AQUARIUM SUPLS',
  'AQUARIUMS & AQUARIUM SUPLS-LEASING/MNTC',
  'AQUARIUMS & AQUARIUM SUPLS-MFRS',
  'AQUARIUMS & AQUARIUM SUPLS-WHOLESALE',
  'AQUARIUMS-PUBLIC',
  'ARBITRATION SERVICES',
  'ARBORETUMS',
  'ARBORISTS',
  'ARCADES',
  'ARCHAEOLOGISTS',
  'ARCHERY EQUIPMENT & SUPPLIES',
  'ARCHERY EQUIPMENT & SUPPLIES-MFRS',
  'ARCHERY EQUIPMENT & SUPPLIES-WHOLESALE',
  'ARCHERY INSTRUCTION',
  'ARCHERY RANGES',
  'ARCHITECTS',
  'ARCHITECTS & BUILDERS SERVICE',
  'ARCHITECTS & ENGINEERS',
  'ARCHITECTS SERVICE BUREAU',
  'ARCHITECTS SUPPLIES (WHOLESALE)',
  'ARCHITECTS SUPPLIES-MANUFACTURERS',
  'ARCHITECTURAL & CONSTR SPECIFICATIONS',
  'ARCHITECTURAL CONSULTANTS',
  'ARCHITECTURAL DESIGNERS',
  'ARCHITECTURAL ILLUSTRATORS',
  'ARCHITECTURAL METALWORK',
  'ARCHITECTURAL MODELS',
  'Architectural Services',
  'ARCHITECTURAL SERVICES',
  'ARCHITECTURAL SERVICES',
  'ARCHITECTURAL/ORNAMENTAL MTL WORK (MFRS)',
  'Architecture',
  'Architecture & Planning',
  'ARCHIVES',
  'Archiving Service',
  'ARGON (WHOLESALE)',
  'ARMATURE REWINDING SHOPS',
  'ARMATURES (WHOLESALE)',
  'ARMATURES-REPAIRING & REWINDING',
  'ARMORED CAR SERVICE',
  'Armored Car Services',
  'ARMORED CAR-MANUFACTURERS',
  'ARMY & NAVY GOODS',
  'ARMY & NAVY GOODS-MANUFACTURERS',
  'ARMY & NAVY GOODS-WHOLESALE',
  'AROMATHERAPY',
  'ARRANGEMENT OF PASSENGER TRNSPRTN NEC',
  'ART',
  'Art',
  'ART CASTING',
  'ART CENTERS',
  'ART CONSULTANTS',
  'ART CULTURAL SOCIETIES',
  'Art Dealers',
  'ART DEALERS-WHOLESALE',
  'ART DELIVERY',
  'ART GALLERIES & DEALERS',
  'ART GALLERIES & MUSEUMS EQUIP & SUPPLIES',
  'ART GOODS-MANUFACTURERS',
  'ART GOODS-WHOLESALE',
  'ART INSTRUCTION & SCHOOLS',
  'ART METAL WORK (WHOLESALE)',
  'ART PUBLISHERS',
  'ART RENTAL',
  'ART RESTORING',
  'Artificial and Synthetic Fibers and Filaments Manufacturing',
  'ARTIFICIAL BREASTS',
  'ARTIFICIAL EYELASHES (WHOLESALE)',
  'ARTIFICIAL EYES-HUMAN (MANUFACTURERS)',
  'ARTIFICIAL EYES-TOYS & ANIMALS (MFRS)',
  'ARTIFICIAL FINGERNAILS',
  'ARTIFICIAL FLOWERS & PLANTS & TREES',
  'ARTIFICIAL FLOWERS & PLANTS & TREES-MFRS',
  'ARTIFICIAL FLOWERS-MTRLS/SUPLS (WHOL)',
  'ARTIFICIAL FLOWERS-WHOLESALE',
  'ARTIFICIAL FLOWERS/PLANTS-MFRS SUPL-WHOL',
  'ARTIFICIAL FLWRS/PLANTS-MFRS SUPL (MFRS)',
  'ARTIFICIAL FRUITS & FOOD (MANUFACTURERS)',
  'ARTIFICIAL GRASS',
  'Artificial Intelligence',
  'ARTIFICIAL KIDNEY CENTERS',
  'ARTIFICIAL LARYNXES (WHOLESALE)',
  'ARTIFICIAL LIMBS',
  'ARTIFICIAL LIMBS-MANUFACTURERS',
  'ARTIFICIAL SNOW (MANUFACTURERS)',
  'ARTISTS AGENTS',
  'ARTISTS MATERIALS & SUPPLIES',
  'ARTISTS MATERIALS-MANUFACTURERS',
  'ARTISTS MATERIALS-WHOLESALE',
  'ARTISTS-COMMERCIAL',
  'ARTISTS-FINE ARTS',
  'ARTISTS-SANTOS-FOLK ART',
  'ARTISTS-SCULPTURE',
  'ARTS & CRAFTS-INSTRUCTION',
  'ARTS & CRAFTS-WHOLESALE',
  'Arts and Crafts',
  'ARTS ORGANIZATIONS & INFORMATION',
  'ASBESTOS & ASBESTOS FREE PRODS (MFRS)',
  'ASBESTOS & ASBESTOS PRODUCTS (WHOLESALE)',
  'ASBESTOS PRODUCTS',
  'ASBESTOS REMOVAL SERVICE',
  'ASBESTOS REMOVAL SUPPLIES & EQUIP (WHOL)',
  'ASBESTOS SUBSTITUTES (MANUFACTURERS)',
  'ASBESTOS TRAINING SERVICE',
  'ASBESTOS-CONSULTING & TESTING',
  'ASHRAMS',
  'ASPHALT & ASPHALT PRODUCTS',
  'ASPHALT & ASPHALT PRODUCTS-MANUFACTURERS',
  'ASPHALT AGGREGATES (WHOLESALE)',
  'ASPHALT FELTS & COATINGS (MFRS)',
  'ASPHALT FELTS AND COATINGS',
  'ASPHALT MIXING EQUIPMENT (WHOLESALE)',
  'ASPHALT PAVING CONTRACTORS',
  'Asphalt Paving Mixture and Block Manufacturing',
  'ASPHALT PAVING MIXTURES AND BLOCKS',
  'ASPHALT PRODUCTS-WHOLESALE',
  'Asphalt Shingle and Coating Materials Manufacturing',
  'ASSAYERS & ASSAYING SERVICE',
  'ASSAYERS SUPPLIES (WHOLESALE)',
  'ASSEMBLY & FABRICATING SERVICE',
  'ASSESSMENT CONSULTANTS',
  'ASSET MANAGEMENT',
  'Asset Management',
  'ASSET PROTECTION CONSULTANTS',
  'Assisted Living',
  'Assisted Living Facilities for the Elderly',
  'Assistive Technology',
  'Association',
  'ASSOCIATION MANAGEMENT',
  'ASSOCIATIONS',
  'ASTROLOGERS',
  'ASTROLOGY SCHOOLS',
  'ATHLETIC CLOTHING-RETAIL',
  'ATHLETIC FIELD MAINTENANCE',
  'ATHLETIC ORGANIZATIONS',
  'ATOMIZERS (MANUFACTURERS)',
  'ATTIC & BASEMENT FINISHING',
  'ATTORNEY REFERRAL SERVICE',
  'ATTORNEY-BAR ASSOCIATIONS',
  'ATTORNEYS',
  'ATTORNEYS SERVICE BUREAUS',
  'AUCTIONEERS',
  'Auctions',
  'Audio',
  'Audio and Video Equipment Manufacturing',
  'AUDIO-VISUAL CONSULTANTS',
  'AUDIO-VISUAL CREATIVE SERVICES',
  'AUDIO-VISUAL EQUIPMENT & SUPLS (WHOL)',
  'AUDIO-VISUAL EQUIPMENT-DEALERS',
  'AUDIO-VISUAL EQUIPMENT-RENTING & LEASING',
  'AUDIO-VISUAL EQUIPMENT-REPAIRING',
  'AUDIO-VISUAL PRODUCTION SERVICE',
  'Audiobooks',
  'AUDIOLOGISTS',
  'AUDIOMETERS (WHOLESALE)',
  'AUDIOMETRIC TESTING',
  'AUDITORIUMS',
  'AUDITORS',
  'Augmented Reality',
  'AUTO AND HOME SUPPLY STORES',
  'AUTO EXHAUST SYSTEM REPAIR SHOPS',
  'AUTO INSPECTION STA EQUIP/SUPLS (WHOL)',
  'Auto Insurance',
  'AUTO MACHINE SHOP EQUIP/SUPLS (WHOL)',
  'AUTO SEATCOVERS ETC-MATERIAL/EQPT-MFRS',
  'AUTOCLAVES',
  'AUTOGRAPH DEALERS',
  'AUTOGRAPHIC REGISTERS & SUPPLIES',
  'AUTOMATED TELLER MACHINES',
  'AUTOMATIC CNTRLS-RESIDENTIAL/COML (MFRS)',
  'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use ',
  'AUTOMATIC VENDING MACHINES',
  'AUTOMATION CONSULTANTS',
  'AUTOMATION SYSTEMS & EQUIPMENT-MFRS',
  'AUTOMATION SYSTEMS & EQUIPMENT-WHOLESALE',
  'AUTOMOBILE & TRUCK BROKERS',
  'AUTOMOBILE ACCESSORIES',
  'AUTOMOBILE AIR CONDITIONING EQUIPMENT',
  'AUTOMOBILE ALARMS',
  'Automobile and Other Motor Vehicle Merchant Wholesalers',
  'AUTOMOBILE APPEARANCE & PROTECTION',
  'AUTOMOBILE APPRAISERS',
  'AUTOMOBILE AUCTIONS (WHOLESALE)',
  'AUTOMOBILE BEDS',
  'AUTOMOBILE BODY SHOP EQUIP/SUPLS (WHOL)',
  'AUTOMOBILE BODY SHOP EQUIPMENT-MFRS',
  'AUTOMOBILE BODY-MANUFACTURERS',
  'AUTOMOBILE BODY-REPAIRING & PAINTING',
  'AUTOMOBILE BUMPERS GUARDS & GRILLS',
  'AUTOMOBILE BUMPERS GUARDS & GRILLS-MFRS',
  'AUTOMOBILE BUMPERS GUARDS & GRILLS-WHOL',
  'AUTOMOBILE CLUBS',
  'AUTOMOBILE CLUTCH REBUILDING',
  'AUTOMOBILE COVERS',
  'AUTOMOBILE CRUISE CONTROL EQUIP (WHOL)',
  'AUTOMOBILE CRUSHING SERVICE (WHOLESALE)',
  'AUTOMOBILE CUSTOMIZING',
  'AUTOMOBILE DEALERS-ELECTRIC CARS',
  'AUTOMOBILE DEALERS-NEW CARS',
  'AUTOMOBILE DEALERS-USED CARS',
  'AUTOMOBILE DEALERS-USED CARS-WHOLESALE',
  'AUTOMOBILE DETAIL & CLEAN-UP SERVICE',
  'AUTOMOBILE DIAGNOSTIC EQUIPMENT (WHOL)',
  'AUTOMOBILE DIAGNOSTIC SERVICE',
  'AUTOMOBILE DIAGNOSTIC SERVICE EQUIP-MFRS',
  'AUTOMOBILE DISMANTLING/RECYCLING (WHOL)',
  'AUTOMOBILE DLRS-CUSTOM DESIGNED REPLICA',
  'Automobile Driving Schools',
  'AUTOMOBILE ELECTRIC EQUIPMENT',
  'AUTOMOBILE ELECTRIC EQUIPMENT-MFRS',
  'AUTOMOBILE ELECTRIC EQUIPMENT-WHOLESALE',
  'AUTOMOBILE ELECTRIC SERVICE',
  'AUTOMOBILE EMISSION TESTING',
  'AUTOMOBILE FLOOR COVERINGS',
  'AUTOMOBILE FLOOR COVERINGS-MANUFACTURERS',
  'AUTOMOBILE FRAME STRAIGHTENING',
  'AUTOMOBILE FUEL CONVERSION',
  'AUTOMOBILE GLASS-SERVICE & INSTALLATION',
  'AUTOMOBILE HAND & FOOT CONTROLS (WHOL)',
  'AUTOMOBILE HEADLIGHT ADJUSTING',
  'AUTOMOBILE HUB CAPS',
  'AUTOMOBILE INSPECTION STATIONS',
  'AUTOMOBILE LOCK-OUT SVC',
  'AUTOMOBILE LUBRICATION SERVICE',
  'AUTOMOBILE MACHINE SHOP EQUIP/SUPLS-MFRS',
  'AUTOMOBILE MACHINE SHOP SERVICE',
  'AUTOMOBILE MACHINE SHOP SERVICE-WHOL',
  'Automobile Manufacturing',
  'AUTOMOBILE MOTOR REBUILDING',
  'AUTOMOBILE MOTORS (WHOLESALE)',
  'AUTOMOBILE PAINT',
  'AUTOMOBILE PARKING',
  'AUTOMOBILE PARTS & SUPLS-ANTIQUE (WHOL)',
  'AUTOMOBILE PARTS & SUPPLIES-MFRS',
  'AUTOMOBILE PARTS & SUPPLIES-RETAIL-NEW',
  'AUTOMOBILE PARTS & SUPPLIES-WHOLESALE',
  'AUTOMOBILE PARTS-USED & REBUILT (WHOL)',
  'AUTOMOBILE PARTS-WAREHOUSES',
  'AUTOMOBILE PERFORMANCE/SPORTS CAR RPR',
  'AUTOMOBILE PIN STRIPING',
  'AUTOMOBILE RACING CAR EQUIPMENT',
  'AUTOMOBILE RACING CARS',
  'AUTOMOBILE RACING EQUIPMENT-WHOLESALE',
  'AUTOMOBILE RACING/SPORTS CAR EQUIP-MFRS',
  'AUTOMOBILE RADIATOR REPAIRING',
  'AUTOMOBILE RADIATOR-MANUFACTURERS',
  'AUTOMOBILE RADIO & STEREO SYSTEMS-MFRS',
  'AUTOMOBILE RADIO & STEREO SYSTEMS-WHOL',
  'AUTOMOBILE RADIO & STEREO SYSTS-SLS/SVC',
  'AUTOMOBILE RADIO/STEREO SYSTS-REPAIRING',
  'AUTOMOBILE RADIOS/STEREOS-EQUIP/ACCESS',
  'AUTOMOBILE REMOTE STARTERS',
  'AUTOMOBILE RENTING & LEASING',
  'AUTOMOBILE REPAIR MANUALS',
  'AUTOMOBILE REPAIR/SVC FACILITIES-RENTING',
  'AUTOMOBILE REPAIR/SVC-EQUIP & SUPLS-MFRS',
  'AUTOMOBILE REPAIR/SVC-EQUIP/SUPL (WHOL)',
  'AUTOMOBILE REPAIR/SVC-FOUR WHEEL DRIVE',
  'AUTOMOBILE REPAIRING & SERVICE',
  'AUTOMOBILE REPAIRING & SERVICE-MOBILE',
  'AUTOMOBILE RESTORATION-ANTIQUE & CLASSIC',
  'AUTOMOBILE SEATCOVERS TOPS & UPHOLSTERY',
  'AUTOMOBILE SEATCOVERS-MANUFACTURERS',
  'AUTOMOBILE SEATCOVERS-WHOLESALE',
  'AUTOMOBILE SMOG BRAKE/LAMP-INSPCTN & RPR',
  'AUTOMOBILE SMOG CONTROL DEVICES',
  'AUTOMOBILE SPEED CONTROLS (WHOLESALE)',
  'AUTOMOBILE STEREO EQUIP & ACCESSORIES',
  'AUTOMOBILE STORAGE',
  'AUTOMOBILE SUNROOFS',
  'AUTOMOBILE SUSPENSIONS',
  'AUTOMOBILE TAG AGENCIES',
  'AUTOMOBILE TRANSPORTERS & DRIVE-AWAY CO',
  'AUTOMOBILE UNDERCOATING & RUSTPROOFING',
  'AUTOMOBILE UNDERCOATING MATERIALS (WHOL)',
  'AUTOMOBILE UPHLSTRY/TOP MTRL/EQUIP-DISTR',
  'AUTOMOBILE UPHOLSTERY CLEANING',
  'AUTOMOBILE WARRANTY PROCESSING SERVICE',
  'AUTOMOBILE WHEEL-MANUFACTURERS',
  'AUTOMOBILE WRECKERS-MANUFACTURERS',
  'AUTOMOBILE WRECKING (WHOLESALE)',
  'AUTOMOBILE-ANTIQUE & CLASSIC',
  'AUTOMOBILE-BUYERS',
  'AUTOMOBILE-MANUFACTURERS',
  'AUTOMOBILE-MANUFACTURERS EQUIP/SUPL-MFRS',
  'AUTOMOBILE-MFRS EQUIP & SUPLS (WHOL)',
  'AUTOMOBILE-NEW CAR PRE-DELIVERY SERVICE',
  'AUTOMOBILE-OIL REFINERS-DISTRIBUTORS',
  'AUTOMOBILE-OIL REFINERS-MANUFACTURERS',
  'AUTOMOBILE-PURCHASING CONSULTANTS',
  'AUTOMOBILE-RECONDITIONING',
  'AUTOMOBILE-STRIPING SERVICE',
  'AUTOMOBILE-WOOD REFINISHING',
  'AUTOMOBILES AND OTHER MOTOR VEHICLES',
  'AUTOMOBILES-FLEET SALES',
  'Automotive',
  'AUTOMOTIVE AND APPAREL TRIMMINGS',
  'Automotive Body, Paint, and Interior Repair and Maintenance ',
  'AUTOMOTIVE CONSULTANTS',
  'AUTOMOTIVE DEALERS',
  'AUTOMOTIVE DEALERS NEC',
  'Automotive Exhaust System Repair',
  'AUTOMOTIVE FOREIGN MANUALS',
  'AUTOMOTIVE GLASS REPLACEMENT SHOPS',
  'AUTOMOTIVE GLASS REPLACEMENT SHOPS',
  'Automotive Glass Replacement Shops',
  'AUTOMOTIVE INFORMATION SERVICES',
  'AUTOMOTIVE INTERIOR LEAK REPAIR',
  'Automotive Oil Change and Lubrication Shops',
  'Automotive Parts and Accessories Stores',
  'AUTOMOTIVE REPAIR SHOPS',
  'AUTOMOTIVE REPAIR SHOPS',
  'AUTOMOTIVE SERVICES',
  'AUTOMOTIVE STAMPINGS',
  'AUTOMOTIVE STAMPINGS (MANUFACTURERS)',
  'AUTOMOTIVE SVCS-EXCEPT RPR & CAR WASHES',
  'Automotive Transmission Repair',
  'AUTOMOTIVE TRANSMISSION REPAIR SHOPS',
  'AUTOMOTIVE TRIMMING/APPRL FINDINGS (MFR)',
  'Autonomous Vehicles',
  'AUTOPSY SERVICE',
  'AVIARIES',
  'Aviation & Aerospace',
  'AVIATION CONSULTANTS',
  'AVIATION FUEL (WHOLESALE)',
  'AVIATION ORGANIZATIONS',
  'AWNINGS & CANOPIES',
  'AWNINGS & CANOPIES-CLEANING',
  'AWNINGS & CANOPIES-MAINTENANCE',
  'AWNINGS & CANOPIES-MANUFACTURERS',
  'AWNINGS & CANOPIES-WHOLESALE',
  'AXLES',
  'AXLES-MANUFACTURERS',
  'B2B',
  'B2C',
  'BABBITTING (MANUFACTURERS)',
  'Baby',
  'BABY ACCESSORIES',
  'BABY ACCESSORIES-RENTAL',
  'BABY ACCESSORIES-WHOLESALE',
  'BABY CARRIAGE SUPPLIES (MANUFACTURERS)',
  'BABY CARRIAGES & STROLLERS',
  'BABY CARRIAGES-MANUFACTURERS',
  'BABY CRIB-RENTAL',
  'BABY FOODS (WHOLESALE)',
  'BABY FORMULA SERVICE',
  'BABY FURNITURE & ACCESSORIES-MFRS',
  'BABY PRODUCTS-WHOLESALE',
  'BABY SITTERS',
  'BACK FLOW PREVENTION DEVICES (WHOLESALE)',
  'BACK SUPPORTS',
  'BACKHOE RENTAL',
  'BACKING CLOTHS-MANUFACTURERS',
  'BACKPACKING & MOUNTAINEERING SCHOOLS',
  'BACKPACKING & MOUNTAINEERING-EQUIP/SUPL',
  'BACTERIOLOGISTS',
  'BADGES (WHOLESALE)',
  'BADGES-MANUFACTURERS',
  'BADGES-MANUFACTURERS SUPPLIES',
  'BADMINTON EQUIPMENT & SUPPLIES (WHOL)',
  'BAG MAKING MACHINERY (MANUFACTURERS)',
  'BAG TIES (MANUFACTURERS)',
  'BAGELS',
  'BAGELS-MANUFACTURERS',
  'BAGELS-WHOLESALE',
  'BAGGAGE TRANSFER',
  'BAGGING SERVICE',
  'BAGGING-COTTON BALE (MANUFACTURERS)',
  'BAGS-BURLAP & COTTON-USED (WHOLESALE)',
  'BAGS-BURLAP CANVAS & COTTON (WHOLESALE)',
  'BAGS-BURLAP CANVAS & COTTON-MFRS',
  'BAGS-GARBAGE-MANUFACTURERS',
  'BAGS-INDUSTRIAL & COMMERCIAL (WHOLESALE)',
  'BAGS-MANUFACTURERS',
  'BAGS-PAPER (MANUFACTURERS)',
  'BAGS-PLASTIC (MANUFACTURERS)',
  'BAGS-PLASTIC-WHOLESALE',
  'BAGS-SPECIALTY (MANUFACTURERS)',
  'BAGS-SPECIALTY-WHOLESALE',
  'BAGS: PLASTIC',
  'BAGS: UNCOATED PAPER AND MULTIWALL',
  'BAIL COMMISSIONERS',
  'BAILIFFS',
  'Baked Goods Stores',
  'BAKERS EQUIPMENT (WHOLESALE)',
  'BAKERS EQUIPMENT-MANUFACTURERS',
  'BAKERS EQUIPMENT-REPAIRING',
  'BAKERS SUPPLIES (WHOLESALE)',
  'BAKERS SUPPLIES-MANUFACTURERS',
  'BAKERS-RETAIL',
  'BAKERS-WHOLESALE',
  'Bakery',
  'BALANCING EQUIPMENT (WHOLESALE)',
  'BALANCING SERVICE-AUTOMOTIVE',
  'BALANCING SERVICE-INDUSTRIAL',
  'BALERS-MANUFACTURERS',
  'BALING EQUIPMENT & SUPLS-MANUFACTURERS',
  'BALING EQUIPMENT & SUPPLIES (WHOLESALE)',
  'BALL & ROLLER BEARING (MANUFACTURERS)',
  'Ball and Roller Bearing Manufacturing',
  'BALL AND ROLLER BEARINGS',
  'BALL SCREWS & SPLINES (WHOLESALE)',
  'BALLOON ADVERTISING',
  'BALLOON DECORATORS',
  'BALLOONS-MANNED',
  'BALLOONS-MANNED-MANUFACTURERS',
  'BALLOONS-MANNED-WHOLESALE',
  'BALLOONS-NOVELTY & TOY-MANUFACTURERS',
  'BALLOONS-NOVELTY & TOY-WHOLESALE',
  'BALLROOMS',
  'BALLS-GLASS (MANUFACTURERS)',
  'BALLS-PLASTIC (MANUFACTURERS)',
  'BALLS-RUBBER (MANUFACTURERS)',
  'BALLS-STEEL BRONZE ETC (MANUFACTURERS)',
  'BALSA WOOD',
  'BAMBOO & WOVEN WOOD PRODUCTS (WHOLESALE)',
  'BAMBOO PRODUCTS-MANUFACTURERS',
  'BAMBOO-RETAIL',
  'BAMBOO-WHOLESALE',
  'BANANAS-WHOLESALE',
  'BANK DESIGNERS & CONSULTANTS',
  'BANK EQUIPMENT & SUPPLIES (WHOLESALE)',
  'BANK EQUIPMENT & SUPPLIES-MANUFACTURERS',
  'BANK HOLDING COMPANIES',
  'BANK PROTECTIVE EQUIPMENT',
  'Banking',
  'BANKING SYSTEMS & SERVICE-ELECTRONIC',
  'BANKRUPTCY SERVICE',
  'BANKS',
  'BANKS-CONSULTING SERVICES',
  'BANNERS',
  'BANQUET ROOMS',
  'BANQUETS-ARRANGED',
  'BAR CODE SCANNING EQUIP & SUPLS (WHOL)',
  'BAR CODING',
  'BAR FIXTURES-MANUFACTURERS',
  'BAR RENTAL',
  'BAR REVIEW COURSES',
  'BAR SUPPLIES (WHOLESALE)',
  'BAR-EQUIP FIXTURES SUPPLIES/CUSTOM MADE',
  'BARBECUE',
  'BARBECUE EQUIPMENT & SUPPLIES (WHOL)',
  'BARBECUE EQUIPMENT & SUPPLIES-MFRS',
  'BARBECUE-WHOLESALE (SAUCE)',
  'BARBER SCHOOLS',
  'BARBER SHOPS',
  'Barber Shops',
  'BARBERS',
  'BARBERS EQUIPMENT & SUPPLIES (WHOLESALE)',
  'BARBERS EQUIPMENT & SUPPLIES-MFRS',
  'Bare Printed Circuit Board Manufacturing',
  'BARGE LINES & TERMINALS',
  'BARGES (MANUFACTURERS)',
  'BARGES-LEASED',
  'BARK',
  'BARN & FEEDLOT CLEANING',
  'BARN EQUIPMENT (WHOLESALE)',
  'BARN EQUIPMENT-MANUFACTURERS',
  'BARNS',
  'BARREL-MANUFACTURERS EQUIP/SUPLS (MFRS)',
  'BARRELS & DRUMS (MANUFACTURERS)',
  'BARRELS & DRUMS-EQUIPMENT & SUPLS (WHOL)',
  'BARRELS & DRUMS-FIBER (WHOLESALE)',
  'BARRELS & DRUMS-PLASTIC (MANUFACTURERS)',
  'BARRELS & DRUMS-USED (WHOLESALE)',
  'BARRELS & DRUMS-WOODEN (MANUFACTURERS)',
  'BARRICADES',
  'BARRICADES-MANUFACTURERS',
  'BARS',
  'BARTENDING SERVICE',
  'BARTER & TRADE EXCHANGE',
  'Baseball',
  'BASEBALL & TRADING CARDS-MANUFACTURERS',
  'BASEBALL BATTING RANGES',
  'BASEBALL CLUBS',
  'BASEBALL DIAMOND CONSTRUCTION',
  'BASEBALL EQUIPMENT & SUPPLIES (WHOL)',
  'BASEBALL SPORTS CARDS & MEMORABILIA',
  'BASEMENT WATERPROOFING',
  'BASEMENT-REPAIR & RESTORATION',
  'Basketball',
  'BASKETBALL CLUBS',
  'BASKETBALL COURT CONSTRUCTION',
  'BASKETBALL EQUIPMENT & SUPPLIES (WHOL)',
  'BASKETS',
  'BASKETS-MANUFACTURERS',
  'BASKETS-USED',
  'BASKETS-WHOLESALE',
  'BATCHING EQUIPMENT (MANUFACTURERS)',
  'BATH EQUIPMENT & SUPPLIES',
  'BATH EQUIPMENT & SUPPLIES-MANUFACTURERS',
  'BATH EQUIPMENT & SUPPLIES-WHOLESALE',
  'BATHING BEACHES',
  'BATHING SUIT MATERIALS (MANUFACTURERS)',
  'BATHING SUITS & ACCESSORIES',
  'BATHING SUITS-MANUFACTURERS',
  'BATHROOM ACCESSORIES-MANUFACTURERS',
  'BATHROOM FIXTURES & ACCESSORIES-RETAIL',
  'BATHROOM FIXTURES & ACCESSORIES-WHOL',
  'BATHROOM REMODELING',
  'BATHS-BATH HOUSES SPAS & SAUNAS',
  'BATHTUBS & SINKS-REPAIRING & REFINISHING',
  'BATON TWIRLING INSTRUCTION',
  'BATTERIES-DRY CELL-MANUFACTURERS',
  'BATTERIES-DRY CELL-WHOLESALE',
  'BATTERIES-MANUFACTURERS',
  'BATTERIES-STORAGE-RETAIL',
  'BATTERIES-STORAGE-WHOLESALE',
  'Battery',
  'BATTERY BACK-UP SYSTEMS (WHOLESALE)',
  'BATTERY CHARGING EQUIPMENT (WHOLESALE)',
  'BATTERY CHARGING EQUIPMENT-MANUFACTURERS',
  'BATTERY CHARGING EQUIPMENT-REPAIRING',
  'BATTERY REPAIRING & REBUILDING (WHOL)',
  'BATTERY SUPPLIES',
  'BATTERY SUPPLIES-WHOLESALE',
  'BATTING (QUILTING MATERIAL-MFRS)',
  'BEACH & CABANA CLUBS',
  'BEACH SUPPLIES',
  'BEACH SUPPLIES-WHOLESALE',
  'BEAD STRINGING',
  'BEADS (WHOLESALE)',
  'BEAMS (MANUFACTURERS)',
  'BEAN BROKERS & DEALERS',
  'BEAN CLEANING',
  'BEAN ELEVATORS',
  'BEAN MILLS',
  'BEANS PEAS LENTILS & ETC-DRIED (MFRS)',
  'BEARINGS (WHOLESALE)',
  'BEARINGS-MANUFACTURERS',
  'BEARINGS-REBABBITTING',
  'Beauty',
  'BEAUTY SALONS',
  'Beauty Salons',
  'BEAUTY SALONS-EQUIPMENT & SUPLS (WHOL)',
  'BEAUTY SALONS-EQUIPMENT & SUPPLIES-MFRS',
  'BEAUTY SCHOOLS',
  'BEAUTY SHOPS',
  'BEAUTY SUPPLIES & EQUIPMENT-REPAIRING',
  'BED & BREAKFAST ACCOMMODATIONS',
  'BED BOARDS (WHOLESALE)',
  'BED FRAMES-METAL (MANUFACTURERS)',
  'BED HEADBOARDS',
  'BED SPRINGS-MANUFACTURERS',
  'BED SPRINGS-WHOLESALE',
  'BED WETTING CONTROL SYSTEMS',
  'Bed-and-Breakfast Inns',
  'BEDDING',
  'BEDDING-MANUFACTURERS',
  'BEDDING-MANUFACTURERS SUPPLIES (MFRS)',
  'BEDDING-WHOLESALE',
  'BEDS-DISAPPEARING',
  'BEDS-HOSPITAL (WHOLESALE)',
  'BEDS-RENTING',
  'BEDS-RETAIL',
  'BEDS-WHOLESALE',
  'BEDSPREADS',
  'BEDSPREADS-MANUFACTURERS',
  'BEDSPREADS-WHOLESALE',
  'BEE REMOVAL',
  'BEEF CATTLE',
  'BEEF CATTLE FEEDLOTS',
  'Beef Cattle Ranching and Farming',
  'BEEKEEPERS',
  'BEEKEEPERS SUPPLIES (WHOLESALE)',
  'BEEPERS EQUIPMENT SERVICE & REPAIR',
  'BEER & ALE-RETAIL',
  'BEER & ALE-WHOLESALE',
  'BEER AND ALE',
  'Beer and Ale Merchant Wholesalers',
  'BEER DISPENSING & COOLING EQUIP (WHOL)',
  'BEER-HOMEBREWING EQUIPMENT & SUPPLIES',
  'Beer, Wine, and Liquor Stores ',
  'BEER/ALE BRWRS/DISTR-NON-ALCOHOLIC (MFR)',
  'BEESWAX (MANUFACTURERS)',
  'BEET SUGAR',
  'BEET SUGAR (MANUFACTURERS)',
  'Beet Sugar Manufacturing',
  'BELLOWS (MANUFACTURERS)',
  'BELTING & BELTING SUPPLIES (WHOLESALE)',
  'BELTING & BELTING SUPPLIES-MANUFACTURERS',
  'BELTS & SUSPENDERS',
  'BELTS & SUSPENDERS-MANUFACTURERS',
  'BELTS & SUSPENDERS-MFRS SUPPLIES-MFRS',
  'BELTS-APPAREL-WHOLESALE',
  'BELTS-MANUFACTURERS SUPPLIES',
  'BELTS-MENS-MANUFACTURERS',
  'BELTS-WOMENS-MANUFACTURERS',
  'BENCH DRAWERS & LEGS-MANUFACTURERS',
  'BENCH DRAWERS & LEGS-RETAIL',
  'BENCH DRAWERS & LEGS-WHOLESALE',
  'BENCHES & WORK TABLES (WHOLESALE)',
  'BENCHES-SEATING (MANUFACTURERS)',
  'BENDING MACHINERY (MANUFACTURERS)',
  'BENTONITE',
  'Berry (except Strawberry) Farming',
  'BERRY CROPS',
  'BERYLLIUM (MANUFACTURERS)',
  'BETTER BUSINESS BUREAUS',
  'BEVERAGE COOLERS (WHOLESALE)',
  'BEVERAGE DISPENSING EQUIP/SUPLS (WHOL)',
  'BEVERAGE DISPENSING EQUIP/SUPLS-MFRS',
  'BEVERAGE POWDERS (MANUFACTURERS)',
  'BEVERAGES (WHOLESALE)',
  'BEVERAGES-HOME DELIVERY',
  'BEVERAGES-MANUFACTURERS',
  'BEVERAGES-NON-ALCOHOLIC-RETAIL',
  'BIBLE CAMPS',
  'BIBLE SCHOOLS & STUDY',
  'BIBLE-MANUFACTURERS',
  'BIBLES',
  'BIBS-ADULT',
  'BICYCLE RACKS & SECURITY SYSTEMS',
  'BICYCLE TOURS',
  'BICYCLES-DEALERS',
  'BICYCLES-DEALERS-USED',
  'BICYCLES-MANUFACTURERS',
  'BICYCLES-PARTS & SUPPLIES',
  'BICYCLES-PARTS & SUPPLIES-MANUFACTURERS',
  'BICYCLES-PARTS & SUPPLIES-WHOLESALE',
  'BICYCLES-RENTING',
  'BICYCLES-REPAIRING',
  'BICYCLES-WHOLESALE',
  'BID REPORTING SERVICE',
  'Big Data',
  'BILL PAYING SERVICE',
  'BILL REVIEWING SERVICE',
  'BILLIARD EQUIPMENT & SUPPLIES (WHOL)',
  'BILLIARD PARLORS',
  'BILLIARD TABLES-MANUFACTURERS',
  'Billing',
  'BILLING SERVICE',
  'BINDERS-LOOSE LEAF (MANUFACTURERS)',
  'BINDERS-LOOSE LEAF-WHOL & MFRS',
  'BINDINGS',
  'BINDINGS-BIAS & STRAIGHT (WHOLESALE)',
  'BINDINGS-MANUFACTURERS EQUIPMENT (MFRS)',
  'BINGO GAMES',
  'BINGO SUPPLIES',
  'BINOCULARS',
  'BINOCULARS-MANUFACTURERS',
  'BINOCULARS-WHOLESALE',
  'BIOFEEDBACK THERAPISTS',
  'Biofuel',
  'Bioinformatics',
  'BIOLOGICAL CLEANING SERVICE',
  'BIOLOGICAL LABORATORY SUPPLIES (WHOL)',
  'Biological Product (except Diagnostic) Manufacturing',
  'BIOLOGICAL PRODUCTS',
  'BIOLOGICAL PRODUCTS (MANUFACTURERS)',
  'BIOLOGICAL SPECIMENS-MANUFACTURERS',
  'BIOLOGICAL SUPPLIES',
  'BIOLOGY CONSULTANTS',
  'Biomass Electric Power Generation',
  'Biomass Energy',
  'Biometrics',
  'Biopharma',
  'Biotechnology',
  'BIOTECHNOLOGY PRODUCTS & SERVICES',
  'BIRD BARRIERS & REPELLENTS & CONTROLS',
  'BIRD FEED-MANUFACTURERS',
  'BIRD FEED-WHOLESALE',
  'BIRD FEEDERS & HOUSES',
  'BIRD FEEDERS & HOUSES-MANUFACTURERS',
  'BIRD HOSPITALS',
  'BIRD SUPPLIES-WHOLESALE',
  'BIRDS',
  'BIRTH ANNOUNCEMENTS',
  'BIRTH CENTERS',
  'BIRTH CERTIFICATE SERVICE',
  'BIRTH PREPARATION SERVICE',
  'Bitcoin',
  'BITS (MANUFACTURERS)',
  'Bituminous Coal and Lignite Surface Mining',
  'BITUMINOUS COAL AND LIGNITE-SURFACE MINING',
  'Bituminous Coal Underground Mining',
  'BITUMINOUS COAL-UNDERGROUND MINING',
  'BITUMINOUS COAL-UNDERGROUND MINING',
  'BLACK LIGHT EQUIPMENT & SUPPLIES',
  'BLACKBOARDS-MANUFACTURERS',
  'BLACKSMITH SHOP SUPPLIES (WHOLESALE)',
  'BLACKSMITHS',
  'BLACKTOPPING',
  'BLADES (MANUFACTURERS)',
  'Blank Magnetic and Optical Recording Media Manufacturing',
  'BLANKBOOKS (MANUFACTURERS)',
  'BLANKBOOKS AND LOOSELEAF BINDERS',
  'BLANKBOOKS LOOSELEAF BNDRS/DEVICES (MFR)',
  'BLANKETS (WHOLESALE)',
  'BLANKETS-MANUFACTURERS',
  'BLAST FURNACES AND STEEL MILLS',
  'BLEACHERS & GRANDSTANDS (WHOLESALE)',
  'BLEACHERY',
  'BLEACHING COMPOUNDS (MANUFACTURERS)',
  'BLEACHING COMPOUNDS-WHOLESALE',
  'Blind and Shade Manufacturing',
  'BLIND INSTITUTIONS',
  'BLIND ORGANIZATIONS',
  'BLINDS-VENETIAN & VERTICAL-MANUFACTURERS',
  'Blockchain',
  'Blogging Platforms',
  'Blood and Organ Banks',
  'BLOOD BANKS & CENTERS',
  'BLOOD PRESSURE CHECKING SERVICE',
  'BLOOD TESTS',
  'BLOUSES-MANUFACTURERS',
  'BLOUSES-RETAIL',
  'BLOUSES-WHOLESALE',
  'BLOW TORCHES (MANUFACTURERS)',
  'BLOWERS & BLOWER SYSTEMS',
  'BLOWERS AND FANS',
  'BLOWERS-MANUFACTURERS',
  'BLUEPRINTING',
  'BLUEPRINTING EQUIPMENT & SUPPLIES (WHOL)',
  'BLUEPRINTING EQUIPMENT & SUPPLIES-MFRS',
  'BLUEPRINTING EQUIPMENT-REPAIRING & SVC',
  'BOARDING HOUSES',
  'BOARDS OF EDUCATION',
  'BOARDS OF TRADE',
  'BOARDSAILING-MANUFACTURERS',
  'BOAT & VAN SEATING-MANUFACTURERS',
  'BOAT & YACHT CUSTOMIZING',
  'BOAT BROKERS',
  'BOAT BUILDERS-MATERIALS',
  'Boat Building',
  'BOAT CLEANING',
  'BOAT CLUBS',
  'BOAT COVERS TOPS & UPHOLSTERY',
  'BOAT COVERS TOPS & UPHOLSTERY-MFRS',
  'BOAT COVERS TOPS & UPHOLSTERY-WHOLESALE',
  'BOAT COVERS TOPS/UPHOLSTERY MTRLS-DISTR',
  'BOAT DEALERS',
  'BOAT DEALERS',
  'Boat Dealers',
  'BOAT DISTRIBUTORS',
  'BOAT DOCKS',
  'BOAT EQUIPMENT & SUPPLIES',
  'BOAT EQUIPMENT & SUPPLIES-MANUFACTURERS',
  'BOAT EQUIPMENT & SUPPLIES-WHOLESALE',
  'BOAT LAUNCHING',
  'BOAT LIFTS (MANUFACTURERS)',
  'BOAT MOORAGES',
  'BOAT PAINTING & LETTERING',
  'BOAT PART-USED & REBUILT',
  'BOAT PERFORMANCE & RACING EQUIPMENT',
  'BOAT REPAIRING',
  'BOAT RESTORATION',
  'BOAT STORAGE',
  'BOAT TRANSPORTING',
  'BOAT WINDOWS',
  'BOAT YARDS',
  'BOATBUILDING AND REPAIRING',
  'Boating',
  'BOATING INSTRUCTION',
  'BOATS-EXCURSIONS',
  'BOATS-LIVERY',
  'BOATS-MANUFACTURERS',
  'BOATS-RENTAL & CHARTER',
  'BOBBIN-MANUFACTURERS',
  'BOBCAT SERVICE',
  'BODY MOVEMENT EDUCATION',
  'BODY PIERCING',
  'BODY WRAP SALONS',
  'BODYGUARD SERVICE',
  'BOILER COMPOUNDS (WHOLESALE)',
  'BOILER SETTING',
  'BOILER TUBES (MANUFACTURERS)',
  'BOILERS-NEW & USED (WHOLESALE)',
  'BOILERS-NEW & USED-MANUFACTURERS',
  'BOILERS-RENTING',
  'BOILERS-REPAIRING & CLEANING',
  'BOILERS-SUPPLIES & PARTS (WHOLESALE)',
  'BOLOGNA-MANUFACTURERS',
  'Bolt, Nut, Screw, Rivet, and Washer Manufacturing ',
  'BOLTS',
  'BOLTS & NUTS AND WASHERS (WHOLESALE)',
  'BOLTS & NUTS-MANUFACTURERS',
  'BOLTS & NUTS-RETAIL',
  'BOLTS NUTS SCREWS RIVETS/WASHERS (MFRS)',
  'BOMB DISPOSAL',
  'BONDING AGENTS',
  'BONDS-BAIL',
  'BONDS-MUNICIPAL & CORPORATE',
  'BONDS-SURETY & FIDELITY',
  'BONES',
  'BONING (WHOLESALE)',
  'BONSAI PLANTS & SUPPLIES',
  'BOOK & CATALOG COVERS (MANUFACTURERS)',
  'BOOK BACKS-IMITATION (MANUFACTURERS)',
  'BOOK DEALERS-PRO TECHNICAL & TRADE',
  'BOOK DEALERS-RETAIL',
  'BOOK DEALERS-USED & RARE',
  'BOOK DEALERS-WHOLESALE',
  'BOOK PRINTING',
  'Book Publishers',
  'BOOK PUBLISHING',
  'BOOK REVIEWERS',
  'BOOK STORES',
  'Book Stores',
  'BOOK-MANUFACTURERS',
  'Book, Periodical, and Newspaper Merchant Wholesalers ',
  'BOOKBINDERS',
  'BOOKBINDERS EQUIPMENT & SUPPLIES (WHOL)',
  'BOOKBINDERS-HOME LIBRARY',
  'BOOKBINDING & RELATED WORK',
  'BOOKBINDING AND RELATED WORK',
  'BOOKCASES',
  'BOOKS',
  'BOOKS ON TAPE',
  'Books Printing',
  'BOOKS-PUBLISHING & PRINTING',
  'BOOT & SHOE-CUT STOCK & FINDINGS (MFRS)',
  'BOOTHS-TELEPHONE (MANUFACTURERS)',
  'BOOTHS-TOLL & PARKING LOT & ETC (MFRS)',
  'BOOTS',
  'BOOTS-MANUFACTURERS',
  'BOOTS-REPAIRING',
  'BOOTS-WHOLESALE',
  'BORAX (MANUFACTURERS)',
  'BORING CONTRACTORS',
  'BOTANICAL AND ZOOLOGICAL GARDENS',
  'BOTANICAL GARDENS',
  'BOTTLE & CAN RECYCLING DEPOTS (WHOL)',
  'BOTTLE CAPS & SEALS (MANUFACTURERS)',
  'BOTTLE OPENERS (MANUFACTURERS)',
  'BOTTLED AND CANNED SOFT DRINKS',
  'Bottled Water Manufacturing',
  'BOTTLERS',
  'BOTTLERS EQUIPMENT & SUPPLIES (WHOL)',
  'BOTTLERS EQUIPMENT & SUPPLIES-MFRS',
  'BOTTLES (MANUFACTURERS)',
  'BOTTLES-PRINTING',
  'BOTTLES-USED (WHOLESALE)',
  'BOTTLES-WHOLESALE',
  'BOUTIQUE ITEMS-MANUFACTURERS',
  'BOUTIQUE ITEMS-RETAIL',
  'BOUTIQUE ITEMS-WHOLESALE',
  'BOWLING APPAREL & ACCESSORIES',
  'BOWLING APPAREL & ACCESSORIES-MFRS',
  'BOWLING APPAREL & ACCESSORIES-WHOLESALE',
  'BOWLING BALL-MANUFACTURERS',
  'Bowling Centers',
  'BOWLING CENTERS',
  'BOWLING CENTERS',
  'BOWLING INSTRUCTION',
  'BOWLING LANE EQUIPMENT & SUPLS (WHOL)',
  'BOWLING LANE REFINISHING & REPAIRING',
  'BOX LUNCHES',
  'BOX LUNCHES-WHOLESALE',
  'BOX OPENERS (MANUFACTURERS)',
  'BOX PARTITIONS (MANUFACTURERS)',
  'BOX-MANUFACTURERS EQUIP & SUPLS (WHOL)',
  'BOX-MANUFACTURERS EQUIP & SUPPLIES-MFRS',
  'BOXES-CORRUGATED & FIBER (WHOLESALE)',
  'BOXES-FOLDING-MANUFACTURERS',
  'BOXES-METAL (WHOLESALE)',
  'BOXES-METAL-MANUFACTURERS',
  'BOXES-PAPER (MANUFACTURERS)',
  'BOXES-PAPER-GLUING & FINISHING (MFRS)',
  'BOXES-PLASTIC & TRANSPARENT (MFRS)',
  'BOXES-SPECIALTY & FANCY (WHOLESALE)',
  'BOXES-SPECIALTY & FANCY-MANUFACTURERS',
  'BOXES-USED (WHOLESALE)',
  'BOXES-WIRE BOUND (MANUFACTURERS)',
  'BOXES-WOODEN (WHOLESALE)',
  'BOXES-WOODEN-MANUFACTURERS',
  'BOXING INSTRUCTION',
  'BOY & GIRL ORGANIZATIONS SUPPLIES',
  'BOYS CLOTHING & FURNISHINGS-MFRS',
  'BOYS CLOTHING & FURNISHINGS-RETAIL',
  'BOYS CLOTHING & FURNISHINGS-WHOLESALE',
  'BRACKETS (MANUFACTURERS)',
  'BRAIDS (MANUFACTURERS)',
  'BRAILLE SUPPLIES',
  'BRAKE FLUIDS',
  'BRAKE FLUIDS-MANUFACTURERS',
  'BRAKE LINING-DISTRIBUTORS',
  'BRAKE LINING-MANUFACTURERS',
  'BRAKE LININGS',
  'BRAKE SERVICE',
  'BRAKE SERVICE EQUIPMENT (WHOLESALE)',
  'BRAKE SERVICE EQUIPMENT-MANUFACTURERS',
  'BRAKE SHOE BONDING & EXCHANGING',
  'BRAKES-DISTRIBUTORS',
  'BRAKES-MANUFACTURERS',
  'BRANCHES & AGENCIES OF FOREIGN BANKS',
  'Brand Marketing',
  'BRANDING EQUIPMENT (MANUFACTURERS)',
  'BRAS',
  'BRASS (MANUFACTURERS)',
  'BRASS MILLS',
  'BRASS PRODUCTS (MANUFACTURERS)',
  'BRASS PRODUCTS-WHOLESALE',
  'BRASS RUBBINGS (MANUFACTURERS)',
  'BRASS-DECORATIVE ACCESSORIES',
  'BRASS-WHOLESALE',
  'BRASSIERES',
  'BRASSIERES GIRDLES/ALLIED GRMNTS (MFRS)',
  'BRASSIERES-MANUFACTURERS',
  'BRASSIERES-WHOLESALE',
  'BRAZING',
  'BREAD',
  'BREAD/OTHER BAKERY PROD-EX COOKIES (MFR)',
  'Breakfast Cereal Manufacturing',
  'BREAKFAST NOOKS & BOOTHS (WHOLESALE)',
  'BREASTFEEDING SUPPLIES & INFORMATION',
  'BREATH DEODORANTS',
  'Breweries',
  'BREWERS',
  'BREWERS EQUIPMENT & SUPPLIES (WHOL)',
  'Brewing',
  'BRICK',
  'BRICK AND STRUCTURAL CLAY TILE',
  "BRICK MAKERS' EQUIPMENT (MANUFACTURERS)",
  'BRICK PAVERS',
  'BRICK PRESERVATIVES (WHOLESALE)',
  'BRICK-CLAY COMMON & FACE (WHOLESALE)',
  'BRICK-CLAY COMMON & FACE-MANUFACTURERS',
  'BRICK-CONCRETE PUMICE & ETC',
  'BRICK-FIRE (WHOLESALE)',
  'BRICK-IMITATION (MANUFACTURERS)',
  'BRICK-MANUFACTURERS',
  'BRICK-USED',
  'Brick, Stone, and Related Construction Material Merchant Wholesalers ',
  'BRIDAL GOWN PRESERVATION SERVICE',
  'BRIDAL GOWNS-WOMENS MISSES & JRS-MFRS',
  'BRIDAL REGISTRIES',
  'BRIDAL SHOPS',
  'BRIDAL SUPPLIES-MANUFACTURERS',
  'BRIDAL SUPPLIES-WHOLESALE',
  'BRIDGE',
  'BRIDGE & TUNNEL OPERATING COMPANIES',
  'BRIDGE BUILDERS',
  'BRIDGE CLUBS',
  'BRIDGE INSTRUCTION',
  'BRIDGE TUNNEL & ELEVATED HIGHWAY',
  'BRINE (MANUFACTURERS)',
  'BRIQUETTES (MANUFACTURERS)',
  'BRISTLES (MANUFACTURERS)',
  'BRITISH FOOD PRODUCTS',
  'BROACHES (MANUFACTURERS)',
  'BROACHING (MANUFACTURERS)',
  'BROACHING TOOLS & EQUIPMENT (WHOLESALE)',
  'Broadcast Media',
  'Broadcasting',
  'BROADCASTING COMPANIES',
  'BROADCASTING EQUIPMENT (MANUFACTURERS)',
  'Broadwoven Fabric Mills',
  'BROADWOVEN FABRIC MILLS',
  'BROADWOVEN FABRIC MILLS',
  'BROADWOVEN FABRIC MILLS',
  'BROADWOVEN FABRIC MILLS-COTTON',
  'BROILER',
  'BROILER FRYER & ROASTER CHICKENS',
  'Broilers and Other Meat Type Chicken Production',
  'BROKERS-COUNTERTRADE',
  'BROKERS-HOTEL MOTEL & APARTMENT HOUSE',
  'BROKERS-MERCHANDISE',
  'BROKERS-MOTOR TRANSPORTATION',
  'BRONZE (MANUFACTURERS)',
  'BRONZE CEMETERY MARKERS',
  'BRONZE CLEANING (MANUFACTURERS)',
  'BRONZE ORNAMENTAL WORK (MANUFACTURERS)',
  'BRONZE POWDERS & LIQUIDS (MFRS)',
  'BRONZE PRODUCTS (MANUFACTURERS)',
  'BRONZE TABLETS (MANUFACTURERS)',
  'BRONZE-SHEET ROD TUBE & ETC (MFRS)',
  'BROOM MANUFACTURERS-EQUIP & SUPLS (MFRS)',
  'Broom, Brush, and Mop Manufacturing ',
  'BROOMS (WHOLESALE)',
  'BROOMS & BRUSHES (MANUFACTURERS)',
  'BROOMS AND BRUSHES',
  'BROOMS-MANUFACTURERS',
  'Browser Extensions',
  'BRUSH REMOVAL SERVICE',
  'BRUSH-MANUFACTURERS',
  'BRUSH-MANUFACTURERS EQUIP/SUPLS (WHOL)',
  'BRUSHES (WHOLESALE)',
  'BRUSHES-RETAIL (HOUSEHOLD)',
  'BUCKETS-DIGGING & HOISTING (WHOLESALE)',
  'BUCKLES (MANUFACTURERS)',
  'BUCKLES-MFRS EQUIPMENT & SUPLS (MFRS)',
  'BUCKRAM (MANUFACTURERS)',
  'BUFFING & POLISHING (MANUFACTURERS)',
  'BUFFING & POLISHING EQUIP & SUPLS (WHOL)',
  'BUILDERS HARDWARE (WHOLESALE)',
  'BUILDERS SERVICE',
  'BUILDERS-CONSTRUCTION CONTROL SERVICE',
  'BUILDING CLEANING-EXTERIOR',
  'BUILDING CLEANING-INTERIOR',
  'BUILDING CLEANING/MAINTENANCE SVCS NEC',
  'BUILDING CONSTRUCTION-CONSULTANTS',
  'BUILDING CONTRACTORS',
  'BUILDING DESIGNERS',
  'BUILDING INSPECTION SERVICE',
  'Building Inspection Services',
  'BUILDING MAINTENANCE',
  'Building Maintenance',
  'BUILDING MAINTENANCE SERVICES',
  'Building Material',
  'BUILDING MATERIALS',
  'Building Materials',
  'BUILDING MATERIALS-MANUFACTURERS',
  'BUILDING MATERIALS-USED',
  'BUILDING MATERIALS-WHOLESALE',
  'BUILDING RESTORATION & PRESERVATION',
  'BUILDING SPECIALTIES',
  'BUILDING SYSTEMS & COMPONENTS',
  'BUILDINGS-AIR SUPPORTED',
  'BUILDINGS-CONCRETE',
  'BUILDINGS-METAL',
  'BUILDINGS-METAL-MANUFACTURERS',
  'BUILDINGS-METAL-REPAIRING',
  'BUILDINGS-METAL-WHOLESALE',
  'BUILDINGS-POLE',
  'BUILDINGS-PORTABLE',
  'BUILDINGS-PRE-CUT PREFAB & MODLR-MFRS',
  'BUILDINGS-PRE-CUT PREFABRICATED-WHOL',
  'BUILDINGS-PRE-CUT PREFABRICATED/MODULAR',
  'BUILDINGS-WOOD (MANUFACTURERS)',
  'BULKHEADS (WHOLESALE)',
  'BULLDOZING',
  'BULLET-RESISTANT EQUIPMENT (WHOLESALE)',
  'BULLETIN & DIRECTORY BOARDS (WHOL)',
  'BUMPER STICKERS',
  'BUOYS-MARINE (MANUFACTURERS)',
  'BUREAUS',
  'BURGLAR ALARM SYSTEMS (WHOLESALE)',
  'BURGLAR ALARM SYSTEMS & MONITORING-MFRS',
  'BURGLAR ALARM SYSTEMS-MONITORING',
  'BURGLAR BARS',
  'BURGLAR RESISTANT EQUIPMENT (WHOLESALE)',
  'BURGLARY & ROBBERY PROTECTIVE SERVICE',
  'Burial Casket Manufacturing',
  'BURIAL CASKETS',
  'BURIAL CASKETS (MANUFACTURERS)',
  'BURIAL VAULTS (WHOLESALE)',
  'BURLAP (WHOLESALE)',
  'BURLAP-MANUFACTURERS',
  'BURLS (WHOLESALE)',
  'BURNERS-INDUSTRIAL (MANUFACTURERS)',
  'BURRING',
  'BURSTING MACHINES (MANUFACTURERS)',
  'Bus and Other Motor Vehicle Transit Systems',
  'BUS CHARTER SERVICE',
  'BUS CONVERSION',
  'BUS LINES',
  'BUS LINES-TICKET AGENCIES',
  'BUS TERMINAL AND SERVICE FACILITIES',
  'BUS TOUR AGENCIES',
  'BUSES-BODIES (MANUFACTURERS)',
  'BUSES-CHARTER & RENTAL',
  'BUSES-DISTRIBUTORS',
  'BUSES-MANUFACTURERS',
  'BUSES-NEW & USED (WHOLESALE)',
  'BUSES-PARTS & SUPPLIES (WHOLESALE)',
  'BUSES-PARTS & SUPPLIES-MANUFACTURERS',
  'BUSES-REPAIRING & SERVICE',
  'BUSES-SCHOOL TRANSPORTATION SERVICE',
  'BUSHINGS (WHOLESALE)',
  'BUSINESS & TRADE ORGANIZATIONS',
  'BUSINESS AND SECRETARIAL SCHOOLS',
  'Business and Secretarial Schools',
  'BUSINESS ASSOCIATIONS',
  'Business Associations',
  'BUSINESS BROKERS',
  'BUSINESS CARDS (MANUFACTURERS)',
  'BUSINESS COLLEGES',
  'BUSINESS CONSULTING',
  'BUSINESS DEVELOPMENT',
  'Business Development',
  'BUSINESS FORMS & SYSTEMS (WHOLESALE)',
  'BUSINESS FORMS & SYSTEMS-MANUFACTURERS',
  'Business Information Systems',
  'Business Intelligence',
  'BUSINESS MANAGEMENT CONSULTANTS',
  'BUSINESS PUBLICATIONS (WHOLESALE)',
  'BUSINESS RECORDS & DOCUMENTS-STORAGE',
  'BUSINESS SERVICES',
  'BUSINESS SERVICES NEC',
  'BUSINESS STRATEGIC PLANNING',
  'Business Supplies and Equipment',
  'BUSINESS SUPPORT SERVICE',
  'BUSINESS TELEVISION (WHOLESALE)',
  'Business to Business Electronic Markets',
  'Business Travel',
  'BUST FORMS-MANUFACTURERS',
  'BUTANE GAS',
  'BUTCHER BLOCKS-HOUSEHOLD (MFRS)',
  'BUTCHERING',
  'BUTCHERS EQUIPMENT & SUPPLIES (WHOL)',
  'BUTTER (WHOLESALE)',
  'BUTTON COVERING',
  'BUTTON MAKERS',
  'BUTTONHOLE MAKERS (MANUFACTURERS)',
  'BUTTONHOLE MAKERS-MANUFACTURERS',
  'BUTTONS-ADVERTISING (WHOLESALE)',
  'BUTTONS-CLOTHING (WHOLESALE)',
  'BUTTONS-MFRS EQUIPMENT & SUPLS (MFRS)',
  'BUYERS INFORMATION SERVICE',
  'BUYERS SERVICE',
  'CABIN RENTALS',
  'CABINET MAKERS',
  'CABINET MAKERS EQUIPMENT & SUPLS (WHOL)',
  'CABINET MAKERS EQUIPMENT & SUPPLIES-MFRS',
  'CABINETS',
  'CABINETS-MANUFACTURERS',
  'CABINETS-METAL (MANUFACTURERS)',
  'CABINETS-RESURFACING & REFINISHING',
  'CABINETS-WHOLESALE',
  'CABINS',
  'CABLE (MANUFACTURERS)',
  'CABLE AND OTHER PAY TELEVISION SERVICES',
  'Cable and Other Subscription Programming',
  'CABLE CONDUIT & POLE-CONTRACTORS',
  'CABLE GRIPS-MANUFACTURERS',
  'CABLE RESEARCH AND DEVELOPMENT',
  'CABLE SPLICING',
  'CABLE TELEVISION EQUIPMENT & REPAIR',
  'CABLE TELEVISION SYSTEMS-CONTRACTORS',
  'CABLE/WIRE-INSTALLATION-VOICE/DATA SYST',
  'CABLES-INSULATED (MANUFACTURERS)',
  'CABS-TRACTOR TRUCK & ETC (WHOLESALE)',
  'CACTI',
  'CAD',
  'CAD SYSTEMS & SERVICES',
  'CADMIUM (MANUFACTURERS)',
  'CAFES',
  'CAFETERIAS',
  'CAFETERIAS-INDUSTRIAL-OPERATORS',
  'Cafeterias, Grill Buffets, and Buffets ',
  'CAGES-ANIMAL (MANUFACTURERS)',
  'CAISSONS-STRUCTURAL',
  'CAKE DECORATING',
  'CAKE DECORATING EQUIPMENT & SUPPLIES',
  'CAKE DECORATING INSTRUCTION',
  'CALCIUM CARBONATE (MANUFACTURERS)',
  'CALCIUM CHLORIDE (WHOLESALE)',
  'CALCULATING & ACCOUNTING MACHINES (MFRS)',
  'CALCULATING & ADDING MACHINES/SUPLS-MFRS',
  'CALCULATING & ADDING MACHS/SUPLS (WHOL)',
  'CALCULATING AND ACCOUNTING EQUIPMENT',
  'CALCULATING CHARTS & TABLES',
  'CALCULATING SERVICE',
  'CALCULATING/ADDING MACHINES/SUPL-RENTING',
  'CALCULATING/ADDING MACHS & SUPLS-SVC/RPR',
  'CALENDARS (WHOLESALE)',
  'CALENDARS-MANUFACTURERS',
  'CALIBRATION DEVICES (MANUFACTURERS)',
  'CALIBRATION SERVICES',
  'Call Center',
  'CALLIGRAPHERS',
  'CALLING PAGING/SIGNAL EQPT/SYSTS (WHOL)',
  'CAMERA AND PHOTOGRAPHIC SUPPLY STORES',
  'CAMPERS & PICK-UP COACHES-DEALERS',
  'CAMPERS & PICK-UP COACHES-DISTRIBUTORS',
  'CAMPERS & PICK-UP COACHES-MANUFACTURERS',
  'CAMPERS & PICK-UP COACHES-RENTING',
  'CAMPERS & PICK-UP COACHES-REPAIR & SVC',
  'CAMPERS-SUPPLIES & PARTS',
  'CAMPGROUNDS',
  'CAMPING CONSULTANTS',
  'CAMPING EQUIPMENT',
  'CAMPING EQUIPMENT-MANUFACTURERS',
  'CAMPING EQUIPMENT-RENTING',
  'CAMPING EQUIPMENT-WHOLESALE',
  'CAMPS',
  'CAMPS-INFORMATION & REFERRAL',
  'CAMS-GRINDING & CUTTING (WHOLESALE)',
  'CAMS-MANUFACTURERS',
  'CAN OPENERS-MANUFACTURERS',
  'CAN SEALING EQUIPMENT (MANUFACTURERS)',
  'CAN-MANUFACTURERS',
  'CAN-MANUFACTURERS MACHINERY (MFRS)',
  'CANCER INFORMATION',
  'CANCER TREATMENT CENTERS',
  'CANDLES',
  'CANDLES-MANUFACTURERS',
  'CANDLES-MFRS EQUIPMENT & SUPLS (WHOL)',
  'CANDLES-WHOLESALE',
  'CANDLESTICKS',
  'CANDY',
  'CANDY & CONFECTIONERY-BROKERS',
  'CANDY & CONFECTIONERY-CONSULTANTS',
  'CANDY & CONFECTIONERY-MANUFACTURERS',
  'CANDY & CONFECTIONERY-MFRS EQUIP (MFRS)',
  'CANDY & CONFECTIONERY-MFRS SUPLS (WHOL)',
  'CANDY & CONFECTIONERY-RETAIL',
  'CANDY & CONFECTIONERY-WHOLESALE',
  'CANDY & COOKIE ARRANGEMENTS',
  'CANDY AND OTHER CONFECTIONERY PRODUCTS',
  'CANDY MAKING SUPPLIES',
  'Cane Sugar Manufacturing',
  'CANE SUGAR REFINING',
  'CANES (WHOLESALE)',
  'CANING',
  'CANING SUPPLIES',
  'Cannabis',
  'Cannabis And Marijuana',
  'CANNED & CURED FISH & SEAFOODS (MFRS)',
  'CANNED AND CURED FISH AND SEAFOODS',
  'CANNED FRUITS AND SPECIALTIES',
  'CANNED SPECIALTIES',
  'CANNED SPECIALTIES (MANUFACTURERS)',
  'CANNING (MANUFACTURERS)',
  'CANNING EQUIPMENT & SUPPLIES (WHOLESALE)',
  'CANOE TRIP OUTFITTERS',
  'CANOES',
  'CANOES-RENTAL & CHARTER',
  'CANOPIES-RENTED',
  'CANS (WHOLESALE)',
  'CANS-FIBER (MANUFACTURERS)',
  'CANVAS & RELATED PRODUCTS (MFRS)',
  'CANVAS AND RELATED PRODUCTS',
  'CANVAS GOODS (WHOLESALE)',
  'CANVAS GOODS-REPAIRING',
  'CANVAS PRODUCTS',
  'CANVAS PRODUCTS-CUSTOM MADE',
  'CANVAS-MANUFACTURERS',
  'CANVAS-WHOLESALE',
  'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing ',
  'CAPACITORS (WHOLESALE)',
  'Capital Markets',
  'CAPPING MACHINERY (MANUFACTURERS)',
  'CAPS (WHOLESALE)',
  'CAPS & GOWNS',
  'CAPTIONING SERVICES-TELEVISION',
  'CAR LOADING & UNLOADING',
  'CAR POOL INFORMATION SERVICE',
  'CAR PULLERS',
  'CAR SERVICE',
  'Car Sharing',
  'Car Washes',
  'CAR WASHING & POLISHING',
  'CAR WASHING & POLISHING EQUIPMENT-MFRS',
  'CAR WASHING & POLISHING-COIN OPERATED',
  'CAR WASHING/POLISHING EQUIP/SUPLS (WHOL)',
  'CARBIDE (WHOLESALE)',
  'CARBIDE LIGHTS (MANUFACTURERS)',
  'CARBIDE METALS & PRODUCTS (WHOLESALE)',
  'CARBIDE METALS & PRODUCTS-MANUFACTURERS',
  'CARBON & GRAPHITE PRODUCTS (MFRS)',
  'Carbon and Graphite Product Manufacturing',
  'CARBON AND GRAPHITE PRODUCTS',
  'CARBON BLACK',
  'CARBON BLACK-MANUFACTURERS',
  'CARBON BRUSHES-MANUFACTURERS',
  'CARBON DIOXIDE (WHOLESALE)',
  'CARBON MONOXIDE TESTING',
  'CARBON PAPER (WHOLESALE)',
  'CARBON PAPER AND INKED RIBBONS',
  'CARBON PAPER-MANUFACTURERS',
  'CARBON PETROLEUM',
  'CARBON PRODUCTS (WHOLESALE)',
  'CARBON TETRACHLORIDE (MANUFACTURERS)',
  'CARBON-ACTIVATED (MANUFACTURERS)',
  'CARBONATORS (WHOLESALE)',
  'CARBONIC GAS (WHOLESALE)',
  'CARBOYS (MANUFACTURERS)',
  'CARBURETORS',
  'CARBURETORS PISTONS RINGS/VALVES (MFRS)',
  'CARBURETORS VALVES',
  'CARBURETORS-MANUFACTURERS',
  'CARBURETORS-WHOLESALE',
  'CARD & PAPER FINISHING (MANUFACTURERS)',
  'CARD KEY SYSTEMS',
  'CARD PLAYING ROOMS',
  'CARDBOARD (WHOLESALE)',
  'CARDIOPULMONARY RESUSCITATION INFO/SVCS',
  'CARDTABLE COVERS (WHOLESALE)',
  'CAREER & VOCATIONAL COUNSELING',
  'Career Planning',
  'CARGO & FREIGHT CONTAINERS (WHOLESALE)',
  'CARGO & FREIGHT CONTAINERS-MANUFACTURERS',
  'CARGO STABILIZING DEVICES (WHOLESALE)',
  'CARNIVAL SUPPLIES (WHOLESALE)',
  'CARNIVALS',
  'CARPENTERS',
  'CARPENTRY WORK',
  'CARPET & RUG CLEANERS',
  'CARPET & RUG CLEANING EQUIP & SUPLS-MFRS',
  'CARPET & RUG CLEANING EQUIP/SUPLS (WHOL)',
  'CARPET & RUG CLEANING EQUIPMENT-RENTAL',
  'CARPET & RUG DEALERS-NEW',
  'CARPET & RUG DEALERS-ORIENTAL',
  'CARPET & RUG DEALERS-USED',
  'CARPET & RUG MAKING-INSTRUCTION & SUPLS',
  'CARPET & RUG PADS LININGS & ACCES-WHOL',
  'CARPET & RUG REPAIRING',
  'CARPET & RUG SERGING',
  'CARPET & RUG-CUSTOM MADE',
  'CARPET & RUG-DISTRIBUTORS',
  'CARPET & RUG-DYERS',
  'CARPET & RUG-FINISHING',
  'CARPET & RUG-MANUFACTURERS',
  'CARPET & RUG-MEASURING SERVICE',
  'CARPET & RUG-PADS & LININGS-MFRS',
  'CARPET & RUG-PADS LININGS & ACCESSORIES',
  'CARPET & RUG-RENTING',
  'CARPET & RUG-STORAGE',
  'CARPET & RUG-WEAVERS',
  'CARPET & UPHOLSTERY PROTECTIVE SERVICE',
  'Carpet and Rug Mills',
  'CARPET AND UPHOLSTERY CLEANING',
  'Carpet and Upholstery Cleaning Services',
  'CARPET LAYERS',
  'CARPET LAYERS EQUIPMENT & SUPLS (WHOL)',
  'CARPET LAYERS EQUIPMENT & SUPPLIES-MFRS',
  'CARPET SWEEPERS',
  'CARPET WORKROOMS',
  'CARPETS AND RUGS',
  'CARPORTS',
  'CARPORTS-MANUFACTURERS',
  'CARPORTS-WHOLESALE',
  'CARRIAGES-HORSE',
  'CARS-MINI',
  'CARS-PEDAL POWER',
  'CARTAGE',
  'CARTS (DISPLAY-WHOLESALE)',
  'CARTS-MOTORIZED',
  'CARWASHES',
  'CASEIN (MANUFACTURERS)',
  'CASES',
  'CASES-CARRYING-MANUFACTURERS',
  'CASH CARDS & OTHER CASH PLANS',
  'CASH DRAWERS (MANUFACTURERS)',
  'CASH GRAINS',
  'CASH GRAINS NEC',
  'CASH REGISTERS & SUPLS-REPAIRING (WHOL)',
  'CASH REGISTERS & SUPPLIES (WHOLESALE)',
  'CASH REGISTERS & SUPPLIES-MANUFACTURERS',
  'Casino',
  'CASINO EQUIPMENT & SUPPLIES (WHOLESALE)',
  'CASINO EQUIPMENT-SERVICE & REPAIR',
  'CASINO GAMBLING INSTRUCTION',
  'Casino Hotels',
  'CASINO TRANSPORTATION',
  'CASINOS',
  'Casinos (except Casino Hotels)',
  'CASKET FABRICS & ACCESSORIES (WHOLESALE)',
  'CASKETS (WHOLESALE)',
  'CASKETS-HARDWARE (MANUFACTURERS)',
  'CASKETS-MANUFACTURERS',
  'CASKETS-PET',
  'CASTERS & GLIDES (WHOLESALE)',
  'CASTERS & GLIDES-MANUFACTURERS',
  'CASTING DIRECTORS',
  'CASTINGS',
  'CASTINGS-ALUMINUM (MANUFACTURERS)',
  'CASTINGS-BRONZE',
  'CASTINGS-CENTRIFUGAL',
  'CASTINGS-FERROUS METALS (MANUFACTURERS)',
  'CASTINGS-INVESTMENT (WHOLESALE)',
  'CASTINGS-IRON (MANUFACTURERS)',
  'CASTINGS-REPAIRING',
  'CASTINGS-RESIN',
  'CASTINGS-STEEL (MANUFACTURERS)',
  'CASTINGS-WHOLESALE',
  'Casual Games',
  'CATALOG AND MAIL-ORDER HOUSES',
  'CATALOG COMPILERS',
  'CATALOG SHOWROOMS',
  'CATALYSTS (MANUFACTURERS)',
  'CATCH BASIN COVERS (MANUFACTURERS)',
  'Caterers',
  'CATERERS',
  'CATERERS EQUIPMENT & SUPPLIES (WHOL)',
  'CATERERS EQUIPMENT & SUPPLIES-MFRS',
  'CATERERS SUPPLIES-RENTING',
  'Catering',
  'CATERING-KOSHER',
  'CATS',
  'CATS-PRODUCTS & SERVICES',
  'CATTLE BRANDING',
  'CATTLE COMPANIES',
  'Cattle Feedlots',
  'CATTLE GUARDS (MANUFACTURERS)',
  'CATTLE RANCHES',
  'CATTLE-REGISTERED',
  'CAULKING CONTRACTORS',
  'CAULKING MATERIALS & EQUIPMENT (WHOL)',
  'CAULKING MATERIALS & EQUIPMENT-MFRS',
  'Cause Marketing',
  'CAVE EXPLORATION EQUIPMENT & SUPPLIES',
  'CAVIAR',
  'CD ROM SERVICES',
  'CEDAR PRODUCTS (WHOLESALE)',
  'CEILING CLEANING',
  'CEILING MATERIALS',
  'CEILING MATERIALS-MANUFACTURERS',
  'CEILING MATERIALS-WHOLESALE',
  'CEILINGS',
  'Celebrity',
  'CELEBRITY BROKERS',
  'CELLOPHANE/CELLULOSE MTRLS/PRODS (MFRS)',
  'CELLULAR TELEPHONES (SERVICES)',
  'CELLULAR TELEPHONES-EQUIPMENT & SUPLS',
  'CELLULAR TELEPHONES-SERVICE & REPAIR',
  'CELLULITE MANAGEMENT',
  'CELLULOSIC MANMADE FIBERS',
  'CELLULOSIC MANMADE FIBERS (MFRS)',
  'CEMENT',
  'Cement Manufacturing',
  'CEMENT-ACID RESISTANT (WHOLESALE)',
  'CEMENT-ASBESTOS',
  'CEMENT-MANUFACTURERS',
  'CEMENT-PORTLAND-RETAIL',
  'CEMENT-PORTLAND-WHOLESALE',
  'CEMENT-RETAIL',
  'CEMENT-WHOLESALE',
  'CEMETERIES',
  'Cemeteries and Crematories',
  'CEMETERY EQUIPMENT & SUPPLIES',
  'CEMETERY LOT BROKERS',
  'CEMETERY LOT CARE & SERVICE',
  'CEMETERY SUBDIVIDERS & DEVELOPERS',
  'CEMETERY SUBDIVIDERS AND DEVELOPERS',
  'CENTER PIVOTS (WHOLESALE)',
  'CENTRAL RESERVE DEPOSITORY',
  'CENTRAL RESERVE DEPOSITORY INSTN NEC',
  'CENTRIFUGAL MACHINERY (MANUFACTURERS)',
  'CERAMIC EQUIPMENT & SUPPLIES',
  'CERAMIC EQUIPMENT & SUPPLIES-MFRS',
  'CERAMIC PRODUCTS-DECORATIVE',
  'CERAMIC PRODUCTS-DECORATIVE-MFRS',
  'CERAMIC PRODUCTS-DECORATIVE-WHOLESALE',
  'CERAMIC PRODUCTS-INDUSTRIAL (MFRS)',
  'CERAMIC PRODUCTS-INDUSTRIAL-WHOLESALE',
  'CERAMIC WALL AND FLOOR TILE',
  'CERAMIC-EQUIPMENT & SUPPLIES-WHOLESALE',
  'CERAMICS-DOMESTIC-STUDIOS SUPPLIES ETC',
  'CERAMICS-INSTRUCTION',
  'CEREAL BREAKFAST FOODS',
  'CEREALS (MANUFACTURERS)',
  'CESSPOOL CLEANING',
  'CHAIN LINK FENCES-MANUFACTURERS',
  'CHAINS (WHOLESALE)',
  'CHAINS-JEWELRY ORNAMENTAL',
  'CHAINS-MANUFACTURERS',
  'CHAIR CANING',
  'CHAIR CANING SUPPLIES',
  'CHAIR SEATS',
  'CHAIRS & TABLES-RENTING',
  'CHAIRS-FOLDING (WHOLESALE)',
  'CHAIRS-MANUFACTURERS',
  'CHAIRS-METAL (WHOLESALE)',
  'CHAIRS-ORTHOPEDIC & LIFT (WHOLESALE)',
  'CHAIRS-RENTING',
  'CHAIRS-RETAIL',
  'CHAIRS-WHOLESALE',
  'CHALK-MANUFACTURERS',
  'CHALK-WHOLESALE',
  'CHALKBOARDS (WHOLESALE)',
  'CHAMBERS OF COMMERCE',
  'CHAMOIS SKINS-MANUFACTURERS',
  'CHAPLAINS',
  'CHARCOAL (WHOLESALE)',
  'CHARITABLE INSTITUTIONS',
  'Charity',
  'Charter Bus Industry',
  'Charter Schools',
  'CHARTS',
  'CHAUFFEUR SERVICE',
  'CHECK CASHING PROTECTION SYSTEMS',
  'CHECK CASHING SERVICE',
  'CHECK COLLECTION SERVICE',
  'CHECK PROTECTION SIGN/ENDRSNG MACH (WHL)',
  'CHECK ROOM EQUIPMENT (WHOLESALE)',
  'CHECKS-METAL FIBER ETC (MANUFACTURERS)',
  'CHECKS-PRINTING (MANUFACTURERS)',
  'CHEESE',
  'CHEESE CUTTERS & GRATERS',
  'Cheese Manufacturing',
  'CHEESE NATURAL AND PROCESSED',
  'CHEESE PROCESSORS',
  'CHEESE-IMPORTING (WHOLESALE)',
  'CHEESE-WHOLESALE',
  'CHEESECLOTH-WHOLESALE',
  'CHEFS-PERSONAL',
  'Chemical',
  'CHEMICAL & FERTILIZER MINING NEC',
  'CHEMICAL AND FERTILIZER MINING',
  'CHEMICAL CLEANING-INDUSTRIAL',
  'CHEMICAL DEPENDENCY TREATMENT CENTERS',
  'Chemical Engineering',
  'CHEMICAL MILLING',
  'CHEMICAL PLANT-EQUIPMENT & SUPLS (WHOL)',
  'CHEMICAL PLANT-EQUIPMENT & SUPPLIES-MFRS',
  'CHEMICAL PREPARATIONS',
  'CHEMICAL RESEARCH',
  'Chemicals',
  'CHEMICALS (WHOLESALE)',
  'CHEMICALS AND ALLIED PRODUCTS',
  'CHEMICALS-BLENDING & GRINDING',
  'CHEMICALS-BOUGHT (WHOLESALE)',
  'CHEMICALS-INDUSTRIAL (WHOLESALE)',
  'CHEMICALS-MANUFACTURERS',
  'CHEMICALS-OIL INDUSTRY (WHOLESALE)',
  'CHEMICALS-RECLAIMING',
  'CHEMICALS-RETAIL',
  'CHEMICALS-SPRAYING',
  'CHEMICALS-STORAGE & HANDLING (WHOL)',
  'CHEMICALS-SURPLUS STOCK BUYERS',
  'CHEMISTS-ANALYTICAL & CONSULTING',
  'CHEMISTS-MANUFACTURING',
  'CHENILLES (MANUFACTURERS)',
  'CHERRIES-MARASCHINO (WHOLESALE)',
  'CHESS CLUBS',
  'CHESS EQUIPMENT & SUPPLIES',
  'CHESS INSTRUCTION',
  'CHEWING AND SMOKING TOBACCO',
  'CHEWING GUM',
  'CHEWING GUM (MANUFACTURERS)',
  'CHICKEN DINNERS',
  'Chicken Egg Production',
  'CHICKEN EGGS',
  'CHICKEN EGGS',
  'CHILD ABUSE INFORMATION & TREATMENT CTRS',
  'Child and Youth Services',
  'Child Care',
  'CHILD CARE CENTERS-CONSULTANTS',
  'CHILD CARE CENTERS-EQUIP & SUPLS (WHOL)',
  'CHILD CARE REFERRAL SERVICES',
  'CHILD CARE SERVICE',
  'CHILD DAY CARE SERVICES',
  'Child Day Care Services',
  'CHILD GUIDANCE',
  'CHILD SUPPORT COLLECTIONS',
  'CHILD-PARENT DEVELOPMENT CENTERS',
  'CHILDBIRTH EDUCATION',
  'Children',
  "Children's and Infants' Clothing Stores",
  "CHILDREN'S AND INFANTS' WEAR STORES",
  'CHILDRENS & INFANTS WEAR-MANUFACTURERS',
  'CHILDRENS & INFANTS WEAR-RETAIL',
  'CHILDRENS & INFANTS WEAR-USED',
  'CHILDRENS & INFANTS WEAR-WHOLESALE',
  'CHILDRENS AFTER SCHOOL PROGRAMS',
  'CHILDRENS GARMENT-MFRS REPRESENTATIVE',
  'CHILDRENS NURSING & REHABILITATION CTRS',
  'CHILDRENS SHOES-RETAIL',
  'CHILDRENS TRANSPORTATION SVC',
  'CHILI & CHILI PRODUCTS (WHOLESALE)',
  'CHILI PARLORS',
  'CHILLERS-LIQUID (MANUFACTURERS)',
  'CHIMES & BELLS (MANUFACTURERS)',
  'CHIMNEY & FIREPLACE CLEANING BUILD/RPR',
  'CHIMNEY BUILDERS & REPAIRERS',
  'CHIMNEY CLEANING EQUIP & SUPLS (WHOL)',
  'CHIMNEY LINING MATERIALS',
  'CHIMNEY LINING MATERIALS-MANUFACTURERS',
  'CHIMNEY MATERIALS',
  'CHIMNEY SCREENS',
  'CHIMNEY TOPS & CAPS',
  'CHIMNEYS-PREFABRICATED',
  'CHINAWARE & GLASSWARE-RENTING',
  'CHINAWARE & GLASSWARE-REPAIRING',
  'CHINAWARE & GLASSWARE-RETAIL',
  'CHINAWARE & GLASSWARE-WHOLESALE',
  'CHINAWARE DECORATING & INSTRUCTION',
  'CHINAWARE DECORATORS (MANUFACTURERS)',
  'CHINAWARE-MANUFACTURERS',
  'CHINCHILLAS',
  'CHINESE FOOD PRODUCTS',
  'CHINESE FOOD PRODUCTS-MANUFACTURERS',
  'CHINESE FOODS-WHOLESALE',
  'CHIPS (MANUFACTURERS)',
  'CHIROPRACTIC DOCTOR INFO BUREAUS',
  'CHIROPRACTORS DC',
  'CHIROPRACTORS EQUIPMENT & SUPPLY (WHOL)',
  'CHLORINATORS',
  'CHLORINE (WHOLESALE)',
  'CHLORINE-MANUFACTURERS',
  'CHOCOLATE & COCOA (WHOLESALE)',
  'CHOCOLATE & COCOA-MANUFACTURERS',
  'CHOCOLATE AND COCOA PRODUCTS',
  'Chocolate and Confectionery Manufacturing from Cacao Beans',
  'CHORAL ORGANIZATIONS',
  'CHOREOGRAPHERS',
  'CHRISTIAN BOOKS & SUPPLIES-RETAIL',
  'CHRISTIAN MINISTRIES',
  'CHRISTIAN SCIENCE PRACTITIONERS',
  'CHRISTIAN SCIENCE READING ROOMS',
  'CHRISTIAN TRACTS (MANUFACTURERS)',
  'CHRISTMAS LIGHTS & DECORATIONS',
  'CHRISTMAS LIGHTS & DECORATIONS-MFRS',
  'CHRISTMAS LIGHTS & DECORATIONS-WHOLESALE',
  'CHRISTMAS STOCKINGS',
  'CHRISTMAS TREE FARMS',
  'CHRISTMAS TREES',
  'CHRISTMAS TREES-ARTIFICIAL (MFRS)',
  'CHRISTMAS TREES-WHOLESALE',
  'CHROME (MANUFACTURERS)',
  'CHROMIUM (WHOLESALE)',
  'CHUCK-MANUFACTURERS',
  'CHURCH BUILDERS',
  'CHURCH CAMPS',
  'CHURCH FINANCING',
  'CHURCH FURNISHINGS (WHOLESALE)',
  'CHURCH FURNITURE-MANUFACTURERS',
  'CHURCH ORGANIZATIONS',
  'CHURCH SUPPLIES',
  'CHURCH SUPPLIES-MANUFACTURERS',
  'CHURCH SUPPLIES-WHOLESALE',
  'CHURCH SUPPORT SERVICE',
  'CHURCH VESTMENTS',
  'CHURCHES',
  'CHUTES-METAL PLATE-MANUFACTURERS',
  'CIDER',
  'CIDER MILLS',
  'CIGAR & CIGARETTE ACCESSORIES-WHOL/MFRS',
  'CIGAR & CIGARETTE LIGHTER FLUIDS',
  'CIGAR & CIGARETTE LIGHTERS (WHOLESALE)',
  'CIGAR & CIGARETTE LIGHTERS-MANUFACTURERS',
  'CIGAR & CIGARETTE LIGHTERS-RETAIL',
  'CIGAR CIGARETTE & TOBACCO DEALERS-RETAIL',
  'CIGAR CIGARETTE & TOBACCO DEALERS-WHOL',
  'CIGAR CIGARETTE & TOBACCO-MANUFACTURERS',
  'CIGAR/CIGARETTE-MFRS EQUIP/SUPLS (MFRS)',
  'CIGARETTE PAPERS & TUBES-MANUFACTURERS',
  'CIGARETTES',
  'CIGARETTES (MANUFACTURERS)',
  'CIGARS',
  'CIGARS (MANUFACTURERS)',
  'CINDER BLOCKS (MANUFACTURERS)',
  'CINDERS (WHOLESALE)',
  'CIRCUIT BOARD ASSEMBLY & REPAIRS (MFRS)',
  'CIRCUIT BOARDS-MANUFACTURERS',
  'CIRCUIT BREAKERS (WHOLESALE)',
  'CIRCUITS-PRINTED & ETCHED-WHOLESALE',
  'CIRCULATION AUDITORS',
  'CIRCULATION BUILDERS',
  'CIRCUS COMPANIES',
  'CISTERN BUILDERS',
  'CITIZENSHIP INSTRUCTION',
  'Citrus (except Orange) Groves',
  'CITRUS BUYERS',
  'CITRUS CONSULTANTS',
  'CITRUS FRUIT PROCESSING',
  'CITRUS FRUIT PRODUCTS',
  'CITRUS FRUIT PRODUCTS-MANUFACTURERS',
  'CITRUS FRUIT PRODUCTS-WHOLESALE',
  'CITRUS FRUITS',
  'CITRUS FRUITS (WHOLESALE)',
  'CITRUS GROVE SERVICE',
  'CITRUS GROVES & MANAGEMENT',
  'CITRUS GROWERS',
  'CITRUS HARVESTING',
  'CITRUS HAULING',
  'CITRUS PACKERS',
  'CITY GOVERNMENT-AGRICULTURAL PROGRAMS',
  'CITY GOVERNMENT-CONSERVATION DEPARTMENTS',
  'CITY GOVERNMENT-COURTS',
  'CITY GOVERNMENT-CREDIT UNIONS',
  'CITY GOVERNMENT-ECONOMIC PROGRAM ADM',
  'CITY GOVERNMENT-EDUCATION PROGRAMS',
  'CITY GOVERNMENT-ENVIRONMENTAL PROGRAMS',
  'CITY GOVERNMENT-EXECUTIVE OFFICES',
  'CITY GOVERNMENT-FINANCE & TAXATION',
  'CITY GOVERNMENT-GENERAL OFFICES',
  'CITY GOVERNMENT-HOUSING PROGRAMS',
  'CITY GOVERNMENT-LEGAL COUNSEL',
  'CITY GOVERNMENT-LIBRARIES',
  'CITY GOVERNMENT-LICENSING & INSPECTION',
  'CITY GOVERNMENT-NATIONAL SECURITY',
  'CITY GOVERNMENT-PUBLIC HEALTH PROGRAMS',
  'CITY GOVERNMENT-SOCIAL & HUMAN RESOURCES',
  'CITY GOVERNMENT-TRANSPORTATION PROGRAMS',
  'CITY GOVERNMENT-URBAN PLANNING & DEV',
  'CITY GOVERNMENT-VETERANS AFFAIRS ADMIN',
  'CITY GOVT-CORRECTIONAL INSTITUTIONS',
  'CITY GOVT-REGULATION/ADM-COMMS/UTILITIES',
  'CITY HALL',
  'CITY REGIONAL & TOWN PLANNERS',
  'Civic & Social Organization',
  'CIVIC AND SOCIAL ASSOCIATIONS',
  'Civic and Social Organizations',
  'CIVIC ORGANIZATIONS',
  'CivicTech',
  'CIVIL DEFENSE AGENCIES',
  'CIVIL DEFENSE EQUIPMENT & SUPPLIES',
  'CIVIL ENFORCEMENT',
  'Civil Engineering',
  'CIVIL SERVICE SCHOOLS',
  'Claims Adjusting',
  'CLAMBAKES',
  'CLAMPS (WHOLESALE)',
  'CLAMPS-MANUFACTURERS',
  'CLAMS (WHOLESALE)',
  'CLASS RINGS (MANUFACTURERS)',
  'Classifieds',
  'CLAY',
  'Clay and Ceramic and Refractory Minerals Mining',
  'CLAY AND RELATED MINERALS',
  'Clay Building Material and Refractories Manufacturing',
  'CLAY CERAMIC & REFRACTORY MINERALS NEC',
  'CLAY PRODUCTS',
  'CLAY REFRACTORIES',
  'CLAY REFRACTORIES (MANUFACTURERS)',
  'CLAY-WHOLESALE',
  'Clean Energy',
  'CLEAN ROOM FACILITIES',
  'CLEAN ROOMS-INSTALLATION & EQUIPMENT',
  'CLEAN ROOMS-SERVICE & CLEANING',
  'CLEANERS',
  'CLEANERS & DYERS EQUIPMENT (WHOLESALE)',
  'CLEANERS & DYERS SUPPLIES (WHOLESALE)',
  'CLEANERS & DYERS-WHOLESALE',
  'CLEANERS-BLANKET',
  'CLEANERS-UPHOLSTERY',
  'CLEANERS-WHOLESALE',
  'CLEANING COMPOUNDS (WHOLESALE)',
  'CLEANING COMPOUNDS-MANUFACTURERS',
  'CLEANING SERVICES-COMMERCIAL/RESIDENTIAL',
  'CLEANING SERVICES-INDUSTRIAL',
  'CLEANING SYSTEMS-PRESSURE CHEMICAL-MFRS',
  'CLEANING SYSTEMS-PRESSURE/CHEM-RENTING',
  'CLEANING SYSTS-PRESSURE CHEM/ETC (WHOL)',
  'CLEANING-ROOF PATIO SIDEWALK & ETC',
  'CleanTech',
  'CLEARING HOUSES',
  'CLERGY',
  'CLERGY & PASTORAL COUNSELING',
  'Clinical Trials',
  'CLINICS',
  'CLIPPING BUREAUS',
  'CLOCK MOVING SERVICE',
  'CLOCKS-COMPONENTS (WHOLESALE)',
  'CLOCKS-DEALERS',
  'CLOCKS-MANUFACTURERS',
  'CLOCKS-MASTER CNTRL/SIGNAL SYSTS (WHOL)',
  'CLOCKS-REPAIRING & PARTS',
  'CLOCKS-WHOLESALE',
  'CLOSED CAPTIONING SERVICE',
  'CLOSET ACCESSORIES-MANUFACTURERS',
  'CLOSETS & CLOSET ACCESSORIES (WHOL)',
  'CLOSETS-DESIGNING & ORGANIZING',
  'CLOSURES-INDUSTRIAL-PROTECTIVE (MFRS)',
  'CLOTH CUTTING',
  'CLOTH CUTTING MACHINES (WHOLESALE)',
  'CLOTH CUTTING MACHINES-MANUFACTURERS',
  'CLOTH SHRINKING & SPONGING',
  'CLOTHES & ACCESSORIES-MENS-MANUFACTURERS',
  'CLOTHES & ACCESSORIES-WOMENS-MFRS',
  'CLOTHES LINE EQUIP & SUPPLIES (MFRS)',
  'CLOTHES POSTS',
  'Clothing Accessories Stores',
  'Clothing and Apparel',
  'CLOTHING-ACTIVEWEAR',
  'CLOTHING-ADAPTIVE',
  'CLOTHING-COLLECTIBLE PERIOD & VINTAGE',
  'CLOTHING-COORDINATING SERVICE',
  'CLOTHING-CUSTOM MADE',
  'CLOTHING-HEALTH CARE',
  'CLOTHING-INSTITUTIONAL',
  'CLOTHING-MANUFACTURERS',
  'CLOTHING-PRINTING & LETTERING',
  'CLOTHING-RETAIL',
  'CLOTHING-USED',
  'CLOTHING-WHOLESALE',
  'Cloud Computing',
  'Cloud Data Services',
  'Cloud Infrastructure',
  'Cloud Management',
  'Cloud Security',
  'Cloud Storage',
  'CLOWN SUPPLIES',
  'CLOWNS',
  'CLUB MEMBERSHIP BROKERS',
  'CLUB ROOM EQUIPMENT (WHOLESALE)',
  'CLUBS',
  'CLUTCHES',
  'CLUTCHES & CLUTCH REPAIR',
  'CLUTCHES-MANUFACTURERS',
  'CMS',
  'CO-GENERATION',
  'CO-GENERATION EQUIPMENT & SUPLS (WHOL)',
  'COACHING',
  'COAL & COKE-RETAIL',
  'COAL & COKE-WHOLESALE',
  'COAL & OTHER MINERALS & ORES (WHOLESALE)',
  'Coal and Other Mineral and Ore Merchant Wholesalers',
  'COAL AND OTHER MINERALS AND ORES',
  'COAL BROKERS',
  'COAL HAULING',
  'COAL LAND LEASES',
  'COAL MINING & SHIPPING',
  'COAL MINING SERVICES',
  'COAL MINING SERVICES',
  'COAL PREPARATION-WASHING EQUIP (WHOL)',
  'COAL WASHING',
  'Coastal and Great Lakes Freight Transportation',
  'Coastal and Great Lakes Passenger Transportation',
  'COAT & APRON SUPPLY SERVICE',
  'COAT FRONTS (MANUFACTURERS)',
  'COAT RACKS-RENTING',
  'COATED & LAMINATED PAPER NEC (MFRS)',
  'COATED FABRICS',
  'COATED FABRICS (WHOLESALE)',
  'COATED FABRICS-NOT RUBBERIZED (MFRS)',
  'COATING ENGRAVING/ALLIED SVCS NEC (MFRS)',
  'COATINGS-PROTECTIVE-APPLICATORS (MFRS)',
  'COATINGS-PROTECTIVE-MANUFACTURERS',
  'COATINGS-PROTECTIVE-WHOLESALE',
  'COATINGS-VACUUM DEPOSITION (MFRS)',
  'COATS OF ARMS',
  'COATS-MANUFACTURERS',
  'COATS-RETAIL',
  'COATS-WHOLESALE',
  'COCKTAIL LOUNGES',
  'COCKTAIL MIXES',
  'COCOA (WHOLESALE)',
  'COCONUT PRODUCTS',
  'CODES',
  'Coffee',
  'COFFEE & FOOD SVC-MOBILE',
  'COFFEE & TEA',
  'COFFEE & TEA PRODUCTS-MANUFACTURERS',
  'COFFEE & TEA-WHOLESALE',
  'Coffee and Tea Manufacturing',
  'COFFEE BREAK SERVICE & SUPPLIES',
  'COFFEE BREAK SERVICE & SUPPLIES-WHOL',
  'COFFEE BREWING DEVICES',
  'COFFEE BREWING DEVICES-REPAIRING',
  'COFFEE BREWING DEVICES-WHOLESALE',
  'COFFEE MILLS',
  'COFFEE ROASTING (WHOLESALE)',
  'COFFEE ROASTING & HANDLING EQUIPMENT',
  'COFFEE ROASTING/HANDLING EQUIPMENT-MFRS',
  'COFFEE SHOPS',
  'COIL TUBING (MANUFACTURERS)',
  'COIL WINDING',
  'COIL WINDING MACHINERY (WHOLESALE)',
  'COILS (WHOLESALE)',
  'COILS-ELECTRIC (WHOLESALE)',
  'COILS-FIN (WHOLESALE)',
  'COILS-PIPE (MANUFACTURERS)',
  'COILS-PLATE (MANUFACTURERS)',
  'COILS-STEAM (WHOLESALE)',
  'COIN & BILL CHANGING MACHINES-WHOLESALE',
  'COIN & BILL CHANGING/HANDLING MACHINES',
  'COIN BANKS (WHOLESALE)',
  'COIN DEALERS SUPPLIES & ETC',
  'COIN DEALERS SUPPLIES ETC-MANUFACTURERS',
  'COIN DEALERS SUPPLIES-WHOLESALE',
  'COIN MACHINES & SUPPLIES',
  'COIN-OPERATED AMUSEMENT DEVICES',
  'COIN-OPERATED LAUNDRIES AND CLEANING',
  'Coin-Operated Laundries and Drycleaners',
  'COIN/BILL CNTNG SORTING/WRAP-MACH (WHOL)',
  'COINED PARTS-METAL (MANUFACTURERS)',
  'COINS & TOKENS-MANUFACTURERS',
  'COLD FINISHING OF STEEL SHAPES',
  'COLD HEADING (MANUFACTURERS)',
  'COLD TYPE COMPOSITION',
  'COLD TYPE COMPOSITION-EQUIP/SUPLS (MFRS)',
  'Collaboration',
  'Collaborative Consumption',
  'COLLAPSIBLE TUBES (MANUFACTURERS)',
  'COLLARS (MANUFACTURERS)',
  'COLLATERAL CERTIFICATION & SUPERVISION',
  'COLLATING SERVICE',
  'COLLECTIBLES',
  'Collectibles',
  'Collection Agencies',
  'COLLECTION AGENCIES',
  'Collection Agency',
  'COLLECTION SYSTEMS',
  'COLLEGE & UNIVERSITY PLACEMENT SERVICE',
  'College Recruiting',
  'COLLEGES AND UNIVERSITIES',
  'Colleges, Universities, and Professional Schools ',
  'COLLETS (MANUFACTURERS)',
  'COLLOIDAL MILLS',
  'COLONIC IRRIGATION',
  'COLONIC THERAPY-APPARATUS & SUPLS (MFRS)',
  'COLOR CARDS (MANUFACTURERS)',
  'COLOR CONSULTANTS',
  'COLOR COPYING',
  'COLOR MATCHING SERVICE & EQUIPMENT',
  'COLOR SEPARATIONS OFFSET PHOTO ETC-WHOL',
  'COLOR SEPARATIONS-GRAPHIC ARTS (MFRS)',
  'COLOR SPRTNS-OFFSET PHOTO ENGRAVE (MFRS)',
  'COLORS & PIGMENTS (WHOLESALE)',
  'COLORS & PIGMENTS-MANUFACTURERS',
  'COLORS-FOOD (MANUFACTURERS)',
  'COLUMBARIUMS',
  'COLUMNS',
  'COMBINATION UTILITIES',
  'COMBS (WHOLESALE)',
  'COMBUSTION & HEATING CONSULTANTS',
  'COMBUSTION CONTROLS (MANUFACTURERS)',
  'COMEDY CLUBS',
  'COMIC BOOKS',
  'Comics',
  'COML IND INSTNL ELEC LIGHTING (MFRS)',
  'COMMENTATORS NARRATORS & ANNOUNCERS',
  'Commerce and Shopping',
  'Commercial',
  'COMMERCIAL AGRICULTURAL CLEANERS (MFRS)',
  'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing ',
  'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
  'Commercial and Institutional Building Construction',
  'COMMERCIAL ART AND GRAPHIC DESIGN',
  'Commercial Bakeries',
  'Commercial Banking',
  'COMMERCIAL BANKS',
  'COMMERCIAL BANKS NEC',
  'COMMERCIAL ECONOMIC EDUCATIONAL RESEARCH',
  'COMMERCIAL EQUIPMENT',
  'COMMERCIAL EQUIPMENT NEC (WHOLESALE)',
  'Commercial Insurance',
  'COMMERCIAL LAUNDRY DRYCLEAN MACHS (MFRS)',
  'COMMERCIAL LAUNDRY EQUIPMENT',
  'Commercial Lending',
  'COMMERCIAL LIGHTING FIXTURES',
  'COMMERCIAL NONPHYSICAL RESEARCH',
  'COMMERCIAL PAPER',
  'COMMERCIAL PHOTOGRAPHY',
  'Commercial Photography',
  'COMMERCIAL PHYSICAL RESEARCH',
  'COMMERCIAL PHYSICAL RESEARCH',
  'COMMERCIAL PRINTING',
  'COMMERCIAL PRINTING',
  'COMMERCIAL PRINTING',
  'Commercial Printing (except Screen and Books)',
  'COMMERCIAL PRINTING NEC',
  'COMMERCIAL PRINTING-GRAVURE',
  'COMMERCIAL PRINTING-LITHOGRAPHIC',
  'Commercial Real Estate',
  'Commercial Screen Printing',
  'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing ',
  'COMMERCIALS-RADIO & TELEVISION',
  'COMMISSARY CONTRACTORS',
  'COMMISSION MERCHANTS',
  'COMMODITY BROKERS',
  'Commodity Contracts Brokerage',
  'COMMODITY CONTRACTS BROKERS',
  'Commodity Contracts Dealing',
  'COMMODITY EXCHANGES',
  'COMMODITY PRICE ANALYSTS',
  'Communication Equipment Repair and Maintenance',
  'COMMUNICATION EQUIPMENT-MANUFACTURERS',
  'Communication Hardware',
  'COMMUNICATION SERVICES',
  'COMMUNICATIONS',
  'COMMUNICATIONS CONSULTANTS',
  'COMMUNICATIONS CONTRACTORS',
  'COMMUNICATIONS EQUIPMENT',
  'COMMUNICATIONS EQUIPMENT (WHOLESALE)',
  'COMMUNICATIONS EQUIPMENT NEC (MFRS)',
  'Communications Infrastructure',
  'COMMUNICATIONS SERVICES NEC',
  'COMMUNICATIONS SERVICES-COMMON CARRIERS',
  'Communities',
  'COMMUNITY ACTION AGENCIES',
  'Community and Lifestyle',
  'COMMUNITY CARE FACILITIES-ADULT CARE',
  'Community Food Services',
  'COMMUNITY ORGANIZATIONS',
  'COMMUNITY SERVICES',
  'COMMUTATOR RESURFACING EQUIPMENT',
  'COMMUTATORS',
  'Commuter Rail Systems',
  'COMPACT DISCS-MANUFACTURERS',
  'COMPACTORS (MANUFACTURERS)',
  'COMPACTORS-EARTH (WHOLESALE)',
  'COMPACTORS-WASTE-HOUSEHOLD-DEALERS',
  'COMPACTORS-WASTE-INDUSTRIAL/COML (MFRS)',
  'COMPACTORS-WASTE-SERVICE & REPAIR',
  'COMPACTORS-WASTE-SUPPLIES & PARTS (WHOL)',
  'COMPACTORS-WASTE-WHOLESALE',
  'COMPARATORS (MANUFACTURERS)',
  'COMPASSES (MANUFACTURERS)',
  'COMPENSATION-ACCIDENT SERVICE',
  'Compliance',
  'COMPOSITES (MANUFACTURERS)',
  'COMPOST (MANUFACTURERS)',
  'COMPOST SHREDDING MACHINES (WHOLESALE)',
  'COMPRESSORS-AIR & GAS (WHOLESALE)',
  'COMPRESSORS-AIR & GAS-RENTING',
  'COMPRESSORS-AIR & GAS-REPAIRING',
  'COMPRESSORS-AIR COND & REFRIG (WHOL)',
  'COMPRESSORS-AIR-MANUFACTURERS',
  'Computer',
  'COMPUTER & ELECTRONIC EQUIPMENT MOVERS',
  'COMPUTER & EQUIPMENT DEALERS',
  'Computer & Network Security',
  'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers',
  'Computer and Office Machine Repair and Maintenance',
  'COMPUTER AND SOFTWARE STORES',
  'COMPUTER ANIMATION',
  'COMPUTER BOOKS-DEALERS',
  'COMPUTER BULLETIN BOARDS',
  'COMPUTER CLEANING',
  'COMPUTER CLUBS',
  'COMPUTER COMMUNICATION TECHNOLOGY',
  'COMPUTER CONSULTANTS',
  'COMPUTER CONSULTANTS-INSTALLATION/REPAIR',
  'COMPUTER DATA STORAGE',
  'COMPUTER DISASTER PLANNING',
  'COMPUTER FACILITIES MANAGEMENT',
  'COMPUTER FACILITIES MANAGEMENT',
  'Computer Facilities Management Services',
  'COMPUTER FURNITURE',
  'Computer Games',
  'COMPUTER GRAPHICS',
  'Computer Hardware',
  'COMPUTER INTEGRATED SYSTEMS DESIGN',
  'COMPUTER INTEGRATED SYSTEMS DESIGN',
  'COMPUTER MAINTENANCE AND REPAIR',
  'COMPUTER MUSIC PRODUCTS',
  'Computer Networking',
  'COMPUTER PARTS & SUPPLIES',
  'COMPUTER PERIPHERAL EQUIPMENT',
  'COMPUTER PERIPHERALS (MANUFACTURERS)',
  'COMPUTER PICTURES-NOVELTY',
  'COMPUTER PRESENTATIONS',
  'COMPUTER PROGRAMMING SERVICES',
  'COMPUTER RELATED SERVICES',
  'COMPUTER RELATED SERVICES NEC',
  'COMPUTER RENTAL AND LEASING',
  'COMPUTER ROOM CLEANING',
  'COMPUTER ROOM FURNITURE & STORAGE SYSTS',
  'COMPUTER ROOMS-INSTALLATION & EQUIPMENT',
  'COMPUTER SERVICES',
  'COMPUTER SOFTWARE',
  'Computer Software',
  'COMPUTER SOFTWARE-MANUFACTURERS',
  'COMPUTER SOFTWARE-WHOLESALE',
  'Computer Storage Device Manufacturing',
  'COMPUTER STORAGE DEVICES',
  'COMPUTER STORAGE DEVICES (MANUFACTURERS)',
  'COMPUTER SUPPLIES & PARTS-MANUFACTURERS',
  'Computer Systems Design Services',
  'Computer Terminal and Other Computer Peripheral Equipment Manufacturing',
  'COMPUTER TERMINALS',
  'COMPUTER TERMINALS (MANUFACTURERS)',
  'COMPUTER TIME SHARING SERVICE',
  'COMPUTER TRAINING',
  'Computer Training',
  'Computer Vision',
  'COMPUTER WIRING',
  'COMPUTER-SOFTWARE DEVELOPERS',
  'COMPUTERS',
  'COMPUTERS VIRUS DETECTION & PREVENTION',
  'COMPUTERS-DEALERS-USED',
  'COMPUTERS-ELECTRONIC-MANUFACTURERS',
  'COMPUTERS-ENHANCEMENTS',
  'COMPUTERS-EQUIPMENT INSTALLATION',
  'COMPUTERS-MULTIMEDIA',
  'COMPUTERS-NETWORKING',
  'COMPUTERS-PROGRAMMING INSTRUCTION',
  'COMPUTERS-RENTING & LEASING',
  'COMPUTERS-SERVICE & REPAIR',
  'COMPUTERS-SOFTWARE DUPLICATION',
  'COMPUTERS-SUPPLIES & PARTS-WHOLESALE',
  'COMPUTERS-SUPPLY-REMANUFACTURING (WHOL)',
  'COMPUTERS-SUPPORT SERVICES',
  'COMPUTERS-SYSTEM DESIGNERS & CONSULTANTS',
  'COMPUTERS-TELEPHONY',
  'COMPUTERS-VIRTUAL REALITY',
  'COMPUTERS-WHOLESALE',
  'COMPUTING DEVICES (WHOLESALE)',
  'COMPUTING SERVICE',
  'CONCERT BUREAUS',
  'Concerts',
  'CONCESSIONAIRES',
  'CONCESSIONAIRES EQUIP & SUPPLIES (WHOL)',
  'CONCIERGE SERVICE',
  'CONCRETE ADDITIVES (WHOLESALE)',
  'CONCRETE ADDITIVES-MANUFACTURERS',
  'CONCRETE AGGREGATES (WHOLESALE)',
  'CONCRETE BLOCK AND BRICK',
  'Concrete Block and Brick Manufacturing',
  'CONCRETE BLOCKS & SHAPES (WHOLESALE)',
  'CONCRETE BREAKING CUT/SAW EQPT (WHOL)',
  'CONCRETE BREAKING CUTTING & SAWING & ETC',
  'CONCRETE BREAKING CUTTING EQUIPMENT-MFRS',
  'CONCRETE CONSTR FORMS & ACCES (WHOL)',
  'CONCRETE CONTRACTORS',
  'CONCRETE CURING & TREATING MTRL (WHOL)',
  'CONCRETE DITCH LINERS',
  'CONCRETE EQUIPMENT & SUPPLIES (WHOL)',
  'CONCRETE EQUIPMENT & SUPPLIES-RENTING',
  'CONCRETE FASTENERS (WHOLESALE)',
  'CONCRETE GRINDING & FINISHING',
  'CONCRETE MIXERS (WHOLESALE)',
  'CONCRETE MIXERS-MANUFACTURERS',
  'CONCRETE MIXERS-RENTING',
  'CONCRETE MIXING PLANT EQUIPMENT (WHOL)',
  'CONCRETE PATCHING COMPOUNDS',
  'CONCRETE PATCHING COMPOUNDS-MFRS',
  'CONCRETE PIPE (MANUFACTURERS)',
  'Concrete Pipe Manufacturing',
  'CONCRETE POST TENSIONING',
  'CONCRETE PRODS-EX BLOCK & BRICK (MFRS)',
  'CONCRETE PRODUCTS',
  'CONCRETE PRODUCTS (WHOLESALE)',
  'CONCRETE PUMPING EQUIPMENT (WHOLESALE)',
  'CONCRETE PUMPING SERVICE',
  'CONCRETE REINFORCEMENTS (WHOLESALE)',
  'CONCRETE REINFORCEMENTS-MANUFACTURERS',
  'CONCRETE RESTORATION REPAIR & CLEANING',
  'CONCRETE RESURFACING',
  'CONCRETE TECHNOLOGISTS',
  'CONCRETE VIBRATORS (WHOLESALE)',
  'CONCRETE WORK',
  'CONCRETE-BLOCK/PROD-MOLDING MACH (MFRS)',
  'CONCRETE-BLOCKS & SHAPES-MANUFACTURERS',
  'CONCRETE-CRUSHED (WHOLESALE)',
  'CONCRETE-DRY MIXED (WHOLESALE)',
  'CONCRETE-FORMS-ERECTORS',
  'CONCRETE-PRECAST',
  'CONCRETE-PRESTRESSED (MANUFACTURERS)',
  'CONCRETE-READY MIXED',
  'CONCRETE-READY MIXED-WHOLESALE',
  'CONCRETE-SEALING & WATERPROOFING',
  'CONCRETE-SOUND INSULATING',
  'CONDENSATION CONTROL',
  'CONDENSERS (WHOLESALE)',
  'CONDENSERS-TUBES & TUBING (MFRS)',
  'CONDIMENTS & SAUCES (WHOLESALE)',
  'CONDIMENTS & SAUCES-MANUFACTURERS',
  'CONDOMINIUM MAINTENANCE',
  'CONDOMINIUMS',
  'CONDOMINIUMS-TIME SHARING',
  'CONDOMS-DISTRIBUTORS',
  'CONDOMS-MANUFACTURERS',
  'CONDUIT CONTRACTORS',
  'CONDUITS & CONDUIT FITTINGS (WHOLESALE)',
  'CONE-SEED & DECORATIVE (MANUFACTURERS)',
  'CONFECTIONERY',
  'Confectionery',
  'Confectionery and Nut Stores',
  'Confectionery Manufacturing from Purchased Chocolate',
  'Confectionery Merchant Wholesalers',
  'CONFERENCE & SEMINAR COORDINATORS',
  'CONFERENCE CENTERS',
  'CONFETTI-MANUFACTURERS',
  'CONFETTI-WHOLESALE',
  'CONGREGATIONS-MESSIANIC',
  'CONNECTORS-ELECTRIC (WHOLESALE)',
  'CONNECTORS-ELECTRIC-MANUFACTURERS',
  'CONSIGNMENT SHOPS',
  'Console Games',
  'CONSTABLES',
  'Construction',
  'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers',
  'CONSTRUCTION AND MINING MACHINERY',
  'CONSTRUCTION COMPANIES',
  'CONSTRUCTION CONSULTANTS',
  'CONSTRUCTION EQUIPMENT-MANUFACTURERS',
  'CONSTRUCTION ESTIMATES',
  'CONSTRUCTION LOANS',
  'CONSTRUCTION MACHINERY',
  'CONSTRUCTION MACHINERY (WHOLESALE)',
  'CONSTRUCTION MACHINERY & EQUIP (MFRS)',
  'Construction Machinery Manufacturing',
  'CONSTRUCTION MANAGEMENT',
  'CONSTRUCTION MATERIALS',
  'CONSTRUCTION MATERIALS NEC (WHOLESALE)',
  'CONSTRUCTION REPORTS',
  'CONSTRUCTION SAND AND GRAVEL',
  'Construction Sand and Gravel Mining',
  'CONSTRUCTION SITE CLEAN-UP SERVICES',
  'CONSTRUCTION-BUILDING CONTRACTORS',
  'CONSTRUCTION-HEAVY PROJECTS',
  'CONSTRUCTION-SAND & GRAVEL',
  'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing ',
  'CONSULTANT BROKERS',
  'CONSULTANTS-BUSINESS NEC',
  'CONSULTANTS-CHEMICAL',
  'CONSULTANTS-EVENT PLANNING/COORDINATING',
  'CONSULTANTS-REFERRAL SERVICE',
  'CONSULTANTS-SECURITY CONTROL',
  'CONSULTANTS-SUPPORT SERVICES',
  'Consulting',
  'CONSULTING SERVICES',
  'Consumer',
  'CONSUMER ADVISORY COUNSELING',
  'Consumer Applications',
  'Consumer Electronics',
  'Consumer Electronics and Appliances Rental',
  'Consumer Electronics Repair and Maintenance',
  'Consumer Goods',
  'Consumer Lending',
  'Consumer Lending',
  'CONSUMER ORGANIZATIONS',
  'CONSUMER PROTECTION AGENCIES',
  'Consumer Research',
  'Consumer Reviews',
  'Consumer Services',
  'Consumer Software',
  'CONTACT LENSES',
  'CONTACT LENSES-MANUFACTURERS',
  'CONTACT LENSES-OPHTHALMOLOGISTS',
  'CONTACT LENSES-OPTOMETRISTS',
  'Contact Management',
  'CONTACT POINTS (MANUFACTURERS)',
  'CONTACTS & PRECIOUS METALS (MFRS)',
  'CONTAINERIZED FREIGHT & CARGO SERVICE',
  'CONTAINERS (MANUFACTURERS)',
  'CONTAINERS-INDUSTRIAL (MANUFACTURERS)',
  'CONTAMINATION CONTROL EQUIP & SUPPLIES',
  'CONTAMINATION CONTROL SERVICES',
  'Content',
  'Content and Publishing',
  'Content Creators',
  'Content Delivery Network',
  'Content Discovery',
  'Content Marketing',
  'Content Syndication',
  'CONTEST ARRANGING & JUDGING',
  'Contests',
  'Continuing Care Retirement Communities',
  'Continuing Education',
  'CONTRACT FURNISHINGS',
  'CONTRACTORS',
  'CONTRACTORS-BOARDING & HOUSING',
  'CONTRACTORS-DESIGN & DEVELOPMENT',
  'CONTRACTORS-ENGINEERING GENERAL',
  'CONTRACTORS-EQUIP/SUPLS-DLRS/SVC (WHOL)',
  'CONTRACTORS-EQUIPMENT & SUPLS-RENTING',
  'CONTRACTORS-EQUIPMENT & SUPLS-REPAIR',
  'CONTRACTORS-EQUIPMENT-MANUFACTURERS',
  'CONTRACTORS-EQUIPMENT-USED (WHOLESALE)',
  'CONTRACTORS-INDUSTRIAL & COMMERCIAL',
  'CONTRACTORS-INTERIOR FURNISHINGS',
  'CONTRACTORS-OIL FIELD',
  'CONTRACTORS-PARKING/HIGHWAY IMPROVEMENT',
  'CONTRACTORS-SITE DEVELOPMENT',
  'CONTRACTORS-TILE',
  'CONTROL PANELS (MANUFACTURERS)',
  'CONTROLS CONTROL SYSTEMS/REGULATORS-MFRS',
  'CONTROLS CONTROL SYSTEMS/REGULATORS-RPR',
  'CONTROLS CONTROL SYSTS/REGULATORS (WHOL)',
  'CONVALESCENT HOMES',
  'CONVALESCENT HOMES PLACEMENT SERVICE',
  'CONVALESCENT SUPPLIES',
  'CONVENIENCE STORES',
  'Convenience Stores',
  'CONVENTION & MEETING FACILITIES & SVC',
  'Convention and Trade Show Organizers',
  'Convention and Visitors Bureaus',
  'CONVENTION INFORMATION BUREAUS',
  'CONVENTS & MONASTERIES',
  'CONVERTED PAPER PRODUCTS',
  'CONVERTED PAPER/PAPERBRD PROD NEC (MFRS)',
  'CONVERTERS-ELECTRIC (WHOLESALE)',
  'Conveyor and Conveying Equipment Manufacturing',
  'CONVEYOR PARTS & SUPPLIES (MFRS)',
  'CONVEYOR-CHAIN PROTECTIVE DEVICES (MFRS)',
  'CONVEYORS & CONVEYING EQUIPMENT (WHOL)',
  'CONVEYORS & CONVEYING EQUIPMENT-MFRS',
  'CONVEYORS AND CONVEYING EQUIPMENT',
  'CONVEYORS-BELTING (MANUFACTURERS)',
  'Cookie and Cracker Manufacturing',
  'COOKIE SHOPS',
  'COOKIES & CRACKERS',
  'COOKIES & CRACKERS-MANUFACTURERS',
  'COOKIES & CRACKERS-WHOLESALE',
  'COOKIES AND CRACKERS',
  'Cooking',
  'COOKING UTENSILS',
  'COOKING UTENSILS-MANUFACTURERS',
  'COOKING UTENSILS-WHOLESALE',
  'COOLER PADS (WHOLESALE)',
  'COOLERS-EVAPORATIVE',
  'COOLERS-EVAPORATIVE-MANUFACTURERS',
  'COOLERS-EVAPORATIVE-SUPLS/PARTS (WHOL)',
  'COOLERS-EVAPORATIVE-WHOLESALE',
  'COOLING SYSTEMS-MACHINE',
  'COOLING TOWERS',
  'COOPERATIVES',
  'COPIERS-RENTING & LEASING',
  'COPPER (WHOLESALE)',
  'COPPER FABRICATORS',
  'COPPER FOUNDRIES',
  'COPPER FOUNDRIES',
  'COPPER ORES',
  'COPPER ORES',
  'COPPER OXIDE (MANUFACTURERS)',
  'COPPER PRODUCTS (WHOLESALE)',
  'COPPER ROLLING AND DRAWING',
  'Copper Rolling, Drawing, Extruding, and Alloying',
  'COPPER-SHEET ROD TUBE ETC (MFRS)',
  'Copper, Nickel, Lead, and Zinc Mining ',
  'COPPERSMITHS',
  'COPPERWARES',
  'COPYING & DUPLICATING MACHINES & SUPLS',
  'COPYING & DUPLICATING SERVICE',
  'COPYING MACHINES & SUPPLIES-MFRS',
  'COPYING MACHINES-SERVICE & REPAIR',
  'COPYWRITERS',
  'CORAL',
  'CORD SETS & CORDS-ELECTRIC (MFRS)',
  'CORD TRIMMING (MANUFACTURERS)',
  'CORDAGE & TWINE (MANUFACTURERS)',
  'CORDAGE AND TWINE',
  'CORDIALS',
  'CORES-FOUNDRY',
  'CORK & CORK PRODUCTS (WHOLESALE)',
  'CORK & CORK PRODUCTS-MANUFACTURERS',
  'CORN',
  'CORN',
  'CORN CHIPS (WHOLESALE)',
  'CORN COB PRODUCTS (WHOLESALE)',
  'Corn Farming',
  'CORN MILLS',
  'CORN PRODUCTS (WHOLESALE)',
  'CORN SHELLING',
  'CORNICES-BUILDING',
  'CORPORATE GIFT SERVICE',
  'CORPORATE LODGING',
  'Corporate Training',
  'Corporate, Subsidiary, and Regional Managing Offices ',
  'CORPORATION SUPPLIES (WHOLESALE)',
  'CORRAL BUILDERS',
  'CORRECTIONAL INSTITUTIONS',
  'Correctional Institutions',
  'CORRECTIONAL INSTITUTIONS-PRIVATE',
  'Corrections Facilities',
  'CORRESPONDENCE SCHOOLS',
  'CORROSION CONTROL',
  'CORRUGATED & SOLID FIBER BOXES (MFRS)',
  'Corrugated and Solid Fiber Box Manufacturing',
  'CORRUGATED AND SOLID FIBER BOXES',
  'CORSET MATERIALS (MANUFACTURERS)',
  'CORSETS & GIRDLES-WHOLESALE',
  'CORSETS GIRDLES & BRASSIERES',
  'CORSETS-MANUFACTURERS',
  'Cosmetic Surgery',
  'Cosmetics',
  'COSMETICS & PERFUMES-RETAIL',
  'COSMETICS-MANUFACTURERS',
  'COSMETICS-PERMANENT',
  'COSMETICS-WHOLESALE',
  'Cosmetics, Beauty Supplies, and Perfume Stores ',
  'COSMETICS/TOILET PREPS-MFRS SUPL (WHOL)',
  'COSMETOLOGISTS',
  'Cosmetology and Barber Schools',
  'COSTUME DESIGNERS',
  'COSTUME FABRICS TRIMMINGS & ACCESS-MFRS',
  'COSTUME FABRICS TRIMMINGS & ACCESSORIES',
  'COSTUME JEWELRY',
  'COSTUME JEWELRY',
  'COSTUME JEWELRY-MANUFACTURERS',
  'COSTUME JEWELRY-WHOLESALE',
  'COSTUME JWLRY/NOVELTIES-EX PRCS (MFRS)',
  'COSTUMES-MASQUERADE & THEATRICAL',
  'COSTUMES-MASQUERADE & THEATRICAL-MFRS',
  'COSTUMES-MASQUERADE & THEATRICAL-WHOL',
  'COSTUMES-RENTING',
  'COTS',
  'COTTAGES & CABINS',
  'COTTON',
  'COTTON',
  'COTTON BROKERS',
  'COTTON BUYERS',
  'COTTON COMPRESSES',
  'Cotton Farming',
  'COTTON FINISHERS',
  'COTTON GINNING',
  'Cotton Ginning',
  'COTTON GINNING EQUIP & SUPPLIES (MFRS)',
  'COTTON GINS',
  'COTTON GOODS-CONVERTERS (WHOLESALE)',
  'COTTON GOODS-MANUFACTURERS',
  'COTTON GOODS-RETAIL',
  'COTTON GOODS-WHOLESALE',
  'COTTON LINTERS',
  'COTTON MILLS',
  'COTTON OIL MILLS',
  'COTTON PICKING MACHINERY',
  'COTTON PROCESSING',
  'COTTON SHIPPERS',
  'COTTON-ABSORBENT (MANUFACTURERS)',
  'COTTON-DUCK (WHOLESALE)',
  'COTTONSEED DELINTING',
  'COTTONSEED OIL MILLS',
  'COTTONSEED PRODUCTS (MANUFACTURERS)',
  'COUNSELING SERVICES',
  'COUNSELORS',
  'COUNSELORS-COUPLES',
  'COUNSELORS-HOLISTIC',
  'COUNSELORS-LICENSED PROFESSIONAL',
  'COUNSELORS-RAPE & SEXUAL ABUSE',
  'COUNTER TOPS',
  'COUNTER TOPS-MANUFACTURERS',
  'COUNTER TOPS-WHOLESALE',
  'COUNTERFEIT MONEY DETECTING DVCS (MFRS)',
  'COUNTING MACHINES (WHOLESALE)',
  'COUNTRY CLUBS',
  'COUNTRY STORES',
  'COUNTY GOVERNMENT-AGRICULTURAL PROGRAMS',
  'COUNTY GOVERNMENT-CONSERVATION DEPTS',
  'COUNTY GOVERNMENT-CORRECTIONAL INSTNS',
  'COUNTY GOVERNMENT-COURTS',
  'COUNTY GOVERNMENT-CREDIT UNIONS',
  'COUNTY GOVERNMENT-ECONOMIC PROGRAM ADM',
  'COUNTY GOVERNMENT-EDUCATION PROGRAMS',
  'COUNTY GOVERNMENT-ENVIRONMENTAL PROGRAMS',
  'COUNTY GOVERNMENT-EXECUTIVE OFFICES',
  'COUNTY GOVERNMENT-FINANCE & TAXATION',
  'COUNTY GOVERNMENT-FIRE PROTECTION',
  'COUNTY GOVERNMENT-GENERAL OFFICES',
  'COUNTY GOVERNMENT-HOUSING PROGRAMS',
  'COUNTY GOVERNMENT-INTERNATIONAL AFFAIRS',
  'COUNTY GOVERNMENT-LEGAL COUNSEL',
  'COUNTY GOVERNMENT-LIBRARIES',
  'COUNTY GOVERNMENT-LICENSING & INSPECTION',
  'COUNTY GOVERNMENT-NATIONAL SECURITY',
  'COUNTY GOVERNMENT-PUBLIC HEALTH PROGRAMS',
  'COUNTY GOVERNMENT-PUBLIC ORDER & SAFETY',
  'COUNTY GOVERNMENT-SOCIAL/HUMAN RESOURCES',
  'COUNTY GOVERNMENT-URBAN PLANNING & DEV',
  'COUNTY GOVERNMENT-VETERANS AFFAIRS ADMIN',
  'COUNTY GOVT-REG & ADM-COMMS/UTILITIES',
  'COUNTY GOVT-TRANSPORTATION PROGRAMS',
  'COUPLINGS (WHOLESALE)',
  'Coupons',
  'Courier Service',
  'COURIER SERVICES',
  'COURIER SERVICES',
  'Couriers and Express Delivery Services',
  'COURT & CONVENTION REPORTERS',
  'Court Reporting and Stenotype Services',
  'COURT REPORTING SCHOOLS',
  'COURTS',
  'COURTS',
  'Courts',
  'COUTURE ART',
  'COVERS-PROTECTIVE',
  'COW CLIPPING',
  'Coworking',
  'CRAB MEAT',
  'CRAFT & CRAFT SUPPLIES-WHOLESALE',
  'Craft Beer',
  'CRAFT GALLERIES & DEALERS',
  'CRAFT SUPPLIES',
  'CRAFT SUPPLIES-MANUFACTURERS',
  'CRAFT-INSTRUCTION',
  'CRAFTS',
  'CRANBERRIES (CANNERS)',
  'CRANBERRY GROWERS',
  'CRANE SERVICE',
  'CRANES & DERRICKS (WHOLESALE)',
  'CRANES-ACCESSORIES & PARTS (WHOLESALE)',
  'CRANES-INSPECTION & TESTING SERVICE',
  'CRANES-MANUFACTURERS',
  'CRANES-OVERHEAD TRAVELING SYSTEMS-MFRS',
  'CRANES-RENTING',
  'CRANES-REPAIRING',
  'CRANKSHAFT GRINDING',
  'CRANKSHAFT-MANUFACTURERS',
  'CRAWFISH FARMS',
  'CRAYONS-MANUFACTURERS',
  'CRAYONS-WHOLESALE',
  'CREAMERIES',
  'CREAMERY BUTTER',
  'CREAMERY BUTTER (MANUFACTURERS)',
  'Creamery Butter Manufacturing',
  'Creative Agency',
  'Credit',
  'CREDIT & DEBT COUNSELING SERVICES',
  'Credit Bureau',
  'Credit Bureaus',
  'CREDIT CARD & OTHER CREDIT PLANS',
  'Credit Card Issuing',
  'CREDIT CARD-MERCHANT SERVICES',
  'CREDIT CARD-PROTECTION SERVICE',
  'CREDIT CARD-TERMINAL SYSTEMS (WHOLESALE)',
  'CREDIT CARD/CREDIT PLNS EQPT/SUPL (WHOL)',
  'CREDIT CARD/OTHER PLANS EQUIP/SUPL-MFRS',
  'Credit Cards',
  'CREDIT CARDS-PLASTIC METAL ETC-DISTR',
  'CREDIT INVESTIGATORS',
  'CREDIT RATING CORRECTION SERVICE',
  'CREDIT REPORTING AGENCIES',
  'CREDIT REPORTING SERVICES',
  'CREDIT RESTORATION',
  'CREDIT UNIONS',
  'Credit Unions',
  'CREDIT UNIONS NOT FEDERALLY CHARTERED',
  'CREMATORIES',
  'CREOSOTE (MANUFACTURERS)',
  'CREOSOTED PRODUCTS',
  'Cricket',
  'CRIME PREVENTION PROGRAMS',
  'CRIME VICTIM SERVICES',
  'CRIME VICTIMS',
  'CRIMINOLOGISTS',
  'CRISIS INTERVENTION SERVICE',
  'CRITICAL PATH SCHEDULING',
  'CRM',
  'CROP HARVESTING',
  'CROP HARVESTING-PRIMARILY BY MACHINE',
  'Crop Harvesting, Primarily by Machine ',
  'CROP PLANTING AND PROTECTION',
  'CROP PREPARATION SERVICES FOR MARKET',
  'CROP PREPARATION SERVICES FOR MARKET',
  'CROP PROTECTION PRODUCTS',
  'CROQUET CLUBS',
  'Crowdfunding',
  'Crowdsourcing',
  'CROWNS & CLOSURES (MANUFACTURERS)',
  'CROWNS AND CLOSURES',
  'CRUCIBLES (MANUFACTURERS)',
  'CRUDE PETROLEUM AND NATURAL GAS',
  'Crude Petroleum Extraction',
  'CRUDE PETROLEUM PIPELINES',
  'CRUDE PETROLEUM PIPELINES',
  'CRUISE CONTROLS-AUTOMOTIVE',
  'CRUISES',
  'CRUSHED & BROKEN GRANITE',
  'CRUSHED & BROKEN LIMESTONE',
  'CRUSHED & BROKEN STONE NEC',
  'CRUSHED AND BROKEN GRANITE',
  'Crushed and Broken Granite Mining and Quarrying',
  'CRUSHED AND BROKEN LIMESTONE',
  'Crushed and Broken Limestone Mining and Quarrying',
  'CRUSHED AND BROKEN STONE',
  'CRUSHING & PULVERIZING EQUIP (WHOLESALE)',
  'CRUSHING & PULVERIZING SERVICE (WHOL)',
  'CRUTCHES',
  'CRYOGENIC EQUIPMENT & SUPPLIES (MFRS)',
  'CRYOGENIC PROCESSING',
  'CRYOGENIC RESEARCH & DEVELOPMENT',
  'CRYONIC SERVICES',
  'Cryptocurrency',
  'CRYSTAL QUARTZ (MANUFACTURERS)',
  'CRYSTALS-INDUSTRIAL (MANUFACTURERS)',
  'CULVERTS',
  'CUPOLAS (WHOLESALE)',
  'CURIOS',
  'CURLING CLUBS',
  'CURRENCY EXCHANGES',
  'Current-Carrying Wiring Device Manufacturing',
  'CURRENT-CARRYING WIRING DEVICES',
  'CURRENT-CARRYING WIRING DEVICES (MFRS)',
  'Curtain and Linen Mills',
  'CURTAIN WALLS',
  'CURTAIN WALLS-MANUFACTURERS',
  'CURTAINS AND DRAPERIES',
  'CURTAINS-MANUFACTURERS',
  'CURTAINS-SHOWER & BATH-MANUFACTURERS',
  'CURTAINS-SHOWER & BATH-WHOLESALE',
  'CUSHIONS (UPHOLSTERY SERVICE)',
  'CUSHIONS-MANUFACTURERS',
  'CUSTODIAL HOMES',
  'Custom Architectural Woodwork and Millwork Manufacturing',
  'CUSTOM COMBINING',
  'CUSTOM COMPOUND PURCHASED RESINS',
  'Custom Compounding of Purchased Resins',
  'CUSTOM COMPOUNDING-PURCHASED RESIN (MFR)',
  'CUSTOM COMPUTER PROGRAMMING SERVICES',
  'Custom Computer Programming Services',
  'CUSTOM FARMING',
  'Custom Roll Forming',
  'Customer Service',
  'CUSTOMS BROKERS',
  'CUSTOMS CONSULTANTS',
  'Cut and Sew Apparel Contractors',
  'Cut Stock, Resawing Lumber, and Planing ',
  'CUT STONE & STONE PRODUCTS (MFRS)',
  'Cut Stone and Stone Product Manufacturing',
  'CUT STONE AND STONE PRODUCTS',
  'CUTLERY',
  'CUTLERY-MANUFACTURERS',
  'CUTLERY-MANUFACTURERS (TABLEWARE)',
  'CUTLERY-RETAIL',
  'CUTLERY-WHOLESALE',
  'CUTTING & SLITTING SVC',
  'CUTTING BLOCKS (MANUFACTURERS)',
  'CUTTING COMPOUNDS (MANUFACTURERS)',
  'CUTTING ROOM EQUIPMENT & SUPPLIES (MFRS)',
  'Cutting Tool and Machine Tool Accessory Manufacturing',
  'CUTTING TOOLS/ACCES/MEASURING DVCS (MFR)',
  'Cyber Security',
  'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing ',
  'CYCLIC CRUDES AND INTERMEDIATES',
  'CYCLIC ORGANIC CRUDES/DYES/PGMNTS (MFRS)',
  'Cycling',
  'CYLINDER HEADS-MANUFACTURERS',
  'CYLINDERS-AIR & HYDRAULIC (WHOLESALE)',
  'CYLINDERS-AIR & HYDRAULIC-MANUFACTURERS',
  'CYLINDERS-ELECTRIC (MANUFACTURERS)',
  'CYLINDERS-GAS (MANUFACTURERS)',
  'CYLINDERS-REBUILDING & REPAIRING',
  'DAIRIES',
  'Dairy',
  'Dairy Cattle and Milk Production',
  'DAIRY CONSULTANTS',
  'DAIRY EQUIPMENT & SUPPLIES (WHOLESALE)',
  'DAIRY EQUIPMENT & SUPPLIES-MANUFACTURERS',
  'DAIRY EQUIPMENT & SUPPLIES-REPAIRING',
  'DAIRY FARMS',
  'DAIRY FARMS',
  'Dairy Product (except Dried or Canned) Merchant Wholesalers',
  'DAIRY PRODUCTS',
  'DAIRY PRODUCTS STORES',
  'DAIRY PRODUCTS-RETAIL',
  'DAIRY PRODUCTS-WHOLESALE',
  'DAMPERS (MANUFACTURERS)',
  'DANCE CLUBS',
  'DANCE COMPANIES',
  'Dance Companies',
  'DANCE STUDIOS',
  'DANCING INSTRUCTION',
  'DANCING SUPPLIES',
  'DANCING SUPPLIES-MANUFACTURERS',
  'DANCING SUPPLIES-WHOLESALE',
  'DANCING-PUBLIC',
  'DARK ROOM RENTAL',
  'Darknet',
  'DARTS & DART BOARDS',
  'DARTS & DART BOARDS-MANUFACTURERS',
  'DARTS & DART BOARDS-WHOLESALE',
  'Data and Analytics',
  'Data Center',
  'Data Center Automation',
  'DATA COMMUNICATION SERVICE',
  'DATA COMMUNICATIONS EQUIP/SYSTEMS (WHOL)',
  'DATA COMMUNICATIONS SYSTEMS-CABLING',
  'DATA COMMUNICATIONS SYSTEMS-NETWORKS',
  'DATA COMMUNICATIONS SYSTEMS-SVC/REPAIR',
  'Data Integration',
  'Data Mining',
  'DATA PROCESSING AND PREPARATION',
  'DATA PROCESSING EQUIPMENT (WHOLESALE)',
  'DATA PROCESSING EQUIPMENT-MAINTENANCE',
  'DATA PROCESSING EQUIPMENT-MANUFACTURERS',
  'DATA PROCESSING SCHOOLS',
  'DATA PROCESSING SERVICE',
  'DATA PROCESSING SUPPLIES (WHOLESALE)',
  'Data Processing, Hosting, and Related Services',
  'DATA RETRIEVAL SERVICES',
  'Data Storage',
  'DATA STORAGE EQUIPMENT & SYSTEMS',
  'DATA SYSTEMS CONSULTANTS & DESIGNERS',
  'Data Visualization',
  'Database',
  'DATES-EDIBLE',
  'Dating',
  'DATING SERVICES',
  'DAY CARE CENTERS-ADULT',
  'DEAF COMMUNICATION AIDS',
  'DEAF SERVICES & FACILITIES',
  'Debit Cards',
  'DEBT ADJUSTERS',
  'DEBT BUYERS',
  'Debt Collections',
  'DEBURRING',
  'DECALS (MANUFACTURERS)',
  'DECALS-WHOLESALE',
  'DECIDUOUS TREE FRUITS',
  'DECK BUILDERS',
  'DECOLLATING MACHINES (WHOLESALE)',
  'DECORATING CONTRACTORS-PARTY/CONVENTION',
  'DECORATION SUPPLIES-MANUFACTURERS',
  'DECORATION SUPPLIES-WHOLESALE',
  'DECOYS',
  'DEDIMUS JUSTICES',
  'DEEP SEA DOMESTIC TRANSPORTATION OF FREIGHT',
  'DEEP SEA FOREIGN TRANSPORTATION OF FREIGHT',
  'Deep Sea Freight Transportation',
  'DEEP SEA PASSENGER TRANSPORTATION',
  'Deep Sea Passenger Transportation',
  'DEEP SEA-DOMESTIC TRANSPORT OF FREIGHT',
  'DEEP SEA-FOREIGN TRANSPORT OF FREIGHT',
  'DEEP SEA-PASSENGER TRANS-EXCEPT FERRY',
  'DEER BARRIERS & REPELLANTS',
  'Defense & Space',
  'DEFENSE CONTRACTORS',
  'DEGREASING EQUIPMENT & SUPPLIES (WHOL)',
  'DEHUMIDIFYING EQUIPMENT',
  'DEHUMIDIFYING EQUIPMENT-MANUFACTURERS',
  'DEHUMIDIFYING EQUIPMENT-WHOLESALE',
  'DEHYDRATED FRUITS',
  'DEHYDRATING EQUIPMENT & SUPPLIES (WHOL)',
  'DEHYDRATING SERVICE',
  'DELI-BAKERY',
  'DELICATESSEN PRODUCTS (MANUFACTURERS)',
  'DELICATESSENS',
  'DELICATESSENS-WHOLESALE',
  'Delivery',
  'DELIVERY SERVICE',
  'Delivery Service',
  'DEMOLITION CONSULTANTS',
  'DEMOLITION CONTRACTORS',
  'DEMONSTRATION SERVICE-MERCHANDISE',
  'Dental',
  'DENTAL EQUIPMENT & SUPPLIES-LEASING',
  'DENTAL EQUIPMENT & SUPPLIES-MFRS',
  'DENTAL EQUIPMENT & SUPPLIES-WHOLESALE',
  'DENTAL EQUIPMENT AND SUPPLIES',
  'Dental Equipment and Supplies Manufacturing',
  'DENTAL EQUIPMENT-MANUFACTURERS',
  'DENTAL EQUIPMENT-REPAIRING & REFINISHING',
  'DENTAL HYGIENISTS',
  'DENTAL LABORATORIES',
  'Dental Laboratories',
  'DENTAL PRACTICE-MANAGEMENT & CONSULTANTS',
  'DENTAL SCHOOLS',
  'DENTIFRICES (MANUFACTURERS)',
  'DENTIST INFORMATION BUREAUS',
  'DENTISTS',
  'DENTISTS SERVICE ORGANIZATIONS',
  'DENTURE REPAIR SERVICE',
  'DENTURISTS',
  'DEODORANTS (WHOLESALE)',
  'DEODORANTS-MANUFACTURERS',
  'DEODORIZING & DISINFECTING',
  'DEODORIZING EQUIPMENT (WHOLESALE)',
  'DEPARTMENT STORES',
  'DEPARTMENT STORES',
  'Department Stores',
  'DEPTH SOUNDERS-SALES & SERVICE',
  'Design',
  'DESIGNERS',
  'DESIGNERS-ARCHITECTURAL',
  'DESIGNERS-INDUSTRIAL',
  'DESK PADS & ACCESSORIES',
  'DESKS (WHOLESALE)',
  'Desktop Apps',
  'DESKTOP PUBLISHING EQUIPMENT',
  'DESSERT PREPARATIONS',
  'DESTINATION MANAGEMENT',
  'DETECTIVE AGENCIES',
  'DETECTIVE AND ARMORED CAR SERVICES',
  'DETECTIVE GUARD & ARMORED CAR SERVICES',
  'DETECTIVES-PRIVATE',
  'Developer APIs',
  'Developer Platform',
  'Developer Tools',
  'DEVELOPMENTAL ACHIEVEMENT CENTERS',
  'DEVELOPMENTALLY DISABLED INFO/SVCS',
  'DEVOTION FOR THE DAY',
  'DEWATERING CONTRACTORS',
  'DEXTRINE',
  'Diabetes',
  'DIABETES INFORMATION CENTERS',
  'DIABETIC SUPPLIES',
  'Diagnostic Imaging Centers',
  'DIAGNOSTIC SUBSTANCES',
  'DIAL-A-RIDE',
  'DIALS (MANUFACTURERS)',
  'DIALYSIS',
  'DIALYSIS EQUIPMENT SERVICES',
  'DIAMOND BROKERS',
  'DIAMOND BUYERS (WHOLESALE)',
  'DIAMOND CUTTERS EQUIP & SUPLS (MFRS)',
  'DIAMOND CUTTING',
  'DIAMOND DRILLING',
  'DIAMOND IRRADIATION (MANUFACTURERS)',
  'DIAMOND SETTERS',
  'DIAMOND TOOLS (MANUFACTURERS)',
  'DIAMONDS',
  'DIAMONDS & JEWELRY BOUGHT',
  'DIAMONDS-INDUSTRIAL (WHOLESALE)',
  'DIAMONDS-WHOLESALE',
  'DIAPER SERVICE',
  'DIAPER-MANUFACTURERS',
  'DIAPERS-WHOLESALE',
  'DIAPHRAGMS-INDUSTRIAL (MANUFACTURERS)',
  'DIATOMACEOUS EARTH (MANUFACTURERS)',
  'DICTATING MACHINE TRANSCRIBING',
  'DICTATING MACHINES & SUPPLIES (WHOL)',
  'DICTATING MACHINES-SERVICE & REPAIR',
  'DIE CASTING MACHINERY (MANUFACTURERS)',
  'DIE CASTING METALS (MANUFACTURERS)',
  'DIE CASTINGS (WHOLESALE)',
  'DIE CUSHIONS-PNEUMATIC (MANUFACTURERS)',
  'DIE CUTTING (MANUFACTURERS)',
  'DIE CUTTING EQUIPMENT & SUPPLIES (MFRS)',
  'DIE MAKERS',
  'DIE MAKERS EQUIPMENT & SUPPLIES-MFRS',
  'DIE SINKING (MANUFACTURERS)',
  'DIE-CUT PAPER & PAPERBOARD (MFRS)',
  'DIE-CUT PAPER AND BOARD',
  'DIES (MANUFACTURERS)',
  'DIESEL FUEL (WHOLESALE)',
  'Diet and Weight Reducing Centers',
  'Dietary Supplements',
  'DIETITIANS',
  'DIFFUSERS-AIR (MANUFACTURERS)',
  'Digital Entertainment',
  'Digital Marketing',
  'Digital Media',
  'Digital Signage',
  'DIMENSION STONE',
  'DIMENSION STONE',
  'Dimension Stone Mining and Quarrying',
  'DINETTE SETS (MANUFACTURERS)',
  'DINING CAR BUILDERS',
  'DINING CLUB PLANS',
  'DINNERWARE-PLASTIC (MANUFACTURERS)',
  'DIORAMAS',
  'DIPLOMAS',
  'Direct Health and Medical Insurance Carriers',
  'Direct Life Insurance Carriers',
  'Direct Mail Advertising',
  'DIRECT MAIL ADVERTISING SERVICES',
  'DIRECT MAILERS & RELATED-NEC',
  'Direct Marketing',
  'Direct Property and Casualty Insurance Carriers',
  'Direct Sales',
  'DIRECT SELLING ESTABLISHMENTS',
  'DIRECT SELLING ESTABLISHMENTS',
  'Direct Title Insurance Carriers',
  'Directory and Mailing List Publishers',
  'DIRT CONTRACTORS',
  'DISABILITY ACCESSIBILITY COMPLIANCE',
  'DISABILITY ACCESSIBILITY RENOVATIONS',
  'DISABILITY SERVICES',
  'DISABLED & HANDICAPPED EMPLOYMENT SVC',
  'DISABLED PERSONS EQUIPMENT & SUPPLIES',
  'DISASTER PREPAREDNESS',
  'DISC JOCKEY EQUIPMENT & SUPPLIES',
  'DISCING-ROTARY CULTIVATING & PLOWING SVC',
  'DISCOTHEQUES',
  'DISCOUNT CARDS COUPONS & STAMP COMPANIES',
  'DISHWASHING COMPOUNDS (WHOLESALE)',
  'DISHWASHING MACHINES-COMMERCIAL (WHOL)',
  'DISHWASHING MACHINES-DEALERS',
  'DISHWASHING MACHINES-MANUFACTURERS',
  'DISHWASHING MACHINES-RENTING & LEASING',
  'DISHWASHING MACHINES-SERVICE & REPAIR',
  'DISHWASHING MACHINES-SUPLS/PARTS (WHOL)',
  'DISHWASHING MACHINES-WHOLESALE',
  'DISINFECTANTS & GERMICIDES (WHOLESALE)',
  'DISINFECTANTS & GERMICIDES-MANUFACTURERS',
  'DISINFECTING AND PEST CONTROL SERVICES',
  'DISPENSING DEVICES (WHOLESALE)',
  'DISPENSING DEVICES-MANUFACTURERS',
  'DISPLAY DESIGNERS & PRODUCERS',
  'DISPLAY DESIGNERS & PRODUCERS-MFRS',
  'DISPLAY DESIGNERS & PRODUCERS-WHOLESALE',
  'DISPLAY DSGNRS/PRODUCERS-EXPO CONVENTION',
  'DISPLAY FIXTURES & MATERIALS (WHOLESALE)',
  'DISPLAY FIXTURES & MATERIALS-MFRS',
  'DISPLAY FIXTURES & MATERIALS-USED (WHOL)',
  'DISPLAY INSTALLATION SERVICE',
  'DISPOSABLE GARMENTS (MANUFACTURERS)',
  'DISPOSABLE PRODUCTS (WHOLESALE)',
  'DISPOSABLE PRODUCTS-MANUFACTURERS',
  'DISPOSAL WELLS',
  'DISTILLED AND BLENDED LIQUORS',
  'Distilleries',
  'DISTILLERS',
  'DISTILLERS REPRESENTATIVES',
  'Distillery',
  'DISTILLERY EQUIPMENT & SUPPLIES (WHOL)',
  'DISTRIBUTING SERVICE-CIRCULAR & SAMPLE',
  'DISTRIBUTION CENTERS',
  'DISTRIBUTION SERVICES',
  'DISTRIBUTOR-GROCERIES',
  'DITCHING CONTRACTORS',
  'DIVERS',
  'DIVERS EQUIPMENT & SUPPLIES',
  'DIVERS EQUIPMENT & SUPPLIES-MFRS',
  'DIVERS EQUIPMENT & SUPPLIES-WHOLESALE',
  'Diving',
  'DIVING EXCURSION PACKAGES',
  'DIVING INSTRUCTION',
  'DIVORCE ASSISTANCE',
  'DIY',
  'DOCK BUMPERS (WHOLESALE)',
  'DOCK COVERS',
  'DOCKBOARDS & RAMPS',
  'DOCKS (BUILDERS)',
  'DOCKS (FACILITY)',
  'DOCKS-MANUFACTURERS',
  'Document Management',
  'Document Preparation',
  'DOCUMENT PREPARATION SERVICE',
  'Document Preparation Services',
  'DOG & CAT FOOD (MANUFACTURERS)',
  'DOG & CAT KENNELS',
  'DOG & CAT PET SITTING SERVICES',
  'DOG AND CAT FOOD',
  'Dog and Cat Food Manufacturing',
  'DOG BREEDERS',
  'DOG BREEDERS-INFORMATION & REFERRAL SVC',
  'DOG ENCLOSURES & RUNWAYS',
  'DOG HOUSES',
  'DOG SLEDDING',
  'DOG SLEDDING EQUIPMENT',
  'DOG SLEDS-MANUFACTURERS & SALES',
  'DOG TRAINING',
  'DOLL CLOTHING & ACCESSORIES',
  'DOLL CLOTHING & ACCESSORIES-MFRS',
  'DOLL CLOTHING & ACCESSORIES-WHOLESALE',
  'DOLL HOUSES & ACCESSORIES',
  'DOLL HOUSES & ACCESSORIES-WHOLESALE',
  'DOLL-MFRS EQUIPMENT & SUPPLIES (WHOL)',
  'Doll, Toy, and Game Manufacturing',
  'DOLLS & STUFFED TOYS (MANUFACTURERS)',
  'DOLLS AND STUFFED TOYS',
  'DOLLS-MANUFACTURERS',
  'DOLLS-PARTS SUPPLIES & INSTRUCTION',
  'DOLLS-REPAIRING',
  'DOLLS-RETAIL',
  'DOLLS-WHOLESALE',
  'DOLOMITE',
  'Domain Registrar',
  'DOME STRUCTURES',
  'DOMESTIC ABUSE INFORMATION & TREATMENT',
  'DOOR & GATE OPERATING DEVICES',
  'DOOR & GATE OPERATING DEVICES-MFRS',
  'DOOR CHIMES & BELLS',
  'DOOR CLOSERS & CHECKS (WHOLESALE)',
  'DOOR CLOSERS & CHECKS-MANUFACTURERS',
  'DOOR CLOSERS & CHECKS-REPAIRING',
  'DOOR FRAMES (WHOLESALE)',
  'DOOR FRAMES-MANUFACTURERS',
  'DOOR LOOK-OUTS',
  'DOORS',
  'DOORS & FRAMES-COML & INDUSTRIAL (WHOL)',
  'DOORS-FIREPROOF (MANUFACTURERS)',
  'DOORS-FOLDING (MANUFACTURERS)',
  'DOORS-GARAGE',
  'DOORS-GLASS',
  'DOORS-GLASS-MANUFACTURERS',
  'DOORS-HANGING & CUTTING',
  'DOORS-MANUFACTURERS',
  'DOORS-METAL',
  'DOORS-METAL-MANUFACTURERS',
  'DOORS-OVERHEAD TYPE',
  'DOORS-PLASTIC LEATHER OR FABRIC (MFRS)',
  'DOORS-REPAIRING',
  'DOORS-REVOLVING',
  'DOORS-ROLLING',
  'DOORS-SLIDING (WHOLESALE)',
  'DOORS-SUPPLIES & PARTS',
  'DOORS-USED',
  'DOORS-WHOLESALE',
  'DOORS-WOOD',
  'DOORS-WOOD-WHOLESALE',
  'DORMER CONSTRUCTION',
  'DORMITORIES',
  'DOUGHNUT MACHINES & SUPPLIES (WHOLESALE)',
  'DOUGHNUTS',
  'DOUGHNUTS-MANUFACTURERS',
  'DOWELS (WHOLESALE)',
  'DOWN PRODUCTS',
  'DOZER SERVICE',
  'DRAFTING ROOM EQUIPMENT & SUPLS (WHOL)',
  'DRAFTING ROOM EQUIPMENT-MANUFACTURERS',
  'Drafting Services',
  'DRAFTING SERVICES',
  'DRAGLINES',
  'DRAIN LAYING',
  'DRAIN PIPES-CLAY-MANUFACTURERS',
  'DRAIN-TILE',
  'DRAINAGE CONTRACTORS',
  'DRAINBOARDS',
  'DRAMATIC INSTRUCTION',
  'DRAPERIES & CURTAINS-ALTERATIONS',
  'DRAPERIES & CURTAINS-RETAIL/CUSTOM MADE',
  'DRAPERIES & CURTAINS-WHOLESALE',
  'DRAPERIES-RENTING',
  'DRAPERY & CURTAIN CLEANERS',
  'DRAPERY & CURTAIN FABRICS-RETAIL',
  'DRAPERY & CURTAIN FABRICS-WHOLESALE',
  'DRAPERY & CURTAIN FIXTURES',
  'DRAPERY & CURTAIN FIXTURES-MANUFACTURERS',
  'DRAPERY & CURTAIN FIXTURES-WHOLESALE',
  "DRAPERY & CURTAIN-MFRS' SUPLS (WHOL)",
  'DRAPERY AND UPHOLSTERY STORES',
  'DRAPERY CONTRACTORS',
  'DRAPERY FABRICS-MANUFACTURERS',
  'DRAPERY HARDWARE AND BLINDS AND SHADES',
  'DRAPERY HDWR-WINDOW BLINDS/SHADES (MFRS)',
  'DRAPERY INSTALLATION SERVICE',
  'DRAPERY PATTERN MAKERS',
  'DRAPERY TRIMMINGS (WHOLESALE)',
  'DRAPERY WORKROOMS',
  'DRAWER SLIDES-MANUFACTURERS',
  'DRAWING & EXTRUDING COMPOUNDS (MFRS)',
  'DRAWING/INSULATING-NONFERROUS WIRE (MFR)',
  'DREDGING',
  'DREDGING MACHINERY (WHOLESALE)',
  'DREDGING MACHINERY-MFRS',
  'DRESS FORMS (WHOLESALE)',
  'DRESS FORMS-MANUFACTURERS',
  'DRESS FORMS-WHOLESALE',
  'DRESS GOODS-WHOLESALE',
  'DRESS PATTERNS',
  'DRESS TRIMMINGS (WHOLESALE)',
  'DRESS/WORK GLOVES-EX KNIT/LEATHER (MFRS)',
  'DRESSES-MANUFACTURERS',
  'DRESSES-WHOLESALE',
  'DRESSING GOWNS-MANUFACTURERS',
  'DRESSMAKERS',
  'DRESSMAKERS ALTERATIONS',
  'DRESSMAKERS SUPPLIES (WHOLESALE)',
  'DRESSMAKING SCHOOLS',
  'Dried and Dehydrated Food Manufacturing',
  'DRIED/DEHYDRATED FRUITS VEGETABLES (MFR)',
  'DRILL HEADS-MANUFACTURERS',
  'DRILL PRESSES (MANUFACTURERS)',
  'DRILLING & BORING CONTRACTORS',
  'DRILLING & BORING EQUIP & SUPLS (WHOL)',
  'DRILLING & BORING EQUIP & SUPLS-MFRS',
  'DRILLING COMPANIES',
  'DRILLING CONSULTANTS',
  'Drilling Oil and Gas Wells',
  'DRILLING OIL AND GAS WELLS',
  'DRILLING-DIAMOND CORE',
  'DRILLING-OIL & GAS WELLS',
  'DRILLS (WHOLESALE)',
  'DRINKING PLACES',
  'Drinking Places (Alcoholic Beverages)',
  'DRIVE SHAFTS (WHOLESALE)',
  'DRIVE SHAFTS-INDUSTRIAL (MFRS)',
  'DRIVE-IN MOTION PICTURE THEATERS',
  'DRIVE-IN MOTION PICTURE THEATERS',
  'Drive-In Motion Picture Theaters',
  'DRIVEWAYS',
  'DRIVEWAYS-SEALERS',
  'DRIVING INSTRUCTION',
  'DRIVING PROFICIENCY TEST SERVICE',
  'DRIVING TRAINING EQUIPMENT (WHOLESALE)',
  'DRM',
  'Drone Management',
  'Drones',
  'DROP CLOTHS (MANUFACTURERS)',
  'DRUG ABUSE & ADDICTION INFO & TREATMENT',
  'DRUG BROKERS',
  'DRUG DETECTION SERVICE & EQUIPMENT',
  'DRUG MILLERS',
  'DRUG STORES AND PROPRIETARY STORES',
  'DRUG-MANUFACTURERS',
  'DRUG-MANUFACTURERS REPRESENTATIVES',
  'DRUGGISTS-SUNDRIES TO THE TRADE (WHOL)',
  'DRUGLESS PRACTITIONERS',
  'DRUGS',
  "Drugs and Druggists' Sundries Merchant Wholesalers",
  'DRUM CORPS',
  'DRUMS-STEEL (MANUFACTURERS)',
  'DRY',
  'DRY CONDENSED/EVPRTD DAIRY PROD (MFR)',
  'DRY DOCKS',
  'DRY GOODS-COMMISSION (WHOLESALE)',
  'DRY GOODS-RETAIL',
  'DRY GOODS-WHOLESALE',
  'DRY ICE',
  'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour ',
  'Dry Pea and Bean Farming',
  'DRY ROT-REPAIR',
  'DRY WALL CONTRACTORS',
  'DRY WALL CONTRACTORS EQUIP & SUPL-MFRS',
  'DRY WALL CONTRACTORS EQUIP/SUPLS (WHOL)',
  'DRY WALL MATERIALS',
  'DRY WELL CONTRACTORS',
  'Dry, Condensed, and Evaporated Dairy Product Manufacturing ',
  'Drycleaning and Laundry Services (except Coin-Operated)',
  'DRYCLEANING PLANTS',
  'DRYCLEANING PLANTS-EXCEPT RUG',
  'DRYERS-CLOTHES',
  'DRYERS-HAND (WHOLESALE)',
  'DRYING COMPOUNDS (WHOLESALE)',
  'DRYING EQUIPMENT-INDUSTRIAL (WHOLESALE)',
  'Drywall and Insulation Contractors',
  'DSP',
  'Dual-Purpose Cattle Ranching and Farming',
  'DUCT & DUCT FITTINGS (MANUFACTURERS)',
  'DUCT SYSTEMS-AIR CONDITIONING & HEATING',
  'DUDE RANCHES',
  'DUI DRIVING SCHOOLS',
  'DUMBWAITERS',
  'DUMBWAITERS-MANUFACTURERS',
  'DUMBWAITERS-SUPPLIES & PARTS (WHOLESALE)',
  'DUMPS-RUBBISH',
  'DUNE BUGGIES',
  'DUPLICATING MACHINES & SUPPLIES (WHOL)',
  'DURABLE GOODS',
  'DURABLE GOODS NEC (WHOLESALE)',
  'DUST COLLECTING SYSTEMS (WHOLESALE)',
  'DUST COLLECTOR BAGS-MANUFACTURERS',
  'DUST CONTROL MATERIALS (WHOLESALE)',
  'DUST CONTROL MATERIALS-RENTING',
  'DUSTERS & DUSTING CLOTHS',
  'DUSTERS-MANUFACTURERS',
  'DVD SALES & SVC',
  'DWELLING OPERATORS',
  'DYERS',
  'DYERS-INDUSTRIAL (MANUFACTURERS)',
  'DYERS-WHOLESALE',
  'DYES & DYESTUFFS (WHOLESALE)',
  'DYES & DYESTUFFS-MANUFACTURERS',
  'DYNAMOMETERS (MANUFACTURERS)',
  'E-COMMERCE',
  'E-Commerce',
  'E-Commerce Platforms',
  'E-Learning',
  'E-Signature',
  'EAR MOLDS & PROTECTIVE DEVICES',
  'EAR PIERCING EQUIPMENT & SUPPLIES (WHOL)',
  'EAR PIERCING SERVICE',
  'EAR PLUGS',
  'EARTH HOME CONSTRUCTION',
  'EARTHQUAKE PRODUCTS & SERVICES',
  'EARTHWORMS',
  'EASELS-MANUFACTURERS',
  'EASELS-WHOLESALE',
  'EATING DISORDERS INFO & TREATMENT CTRS',
  'EBooks',
  'ECONOMIC DEVELOPMENT AGENCIES',
  'ECONOMIC RESEARCH & ANALYSIS',
  'EDIBLE FATS AND OILS',
  'Ediscovery',
  'EDITING & RESEARCH SERVICES',
  'EDITORIAL SERVICES',
  'EdTech',
  'Education',
  'EDUCATION CENTERS',
  'Education Management',
  'EDUCATION RELIGIOUS & CHARITABLE TRUSTS',
  'EDUCATIONAL ASSOCIATIONS',
  'EDUCATIONAL CONSULTANTS',
  'EDUCATIONAL COOPERATIVE ORGANIZATIONS',
  'EDUCATIONAL MATERIALS',
  'EDUCATIONAL PERFORMERS',
  'EDUCATIONAL PROGRAMS',
  'EDUCATIONAL PSYCHOLOGISTS',
  'EDUCATIONAL RESEARCH',
  'EDUCATIONAL SCHOLARSHIP PLANS',
  'EDUCATIONAL SERVICE-BUSINESS',
  'Educational Support Services',
  'Edutainment',
  'EGG PROCESSING/SHPNG EQPT/SUPLS (WHOL)',
  'EGGS (WHOLESALE)',
  'EGGS-CANNED (MANUFACTURERS)',
  'EGGS-DRIED (MANUFACTURERS)',
  'EGGS-RETAIL',
  'ELASTIC GOODS (MANUFACTURERS)',
  'ELASTIC GOODS-WHOLESALE',
  'ELASTIC STOCKINGS',
  'Elder Care',
  'Elderly',
  'ELDERLY COMPANION SERVICE',
  'ELEC EQUIP-INTERNAL COMB ENGINES (MFRS)',
  'ELECTRIC & OTHER SERVICES-COMBINED',
  'ELECTRIC AND OTHER SERVICES COMBINED',
  'ELECTRIC APPLIANCES-SMALL-MANUFACTURERS',
  'Electric Bulk Power Transmission and Control',
  'ELECTRIC CABLE FAULT LOCATING',
  'ELECTRIC CARS-AUTOMOBILE (MANUFACTURERS)',
  'ELECTRIC COMPANIES',
  'ELECTRIC CONTRACTORS',
  'ELECTRIC CONTRACTORS-COML & INDUSTRIAL',
  'ELECTRIC CONTRACTORS-MARINE',
  'ELECTRIC DRIVES-MANUFACTURERS',
  'ELECTRIC EQUIPMENT & SUPPLIES-RETAIL',
  'ELECTRIC EQUIPMENT & SUPPLIES-WHOLESALE',
  'ELECTRIC EQUIPMENT-MANUFACTURERS',
  'ELECTRIC EQUIPMENT-RENTING',
  'ELECTRIC EQUIPMENT-SERVICE & REPAIRING',
  'ELECTRIC EQUIPMENT-TESTING',
  'ELECTRIC EQUIPMENT-USED (WHOLESALE)',
  'ELECTRIC FENCE (MANUFACTURERS)',
  'ELECTRIC HEAT CONSULTANTS',
  'ELECTRIC HEATING ELEMENTS (WHOLESALE)',
  'ELECTRIC HEATING EQUIP & SYSTEMS (WHOL)',
  'ELECTRIC HEATING EQUIPMENT-MANUFACTURERS',
  'ELECTRIC HOUSEWARES & FANS (MFRS)',
  'ELECTRIC HOUSEWARES AND FANS',
  'ELECTRIC INSTRUMENTS-MANUFACTURERS',
  'Electric Lamp Bulb and Part Manufacturing',
  'ELECTRIC LAMP BULBS & TUBES-MFRS',
  'ELECTRIC LAMPS',
  'ELECTRIC MOTORS-CONTROLS-MANUFACTURERS',
  'ELECTRIC MOTORS-CONTROLS-WHOLESALE',
  'ELECTRIC MOTORS-DISTRIBUTORS',
  'ELECTRIC MOTORS-DLRS/REPAIRING (WHOL)',
  'ELECTRIC MOTORS-MANUFACTURERS',
  'ELECTRIC MOTORS-SUPPLIES & PARTS (WHOL)',
  'ELECTRIC MOTORS-SUPPLIES & PARTS-MFRS',
  'ELECTRIC MOTORS-USED',
  'Electric Power Distribution',
  'ELECTRIC POWER SYSTEMS-EMERGENCY/STANDBY',
  'ELECTRIC RATE CONSULTANTS',
  'ELECTRIC SERVICES',
  'ELECTRIC SUPPLIES-MANUFACTURERS',
  'ELECTRIC SWITCHBOARDS-MANUFACTURERS',
  'ELECTRIC SWITCHBOARDS/PANELS (WHOLESALE)',
  'ELECTRIC TRAINS-TOY',
  'ELECTRIC TRANSMISSION/DISTR EQUIP (MFRS)',
  'Electric Vehicle',
  'ELECTRICAL & ELECTRONIC REPAIR SHOPS NEC',
  'ELECTRICAL APPARATUS AND EQUIPMENT',
  'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers ',
  'ELECTRICAL APPLIANCES',
  'ELECTRICAL CONSULTANTS',
  'Electrical Contractors and Other Wiring Installation Contractors',
  'ELECTRICAL DESIGNERS',
  'ELECTRICAL DISCHARGE MACHINING (WHOL)',
  'ELECTRICAL DISCHARGE MACHS/SUPLS (WHOL)',
  'Electrical Distribution',
  'ELECTRICAL EQUIPMENT AND SUPPLIES',
  'ELECTRICAL INDSTRL APPARATUS NEC (MFRS)',
  'ELECTRICAL INDUSTRIAL APPARATUS',
  'ELECTRICAL POWER SYSTEMS-MAINTENANCE',
  'ELECTRICAL POWER SYSTEMS-TESTING',
  'ELECTRICAL PRODUCTS-MANUFACTURERS',
  'ELECTRICAL REPAIR SHOPS',
  'ELECTRICAL WORK',
  'Electrical/Electronic Manufacturing',
  'ELECTRO-CHEMICAL MACHINING (MFRS)',
  'ELECTRO-MECHANICAL EQUIP-MANUFACTURERS',
  'ELECTROCARDIOGRAM SYSTS/ANALYSIS (WHOL)',
  'ELECTRODES (WHOLESALE)',
  'ELECTROFORMING (MANUFACTURERS)',
  'ELECTROLOGISTS',
  'ELECTROLYSIS',
  'ELECTROLYSIS EQUIP & SUPLS-MANUFACTURERS',
  'ELECTROLYSIS EQUIP & SUPLS-WHOLESALE',
  'ELECTROLYSIS SCHOOLS',
  'Electromedical and Electrotherapeutic Apparatus Manufacturing',
  'ELECTROMEDICAL EQUIPMENT',
  'ELECTROMEDICAL/THERAPEUTIC APPRTS (MFRS)',
  'ELECTROMETALLURGICAL PROD-EX STEEL (MFR)',
  'ELECTROMETALLURGICAL PRODUCTS',
  'ELECTRON TUBES',
  'ELECTRON TUBES (MANUFACTURERS)',
  'ELECTRONIC CAPACITOR (MANUFACTURERS)',
  'ELECTRONIC CAPACITORS',
  'ELECTRONIC CIRCUIT BOARDS-TESTING/REPAIR',
  'ELECTRONIC COIL & TRANSFORMERS (MFRS)',
  'ELECTRONIC COILS AND TRANSFORMERS',
  'ELECTRONIC COMPONENTS',
  'Electronic Computer Manufacturing',
  'ELECTRONIC COMPUTERS',
  'Electronic Connector Manufacturing',
  'ELECTRONIC CONNECTORS',
  'ELECTRONIC CONNECTORS (MANUFACTURERS)',
  'ELECTRONIC CONTROL SYSTEMS',
  'ELECTRONIC CONTROLS-MANUFACTURERS',
  'ELECTRONIC DATA INTERCHANGE',
  'Electronic Design Automation (EDA)',
  'ELECTRONIC ENCLOSURES-MANUFACTURERS',
  'ELECTRONIC ENCLOSURES-WHOLESALE',
  'ELECTRONIC EQUIP/SUPLS-PROCESS CONTROLS',
  'ELECTRONIC EQUIPMENT & SUPPLIES-MFRS',
  'ELECTRONIC EQUIPMENT & SUPPLIES-REPAIR',
  'ELECTRONIC EQUIPMENT & SUPPLIES-RETAIL',
  'ELECTRONIC EQUIPMENT & SUPPLIES-WHOL',
  'Electronic Health Record (EHR)',
  'ELECTRONIC HEATING EQUIPMENT (WHOLESALE)',
  'ELECTRONIC IMAGING',
  'ELECTRONIC INSTRUMENTS (WHOLESALE)',
  'ELECTRONIC INSTRUMENTS-MANUFACTURERS',
  'ELECTRONIC MAIL SERVICE',
  'ELECTRONIC PARTS AND EQUIPMENT',
  'ELECTRONIC PARTS ASSEMBLERS (WHOLESALE)',
  'ELECTRONIC POWER SUPPLIES (WHOLESALE)',
  'ELECTRONIC POWER SUPPLIES-MANUFACTURERS',
  'ELECTRONIC RESEARCH & DEVELOPMENT',
  'ELECTRONIC RESISTORS',
  'ELECTRONIC RETAILING',
  'ELECTRONIC SCOREBOARDS & SIGNS (WHOL)',
  'Electronic Shopping and Mail-Order Houses',
  'ELECTRONIC TESTING EQUIPMENT (WHOLESALE)',
  'ELECTRONIC TUBES (WHOLESALE)',
  'ELECTRONIC-MFRS REPRESENTATIVES (WHOL)',
  'Electronics',
  'Electronics Stores',
  'ELECTRONICS-CONSULTANTS',
  'Electroplating, Plating, Polishing, Anodizing, and Coloring ',
  'ELECTROPLATING/POLISHING/ANODIZING (MFR)',
  'ELECTROSTATIC EQUIPMENT (MANUFACTURERS)',
  'ELEMENTARY AND SECONDARY SCHOOLS',
  'Elementary and Secondary Schools',
  'ELEMENTS & ALLOYS',
  'ELEVATOR & ESCALATOR INSPECTION SERVICE',
  'Elevator and Moving Stairway Manufacturing',
  'ELEVATOR CARS-REFINISHING',
  'ELEVATOR CONSULTANTS',
  'ELEVATOR SHAFT CLEANING',
  'ELEVATORS & MOVING STAIRWAYS (MFRS)',
  'ELEVATORS AND MOVING STAIRWAYS',
  'ELEVATORS-PORTABLE',
  'ELEVATORS-REPAIRING',
  'ELEVATORS-SALES & SERVICE',
  'ELEVATORS-SALES & SERVICE-MANUFACTURERS',
  'ELEVATORS-SUPPLIES & PARTS (WHOLESALE)',
  'ELEVATORS-SUPPLIES & PARTS-MANUFACTURERS',
  'Email',
  'Email Marketing',
  'EMBALMERS',
  'Embedded Software',
  'Embedded Systems',
  'EMBEDMENTS/ENCAPSULATIONS-PLASTIC (MFRS)',
  'EMBLEMS (MANUFACTURERS)',
  'EMBOSSING',
  'EMBOSSING EQUIPMENT & SUPPLIES (WHOL)',
  'EMBROIDERING MACHINES (WHOLESALE)',
  'EMBROIDERING MACHINES-MANUFACTURERS',
  'EMBROIDERY',
  'EMBROIDERY-SUPPLIES',
  'EMBROIDERY-WHOLESALE',
  'Emergency and Other Relief Services',
  'EMERGENCY CALLS',
  'EMERGENCY CHIROPRACTIC SERVICE',
  'EMERGENCY DISASTER PLANNING',
  'EMERGENCY DISASTER RESTORATION SVC',
  'EMERGENCY INDUSTRIAL MEDICAL UNITS',
  'EMERGENCY MEDICAL & SURGICAL SERVICE',
  'Emergency Medicine',
  'EMERGENCY MINOR MEDICAL FACILITIES/SVCS',
  'EMERGENCY NOTIFICATION SERVICE',
  'EMERGENCY REPORTING SYSTEMS',
  'Emerging Markets',
  'EMPLOYEE ASSISTANCE PROGRAMS',
  'EMPLOYEE BENEFIT & COMPENSATION PLANS',
  'EMPLOYEE BENEFIT CONSULTANTS',
  'Employee Benefits',
  'EMPLOYEES EDUCATIONAL SYSTEMS',
  'EMPLOYEES SUGGESTION SYSTEM',
  'Employment',
  'EMPLOYMENT AGENCIES',
  'EMPLOYMENT AGENCIES & OPPORTUNITIES',
  'EMPLOYMENT CONSULTANTS',
  'EMPLOYMENT CONTRACTORS-TEMPORARY HELP',
  'EMPLOYMENT COUNSELING',
  'EMPLOYMENT DATABASE SERVICES',
  'Employment Placement Agencies',
  'EMPLOYMENT SCREENING SERVICES',
  'EMPLOYMENT SERVICE-EMPLOYEE LEASING',
  'EMPLOYMENT SERVICE-GOVT CO FRATERNAL',
  'EMPLOYMENT SVC-HANDICAPPED & DISABLED',
  'EMPLOYMENT TRAINING SERVICE',
  'EMPLOYMENT VERIFICATION',
  'ENAMELED WARE',
  'ENAMELING JAPANNING & LACQUERING (MFRS)',
  'ENAMELS-PORCELAIN-MANUFACTURERS',
  'ENCAPSULATING (MANUFACTURERS)',
  'ENCLOSURES',
  'ENCYCLOPEDIAS',
  'ENDERMOLOGIE',
  'ENDOSCOPY CENTERS',
  'Energy',
  'ENERGY AUDITS',
  'ENERGY CODE COMPLIANCE ANALYSTS',
  'ENERGY CONSERVATION & MGMT CONSULTANTS',
  'ENERGY CONSERVATION PRODS-MANUFACTURERS',
  'ENERGY CONSERVATION PRODS-RETAIL',
  'ENERGY CONSERVATION PRODS-WHOLESALE',
  'Energy Efficiency',
  'ENERGY EQUIPMENT SYSTEMS & SUPPLIES',
  'Energy Management',
  'ENERGY MANAGEMENT SYSTEMS & PRODUCTS',
  'ENERGY MARKETING',
  'ENERGY RECOVERY SYSTEMS (WHOLESALE)',
  'Energy Storage',
  'ENGINE ELECTRICAL EQUIPMENT',
  'ENGINE TURNING',
  'Engineered Wood Member (except Truss) Manufacturing',
  'ENGINEERING & ARCHITECTURAL SERVICES',
  'ENGINEERING EQUIPMENT & SUPPLIES (WHOL)',
  'ENGINEERING EQUIPMENT & SUPPLIES-MFRS',
  'ENGINEERING REPORTS',
  'ENGINEERING SCHOOLS',
  'Engineering Services',
  'ENGINEERING SERVICES',
  'ENGINEERING SERVICES',
  'ENGINEERING SURVEYS',
  'ENGINEERING-JOB SHOPS',
  'ENGINEERS',
  'ENGINEERS-ACOUSTICAL',
  'ENGINEERS-AERONAUTICAL',
  'ENGINEERS-AGRICULTURAL',
  'ENGINEERS-AIR COND HEATING VENTILATING',
  'ENGINEERS-ARCHITECTURAL',
  'ENGINEERS-AUTOMOTIVE',
  'ENGINEERS-BIOMEDICAL',
  'ENGINEERS-BUILDING INSPECTION',
  'ENGINEERS-CHEMICAL',
  'ENGINEERS-CIVIL',
  'ENGINEERS-COMMUNICATIONS',
  'ENGINEERS-CONSTRUCTION',
  'ENGINEERS-CONSULTING',
  'ENGINEERS-CONTROL SYSTEMS',
  'ENGINEERS-CORROSION',
  'ENGINEERS-COST',
  'ENGINEERS-DESIGNING',
  'ENGINEERS-DRAINAGE',
  'ENGINEERS-EARTHQUAKE',
  'ENGINEERS-ELECTRICAL',
  'ENGINEERS-ELECTRONIC',
  'ENGINEERS-ENERGY MANAGEMENT',
  'ENGINEERS-ENVIRONMENTAL',
  'ENGINEERS-FOUNDATION',
  'ENGINEERS-GAS',
  'ENGINEERS-GEOLOGICAL',
  'ENGINEERS-GEOTECHNICAL',
  'ENGINEERS-HAZARDOUS & INDUSTRIAL WASTE',
  'ENGINEERS-HIGHWAYS & BRIDGES',
  'ENGINEERS-HYDRAULIC',
  'ENGINEERS-INDUSTRIAL',
  'ENGINEERS-INSPECTING',
  'ENGINEERS-IRRIGATION',
  'ENGINEERS-LAND PLANNING',
  'ENGINEERS-MACHINE DESIGN',
  'ENGINEERS-MANAGEMENT',
  'ENGINEERS-MANUFACTURING',
  'ENGINEERS-MARINE',
  'ENGINEERS-MATERIAL HANDLING',
  'ENGINEERS-MECHANICAL',
  'ENGINEERS-METALLURGICAL',
  'ENGINEERS-MINING',
  'ENGINEERS-MUNICIPAL',
  'ENGINEERS-NUCLEAR',
  'ENGINEERS-PETROLEUM',
  'ENGINEERS-PETROLEUM REFINING & TRNSMSS',
  'ENGINEERS-PHOTOGRAMMETRIC',
  'ENGINEERS-PIPE LINE',
  'ENGINEERS-PLASTIC',
  'ENGINEERS-PLUMBING',
  'ENGINEERS-POWER',
  'ENGINEERS-PROCESSING',
  'ENGINEERS-PROFESSIONAL',
  'ENGINEERS-REFRIGERATING',
  'ENGINEERS-RESEARCH',
  'ENGINEERS-SAFETY',
  'ENGINEERS-SANITARY',
  'ENGINEERS-SOLAR',
  'ENGINEERS-STRUCTURAL',
  'ENGINEERS-SYSTEMS',
  'ENGINEERS-TECHNICAL SERVICE',
  'ENGINEERS-TESTING',
  'ENGINEERS-TRAFFIC & TRANSPORTATION',
  'ENGINEERS-TRANSPORTATION',
  'ENGINEERS-WASTE WATER TREATMENT',
  'ENGINEERS-WATER SUPPLY',
  'ENGINES-DIESEL (WHOLESALE)',
  'ENGINES-DIESEL-FUEL INJECTION-SVC & RPR',
  'ENGINES-DIESEL-MANUFACTURERS',
  'ENGINES-DIESEL-REPAIRING',
  'ENGINES-DIESEL-USED',
  'ENGINES-FUEL-CONVERSION',
  'ENGINES-GAS',
  'ENGINES-GASOLINE',
  'ENGINES-GASOLINE-REPAIRING',
  'ENGINES-GASOLINE-WHOLESALE',
  'ENGINES-MARINE',
  'ENGINES-MARINE-REPAIRING',
  'ENGINES-RACING',
  'ENGINES-REBUILDING & EXCHANGING',
  'ENGINES-REBUILDING & REPAIRING',
  'ENGINES-SMALL-REPAIRING',
  'ENGINES-STEAM (MANUFACTURERS)',
  'ENGINES-SUPPLIES-EQUIP & PARTS (WHOL)',
  'ENGINES-SUPPLIES-EQUIPMENT & PARTS-MFRS',
  'ENGRAVERS-BANK NOTE',
  'ENGRAVERS-CYLINDER',
  'ENGRAVERS-DIE',
  'ENGRAVERS-GENERAL',
  'ENGRAVERS-GLASSWARE (MANUFACTURERS)',
  'ENGRAVERS-MECHANICAL (MANUFACTURERS)',
  'ENGRAVERS-METAL',
  'ENGRAVERS-PHOTO',
  'ENGRAVERS-PLASTIC WOOD & ETC',
  'ENGRAVERS-ROTOGRAVURE',
  'ENGRAVERS-RUBBER',
  'ENGRAVERS-SECURITY-PERSONAL & COMMERCIAL',
  'ENGRAVERS-STATIONERY (MANUFACTURERS)',
  'ENGRAVERS-STEEL & COPPER PLATE',
  'ENGRAVING EQUIPMENT & SUPPLIES (WHOL)',
  'ENROLLED AGENTS',
  'Enterprise',
  'Enterprise Applications',
  'Enterprise Resource Planning (ERP)',
  'Enterprise Software',
  'ENTERTAINERS',
  'ENTERTAINERS ADULT',
  'ENTERTAINERS AND ENTERTAINMENT GROUPS',
  'ENTERTAINERS-CHILDREN & FAMILY',
  'ENTERTAINERS-FAMILY & BUSINESS',
  'Entertainment',
  'ENTERTAINMENT BUREAUS',
  'ENTERTAINMENT PRODUCERS',
  'ENTOMOLOGISTS',
  'ENVELOPE MACHINERY PARTS & SUPLS (WHOL)',
  'ENVELOPES',
  'ENVELOPES-MANUFACTURERS',
  'ENVELOPES-RETAIL',
  'ENVELOPES-WHOLESALE',
  'Environment, Conservation and Wildlife Organizations ',
  'ENVIRONMENTAL & ECOLOGICAL PRODS (WHOL)',
  'ENVIRONMENTAL & ECOLOGICAL SERVICES',
  'ENVIRONMENTAL ANALYSIS SERVICES',
  'ENVIRONMENTAL ATTORNEYS',
  'ENVIRONMENTAL CONSERVATION/ECOLOGCL ORG',
  'Environmental Consulting',
  'Environmental Consulting Services',
  'ENVIRONMENTAL CONTROLS',
  'Environmental Engineering',
  'ENVIRONMENTAL PRODUCTS & SUPLS (WHOL)',
  'ENVIRONMENTAL RECLAMATION',
  'Environmental Services',
  'ENVIRONMENTAL SURVEYS',
  'ENZYMES (MANUFACTURERS)',
  'EPILEPSY EDUCATIONAL REFERRAL/SPRT SVC',
  'EPOXY-COMPOUNDS (MANUFACTURERS)',
  'Equestrian',
  'EQUIPMENT RENTAL AND LEASING',
  'ERECTING CONTRACTORS',
  'ERGONOMICS',
  'EROSION CONTROL',
  'ERRANDS & MISCELLANEOUS SERVICES',
  'ESCALATORS (MANUFACTURERS)',
  'ESCORT SERVICE-MOTORIZED',
  'ESCORT SERVICE-PERSONAL',
  'ESCROW SERVICE',
  'eSports',
  'ESPRESSO & ESPRESSO BARS',
  'ESTATE CLEANING',
  'ESTATE CONSULTANTS',
  'ESTATE MANAGEMENT',
  'ESTATE PLANNING',
  'ESTATE SALES',
  'ESTATES',
  'ESTHETICIANS',
  'ETCHED PRODUCTS-METAL GLASS & ETC (WHOL)',
  'ETCHED PRODUCTS-METAL GLASS & ETC-MFRS',
  'ETCHING EQUIPMENT & SUPPLIES (MFRS)',
  'Ethereum',
  'ETHNIC PRODUCTS & SERVICE',
  'ETHNIC SOCIETIES',
  'Ethyl Alcohol Manufacturing',
  'ETIQUETTE SCHOOLS',
  'EVAPORATING APPARATUS (MANUFACTURERS)',
  'Event Management',
  'Event Promotion',
  'Events',
  'Events Services',
  'EVENTS-SPECIAL',
  'EVICTION SERVICE',
  'Exam Preparation and Tutoring',
  'EXCAVATING CONTRACTORS',
  'EXCAVATING EQUIPMENT (WHOLESALE)',
  'EXCAVATING EQUIPMENT-RENTING & LEASING',
  'EXCAVATION WORK',
  'EXCELSIOR (MANUFACTURERS)',
  'EXECUTIVE AND LEGISLATIVE COMBINED',
  'Executive and Legislative Offices, Combined ',
  'Executive Office',
  'EXECUTIVE OFFICES',
  'Executive Offices',
  'EXECUTIVE SEARCH CONSULTANTS',
  'Executive Search Services',
  'EXECUTIVE SUITES',
  'EXECUTIVE TRAINING CONSULTANTS',
  'EXERCISE & PHYSICAL FITNESS PROGRAMS',
  'EXERCISE & PHYSICAL FITNESS WEAR',
  'EXERCISE EQUIPMENT-RENTING',
  'EXERCISE EQUIPMENT-RETAIL',
  'EXERCISE EQUIPMENT-WHOLESALE',
  'EXPANDED METALS (MANUFACTURERS)',
  'EXPANSION BOLTS & SHIELDS (MFRS)',
  'EXPANSION JOINTS (MANUFACTURERS)',
  'EXPEDITERS',
  'EXPEDITIONS-ARRANGED & OUTFITTED',
  'EXPERIMENTAL WORK',
  'EXPLORERS-NATURAL RESOURCES',
  'EXPLOSIVES',
  'EXPLOSIVES (WHOLESALE)',
  'Explosives Manufacturing',
  'EXPLOSIVES-MANUFACTURERS',
  'EXPORT CONSULTANTS',
  'EXPORT FINANCE',
  'EXPORT MANAGEMENT',
  'EXPORTERS',
  'EXPORTERS-TRNSPTN-EQUIP & SUPLS (WHOL)',
  'EXPOSITION MANAGERS',
  'EXPOSITION STANDS-DESIGNERS & MFRS',
  'EXPOSITION SUPPLIES-RENTING',
  'EXPOSITION TRADE SHOWS & FAIRS',
  'EXPRESS & TRANSFER SVC',
  'Exterminating and Pest Control Services',
  'EXTERMINATING/FUMIGATING EQPT/SUPL (WHL)',
  'Extermination Service',
  'EYE BANKS',
  'EYE TRAINING EQUIPMENT & SUPLS (WHOL)',
  'EYELASHES-ARTIFICIAL',
  'EYELETS (WHOLESALE)',
  'EYELETTING',
  'EYES-HUMAN PROSTHETIC',
  'EYESIGHT TRAINING',
  'Eyewear',
  'Fabric Coating Mills',
  'FABRIC DRESS AND WORK GLOVES',
  'FABRIC LAMINATING & BONDING (MFRS)',
  'FABRIC SHOPS',
  'FABRIC TREATMENT',
  'FABRICATED METAL PRODUCTS',
  'FABRICATED PIPE & PIPE FITTINGS (MFRS)',
  'FABRICATED PIPE AND FITTINGS',
  'Fabricated Pipe and Pipe Fitting Manufacturing',
  'FABRICATED PLATE WORK (BOILER SHOP)',
  'FABRICATED PLATE WORK-BOILER SHOPS',
  'FABRICATED RUBBER PRODUCTS',
  'FABRICATED STRUCTURAL METAL',
  'FABRICATED STRUCTURAL METAL (MFRS)',
  'Fabricated Structural Metal Manufacturing',
  'FABRICATED TEXTILE PRODUCTS',
  'FABRICATED WIRE PRODUCTS-MISC (MFRS)',
  'FABRICS-INDUSTRIAL (MANUFACTURERS)',
  'FABRICS-MANUFACTURERS',
  'FABRICS-WHOLESALE',
  'Facebook',
  'FACIAL COSMETOLOGY',
  'Facial Recognition',
  'FACILITIES & SPACE PLANNING CONSULTANTS',
  'FACILITIES MANAGEMENT',
  'Facilities Services',
  'FACILITIES SUPPORT MANAGEMENT SERVICES',
  'Facilities Support Services',
  'FACILITIES SUPPORT SERVICES',
  'Facilities Support Services',
  'Facility Management',
  'FACSIMILE COMM EQUIP SYSTS/SUPLS (WHOL)',
  'FACSIMILE COMMUNICATION EQUIPMENT (WHOL)',
  'FACSIMILE TRANSMISSION SERVICE',
  'FACSIMILE-REPAIRING',
  'FACTORS',
  'FACTORY LOCATING CONSULTANTS',
  'FACTORY MAINTENANCE',
  'FACTORY OUTLETS',
  'FAIRGROUNDS',
  'FALLOUT SHELTER EQUIPMENT & SUPPLIES',
  'FALLOUT SHELTERS-CONSULTANTS',
  'Family',
  'FAMILY & CHILDREN SERVICES',
  'FAMILY CLOTHING STORES',
  'Family Clothing Stores',
  'Family Planning Centers',
  'FAMILY PLANNING INFORMATION CENTERS',
  'FAN & BLOWER PARTS-MANUFACTURERS',
  'FANS-HAND PAPER ETC (WHOLESALE)',
  'FANS-HOUSEHOLD',
  'FANS-HOUSEHOLD-MANUFACTURERS',
  'FANS-HOUSEHOLD-WHOLESALE',
  'FANS-INDUSTRIAL & COMMERCIAL (WHOLESALE)',
  'FANS-INDUSTRIAL & COMMERCIAL-MFRS',
  'FANS-REPAIRING',
  'FANS-SUPPLIES & PARTS',
  'FANS-VENTILATING & EXHAUST (WHOLESALE)',
  'Fantasy Sports',
  'FARM & HOME ADVISORY SERVICE',
  'FARM AND GARDEN MACHINERY',
  'Farm and Garden Machinery and Equipment Merchant Wholesalers',
  'FARM BUILDINGS',
  'FARM DRAINAGE',
  'FARM EQUIPMENT (WHOLESALE)',
  'FARM EQUIPMENT-MANUFACTURERS',
  'FARM EQUIPMENT-RENTING (WHOLESALE)',
  'FARM EQUIPMENT-REPAIRING & PARTS',
  'FARM EQUIPMENT-REPAIRING & PARTS-MFRS',
  'FARM EQUIPMENT-USED (WHOLESALE)',
  'FARM LABOR CONTRACTORS',
  'Farm Labor Contractors and Crew Leaders',
  'FARM LABOR-CONTRACTORS & CREW LEADERS',
  'FARM MACHINERY AND EQUIPMENT',
  'Farm Machinery and Equipment Manufacturing',
  'FARM MANAGEMENT SERVICE',
  'FARM MANAGEMENT SERVICES',
  'Farm Management Services',
  'FARM MANAGEMENT SYSTEMS',
  'FARM MARKETS',
  'FARM ORGANIZATIONS',
  'FARM PRODUCE',
  'Farm Product Warehousing and Storage',
  'FARM PRODUCT WAREHOUSING AND STORAGE',
  'FARM PRODUCTS',
  'FARM PRODUCTS-RAW MATERIALS NEC (WHOL)',
  'FARM SUPERVISORS',
  'FARM SUPPLIES',
  'FARM SUPPLIES (WHOLESALE)',
  'Farm Supplies Merchant Wholesalers',
  'FARM-PRODUCT RAW MATERIALS',
  'FARMERS CO-OP RETAIL STORES',
  'Farmers Market',
  'Farming',
  'FARMING SERVICE',
  'FARMS',
  'FARMS-ORGANIC',
  'Fashion',
  'FASHION CONSULTANTS',
  'FASHION DESIGN SCHOOLS',
  'FASHION DESIGNERS',
  'Fast-Moving Consumer Goods',
  'Fastener, Button, Needle, and Pin Manufacturing ',
  'FASTENERS AND PINS',
  'FASTENERS-BUTTONS NEEDLES & PINS (MFRS)',
  'FASTENERS-HOOK & LOOP',
  'FASTENERS-INDUSTRIAL (WHOLESALE)',
  'FASTENERS-INDUSTRIAL-PAINTING (MFRS)',
  'FASTENERS-SNAP (WHOLESALE)',
  'FASTENERS-TEXTILE & APPAREL (WHOLESALE)',
  'FAT DEALERS (MANUFACTURERS)',
  'Fats and Oils Refining and Blending',
  'FAVORS',
  'FAX INFORMATION & SERVICE BUREAUS',
  'FEATHER GOODS (MANUFACTURERS)',
  'FEATHER RENOVATORS',
  'FEATHERS (WHOLESALE)',
  'FEDERAL & FEDERALLY-SPONSORED CREDIT',
  'FEDERAL AND FEDERALLY SPONSORED CREDIT',
  'FEDERAL CREDIT UNIONS',
  'FEDERAL GOVERNMENT-AGRICULTURAL PROGRAMS',
  'FEDERAL GOVERNMENT-CONSERVATION DEPTS',
  'FEDERAL GOVERNMENT-COURTS',
  'FEDERAL GOVERNMENT-ECONOMIC PROGRAM ADM',
  'FEDERAL GOVERNMENT-EDUCATION PROGRAMS',
  'FEDERAL GOVERNMENT-EXECUTIVE OFFICES',
  'FEDERAL GOVERNMENT-FINANCE & TAXATION',
  'FEDERAL GOVERNMENT-FIRE PROTECTION',
  'FEDERAL GOVERNMENT-GENERAL OFFICES',
  'FEDERAL GOVERNMENT-HOUSING PROGRAMS',
  'FEDERAL GOVERNMENT-INTERNATIONAL AFFAIRS',
  'FEDERAL GOVERNMENT-LEGAL COUNSEL',
  'FEDERAL GOVERNMENT-LIBRARIES',
  'FEDERAL GOVERNMENT-LICENSING/INSPECTION',
  'FEDERAL GOVERNMENT-NATIONAL SECURITY',
  'FEDERAL GOVERNMENT-POLICE',
  'FEDERAL GOVERNMENT-PUBLIC HLTH PROGRAMS',
  'FEDERAL GOVERNMENT-PUBLIC ORDER & SAFETY',
  'FEDERAL GOVERNMENT-SOCIAL & HUMAN RSRCS',
  'FEDERAL GOVERNMENT-SPACE RESEARCH/TECH',
  'FEDERAL GOVERNMENT-TRANSPORTATION PRGRMS',
  'FEDERAL GOVERNMENT-URBAN PLANNING & DEV',
  'FEDERAL GOVT-CORRECTIONAL INSTITUTIONS',
  'FEDERAL GOVT-ENVIRONMENTAL PROGRAMS',
  'FEDERAL GOVT-REG & ADM-COMMS/UTILITIES',
  'FEDERAL GOVT-VETERANS AFFAIRS ADMIN',
  'FEDERAL HOUSING PROJECT',
  'FEDERAL RESERVE BANKS',
  'FEDERAL RESERVE BANKS',
  'FEDERAL SAVINGS INSTITUTIONS',
  'FEDERAL/FEDERALLY SPONSORED CREDIT AGNCS',
  'FEDERALLY CHARTERED CREDIT UNIONS',
  'FEED CONCENTRATES & SUPPLEMENTS (WHOL)',
  'FEED GRINDING (WHOLESALE)',
  'FEED HANDLING EQUIPMENT (MANUFACTURERS)',
  'FEED HAULING',
  'FEED INGREDIENTS (WHOLESALE)',
  'FEED MILL EQUIPMENT & SUPPLIES (MFRS)',
  'FEED-DEALERS (WHOLESALE)',
  'FEED-MANUFACTURERS',
  'FEED-WHOLESALE',
  'FEEDERS-INDUSTRIAL (WHOLESALE)',
  'FELDENKRAIS PRACTITIONERS',
  'FELT & FELT PRODUCTS (WHOLESALE)',
  'FELT PRODUCTS-MANUFACTURERS',
  'FENCE (WHOLESALE)',
  'FENCE CONTRACTORS',
  'FENCE POSTS & FITTINGS (WHOLESALE)',
  'FENCE POSTS & FITTINGS-MANUFACTURERS',
  'FENCE-MANUFACTURERS',
  'FENCE-REPAIRING',
  'FENCING APPAREL & EQUIPMENT',
  'FENDER-MANUFACTURERS',
  'FERN PRODUCTS (WHOLESALE)',
  'FERNERIES',
  'FERRIES',
  'FERRIES',
  'FERRITES-MAGNETICALLY SOFT (MFRS)',
  'FERROALLOY ORES',
  'FERROALLOY ORES EXCEPT VANADIUM',
  'FERRULES (MANUFACTURERS)',
  'Ferry Service',
  'Fertility',
  'Fertilizer (Mixing Only) Manufacturing',
  'FERTILIZER MIXING ONLY (MANUFACTURERS)',
  'FERTILIZER PLANT EQUIPMENT (WHOLESALE)',
  'FERTILIZERS',
  'FERTILIZERS (WHOLESALE)',
  'FERTILIZERS-MANUFACTURERS',
  'FERTILIZERS-REMOVING & SPREADING',
  'FERTILIZERS-RETAIL',
  'FERTILIZING EQUIPMENT (WHOLESALE)',
  'FERTILIZING EQUIPMENT-MANUFACTURERS',
  'FERTILIZING HAULING',
  'FIBER & FIBER PRODUCTS (MANUFACTURERS)',
  'FIBER CANS',
  'FIBER GLASS FABRICATORS',
  'FIBER GLASS MATERIALS (WHOLESALE)',
  'FIBER GLASS PRODUCTS (WHOLESALE)',
  'FIBER GLASS PRODUCTS-MANUFACTURERS',
  'FIBER GLASS-REPAIRING',
  'Fiber Optic Cable Manufacturing',
  'FIBER OPTICS',
  'FIBER OPTICS-EQUIPMENT & SYSTEMS (MFRS)',
  'FIBER OPTICS-SERVICES',
  'FIBER OPTICS-SYSTEMS CONSULTANTS',
  'Fiber, Yarn, and Thread Mills ',
  'FIDUCIARIES',
  'FIELD CROPS',
  'FIELD CROPS-EXCEPT CASH GRAINS NEC',
  'Field Support',
  'Field-Programmable Gate Array (FPGA)',
  'FILAMENT WINDING (MANUFACTURERS)',
  'File Sharing',
  'FILING CONSULTANTS',
  'FILING EQUIPMENT SYSTEMS & SUPLS-MFRS',
  'FILING EQUIPMENT SYSTEMS & SUPPLIES',
  'FILING SERVICES',
  'FILL CONTRACTORS',
  'FILL DIRT',
  'FILLERS (NON-METALLIC)(MANUFACTURERS)',
  'FILLING MACHINERY (MANUFACTURERS)',
  'FILLING SERVICE',
  'Film',
  'FILM & MOTION PICTURE PRODUCERS',
  'Film Distribution',
  'Film Production',
  'FILM SCRAP (WHOLESALE)',
  'FILM STUDIO PRODUCTION FACILITIES',
  'FILTER PRESSES (MANUFACTURERS)',
  'FILTERING MATERIALS & SUPPLIES (MFRS)',
  'FILTERS (MANUFACTURERS)',
  'FILTERS-AIR & GAS',
  'FILTERS-AIR & GAS-CLEANING SERVICE',
  'FILTERS-AIR & GAS-COML & INDSTRL (WHOL)',
  'FILTERS-AIR & GAS-MANUFACTURERS',
  'FILTERS-AIR & GAS-WHOLESALE',
  'FILTERS-AIR-HOME-FURNACE/AC ETC (WHOL)',
  'FILTERS-CHEMICAL (MANUFACTURERS)',
  'FILTERS-FUEL & OIL-MANUFACTURERS',
  'FILTERS-FUEL & OIL-RETAIL',
  'FILTERS-FUEL & OIL-WHOLESALE',
  'FILTERS-LIQUID (MANUFACTURERS)',
  'FILTERS-LIQUID & INDUSTRIAL-DISTRIBUTORS',
  'FILTRATION EQUIPMENT-MANUFACTURERS',
  'FINANCE',
  'Finance',
  'FINANCIAL ADVISORY SERVICES',
  'FINANCIAL DOCUMENT INFORMATION SERVICES',
  'Financial Exchanges',
  'FINANCIAL MANAGEMENT & CONSULTING',
  'FINANCIAL PLANNERS-CERTIFIED',
  'FINANCIAL PLANNING CONSULTANTS',
  'FINANCIAL REPORT ANALYSTS',
  'Financial Services',
  'Financial Transactions Processing, Reserve, and Clearinghouse Activities ',
  'FINANCING',
  'FINANCING CONSULTANTS',
  'FINANCING-AUTOMOBILE',
  'FINANCING-BUSINESS',
  'FINANCING-INSURANCE PREMIUM',
  'FINDING & SEARCHING SERVICE',
  'Fine Art',
  'Fine Arts Schools',
  'FINE EARTHENWARE TBL/KTCHN ARTICLE (MFR)',
  'FINFISH',
  'FINFISH',
  'Finfish Farming and Fish Hatcheries',
  'Finfish Fishing',
  'FINGERPRINT EXPERTS',
  'FINGERPRINTING',
  'FINGERPRINTING EQUIPMENT (MANUFACTURERS)',
  'Finish Carpentry Contractors',
  'FINISHERS OF TEXTILES NEC (MFRS)',
  'FINISHERS-BROADWOVEN FBRCS-MANMADE (MFR)',
  'FINISHING PLANTS',
  'FINISHING PLANTS',
  'FINISHING PLANTS',
  'FinTech',
  'FIRE',
  'FIRE ALARM SYSTEMS (WHOLESALE)',
  'FIRE ALARM SYSTEMS & EQUIPMENT-TESTING',
  'FIRE ALARM SYSTEMS-MANUFACTURERS',
  'FIRE DAMAGE RESTORATION',
  'FIRE DEPARTMENT EQUIPMENT & SUPLS (WHOL)',
  'FIRE DEPARTMENT EQUIPMENT & SUPLS-MFRS',
  'FIRE DEPARTMENTS',
  'FIRE ESCAPES (MANUFACTURERS)',
  'FIRE EXIT DEVICES-MANUFACTURERS',
  'FIRE EXTINGUISHERS (WHOLESALE)',
  'FIRE EXTINGUISHERS-MANUFACTURERS',
  'FIRE EXTINGUISHING FLUID (WHOLESALE)',
  'FIRE HOSE (WHOLESALE)',
  'FIRE HYDRANTS (MANUFACTURERS)',
  'FIRE INSPECTIONS & INVESTIGATIONS',
  'FIRE LOOKOUT STATIONS',
  'FIRE PROTECTION',
  'Fire Protection',
  'FIRE PROTECTION CONSULTANTS',
  'FIRE PROTECTION ENGINEERS',
  'FIRE PROTECTION EQUIPMENT & SUPLS (WHOL)',
  'FIRE PROTECTION EQUIPMENT & SUPLS-MFRS',
  'FIRE PROTECTION SERVICE',
  'FIRE STARTERS',
  'FIRE TRUCKS-MANUFACTURERS',
  'FIRE VIOLATIONS-REMOVED',
  'FIREARM ACCESSORIES-MANUFACTURERS',
  'FIREARMS-MANUFACTURERS',
  'FIREPLACE CONTRACTORS',
  'FIREPLACE EQUIPMENT-MANUFACTURERS',
  'FIREPLACE EQUIPMENT-RETAIL',
  'FIREPLACE EQUIPMENT-WHOLESALE',
  'FIREPLACES',
  'FIREPLACES-MANUFACTURERS',
  'FIREPLACES-REPAIRED',
  'FIREPLACES-WHOLESALE',
  'FIREPROOFING',
  'FIREPROOFING MATERIALS (MANUFACTURERS)',
  'FIREPROOFING MATERIALS-WHOLESALE',
  'FIREWOOD',
  'FIREWOOD-MANUFACTURERS',
  'FIREWOOD-WHOLESALE',
  'FIREWORKS (WHOLESALE)',
  'FIREWORKS-MANUFACTURERS',
  'First Aid',
  'FIRST AID INSTRUCTION',
  'FIRST AID SERVICE',
  'FIRST AID SUPPLIES',
  'FIRST AID SUPPLIES-MANUFACTURERS',
  'FIRST AID SUPPLIES-WHOLESALE',
  'FISH & SEAFOOD-BROKERS',
  'Fish and Seafood Markets',
  'Fish and Seafood Merchant Wholesalers',
  'FISH AND SEAFOODS',
  'FISH BY-PRODUCTS (MANUFACTURERS)',
  'FISH CAKE FACTORIES',
  'FISH CLEANING',
  'FISH FARMING EQUIPMENT & SUPPLIES-MFRS',
  'FISH FARMING EQUIPMENT & SUPPLIES-WHOL',
  'FISH HATCHERIES',
  'FISH HATCHERIES AND PRESERVES',
  'FISH HOUSES-PORTABLE (MANUFACTURERS)',
  'FISH HOUSES-RENTAL',
  'FISH NETS',
  'FISH NETS-MANUFACTURERS',
  'FISH PACKERS',
  'FISH PACKERS EQUIPMENT (MANUFACTURERS)',
  'FISH POOLS',
  'FISH SMOKING & CURING (MANUFACTURERS)',
  'FISHERMEN-COMMERCIAL',
  'FISHERMENS SUPPLIES-MANUFACTURERS',
  'FISHERMENS SUPPLIES-WHOLESALE',
  'Fishery',
  'FISHING BAIT',
  'FISHING BAIT-MANUFACTURERS',
  'FISHING BAIT-WHOLESALE',
  'FISHING CAMPS',
  'FISHING INFORMATION',
  'FISHING LAKE MANAGEMENT',
  'FISHING LAKES & PONDS',
  'FISHING LAKES-PRIVATE',
  'FISHING LAKES-PUBLIC',
  'FISHING PARTIES',
  'FISHING PIERS',
  'FISHING STATIONS',
  'FISHING TACKLE MFRS-SUPLS (MFRS)',
  'FISHING TACKLE-DEALERS',
  'FISHING TACKLE-MANUFACTURERS',
  'FISHING TACKLE-REPAIRING & PARTS',
  'FISHING TACKLE-WHOLESALE',
  'FISHING TOURNAMENTS',
  'Fitness',
  'Fitness and Recreational Sports Centers',
  'FITTINGS-INDUSTRIAL (MANUFACTURERS)',
  'FIX-IT SHOPS',
  'FIXED FACILITIES & INSPECTION/WEIGHING',
  'FLAG INFORMATION',
  'FLAG POLES (WHOLESALE)',
  'FLAG POLES-MANUFACTURERS',
  'FLAGS & BANNERS-MANUFACTURERS',
  "FLAGS & BANNERS-MFRS' SUPPLIES (WHOL)",
  'FLAGS & BANNERS-WHOLESALE',
  'FLAGSTONES (MANUFACTURERS)',
  'FLANGE PROTECTORS (MANUFACTURERS)',
  'FLANGES (WHOLESALE)',
  'FLARES & SIGNALS (WHOLESALE)',
  'Flash Sale',
  'Flash Storage',
  'FLASHERS-ELECTRIC (MANUFACTURERS)',
  'FLASHING-WALL & ROOF & ETC',
  'FLASHLIGHTS (MANUFACTURERS)',
  'FLAT GLASS',
  'Flat Glass Manufacturing',
  'FLATWARE-TABLE',
  'FLAVORING EXTRACTS (WHOLESALE)',
  'FLAVORING EXTRACTS & SYRUPS NEC (MFRS)',
  'FLAVORING EXTRACTS AND SYRUPS',
  'FLAVORING EXTRACTS-MANUFACTURERS',
  'FLAVORING EXTRACTS-MFRS SUPLS (MFRS)',
  'FLAVORING EXTRACTS-RAW MATERIALS-WHOL',
  'Flavoring Syrup and Concentrate Manufacturing',
  'FLEA MARKETS',
  'Fleet Management',
  'FLEXIBLE SHAFTING (MANUFACTURERS)',
  'FLIGHT AIRCRAFT INSTRUCTION',
  'Flight Training',
  'FLOAT TRIPS',
  'FLOATS-MECHANICAL (MANUFACTURERS)',
  'FLOATS-STYROFOAM (MANUFACTURERS)',
  'FLOCK-FINISHING SUPPLIES (MFRS)',
  'FLOCK-MANUFACTURERS & PROCESSORS',
  'FLOCKING (MANUFACTURERS)',
  'FLOCKING EQUIPMENT & SUPPLIES (MFRS)',
  'FLOOD ASSISTANCE',
  'FLOOD CONTROL EQUIPMENT',
  'FLOOR ARMORING',
  'FLOOR COATINGS-CONCRETE',
  'FLOOR COVERING STORES',
  'Floor Covering Stores',
  'FLOOR COVERINGS-RETAIL',
  'FLOOR DEGREASING',
  'FLOOR LAYERS EQUIPMENT & SUPPLIES (MFRS)',
  'FLOOR LAYING & FLOOR WORK NEC',
  'FLOOR LAYING AND FLOOR WORK',
  'FLOOR LAYING REFINISHING & RESURFACING',
  'FLOOR MACHINES (WHOLESALE)',
  'FLOOR MACHINES-MANUFACTURERS',
  'FLOOR MACHINES-RENTING',
  'FLOOR MACHINES-REPAIRING',
  'FLOOR MATERIALS',
  'FLOOR MATERIALS-MANUFACTURERS',
  'FLOOR MATERIALS-WHOLESALE',
  'FLOOR POLISH & WAX',
  'FLOOR REFINISHING & RESURFACING',
  'FLOOR REFINISHING SUPPLIES',
  'FLOOR TREATMENT COMPOUNDS (WHOLESALE)',
  'FLOOR WAXING POLISHING & CLEANING',
  'FLOOR WAXING POLISHING/CLNG MTRLS (WHOL)',
  'Flooring Contractors',
  'FLOORING-HARDWOOD (MANUFACTURERS)',
  'FLOORS-CONTRACTORS & BUILDERS',
  'FLOORS-INDUSTRIAL',
  'FLOORS-PORTABLE',
  'FLOORS-RAISED',
  'FLORAL DESIGN INSTRUCTION',
  'Floriculture Production',
  'FLORIDA ROOMS',
  'Florists',
  'FLORISTS - FLOWERS',
  'FLORISTS-RETAIL',
  'FLORISTS-SUPPLIES (WHOLESALE)',
  'FLORISTS-SUPPLIES-MANUFACTURERS',
  'FLORISTS-WHOLESALE',
  'FLOTATION CENTERS',
  'FLOUR (WHOLESALE)',
  'FLOUR AND OTHER GRAIN MILL PRODUCTS',
  'Flour Milling',
  'FLOUR MILLS',
  'FLOUR-PREPARED (MANUFACTURERS)',
  'FLOWER ARRANGING INSTRUCTION',
  'FLOWER ARRANGING SUPPLIES (WHOLESALE)',
  'FLOWER LEIS',
  "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers",
  'FLOWER/PLNT-ARTFCL/PRSRVD-MFR SUPL (WHL)',
  'Flowers',
  'FLOWERS & PLANTS-ARTIFICIAL-MFRS',
  'FLOWERS & PLANTS-ARTIFICIAL-WHOLESALE',
  'FLOWERS AND FLORISTS SUPPLIES',
  'FLOWERS-PLANTS & TREES-SILK/DRIED-RENTAL',
  'FLOWERS/PLANTS-PRESERVED SILK DRIED-WHOL',
  'FLUES',
  'FLUID METERS AND COUNTING DEVICES',
  'FLUID MILK',
  'FLUID MILK (MANUFACTURERS)',
  'Fluid Milk Manufacturing',
  'Fluid Power Cylinder and Actuator Manufacturing',
  'FLUID POWER CYLINDERS & ACTUATORS (MFRS)',
  'FLUID POWER CYLINDERS AND ACTUATORS',
  'Fluid Power Pump and Motor Manufacturing',
  'FLUID POWER PUMPS & MOTORS (MFRS)',
  'FLUID POWER PUMPS AND MOTORS',
  'Fluid Power Valve and Hose Fitting Manufacturing',
  'FLUID POWER VALVES AND HOSE FITTINGS',
  'FLUID POWER VALVES/HOSE FITTINGS (MFRS)',
  'FLUORESCENT LGHTNG APPARATUS/FXTR (WHOL)',
  'FLUORSPAR',
  'FLUX-MANUFACTURERS',
  'FLY ASH (MANUFACTURERS)',
  'FOAM & SPONGE RUBBER-RETAIL',
  'FOIL & FOIL PRODUCTS (MANUFACTURERS)',
  'FOIL STAMPING (MANUFACTURERS)',
  'FOLDING MACHINES (MANUFACTURERS)',
  'Folding Paperboard Box Manufacturing',
  'FOLDING PAPERBOARD BOXES',
  'Food (Health) Supplement Stores',
  'Food & Beverages',
  'FOOD AGENTS',
  'FOOD ANALYSIS',
  'Food and Beverage',
  'FOOD BANKS',
  'FOOD BROKERS',
  'FOOD BULK (WHOLESALE)',
  'FOOD CROPS GROWN UNDER COVER',
  'FOOD CROPS GROWN UNDER COVER',
  'Food Delivery',
  'FOOD FACILITIES (WHOLESALE)',
  'FOOD FACILITIES-CONSULTANTS',
  'FOOD MARKETS',
  'FOOD MIXERS (MANUFACTURERS)',
  'FOOD MIXES (WHOLESALE)',
  'FOOD PACKERS SUPPLIES (MANUFACTURERS)',
  'FOOD PLANS',
  'FOOD PREPARATIONS',
  'FOOD PREPARATIONS NEC (MANUFACTURERS)',
  'FOOD PRESERVATION EQUIP & SUPLS (WHOL)',
  'Food Processing',
  'FOOD PROCESSING CONSULTANTS',
  'FOOD PROCESSING EQUIPMENT & SUPLS (WHOL)',
  'FOOD PROCESSING EQUIPMENT & SUPLS-MFRS',
  'FOOD PROCESSORS-HOUSEHOLD (MFRS)',
  'Food Product Machinery Manufacturing',
  'Food Production',
  'FOOD PRODUCTS (WHOLESALE)',
  'FOOD PRODUCTS & MANUFACTURERS',
  'FOOD PRODUCTS MACHINERY',
  'FOOD PRODUCTS-MACHINERY (MANUFACTURERS)',
  'FOOD PRODUCTS-MANUFACTURERS EQUIP (WHOL)',
  'FOOD PRODUCTS-RECONDITIONING',
  'FOOD PRODUCTS-RETAIL',
  'Food Service Contractors',
  'FOOD SERVICE-DISTRIBUTORS',
  'FOOD SERVICE-INDUSTRIAL',
  'FOOD SERVICE-MANAGEMENT',
  'FOOD SERVICE-SUPPLIES (WHOLESALE)',
  'FOOD SPECIALTIES-MANUFACTURERS',
  'FOOD SPECIALTIES-RETAIL',
  'FOOD SPECIALTIES-WHOLESALE',
  'FOOD SUPPLEMENTS',
  'Food Trucks',
  'FOOD-HOME DELIVERY',
  'FOODS-CANNED (MANUFACTURERS)',
  'FOODS-CARRY OUT',
  'FOODS-DEHYDRATED (WHOLESALE)',
  'FOODS-FOREIGN (WHOLESALE)',
  'FOODS-INSTITUTIONAL',
  'FOODS-NATURAL',
  'FOOT APPLIANCES',
  'FOOT APPLIANCES-MANUFACTURERS',
  'FOOT APPLIANCES-WHOLESALE',
  'FOOT REMEDIES (MANUFACTURERS)',
  'FOOTBALL CLUBS',
  'FOOTWEAR',
  'FOOTWEAR',
  'Footwear and Leather Goods Repair',
  'FOOTWEAR CUT STOCK',
  'FOOTWEAR EXCEPT RUBBER NEC (MFRS)',
  'Footwear Manufacturing',
  'Footwear Merchant Wholesalers',
  'FORECLOSURE ASSISTANCE',
  'FOREIGN BANK AND BRANCHES AND AGENCIES',
  'FOREIGN EXCHANGE BROKERS & DEALERS',
  'FOREIGN EXCHANGE STUDENTS ORGANIZATIONS',
  'FOREIGN TRADE AND INTERNATIONAL BANKS',
  'FOREIGN TRADE AND INTERNATIONAL BANKS',
  'FOREIGN TRADE CONSULTANTS',
  'FORENSIC CONSULTANTS',
  'FOREST FIRES',
  'FOREST LAND',
  'FOREST NURSERIES & GATHERING FOREST PROD',
  'Forest Nurseries and Gathering of Forest Products',
  'FOREST PRODUCTS',
  'FORESTERS-CONSULTING',
  'Forestry',
  'FORESTRY & TIMBER EQUIP & SUPLS (WHOL)',
  'FORESTRY SERVICES',
  'FORGING EQUIPMENT (MANUFACTURERS)',
  'FORGING-DIE SINKERS (MANUFACTURERS)',
  'FORGINGS (MANUFACTURERS)',
  'FORK LIFTS-RENTING',
  'Formal Wear and Costume Rental',
  'FORMAL WEAR-RENTAL',
  'FORMAL WEAR-RETAIL',
  'FORMAL WEAR-USED',
  'Fossil Fuel Electric Power Generation',
  'Fossil Fuels',
  'FOSSIL REPLICAS (MANUFACTURERS)',
  'FOSTER CARE',
  'FOUNDATION-CONTRACTORS',
  'FOUNDATION-DRILLING CONTRACTORS',
  'FOUNDATION-EDUC PHILANTHROPIC RESEARCH',
  'FOUNDATION-EXPLORATION & TEST BORINGS',
  'FOUNDATION-FORMS',
  'Foundries',
  'FOUNDRIES-ALUMINUM BRASS BRONZE & ETC',
  'FOUNDRIES-DUCTILE IRON',
  'FOUNDRIES-GRAY IRON',
  'FOUNDRIES-IRON',
  'FOUNDRIES-MAGNESIUM',
  'FOUNDRIES-MALLEABLE IRON',
  'FOUNDRIES-NICKEL & ALLOYS',
  'FOUNDRIES-STEEL',
  'FOUNDRIES-TITANIUM',
  'FOUNDRY EQUIPMENT & SUPPLIES (WHOLESALE)',
  'FOUNTAINS-DRINKING (WHOLESALE)',
  'FOUNTAINS-GARDEN DISPLAY ETC',
  'FOUNTAINS-GARDEN DISPLAY ETC-MFRS',
  'FOUNTAINS-GARDEN DISPLAY ETC-WHOLESALE',
  'FOUR WHEEL DRIVE VEHICLES',
  'FOUR WHEEL DRIVE-PARTS & ACCESSORIES',
  'FOUR WHEEL DRIVE-REPAIRING & SERVICE',
  'FRAGRANCE-MANUFACTURERS',
  'FRAMES-INDUSTRIAL (MANUFACTURERS)',
  'Framing Contractors',
  'FRAMING CONTRACTORS-BUILDINGS',
  'Franchise',
  'FRANCHISING',
  'FRANKFURTER STAND EQUIPMENT (WHOLESALE)',
  'FRANKFURTERS & ROLLS (WHOLESALE)',
  'FRATERNAL ORGANIZATIONS',
  'FRATERNAL REGALIA & SUPPLIES',
  'FRATERNITIES & SORORITIES',
  'Fraud Detection',
  'Freelance',
  'Freemium',
  'Freestanding Ambulatory Surgical and Emergency Centers',
  'FREEZERS-PROVISIONING',
  'FREIGHT BROKERS & AGENTS',
  'Freight Service',
  'FREIGHT TRANSPORTATION ARRANGEMENT',
  'Freight Transportation Arrangement',
  'FREIGHT TRANSPORTATION ON THE GREAT LAKES',
  'FREIGHT-BILL AUDIT',
  'FREIGHT-CONSOLIDATING',
  'FREIGHT-FORWARDING',
  'FREIGHT-INSPECTION',
  'FREIGHT-TRAFFIC CONSULTANTS',
  'FREIGHT-TRAFFIC MANAGERS',
  'FREIGHT-TRAFFIC SERVICE',
  'FREIGHT-TRANSPORT ON THE GREAT LAKES',
  'Fresh Fruit and Vegetable Merchant Wholesalers',
  'FRESH FRUITS AND VEGETABLES',
  'FRESH OR FROZEN PACKAGED FISH',
  'FRICTION MATERIALS (MANUFACTURERS)',
  'FRINGES (MANUFACTURERS)',
  'FROG FARMS',
  'FROZEN BAKERY PRODS-EXCEPT BREAD (MFRS)',
  'FROZEN BAKERY PRODUCTS',
  'Frozen Cakes, Pies, and Other Pastries Manufacturing ',
  "FROZEN CANAPES & HORS D'OEUVRES-MFRS",
  'FROZEN CUSTARD MACHINES (WHOLESALE)',
  'FROZEN FOOD LOCKER PLANTS',
  'FROZEN FOOD PROCESSORS',
  'FROZEN FOODS-RETAIL',
  'FROZEN FOODS-WHOLESALE',
  'FROZEN FRUIT, FRUIT JUICES/VEGS (MFRS) ',
  'Frozen Fruit, Juice, and Vegetable Manufacturing ',
  'FROZEN FRUITS AND VEGETABLES',
  'FROZEN SPECIALTIES',
  'Frozen Specialty Food Manufacturing',
  'Fruit',
  'FRUIT & PRODUCE PACKERS',
  'Fruit and Tree Nut Combination Farming',
  'Fruit and Vegetable Canning',
  'FRUIT AND VEGETABLE MARKETS',
  'Fruit and Vegetable Markets',
  'FRUIT BASKETS-GIFT',
  'FRUIT BASKETS-GIFT-WHOLESALE',
  'FRUIT DRYING',
  'FRUIT JUICES (WHOLESALE)',
  'FRUIT PACKAGES',
  'FRUIT PACKERS SUPPLIES (WHOLESALE)',
  'FRUIT POLLENS (MANUFACTURERS)',
  'FRUIT PROCESSORS',
  'FRUITS & TREE NUTS NEC',
  'FRUITS & VEGETABLES & PRODUCE-RETAIL',
  'FRUITS & VEGETABLES-BROKERS',
  'FRUITS & VEGETABLES-GROWERS & SHIPPERS',
  'FRUITS & VEGETABLES-HARVESTING',
  'FRUITS & VEGETABLES-WHOLESALE',
  'FRUITS AND TREE NUTS',
  'FRUITS-DRIED (WHOLESALE)',
  'FRUITS-GLACE',
  'Fuel',
  'FUEL AUTOMATED SERVICE',
  'Fuel Cell',
  'FUEL DEALERS',
  'Fuel Dealers',
  'FUEL INJECTION EQUIPMENT (REPAIRING)',
  'FUEL INJECTION EQUIPMENT & SERVICE-MFRS',
  'FUEL OIL DEALERS',
  'FUEL-BULK-DEALERS',
  'FUEL-BULK-DELIVERY',
  'FUEL-DEALERS NEC',
  'FUEL-DISTRIBUTORS',
  'FUEL-ECONOMIZERS',
  'FUEL-MANUFACTURERS',
  'FUEL-RETAIL',
  'Full-Service Restaurants',
  'FUNCTIONS RELATED TO DEPOSIT BANKING',
  'FUNCTIONS RELATED TO DEPOSITORY BANKING',
  'FUND RAISING COUNSELORS & ORGANIZATIONS',
  'FUND RAISING GAMES MDSE & SUPLS-MFRS',
  'FUND RAISING MERCHANDISE (WHOLESALE)',
  'Fund-Raising',
  'Funding Platform',
  'FUNERAL ANNOUNCEMENTS',
  'FUNERAL DIRECTORS',
  'FUNERAL DIRECTORS-CARRIER SERVICE',
  'FUNERAL DIRECTORS-EQUIP & SUPLS (WHOL)',
  'FUNERAL ESCORT SERVICE',
  'Funeral Homes and Funeral Services',
  'FUNERAL INFORMATION SERVICE',
  'FUNERAL PLANS (PRE-ARRANGED)',
  'FUNERAL REFERRAL & CONSULTING SERVICES',
  'FUNERAL SERVICE AND CREMATORIES',
  'Funerals',
  'FUNGICIDES',
  'FUR BUSINESS-RETAIL',
  'FUR BUSINESS-WHOLESALE',
  'FUR BUYERS (WHOLESALE)',
  'FUR CLEANING DYEING & GLAZING-WHOLESALE',
  'FUR CLEANING DYEING & STORAGE-RETAIL',
  'FUR CUTTINGS (WHOLESALE)',
  'FUR DRESSERS & DYERS EQUIP/SUPLS (MFRS)',
  'FUR FARMS',
  'FUR FARMS EQUIPMENT & SUPPLIES (WHOL)',
  'FUR FINISHERS & LINERS (MANUFACTURERS)',
  'FUR GOODS',
  'FUR GOODS (MANUFACTURERS)',
  'FUR MATCHING (MANUFACTURERS)',
  'Fur-Bearing Animal and Rabbit Production',
  'FUR-BEARING ANIMALS AND RABBITS',
  'FUR-DEALERS-USED',
  'FUR-DEALERS-WHOLESALE',
  'FUR-DESIGNERS',
  'FUR-REMODELING & REPAIRING',
  'FUR-RENTING',
  'FUR-SHEARING',
  'FUR-SKIN-DEALERS & BROKERS',
  'FUR-STORAGE',
  'FUR-STORAGE-WHOLESALE',
  'FURNACE ARCHES-SUSPENDED',
  'FURNACES-HEATING (WHOLESALE)',
  'FURNACES-INDUSTRIAL (MANUFACTURERS)',
  'FURNACES-PARTS & SUPPLIES (WHOLESALE)',
  'FURNACES-REPAIRING & CLEANING',
  'FURNITURE',
  'Furniture',
  'FURNITURE & FIXTURES NEC (MFRS)',
  'FURNITURE ACCESSORIES & DECOR-WHOLESALE',
  'FURNITURE ACCESSORIES DECOR-MFRS',
  'FURNITURE AND FIXTURES',
  'FURNITURE BUYERS (WHOLESALE)',
  'FURNITURE COMPONENTS (MANUFACTURERS)',
  'FURNITURE FINISHING MATERIALS (MFRS)',
  'FURNITURE FRAMES (MANUFACTURERS)',
  'FURNITURE FRAMES-WHOLESALE',
  'FURNITURE GRILLES',
  'FURNITURE LEGS-MANUFACTURERS',
  'FURNITURE LEGS-RETAIL',
  'FURNITURE LEGS-WHOLESALE',
  'Furniture Merchant Wholesalers',
  'FURNITURE MOVERS',
  'FURNITURE PADS (MANUFACTURERS)',
  'FURNITURE REPRESENTATIVES (WHOLESALE)',
  'FURNITURE STORES',
  'Furniture Stores',
  'FURNITURE-BRACES-MANUFACTURERS',
  'FURNITURE-CHILDRENS',
  'FURNITURE-CHILDRENS-MANUFACTURERS',
  'FURNITURE-CHILDRENS-WHOLESALE',
  'FURNITURE-DEALERS-RETAIL',
  'FURNITURE-DEALERS-SHOWROOMS',
  'FURNITURE-DEALERS-WHOLESALE',
  'FURNITURE-DESIGNERS & CUSTOM BUILDERS',
  'FURNITURE-EXPOSITION MARTS',
  'FURNITURE-HARDWARE-WHOLESALE',
  'FURNITURE-MANUFACTURERS',
  'FURNITURE-MANUFACTURERS EQUIP/SUPLS-MFRS',
  'FURNITURE-MANUFACTURERS-OFFICE & COML',
  'FURNITURE-METAL',
  "FURNITURE-MFRS' EQUIP & SUPLS (WHOL)",
  'FURNITURE-OUTDOOR',
  'FURNITURE-OUTDOOR-MANUFACTURERS',
  'FURNITURE-OUTDOOR-RECOVERING & REPAIRING',
  'FURNITURE-OUTDOOR-WHOLESALE',
  'FURNITURE-RENTING & LEASING',
  'FURNITURE-REPAIRING & REFINISHING',
  'FURNITURE-REPAIRING & REFINISHING SUPLS',
  'FURNITURE-REPAIRING/REFINISH SUPLS-MFRS',
  'FURNITURE-REPAIRING/REFINISH SUPLS-WHOL',
  'FURNITURE-STRIPPING',
  'FURNITURE-UNFINISHED',
  'FURNITURE-UNFINISHED-MANUFACTURERS',
  'FURNITURE-UNFINISHED-WHOLESALE',
  'FURNITURE-USED',
  'FURNITURE-WICKER',
  'FUSES-BLASTING-MANUFACTURERS',
  'FUSES-ELECTRIC (WHOLESALE)',
  'FUSES-ELECTRIC-MANUFACTURERS',
  'FUTONS',
  'FUTURES-EXCHANGES',
  'G P S NAVIGATION SYSTEMS & SERVICES',
  'GALVANIZING (MANUFACTURERS)',
  'Gambling',
  'Gambling & Casinos',
  'GAMBLING ABUSE/ADDICTION INFO/TREATMENT',
  'GAME BIRDS VENISON ETC',
  'GAME DESIGNERS',
  'GAME FARMS',
  'GAME PROCESSING',
  'GAMES',
  'GAMES & GAME SUPPLIES',
  'GAMES & GAME SUPPLIES-MANUFACTURERS',
  'GAMES & GAME SUPPLIES-WHOLESALE',
  "GAMES TOYS & CHILDREN'S VEHICLES (MFRS)",
  'Gamification',
  'Gaming',
  'GAMING CONSULTANTS',
  'GAMING EQUIPMENT & SUPPLIES (WHOLESALE)',
  'GAMING EQUIPMENT & SUPPLIES-MFRS',
  'GARAGE BUILDERS',
  'GARAGE DOORS-REPAIRING',
  'GARAGE EQUIPMENT (WHOLESALE)',
  'GARAGE EXHAUST SYSTEMS (MANUFACTURERS)',
  'GARAGE MANAGEMENT',
  'GARBAGE COLLECTION',
  'GARBAGE COLLECTION & DISPOSAL EQUIP-MFRS',
  'GARBAGE COLLECTION EQUIPMENT (WHOLESALE)',
  'GARBAGE CONTAINER RECEPTACLES (WHOL)',
  'GARBAGE DISPOSAL EQPT-SUPLS/PARTS (WHOL)',
  'GARBAGE DISPOSAL EQUIP-IND & COML (WHOL)',
  'GARBAGE DISPOSAL EQUIPMENT-HOUSEHOLD',
  'GARBAGE DISPOSAL EQUIPMENT-MANUFACTURERS',
  'GARBAGE DISPOSAL EQUIPMENT-SVC & REPAIR',
  'GARBAGE DISPOSAL EQUIPMENT-WHOLESALE',
  'GARBAGE/RUBBISH RMVL CONTRS EQUIP (WHOL)',
  'GARDEN & LAWN EQUIPMENT & SUPLS-MFRS',
  'GARDEN CENTERS',
  'GARDEN ORNAMENTS',
  'GARLIC (MANUFACTURERS)',
  'GARMENT BAGS & COVERS (FABRIC-MFRS)',
  'GARMENT BAGS & COVERS (PLASTIC-MFRS)',
  'GARMENT CURING',
  'GARMENT HANGERS-WHOLESALE',
  "GARMENT PRESSING AND CLEANERS' AGENTS",
  'GARMENT RACKS-MANUFACTURERS',
  'GARMENT RACKS-RENTAL',
  'GARMENT STORAGE',
  'GARMENT-PRINTING & LETTERING',
  'GARTERS-WHOLESALE',
  'GAS (LPG) BOTTLED BULK EQUIP/SUPL (WHOL)',
  'GAS & OTHER SERVICES-COMBINED',
  'GAS AND OTHER SERVICES COMBINED',
  'GAS APPLIANCES',
  'GAS APPLIANCES-SERVICING',
  'GAS BOOSTERS',
  'GAS BURNERS (WHOLESALE)',
  'GAS BURNERS-MANUFACTURERS',
  'GAS BURNERS-SERVICE',
  'GAS BURNERS-SUPPLIES & PARTS (WHOLESALE)',
  'GAS COMPANIES',
  'GAS COMPRESSOR STATIONS (WHOLESALE)',
  'GAS DETECTORS',
  'GAS EQUIPMENT (WHOLESALE)',
  'GAS EQUIPMENT-INDUSTRIAL (MANUFACTURERS)',
  'GAS EQUIPMENT-SERVICING',
  'GAS FITTERS',
  'GAS GENERATING MACHINES (MANUFACTURERS)',
  'GAS LIGHTS (WHOLESALE)',
  'GAS LIGHTS-MANUFACTURERS',
  'GAS LINE INSTALLATION & REPAIR',
  'GAS LOGS',
  'GAS MEASUREMENT SERVICE',
  'GAS ODORANTS (MANUFACTURERS)',
  'GAS PLANT EQUIPMENT (WHOLESALE)',
  'GAS PLANTS',
  'GAS PROCESSORS',
  'GAS PRODUCERS',
  'GAS PRODUCTION AND/OR DISTRIBUTION',
  'GAS PURIFIERS (MANUFACTURERS)',
  'GAS RECYCLING PLANTS',
  'GAS SYSTEMS',
  'GAS TANKS-REPAIRING',
  'GAS TANKS-SALES & SERVICE (WHOLESALE)',
  'GAS TESTING-MEDICAL',
  'GAS TRANSMISSION AND DISTRIBUTION',
  'GAS TRANSMISSION COMPANIES',
  'GAS WELL CLEANERS',
  'GAS WELL DRILLING',
  'GAS WELL PLUGGING',
  'GAS-CARD LOCK (WHOLESALE)',
  'GAS-DIESEL',
  'GAS-IND & MEDICAL-CYLINDER & BULK-MFRS',
  'GAS-INDSTRL/MED-CYLINDER & BULK (WHOL)',
  'GAS-LEADED/LEAD-FREE',
  'GAS-LIQUEFIED PETRO-BTTLD/BULK (WHOL)',
  'GAS-LIQUEFIED PETROLEUM ETC-EQUIP/SUPLS',
  'GAS-LIQUID PETRO-CARBURETION SYST (MFR)',
  'GAS-NATURAL',
  'GAS-PROPANE-REFILLING STATIONS',
  'GASES (WHOLESALE)',
  'GASKET CUTTING EQUIPMENT (MANUFACTURERS)',
  'Gasket, Packing, and Sealing Device Manufacturing ',
  'GASKETS (WHOLESALE)',
  'GASKETS PACKING AND SEALING DEVICES',
  'GASKETS-MANUFACTURERS',
  'GASKETS-PACKING & SEALING DEVICES (MFRS)',
  'GASOLINE & OIL BULK STATIONS (WHOL)',
  'GASOLINE & OIL-WHOLESALE',
  'GASOLINE ADDITIVE-DEALERS',
  'GASOLINE ADDITIVE-DISTRIBUTORS',
  'GASOLINE ADDITIVE-MANUFACTURERS',
  'GASOLINE PLANT EQUIPMENT (MANUFACTURERS)',
  'GASOLINE SERVICE STATIONS',
  'Gasoline Stations with Convenience Stores',
  'GASOLINE SVC STATION EQUIP-INSTALLING',
  'GATES (WHOLESALE)',
  'GATES-MANUFACTURERS',
  'GAUGES & GAGES (WHOLESALE)',
  'GAUGES & GAGES-MANUFACTURERS',
  'GAUGES-ACCESSORIES & PARTS (MFRS)',
  'GAUGES-REPAIRING',
  'GAVELS (WHOLESALE)',
  'GAY & LESBIAN ORGANIZATIONS',
  'GAZEBO BUILDERS',
  'GEARS & GEAR CUTTING (MANUFACTURERS)',
  'GELATINE (MANUFACTURERS)',
  'GEMOLOGISTS',
  'GEMS & MINERALS',
  'GENEALOGISTS',
  'General Automotive Repair',
  'GENERAL AUTOMOTIVE REPAIR SHOPS',
  'GENERAL CONTRACTORS',
  'GENERAL CONTRACTORS-NONRESIDENTIAL BLDGS',
  'GENERAL CONTRACTORS-RESIDENTIAL BLDGS',
  'GENERAL CONTRS-INDUSTRIAL BLDGS & WRHSE',
  'GENERAL FARMS',
  'GENERAL FARMS',
  'GENERAL FARMS-PRIMARILY CROP',
  'General Freight Trucking, Local ',
  'General Freight Trucking, Long-Distance, Less Than Truckload ',
  'General Freight Trucking, Long-Distance, Truckload ',
  'GENERAL GOVERNMENT',
  'GENERAL IND MACHINERY/EQUIP NEC (MFRS)',
  'GENERAL INDUSTRIAL MACHINERY',
  'General Line Grocery Merchant Wholesalers',
  'GENERAL LIVESTOCK',
  'GENERAL LIVESTOCK-EXCEPT DAIRY/POULTRY',
  'GENERAL MEDICAL AND SURGICAL HOSPITALS',
  'General Medical and Surgical Hospitals',
  'GENERAL MERCHANDISE-RETAIL',
  'GENERAL MERCHANDISE-WHOLESALE',
  'General Rental Centers',
  'GENERAL WAREHOUSING AND STORAGE',
  'General Warehousing and Storage',
  'Generation Y',
  'Generation Z',
  'GENERATORS-DIESEL',
  'GENERATORS-ELECTRIC (WHOLESALE)',
  'GENERATORS-ELECTRIC-MANUFACTURERS',
  'GENERATORS-ELECTRIC-RENTING',
  'GENERATORS-ELECTRIC-REPAIRING',
  'GENERATORS-STEAM (MANUFACTURERS)',
  'GENERATORS-WIND POWERED (MANUFACTURERS)',
  'Genetics',
  'GEOCHEMISTS',
  'GEOGRAPHERS',
  'GEOLOGICAL CONSULTANTS',
  'GEOLOGICAL LABORATORIES',
  'GEOLOGISTS SUPPLIES (WHOLESALE)',
  'GEOPHYSICAL APPARATUS (WHOLESALE)',
  'Geophysical Surveying and Mapping Services',
  'GEOPHYSICISTS',
  'Geospatial',
  'GEOTECHNICAL APPARATUS & SUPPLIES',
  'GEOTEXTILES (WHOLESALE)',
  'Geothermal Electric Power Generation',
  'GEOTHERMAL EXPLORATION',
  'GEOTHERMAL HTG/COOLING EQUIP/SYSTS-DLRS',
  'GEOTHERMAL HTG/COOLING EQUIP/SYSTS-MFRS',
  'GERONTOLOGISTS',
  'GIFT',
  'Gift',
  'GIFT BASKETS & PARCELS',
  'GIFT BASKETS & PARCELS-MANUFACTURERS',
  'GIFT BASKETS & PARCELS-WHOLESALE',
  'Gift Card',
  'GIFT CERTIFICATES-PREPRINTED (MFRS)',
  'Gift Exchange',
  'Gift Registry',
  'GIFT REMINDER SERVICE',
  'GIFT SHOPS',
  'GIFT WRAPPING MATERIALS',
  'GIFT WRAPPING MATERIALS-MANUFACTURERS',
  'GIFT WRAPPING MATERIALS-WHOLESALE',
  'GIFT WRAPPING SERVICE',
  'Gift, Novelty, and Souvenir Stores ',
  'GIFTWARES-MANUFACTURERS',
  'GIFTWARES-WHOLESALE',
  'GINSENG',
  'GIRDLES & BRAS-WHOLESALE',
  "GIRL'S AND CHILDREN'S DRESSES",
  "GIRL'S AND CHILDREN'S OUTERWEAR",
  'GIRLS APPAREL',
  'GIRLS APPAREL-MANUFACTURERS',
  'GIRLS CHLDRNS INFNTS DRESSES/SHRTS (MFR)',
  'Glass and Glazing Contractors',
  'GLASS AND GLAZING WORK',
  'GLASS BEADS-INDUSTRIAL (WHOLESALE)',
  'GLASS BENDING DRILLING & GRINDING & ETC',
  'GLASS BLOCK',
  'GLASS COATING & TINTING',
  'GLASS COATING & TINTING MATERIALS (WHOL)',
  'GLASS COATING & TINTING MATERIALS-MFRS',
  'Glass Container Manufacturing',
  'GLASS CONTAINERS',
  'GLASS CONTAINERS (MANUFACTURERS)',
  'GLASS DECORATORS (MANUFACTURERS)',
  'GLASS FURNITURE TOPS',
  'GLASS MAKING & WORKING MACHINERY (MFRS)',
  'GLASS PROD MADE-PURCHASED GLASS (MFRS)',
  'Glass Product Manufacturing Made of Purchased Glass',
  'GLASS PRODUCTS (MANUFACTURERS)',
  'GLASS RETAIL',
  'GLASS SUBSTITUTES (MANUFACTURERS)',
  'GLASS SUN ROOMS',
  'GLASS-AUTO PLATE & WINDOW & ETC',
  'GLASS-BLOCK STRUCTURAL & ETC (MFRS)',
  'GLASS-BLOWERS (MANUFACTURERS)',
  'GLASS-BOARD-UP SERVICE',
  'GLASS-BROKEN',
  'GLASS-CARVED ORNAMENTAL BEVELED ETC',
  'GLASS-CIRCLES & OTHER SPECIAL SHAPES',
  'GLASS-GRINDING/CUTTING MACHINERY (MFRS)',
  'GLASS-HAND BLOWN ART',
  'GLASS-LETTERING & APPLIED COLOR (MFRS)',
  'GLASS-MANUFACTURERS',
  'GLASS-PRESSED MOLDED & ETC (MFRS)',
  'GLASS-REPAIRING',
  'GLASS-RODS & TUBING (MANUFACTURERS)',
  'GLASS-STAINED & LEADED',
  'GLASS-STAINED & LEADED-MANUFACTURERS',
  'GLASS-STAINED & LEADED-WHOLESALE',
  'GLASS-TEMPERED',
  'GLASS-WHOLESALE',
  'Glass, Ceramics & Concrete',
  'GLASSINE PAPER PRODUCTS (MANUFACTURERS)',
  'GLASSWARE DECORATION (MANUFACTURERS)',
  'GLASSWARE-MANUFACTURERS',
  'GLASSWARE-WHOLESALE',
  'GLAZIERS',
  'GLAZIERS SUPPLIES',
  'GLAZING COMPOUNDS (MANUFACTURERS)',
  'GLIDERS (MANUFACTURERS)',
  'GLOBAL POSITIONING SYSTEMS',
  'GLOVES & MITTENS-DRESS (WHOLESALE)',
  'GLOVES-CLEANING & REPAIRING',
  'GLOVES-MANUFACTURERS SUPPLIES (WHOL)',
  'GLOVES-RETAIL',
  'GLOVES-SURGICAL & EXAMINATION (WHOL)',
  'GLOVES-WHOLESALE',
  'GLOVES-WOMENS-MANUFACTURERS',
  'GLOVES-WORK & INDUSTRIAL',
  'GLOVES-WORK & INDUSTRIAL-MANUFACTURERS',
  'Goat Farming',
  'GOATS MILK',
  'GOGGLES-INDUSTRIAL (MANUFACTURERS)',
  'GOLD LEAF (MANUFACTURERS)',
  'GOLD NUGGET JEWELRY-MANUFACTURERS',
  'GOLD NUGGET JEWELRY-RETAIL',
  'GOLD NUGGET JEWELRY-WHOLESALE',
  'Gold Ore Mining',
  'GOLD ORES',
  'GOLD ORES',
  'GOLD PANNING',
  'GOLD SILVER & PLATINUM-BUYERS',
  'GOLD SILVER & PLATINUM-DEALERS',
  'GOLD STAMPING',
  'GOLDSMITH & SILVERSMITH PRODUCTS',
  'Golf',
  'GOLF CARS & CARTS',
  'GOLF CARS & CARTS-MANUFACTURERS',
  'GOLF CARS & CARTS-WHOLESALE',
  'GOLF COURSE ARCHITECTS',
  'GOLF COURSE CONSTRUCTION',
  'GOLF COURSE CONSULTANTS',
  'GOLF COURSE EQUIPMENT (WHOLESALE)',
  'Golf Courses and Country Clubs',
  'GOLF COURSES-MINIATURE',
  'GOLF COURSES-PRIVATE',
  'GOLF COURSES-PUBLIC',
  'GOLF EQUIPMENT & SUPPLIES-MANUFACTURERS',
  'GOLF EQUIPMENT & SUPPLIES-RETAIL',
  'GOLF EQUIPMENT & SUPPLIES-WHOLESALE',
  'GOLF EQUIPMENT-RENTALS',
  'GOLF EQUIPMENT-REPAIRING & REFINISHING',
  'GOLF INSTRUCTION',
  'GOLF ORGANIZATIONS',
  'GOLF PRACTICE RANGES',
  'GOLF TOURNAMENT BOOKING SERVICE',
  'GOLF VACATION PACKAGES',
  'Google',
  'Google Glass',
  'GOURMET COOKWARE',
  'GOURMET SHOPS',
  'Government',
  'Government Administration',
  'Government and Military',
  'GOVERNMENT OFCS-AUTHORITIES/COMMISSIONS',
  'GOVERNMENT OFFICES-CITY, VILLAGE & TWP ',
  'GOVERNMENT OFFICES-COUNTY',
  'GOVERNMENT OFFICES-INDIAN',
  'GOVERNMENT OFFICES-STATE',
  'GOVERNMENT OFFICES-US',
  'Government Relations',
  'GOVERNMENT-ARCHITECTURAL SERVICES',
  'GOVERNMENT-BOOK STORES',
  'GOVERNMENT-COLLEGES & PROFESSIONAL SCHLS',
  'GOVERNMENT-COMMERCIAL PRINTING-GRAVURE',
  'GOVERNMENT-CONTRACT CONSULTANTS',
  'GOVERNMENT-CONTRACTORS',
  'GOVERNMENT-FORESTRY SERVICES',
  'GOVERNMENT-INDIVIDUAL/FAMILY SOCIAL SVCS',
  'GOVERNMENT-INSURANCE CARRIERS NEC',
  'GOVERNMENT-JOB TRAINING/VOC REHAB SVCS',
  'GOVERNMENT-NATIONAL CEMETERIES',
  'GOVERNMENT-NONCOMMERCIAL RESEARCH ORGS',
  'GOVERNMENT-PENSION HEALTH/WELFARE FUNDS',
  'GOVERNMENT-PUBLISHING NEC',
  'GOVERNMENT-RADIO BROADCASTING STATIONS',
  'GOVERNMENT-RAILROADS LINE-HAUL OPERATORS',
  'GOVERNMENT-REGIONAL AGENCIES',
  'GOVERNMENT-RELATIONS CONSULTANTS',
  'GOVERNMENT-SPECIALTY HOSP EX PSYCHIATRIC',
  'GOVERNMENT-TRUSTS EXCEPT EDUCATIONAL',
  'GOVERNMENT-WEATHER AGENCIES',
  'GOVERNORS (ENGINE PARTS-WHOLESALE)',
  'GOVT-ARBORETA BOTANICAL/ZOOLOGICAL GRDN',
  'GOVT-COML PHYSICAL/BIOLOGICAL RESEARCH',
  'GOVT-COMPUTER PROCESSING & PREPARATION',
  'GOVT-SECURITY BROKERS DLRS/FLOTATION CO',
  'GovTech',
  'GOWNS',
  'GPS',
  'GPU',
  'GRADING CONTRACTORS',
  'GRADUATION SUPPLIES',
  'GRAFFITI REMOVAL & PROTECTION SERVICES',
  'Grain and Field Bean Merchant Wholesalers',
  'GRAIN AND FIELD BEANS',
  'GRAIN BINS',
  'GRAIN BROKERS',
  'GRAIN CLEANING',
  'GRAIN DRYERS & DRYING EQUIPMENT (WHOL)',
  'GRAIN DRYING',
  'GRAIN ELEVATORS',
  'GRAIN ELEVATORS-BUILDERS',
  'GRAIN ELEVATORS-EQUIP & SUPPLIES (WHOL)',
  'GRAIN ELEVATORS-REPAIRING',
  'GRAIN EXCHANGES',
  'GRAIN FUMIGATION',
  'GRAIN HANDLING EQUIPMENT (MANUFACTURERS)',
  'GRAIN HAULING',
  'GRAIN-DEALERS (WHOLESALE)',
  'GRAINING',
  'GRANITE (WHOLESALE)',
  'GRANITE PRODUCTS-MANUFACTURERS',
  'GRANITE-DECOMPOSED',
  'Grantmaking Foundations',
  'Grape Vineyards',
  'GRAPES',
  'Graphic Design',
  'Graphic Design Services',
  'GRAPHIC DESIGNERS',
  'GRAPHIC DESIGNERS-COMPUTER',
  'GRAPHIC SERVICES',
  'GRAPHITE (MANUFACTURERS)',
  'GRASS',
  'GRATINGS (MANUFACTURERS)',
  'GRAVEL HAULING',
  'GRAY & DUCTILE IRON FOUNDRIES',
  'GRAY AND DUCTILE IRON FOUNDRIES',
  'GREASE TRAPS (CLEANING SERVICE)',
  'GREASE TRAPS & SAND TRAPS-SERVICE',
  'GREASE TRAPS-MANUFACTURERS',
  'GREASES (WHOLESALE)',
  'GREEK FOOD PRODUCTS',
  'Green Building',
  'Green Consumer Goods',
  'GREENHOUSE BUILDERS',
  'GREENHOUSE EQUIPMENT & SUPPLIES (WHOL)',
  'GREENHOUSES',
  'GREENHOUSES-MANUFACTURERS',
  'GREENHOUSES-WHOLESALE',
  'GreenTech',
  'Greeting Card Publishers',
  'GREETING CARD-MANUFACTURERS SUPLS (MFRS)',
  'GREETING CARDS',
  'GREETING CARDS-MANUFACTURERS',
  'GREETING CARDS-RETAIL',
  'GREETING CARDS-WHOLESALE',
  'GREETING SERVICE',
  'GRILLES (MANUFACTURERS)',
  'GRILLES & REGISTERS & DIFFUSERS (WHOL)',
  'GRILLS-GAS & ELECTRIC',
  'GRINDING MACHINES & EQUIP-MANUFACTURERS',
  'GRINDING MACHINES & EQUIPMENT (WHOL)',
  'GRINDING WHEEL DRESSERS (MANUFACTURERS)',
  'GRINDING WHEELS (MANUFACTURERS)',
  'GRINDING-CENTERLESS',
  'GRINDING-PRECISION & PRODUCTION',
  'GRISTMILLS',
  'GROCERIES',
  'GROCERIES & RELATED PRODUCTS NEC (WHOL)',
  'GROCERIES AND RELATED PRODUCTS',
  'GROCERS-ETHNIC FOODS',
  'GROCERS-RETAIL',
  'GROCERS-WHOLESALE',
  'Grocery',
  'GROCERY STORES',
  'GROMMETS (WHOLESALE)',
  'Ground or Treated Mineral and Earth Manufacturing',
  'Group Buying',
  'GROUP HOMES',
  'GROUTING COMPOUNDS',
  'GROUTING COMPOUNDS-MANUFACTURERS',
  'GROUTING CONTRACTORS',
  'GUARD DOGS',
  'GUARD RAILS',
  'GUARDIANSHIP SERVICES',
  'GUARDS-DOOR & WINDOW',
  'GUARDS-DOOR & WINDOW-MANUFACTURERS',
  'GUARDS-DOOR & WINDOW-WHOLESALE',
  'GUARDS-MACHINE (MANUFACTURERS)',
  'GUEST HOUSES',
  'GUEST RANCHES',
  'GUIDE SERVICE',
  'GUIDED MISSILE & SPACE VEHICLE (MFRS)',
  'Guided Missile and Space Vehicle Manufacturing',
  'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing',
  'GUIDED MISSILE/SPC VHCL-PARTS NEC (MFRS)',
  'GUIDED MISSILES AND SPACE VEHICLES',
  'GUIDED MSSLE/SPACE VHCL-PROP UNITS (MFR)',
  'Guides',
  'GUITARS',
  'GUM & WOOD CHEMICALS (MANUFACTURERS)',
  'GUM AND WOOD CHEMICALS',
  'GUMMED TAPE (MANUFACTURERS)',
  'GUMS (WHOLESALE)',
  'GUN CLUBS',
  'GUN SAFETY & MARKSMANSHIP INSTRUCTION',
  'GUNITE CONTRACTORS',
  'GUNS & GUNSMITHS',
  'GUNS & GUNSMITHS-WHOLESALE',
  'GUNSIGHTS SCOPES & MOUNTS',
  'GUNSTOCK-MANUFACTURERS',
  'GUTTER & DOWNSPOUT CLEANING',
  'GUTTERS & DOWNSPOUTS',
  'GUTTERS & DOWNSPOUTS-MANUFACTURERS',
  'GUTTERS & DOWNSPOUTS-WHOLESALE',
  'GYMNASIUM EQUIPMENT & SUPPLIES-MFRS',
  'GYMNASIUMS',
  'GYMNASIUMS EQUIPMENT & SUPPLIES',
  'GYMNASTIC EQUIPMENT & SUPPLIES (WHOL)',
  'GYMNASTIC INSTRUCTION',
  'GYPSUM & GYPSUM PRODUCTS (MANUFACTURERS)',
  'Gypsum Product Manufacturing',
  'GYPSUM PRODUCTS',
  'GYPSUM PRODUCTS (MANUFACTURERS)',
  'GYROSCOPES (MANUFACTURERS)',
  'HAIR CLINICS',
  'HAIR CLIPPERS (WHOLESALE)',
  'HAIR DRYERS (WHOLESALE)',
  'HAIR DRYERS-MANUFACTURERS',
  'HAIR DRYERS-WHOLESALE',
  'HAIR GOODS & SUPPLIES-RETAIL',
  'HAIR GOODS-MANUFACTURERS',
  'HAIR GOODS-WHOLESALE',
  'HAIR ORNAMENTS',
  'HAIR ORNAMENTS-MANUFACTURERS',
  'HAIR ORNAMENTS-WHOLESALE',
  'HAIR PINS (MANUFACTURERS)',
  'HAIR PREPARATIONS (WHOLESALE)',
  'HAIR REMOVING',
  'HAIR REPLACEMENT',
  'HAIR TREATMENT',
  'HAIR WEAVING',
  'HAIR-ANIMAL (WHOLESALE)',
  'HAIRDRESSING SUPPLIES (WHOLESALE)',
  'HALFTONE PRINTS-SCREENED',
  'HALLS & AUDITORIUMS',
  'HAMBURGER & HOT DOG STANDS',
  'HAMMOCKS',
  'HAMMOCKS-MANUFACTURERS',
  'HAMMOCKS-WHOLESALE',
  'HAMPERS-WHOLESALE',
  'HAMS',
  'HAND AND EDGE TOOLS',
  'HAND THERAPY',
  'HAND TRUCK (MANUFACTURERS)',
  'HANDBAG-FRAMES & FITTINGS (MFRS)',
  'HANDBAGS',
  'HANDBAGS-MANUFACTURERS',
  'HANDBAGS-MFRS EQUIPMENT & SUPLS (MFRS)',
  'HANDBAGS-REPAIRING',
  'HANDBAGS-WHOLESALE',
  'HANDBALL COURTS',
  'HANDICAPPED & DISABLED APPAREL',
  'HANDICAPPED EQUIPMENT-SALES & SVC (WHOL)',
  'HANDICAPPED SERVICES & ORGANIZATIONS',
  'HANDICAPPED TRANSPORTATION SERVICE',
  'HANDICRAFTS-MANUFACTURERS',
  'HANDKERCHIEFS (MANUFACTURERS)',
  'HANDLES-MANUFACTURERS',
  'HANDLES-WHOLESALE',
  'Handmade',
  'HANDWRITING EXPERTS',
  'HANDYMAN SERVICES',
  'HANG GLIDERS',
  'HANGERS-GARMENT (MANUFACTURERS)',
  'HARD SURFACE FLOOR COVERINGS',
  'HARDBOARD-DEALERS',
  'HARDBOARD-FABRICATORS & MANUFACTURERS',
  'HARDWARE',
  'HARDWARE',
  'Hardware',
  'HARDWARE CONSULTANTS',
  'Hardware Manufacturing',
  'Hardware Merchant Wholesalers',
  'HARDWARE NEC (MANUFACTURERS)',
  'HARDWARE STORES',
  'Hardware Stores',
  'HARDWARE-FURNITURE-MANUFACTURERS',
  'HARDWARE-MANUFACTURERS',
  'HARDWARE-MARINE',
  'HARDWARE-MARINE-MANUFACTURERS',
  'HARDWARE-RETAIL',
  'HARDWARE-WHOLESALE',
  'HARDWOOD DIMENSION & FLOORING MILLS',
  'HARDWOOD DIMENSION AND FLOORING MILLS',
  'HARDWOOD VENEER AND PLYWOOD',
  'Hardwood Veneer and Plywood Manufacturing',
  'HARDWOODS',
  'HARDWOODS-WHOLESALE',
  'HARVESTING EQUIPMENT-MANUFACTURERS',
  'HARVESTING-CONTRACT',
  'HAT RACKS',
  'HATCHES & SCUTTLES (WHOLESALE)',
  'HATS',
  'HATS CAPS & MILLINERY (MANUFACTURERS)',
  'HATS-CLEANERS & RENOVATORS',
  'HATS-MANUFACTURERS',
  'HATS-RETAIL',
  'HATS-WHOLESALE',
  'HATTERS EQUIPMENT & SUPPLIES (WHOLESALE)',
  'HAUNTED HOUSES',
  'HAWAIIAN FOODS',
  'HAWAIIAN GOODS',
  'HAWAIIAN WEAR-MANUFACTURERS',
  'HAWAIIAN WEAR-RETAIL',
  'HAWAIIAN WEAR-WHOLESALE',
  'HAY & ALFALFA (WHOLESALE)',
  'HAY BALING SERVICE',
  'HAY EQUIPMENT & SERVICE (WHOLESALE)',
  'Hay Farming',
  'HAY GRINDING',
  'HAY HAULING',
  'HAY RIDES',
  'HAZARDOUS CHEMICAL CONSULTANTS',
  'HAZARDOUS MATERIAL STORAGE EQUIPMENT',
  'HAZARDOUS MATERIALS CONSULTANTS',
  'HAZARDOUS MATERIALS-PACKING & STORAGE',
  'Hazardous Waste Collection',
  'Hazardous Waste Treatment and Disposal',
  'HEADBOARDS-MANUFACTURERS',
  'HEALING PRACTITIONERS',
  'HEALTH & ALLIED SERVICES',
  'HEALTH & BEAUTY AID PRODUCTS-WHOLESALE',
  'HEALTH & BEAUTY AIDS-MANUFACTURERS',
  'HEALTH & BEAUTY CONSULTANTS',
  'HEALTH & DIET FOODS-RETAIL',
  'HEALTH & FITNESS PROGRAM CONSULTANTS',
  'HEALTH & FITNESS THERAPY',
  'HEALTH & NUTRITION CONSULTANTS',
  'HEALTH & WELFARE AGENCIES',
  'HEALTH AND ALLIED SERVICES',
  'Health and Welfare Funds',
  'HEALTH APPLIANCES (WHOLESALE)',
  'Health Care',
  'HEALTH CARE ALTERNATIVES',
  'HEALTH CARE FACILITIES',
  'HEALTH CARE INSTRUCTION',
  'HEALTH CARE MANAGEMENT',
  'HEALTH CARE PRODUCTS',
  'HEALTH CARE UTILIZATION REVIEW',
  'HEALTH CLUBS STUDIOS & GYMNASIUMS',
  'Health Diagnostics',
  'HEALTH EDUCATION',
  'HEALTH EQUIPMENT & SUPLS-MANUFACTURERS',
  'HEALTH EQUIPMENT & SUPPLIES-WHOLESALE',
  'HEALTH FACILITIES',
  'HEALTH FOOD PRODUCTS-MANUFACTURERS',
  'HEALTH FOOD PRODUCTS-WHOLESALE',
  'HEALTH INFORMATION & REFERRAL PROGRAMS',
  'Health Insurance',
  'HEALTH MAINTENANCE ORGANIZATIONS',
  'HEALTH PLANS',
  'HEALTH RECORDING SERVICE',
  'HEALTH RELATED FACILITIES',
  'HEALTH RESORTS',
  'HEALTH SERVICES',
  'HEALTH SERVICES-EXTENDED CARE',
  'HEALTH SYSTEMS EVALUATION CONSULTANTS',
  'Health, Wellness and Fitness',
  'HEARING & SOUND LEVEL TESTING',
  'HEARING AIDS & DEVICES-WHOLESALE',
  'HEARING AIDS-MANUFACTURERS',
  'HEARING AIDS-PARTS & REPAIRING',
  'HEARING AIDS-RENTAL',
  'HEARING IMPAIRED EQUIPMENT & SUPPLIES',
  'HEARING TESTING-INDUSTRIAL',
  'HEAT EXCHANGERS (WHOLESALE)',
  'HEAT EXCHANGERS-MANUFACTURERS',
  'HEAT GUNS (MANUFACTURERS)',
  'HEAT PUMPS',
  'HEAT PUMPS-MANUFACTURERS',
  'HEAT PUMPS-WHOLESALE',
  'HEAT RECOVERY EQUIPMENT & SYSTEMS (WHOL)',
  'HEAT RECOVERY EQUIPMENT & SYSTEMS-MFRS',
  'HEAT SEALING (WHOLESALE)',
  'HEAT SUPPLYING COMPANIES',
  'HEAT TRANSFER MATERIALS (MANUFACTURERS)',
  'HEAT TREATING METAL (MANUFACTURERS)',
  'HEAT TREATING METAL EQUIP/SUPLS (MFRS)',
  'HEATERS-AUTOMOTIVE (WHOLESALE)',
  'HEATERS-CONSTRUCTION (WHOLESALE)',
  'HEATERS-INDUSTRIAL (MANUFACTURERS)',
  'HEATERS-UNIT-DEALERS (WHOLESALE)',
  'HEATERS-UNIT-DISTRIBUTORS',
  'HEATERS-UNIT-MANUFACTURERS',
  'HEATERS-UNIT-REPAIRING',
  'HEATING CONTRACTORS',
  'HEATING EQUIP & SYSTS-SUPLS/PARTS (WHOL)',
  'HEATING EQUIPMENT',
  'Heating Equipment (except Warm Air Furnaces) Manufacturing',
  'HEATING EQUIPMENT & SYSTEMS (WHOLESALE)',
  'HEATING EQUIPMENT & SYSTEMS-REPAIR & SVC',
  'HEATING EQUIPMENT-MANUFACTURERS',
  'HEATING SPECIALTIES',
  'HEATING SYSTEMS-CLEANING & REPAIRING',
  'HEAVY CONSTRUCTION',
  'HEAVY CONSTRUCTION EQUIPMENT RENTAL',
  'HEAVY CONSTRUCTION EQUIPMENT-RENTAL',
  'Heavy Duty Truck Manufacturing',
  'HEAVY EQUIPMENT (WHOLESALE)',
  'HEAVY EQUIPMENT-REPAIR',
  'HEAVY EQUIPMENT-SUPPLIES (WHOLESALE)',
  'Hedge Funds',
  'HEELS-MANUFACTURERS',
  'HELICOPTER-CHARTER & RENTAL SERVICE',
  'HELICOPTER-DEALERS',
  'HELICOPTER-DISTRIBUTORS',
  'HELICOPTER-EQUIP & PARTS & SUPLS (MFRS)',
  'HELICOPTER-MANUFACTURERS',
  'HELICOPTER-SERVICING & MAINTENANCE',
  'HELIPORTS-CONSTRUCTION',
  'HELIUM (WHOLESALE)',
  'HELP SUPPLY SERVICES',
  'HEMP PRODUCTS',
  'HERBALISTS',
  'HERBICIDE CONSULTANTS',
  'HERBICIDES (MANUFACTURERS)',
  'HERBS',
  'HERBS-WHOLESALE',
  'HERMETIC MOTOR REWINDING',
  'HIDES (WHOLESALE)',
  'Higher Education',
  'HIGHWAY AND STREET CONSTRUCTION',
  'HIGHWAY GUARD RAILS (WHOLESALE)',
  'Highway, Street, and Bridge Construction ',
  'HIKING CLUBS',
  'HINGES',
  'HINGES-MANUFACTURERS',
  'HINGES-WHOLESALE',
  'HISTORICAL ORGANIZATIONS',
  'HISTORICAL PLACES',
  'HISTORICAL PRESERVATION/RESTORATION SVC',
  'HISTORICAL RESEARCH',
  'HISTORICAL RESTORATION CONSULTANTS',
  'Historical Sites',
  'HMO Medical Centers',
  'HOBBY',
  'HOBBY & MODEL CONSTR SUPPLIES-MFRS',
  'HOBBY & MODEL CONSTR SUPPLIES-RETAIL',
  'HOBBY & MODEL CONSTR SUPPLIES-WHOLESALE',
  'Hobby, Toy, and Game Stores ',
  'Hockey',
  'HOCKEY CLUBS',
  'HOCKEY EQUIPMENT',
  'Hog and Pig Farming',
  'HOG EQUIPMENT (MANUFACTURERS)',
  'HOG FARMS',
  'HOGS',
  'HOGS',
  'HOISTING & RIGGING SERVICE',
  'HOISTS',
  'HOISTS (WHOLESALE)',
  'HOISTS-MANUFACTURERS',
  'HOISTS-REPAIRING',
  'HOLDING COMPANIES',
  'HOLDING COMPANIES (BANK)',
  'HOLDING COMPANIES (NON-BANK)',
  'HOLIDAY LETTERS & GIFTS',
  'HOLISTIC PRACTITIONERS',
  'HOLLY GROWERS & SHIPPERS',
  'HOLSTERS (MANUFACTURERS)',
  'HOME & AUTOMOTIVE SUPPLIES',
  'HOME & PERSONAL CARE PRODUCTS',
  'HOME ACCESSORIES & FURNISHINGS',
  'Home and Garden',
  'Home and Garden Equipment Repair and Maintenance',
  'HOME AUTOMATION SYSTEMS',
  'HOME BUILDERS',
  'HOME CENTERS',
  'Home Centers',
  'Home Decor',
  'HOME DEMONSTRATION-MERCHANDISE',
  'HOME DESIGN & PLANNING SERVICE',
  'HOME FOOD PRODUCTS-WHOLESALE',
  'Home Furnishing Merchant Wholesalers',
  'HOME HEALTH & HEALTH CARE EQUIPMENT',
  'Home Health Care',
  'Home Health Care Services',
  'HOME HEALTH CARE SERVICES',
  'Home Health Equipment Rental',
  'HOME HEALTH SERVICE',
  'Home Improvement',
  'HOME IMPROVEMENTS',
  'HOME MANAGING SERVICES',
  'HOME MEAL REPLACEMENTS',
  'HOME PLANNING SERVICE',
  'Home Renovation',
  'Home Services',
  'HOME SERVICES-ROUTES GROCERIES ETC',
  'HOME WARRANTY PLANS',
  'HOMEFURNISHINGS',
  'Homeland Security',
  'Homeless Shelter',
  'HOMELESS SHELTERS',
  'HOMEMAKERS SERVICE',
  'HOMEOPATHIC REMEDIES',
  'HOMEOPATHIC SUPPLIERS (WHOLESALE)',
  'HOMEOPATHS',
  'HOMES & INSTITUTIONS',
  'HOMES FOR THE HANDICAPPED',
  'HOMES-ADULT',
  'HOMES-BATTERED PERSONS',
  'HOMES-BLIND',
  'HOMES-BOYS',
  'HOMES-CHILDREN',
  'HOMES-GIRLS',
  'HOMES-INFORMATION & PLACEMENT BUREAUS',
  'HOMES-INVALID',
  'HOMES-MATERNITY',
  'HOMES-MENTAL RETARDATION & DEV DISABLED',
  'HOMES-NURSING CONSULTANTS',
  'HOMES-PERSONAL CARE FACILITY',
  'HOMES-WOMEN',
  'HOMOGENIZERS (MANUFACTURERS)',
  'HONEY (WHOLESALE)',
  'HONEY FARMS',
  'HONEYCOMB MATERIALS (MANUFACTURERS)',
  'HONING & LAPPING SERVICE',
  'HONING MACHINES & EQUIPMENT (MFRS)',
  'HOPPERS (MANUFACTURERS)',
  'HORSE BREEDERS',
  'HORSE CARE',
  'HORSE DEALERS (WHOLESALE)',
  'HORSE FARM-BUILDING & CONSTRUCTION',
  'HORSE FURNISHINGS',
  'HORSE FURNISHINGS-MANUFACTURERS',
  'HORSE FURNISHINGS-WHOLESALE',
  'HORSE ORGANIZATIONS',
  'HORSE RACING',
  'HORSE SPAS-HORSE THERAPY',
  'HORSE TRAINING',
  'HORSE TRANSPORTING',
  'HORSERADISH (WHOLESALE)',
  'HORSES & OTHER EQUINES',
  'Horses and Other Equine Production',
  'HORSES AND OTHER EQUINES',
  'HORTICULTURAL CONSULTANTS',
  'Horticulture',
  'HOSE (WHOLESALE)',
  'HOSE & TUBING-FLEXIBLE-METAL (WHOLESALE)',
  'HOSE & TUBING-RUBBER & PLASTIC (WHOL)',
  'HOSE & TUBING-RUBBER & PLASTIC-MFRS',
  'HOSE ASSEMBLIES (MANUFACTURERS)',
  'HOSE COUPLINGS & FITTINGS (WHOLESALE)',
  'HOSE COUPLINGS & FITTINGS-MANUFACTURERS',
  'HOSE RACKS & REELS (MANUFACTURERS)',
  'HOSE-INDUSTRIAL-MANUFACTURERS',
  'HOSIERY',
  'Hosiery and Sock Mills',
  'HOSIERY-FINISHING (MANUFACTURERS)',
  'HOSIERY-MANUFACTURERS',
  'HOSIERY-RETAIL',
  'HOSIERY-WHOLESALE',
  'HOSPICES',
  'Hospital',
  'Hospital & Health Care',
  'HOSPITAL & MEDICAL SERVICE PLANS',
  'HOSPITAL AND MEDICAL SERVICE PLANS',
  'HOSPITAL BEDS-RENTING',
  'HOSPITAL CONSULTANTS',
  'HOSPITAL EQUIP-REPAIRING & REFINISHING',
  'HOSPITAL EQUIPMENT & SUPPLIES (WHOL)',
  'HOSPITAL EQUIPMENT & SUPPLIES-MFRS',
  'HOSPITAL EQUIPMENT & SUPPLIES-RENTING',
  'HOSPITAL SERVICE ORGANIZATIONS',
  'HOSPITAL TRANSPORTATION SERVICE',
  'HOSPITAL/HLTH FACILITIES PLANNING AGENCY',
  'Hospitality',
  'HOSPITALIZATION PLANS-MEDICAL & SURGICAL',
  'HOSPITALS',
  'HOSTELS',
  'HOT DOG STANDS',
  'HOT LINES',
  'HOT SHOT SERVICE',
  'HOT SPRINGS',
  'HOT STAMPING EQUIPMENT & SUPLS (MFRS)',
  'HOT STAMPING-FOIL GOLD & ETC',
  'HOT TUBS & SPAS',
  'HOT TUBS & SPAS-MANUFACTURERS',
  'HOT TUBS & SPAS-RENTAL',
  'HOT TUBS & SPAS-SERVICE & REPAIR',
  'HOT TUBS & SPAS-SUPPLIES & PARTS',
  'HOT TUBS & SPAS-WHOLESALE',
  'Hotel',
  'HOTEL & MOTEL DEVELOPERS',
  'HOTEL & MOTEL FURNISHINGS (WHOLESALE)',
  'HOTEL & MOTEL MANAGEMENT',
  'Hotels (except Casino Hotels) and Motels',
  'HOTELS & MOTELS',
  'HOTELS AND MOTELS',
  'HOTELS-APARTMENT',
  'HOUSE & BUILDING MOVERS',
  'HOUSE BUYERS',
  'HOUSE CLEANING',
  'HOUSE CLEANING EQUIPMENT & SUPPLIES',
  'HOUSE FURNISHINGS-MANUFACTURERS',
  'HOUSE FURNISHINGS-RETAIL',
  'HOUSE FURNISHINGS-WHOLESALE',
  'HOUSE LEVELING',
  'HOUSE NUMBERS (MANUFACTURERS)',
  'HOUSE RAISING',
  'HOUSE SITTER SERVICE',
  'HOUSE SLIPPERS',
  'HOUSE WASHING-EXTERIOR',
  'HOUSEBOATS (MANUFACTURERS)',
  'HOUSEHOLD APPLIANCE STORES',
  'Household Appliance Stores',
  'HOUSEHOLD APPLIANCES',
  'HOUSEHOLD APPLIANCES NEC (MANUFACTURERS)',
  'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers ',
  'HOUSEHOLD AUDIO AND VIDEO EQUIPMENT',
  'HOUSEHOLD AUDIO/VIDEO EQUIPMENT (MFRS)',
  'HOUSEHOLD COOKING EQUIPMENT',
  'HOUSEHOLD COOKING EQUIPMENT (MFRS)',
  'HOUSEHOLD FURNISHINGS',
  'HOUSEHOLD FURNITURE',
  'Household Furniture (except Wood and Metal) Manufacturing',
  'HOUSEHOLD FURNITURE NEC (MFRS)',
  'HOUSEHOLD HOME & FARM REFRIG (MFRS)',
  'HOUSEHOLD LAUNDRY EQUIPMENT',
  'HOUSEHOLD PRODUCTS',
  'HOUSEHOLD REFRIGERATORS AND FREEZERS',
  'HOUSEHOLD VACUUM CLEANERS',
  'HOUSEHOLD VACUUM CLEANERS (MFRS)',
  'Housekeeping Service',
  'HOUSEWARES-MANUFACTURERS',
  'HOUSEWARES-RETAIL',
  'HOUSEWARES-WHOLESALE',
  'HOUSING ASSISTANCE',
  'HOUSING AUTHORITIES',
  'HOUSING CONSULTANTS',
  'HOUSING PROGRAMS',
  'HOUSING-INDUSTRIAL',
  'HOUSING-RENTAL',
  'HOUSING-SURVEY CONSULTANTS',
  'HOVERCRAFT (MANUFACTURERS)',
  'HULA INSTRUCTIONS',
  'HULA SUPPLIES-RETAIL',
  'Human Computer Interaction',
  'HUMAN FACTORS-RESEARCH & DEVELOPMENT',
  'HUMAN RELATIONS COUNSELORS',
  'HUMAN RESOURCE CONSULTANTS',
  'Human Resources',
  'Human Resources Consulting Services',
  'Human Rights Organizations',
  'HUMAN SERVICES ORGANIZATIONS',
  'HUMANE SOCIETIES',
  'Humanitarian',
  'HUMIDIFYING APPARATUS',
  'HUMIDIFYING APPARATUS-MANUFACTURERS',
  'HUMIDIFYING APPARATUS-WHOLESALE',
  'HUNTING',
  'Hunting',
  'HUNTING & FISHING CLUBS',
  'HUNTING & FISHING PRESERVES',
  'Hunting and Trapping',
  'HUNTING SUPPLIES',
  'HUNTING TRIPS',
  'HURRICANE PREPAREDNESS',
  'HYDRATOR-LIME (MANUFACTURERS)',
  'HYDRAULIC EQUIPMENT & SUPPLIES (WHOL)',
  'HYDRAULIC EQUIPMENT-MANUFACTURERS',
  'HYDRAULIC EQUIPMENT-REPAIRING',
  'Hydroelectric Power Generation',
  'HYDROFORMING (MANUFACTURERS)',
  'HYDROGEN (MANUFACTURERS)',
  'HYDROGEOLOGISTS',
  'HYDROLOGISTS',
  'HYDROMETERS (MANUFACTURERS)',
  'HYDROMULCHING',
  'Hydroponics',
  'HYDROPONICS EQUIPMENT & SUPPLIES',
  'HYDROPONICS-VEGETABLE GROWERS',
  'HYDROSEEDING',
  'HYDROSTATIC TESTING',
  'HYGROMETERS-MANUFACTURERS',
  'HYPERBARIC SERVICES',
  'HYPERTRICHOLOGISTS',
  'HYPNOTHERAPY',
  'HYPNOTISTS',
  "HYPNOTISTS' REFERRAL SERVICE",
  'IaaS',
  'ICE',
  'ICE BOXES (MANUFACTURERS)',
  'ICE CARVERS',
  'ICE CHESTS (MANUFACTURERS)',
  'ICE CREAM & FROZEN DESSERTS (MFRS)',
  'ICE CREAM & FROZEN DESSERTS-DISTRIBUTORS',
  'ICE CREAM AND FROZEN DESERTS',
  'Ice Cream and Frozen Dessert Manufacturing',
  'ICE CREAM CABINETS (WHOLESALE)',
  'ICE CREAM CONE-DISTRIBUTORS',
  'ICE CREAM CONE-MANUFACTURERS',
  "ICE CREAM CONE-MFRS' EQUIPMENT (WHOL)",
  'ICE CREAM FREEZERS (WHOLESALE)',
  'ICE CREAM FREEZERS-MANUFACTURERS',
  'ICE CREAM MIXES (WHOLESALE)',
  'ICE CREAM MIXES-MANUFACTURERS',
  'ICE CREAM PARLORS',
  'ICE CREAM-MANUFACTURERS EQUIPMENT (WHOL)',
  'ICE CREAM-MANUFACTURERS SUPPLIES (MFRS)',
  'ICE CRUSHING MACHINERY (MANUFACTURERS)',
  'ICE HANDLING EQUIPMENT (WHOLESALE)',
  'ICE MAKERS EQUIP-HOME & OFFICE (WHOL)',
  'ICE MAKING EQUIPMENT & MACHINES (WHOL)',
  'ICE MAKING EQUIPMENT & MACHINES-MFRS',
  'ICE MAKING EQUIPMENT & MACHINES-RENTING',
  'ICE MAKING EQUIPMENT & MACHS-RPR & CLEAN',
  'Ice Manufacturing',
  'ICE MELTING EQUIPMENT & SUPPLIES (WHOL)',
  'ICE SUBSTITUTES (MANUFACTURERS)',
  'ICE-MANUFACTURERS',
  'ICE-WHOLESALE',
  'IDEAS-DEVELOPED MARKETED & ETC',
  'IDENTIFICATION BUREAUS',
  'IDENTIFICATION CARD SERVICE',
  'IDENTIFICATION CARDS (WHOLESALE)',
  'IDENTIFICATION EQUIP & SUPLS-MFRS',
  'IDENTIFICATION EQUIPMENT & SUPLS (WHOL)',
  'Identity Management',
  'IGNITION INTERLOCK DEVICES (MFRS)',
  'ILLUSTRATORS',
  'IMAGE CONSULTANTS-PERSONAL',
  'Image Recognition',
  'IMMIGRATION & NATURALIZATION SERVICES',
  'Impact Investing',
  'Import and Export',
  'IMPORTED AUTOMOBILE SERVICES',
  'IMPORTED FOODS',
  'IMPORTERS',
  'IMPORTERS-AUTOMOTIVE-PARTS & SUPPLIES',
  'IMPORTERS-CONTRACTORS EQUIP/SUPLS (WHOL)',
  'IMPORTERS-RETAIL',
  'IMPREGNATING',
  'IMPREGNATING APPARATUS (MANUFACTURERS)',
  'IMPREGNATING CASTINGS (MANUFACTURERS)',
  'IMPRINTING',
  'In-Flight Entertainment',
  'In-Vitro Diagnostic Substance Manufacturing',
  'IN-VITRO/IN-VIVO DIAGNOSTIC SBSTNC (MFR)',
  'INCENSE',
  'INCENSE-MANUFACTURERS',
  'INCENSE-WHOLESALE',
  'INCENTIVE PROGRAMS',
  'INCINERATORS (WHOLESALE)',
  'INCINERATORS-MANUFACTURERS',
  'INCINERATORS-SUPPLIES & PARTS (WHOL)',
  'INCORPORATING COMPANIES',
  'Incubators',
  'Independent Artists, Writers, and Performers ',
  'INDEPENDENT LIVING SERVICES FOR DISABLED',
  'Independent Music',
  'INDEXING SVC-BOOK/PERIODICAL/ETC (MFRS)',
  'INDIAN GOODS',
  'INDIAN GOODS-MANUFACTURERS',
  'INDIAN GOODS-WHOLESALE',
  'INDIAN JEWELRY',
  'INDIAN RESERVATIONS & TRIBES',
  'INDICATORS-DIAL (MANUFACTURERS)',
  'INDICATORS-FLOW',
  'Individual & Family Services',
  'INDIVIDUAL AND FAMILY SERVICES',
  'INDIVIDUAL RETIREMENT ACCOUNTS',
  'INDOOR AIR QUALITY TESTING',
  'Indoor Positioning',
  'INDSTRL/COML MACHINERY/EQUIP NEC (MFRS)',
  'INDUCTION HEATING EQUIPMENT (WHOLESALE)',
  'Industrial',
  'INDUSTRIAL & COML FANS & BLOWERS (MFRS)',
  'INDUSTRIAL & PERSONAL SVC-PAPER (WHOL)',
  'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing',
  'INDUSTRIAL AND PERSONAL SERVICE PAPER',
  'Industrial and Personal Service Paper Merchant Wholesalers',
  'Industrial Automation',
  'Industrial Building Construction',
  'INDUSTRIAL BUILDINGS AND WAREHOUSES',
  'INDUSTRIAL CONSULTANTS',
  'INDUSTRIAL CONTAINERS-MANUFACTURING',
  'Industrial Design',
  'Industrial Design Services',
  'INDUSTRIAL DEVELOPMENTS',
  'Industrial Engineering',
  'INDUSTRIAL EQUIPMENT & SUPLS-REPAIRING',
  'INDUSTRIAL EQUIPMENT & SUPPLIES (WHOL)',
  'INDUSTRIAL EQUIPMENT & SUPPLIES-MFRS',
  'INDUSTRIAL FURNACES AND OVENS',
  'Industrial Gas Manufacturing',
  'INDUSTRIAL GASES',
  'INDUSTRIAL HYGIENE CONSULTANTS',
  'INDUSTRIAL INORGANIC CHEMICALS',
  'INDUSTRIAL INORGANIC CHMCLS NEC (MFRS)',
  'INDUSTRIAL INSTRUMENTATION (MFRS)',
  'INDUSTRIAL LAUNDERERS',
  'Industrial Launderers',
  'INDUSTRIAL MACHINERY',
  'INDUSTRIAL MACHINERY AND EQUIPMENT',
  'Industrial Machinery and Equipment Merchant Wholesalers',
  'Industrial Manufacturing',
  'INDUSTRIAL MEASURING/CNTRL INSTR (MFRS)',
  'INDUSTRIAL MEDICINE',
  'Industrial Mold Manufacturing',
  'INDUSTRIAL ORGANIC CHEMICALS',
  'INDUSTRIAL ORGANIC CHEMICALS NEC (MFRS)',
  'INDUSTRIAL PARKS',
  'INDUSTRIAL PATTERNS',
  'INDUSTRIAL PATTERNS (MANUFACTURERS)',
  'Industrial Process Furnace and Oven Manufacturing',
  'INDUSTRIAL PROCESS-FURNACES/OVENS (MFRS)',
  'INDUSTRIAL RELATIONS CONSULTANTS',
  'INDUSTRIAL RESEARCH',
  'INDUSTRIAL SAND',
  'INDUSTRIAL SAND',
  'Industrial Sand Mining',
  'INDUSTRIAL SUPPLIES',
  'Industrial Supplies Merchant Wholesalers',
  'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing ',
  'INDUSTRIAL TRUCKS AND TRACTORS',
  'INDUSTRIAL TRUCKS TRACTORS/TRLRS (MFRS)',
  'Industrial Valve Manufacturing',
  'INDUSTRIAL VALVES',
  'INDUSTRIAL VALVES (MANUFACTURERS)',
  'INDUSTRY LEADERS-ADVERTISING',
  'INDUSTRY LEADERS-DIRECT MAIL',
  'INFANTS EQUIPMENT & SUPPLIES-MFRS',
  'INFANTS EQUIPMENT & SUPPLIES-WHOLESALE',
  'INFANTS GARMENTS-WHOLESALE & MFRS',
  'INFANTS WEAR',
  'INFERTILITY COUNSELING',
  'INFORMATION & REFERRAL SVCS',
  'INFORMATION & REFERRAL SVCS-HLTH PRGRMS',
  'Information and Communications Technology (ICT)',
  'INFORMATION BROKERS-DOCUMENT DLVRY/RSRCH',
  'INFORMATION BUREAUS',
  'INFORMATION EQUIP & SYSTEMS',
  'INFORMATION MANAGEMENT',
  'INFORMATION PROVIDER SERVICES-DIAL IT',
  'INFORMATION RETRIEVAL SERVICES',
  'INFORMATION RETRIEVAL SERVICES',
  'INFORMATION RETRIEVAL SYSTS/EQUIP (WHOL)',
  'INFORMATION SEARCH & RETRIEVAL SERVICE',
  'Information Services',
  'INFORMATION SYSTEM CONSULTANTS',
  'Information Technology',
  'Information Technology and Services',
  'INFORMATION TECHNOLOGY SERVICES',
  'INFRARED EQUIPMENT (MANUFACTURERS)',
  'INFRARED INSPECTION SERVICE',
  'Infrastructure',
  'INK ERADICATORS (MANUFACTURERS)',
  'INKS',
  'INKS-MANUFACTURERS',
  'INKS-MANUFACTURERS MATERIALS (WHOLESALE)',
  'INKS-PRINTING & LITHOGRAPHING (MFRS)',
  'INKS-WRITING & MARKING (WHOLESALE)',
  'Inland Water Freight Transportation',
  'Inland Water Passenger Transportation',
  'Innovation Management',
  'INNS',
  'INORGANIC PIGMENTS',
  'INORGANIC PIGMENTS (MANUFACTURERS)',
  'INSECT CONTROL DEVICES',
  'INSECT CONTROL DEVICES-MANUFACTURERS',
  'INSECTICIDE BASES (WHOLESALE)',
  'INSECTICIDES',
  'INSECTICIDES-MANUFACTURERS',
  'INSECTICIDES-WHOLESALE',
  'INSEMINATION SERVICE-ARTIFICIAL',
  'INSERTS-SCREW THREAD',
  'INSPECTION AND FIXED FACILITIES',
  'INSPECTION DEVICES-INDUSTRIAL (WHOL)',
  'INSPECTION SERVICE',
  'INSTALLATION OR ERECTION-BLDG EQPT NEC',
  'INSTALLATION SERVICE',
  'INSTALLING BUILDING EQUIPMENT',
  'INSTITUTION SUPPLIES (WHOLESALE)',
  'Institutional Furniture Manufacturing',
  'INSTRS-MEASURING/TESTING ELEC (MFRS)',
  'INSTRUCTION MANUALS',
  'INSTRUMENT CASES (WHOLESALE)',
  'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals',
  'INSTRUMENTATION INDICATING CNTRL CNSLTNT',
  'INSTRUMENTS (WHOLESALE)',
  'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables ',
  'INSTRUMENTS TO MEASURE ELECTRICITY',
  'INSTRUMENTS-INDUSTRIAL-REPAIRING',
  'INSTRUMENTS-MEASURING (MANUFACTURERS)',
  'INSULATED CLOTHING',
  'INSULATED CONTAINERS & CARRIERS (MFRS)',
  'INSULATION APPLYING MACHINERY (MFRS)',
  'INSULATION CONTRACTORS-COLD & HEAT',
  'INSULATION CONTRS-EQUIP/SUPLS (WHOL)',
  'INSULATION MATERIALS-COLD & HEAT',
  'INSULATION MATERIALS-COLD & HEAT-MFRS',
  'INSULATION MATERIALS-COLD & HEAT-WHOL',
  'INSULATION MATERIALS-ELECTRIC (WHOL)',
  'INSULATION-MANUFACTURERS',
  'INSURANCE',
  'Insurance',
  'INSURANCE ADJUSTERS',
  'Insurance Agencies and Brokerages',
  'INSURANCE AGENTS',
  'INSURANCE AGENTS BROKERS & SERVICE',
  'INSURANCE ANNUITIES',
  'INSURANCE CARRIERS',
  'INSURANCE CARRIERS NEC',
  'INSURANCE CONSULTANTS & ADVISORS',
  'INSURANCE MANAGERS',
  'INSURANCE MORTGAGE',
  'INSURANCE REFERRAL SVC',
  'INSURANCE TRAVEL',
  'INSURANCE-AUTOMOBILE',
  'INSURANCE-BOAT',
  'INSURANCE-BONDS',
  'INSURANCE-BURIAL',
  'INSURANCE-CANCER',
  'INSURANCE-CHARTERED-LIFE-UNDERWRITERS',
  'INSURANCE-CLAIM PROCESSING SERVICES',
  'INSURANCE-ESTATE PLANNING',
  'INSURANCE-EXCESS & SURPLUS',
  'INSURANCE-FIRE',
  'INSURANCE-GROUP',
  'INSURANCE-HEALTH & ACCIDENT',
  'INSURANCE-HOLDING COMPANIES',
  'INSURANCE-HOMEOWNERS',
  'INSURANCE-INSPECTION & AUDITS',
  'INSURANCE-INVESTIGATORS',
  'INSURANCE-LIABILITY',
  'INSURANCE-LIFE (AGENTS)',
  'INSURANCE-MEDICAL EXAMINATIONS',
  'INSURANCE-MOBILE HOME',
  'INSURANCE-PET',
  'INSURANCE-PLAN ADMINISTRATORS',
  'INSURANCE-PROPERTY & CASUALTY',
  'INSURANCE-RATING BUREAUS',
  'INSURANCE-REPORTS',
  'INSURANCE-SAVINGS-BANK-LIFE',
  'INSURANCE-SCHOOLS',
  'INSURANCE-TITLE',
  'INSURANCE-VIDEO & PHOTO PRODUCTION SVC',
  'INSURED PROPERTY REPLACEMENT SERVICE',
  'InsurTech',
  'Intellectual Property',
  'Intelligent Systems',
  'INTERACTIVE MEDIA',
  'INTERCITY AND RURAL BUS TRANSPORTATION',
  'INTERCOMMUNICATION EQPT SYSTS/SVC (WHOL)',
  'INTERIOR DECORATING & DESIGNING SCHOOLS',
  'INTERIOR DECORATORS & DESIGNERS WORKROOM',
  'INTERIOR DECORATORS DESIGN & CONSULTANTS',
  'INTERIOR DECORATORS/DESIGNERS SUPLS-MFRS',
  'INTERIOR DECORATORS/DESIGNERS SUPLS-RTL',
  'INTERIOR DECORATORS/DSGNRS SUPLS (WHOL)',
  'Interior Design',
  'Interior Design Services',
  'INTERIOR LANDSCAPING',
  'INTERMEDIATE CARE FACILITIES',
  'INTERMEDIATE CARE FACILITIES',
  'INTERNAL COMBUSTION ENGINES',
  'INTERNAL COMBUSTION ENGINES NEC (MFRS)',
  'INTERNATIONAL AFFAIRS',
  'International Affairs',
  'International Affairs',
  'INTERNATIONAL BUSINESS SERVICES',
  'INTERNATIONAL CONSULTANTS',
  'INTERNATIONAL MARKETING',
  'International Trade and Development',
  'International Trade Financing',
  'INTERNATIONAL TRADE SERVICES',
  'Internet',
  'INTERNET HARDWARE & SOFTWARE PROVIDERS',
  'INTERNET HOME PAGE DEV CONSULTING',
  'INTERNET LIBRARIES',
  'Internet of Things',
  'Internet Publishing and Broadcasting and Web Search Portals',
  'Internet Radio',
  'INTERNET SERVICE',
  'Internet Services',
  'INTERNET SERVICES E-MAIL SERVICES',
  'INTERNET SERVICES-INTERNET TRAINING',
  'INTERNET SVCS-NETWORK DESIGNERS/CONSLNT',
  'INTERSTATE COMMERCE COMMISSION PRCTNRS',
  'Interurban and Rural Bus Transportation',
  'Intrusion Detection',
  'INVENTION CONSULTANTS & BROKERS',
  'INVENTORS',
  'INVENTORY SERVICE',
  'INVERTERS (MANUFACTURERS)',
  'Investigation Services',
  'INVESTIGATORS',
  'INVESTMENT ADVICE',
  'Investment Advice',
  'INVESTMENT ADVISORY REFERRAL SERVICE',
  'INVESTMENT BANKERS',
  'Investment Banking',
  'Investment Banking and Securities Dealing',
  'INVESTMENT CASTINGS (MANUFACTURERS)',
  'INVESTMENT MANAGEMENT',
  'Investment Management',
  'INVESTMENT OFFICES',
  'INVESTMENT SECURITIES',
  'INVESTMENT TRUSTS',
  'INVESTMENTS',
  'INVESTORS',
  'INVESTORS NEC',
  'INVITATIONS & ANNOUNCEMENTS-MFRS',
  'INVITATIONS & ANNOUNCEMENTS-RETAIL',
  'INVITATIONS & ANNOUNCEMENTS-WHOLESALE',
  'IODINE (MANUFACTURERS)',
  'iOS',
  'IRISH GOODS-RETAIL',
  'IRISH POTATOES',
  'IRON (WHOLESALE)',
  'Iron and Steel Forging',
  'IRON AND STEEL FORGINGS',
  'Iron and Steel Mills and Ferroalloy Manufacturing',
  'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel',
  'Iron Foundries',
  'Iron Ore Mining',
  'IRON ORES',
  'IRON-ORE',
  'IRON-ORE (MANUFACTURERS)',
  'IRON-OXIDE (MANUFACTURERS)',
  'IRON-PIG (WHOLESALE)',
  'IRONING BOARD COVERS & PADS (MFRS)',
  'IRONING BOARDS (MANUFACTURERS)',
  'IRONING SERVICE',
  'IRONWORK',
  'IRONWORK-MANUFACTURERS',
  'IRONWORK-WHOLESALE',
  'IRONWORKING MACHINERY (MANUFACTURERS)',
  'Irradiation Apparatus Manufacturing',
  'IRRIGATION COMPANIES',
  'IRRIGATION CONSULTANTS',
  'IRRIGATION SYSTEMS',
  'IRRIGATION SYSTEMS & EQUIPMENT (WHOL)',
  'IRRIGATION SYSTEMS & EQUIPMENT-MFRS',
  'IRRIGATION SYSTEMS & EQUIPMENT-RETAIL',
  'ISO CERTIFICATION',
  'ISP',
  'IT Infrastructure',
  'IT Management',
  'ITALIAN FOOD PRODUCTS',
  'ITALIAN FOODS-WHOLESALE',
  'IVORY GOODS',
  'JACKETS-PROMOTIONAL (MANUFACTURERS)',
  'JACKETS-RETAIL',
  'JACKETS-WHOLESALE',
  'JACKS (WHOLESALE)',
  'JACKS-REPAIRING',
  'JADE & JADE GOODS',
  'JAI-ALAI FRONTONS',
  'JAIL EQUIPMENT (WHOLESALE)',
  'JAILS-BUILDERS',
  'JALOUSIES',
  'JALOUSIES-MANUFACTURERS',
  'JALOUSIES-WHOLESALE',
  'JANITOR SERVICE',
  'Janitorial Service',
  'Janitorial Services',
  'JANITORS EQUIPMENT & SUPPLIES-MFRS',
  'JANITORS EQUIPMENT/SUPPLIES (WHOLESALE)',
  'JAPANESE FOOD PRODUCTS',
  'JARS-GLASS & PLASTIC (WHOLESALE)',
  'JEANS',
  'JEANS-MANUFACTURERS',
  'JEANS-WHOLESALE',
  'JET DRIVES-MARINE (MANUFACTURERS)',
  'JET PROPULSION EQUIPMENT (MANUFACTURERS)',
  'JET SKI-DEALERS',
  'JEWELERS EQUIPMENT & MACHINERY (MFRS)',
  'JEWELERS FINDINGS/MTRLS/LAPIDARY (MFRS)',
  'JEWELERS SUPPLIES (WHOLESALE)',
  'JEWELERS SUPPLIES-MANUFACTURERS',
  'JEWELERS-RETAIL',
  'JEWELERS-WHOLESALE',
  "JEWELERS' MATERIALS AND LAPIDARY WORK",
  'JEWELRY',
  'Jewelry',
  'JEWELRY AND PRECIOUS STONES',
  'Jewelry and Silverware Manufacturing',
  'JEWELRY APPRAISAL',
  'JEWELRY BROKERS',
  'JEWELRY BUYERS',
  'JEWELRY CASES (MANUFACTURERS)',
  'JEWELRY CASTINGS (WHOLESALE)',
  'JEWELRY CASTINGS-EQUIP (WHOLESALE)',
  'JEWELRY CASTINGS-MANUFACTURERS',
  'JEWELRY CONTRACTORS (WHOLESALE)',
  'JEWELRY CRAFT SUPPLIES (MANUFACTURERS)',
  'JEWELRY CRAFTSMEN',
  'JEWELRY DESIGNERS',
  'JEWELRY DISPLAY UNITS',
  'JEWELRY ENAMELERS (MANUFACTURERS)',
  'JEWELRY ENGRAVERS',
  'JEWELRY FINDINGS (WHOLESALE)',
  'JEWELRY MOUNTINGS (MANUFACTURERS)',
  'JEWELRY PLATING (MANUFACTURERS)',
  'JEWELRY POLISHING',
  'JEWELRY SOLDERING',
  'JEWELRY STAMPING (MANUFACTURERS)',
  'JEWELRY STORES',
  'Jewelry Stores',
  'JEWELRY-BOUGHT',
  'JEWELRY-HAND WROUGHT',
  'JEWELRY-MANUFACTURERS',
  'JEWELRY-MODEL MAKERS (MANUFACTURERS)',
  'JEWELRY-RENTING & LEASING',
  'JEWELRY-REPAIRING',
  'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers ',
  'JEWELS-IMITATION STONES (WHOLESALE)',
  'JEWISH FOODS',
  'JIG SAWING',
  'JIGS & FIXTURES (MANUFACTURERS)',
  'JIGS & FIXTURES-COMPONENTS (WHOLESALE)',
  'JOB LISTING SERVICE',
  'JOB LOT MERCHANDISE (WHOLESALE)',
  'JOB TRAINING AND RELATED SERVICES',
  'JOINTS-BALL (MANUFACTURERS)',
  'Journalism',
  'Judiciary',
  'JUGGLERS',
  'JUGGLING EQUIPMENT',
  'JUGGLING INSTRUCTION',
  'JUICE BARS',
  'JUICE EXTRACTORS (WHOLESALE)',
  'JUICES-MANUFACTURERS',
  'JUICES-RETAIL',
  'JUICING EQUIPMENT & SUPPLIES',
  'JUNIOR COLLEGES',
  'Junior Colleges',
  'JUNIOR COLLEGES & TECHNICAL INSTITUTES',
  'JUNK-DEALERS',
  'JUSTICE OF THE PEACE',
  'JUVENILE DETENTION CENTERS',
  'KALEIDOSCOPES',
  'KAOLIN & BALL CLAY',
  'KAOLIN AND BALL CLAY',
  'Kaolin and Ball Clay Mining',
  'KARAOKE',
  'KARAOKE MACHINES & TAPES',
  'KARATE JUDO JIU-JITSU & KUNG FU INSTR',
  'KARATE JUDO JIU-JITSU & KUNG FU SUPLS',
  'KARTS-MOTORIZED-DISTRIBUTORS',
  'KARTS-MOTORIZED-MANUFACTURERS',
  'KAYAKS',
  'KENNELS EQUIPMENT & SUPPLIES (WHOLESALE)',
  'KEROSENE',
  'KEROSENE HEATERS-SALES & SERVICE (WHOL)',
  'KEROSENE-WHOLESALE',
  'KETTLES (MANUFACTURERS)',
  'KEY CONTROL SYSTEMS',
  'KEY HOLDERS (MANUFACTURERS)',
  'KEY MAKERS',
  'KEYS',
  'KEYS-MANUFACTURERS',
  'KEYS-WHOLESALE',
  'KIDNEY DIALYSIS CENTERS',
  'KIDNEY DIALYSIS CENTERS',
  'Kidney Dialysis Centers',
  'KIDS ENTERTAINMENT',
  'KILNS (MANUFACTURERS)',
  'KILNS-WHOLESALE',
  'KINDERGARTENS',
  'KINESIOTHERAPY',
  'KITCHEN ACCESSORIES',
  'KITCHEN ACCESSORIES-WHOL & MFRS',
  'KITCHEN CABINETS & EQUIPMENT-HOUSEHOLD',
  'KITCHEN CABINETS-REFINISHING',
  'KITCHEN CABINETS-WHOLESALE',
  'KITCHEN EXHAUST SYSTEMS-CLEANED',
  'KITCHEN FIXTURES-WHOLESALE',
  'KITCHEN REMODELING & PLANNING SVC',
  'KITCHEN RENTALS-COMMERCIAL',
  'KITCHEN TOOLS-MANUFACTURERS',
  'KITCHEN TOOLS-RETAIL',
  'KITES-MANUFACTURERS',
  'KITES-RETAIL',
  'KITES-WHOLESALE',
  'Knit Fabric Mills',
  'KNIT GOODS-BLOCKING SERVICE',
  'KNIT GOODS-CONTRACTORS (MANUFACTURERS)',
  'KNIT GOODS-MANUFACTURERS',
  'KNIT GOODS-PROCESSING & DYEING',
  'KNIT GOODS-REPAIRING',
  'KNIT GOODS-RETAIL',
  'KNIT GOODS-TRIMMINGS (MANUFACTURERS)',
  'KNIT GOODS-WHOLESALE',
  'KNIT OUTERWEAR MILLS',
  'KNIT OUTERWEAR MILLS',
  'KNIT UNDERWEAR & NIGHTWEAR MILLS',
  'KNIT UNDERWEAR MILLS',
  'KNITTED FABRICS (MANUFACTURERS)',
  'KNITTING INSTRUCTION',
  'KNITTING MACHINES (WHOLESALE)',
  'KNITTING MILLS',
  'KNITTING MILLS NEC',
  'KNITTING SUPPLIES',
  'KNIVES-MACHINE (MANUFACTURERS)',
  'KNOBS',
  'Knowledge Management',
  'KOREAN FOODS',
  'KOSHER FOOD PRODUCTS-WHOLESALE',
  'KOSHER FOODS',
  'LABEL FINISHING (MANUFACTURERS)',
  'LABEL STOCK-ADHESIVE (MANUFACTURERS)',
  'LABELING',
  'LABELING EQUIPMENT (WHOLESALE)',
  'LABELING EQUIPMENT-MANUFACTURERS',
  'LABELS (MANUFACTURERS)',
  'LABELS-FABRIC (WHOLESALE)',
  'LABELS-PAPER (MANUFACTURERS)',
  'LABELS-PAPER-WHOLESALE',
  'LABELS-PLASTIC METAL FOIL & ETC (MFRS)',
  'LABELS-PLASTIC METAL FOIL & ETC-WHOL',
  'LABELS-WOVEN (MANUFACTURERS)',
  'LABOR CONTRACTORS',
  'LABOR ORGANIZATIONS',
  'LABOR ORGANIZATIONS',
  'LABOR RELATION CONSULTANTS',
  'Labor Unions and Similar Labor Organizations',
  'LABORATORIES',
  'LABORATORIES-AGRICULTURAL',
  'LABORATORIES-ANALYTICAL',
  'LABORATORIES-BIOCHEMICAL',
  'LABORATORIES-BIOLOGICAL',
  'LABORATORIES-CLINICAL',
  'LABORATORIES-DAIRY',
  'LABORATORIES-DENTAL',
  'LABORATORIES-GRAIN TESTING',
  'LABORATORIES-MARINE BIOLOGICAL',
  'LABORATORIES-MEDICAL',
  'LABORATORIES-METALLURGICAL',
  'LABORATORIES-PATHOLOGICAL',
  'LABORATORIES-PETROLEUM',
  'LABORATORIES-PHARMACEUTICAL (MFRS)',
  'LABORATORIES-PSYCHOLOGICAL',
  'LABORATORIES-RESEARCH & DEVELOPMENT',
  'LABORATORIES-TESTING',
  'LABORATORIES-VETERINARY',
  'LABORATORY ANALYTICAL INSTRUMENTS (MFRS)',
  'LABORATORY APPARATUS AND FURNITURE',
  'LABORATORY EQUIPMENT & SUPPLIES (WHOL)',
  'LABORATORY EQUIPMENT & SUPPLIES-MFRS',
  'LABORATORY FURNITURE (MANUFACTURERS)',
  'LABORATORY SET-UP CONSULTANTS',
  'LACE & WARP KNIT FABRIC MILLS',
  'LACE AND WARP KNIT FABRIC MILLS',
  'LACE CUTTING',
  'LACES',
  'LACES-MANUFACTURERS',
  'LACES-WHOLESALE',
  'LACQUER THINNERS (MANUFACTURERS)',
  'LACQUERS',
  'LACQUERS-MANUFACTURERS',
  'LACTATION COUNSELING',
  'LADDERS (WHOLESALE)',
  'LADDERS-MANUFACTURERS',
  'LADDERS-RENTING',
  'LADIES READY TO WEAR-MANUFACTURERS',
  'LADLES',
  'LAKE & BEACH CLEANING & IMPROVEMENT',
  'LAKE & POND CONSTRUCTION & MAINTENANCE',
  'LAKE MANAGEMENT SERVICES',
  'LAMINATED PLASTICS PLATE AND SHEET',
  'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing',
  'LAMINATED PLSTCS-PLATE SHT/PROFILE (MFR)',
  'LAMINATED STRUCTURAL PRODUCTS (WHOL)',
  'LAMINATING EQUIPMENT & SUPPLIES (WHOL)',
  'LAMINATIONS-MOTOR & TRANSFORMER (MFRS)',
  'LAMINATIONS-PLASTIC PAPER ETC',
  'LAMINATIONS-PLASTIC PAPER ETC-MFRS',
  'LAMINATIONS-PLASTIC PAPER ETC-WHOLESALE',
  'LAMP BULB HOLDERS (MANUFACTURERS)',
  'LAMP BULBS (WHOLESALE)',
  'LAMP BULBS-MANUFACTURERS',
  'LAMP SHADE COVERS',
  'LAMP SHADE FRAMES & SUPPLIES',
  'LAMP SHADES-CLEANING & REPAIRING',
  'LAMP SHADES-MANUFACTURERS',
  'LAMP SHADES-WHOLESALE',
  'LAMP-BLACK (MANUFACTURERS)',
  'LAMPS & LAMP SHADES-RETAIL',
  'LAMPS-HEALTH',
  'LAMPS-INCANDESCENT',
  'LAMPS-MANUFACTURERS',
  'LAMPS-MOUNTING & REPAIRING',
  'LAMPS-SUPPLIES & PARTS (MANUFACTURERS)',
  'LAMPS-SUPPLIES & PARTS-WHOLESALE',
  'LAMPS-WHOLESALE',
  'LAND',
  'LAND CLEARING & LEVELING',
  'LAND COMPANIES',
  'LAND CONTRACTS',
  'LAND PLANNING SERVICES',
  'Land Subdivision',
  'LANDFILL DEMOLITION',
  'LANDFILLS-SANITARY',
  'LANDFILLS-SOLID WASTE',
  'LANDLORD & TENANT OFFICERS',
  'LANDLORDS SERVICE BUREAUS',
  'LANDSAILERS & ACCESSORIES',
  'Landscape Architectural Services',
  'LANDSCAPE CONTRACTORS',
  'LANDSCAPE COUNSELING AND PLANNING',
  'LANDSCAPE DESIGNERS',
  'LANDSCAPE FORESTERS',
  'LANDSCAPE LIGHTING',
  'LANDSCAPE NURSERYMEN',
  'Landscaping',
  'LANDSCAPING EQUIPMENT & SUPPLIES',
  'LANDSCAPING EQUIPMENT & SUPPLIES-MFRS',
  'LANDSCAPING EQUIPMENT & SUPPLIES-WHOL',
  'Landscaping Services',
  'LANDSCAPING-STONE',
  'Language Learning',
  'LANGUAGE SCHOOLS',
  'Language Schools',
  'LANGUAGE TRAINING AIDS',
  'LANOLIN (MANUFACTURERS)',
  'LANTERNS',
  'LAPIDARIES',
  'LAPIDARY EQUIPMENT & SUPPLIES (WHOL)',
  'LAPPING',
  'Laser',
  'LASER CUTTING',
  'LASER PRINTERS (WHOLESALE)',
  'LASER SURGERY',
  'LASER VISION CORRECTION',
  'LASERS (WHOLESALE)',
  'LASERS & ELECTRO OPTICS CONSULTANTS',
  'LASERS-COMMUNICATION RESEARCH & DEV',
  'LASERS-EQUIPMENT & SERVICE (WHOLESALE)',
  'LASERS-MEDICAL-MANUFACTURERS',
  'Last Mile Transportation',
  'LATEX LIQUID (MANUFACTURERS)',
  'LATEX PRODUCTS (MANUFACTURERS)',
  'LATH (WHOLESALE)',
  'LATH-MTL PLASTER COMPOSITION/ETC (WHOL)',
  'LATHE ATTACHMENTS (MANUFACTURERS)',
  'LATHING CONTRACTORS',
  'LAUHALA PRODUCTS',
  'LAUNDRIES',
  'LAUNDRIES-INDUSTRIAL & COMMERCIAL',
  'LAUNDRIES-SELF SERVICE',
  'LAUNDRY & GARMENT SERVICES NEC',
  'Laundry and Dry-cleaning',
  'LAUNDRY AND GARMENT SERVICES',
  'LAUNDRY CONSULTANTS',
  'LAUNDRY EQUIP-COML-COIN OPERATED (WHOL)',
  'LAUNDRY EQUIPMENT (WHOLESALE)',
  'LAUNDRY EQUIPMENT-REPAIRING',
  'LAUNDRY SUPPLIES (WHOLESALE)',
  'LAW BOOKS',
  'LAW BRIEF PRINTERS',
  'LAW CITATION RETRIEVAL SERVICE',
  'Law Enforcement',
  'LAW ENFORCEMENT CONSULTANTS',
  'Law Practice',
  'LAW SCHOOLS',
  'LAWN & GARDEN EQUIP & SUPPLIES-RETAIL',
  'LAWN & GARDEN EQUIPMENT & SUPLS-WHOL',
  'LAWN & GARDEN EQUIPMENT-RENTING',
  'LAWN & GARDEN FURNISHINGS',
  'LAWN & GARDEN OUTDOOR POWER EQUIP (MFRS)',
  'LAWN & GROUNDS MAINTENANCE',
  'LAWN AND GARDEN EQUIPMENT',
  'LAWN AND GARDEN SERVICES',
  'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing',
  'LAWN CONSULTANTS',
  'LAWN MOWERS',
  'LAWN MOWERS-MANUFACTURERS',
  'LAWN MOWERS-PARTS & SUPPLIES',
  'LAWN MOWERS-SHARPENING & REPAIRING',
  'LAWN MOWERS-USED',
  'LAWN MOWERS-WHOLESALE',
  'LAWN SPRAYING',
  'LAWN SWEEPERS-MANUFACTURERS',
  'LAWN SWEEPERS-WHOLESALE',
  'LAWN/GRDN TRACTORS/HOME LAWN EQPT (MFRS)',
  'LAYOUTS-OFFICE FACTORY & ETC',
  'LEAD (WHOLESALE)',
  'LEAD & ZINC ORES',
  'LEAD AND ZINC ORES',
  'LEAD BURNING (MANUFACTURERS)',
  'Lead Generation',
  'Lead Management',
  'LEAD PAINT DETECTION & REMOVAL SCHOOLS',
  'LEAD PAINT DETECTION SERVICE',
  'LEAD PENCILS AND ART GOODS',
  'LEAD PENCILS CRAYONS/ARTISTS MTRL (MFRS)',
  'LEAD PRODUCTS (MANUFACTURERS)',
  'LEAD REMOVAL & ABATEMENT',
  'LEAK DETECTING INSTRUMENTS (WHOLESALE)',
  'LEAK DETECTING SERVICE',
  'LEARNING DISABILITIES INFORMATION SVC',
  'LEARNING DISABILITY COUNSELING',
  'Leasing',
  'LEASING EQUIPMENT',
  'LEASING SERVICE',
  'LEATHER (WHOLESALE)',
  'LEATHER & SHEEP-LINED CLOTHING (MFRS)',
  'Leather and Hide Tanning and Finishing',
  'LEATHER AND SHEEP-LINED CLOTHING',
  'LEATHER APPAREL-MANUFACTURERS',
  'LEATHER APPAREL-WHOLESALE',
  'LEATHER CLEANING',
  'LEATHER CLOTHING-RETAIL',
  'LEATHER CRAFT SUPPLIES',
  'LEATHER FINDINGS (WHOLESALE)',
  'LEATHER FINISHERS',
  'LEATHER GLOVES & MITTENS (MFRS)',
  'LEATHER GLOVES AND MITTENS',
  'LEATHER GOODS',
  'LEATHER GOODS & CLOTHES-WHOLESALE',
  "LEATHER GOODS MFRS' SUPPLIES-MFRS",
  'LEATHER GOODS NEC (MANUFACTURERS)',
  'LEATHER GOODS-CUSTOM MADE',
  'LEATHER GOODS-DEALERS',
  'LEATHER GOODS-MANUFACTURERS',
  'LEATHER GOODS-MANUFACTURERS SUPLS (WHOL)',
  'LEATHER GOODS-REPAIRING',
  'LEATHER GOODS-TRIMMINGS (MANUFACTURERS)',
  'LEATHER TANNING AND FINISHING',
  'LEATHER-ARTIFICIAL (MANUFACTURERS)',
  'LEATHER-MANUFACTURERS',
  'LEATHER-PRINTING & DECORATING (MFRS)',
  'LEATHER-SCRAP (MANUFACTURERS)',
  'LEATHER-SOLE (MANUFACTURERS)',
  'LEATHER-TANNING & FINISHING (MFRS)',
  'LEATHER-UPPER (MANUFACTURERS)',
  'LEATHER-WORKING MACHINERY (MFRS)',
  'LECITHIN (MANUFACTURERS)',
  'LECTERNS (WHOLESALE)',
  'LECTURE & SEMINAR BUREAUS',
  'Legal',
  'LEGAL CLINICS',
  'LEGAL COUNSEL AND PROSECUTION',
  'Legal Counsel and Prosecution',
  'LEGAL FORMS (WHOLESALE)',
  'LEGAL FORMS-MANUFACTURERS',
  'LEGAL GRAPHICS',
  'LEGAL MARKETING',
  'LEGAL RESEARCH',
  'LEGAL SERVICE PLANS',
  'LEGAL SERVICES',
  'Legal Services',
  'LEGAL SERVICES - ATTORNEYS',
  'Legal Tech',
  'LEGISLATIVE ANALYSTS & CONSULTANTS',
  'LEGISLATIVE ASSEMBLIES',
  'LEGISLATIVE BODIES',
  'Legislative Bodies',
  'Legislative Office',
  'LEGISLATIVE RESEARCH',
  'Leisure',
  'Leisure, Travel & Tourism',
  'Lending',
  'Lending and Investments',
  'LENS COATING (WHOLESALE)',
  'LENSES-MANUFACTURERS',
  'LENSES-WHOLESALE',
  'Lessors of Miniwarehouses and Self-Storage Units',
  'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
  'Lessors of Nonresidential Buildings (except Miniwarehouses)',
  'Lessors of Other Real Estate Property',
  'LESSORS OF RAILROAD PROPERTY',
  'LESSORS OF REAL PROPERTY NEC',
  'Lessors of Residential Buildings and Dwellings',
  'LETTER SHOP SERVICE',
  'LETTER WRITING SERVICE',
  'LETTERING MACHINES (MANUFACTURERS)',
  'LETTERS OF CREDIT',
  'LETTERS-SIGN-MANUFACTURERS',
  'LEVELS (WHOLESALE)',
  'LGBT',
  'LIBRARIES',
  'Libraries',
  'Libraries and Archives',
  'LIBRARIES-CIRCULATING & RENTAL',
  'LIBRARIES-INSTITUTIONAL',
  'LIBRARIES-PUBLIC',
  'LIBRARY CONSULTANTS',
  'LIBRARY EQUIPMENT & SUPPLIES (WHOLESALE)',
  'LIBRARY NETWORKS',
  'LIBRARY RESEARCH & SERVICE',
  'LIBRARY SUPPORT SERVICES',
  'LICENSE PLATE FRAMES-MANUFACTURERS',
  'LICENSE PLATE FRAMES-WHOLESALE',
  'LICENSE SERVICES',
  'LICORICE (MANUFACTURERS)',
  'LIE DETECTORS (WHOLESALE)',
  'LIEN PROCESSING SERVICE',
  'LIFE CARE COMMUNITIES',
  'LIFE INSURANCE',
  'Life Insurance',
  'LIFE INSURANCE (UNDERWRITERS)',
  'LIFE PRESERVERS',
  'LIFE PRESERVERS-MANUFACTURERS',
  'LIFE RAFT INFLATION EQUIPMENT',
  'LIFE RAFTS & FLOATS-MANUFACTURERS',
  'Life Science',
  'Lifestyle',
  'LIFTS (WHOLESALE)',
  'LIFTS-MANNED',
  'LIGHT BULBS & TUBES (WHOLESALE)',
  'Light Truck and Utility Vehicle Manufacturing',
  'LIGHTERAGE',
  'LIGHTING',
  'Lighting',
  'LIGHTING CONSULTANTS',
  'LIGHTING ENGINEERS',
  'LIGHTING EQUIPMENT',
  'LIGHTING EQUIPMENT NEC (MANUFACTURERS)',
  'LIGHTING EQUIPMENT-EMERGENCY (WHOLESALE)',
  'LIGHTING EQUIPMENT-MANUFACTURERS',
  'LIGHTING FIXTURES-MANUFACTURERS',
  'LIGHTING FIXTURES-REPAIRING & MNTNC',
  'LIGHTING FIXTURES-RETAIL',
  'LIGHTING FIXTURES-SUPPLIES & PARTS-MFRS',
  'LIGHTING FIXTURES-USED',
  'LIGHTING FIXTURES-WHOLESALE',
  'LIGHTING SYSTEMS & EQUIPMENT (WHOLESALE)',
  'LIGHTING-MAINTENANCE SERVICE',
  'LIGHTING-MARQUEE & DISPLAY',
  'LIGHTING-PLANTS (WHOLESALE)',
  'LIGHTNING PROTECTION EQUIPMENT',
  'LIGHTNING PROTECTION EQUIPMENT-MFRS',
  'LIGHTNING-PROTECTION EQUIPMENT (WHOL)',
  'LIME',
  'LIME & LIMESTONE-WHOLESALE',
  'LIME HAULING',
  'Lime Manufacturing',
  'LIME-MANUFACTURERS',
  'LIME-MANUFACTURERS (PESTICIDES)',
  'LIMESTONE',
  'LIMESTONE-AGRICULTURAL',
  'LIMITED SPECIALTY CONTRACTORS',
  'Limited-Service Restaurants',
  'Limousine Service',
  'LIMOUSINE SERVICE',
  'Limousine Service',
  'LIMOUSINE-CONVERSIONS',
  'LIMOUSINE-DEALERS',
  'Line-Haul Railroads',
  'LINEMENS EQUIPMENT & SUPPLIES (WHOL)',
  'LINEN SUPPLY',
  'Linen Supply',
  'LINEN SUPPLY SERVICE',
  'LINENS-MANUFACTURERS',
  'LINENS-PIECE GOODS (WHOLESALE)',
  'LINENS-RETAIL',
  'LINENS-WHOLESALE',
  'LINGERIE',
  'Lingerie',
  'LINGERIE-MANUFACTURERS',
  'LINGERIE-WHOLESALE',
  'LININGS-BAG BARREL & ETC (WHOLESALE)',
  'LININGS-CLOTHING (MANUFACTURERS)',
  'LININGS-DRAPERY (MANUFACTURERS)',
  'LININGS-PITS PONDS LAGOONS ETC (MFRS)',
  'LININGS-PLASTIC MEMBRANE & ETC',
  'LINOLEUM LAYERS',
  'LINOLEUM LAYERS EQUIPMENT & SUPPLIES',
  'LINOLEUM-ASPHALT FELT BASE FLR CVR (MFR)',
  'LINOLEUM-DEALERS',
  'LINOLEUM-MANUFACTURERS',
  'LINOLEUM-WHOLESALE',
  'LINOLEUM/HARD SURFACE FLR CVRS NEC (MFR)',
  'LINOTYPE COMPOSITION (MANUFACTURERS)',
  'Linux',
  'LIP READING INSTRUCTION',
  'LIQUEFIED PETROLEUM GAS DEALERS',
  'LIQUID AIR (WHOLESALE)',
  'LIQUIDATORS',
  'LIQUOR BROKERS',
  'LIQUOR CONTROL SYSTEMS (WHOLESALE)',
  'LIQUOR STORES',
  'LIQUOR TESTING',
  'LIQUORS-RETAIL',
  'LIQUORS-WHOLESALE',
  'LITERARY AGENTS',
  'LITHOGRAPHERS',
  'LITHOGRAPHERS EQUIPMENT & SUPLS (WHOL)',
  'LITHOGRAPHERS PLATE SERVICE',
  'LITHOGRAPHIC COMPOSITION (MFRS)',
  'LITHOGRAPHIC NEGATIVES & PLATES (MFRS)',
  'LITHOGRAPHING ON METAL',
  'LITIGATION SUPPORT SERVICE',
  'LIVESTOCK',
  'Livestock',
  'LIVESTOCK APPRAISERS',
  'LIVESTOCK AUCTION MARKETS',
  'LIVESTOCK BREEDERS',
  'LIVESTOCK BUYERS',
  'LIVESTOCK CARE SERVICES',
  'LIVESTOCK COMMISSION',
  'LIVESTOCK CONSULTANTS',
  'LIVESTOCK EQUIPMENT & SUPPLIES (WHOL)',
  'LIVESTOCK EQUIPMENT & SUPPLIES-MFRS',
  'LIVESTOCK FEED (MANUFACTURERS)',
  'LIVESTOCK FEEDING',
  'LIVESTOCK GROOMING',
  'LIVESTOCK HAULING',
  'Livestock Merchant Wholesalers',
  'LIVESTOCK PRODUCERS',
  'LIVESTOCK RECORDING ASSOCIATIONS',
  'LIVESTOCK SERVICES',
  'LIVESTOCK SERVICES-EXCEPT VETERINARY',
  'LIVESTOCK SPRAYING',
  'LIVESTOCK-DEALERS (WHOLESALE)',
  'LIVESTOCK-EMBRYO TRANSFER',
  'LLAMAS',
  'LOAD CONTROLLERS',
  'LOADING DOCK EQUIPMENT (WHOLESALE)',
  'LOADING DOCK EQUIPMENT-MANUFACTURERS',
  'LOAM',
  'LOAN BROKERAGE',
  'LOAN BROKERS',
  'LOANS',
  'LOANS-AGRICULTURAL',
  'LOANS-ALTERNATIVE',
  'LOANS-AUTOMOBILE',
  'LOANS-PERSONAL',
  'LOBBYISTS',
  'LOBSTERS',
  'Local',
  'Local Advertising',
  'LOCAL AND SUBURBAN TRANSIT',
  'LOCAL BUS CHARTER SERVICE',
  'LOCAL BUS CHARTER SERVICE',
  'Local Business',
  'Local Messengers and Local Delivery',
  'LOCAL PASSENGER TRANSPORTATION',
  'LOCAL PASSENGER TRANSPORTATION NEC',
  'Local Shopping',
  'LOCAL TRUCKING',
  'LOCAL TRUCKING WITH STORAGE',
  'LOCAL TRUCKING WITH STORAGE',
  'Location Based Services',
  'LOCK BOXES',
  'LOCKERS',
  'LOCKERS-MANUFACTURERS',
  'LOCKERS-USED (WHOLESALE)',
  'LOCKERS-WHOLESALE',
  'LOCKS & LOCKSMITHS',
  'LOCKS & LOCKSMITHS-WHOLESALE',
  'LOCKS-MFRS-EXCEPT SAFE VAULT COIN',
  'LOCKSMITH EQUIPMENT & SUPPLIES (WHOL)',
  'Locksmiths',
  'LOCOMOTIVES (MANUFACTURERS)',
  'LODGES-FISHING & HUNTING',
  'LOG BROKERS',
  'LOG BUYERS (WHOLESALE)',
  'LOG CABINS HOMES & BUILDINGS (MFRS)',
  'LOG CHINKING',
  'LOG EXPORTERS',
  'LOG HAULING',
  'LOG RAFTING',
  'LOG SCALERS',
  'LOG SIDING (MANUFACTURERS)',
  'LOG SPLITTING EQUIPMENT (WHOLESALE)',
  'LOG STORAGE COMPANIES',
  'LOGGING',
  'LOGGING',
  'Logging',
  'LOGGING COMPANIES',
  'Logistics',
  'Logistics and Supply Chain',
  'LOGOS',
  'LONG DISTANCE TELEPHONE SERVICE',
  'LONG TERM CARE FACILITY',
  'LOOSE LEAF EQUIPMENT SYSTS & SUPLS-MFRS',
  'LOT CLEANERS',
  'LOTTERY AGENTS',
  'LOUD SPEAKERS',
  'LOUD SPEAKERS-MANUFACTURERS',
  'LOUD SPEAKERS-REPAIRING',
  'LOUVERS (WHOLESALE)',
  'LOUVERS-MANUFACTURERS',
  'LOW RENT HOUSING',
  'LOW VISION AIDS',
  'LOW VOLTAGE SYSTEMS CONTRACTORS',
  'Loyalty Programs',
  'LUAUS',
  'LUBRICANTS (WHOLESALE)',
  'LUBRICANTS-DRY (WHOLESALE)',
  'LUBRICANTS-MANUFACTURERS',
  'LUBRICANTS-SYNTHETIC (WHOLESALE)',
  'LUBRICATING COMPOUNDS (WHOLESALE)',
  'LUBRICATING DEVICES & SYSTEMS (MFRS)',
  'LUBRICATING EQUIPMENT (WHOLESALE)',
  'LUBRICATING OILS & GREASES (MFRS)',
  'LUBRICATING OILS AND GREASES',
  'LUBRICATING SERVICE-MOBILE',
  'LUGGAGE',
  'LUGGAGE (MANUFACTURERS)',
  'LUGGAGE AND LEATHER GOODS STORES',
  'Luggage and Leather Goods Stores',
  'LUGGAGE CARRIERS',
  'LUGGAGE COVERS',
  'LUGGAGE-MANUFACTURERS EQUIP/SUPLS (WHOL)',
  'LUGGAGE-REPAIRING',
  'LUGGAGE-RETAIL',
  'LUGGAGE-WHOLESALE',
  'LUGS & TERMINALS-ELECTRICAL (MFRS)',
  'LUMBER',
  'LUMBER AND OTHER BUILDING MATERIALS',
  'LUMBER BROKERS',
  'LUMBER CARRIERS',
  'LUMBER MILL REPRESENTATIVES (WHOLESALE)',
  'LUMBER TERMINALS',
  'LUMBER-COMMISSION',
  'LUMBER-DRYING (WHOLESALE)',
  'LUMBER-EXPORTERS & IMPORTERS (WHOL)',
  'LUMBER-INSPECTORS',
  'LUMBER-MANUFACTURERS',
  'LUMBER-RETAIL',
  'LUMBER-TREATING',
  'LUMBER-USED',
  'LUMBER-WHOLESALE',
  'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers ',
  'LUMINOUS PRODUCTS (MANUFACTURERS)',
  'LUNCHEONETTE FOOD SUPPLY',
  'Luxury Goods & Jewelry',
  'MACADAMIA NUTS',
  'MACARONI (MANUFACTURERS)',
  'MACARONI AND SPAGHETTI',
  'MACARONI MACHINERY (MANUFACTURERS)',
  'MACARONI PRODUCTS (WHOLESALE)',
  'MACARONI SPAGHETTI & NOODLES (MFRS)',
  'MACHINE FOUNDATIONS',
  'Machine Learning',
  'MACHINE PARTS-MANUFACTURERS',
  'MACHINE SHOP EQUIPMENT (MANUFACTURERS)',
  'MACHINE SHOP SUPPLIES (WHOLESALE)',
  'Machine Shops',
  'MACHINE SHOPS',
  'MACHINE SHOPS-EXPERIMENTAL',
  'MACHINE TOOL ACCESSORIES',
  'Machine Tool Manufacturing',
  'MACHINE TOOLS',
  'MACHINE TOOLS',
  'MACHINE TOOLS (WHOLESALE)',
  'MACHINE TOOLS-DESIGNERS & CONSULTANTS',
  'MACHINE TOOLS-MANUFACTURERS',
  'MACHINE TOOLS-REPAIRING/REBLDG (WHOL)',
  'Machinery',
  'Machinery Manufacturing',
  'MACHINERY-CLEANING',
  'MACHINERY-DESIGNING',
  'MACHINERY-MANUFACTURERS',
  'MACHINERY-MOVERS & ERECTORS',
  'MACHINERY-MOVING EQUIPMENT-MANUFACTURERS',
  'MACHINERY-NEW (WHOLESALE)',
  'MACHINERY-REBUILDING & REPAIRING (WHOL)',
  'MACHINERY-RENTING',
  'MACHINERY-SPECIALLY DESIGNED & BUILT',
  'MACHINERY-USED (WHOLESALE)',
  'macOS',
  'MACRAME SUPPLIES',
  'MACRAME-MANUFACTURERS',
  'MACRAME-WHOLESALE',
  'Made to Order',
  'MAGAZINES-BACK NUMBER (WHOLESALE)',
  'MAGAZINES-DEALERS',
  'MAGAZINES-DISTRIBUTORS',
  'MAGAZINES-SUBSCRIPTION AGENTS',
  'MAGAZINES-USED & RARE',
  'MAGICIANS',
  'MAGICIANS SUPPLIES',
  'MAGICIANS SUPPLIES-MANUFACTURERS',
  'MAGNESITE (WHOLESALE)',
  'MAGNESIUM & MAGNESIUM PRODUCTS (MFRS)',
  'MAGNETIC AND OPTICAL RECORDING MEDIA',
  'MAGNETIC RESONANCE IMAGING',
  'MAGNETIC/OPTICAL RECORDING MEDIA (MFRS)',
  'MAGNETOS (WHOLESALE)',
  'MAGNETS & MAGNETIC DEVICES (WHOLESALE)',
  'MAGNIFYING GLASSES (MANUFACTURERS)',
  'MAID & BUTLER SERVICE',
  'MAIDS & BUTLERS SVC-REFERRAL & INFO SVC',
  'MAIL BOX-RENTALS',
  'MAIL BOXES (WHOLESALE)',
  'MAIL BOXES-MANUFACTURERS',
  'MAIL BOXES-RETAIL',
  'MAIL CHUTES (MANUFACTURERS)',
  'MAIL ORDER & CATALOG SHOPPING',
  'MAIL ORDER FULFILLMENT SERVICE',
  'MAIL RECEIVING SERVICE',
  'MAIL SORTING SERVICE',
  'MAILING & SHIPPING SERVICES',
  'MAILING CONTAINERS (MANUFACTURERS)',
  'MAILING CONTAINERS-WHOLESALE',
  'MAILING LISTS',
  'MAILING MACHINES & EQUIPMENT (WHOLESALE)',
  'MAILING MACHINES & EQUIPMENT-MFRS',
  'MAINTENANCE PROGRAMS',
  'MAINTENANCE-GENERAL REPAIR',
  'Major Household Appliance Manufacturing',
  'MAJORETTE SUPPLIES',
  'MAKE-UP STUDIOS',
  'MAKE-UP-MASQUERADE & THEATRICAL-MFRS',
  'MAKE-UP-MASQUERADE & THEATRICAL-RETAIL',
  'MAKE-UP-MASQUERADE & THEATRICAL-WHOL',
  'MALLEABLE IRON FOUNDRIES',
  'MALT',
  'MALT (MANUFACTURERS)',
  'MALT BEVERAGES',
  'MALT BEVERAGES (MANUFACTURERS)',
  'Malt Manufacturing',
  'MALTED MILK (WHOLESALE)',
  'MALTS & HOPS (WHOLESALE)',
  'MAMMOGRAPH',
  'Management Consulting',
  'MANAGEMENT CONSULTING SERVICES',
  'Management Information Systems',
  'MANAGEMENT INVESTMENT',
  'MANAGEMENT INVESTMENT OPEN-END',
  'MANAGEMENT RESEARCH',
  'MANAGEMENT SERVICES',
  'MANAGEMENT SERVICES',
  'MANAGEMENT TRAINING',
  'MANHOLE COVERS (MANUFACTURERS)',
  'MANICURE EQUIPMENT & SUPPLIES (WHOL)',
  'MANICURE IMPLEMENTS-MANUFACTURERS',
  'MANICURING',
  'MANIFOLD BUSINESS FORMS',
  'MANIFOLD BUSINESS FORMS (MFRS)',
  'MANMADE ORGANIC FBRS-EX CELLULOSIC (MFR)',
  'MANNEQUINS-DISPLAY FIXTURE (WHOLESALE)',
  'MANTELS',
  'Manufactured (Mobile) Home Dealers',
  'Manufactured Home (Mobile Home) Manufacturing',
  'MANUFACTURED ICE',
  'MANUFACTURERS',
  'MANUFACTURERS-AGENTS & REPRESENTATIVES',
  'MANUFACTURERS-COATINGS',
  'MANUFACTURERS-LATH',
  'MANUFACTURERS-SHIPPING PRODUCTS',
  'MANUFACTURERS-SNOW REMOVAL EQUIPMENT',
  'MANUFACTURERS-SNOWMOBILES',
  'MANUFACTURERS-TRAILERS',
  'MANUFACTURERS-WATERING SYSTEMS',
  'Manufacturing',
  'MANUFACTURING CONTAINERS-STEEL',
  'MANUFACTURING INDUSTRIES',
  'MANURE HANDLING EQUIP & SYSTEMS (WHOL)',
  'MANURE REMOVAL',
  'MANUSCRIPT EDITING',
  'MANUSCRIPT SERVICE',
  'MAPLE SUGAR & SYRUP',
  'MAPLE SUGAR & SYRUP-WHOLESALE',
  'MAPLE SUGAR-MANUFACTURERS SUPLS (WHOL)',
  'Mapping Services',
  'MAPPING SERVICES (WHOLESALE)',
  'MAPS-DEALERS',
  'MAPS-MOUNTING',
  'MAPS-PUBLISHERS & DISTRS',
  'MARBLE & TERRAZZO-CLEANING & SERVICE',
  'MARBLE CONTRACTORS',
  'MARBLE CONTRACTORS EQUIP & SUPLS (MFRS)',
  'MARBLE DECORATORS',
  'MARBLE PRODUCTS-NATURAL-MANUFACTURERS',
  'MARBLE-CULTURED (WHOLESALE)',
  'MARBLE-CULTURED-RETAIL',
  'MARBLE-NATURAL (WHOLESALE)',
  'MARBLE-NATURAL-RETAIL',
  'MARBLE-PRE-CAST (WHOLESALE)',
  'MARBLE-SYNTHETIC (WHOLESALE)',
  'MARBLES-PLAYING & ETC (MANUFACTURERS)',
  'MARCHING UNITS INSTRUCTION & ACCESSORIES',
  'MARGARINE (MANUFACTURERS)',
  'Marinas',
  'MARINAS',
  'MARINAS',
  'Marine Cargo Handling',
  'MARINE CARGO HANDLING',
  'MARINE CARGO HANDLING',
  'MARINE CONSULTANTS',
  'MARINE CONTRACTORS & DESIGNERS',
  'MARINE DOCUMENTATION',
  'MARINE ELECTRIC SERVICE',
  'MARINE ELECTRONIC EQUIP & SUPLS (WHOL)',
  'MARINE ELECTRONIC EQUIP & SUPLS-RETAIL',
  'MARINE ELECTRONIC EQUIP-REPAIRING & SVC',
  'MARINE ELECTRONIC EQUIPMENT & SUPL-MFRS',
  'MARINE ELECTRONICS',
  'MARINE ENGINES-REPAIRING',
  'MARINE EQUIPMENT & SUPPLIES',
  'MARINE EQUIPMENT & SUPPLIES-MFRS',
  'MARINE EQUIPMENT & SUPPLIES-REPAIRING',
  'MARINE EQUIPMENT & SUPPLIES-WHOLESALE',
  'MARINE EQUIPMENT-USED & REBUILT',
  'MARINE INSTRUMENTS-MANUFACTURERS',
  'MARINE OPERATORS',
  'MARINE REFRIGERATION & AIR COND-MFRS',
  'MARINE REFRIGERATION/AC-SALES/SVC (WHOL)',
  'MARINE REPAIRS',
  'MARINE RESOURCE CONSULTANTS',
  'MARINE SERVICE STATIONS',
  'Marine Technology',
  'Marine Transportation',
  'MARINE TRANSPORTATION CONSULTANTS',
  'MARINE VESSEL MANAGEMENT',
  'MARINE WAYS',
  'MARINE-RADIO TELEPHONE EQUIPMENT (WHOL)',
  'MARINE-RADIO TELEPHONE SERVICE',
  'MARIONETTES & PUPPETS (MANUFACTURERS)',
  'Maritime',
  'Market Research',
  'MARKET RESEARCH & ANALYSIS',
  'Marketing',
  'Marketing and Advertising',
  'MARKETING ASSOCIATIONS-NEC',
  'Marketing Automation',
  'MARKETING CONSULTANTS',
  'Marketing Consulting Services',
  'MARKETING PROGRAMS & SERVICES',
  'Marketing Research and Public Opinion Polling',
  'MARKETING/INDUSTRY PUBLICATIONS-NEC',
  'Marketplace',
  'MARKETS & GROCERS EQUIP & SUPLS (WHOL)',
  'MARKETS-KOSHER',
  'MARKING DEVICES',
  'MARKING DEVICES (MANUFACTURERS)',
  'MARKING DIES (MANUFACTURERS)',
  'MARKING SERVICE-METAL RUBBER & ETC',
  'MARQUETRY',
  'MARRIAGE & FAMILY COUNSELORS',
  'MARRIAGE BROKERS',
  'MARSH BUGGIES (WHOLESALE)',
  'MARTIAL ARTS INSTRUCTION',
  'MARTIAL ARTS SUPPLIES & EQUIPMENT',
  'MASERS (MANUFACTURERS)',
  'MASKS-MASQUERADE & NOVELTY (MFRS)',
  'MASKS-PROTECTIVE (MANUFACTURERS)',
  'MASON CONTRACTORS EQUIP & SUPLS (WHOL)',
  'MASON CONTRACTORS EQUIPMENT & SUPLS-MFRS',
  'MASONIC OUTFITTERS',
  'MASONRY AND OTHER STONEWORK',
  'MASONRY CONTRACTORS',
  'Masonry Contractors',
  'MASONRY PANELS-PREFABRICATED',
  'MASONRY SUPPLIES',
  'MASSAGE',
  'MASSAGE EQUIPMENT & SUPPLIES (WHOLESALE)',
  'MASSAGE SCHOOLS',
  'MASSAGE THERAPISTS',
  'MASTS & SPARS (MANUFACTURERS)',
  'MATCHES-MFRS ADVERTISING REPRESENTATIVES',
  'MATCHES-WHOLESALE',
  'MATERIAL HANDLING EQUIPMENT (WHOLESALE)',
  'MATERIAL HANDLING EQUIPMENT-MFRS',
  'MATERIAL HANDLING EQUIPMENT-SVC & REPAIR',
  'MATERIAL HANDLING SYSTEMS CONSULTANTS',
  'Materials Recovery Facilities',
  'MATERNITY APPAREL',
  'MATERNITY APPAREL-MANUFACTURERS',
  'MATERNITY APPAREL-WHOLESALE',
  'MATERNITY SERVICES',
  'MATERNITY SUPPORTS',
  'MATHEMATICIANS',
  'MATRICES (MANUFACTURERS)',
  'MATS & MATTING (WHOLESALE)',
  'MATS & MATTING-MANUFACTURERS',
  'MATS & MATTING-RENTING',
  'MATS & MATTING-RETAIL',
  'MATTRESS & COMFORTER COVERS',
  'MATTRESS MAKERS SUPPLIES (WHOLESALE)',
  'MATTRESS MAKERS SUPPLIES-MANUFACTURERS',
  'Mattress Manufacturing',
  'MATTRESS PADS & COVERS',
  'MATTRESSES',
  'MATTRESSES AND BEDSPRINGS',
  'MATTRESSES-MANUFACTURERS',
  'MATTRESSES-RENOVATING',
  'MATTRESSES-WHOLESALE',
  'MATTRESSES/FOUNDATIONS/CONV BEDS (MFRS)',
  'MATZOS',
  'MAUSOLEUMS',
  'MAYONNAISE & SALAD DRESSINGS (WHOLESALE)',
  'MAYONNAISE & SALAD DRESSINGS-MFRS',
  'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing ',
  'MEASURES-DRY LIQUID ETC (MANUFACTURERS)',
  'MEASURING & DISPENSING PUMPS (MFRS)',
  'MEASURING AND CONTROLLING DEVICES',
  'MEASURING AND DISPENSING PUMPS',
  'MEASURING MACHINES (MANUFACTURERS)',
  'MEASURING TAPES & RULES (MANUFACTURERS)',
  'Measuring, Dispensing, and Other Pumping Equipment Manufacturing ',
  'MEASURING/CONTROLLING DEVICES NEC (MFRS)',
  'MEAT AND FISH MARKETS',
  'Meat and Meat Product Merchant Wholesalers',
  'MEAT BROKERS',
  'MEAT CHOPPERS & GRINDERS (MANUFACTURERS)',
  'MEAT CURING SERVICE',
  'MEAT CUTTING SERVICE',
  'MEAT JOBBERS',
  'MEAT LOCKERS',
  'Meat Markets',
  'MEAT MARKETS-KOSHER',
  'MEAT PACKERS',
  'MEAT PACKERS EQUIPMENT & SUPPLIES (WHOL)',
  'MEAT PACKING PLANTS',
  'MEAT PATTY MOLDING MACHINES (WHOLESALE)',
  'Meat Processed from Carcasses',
  'MEAT PROCESSING EQUIPMENT (WHOLESALE)',
  'MEAT PRODUCTS',
  'MEAT SAWS (MANUFACTURERS)',
  'MEAT TENDERIZING PREPARATIONS',
  'MEAT-KOSHER-WHOLESALE',
  'MEAT-RETAIL',
  'MEAT-WHOLESALE',
  'MEATS AND MEAT PRODUCTS',
  'MEATS-FROZEN (WHOLESALE)',
  'MECHANICAL CONTRACTORS',
  'Mechanical Design',
  'Mechanical Engineering',
  'Mechanical or Industrial Engineering',
  'MECHANICAL POWER TRANS EQUIP NEC (MFRS)',
  'Mechanical Power Transmission Equipment Manufacturing',
  'MECHANICAL RUBBER GOODS',
  'MECHANOTHERAPISTS DM',
  'MEDALS-DESIGNERS & MANUFACTURERS',
  'Media and Entertainment',
  'MEDIA BROKERS',
  'Media Buying Agencies',
  'MEDIA BUYING SERVICE',
  'MEDIA CONSULTANTS',
  'MEDIA MONITORING',
  'Media Production',
  'Media Representatives',
  'Medical',
  'MEDICAL & SURGICAL PLANS',
  'MEDICAL & SURGICAL SVC ORGANIZATIONS',
  'MEDICAL ALARMS (WHOLESALE)',
  'MEDICAL AND HOSPITAL EQUIPMENT',
  'MEDICAL BILL REVIEW',
  'MEDICAL BOOKS',
  'MEDICAL BUSINESS ADMINISTRATION',
  'MEDICAL CENTERS',
  'Medical Device',
  'Medical Devices',
  'MEDICAL DIAGNOSTIC EQUIPMENT-MOBILE',
  'MEDICAL ELECTRONICS (WHOLESALE)',
  'MEDICAL EMERGENCY TRAINING',
  'MEDICAL EQUIPMENT RENTAL',
  'MEDICAL EQUIPMENT-RENTALS',
  'MEDICAL EQUIPMENT-REPAIRING',
  'MEDICAL EXAMINATIONS-INSURANCE',
  'MEDICAL EXAMINERS',
  'MEDICAL GROUPS',
  'MEDICAL IMAGING-MAGNETIC',
  'MEDICAL INFORMATION SERVICES',
  'MEDICAL LABORATORIES',
  'Medical Laboratories',
  'MEDICAL LEGAL CONSULTANTS',
  'MEDICAL MANAGEMENT CONSULTANTS',
  'MEDICAL MANAGEMENT SERVICE',
  'Medical Practice',
  'MEDICAL PROVIDER CONSULTANTS',
  'MEDICAL RECORDS SERVICE',
  'MEDICAL RESEARCH',
  'MEDICAL SERVICES-MOBILE',
  'MEDICAL SERVICES-TEMPORARY',
  'MEDICAL STAFF RELIEF',
  'MEDICAL TRANSCRIPTION SERVICE',
  'MEDICAL TRANSPORTATION',
  'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers ',
  'Medicinal and Botanical Manufacturing',
  'MEDICINAL CHEM/BOTANICAL PRODS (MFRS)',
  'MEDICINALS AND BOTANICALS',
  'MEDICINE CABINETS',
  'MEDICINE CABINETS-DISTRIBUTORS',
  'MEDICINE CABINETS-MANUFACTURERS',
  'MEDICINES-PATENT & PROPRIETARY',
  'MEDICINES-PATENT-MANUFACTURERS',
  'MEDICINES-PATENT-WHOLESALE',
  'MEDITATION INSTRUCTION',
  'MEDITATION ORGANIZATIONS',
  'MEETING & CONFERENCE CONSULTANTS',
  'Meeting Software',
  'MEGAPHONES',
  'MELTING POTS (MANUFACTURERS)',
  'MEMBERSHIP ORGANIZATIONS',
  'MEMBERSHIP ORGANIZATIONS NEC',
  'MEMBERSHIP SPORTS & RECREATION CLUBS',
  'MEMBERSHIP SPORTS AND RECREATION CLUBS',
  'MEMBERSHIP-BASIS ORGANIZATION HOTELS',
  'MEMORABILIA',
  'MEMORIAL RESTORATION & PRESERVATION',
  'MEMORIAL SOCIETIES',
  'MEMORY SYSTEMS & COMPONENTS',
  'MEMORY TRAINING IMPROVEMENTS',
  "Men's",
  "MEN'S AND BOY'S CLOTHING",
  "MEN'S AND BOY'S CLOTHING",
  "MEN'S AND BOY'S FURNISHINGS",
  "MEN'S AND BOY'S NECKWEAR",
  "MEN'S AND BOY'S SUITS AND COATS",
  "MEN'S AND BOY'S TROUSERS AND SLACKS",
  "MEN'S AND BOY'S UNDERWEAR AND NIGHTWEAR",
  "MEN'S AND BOY'S WORK CLOTHING",
  "Men's and Boys' Clothing and Furnishings Merchant Wholesalers",
  "MEN'S AND BOYS' CLOTHING STORES",
  "MEN'S CLOTHING & FURNISHINGS-RETAIL",
  "Men's Clothing Stores",
  "MEN'S CLOTHING-MANUFACTURERS",
  "MEN'S FOOTWEAR",
  "MEN'S FOOTWEAR-EXCEPT ATHLETIC (MFRS)",
  "MEN'S FURNISHINGS-MANUFACTURERS",
  "MEN'S ORGANIZATION & SERVICES",
  'MENS & BOYS CLOTHING NEC (MFRS)',
  'MENS & BOYS SUITS COATS/OVERCOATS (MFRS)',
  'MENS & BOYS UNDERWEAR & NIGHTWEAR (MFRS)',
  'MENS & BOYS WORK CLOTHING (MFRS)',
  'Mental Health Care',
  'MENTAL HEALTH CLINICS',
  'MENTAL HEALTH COUNSELORS',
  'MENTAL HEALTH SERVICES',
  'MENTAL RETARDATION & DEV DISABLED SVCS',
  'MENTAL RETARDATION INFORMATION CENTERS',
  'MENU COVERS (MANUFACTURERS)',
  'MENU PRINTERS',
  'MENU SERVICE (MANUFACTURERS)',
  'Men�s and Boys� Cut and Sew Apparel Manufacturing',
  'MERCANTILE AGENCIES',
  'MERCHANDISE BROKERS',
  'MERCHANDISE MARTS',
  'MERCHANDISING MACHINE OPERATORS',
  'MERCHANDISING SERVICE',
  'MERCURY (MANUFACTURERS)',
  'MERGERS & ACQUISITIONS',
  'Messaging',
  'Messaging and Telecommunications',
  'MESSENGER SERVICE',
  'METAL BARRELS',
  'METAL BUYERS (WHOLESALE)',
  'Metal Can Manufacturing',
  'METAL CANS',
  'METAL CANS (MANUFACTURERS)',
  'METAL CEILINGS',
  'METAL CLEANING',
  'METAL CLEANING EQUIPMENT',
  'METAL COATING AND ALLIED SERVICES',
  'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers ',
  'METAL COATINGS (MANUFACTURERS)',
  'Metal Crown, Closure, and Other Metal Stamping (except Automotive) ',
  'METAL CUTTING',
  'METAL CUTTING COOLANT SYSTEMS (MFRS)',
  'METAL DOORS',
  'METAL DOORS SASH FRAMES & TRIM (MFRS)',
  'METAL EXTRUSIONS-IMPACT (WHOLESALE)',
  'METAL FABRICATORS',
  'METAL FINDINGS (MANUFACTURERS)',
  'METAL FINISHERS (MANUFACTURERS)',
  'METAL FINISHERS-BLASTING (MFRS)',
  'METAL FINISHERS-EQUIPMENT & SUPLS (WHOL)',
  'METAL FOIL AND LEAF',
  'METAL FORMING MACHINERY (MANUFACTURERS)',
  'METAL GOODS-MANUFACTURERS',
  'METAL HEAT TREATING',
  'Metal Heat Treating',
  'METAL HOUSEHOLD FURNITURE',
  'Metal Household Furniture Manufacturing',
  'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing ',
  'METAL LATH (MANUFACTURERS)',
  'METAL LETTERS (WHOLESALE)',
  'METAL LOCATING EQUIPMENT',
  'METAL MINING SERVICES',
  'METAL MINING SERVICES',
  'METAL ORES',
  'METAL POLISHING (MANUFACTURERS)',
  'METAL POWDERS (WHOLESALE)',
  'METAL ROOFING CONTRACTORS',
  'METAL SANITARY WARE',
  'Metal Service Centers and Other Metal Merchant Wholesalers',
  'METAL SHAPES-EXTRUDED (MANUFACTURERS)',
  'METAL SHAPING',
  'METAL SHPNG BARRELS DRUM KEG/PAIL (MFRS)',
  'METAL SPECIALTIES (WHOLESALE)',
  'METAL SPECIALTIES-MANUFACTURERS',
  'METAL SPINNING (MANUFACTURERS)',
  'METAL SPINNING EQUIPMENT (MFRS)',
  'METAL SPRAYING',
  'METAL SPRAYING EQUIPMENT (MANUFACTURERS)',
  'METAL STAMPING (MANUFACTURERS)',
  'METAL STAMPING EQUIPMENT (MANUFACTURERS)',
  'METAL STAMPINGS',
  'METAL STAMPINGS NEC (MANUFACTURERS)',
  'Metal Tank (Heavy Gauge) Manufacturing',
  'METAL TREATING (MANUFACTURERS)',
  'Metal Window and Door Manufacturing',
  'METAL WORKING MACHINERY (WHOLESALE)',
  'METAL-DISINTEGRATING',
  'METAL-DISTRIBUTORS',
  'METAL-HOUSEHOLD FURNITURE (MFRS)',
  'METAL-PERFORATING & PUNCHING (MFRS)',
  'METAL-PHOTOETCHING',
  'METAL-POWDER PRODUCTS (MANUFACTURERS)',
  'METAL-POWDER-FABRICATORS',
  'METAL-POWDER-PARTS-FABRICATORS',
  'METAL-ROLLING & FORMING',
  'METAL-STEEL (WHOLESALE)',
  'METAL-TRIM (MANUFACTURERS)',
  'METALLIC FABRICS (MANUFACTURERS)',
  'METALLIZING (MANUFACTURERS)',
  'METALLIZING EQUIPMENT (MANUFACTURERS)',
  'METALLURGISTS',
  'METALLURGISTS EQUIPMENT & SUPPLIES-MFRS',
  'METALLURGISTS EQUIPMENT & SUPPLIES-WHOL',
  'METALS SERVICE CENTERS AND OFFICES',
  'METALS-BASE (WHOLESALE)',
  'METALS-CLAD (MANUFACTURERS)',
  'METALS-CORRUGATED (MANUFACTURERS)',
  'METALS-PRE-COATED (MANUFACTURERS)',
  'METALS-PRECIOUS SHEET WIRE TUBING (WHOL)',
  'METALWORKING MACHINERY',
  'METALWORKING MACHINERY NEC (MFRS)',
  'METAPHYSICAL SUPPLIES & SERVICES',
  'METAPHYSICIANS',
  'METEOROLOGICAL CONSULTANTS',
  'METEOROLOGICAL INSTRUMENTS (WHOLESALE)',
  'METEOROLOGISTS',
  'METERS (WHOLESALE)',
  'METERS-REPAIRING',
  'METHANOL (MANUFACTURERS)',
  'METRIC CONVERSION CONSULTANTS',
  'MEXICAN & LATIN AMERICAN FOOD PRODUCTS',
  'MEXICAN FOOD PRODUCTS-MANUFACTURERS',
  'MEXICAN FOOD PRODUCTS-WHOLESALE',
  'MEXICAN GOODS',
  'MEZZANINE & PLATFORM SYSTS/EQUIP (WHOL)',
  'mHealth',
  'MICA (MANUFACTURERS)',
  'Micro Lending',
  'MICROBIOLOGISTS',
  'MICROFILM STORAGE (WHOLESALE)',
  'MICROFILMING SERVICE EQUIP & SUPLS-MFRS',
  'MICROFILMING SERVICE EQUIP & SUPLS-WHOL',
  'MICROFILMING SERVICE EQUIPMENT & SUPLS',
  'MICROGRAPHICS SERVICE EQUIP/SUPLS (WHOL)',
  'MICROMETERS (WHOLESALE)',
  'MICROPHONES',
  'MICROPROCESSORS-MANUFACTURERS',
  'MICROPROCESSORS-WHOLESALE',
  'MICROSCOPES (WHOLESALE)',
  'MICROWAVE COMM EQUIP & SYSTEMS (WHOL)',
  'MICROWAVE OVENS',
  'MICROWAVE OVENS-MANUFACTURERS',
  'MICROWAVE OVENS-REPAIRING',
  'MICROWAVE OVENS-SUPPLIES & PARTS (WHOL)',
  'MICROWAVE SERVICE & EQUIPMENT',
  'MIDGET RACE CARS & EQUIPMENT',
  'MIDWIVES',
  'MILDEWPROOFING',
  'Military',
  'Military Armored Vehicle, Tank, and Tank Component Manufacturing ',
  'MILK BANKS',
  'MILK BARS',
  'MILK BUYERS',
  'MILK DISPENSERS',
  'MILK DISPENSERS-MANUFACTURERS',
  'MILK-CONDENSED EVAPORATED ETC',
  'MILK-POWDERED (WHOLESALE)',
  'MILL ENDS (MANUFACTURERS)',
  'MILL EQUIPMENT & SUPPLIES (WHOLESALE)',
  'MILL STORES',
  'Millennials',
  'MILLINERS-MANUFACTURERS',
  'MILLINERY-RETAIL',
  'MILLINERY-SUPPLIES (WHOLESALE)',
  'MILLINERY-WHOLESALE',
  'MILLING',
  'MILLING CUTTERS (MANUFACTURERS)',
  'MILLING MACHINES (MANUFACTURERS)',
  'MILLS-FLOUR OAT & BRAN',
  'MILLS-PAPERBOARD',
  'MILLWORK',
  'MILLWORK (MANUFACTURERS)',
  'MILLWORK-RETAIL',
  'MILLWORK-WHOLESALE',
  'MILLWRIGHTS',
  'MINE RESCUE',
  'MINE SHAFT CONTRACTORS',
  'Mineral',
  'MINERAL EXPLORATION',
  'MINERAL SPIRITS (MANUFACTURERS)',
  'MINERAL WOOL',
  'MINERAL WOOL (MANUFACTURERS)',
  'Mineral Wool Manufacturing',
  'MINERALOGISTS',
  'MINERALS',
  'MINERALS',
  'MINERALS/EARTHS-GROUND OR TREATED (MFRS)',
  'MINES',
  'MINI-BIKE DEALERS',
  'MINI-BIKE ENGINES',
  'MINI-BLINDS-SUPPLIES & PARTS',
  'MINIATURE ITEMS FOR COLLECTORS',
  'MINIATURES FOR COLLECTORS-WHOLESALE',
  'Mining',
  'Mining & Metals',
  'MINING BROKERS',
  'MINING COMPANIES',
  'MINING CONSULTANTS',
  'MINING CONTRACTORS',
  'MINING EQUIPMENT (WHOLESALE)',
  'MINING EQUIPMENT & SUPPLIES-MFRS',
  'MINING EQUIPMENT & SUPPLIES-RETAIL',
  'MINING MACHINERY',
  'Mining Machinery and Equipment Manufacturing',
  'Mining Technology',
  'MINISTRIES-OUT REACH',
  'MINK FARMS',
  'MINTS (COIN MINTING)',
  'MIRRORS',
  'MIRRORS-MANUFACTURERS',
  'MIRRORS-RESILVERING',
  'MIRRORS-WHOLESALE',
  'MISC BUSINESS CREDIT INSTITUTIONS',
  'MISC ELECTRICAL GOODS (WHOLESALE)',
  'MISC EQUIPMENT-RENTAL & LEASING',
  'MISC INDSTRL EQUIP & SUPLS NEC (WHOL)',
  'MISC NON-DURABLE GOODS NEC (WHOLESALE)',
  'MISC SVC ESTABLISHMENT EQUIP/SUPL (WHOL)',
  'MISCELLANEOUS APPAREL AND ACCESSORIES',
  'MISCELLANEOUS BUSINESS CREDIT',
  'MISCELLANEOUS FABRICATED WIRE PRODUCTS',
  'Miscellaneous Financial Investment Activities',
  'MISCELLANEOUS FOOD STORES',
  'MISCELLANEOUS FOOD STORES',
  'MISCELLANEOUS GENERAL MERCHANDISE',
  'MISCELLANEOUS HOMEFURNISHINGS',
  'MISCELLANEOUS HOMEFURNISHINGS STORES',
  'MISCELLANEOUS INDUSTRIAL SUPPLIES (WHOL)',
  'Miscellaneous Intermediation',
  'MISCELLANEOUS LEASING COMPANIES',
  'MISCELLANEOUS MARINE PRODUCTS',
  'MISCELLANEOUS MARINE PRODUCTS',
  'MISCELLANEOUS METAL ORES NEC',
  'MISCELLANEOUS METALWORK',
  'MISCELLANEOUS NONMETALLIC MINERALS',
  'MISCELLANEOUS NONMETALLIC MINING',
  'MISCELLANEOUS PERSONAL SERVICES',
  'MISCELLANEOUS PERSONAL SERVICES NEC',
  'MISCELLANEOUS PUBLISHING',
  'MISCELLANEOUS RETAIL STORES',
  'MISCELLANEOUS RETAIL STORES NEC',
  'MISSILE & ROCKET COMPONENTS-MFRS',
  'MISSILE & ROCKET PROPULSION EQUIP (MFRS)',
  'MISSILE & ROCKET RESEARCH & DEV (MFRS)',
  'MISSILE & ROCKET-MANUFACTURERS',
  'MISSILE & ROCKETS EQUIPMENT & SUPPLIES',
  'MISSILE/ROCKET GRND SUPPORT EQUIP (MFRS)',
  'MISSILE/ROCKET INSTR/GUIDANCE EQPT (MFR)',
  'MISSING PERSONS BUREAUS',
  'MISSIONS',
  'MISTING SYSTEMS-OUTDOOR (WHOLESALE)',
  'Mixed Mode Transit Systems',
  'MIXING & AGITATION MACHINERY (WHOLESALE)',
  'MMO Games',
  'Mobile',
  'Mobile Advertising',
  'Mobile Apps',
  'MOBILE CONCESSIONS',
  'Mobile Devices',
  'Mobile Food Services',
  'MOBILE HOME DEALERS',
  'MOBILE HOME SITE OPERATORS',
  'MOBILE HOMES',
  'MOBILE HOMES (MANUFACTURERS)',
  'MOBILE HOMES & TRAILERS-PARKS DESIGNERS',
  'MOBILE HOMES-ANCHORING SERVICE',
  'MOBILE HOMES-APPRAISERS',
  'MOBILE HOMES-CONSULTANTS',
  'MOBILE HOMES-DEALERS',
  'MOBILE HOMES-DEALERS-USED',
  'MOBILE HOMES-DISTRIBUTORS',
  'MOBILE HOMES-EQUIPMENT & PARTS',
  'MOBILE HOMES-EQUIPMENT & PARTS-MFRS',
  'MOBILE HOMES-EQUIPMENT & PARTS-WHOLESALE',
  'MOBILE HOMES-FINANCING',
  'MOBILE HOMES-IMPROVEMENTS',
  'MOBILE HOMES-LISTING SERVICE',
  'MOBILE HOMES-MANUFACTURERS',
  'MOBILE HOMES-MFRS-EQUIP/SUPLS (WHOL)',
  'MOBILE HOMES-PARK DEVELOPERS',
  'MOBILE HOMES-PARKS & COMMUNITIES',
  'MOBILE HOMES-RENTING & LEASING',
  'MOBILE HOMES-REPAIRING & SERVICE',
  'MOBILE HOMES-ROOFING & ROOF COVERING',
  'MOBILE HOMES-TRANSPORTING',
  'MOBILE HOMES-WASHING & CLEANING',
  'MOBILE OFFICES & COML UNITS-REPAIRING',
  'MOBILE OFFICES & COMMERCIAL UNITS',
  'MOBILE OFFICES/COMMERCIAL UNITS-RENTING',
  'Mobile Payments',
  'MOBILE RADIO EMERGENCY COMMUNICATIONS',
  'MOBILE TELEPHONE EQUIP & SUPLS (WHOL)',
  'MOBILE TELEPHONE SERVICE',
  'MOBILE TELEPHONE SERVICE-NON CELLULAR',
  'MOBILE TELEPHONE UNITS (WHOLESALE)',
  'MOCCASINS',
  'MODEL CAR RACING CENTERS',
  'MODEL MAKERS',
  'MODEL RAILROAD EQUIPMENT & SUPPLIES',
  'MODELING AGENCIES',
  'MODELING CONSULTANTS-PHOTOGRAPHIC',
  'MODELING SCHOOLS',
  'MODULAR HOMES-DEALERS',
  'MODULAR PRODUCTS-MANUFACTURERS',
  'MOHAIR (WHOLESALE)',
  'MOISTURE DETECTION EQUIPMENT (MFRS)',
  'MOLASSES (WHOLESALE)',
  'MOLD MAKERS',
  'MOLDED EXTRUDED/LATHE CUT RBBR GDS (MFR)',
  'MOLDING-MANUFACTURERS',
  'MOLDINGS (WHOLESALE)',
  'MOLDS (MANUFACTURERS)',
  'MOLYBDENUM (MANUFACTURERS)',
  'MONASTERIES',
  'Monetary Authorities-Central Bank',
  'MONEY BROKERS',
  'MONEY MARKET FUNDS',
  'MONEY ORDER SERVICE',
  'MONEY ORDER SYSTEMS',
  'MONEY TRANSFER SERVICE',
  'MONOGRAMS',
  'MONORAIL SYSTEMS (WHOLESALE)',
  'MONORAIL TRANSIT SYSTEMS-MANUFACTURERS',
  'MONTESSORI SCHOOLS',
  'MONUMENT MAKERS EQUIPMENT (WHOLESALE)',
  'MONUMENT MEDALLIONS (WHOLESALE)',
  'MONUMENTS',
  'MONUMENTS-CLEANING',
  'MONUMENTS-MANUFACTURERS',
  'MONUMENTS-WHOLESALE',
  'MOOC',
  'MOORAGES',
  'MOPEDS',
  'MOPS (WHOLESALE)',
  'MOPS-MANUFACTURERS',
  'MOPS-RENTAL',
  'MORTAR (WHOLESALE)',
  'Mortgage and Nonmortgage Loan Brokers',
  'MORTGAGE BANKERS AND CORRESPONDENTS',
  'MORTGAGE BROKERS',
  'MORTGAGE FEASIBILITY CONSULTANTS',
  'MORTUARY TRANSPORTATION SERVICE',
  'MOSAICS (WHOLESALE)',
  'MOSQUES',
  'MOSQUITO ABATEMENT',
  'MOSS CONTROL',
  'MOSS REMOVAL',
  'MOTEL & HOTEL CONSULTANTS',
  'MOTEL & HOTEL EQUIPMENT & SUPLS (WHOL)',
  'MOTEL & HOTEL EQUIPMENT & SUPPLIES-MFRS',
  'MOTEL & HOTEL MANAGEMENT',
  'MOTELS & HOTELS RESERVATIONS',
  'MOTHPROOFING',
  'MOTHPROOFING SUPPLIES (MANUFACTURERS)',
  'Motion Capture',
  'MOTION PICTURE AND TAPE DISTRIBUTION',
  'Motion Picture and Video Distribution',
  'MOTION PICTURE AND VIDEO PRODUCTION',
  'Motion Picture and Video Production',
  'MOTION PICTURE CASTING',
  'MOTION PICTURE CONSULTANTS',
  'MOTION PICTURE DISTRIBUTION SERVICES',
  'MOTION PICTURE EQUIP & SUPPLIES (WHOL)',
  'MOTION PICTURE EQUIP & SUPPLIES-MFRS',
  'MOTION PICTURE EQUIP & SUPPLIES-REPAIR',
  'MOTION PICTURE EQUIPMENT & SUPPLIES-HOME',
  'MOTION PICTURE FILM (WHOLESALE)',
  'MOTION PICTURE FILM-DELIVERY',
  'MOTION PICTURE FILM-DISTRS & EXCHS',
  'MOTION PICTURE FILM-EDIT FCLTS-RENTING',
  'MOTION PICTURE FILM-EDITING',
  'MOTION PICTURE FILM-INSPCTN-CLEANING/ETC',
  'MOTION PICTURE FILM-LIBRARIES',
  'MOTION PICTURE FILM-MUSIC SCORING',
  'MOTION PICTURE FILM-STORAGE',
  'MOTION PICTURE LABORATORIES',
  'MOTION PICTURE LOCATION SERVICE',
  'MOTION PICTURE PRODUCERS & STUDIOS',
  'MOTION PICTURE PROJECTION ROOMS',
  'MOTION PICTURE PROJECTION SERVICE',
  'MOTION PICTURE PROPERTIES (WHOLESALE)',
  'MOTION PICTURE REPRESENTATIVES',
  'MOTION PICTURE RESEARCH SERVICE',
  'MOTION PICTURE SERVICING',
  'MOTION PICTURE SET DESIGN & CONSTRUCTION',
  'MOTION PICTURE SOUND SERVICES',
  'MOTION PICTURE SPECIAL EFFECTS',
  'MOTION PICTURE STOCK SHOTS',
  'MOTION PICTURE TECHNICIANS',
  'MOTION PICTURE THEATERS',
  'Motion Picture Theaters (except Drive-Ins)',
  'Motion Pictures and Film',
  'MOTIVATIONAL & SELF IMPROVEMENT TRAINING',
  'MOTOR & GENERATOR-MANUFACTURERS',
  'Motor and Generator Manufacturing',
  'Motor Home Manufacturing',
  'MOTOR HOMES',
  'MOTOR HOMES EQUIPMENT & PARTS',
  'MOTOR HOMES-MANUFACTURERS',
  'MOTOR HOMES-RENTING & LEASING',
  'MOTOR HOMES-REPAIRING & SERVICE',
  'MOTOR HOMES-RETAIL',
  'Motor Vehicle Body Manufacturing',
  'Motor Vehicle Brake System Manufacturing',
  'MOTOR VEHICLE DEALERS-USED ONLY',
  'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
  'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
  'Motor Vehicle Metal Stamping',
  'MOTOR VEHICLE PARTS',
  'Motor Vehicle Parts (Used) Merchant Wholesalers',
  'MOTOR VEHICLE PARTS AND ACCESSORIES',
  'Motor Vehicle Seating and Interior Trim Manufacturing',
  'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
  'MOTOR VEHICLE SUPPLIES AND NEW PARTS',
  'Motor Vehicle Supplies and New Parts Merchant Wholesalers',
  'Motor Vehicle Towing',
  'Motor Vehicle Transmission and Power Train Parts Manufacturing',
  'MOTOR VEHICLES AND CAR BODIES',
  'MOTOR VHCLS/PASSENGER CAR BODIES (MFRS)',
  'MOTORCYCLE CAMPERS-RETAIL',
  'MOTORCYCLE DEALERS',
  'MOTORCYCLE INSPECTION STATIONS',
  'MOTORCYCLE INSTRUCTION',
  'MOTORCYCLE-BICYCLE & PARTS (MFRS)',
  'Motorcycle, ATV, and All Other Motor Vehicle Dealers ',
  'Motorcycle, Bicycle, and Parts Manufacturing ',
  'MOTORCYCLE/MTR SCTRS-PT/SUPL-USED (WHOL)',
  'MOTORCYCLES',
  'MOTORCYCLES & MOTOR SCOOTERS-ACCESSORIES',
  'MOTORCYCLES & MOTOR SCOOTERS-DEALERS',
  'MOTORCYCLES & MOTOR SCOOTERS-MFRS',
  'MOTORCYCLES & MOTOR SCOOTERS-PAINTING',
  'MOTORCYCLES & MOTOR SCOOTERS-RENT/LEASE',
  'MOTORCYCLES & MOTOR SCOOTERS-RPR & SVC',
  'MOTORCYCLES & MOTOR SCOOTERS-SUPPLIES',
  'MOTORCYCLES & MOTOR SCOOTERS-USED',
  'MOTORCYCLES-ANTIQUE & CLASSIC',
  'MOTORCYCLES-CUSTOMIZING',
  'MOTORCYCLES-SUPLS & PARTS-MANUFACTURERS',
  'MOTORCYCLES-SUPLS & PARTS-WHOLESALE',
  'MOTORCYCLES-TRANSPORTING',
  'MOTORS AND GENERATORS',
  'MOTORS-MINIATURE',
  'MOUNTAIN CLIMBING INSTRUCTION',
  'MOUNTING & FINISHING',
  'MOUNTING & FINISHING (PHOTOGRAPHS)',
  'MOVERS',
  'MOVIE MEMORABILIA',
  'MOVIE THEATRES (DRIVE-INS)',
  'MOVING SUPPLIES & EQUIPMENT',
  'MOVING SUPPLIES & EQUIPMENT-RENTING',
  'MOVING-SELF-SERVICE',
  'MOWING SERVICE',
  'MUD JACKING CONTRACTORS',
  'MUFFLERS & EXHAUST SYSTEMS-ENGINE',
  'MUFFLERS & EXHAUST SYSTEMS-ENGINE-MFRS',
  'MUFFLERS & EXHAUST SYSTEMS-ENGINE-WHOL',
  'MUGS',
  'MULCHES',
  'MULTI-LEVEL MARKETING',
  'Multi-level Marketing',
  'MULTIMEDIA (MANUFACTURERS)',
  'MUNICIPAL CONSULTANTS',
  'MUNICIPAL SUPPLIES (WHOLESALE)',
  'MURALS',
  'MUSEUMS',
  'Museums',
  'MUSEUMS AND ART GALLERIES',
  'Museums and Historical Sites',
  'Museums and Institutions',
  'Mushroom Production',
  'MUSHROOMS',
  'Music',
  'MUSIC & LIVE ENTERTAINMENT',
  'Music and Audio',
  'MUSIC ARRANGERS & COMPOSERS',
  'MUSIC BINDING',
  'MUSIC BOXES',
  "MUSIC COMPOSERS' AGENTS",
  'MUSIC COPYISTS',
  'MUSIC DEALERS',
  'Music Education',
  'MUSIC INSTRUCTION-INSTRUMENTAL',
  'MUSIC INSTRUCTION-VOCAL',
  'Music Label',
  'MUSIC PRODUCTION CONSULTANTS',
  'Music Publishers',
  'MUSIC PUBLISHERS',
  'MUSIC RECORDED DANCE',
  'MUSIC ROLLS',
  'MUSIC SHOWS',
  'Music Streaming',
  'MUSIC THERAPY',
  'Music Venues',
  'MUSIC WORKSHOPS',
  'MUSIC WRITERS SUPPLIES (WHOLESALE)',
  'MUSIC-BACKGROUND & FOREGROUND',
  'MUSIC-ENTERTAINMENT',
  'MUSIC-MANUSCRIPT REPRODUCTIONS (MFRS)',
  'MUSIC-PRINTERS & ENGRAVERS',
  'MUSIC-RENTAL',
  'MUSIC-SHEET-MANUFACTURERS',
  'Musical Groups and Artists',
  'Musical Instrument and Supplies Stores',
  'Musical Instrument Manufacturing',
  'MUSICAL INSTRUMENT STORES',
  'MUSICAL INSTRUMENTS',
  'Musical Instruments',
  'MUSICAL INSTRUMENTS (MANUFACTURERS)',
  'MUSICAL INSTRUMENTS-DEALERS',
  'MUSICAL INSTRUMENTS-MANUFACTURERS',
  'MUSICAL INSTRUMENTS-RENTAL',
  'MUSICAL INSTRUMENTS-REPAIRING',
  'MUSICAL INSTRUMENTS-SUPLS & ACCES-MFRS',
  'MUSICAL INSTRUMENTS-SUPLS & ACCES-WHOL',
  'MUSICAL INSTRUMENTS-SUPPLIES & ACCES',
  'MUSICAL INSTRUMENTS-USED',
  'MUSICAL INSTRUMENTS-WHOLESALE',
  'MUSICIANS',
  'MUSTARD (MANUFACTURERS)',
  'MUTUAL FUNDS',
  'MYOFUNCTIONAL THERAPY',
  'MYOTHERAPY',
  'NAIL POLISH-MANUFACTURERS',
  'Nail Salons',
  'NAILED WOOD BOXES AND SHOOK',
  'NAILED/LOCK COR WOOD BOXES/SHOOK (MFRS)',
  'NAILHEADS & NAILHEAD TRIMMING (WHOL)',
  'NAILING MACHINERY (WHOLESALE)',
  'NAILS & TACKS (WHOLESALE)',
  'NAILS & TACKS-MANUFACTURERS',
  'NAME PLATES (WHOLESALE)',
  'NAME PLATES-MANUFACTURERS',
  'Nanotechnology',
  'NAPHTHA (WHOLESALE)',
  'NAPRAPATHS',
  'NARROW FABRIC & OTHER SMALLWARES-MILLS',
  'NARROW FABRIC MILLS',
  'Narrow Fabric Mills and Schiffli Machine Embroidery',
  'NATIONAL COMMERCIAL BANKS',
  'NATIONAL SECURITY',
  'National Security',
  'National Security',
  'NATIVES SERVICE ORGANIZATIONS',
  'NATURAL FOOD PRODUCTS-MANUFACTURERS',
  'NATURAL GAS DISTRIBUTION',
  'Natural Gas Distribution',
  'Natural Gas Extraction',
  'NATURAL GAS LIQUIDS',
  'NATURAL GAS LIQUIDS',
  'NATURAL GAS TRANSMISSION',
  'NATURAL GAS TRANSMISSION',
  'NATURAL GAS TRANSMISSION & DISTRIBUTION',
  'NATURAL HEALTH & EDUCATION',
  'Natural Language Processing',
  'Natural Resources',
  'NATURAL RESOURCES CONSULTANTS',
  'NATURE CENTERS',
  'Nature Parks and Other Similar Institutions',
  'NAUTICAL INSTRUMENTS (WHOLESALE)',
  'NAVAL ARCHITECTS',
  'NAVAL STORES (MANUFACTURERS)',
  'Navigation',
  'Navigation and Mapping',
  'NAVIGATION CONSULTANTS',
  'NAVIGATION SCHOOLS',
  'Navigational Services to Shipping',
  'NECKWEAR-MANUFACTURERS SUPPLIES (MFRS)',
  'NECKWEAR-MENS-MANUFACTURERS',
  'NECKWEAR-RETAIL',
  'NECKWEAR-WHOLESALE',
  'NECKWEAR-WOMENS-MANUFACTURERS',
  'NEEDLES',
  'NEEDLEWORK & NEEDLEWORK MATERIALS-MFRS',
  'NEEDLEWORK & NEEDLEWORK MATERIALS-RETAIL',
  'NEEDLEWORK & NEEDLEWORK MTRLS-WHOL/MFR',
  'NEGLIGEES-MANUFACTURERS',
  'NEON NOVELTIES',
  'NEON SIGNS (WHOLESALE)',
  'NEON-ART',
  'NETS & NETTINGS-WHOLESALE',
  'NETTINGS-RETAIL',
  'Network Hardware',
  'Network Security',
  'NEURO-LINGUISTIC PROGRAMMING',
  'Neuroscience',
  'NEW AND USED CAR DEALERS',
  'New Car Dealers',
  'New Housing For-Sale Builders',
  'New Multifamily Housing Construction (except For-Sale Builders)',
  'New Single-Family Housing Construction (except For-Sale Builders)',
  'News',
  'NEWS DEALERS',
  'NEWS DEALERS AND NEWSSTANDS',
  'News Dealers and Newsstands',
  'NEWS DEALERS-MANUFACTURERS',
  'NEWS PUBLICATIONS-TRADE/ASSN/ETC (MFRS)',
  'NEWS SERVICE',
  'News Syndicates',
  'NEWS SYNDICATES',
  'NEWSLETTERS (MANUFACTURERS)',
  'NEWSPAPER CORRESPONDENTS',
  'NEWSPAPER DEALERS-BACK NUMBER (WHOL)',
  'NEWSPAPER DELIVERY',
  'NEWSPAPER DISTRIBUTORS',
  'NEWSPAPER FEATURE SYNDICATES',
  'Newspaper Publishers',
  'NEWSPAPER PUBLISHING & PRINTING (MFRS)',
  'NEWSPAPER REPRESENTATIVES',
  'NEWSPAPERS',
  'Newspapers',
  'NEWSPAPERS (PUBLISHERS)',
  'Newsprint Mills',
  'NEWSRACKS',
  'NEWSREELS',
  'NFC',
  'NICKEL (WHOLESALE)',
  'NICKEL-SILVER (WHOLESALE)',
  'NIGHT CLUBS',
  'NIGHT CLUBS INFORMATION SERVICE',
  'Nightclubs',
  'Nightlife',
  'Nintendo',
  'NITROGEN (WHOLESALE)',
  'Nitrogenous Fertilizer Manufacturing',
  'NITROGENOUS FERTILIZERS',
  'NOISE CONTROL CONSULTANTS',
  'Non Profit',
  'NON-PROFIT ORGANIZATIONS',
  'Nonchocolate Confectionery Manufacturing',
  'NONCLASSIABLE ESTABLISHMENTS',
  'NONCLASSIFIED ESTABLISHMENTS',
  'NONCLAY REFRACTORIES',
  'NONCLAY REFRACTORIES',
  'NONCOMMERCIAL RESEARCH ORGANIZATIONS',
  'Noncurrent-Carrying Wiring Device Manufacturing',
  'NONCURRENT-CARRYING WIRING DEVICES',
  'NONCURRENT-CARRYING WIRING DEVICES (MFR)',
  'NONDEPOSIT TRUST FACILITIES',
  'NONDEPOSIT TRUST FACILITIES',
  'NONDURABLE GOODS',
  'NONFERROUS DIE-CASTINGS EXCEPT ALUMINUM',
  'Nonferrous Forging',
  'NONFERROUS FORGINGS',
  'NONFERROUS FORGINGS (MANUFACTURERS)',
  'NONFERROUS FOUNDRIES',
  'NONFERROUS FOUNDRIES-EX ALUMINUM/COPPER',
  'Nonferrous Metal (except Aluminum) Smelting and Refining',
  'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding ',
  'Nonferrous Metal Die-Casting Foundries',
  'NONFERROUS ROLLING AND DRAWING',
  'NONFERROUS WIREDRAWING AND INSULATING',
  'NONMETALLIC MINERAL PRODUCTS',
  'NONMETALLIC MINERAL PRODUCTS NEC (MFRS)',
  'NONMETALLIC MINERAL SERVICES',
  'NONMETALLIC MINERALS SVC-EXCEPT FUELS',
  'Nonprofit Organization Management',
  'NONRESIDENTIAL BUILDING OPERATORS',
  'NONRESIDENTIAL CONSTRUCTION',
  'Nonresidential Property Managers',
  'Nonscheduled Chartered Freight Air Transportation',
  'Nonscheduled Chartered Passenger Air Transportation',
  'Nonupholstered Wood Household Furniture Manufacturing',
  'Nonwoven Fabric Mills',
  'NONWOVEN FABRICS',
  'NONWOVEN FABRICS (MANUFACTURERS)',
  'NOODLES (MANUFACTURERS)',
  'NOTARIES-PUBLIC',
  'NOTIONS-MANUFACTURERS',
  'NOTIONS-RETAIL',
  'NOTIONS-WHOLESALE',
  'NOVELTIES-MANUFACTURERS',
  'NOVELTIES-RETAIL',
  'NOVELTIES-WHOLESALE',
  'NOZZLES (WHOLESALE)',
  'Nuclear',
  'Nuclear Electric Power Generation',
  'NUCLEAR ENERGY CONSULTANTS',
  'NUCLEAR FUELS (MANUFACTURERS)',
  'NUCLEAR REACTORS-MANUFACTURERS',
  'NUCLEAR RESEARCH & DEVELOPMENT',
  'NUDIST PARKS',
  'NUMBER ISSUING SYSTEMS (MANUFACTURERS)',
  'NUMBERING MACHINES (MANUFACTURERS)',
  'NUMERICAL CONTROL MACHINING (MFRS)',
  'NURSERIES-PLANTS TREES & ETC-WHOLESALE',
  'Nursery and Tree Production',
  'Nursery, Garden Center, and Farm Supply Stores ',
  'NURSERYMEN',
  'NURSERYMEN EQUIPMENT & SUPPLIES (MFRS)',
  "NURSES & NURSES' REGISTRIES",
  'NURSES SCHOOLS',
  'NURSES SUPPLIES',
  'NURSES UNLICENSED',
  'NURSES-LICENSED-ANESTHETIST',
  'NURSES-PRACTITIONERS',
  'NURSES-RECRUITMENT',
  'NURSING & CONVALESCENT HOMES',
  'NURSING & PERSONAL CARE NEC',
  'NURSING AND PERSONAL CARE',
  'Nursing and Residential Care',
  'Nursing Care Facilities (Skilled Nursing Facilities)',
  'NURSING CONSULTANTS',
  'NURSING HOME EQUIPMENT & SUPLS (WHOL)',
  'NURSING HOME INFORMATION & REFERRAL',
  'NURSING HOME SERVICES',
  'NURSING HOMES CONSTR & MGMT CONSULTANTS',
  'NURSING HOMES MANAGEMENT SERVICE',
  'NURSING SERVICES',
  'NUT CRACKING & SHELLING MACHINES (MFRS)',
  'NUT ROASTING MACHINES (WHOLESALE)',
  'Nutraceutical',
  'NUTRIENTS',
  'Nutrition',
  'NUTRITIONISTS',
  'NUTS-EDIBLE',
  'NUTS-EDIBLE-PROCESSING',
  'NUTS-EDIBLE-WHOLESALE & PROCESSING',
  'NYLON FABRICS (WHOLESALE)',
  'OAKUM (MANUFACTURERS)',
  'OBSERVATORIES',
  'OCCULT SUPPLIES-WHOLESALE',
  'OCCUPATIONAL HEALTH & SAFETY SERVICES',
  'OCCUPATIONAL THERAPISTS',
  'OCEANOGRAPHERS',
  'OCEANOGRAPHIC EQUIPMENT (WHOLESALE)',
  'ODOR NEUTRALIZERS (WHOLESALE)',
  'OFF-ROAD VEHICLES',
  'OFF-TRACK VEHICLES-PARTS SUPLS & ACCESS',
  'OFFICE & DESK SPACE-RENTAL',
  'OFFICE & STORE FIXTURES-EX WOOD (MFRS)',
  'OFFICE & STORE FIXTURES-WOOD (MFRS)',
  'Office Administration',
  'Office Administrative Services',
  'OFFICE BUILDINGS & PARKS',
  'OFFICE EQUIPMENT',
  'OFFICE EQUIPMENT (WHOLESALE)',
  'Office Equipment Merchant Wholesalers',
  'OFFICE FURNITURE',
  'Office Furniture (except Wood) Manufacturing',
  'OFFICE FURNITURE & EQUIP-DEALERS (WHOL)',
  'OFFICE FURNITURE & EQUIP-INSTLTN (WHOL)',
  'OFFICE FURNITURE & EQUIP-MFRS',
  'OFFICE FURNITURE & EQUIP-RENTING',
  'OFFICE FURNITURE & EQUIP-RPR & REFINISH',
  'OFFICE FURNITURE & EQUIP-USED',
  'OFFICE FURNITURE & EQUIP-WHOLESALE',
  'OFFICE FURNITURE-EXCEPT WOOD (MFRS)',
  'Office Machinery and Equipment Rental and Leasing',
  'OFFICE MACHINES',
  'OFFICE MACHINES NEC (MANUFACTURERS)',
  'OFFICE PARTITIONS (WHOLESALE)',
  'OFFICE RECORDS-STORAGE',
  'OFFICE REMODELING',
  'OFFICE SUPPLIES',
  'Office Supplies (except Paper) Manufacturing',
  'Office Supplies and Stationery Stores',
  'OFFICE SUPPLIES-COMMERCIAL (WHOLESALE)',
  'OFFICE SUPPLIES-MANUFACTURERS',
  'OFFICE SUPPLIES-WHOLESALE',
  'OFFICE SYSTEMS-SERVICES',
  'OFFICES & CLINICS OF DOCTORS-OSTEOPATHY',
  'OFFICES AND CLINICS OF CHIROPRACTORS',
  'OFFICES AND CLINICS OF DENTISTS',
  'OFFICES AND CLINICS OF MEDICAL DOCTORS',
  'OFFICES AND CLINICS OF OPTOMETRISTS',
  'OFFICES AND CLINICS OF OSTEOPATHIC PHYSICIANS',
  'OFFICES AND CLINICS OF PODIATRISTS',
  'Offices of All Other Miscellaneous Health Practitioners',
  'Offices of Bank Holding Companies',
  'Offices of Certified Public Accountants',
  'Offices of Chiropractors',
  'Offices of Dentists',
  'OFFICES OF HEALTH PRACTITIONER',
  'OFFICES OF HEALTH PRACTITIONERS NEC',
  'Offices of Lawyers',
  'Offices of Mental Health Practitioners (except Physicians)',
  'Offices of Notaries',
  'Offices of Optometrists',
  'Offices of Other Holding Companies',
  'Offices of Physical, Occupational and Speech Therapists, and Audiologists ',
  'Offices of Physicians (except Mental Health Specialists)',
  'Offices of Physicians, Mental Health Specialists ',
  'Offices of Podiatrists',
  'Offices of Real Estate Agents and Brokers',
  'Offices of Real Estate Appraisers',
  'OFFSET NEGATIVE & PLATE SERVICE',
  'OFFSET REPRODUCTIONS',
  'OFFSET REPRODUCTIONS EQUIP & SUPL (MFRS)',
  'OFFSHORE PLATFORMS/OIL',
  'Oil & Energy',
  'OIL & GAS CONSULTANT',
  'OIL & GAS EXPLORATION & DEVELOPMENT',
  'OIL & GAS PRODUCERS',
  'OIL & GAS-FIELD MACHINERY/EQUIP (MFRS)',
  'OIL & GAS-FIELD SERVICES NEC',
  'OIL & GAS-PRODUCTION',
  'OIL & GREASE ABSORBENTS',
  'OIL ADDITIVES-DISTRIBUTORS',
  'OIL ADDITIVES-MANUFACTURERS',
  'Oil and Gas',
  'OIL AND GAS EXPLORATION SERVICES',
  'OIL AND GAS FIELD MACHINERY',
  'Oil and Gas Field Machinery and Equipment Manufacturing',
  'OIL AND GAS FIELD SERVICES',
  'Oil and Gas Pipeline and Related Structures Construction',
  'OIL BROKERS',
  'OIL BURNERS (WHOLESALE)',
  'OIL BURNERS EQUIPMENT (WHOLESALE)',
  'OIL BURNERS-MANUFACTURERS',
  'OIL BURNERS-SERVICING',
  'OIL BURNERS-SUPPLIES & PARTS (WHOLESALE)',
  'OIL COMPANIES',
  'OIL CRUDE',
  'OIL FIELD CHEMICALS (WHOLESALE)',
  'OIL FIELD COMPRESSOR SVC',
  'OIL FIELD CONSULTANTS',
  'OIL FIELD CONTRACTORS',
  'OIL FIELD ELECTRIC SERVICE',
  'OIL FIELD EQUIPMENT (WHOLESALE)',
  'OIL FIELD EQUIPMENT-MANUFACTURERS',
  'OIL FIELD EQUIPMENT-REPAIRING',
  'OIL FIELD HAULING',
  'OIL FIELD LABOR CREWS',
  'OIL FIELD MAINTENANCE',
  'OIL FIELD SERVICE',
  'OIL FIELD SPECIALTIES',
  'OIL FIELD SUPPLIES (WHOLESALE)',
  'OIL FIELD TRANSPORTATION SERVICE',
  'OIL HANDLING EQUIPMENT (WHOLESALE)',
  'OIL INSPECTORS',
  'OIL LAND LEASES',
  'OIL MARKETERS & DISTRIBUTORS',
  'OIL OPERATORS',
  'OIL PRODUCTS (WHOLESALE)',
  'OIL PROPERTIES',
  'OIL PROPERTY MANAGEMENT',
  'OIL RECOVERY-ENHANCED',
  'OIL REFINERS (MANUFACTURERS)',
  'OIL REPORTS',
  'OIL ROYALTIES',
  'OIL ROYALTY TRADERS',
  'OIL SEEDS (MANUFACTURERS)',
  'OIL SKIMMERS',
  'OIL SPILL BARRIERS (WHOLESALE)',
  'OIL SPILL CLEAN UP SERVICE',
  'OIL STORAGE',
  'OIL TREATING COMPOUNDS (WHOLESALE)',
  'OIL TRUCKERS',
  'OIL WELL CASING-PULLING',
  'OIL WELL CEMENTING',
  'OIL WELL CORE ANALYSIS',
  'OIL WELL DIRECTIONAL DRILLING',
  'OIL WELL DRILLING',
  'OIL WELL DRILLING MUD & ADDITIVES',
  'OIL WELL DRILLING MUD & ADDITIVES-MFRS',
  'OIL WELL DRILLING MUD & ADDITIVES-WHOL',
  'OIL WELL EQUIPMENT & SUPPLIES-RENTING',
  'OIL WELL EQUIPMENT & SUPPLIES-WHOL',
  'OIL WELL FISHING TOOLS (WHOLESALE)',
  'OIL WELL LOG LIBRARIES',
  'OIL WELL LOGGING & PERFORATING',
  'OIL WELL LOGGING/PERFORATING EQPT (MFRS)',
  'OIL WELL SERVICES',
  'OIL WELL SURVEYORS',
  'OIL WELL TESTING',
  'OIL WELL WIRE LINE SERVICE',
  'OIL-BULK-DEALERS-RETAIL',
  'OIL-BULK-DEALERS-WHOLESALE',
  'OIL-CRUDE-PURCHASING',
  'OILS & GREASES (WHOLESALE)',
  'OILS-ANIMAL & FISH (MANUFACTURERS)',
  'OILS-ASPHALT & ROAD',
  'OILS-CORE (MANUFACTURERS)',
  'OILS-CUTTING',
  'OILS-DIESEL (WHOLESALE)',
  'OILS-EDIBLE (WHOLESALE)',
  'OILS-EMULSION (WHOLESALE)',
  'OILS-ESSENTIAL (WHOLESALE)',
  'OILS-FUEL (WHOLESALE)',
  'OILS-FUEL-TREATMENT COMPOUNDS (WHOL)',
  'OILS-HYDRAULIC (WHOLESALE)',
  'OILS-HYDROGENATED (MANUFACTURERS)',
  'OILS-INDUSTRIAL (WHOLESALE)',
  'OILS-LUBRICATING-RETAIL',
  'OILS-LUBRICATING-WHOLESALE',
  'OILS-MINERAL (MANUFACTURERS)',
  'OILS-PETROLEUM (WHOLESALE)',
  'OILS-PETROLEUM-MANUFACTURERS',
  'OILS-PETROLEUM-RETAIL',
  'OILS-RECYCLING',
  'OILS-ROAD',
  'OILS-SYNTHETIC (WHOLESALE)',
  'OILS-VEGETABLE (MANUFACTURERS)',
  'OILS-VEGETABLE (WHOLESALE)',
  'OILS-WASTE (WHOLESALE)',
  'Oilseed (except Soybean) Farming',
  'Oilseed and Grain Combination Farming',
  'OLIVE OIL (MANUFACTURERS)',
  'OLIVES (WHOLESALE)',
  'OMBUDSMEN',
  'One-Hour Photofinishing',
  'ONIONS',
  'Online Auctions',
  'Online Forums',
  'Online Games',
  'Online Media',
  'Online Portals',
  'ONLINE SERVICES',
  'ONYX & ONYX SPECIALTIES (WHOLESALE)',
  'OPALS',
  'Open Source',
  'Open-End Investment Funds',
  'OPERA COMPANIES',
  'Operating Systems',
  'OPERATIONS RESEARCH',
  'OPERATIVE BUILDERS',
  'OPERATIVE BUILDERS',
  'OPERATORS OF APARTMENT BUILDINGS',
  'OPERATORS OF DWELLINGS OTHER THAN APTS',
  'OPERATORS OF NONRESIDENTIAL BUILDINGS',
  'OPERATORS-RESIDENTIAL MOBILE HOME SITES',
  'OPHTHALMIC GOODS',
  'OPHTHALMIC GOODS',
  'Ophthalmic Goods Manufacturing',
  'Ophthalmic Goods Merchant Wholesalers',
  'OPHTHALMIC GOODS-MANUFACTURERS',
  'OPHTHALMOLOGISTS',
  'OPTHALMIC INSTRUMENTS & EQUIP (WHOL)',
  'Optical Communication',
  'OPTICAL DISK EQUIPMENT & SUPPLIES (MFRS)',
  'OPTICAL ENGINEERS',
  'OPTICAL EQUIPMENT MACHINERY/SUPLS (WHOL)',
  'OPTICAL GOODS STORES',
  'Optical Goods Stores',
  'OPTICAL GOODS-MANUFACTURERS',
  'OPTICAL GOODS-RETAIL',
  'OPTICAL GOODS-SERVICE & REPAIR',
  'OPTICAL GOODS-WHOLESALE',
  'Optical Instrument and Lens Manufacturing',
  'OPTICAL INSTRUMENTS & LENSES (MFRS)',
  'OPTICAL INSTRUMENTS AND LENSES',
  'OPTICAL LABORATORIES-MANUFACTURERS',
  'OPTICAL LABORATORIES-RETAIL',
  'OPTICAL LABORATORIES-WHOLESALE',
  'OPTICAL SYSTEMS-MANUFACTURERS',
  'OPTICIANS',
  'OPTICIANS PRODUCTS-MANUFACTURERS',
  'OPTICIANS-WHOLESALE',
  'OPTOMETRISTS OD',
  'OPTOMETRISTS OD-CHILDRENS VISION',
  'OPTOMETRISTS OD-GERIATRIC OPTOMETRY',
  'OPTOMETRISTS OD-THERAPY VISUAL TRAINING',
  'OPTOMETRISTS REFERRAL & INFO SERVICE',
  'Orange Groves',
  'ORCHARD EQUIPMENT & SUPPLIES (WHOL)',
  'ORCHARD SERVICE',
  'ORCHARDS',
  'ORCHESTRAS & BANDS',
  'ORCHID GROWERS SUPPLIES (WHOLESALE)',
  'ORDNANCE & ACCESSORIES NEC (MFRS)',
  'ORDNANCE AND ACCESSORIES',
  'ORE',
  'ORE PROCESSING EQUIPMENT & SUPLS (WHOL)',
  'ORGAN & TISSUE BANKS',
  'Organic',
  'ORGANIC FIBERS',
  'Organic Food',
  'ORGANIC FOODS & SERVICES',
  'ORGANIZATION HOTELS ON MEMBERSHIP BASIS',
  'ORGANIZATIONS',
  'ORGANIZING SERVICES-HOUSEHOLD & BUSINESS',
  'ORGANS',
  'ORGANS-MANUFACTURERS',
  'ORGANS-SUPPLIES & PARTS',
  'ORGANS-TUNING & REPAIRING',
  'ORIENTAL FOOD PRODUCTS',
  'ORIENTAL GOODS',
  'ORIENTAL GOODS-MANUFACTURERS',
  'ORIENTAL GOODS-WHOLESALE',
  'ORIENTAL RUG CLEANERS',
  'ORIFICE FITTINGS (WHOLESALE)',
  'Ornamental and Architectural Metal Work Manufacturing',
  'ORNAMENTAL FLORICULTURE & NURSERY PRODS',
  'ORNAMENTAL METAL WORK (MANUFACTURERS)',
  'ORNAMENTAL NURSERY PRODUCTS',
  'ORNAMENTAL SHRUB & TREE SERVICES',
  'ORNAMENTAL SHRUB AND TREE SERVICES',
  'ORNAMENTS-MANUFACTURERS',
  'ORPHANAGES',
  'ORTHOPEDIC APPLIANCES',
  'ORTHOPEDIC APPLIANCES-MANUFACTURERS',
  'ORTHOPEDIC APPLIANCES-WHOLESALE',
  'ORTHOPEDIC MEDICINE-NON SURGICAL',
  'ORTHOPEDIC PROSTHETIC/SRGCL APPL (MFRS)',
  'ORTHOPTISTS',
  'ORTHOTICS',
  'OSTEOPOROSIS INFORMATION & SUPPORT',
  'OSTOMY EQUIPMENT & SUPPLIES (WHOLESALE)',
  'OSTRICH BREEDERS',
  'Other Accounting Services',
  'Other Activities Related to Credit Intermediation',
  'Other Activities Related to Real Estate',
  'Other Aircraft Parts and Auxiliary Equipment Manufacturing',
  'Other Airport Operations',
  'Other Aluminum Rolling, Drawing, and Extruding ',
  'Other Animal Food Manufacturing',
  'Other Apparel Knitting Mills',
  'Other Aquaculture',
  'Other Automotive Mechanical and Electrical Repair and Maintenance',
  'Other Basic Inorganic Chemical Manufacturing',
  'Other Building Equipment Contractors',
  'Other Building Finishing Contractors',
  'Other Building Material Dealers',
  'Other Business Service Centers (including Copy Shops)',
  'Other Chemical and Allied Products Merchant Wholesalers',
  'Other Chemical and Fertilizer Mineral Mining',
  'Other Clothing Stores',
  'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
  'Other Commercial and Service Industry Machinery Manufacturing',
  'Other Commercial Equipment Merchant Wholesalers',
  'Other Communication and Energy Wire Manufacturing',
  'Other Communications Equipment Manufacturing',
  'Other Community Housing Services',
  'Other Computer Related Services',
  'Other Concrete Product Manufacturing',
  'Other Construction Material Merchant Wholesalers',
  'Other Crushed and Broken Stone Mining and Quarrying',
  'Other Cut and Sew Apparel Manufacturing',
  'Other Depository Credit Intermediation',
  'Other Direct Insurance (except Life, Health, and Medical) Carriers ',
  'Other Direct Selling Establishments',
  'Other Electric Power Generation',
  'Other Electronic and Precision Equipment Repair and Maintenance',
  'Other Electronic Component Manufacturing',
  'Other Electronic Parts and Equipment Merchant Wholesalers',
  'Other Engine Equipment Manufacturing',
  'Other Fabricated Wire Product Manufacturing',
  'Other Farm Product Raw Material Merchant Wholesalers',
  'Other Financial Vehicles',
  'Other Food Crops Grown Under Cover',
  'Other Foundation, Structure, and Building Exterior Contractors ',
  'Other Gambling Industries',
  'Other Gasoline Stations',
  'Other General Government Support',
  'Other Grantmaking and Giving Services',
  'Other Grocery and Related Products Merchant Wholesalers',
  'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing',
  'Other Heavy and Civil Engineering Construction',
  'Other Individual and Family Services',
  'Other Industrial Machinery Manufacturing',
  'Other Insurance Funds',
  'Other Justice, Public Order, and Safety Activities ',
  'Other Lighting Equipment Manufacturing',
  'Other Management Consulting Services',
  'Other Marine Fishing',
  'Other Measuring and Controlling Device Manufacturing',
  'Other Metal Container Manufacturing',
  'Other Metal Valve and Pipe Fitting Manufacturing',
  'Other Millwork (including Flooring)',
  'Other Miscellaneous Durable Goods Merchant Wholesalers',
  'Other Miscellaneous Nondurable Goods Merchant Wholesalers',
  'Other Motion Picture and Video Industries',
  'Other Motor Vehicle Parts Manufacturing',
  'Other Noncitrus Fruit Farming',
  'Other Nonferrous Metal Foundries (except Die-Casting)',
  'Other Nonhazardous Waste Treatment and Disposal',
  'Other Nonscheduled Air Transportation',
  'Other Paperboard Container Manufacturing',
  'Other Performing Arts Companies',
  'Other Personal and Household Goods Repair and Maintenance',
  'Other Personal Care Services',
  'Other Poultry Production',
  'Other Pressed and Blown Glass and Glassware Manufacturing',
  'Other Professional Equipment and Supplies Merchant Wholesalers',
  'Other Residential Care Facilities',
  'Other Scientific and Technical Consulting Services',
  'Other Services Related to Advertising',
  'Other Services to Buildings and Dwellings',
  'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations) ',
  'Other Snack Food Manufacturing',
  'Other Social Advocacy Organizations',
  'Other Sound Recording Industries',
  'Other Specialized Design Services',
  'Other Spectator Sports',
  'Other Support Activities for Air Transportation',
  'Other Support Activities for Road Transportation',
  'Other Support Activities for Water Transportation',
  'Other Technical and Trade Schools',
  'Other Urban Transit Systems',
  'Other Vegetable (except Potato) and Melon Farming',
  'Other Warehousing and Storage',
  'Other Waste Collection',
  'OUTBOARD MOTORS',
  'OUTBOARD MOTORS-PARTS & REPAIR',
  'OUTBOARD MOTORS-RENTING',
  'OUTBOARD MOTORS-REPAIRING',
  'OUTBOARD MOTORS-USED',
  'OUTBOARD MOTORS-WHOLESALE',
  'Outdoor Advertising',
  'Outdoor Advertising',
  'OUTDOOR ADVERTISING SERVICES',
  'OUTDOOR EQUIPMENT ACCESSORIES',
  'OUTDOOR POWER EQUIPMENT',
  'Outdoor Power Equipment Stores',
  'Outdoors',
  'OUTFITTERS',
  'OUTING FACILITIES',
  'Outpatient Care',
  'Outpatient Mental Health and Substance Abuse Centers',
  'OUTPATIENT SERVICES',
  'OUTPLACEMENT CONSULTANTS',
  'Outsourcing',
  'Outsourcing/Offshoring',
  'OVEN CLEANING',
  'OVENS',
  'OVENS-BAKERS (MANUFACTURERS)',
  'OVENS-INDUSTRIAL (MANUFACTURERS)',
  'OVERALLS',
  'OVERALLS-MANUFACTURERS',
  'OVERALLS-WHOLESALE',
  'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing ',
  'OVERHEAD TRAVELING CRANES HOISTS (MFRS)',
  'OXYGEN (WHOLESALE)',
  'OXYGEN PRODUCING EQUIPMENT (WHOLESALE)',
  'OXYGEN THERAPY EQUIPMENT',
  'OXYGEN THERAPY EQUIPMENT-MANUFACTURERS',
  'OXYGEN-MEDICAL (WHOLESALE)',
  'OYSTER BARS',
  'OYSTER GROWERS',
  'OYSTERS',
  'OYSTERS-WHOLESALE',
  'OZONE MACHINES (MANUFACTURERS)',
  'PaaS',
  'PACEMAKER TESTING SERVICE',
  'PACEMAKERS-CARDIAC (MANUFACTURERS)',
  'PACKAGE DESIGNING & DEVELOPMENT',
  'Package/Freight Delivery',
  'Packaged Frozen Food Merchant Wholesalers',
  'PACKAGED FROZEN GOODS',
  'Packaging and Containers',
  'Packaging and Labeling Services',
  'PACKAGING CONTAINERS (MANUFACTURERS)',
  'PACKAGING MACHINERY',
  'Packaging Machinery Manufacturing',
  'PACKAGING MACHINERY-MANUFACTURERS',
  'PACKAGING MACHINERY-WHOLESALE',
  'PACKAGING MATERIALS-MANUFACTURERS',
  'PACKAGING MATERIALS-WHOLESALE',
  'PACKAGING SERVICE',
  'Packaging Services',
  'PACKING & CRATING MATERIALS (WHOLESALE)',
  'PACKING & CRATING SERVICE',
  'PACKING AND CRATING',
  'Packing and Crating',
  'PACKING HOUSE BY-PRODUCTS (WHOLESALE)',
  'PACKING MATERIALS-MECHANICAL (WHOLESALE)',
  'PACKING MATERIALS-SHIPPING (WHOLESALE)',
  'PACKINGS-MECHANICAL (WHOLESALE)',
  'PADDLE TENNIS COURT CONSTRUCTION',
  'PAGEANT MANAGERS & PRODUCERS',
  'PAGEANTS-PRODUCTION TRAINING & SUPPLIES',
  'PAGERS-SALES & SERVICE',
  'PAGING & ANSWERING SERVICE',
  'PAGING & SIGNALING EQUIP/SYSTEMS RPR',
  'PAGING & SIGNALING EQUIPMENT & SYSTEMS',
  'PAGING & SIGNALING SERVICE-PRIVATE SYSTS',
  'PAGING & SIGNALING SVC-COMMON CARRIER',
  'PAGING EQUIPMENT-SALES & SERVICE',
  'PAILS (WHOLESALE)',
  'PAILS-MANUFACTURERS',
  'PAIN CONTROL',
  'PAINT',
  'PAINT & COATINGS-MARINE (WHOLESALE)',
  'PAINT & VARNISH REMOVERS-WHOLESALE',
  'Paint and Coating Manufacturing',
  'Paint and Wallpaper Stores',
  'PAINT BALL GAMES EQUIP & SUPLS (WHOL)',
  'PAINT BALL SPORTS',
  'PAINT REMOVERS-MANUFACTURERS',
  'PAINT REMOVING',
  'PAINT ROLLERS-MANUFACTURERS',
  'PAINT VARNISH & ALLIED PRODUCTS (MFRS)',
  'PAINT-INDUSTRIAL (WHOLESALE)',
  'PAINT-MANUFACTURERS',
  'PAINT-MANUFACTURING EQUIPMENT (WHOL)',
  'PAINT-MANUFACTURING MATERIALS (WHOL)',
  'PAINT-MANUFACTURING MATERIALS-MFRS',
  'PAINT-RETAIL',
  'PAINT-WHOLESALE',
  'Paint, Varnish, and Supplies Merchant Wholesalers ',
  'PAINTERS',
  'PAINTERS EQUIPMENT & SUPPLIES (WHOL)',
  'PAINTERS EQUIPMENT & SUPPLIES-MFRS',
  'PAINTERS EQUIPMENT & SUPPLIES-RENTING',
  'PAINTING AND PAPER HANGING',
  'Painting and Wall Covering Contractors',
  'PAINTING CONTRACTORS-COMMERCIAL & IND',
  'PAINTING-ELECTROSTATIC',
  'PAINTING-PRODUCTION',
  'PAINTS',
  'PAINTS AND ALLIED PRODUCTS',
  'PAJAMAS (MANUFACTURERS)',
  'PAJAMAS (WHOLESALE)',
  'PALEONTOLOGISTS',
  'PALLETS & SKIDS (WHOLESALE)',
  'PALLETS & SKIDS-MANUFACTURERS',
  'PALMISTS',
  'PANELBOARDS-ELECTRIC (MANUFACTURERS)',
  'PANELING',
  'PANELING-DISTRIBUTORS',
  'PANELING-MANUFACTURERS',
  'PANELS',
  'PANELS-INSTRUMENT (WHOLESALE)',
  'PANTS-MANUFACTURERS',
  'PANTS-RETAIL',
  'PANTS-WHOLESALE',
  'PAPAYA PRODUCTS',
  'Paper (except Newsprint) Mills',
  'Paper & Forest Products',
  'PAPER & PLASTIC CUPS CONTAINERS ETC-MFRS',
  'Paper Bag and Coated and Treated Paper Manufacturing',
  'PAPER BROKERS & MILL REPRESENTATIVES',
  'PAPER CLIPS (MANUFACTURERS)',
  'PAPER COATED AND LAMINATED',
  'PAPER COATED AND LAMINATED PACKAGING',
  'PAPER COATING (MANUFACTURERS)',
  'PAPER CONVERTERS (MANUFACTURERS)',
  'PAPER CONVERTING MACHINERY (WHOLESALE)',
  'PAPER CUTTING',
  'PAPER CUTTING & DRILLING MACHINES (WHOL)',
  'PAPER CUTTING-KNIVES (MANUFACTURERS)',
  'PAPER HANGERS EQUIPMENT & SUPPLIES',
  'PAPER INDUSTRIES MACHINERY',
  'Paper Manufacturing',
  'PAPER MILL MACHINERY-MANUFACTURERS',
  'PAPER MILL SUPPLIES (WHOLESALE)',
  'PAPER MILL SUPPLIES-MANUFACTURERS',
  'PAPER MILLS',
  'PAPER PRODUCTS (WHOLESALE)',
  'PAPER PRODUCTS-RETAIL',
  'PAPER RULING (MANUFACTURERS)',
  'PAPER SHREDDING MACHINES (WHOLESALE)',
  'PAPER TOWELS (WHOLESALE)',
  'PAPER TOWELS-MFRS',
  'PAPER TUBES & CORES (WHOLESALE)',
  'PAPER TUBES & CORES-MANUFACTURERS',
  'PAPER-ADHESIVE/PRESSURE SENSITIVE (MFRS)',
  'PAPER-BOX BOARD (MANUFACTURERS)',
  'PAPER-CORRUGATED (MANUFACTURERS)',
  'PAPER-MANUFACTURERS',
  'PAPER-SHREDDED',
  'PAPER-TOILET (WHOLESALE)',
  'PAPER-WAXED (MANUFACTURERS)',
  'PAPER/PLASTIC CUPS CNTNRS/UTNSLS (WHOL)',
  'PAPERBOARD MILLS',
  'Paperboard Mills',
  'PAPERBOARD-SOLID FIBER (WHOLESALE)',
  'PAPERWEIGHTS',
  'PARACHUTES',
  'PARACHUTES-MANUFACTURERS',
  'PARALEGALS',
  'PARAMEDICAL SERVICES',
  'PARAMEDICS',
  'PARAPSYCHOLOGISTS',
  'PARASAILING',
  'PARCEL DELIVERY',
  'PARCEL POST ASSEMBLY SERVICE',
  'PARCELS FOR FOREIGN COUNTRIES',
  'Parenting',
  'PARK & RECREATION CONSULTANTS',
  'PARK PLANNING',
  'Parking',
  'PARKING AREA/LOTS MAINTENANCE & MARKING',
  'PARKING ATTENDANT SERVICE',
  'PARKING CONSULTANTS',
  'Parking Lots and Garages',
  'PARKING METERS (WHOLESALE)',
  'PARKING STA/GARAGES EQUIP/SUPLS (WHOL)',
  'PARKING STATIONS & GARAGES',
  'PARKING STATIONS & GARAGES CONSTRUCTION',
  'PARKING STATIONS & GARAGES EQUIP-MFRS',
  'PARKING TICKETS CONTESTING SERVICE',
  'PARKS',
  'Parks',
  'PARLIAMENTARIANS',
  'Parole Offices and Probation Offices',
  'PARSONAGES',
  'PARTICLE BOARDS (MANUFACTURERS)',
  'PARTIES',
  'PARTIES-CHILDRENS',
  'PARTITIONS (WHOLESALE)',
  'PARTITIONS AND FIXTURES',
  'PARTITIONS-MANUFACTURERS',
  'PARTITIONS-METAL (MANUFACTURERS)',
  'PARTITIONS-RETAIL',
  'PARTITIONS-WIRE (MANUFACTURERS)',
  'PARTY CENTERS',
  'PARTY PLANNING SERVICE',
  'PARTY SUPPLIES',
  'PARTY SUPPLIES-MANUFACTURERS',
  'PARTY SUPPLIES-RENTING',
  'PARTY SUPPLIES-WHOLESALE',
  'PASSENGER CAR LEASING',
  'PASSENGER CAR LEASING',
  'Passenger Car Leasing',
  'PASSENGER CAR RENTAL',
  'Passenger Car Rental',
  'PASSENGER TRANSPORTATION ARRANGEMENT',
  'PASTA (WHOLESALE)',
  'PASTA MACHINERY (MANUFACTURERS)',
  'PATCHES (MANUFACTURERS)',
  'PATCHING & PATCHING MATERIALS (MFRS)',
  'PATENT AGENTS',
  'PATENT DEVELOPMENT & MARKETING',
  'PATENT OWNERS & LESSORS',
  'PATENT OWNERS AND LESSORS',
  'PATENT SEARCHERS',
  'PATERNITY TESTING',
  'PATHOLOGISTS',
  'PATIO & DECK BUILDERS',
  'PATIO & DECK CLEANING & RESTORATION',
  'PATIO EQUIPMENT & SUPPLIES',
  'PATIO PORCH & DECK ENCLOSURES',
  'PATTERN MAKERS',
  'PATTERN MAKERS-FOUNDRIES MODEL MKRS ETC',
  'PATTERNS-CLOTHING DRESS & ETC (MFRS)',
  'PAVEMENT MARKING',
  'PAVEMENT SEALING',
  'PAVEMENT/FLR MARKING SUPLS/EQUIP (WHOL)',
  'PAVING CONTRACTORS',
  'PAVING EQUIPMENT (WHOLESALE)',
  'PAVING EQUIPMENT-MANUFACTURERS',
  'PAVING MATERIALS',
  'PAVING MATERIALS-MANUFACTURERS',
  'PAWN TICKETS-BOUGHT',
  'PAWNBROKERS',
  'PAY TELEPHONES & BOOTHS EQUIPMENT & SVC',
  'Payments',
  'PAYROLL AUDITORS',
  'PAYROLL DISTRIBUTION SERVICE',
  'PAYROLL PREPARATION SERVICE',
  'Payroll Services',
  'PC Games',
  'PEA PROCESSORS',
  'PEACE ORGANIZATIONS',
  'Peanut Farming',
  'PEANUT PRODUCTS (WHOLESALE)',
  'PEANUT SHELLERS',
  'PEANUTS (WHOLESALE)',
  'PEARL ESSENCE (MANUFACTURERS)',
  'PEARL GOODS',
  'PEARLS',
  'PEARLS-WHOLESALE',
  'PEAT MOSS',
  'PECANS (WHOLESALE)',
  'PEDICURISTS',
  'Peer to Peer',
  'PENCIL SHARPENERS (MANUFACTURERS)',
  'Penetration Testing',
  'PENNANTS (WHOLESALE)',
  'PENS & PENCILS-MANUFACTURERS',
  'PENS & PENCILS-SALES & REPAIRING',
  'PENS & PENCILS-SUPPLIES & PARTS (WHOL)',
  'PENS & PENCILS-WHOLESALE',
  'PENS AND MECHANICAL PENCILS',
  'PENS-BRUSH (MANUFACTURERS)',
  'PENS-MECHANICAL PENCILS & PARTS (MFRS)',
  'PENSION',
  'PENSION & PROFIT SHARING PLANS',
  'PENSION CLAIM AGENTS',
  'Pension Funds',
  'PENSION HEALTH & WELFARE FUNDS',
  'PERFORATED METALS PLASTICS ETC (MFRS)',
  'PERFORATING MACHINES (MANUFACTURERS)',
  'PERFORATING OF FABRICS',
  'Performing Arts',
  'PERFUME-MANUFACTURERS',
  'PERFUME-RETAIL',
  'PERFUME-WHOLESALE',
  'PERFUMERS-RAW MATERIALS & SUPLS (WHOL)',
  'PERFUMES COSMETICS/TOILET PREPS (MFRS)',
  'PERFUMING-FABRIC PAPER ETC',
  'Periodical Publishers',
  'PERIODICALS',
  'PERIODICALS-PUBLISHING & PRINTING',
  'Perishable Prepared Food Manufacturing',
  'PERLITE & PERLITE PRODUCTS (MFRS)',
  'PERMACULTURE',
  'PERMANENT MAKE-UP',
  'PERMIT SERVICE-TRANSPORTATION',
  'Personal Branding',
  'PERSONAL CARE HOMES',
  'PERSONAL CREDIT INSTITUTIONS',
  'Personal Development',
  'PERSONAL DEVELOPMENT SCHOOLS',
  'Personal Finance',
  'Personal Health',
  'PERSONAL LEATHER GOODS',
  'PERSONAL ORGANIZERS & PLANNERS',
  'PERSONAL SERVICE BUREAUS',
  'PERSONAL TRAINERS-FITNESS',
  'PERSONALITY DEVELOPMENT',
  'Personalization',
  'PERSONNEL CONSULTANTS',
  'PERSONNEL TRAINING SERVICE',
  'PEST CONTROL',
  'PEST CONTROL SUPPLIES & EQUIP-MFRS',
  'PEST CONTROL SUPPLIES & EQUIPMENT (WHOL)',
  'Pesticide and Other Agricultural Chemical Manufacturing',
  'PESTICIDES & AG CHEMICALS NEC (MFRS)',
  'Pet',
  'PET ADOPTION',
  'Pet and Pet Supplies Stores',
  'PET BOARDING',
  'PET BREEDERS',
  'Pet Care (except Veterinary) Services',
  'PET CEMETERIES & CREMATORIES',
  'PET CEMETERY EQUIPMENT & SUPLS (WHOL)',
  'PET EXERCISING SERVICE',
  'PET FINDING SERVICE',
  'PET FOODS-WHOLESALE',
  'PET FUNERAL SERVICES',
  'PET GROOMING INSTRUCTIONS',
  'PET HEALTH PLANS',
  'PET IDENTIFICATION SERVICE',
  'PET LOSS COUNSELING SERVICES',
  'PET MATCHING & BROKERAGE SVC',
  'PET SERVICES',
  'PET SHOPS',
  'PET SHOPS SUPPLIES (WHOLESALE)',
  'PET SHOPS-WHOLESALE',
  'PET SUPPLIES & FOODS-RETAIL',
  'PET SUPPLIES-WHOLESALE',
  'PET THERAPY',
  'PET TRAINING',
  'PET TRANSPORTING',
  'PET WASHING & GROOMING',
  'PET WASTE REMOVAL',
  'Petrochemical Manufacturing',
  'PETROLATUM (WHOLESALE)',
  'PETROLEUM AND COAL PRODUCTS',
  'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)',
  'PETROLEUM BULK STATIONS & TERMINALS',
  'PETROLEUM BULK STATIONS AND TERMINALS',
  'Petroleum Bulk Stations and Terminals',
  'PETROLEUM CONSULTANTS',
  'PETROLEUM EQUIPMENT (MANUFACTURERS)',
  'Petroleum Lubricating Oil and Grease Manufacturing',
  'PETROLEUM PRODUCTS',
  'PETROLEUM PRODUCTS (WHOLESALE)',
  'PETROLEUM PRODUCTS-MANUFACTURERS',
  'PETROLEUM PRODUCTS-TRANSPORTING',
  'Petroleum Refineries',
  'PETROLEUM REFINING',
  'PETS-LOST & FOUND',
  'PETTING ZOOS',
  'PEWTER',
  'PEWTERWARE (MANUFACTURERS)',
  'Pharmaceutical',
  'PHARMACEUTICAL CNTNRS EQUIP/SUPLS (MFRS)',
  'PHARMACEUTICAL CONSULTANTS',
  'PHARMACEUTICAL INFORMATION',
  'PHARMACEUTICAL MACHINERY (MANUFACTURERS)',
  'PHARMACEUTICAL PREPARATION',
  'Pharmaceutical Preparation Manufacturing',
  'PHARMACEUTICAL PREPARATIONS',
  'PHARMACEUTICAL PRODUCTS-WHOLESALE',
  'PHARMACEUTICAL RESEARCH LABORATORIES',
  'Pharmaceuticals',
  'PHARMACIES',
  'Pharmacies and Drug Stores',
  'PHARMACIST EMPLOYMENT REGISTER',
  'PHARMACOLOGISTS',
  'Philanthropy',
  'PHILOSOPHERS',
  'PHONOGRAPH RECORD/PRERECORDED TAPE (MFR)',
  'PHONOGRAPH SUPPLIES-WHOLESALE',
  'PHONOGRAPHS-COIN OPERATED',
  'PHONOGRAPHS-MANUFACTURERS',
  'PHOSPHATE ROCK',
  'PHOSPHATE ROCK',
  'Phosphate Rock Mining',
  'PHOSPHATES-WHOLESALE',
  'Phosphatic Fertilizer Manufacturing',
  'PHOSPHATIC FERTILIZERS',
  'PHOSPHATIC FERTILIZERS (MANUFACTURERS)',
  'PHOTO ALBUMS & FRAMES',
  'PHOTO COLORING',
  'PHOTO DESIGN PRODUCTION SERVICE',
  'Photo Editing',
  'PHOTO ELECTRIC CELLS & EQUIPMENT (WHOL)',
  'PHOTO FINISHING-CUSTOM',
  'PHOTO FINISHING-RETAIL',
  'PHOTO FINISHING-WHOLESALE',
  'PHOTO LABORATORIES-COMMERCIAL',
  'Photo Sharing',
  'PHOTO-MODELING STUDIOS',
  'PHOTO-RETOUCHING',
  'PHOTO-SCULPTURES',
  'PHOTOCOMPOSITION',
  'PHOTOCOPYING AND DUPLICATING SERVICES',
  'PHOTOFINISH LABORATORIES',
  'Photofinishing Laboratories (except One-Hour)',
  'PHOTOGELATIN REPRODUCTION',
  'PHOTOGRAMMETRIC EQUIP & SUPLS-RETAIL',
  'PHOTOGRAMMETRIC INSTRUMENTS (MFRS)',
  'PHOTOGRAPHERS-AERIAL',
  'PHOTOGRAPHERS-ANIMAL',
  'PHOTOGRAPHERS-ARCHITECTURAL',
  'PHOTOGRAPHERS-COMMERCIAL',
  'PHOTOGRAPHERS-COPY & RESTORATION',
  'PHOTOGRAPHERS-DIGITAL IMAGING',
  'PHOTOGRAPHERS-EVENTS',
  'PHOTOGRAPHERS-FINE ART',
  'PHOTOGRAPHERS-LEGAL',
  'PHOTOGRAPHERS-MARINE',
  'PHOTOGRAPHERS-MEDICAL & DENTAL',
  'PHOTOGRAPHERS-OIL FIELDS',
  'PHOTOGRAPHERS-PASSPORT',
  'PHOTOGRAPHERS-PORTRAIT',
  'PHOTOGRAPHERS-SCIENTIFIC',
  'PHOTOGRAPHERS-SPECIALTY',
  'PHOTOGRAPHERS-SPORTS',
  'PHOTOGRAPHERS-STOCK',
  'PHOTOGRAPHERS-STUDIO RENTAL',
  'PHOTOGRAPHERS-UNDERWATER',
  'PHOTOGRAPHERS-VIDEO',
  'PHOTOGRAPHIC & VIDEO-IMAGING & ANALYSIS',
  'PHOTOGRAPHIC AGENCIES',
  'Photographic and Photocopying Equipment Manufacturing',
  'PHOTOGRAPHIC COLOR PRINTS/TRNSPRNCY-WHOL',
  'PHOTOGRAPHIC COLOR PROCESSING',
  'PHOTOGRAPHIC CONSULTANTS',
  'PHOTOGRAPHIC DARKROOM-RENTAL',
  'PHOTOGRAPHIC ENGINEERING',
  'PHOTOGRAPHIC ENLARGEMENTS',
  'PHOTOGRAPHIC EQUIP & SUPPLIES-MFRS',
  'PHOTOGRAPHIC EQUIP & SUPPLIES-RETAIL',
  'PHOTOGRAPHIC EQUIP & SUPPLIES-USED',
  'PHOTOGRAPHIC EQUIP & SUPPLIES-WHOLESALE',
  'PHOTOGRAPHIC EQUIPMENT AND SUPPLIES',
  'PHOTOGRAPHIC EQUIPMENT AND SUPPLIES',
  'Photographic Equipment and Supplies Merchant Wholesalers',
  'PHOTOGRAPHIC EQUIPMENT-MANUFACTURERS',
  'PHOTOGRAPHIC EQUIPMENT-RENTAL',
  'PHOTOGRAPHIC EQUIPMENT-REPAIRING',
  'Photographic Film, Paper, Plate, and Chemical Manufacturing ',
  'PHOTOGRAPHIC MOUNTS',
  'PHOTOGRAPHIC MOUNTS-WHOLESALE & MFRS',
  'PHOTOGRAPHIC RESTORATIONS',
  'PHOTOGRAPHIC SETS',
  'PHOTOGRAPHIC STUDIOS',
  'PHOTOGRAPHS-STOCK',
  'PHOTOGRAPHY',
  'Photography',
  'PHOTOGRAPHY SCHOOLS',
  'Photography Studios, Portrait ',
  'PHYSICAL CULTURE INSTITUTES',
  'PHYSICAL FITNESS FACILITIES',
  'Physical Security',
  'PHYSICAL THERAPISTS',
  "PHYSICAL THERAPISTS' REFERRAL & INFO SVC",
  'PHYSICAL THERAPY EQUIPMENT (WHOLESALE)',
  'PHYSICAL THERAPY EQUIPMENT-MANUFACTURERS',
  'PHYSICAL THERAPY SCHOOLS',
  'PHYSICIAN & SURGEON RECRUITMENT',
  'PHYSICIANS & SURGEONS',
  'PHYSICIANS & SURGEONS EQUIP & SUPLS-MFRS',
  'PHYSICIANS & SURGEONS EQUIP & SUPLS-WHOL',
  'PHYSICIANS & SURGEONS INFORMATION BUREAU',
  'PHYSICIANS & SURGEONS-EMERGENCY SERVICE',
  'PHYSICIANS ASSISTANTS',
  'PHYSICIANS-NATUROPATHIC',
  'PHYSICIANS-ORIENTAL MEDICINE',
  'PHYSICIANS/SURGEONS EQUIP-SPEC DESIGNED',
  'PHYSICIANS/SURGEONS-PODIATRIST REFERENCE',
  'PHYSICISTS',
  'PHYSIOTHERAPISTS',
  'PIANO & ORGAN MOVING',
  'PIANO ROLLS (WHOLESALE)',
  'PIANO SUPPLIES & PARTS (WHOLESALE)',
  'PIANO-INSTRUCTIONS',
  'PIANOS',
  'PIANOS-MANUFACTURERS',
  'PIANOS-RENTING',
  'PIANOS-TUNING REPAIRING & REFINISHING',
  'PIANOS-USED',
  'PIANOS-WHOLESALE',
  'PICKLED FRUITS/VEGS & DRESSINGS (MFRS)',
  'PICKLES',
  'PICKLES & PICKLE PRODUCTS (MFRS)',
  'PICKLES & PICKLE PRODUCTS-WHOLESALE',
  'PICNIC EQUIPMENT & SUPPLIES',
  'PICNIC GROUNDS',
  'PICTURE FRAMES-DEALERS',
  'PICTURE FRAMES-MANUFACTURERS',
  'PICTURE FRAMES-MANUFACTURERS SUPLS-MFRS',
  'PICTURE FRAMES-MFRS SUPPLIES (WHOL)',
  'PICTURE FRAMES-RESTORING & REPAIRING',
  'PICTURE FRAMES-WHOLESALE',
  'PICTURE FRAMING',
  'PICTURE HANGERS-MANUFACTURERS',
  'PICTURE HANGERS-WHOLESALE',
  'PICTURE HANGING SERVICE',
  'PICTURE LIBRARIES',
  'PICTURE RESTORING',
  'PICTURES',
  'PICTURES-MANUFACTURERS',
  'PICTURES-WHOLESALE',
  'PIECE GOODS AND NOTIONS',
  'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers ',
  'PIEROGIES (MANUFACTURERS)',
  'PIES',
  'PIES-WHOLESALE',
  'PIGEON CONTROL SVC',
  'PILE DRIVING',
  'PILE DRIVING EQUIPMENT (MANUFACTURERS)',
  'PILE FABRICS (MANUFACTURERS)',
  'PILING (WHOLESALE)',
  "PILING CONTRACTORS' EQUIPMENT (MFRS)",
  'PILLOW CASES (MANUFACTURERS)',
  'PILLOWS',
  'PILLOWS-MANUFACTURERS',
  'PILLOWS-RENOVATING',
  'PILLOWS-WHOLESALE',
  'PILOT CAR SERVICE',
  'PILOTS EQUIPMENT & SUPPLIES (MFRS)',
  'PILOTS-MARINE',
  'PINATAS',
  'PINBALL MACHINES',
  'PINS (BROACHES)(MANUFACTURERS)',
  'PINS-CLUB SCHOOL ETC',
  'PINS-COMMON SAFETY ETC (MANUFACTURERS)',
  'PINS-COTTER TAPER ETC (MANUFACTURERS)',
  'PINS-MANUFACTURERS',
  'PIPE & BOILER COVERING CONTRACTORS',
  'PIPE & BOILER COVERING MATERIALS (WHOL)',
  'PIPE & LEAK LOCATING',
  'PIPE BENDING & FABRICATING',
  'PIPE CLEANING',
  'PIPE COATING MATERIALS (MANUFACTURERS)',
  'PIPE COILS (MANUFACTURERS)',
  'PIPE CUTTING & THREADING',
  'PIPE FITTINGS (WHOLESALE)',
  'PIPE FITTINGS-MANUFACTURERS',
  'PIPE HANGERS (MANUFACTURERS)',
  'PIPE INSPECTION',
  'PIPE LINE COMPANIES',
  'PIPE LINE CONTRACTORS',
  'PIPE LINE EQUIPMENT',
  'PIPE LINING & COATING (MANUFACTURERS)',
  'PIPE LOCATING EQUIPMENT (WHOLESALE)',
  'PIPE PUSHING',
  'PIPE PUSHING EQUIPMENT (MANUFACTURERS)',
  'PIPE THAWING',
  'PIPE-JOINT COMPOUNDS (MANUFACTURERS)',
  'PIPE-MANUFACTURERS',
  'PIPE-PLASTIC (MANUFACTURERS)',
  'PIPE-PLASTIC-WHOLESALE',
  'PIPE-RECONDITIONING',
  'PIPE-RENTING',
  'PIPE-SEALING SERVICE-INDUSTRIAL',
  'PIPE-STAINLESS (MANUFACTURERS)',
  'PIPE-THREAD PROTECTORS (MANUFACTURERS)',
  'PIPE-USED (WHOLESALE)',
  'PIPE-WHOLESALE',
  'Pipeline Transportation of Crude Oil',
  'Pipeline Transportation of Natural Gas',
  'Pipeline Transportation of Refined Petroleum Products',
  'PIPELINES',
  'PIPELINES NEC',
  "PIPES & SMOKERS' ARTICLES",
  'PIPES-SMOKING-MFRS EQUIP & SUPLS (MFRS)',
  'PIPING CONTRACTORS',
  'PIPING SYSTS-PREFAB-INSULATED (MFRS)',
  'PIPING-PROCESS & INDUSTRIAL',
  'PISTACHIOS',
  'PISTON RINGS (WHOLESALE)',
  'PISTONS (WHOLESALE)',
  'PIVOTS-PRECISION INSTRUMENT (WHOLESALE)',
  'PIZZA',
  'PIZZA PRODUCTS-MANUFACTURERS',
  'PIZZA SUPPLIES (WHOLESALE)',
  'PIZZA-WHOLESALE',
  'PLAN BUREAUS',
  'PLANETARIUMS',
  'PLANNERS-PROFESSIONAL',
  'PLANTATIONS',
  'PLANTER BOXES',
  'PLANTERS',
  'PLANTERS-DECORATIVE',
  'PLANTS-HORTICULTURAL',
  'PLANTS-INTERIOR DESIGN & MAINTENANCE',
  'PLANTS-LIVING-RENTING & LEASING',
  'PLANTS-RETAIL',
  'PLANTS-WHOLESALE',
  'PLAQUES',
  'PLAQUES-MANUFACTURERS',
  'PLAQUES-WHOLESALE',
  'PLASMA COLLECTORS',
  'PLASTER CRAFT PRODUCTS & SUPPLIES',
  'PLASTER PRODUCTS (MANUFACTURERS)',
  'PLASTER PRODUCTS-ORNAMENTAL (WHOLESALE)',
  "PLASTERERS' EQUIPMENT & SUPPLIES-MFRS",
  'PLASTERING',
  'PLASTERING CONTRACTORS',
  "PLASTERING CONTRS' EQUIP/SUPLS (WHOL)",
  'PLASTERING-ORNAMENTAL',
  'PLASTIC BOTTLES (MANUFACTURERS)',
  'PLASTIC CARDS (MANUFACTURERS)',
  'PLASTIC CONTAINERS (WHOLESALE)',
  'PLASTIC MOLDERS-WHOLESALE',
  'PLASTIC PRODUCTS-ORNAMENTAL',
  'PLASTIC RECYCLING (WHOLESALE)',
  'PLASTIC WELDING',
  'PLASTICIZERS (MANUFACTURERS)',
  'Plastics',
  'PLASTICS & PLASTIC PRODUCTS (MFRS)',
  'Plastics and Rubber Manufacturing',
  'Plastics Bag and Pouch Manufacturing',
  'Plastics Bottle Manufacturing',
  'PLASTICS BOTTLES',
  'PLASTICS FOAM PRODUCTS',
  'Plastics Material and Resin Manufacturing',
  'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers',
  'PLASTICS MATERIALS AND BASIC SHAPES',
  'PLASTICS MATERIALS AND RESINS',
  'Plastics Packaging Film and Sheet (including Laminated) Manufacturing',
  'PLASTICS PIPE',
  'Plastics Pipe and Pipe Fitting Manufacturing',
  'Plastics Plumbing Fixture Manufacturing',
  'PLASTICS PLUMBING FIXTURES',
  'PLASTICS PRODUCTS',
  'PLASTICS-BLOW MOLDING (MANUFACTURERS)',
  'PLASTICS-COATING (MANUFACTURERS)',
  'PLASTICS-EXTRUDERS (MANUFACTURERS)',
  'PLASTICS-FABRCTNG,FNSHNG,DECOR-WHOLESALE ',
  'PLASTICS-FABRICATING/FINISH/DECOR-MFRS',
  'PLASTICS-FABRICS,FILM-ETC PRODUCER (MFR) ',
  'PLASTICS-FOAM (MANUFACTURERS)',
  'PLASTICS-FOAM-WHOLESALE',
  'PLASTICS-FOIL & COATED PAPER BAGS (MFRS)',
  'PLASTICS-HIGH PRESSURE LAMINATES (MFRS)',
  'PLASTICS-HIGH PRESSURE LAMINATES-WHOL',
  'PLASTICS-MACHINERY & EQUIPMENT (WHOL)',
  'PLASTICS-MACHINERY & EQUIPMENT-MFRS',
  'PLASTICS-MANUFACTURERS',
  'PLASTICS-PIPE (MANUFACTURERS)',
  'PLASTICS-PLUMBING FIXTURES (MFRS)',
  'PLASTICS-PRODUCTS NEC (MANUFACTURERS)',
  'PLASTICS-PRODUCTS-FINISHED-MANUFACTURERS',
  'PLASTICS-PRODUCTS-FINISHED-WHOLESALE',
  'PLASTICS-PRODUCTS-RETAIL',
  'PLASTICS-PROTOTYPES (MANUFACTURERS)',
  'PLASTICS-RAW MTRLS-POWDERS LIQUID-WHOL',
  'PLASTICS-RAW MTRLS/POWDER/RESIN-MFRS',
  'PLASTICS-REINFORCED (WHOLESALE)',
  'PLASTICS-RESEARCH & CONSULTING',
  'PLASTICS-ROD,TUBE,SHEETS,ETC-SUPL (MFR) ',
  'PLASTICS-RODS, TUBES, SHEETS ETC (WHOL) ',
  'PLASTICS-SCRAP (WHOLESALE)',
  'PLASTICS-TOOLING (MANUFACTURERS)',
  'PLASTICS-VACUUM/PRESSURE FORMING (MFRS)',
  'Plate Work Manufacturing',
  'PLATEMAKING & RELATED SERVICES',
  'PLATEMAKING SERVICES',
  'Platforms',
  'PLATFORMS-PORTABLE',
  'PLATING (MANUFACTURERS)',
  'PLATING AND POLISHING',
  'PLATING CONSULTANTS',
  'PLATING EQUIPMENT & SUPPLIES (WHOLESALE)',
  'PLATING EQUIPMENT & SUPPLIES-MFRS',
  'PLATING-GOLD & SILVER (MANUFACTURERS)',
  'PLAYGROUND EQUIPMENT',
  'PLAYGROUND EQUIPMENT-MANUFACTURERS',
  'PLAYGROUND EQUIPMENT-WHOLESALE',
  'PLAYGROUNDS',
  'PLAYHOUSES-CHILDREN',
  'PLAYING CARDS (MANUFACTURERS)',
  'PLAYING CARDS-WHOLESALE',
  'PLAYS & PLAY BROKERS',
  'Playstation',
  'PLEATING & HEMSTITCHING (MFRS)',
  'PLEATING AND STITCHING',
  'PLEATING MACHINES (MANUFACTURERS)',
  'PLEATING/STITCHING/TUCKING-TRADE (MFRS)',
  'PLOTTERS (MANUFACTURERS)',
  'PLOTTERS EQUIPMENT & SUPPLIES',
  'PLOTTING SERVICE',
  'PLUGS (WHOLESALE)',
  'PLUMBER REFERRAL SERVICE',
  'PLUMBING',
  'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers',
  'PLUMBING AND HYDRONIC HEATING SUPPLIES',
  'PLUMBING CONTRACTORS',
  'PLUMBING DRAIN & SEWER CLEANING',
  'PLUMBING DRAINS & SEWER CONSULTANTS',
  'PLUMBING DRAINS/SEWER CLEANING EQPT-RNTL',
  'Plumbing Fixture Fitting and Trim Manufacturing',
  'PLUMBING FIXTURE FITTINGS & TRIM (MFRS)',
  'PLUMBING FIXTURE FITTINGS AND TRIM',
  'PLUMBING FIXTURES & SUPPLIES-MFRS',
  'PLUMBING FIXTURES & SUPPLIES-NEW-RETAIL',
  'PLUMBING FIXTURES & SUPPLIES-USED',
  'PLUMBING FIXTURES & SUPPLIES-WHOLESALE',
  'PLUMBING HEATING & AIR CONDITIONING',
  'PLUMBING SPECIALTIES',
  'PLUMBING SUPPLIES-MANUFACTURERS',
  'Plumbing, Heating, and Air-Conditioning Contractors ',
  'PLUSHES (MANUFACTURERS)',
  'PLYWOOD & VENEER MACHINERY (MFRS)',
  'PLYWOOD & VENEERS',
  'PLYWOOD & VENEERS-MANUFACTURERS',
  'PLYWOOD & VENEERS-WHOLESALE',
  'PNEUMATIC EQUIPMENT COMPONENTS (WHOL)',
  'PNEUMATIC EQUIPMENT COMPONENTS-MFRS',
  'PNEUMATIC TUBE EQUIPMENT & SYSTEMS',
  'PNEUMATIC TUBE EQUIPMENT/SYSTEMS-REPAIR',
  'Podcast',
  'PODIATRISTS',
  'PODIATRISTS EQUIPMENT & SUPPLIES (WHOL)',
  'PODIATRISTS INFORMATION BUREAUS',
  'POETRY',
  'POI-MANUFACTURERS',
  'POINT OF SALE',
  'Point of Sale',
  'POINT OF SALES SYSTEMS (MANUFACTURERS)',
  'POINTING-BRICK, STONE, ETC ',
  'POISON CONTROL CENTERS',
  'POLE & PILING-MANUFACTURERS',
  'POLE LINE CONTRACTORS',
  'POLE LINE EQUIPMENT (WHOLESALE)',
  'POLES (WHOLESALE)',
  'POLICE DEPARTMENTS',
  'POLICE EQUIPMENT',
  'POLICE EQUIPMENT-MANUFACTURERS',
  'POLICE EQUIPMENT-WHOLESALE',
  'POLICE PROTECTION',
  'Police Protection',
  'POLICE SERVICE-PRIVATE',
  'Polish and Other Sanitation Good Manufacturing',
  'POLISHES (MANUFACTURERS)',
  'POLISHES AND SANITATION GOODS',
  'POLISHES-WHOLESALE',
  'POLITICAL CAMPAIGN SERVICE',
  'POLITICAL CONSULTANTS',
  'Political Organization',
  'POLITICAL ORGANIZATIONS',
  'POLITICAL ORGANIZATIONS',
  'Political Organizations',
  'Politics',
  'POLLEN & POLLINATION SERVICES',
  'POLLUTION CONTROL',
  'Pollution Control',
  'POLLUTION CONTROL CONSULTANTS',
  'POLO GROUNDS',
  'POLO SUPPLIES (MANUFACTURERS)',
  'POLYETHYLENE MATERIALS & PRODS (MFRS)',
  'POLYGRAPH SERVICE',
  'Polystyrene Foam Product Manufacturing',
  'POLYURETHANE PRODUCTS-MANUFACTURERS',
  'POLYURETHANE PRODUCTS-WHOLESALE',
  'POM POMS-RETAIL',
  'PONDS & POND SUPPLIES',
  'POOL CAR & TRUCK DISTRIBUTION',
  'POPCORN & POPCORN SUPPLIES',
  'POPCORN & POPCORN SUPPLIES-MANUFACTURERS',
  'POPCORN & POPCORN SUPPLIES-WHOLESALE',
  'POPCORN MACHINES (MANUFACTURERS)',
  'POPCORN PRODUCTS-MANUFACTURERS',
  'PORCELAIN ELECTRICAL SUPPLIES',
  'PORCELAIN ENAMEL-REPAIRING/REFINISHING',
  'PORCELAIN PRODUCTS-RETAIL',
  'PORCELAIN PRODUCTS-WHOLESALE',
  'PORCELAIN-ELECTRICAL-SUPPLIES (MFRS)',
  'PORCH SHADES',
  'PORK DEALERS-RETAIL',
  'PORK DEALERS-WHOLESALE',
  'PORK PRODUCTS (MANUFACTURERS)',
  'Port and Harbor Operations',
  'PORT SERVICES',
  'Portfolio Management',
  'PORTFOLIOS',
  'PORTRAIT PAINTING',
  'PORTS',
  'Ports and Harbors',
  'POST CARDS-MANUFACTURERS',
  'POST CARDS-RETAIL',
  'POST CARDS-WHOLESALE',
  'POST EXCHANGE',
  'POST OFFICE EQUIPMENT (WHOLESALE)',
  'POST OFFICES',
  'POSTAGE STAMP DISPENSING MACHINES (WHOL)',
  'POSTAGE STAMPS',
  'POSTAL BOXES-MANUFACTURERS',
  'Postal Service',
  'POSTERS (MANUFACTURERS)',
  'POSTERS-WHOLESALE',
  'Postharvest Crop Activities (except Cotton Ginning)',
  'POSTS-CORNER POSTS (WHOLESALE)',
  'POTASH',
  'POTASH (MANUFACTURERS)',
  'POTASH SODA & BORATE MINERALS',
  'Potash, Soda, and Borate Mineral Mining ',
  'POTATO CHIP FACTORIES',
  'POTATO CHIPS (WHOLESALE)',
  'POTATO CHIPS AND SIMILAR SNACKS',
  'POTATO CHIPS CORN CHIPS/SNACKS (MFRS)',
  'Potato Farming',
  'POTATO GROWERS',
  'POTATO HARVESTING/PLANTING EQUIP (MFRS)',
  'POTATO PROCESSING EQUIPMENT (MFRS)',
  'POTATO PRODUCTS',
  'POTATO SPROUT INHIBITORS (MANUFACTURERS)',
  'POTATO WAREHOUSE EQUIPMENT (MFRS)',
  'POTATO WAREHOUSES',
  'POTATOES-SEED',
  'POTATOES-WHOLESALE',
  'POTTERY',
  'POTTERY EQUIPMENT & SUPPLIES (WHOLESALE)',
  'POTTERY INSTRUCTION',
  'POTTERY PRODUCTS',
  'POTTERY PRODUCTS NEC (MANUFACTURERS)',
  'POTTERY-MANUFACTURERS',
  'POTTERY-WHOLESALE',
  'Pottery, Ceramics, and Plumbing Fixture Manufacturing ',
  'POULTRY & EGGS NEC',
  'POULTRY AND EGGS',
  'Poultry and Poultry Product Merchant Wholesalers',
  'POULTRY AND POULTRY PRODUCTS',
  'POULTRY BROKERS',
  'POULTRY EQUIPMENT & SUPPLIES (WHOLESALE)',
  'POULTRY EQUIPMENT & SUPPLIES-MFRS',
  'POULTRY FARMS',
  'Poultry Hatcheries',
  'POULTRY HATCHERIES',
  'POULTRY MEDICINES & VACCINES (MFRS)',
  'Poultry Processing',
  'POULTRY PROCESSING PLANTS',
  'POULTRY RESEARCH',
  'POULTRY SERVICES (WHOLESALE)',
  'POULTRY SLAUGHTERING AND PROCESSING',
  'POULTRY-RETAIL',
  'POULTRY-WHOLESALE',
  'Poured Concrete Foundation and Structure Contractors',
  'POWDER COATINGS (MANUFACTURERS)',
  'POWDER METAL PARTS-INDUSTRIAL (MFRS)',
  'Powder Metallurgy Part Manufacturing',
  'POWDER PROCESSING-INDUSTRIAL (MFRS)',
  'POWDER PUFFS (MANUFACTURERS)',
  'POWER',
  'Power and Communication Line and Related Structures Construction',
  'Power Boiler and Heat Exchanger Manufacturing',
  'POWER DISTR/SPECIALTY TRANSFORMER (MFRS)',
  'POWER FACTOR CORRECTION (MANUFACTURERS)',
  'Power Grid',
  'POWER LAUNDRIES',
  'POWER PLANT CONSULTANTS',
  'POWER PLANT CONTRACTORS',
  'POWER PLANT EQUIPMENT (WHOLESALE)',
  'POWER PLANT EQUIPMENT-MANUFACTURERS',
  'POWER PLANT EQUIPMENT-REPAIRING',
  'POWER PROTECTION SYSTEMS (MANUFACTURERS)',
  'POWER STEERING-AUTOMOTIVE (MFRS)',
  'POWER SUPPLY-EMERGENCY (WHOLESALE)',
  'POWER TAKE-OFFS',
  'POWER TRANSMISSION EQUIP-MANUFACTURERS',
  'POWER TRANSMISSION EQUIPMENT',
  'POWER TRANSMISSION EQUIPMENT (WHOLESALE)',
  'POWER TRANSMISSION EQUIPMENT-REPAIRING',
  'POWER WASH EQUIPMENT-MANUFACTURERS',
  'POWER-DRIVEN HAND TOOLS (MANUFACTURERS)',
  'Power-Driven Handtool Manufacturing',
  'POWER-DRIVEN HANDTOOLS',
  'Power, Distribution, and Specialty Transformer Manufacturing ',
  'PRAYER FOR THE DAY',
  'PRE-EMPLOYMENT VERIFICATION',
  'PRE-SCHOOLS',
  'Precious Metals',
  'PRECIOUS,SEMI-PRCS,SYNTHETIC STNS (WHL) ',
  'Precision Turned Product Manufacturing',
  'PRECLEANERS-AIR INTAKE SYSTEMS (MFRS)',
  'PRECOOLING',
  'Prediction Markets',
  'Predictive Analytics',
  'PREDICTIVE MAINTENANCE SERVICE',
  'Prefabricated Metal Building and Component Manufacturing',
  'PREFABRICATED METAL BUILDINGS',
  'PREFABRICATED METAL BUILDINGS (MFRS)',
  'Prefabricated Wood Building Manufacturing',
  'PREFABRICATED WOOD BUILDINGS',
  'PREFERRED PROVIDER ORGANIZATIONS',
  'PREGNANCY COUNSELING SVC & INFORMATION',
  'PREMIUM GOODS (WHOLESALE)',
  'PREMIUM GOODS-MANUFACTURERS',
  'PREPACKAGED SOFTWARE',
  'PREPACKAGED SOFTWARE',
  'PREPARED FEEDS',
  'PREPARED FISH & SEAFOOD PRODUCTS (MFR)',
  'PREPARED FLOUR MIXES & DOUGHS (MFRS)',
  'PREPARED FLOUR MIXES AND DOUGHS',
  'PREPRESS SERVICES',
  'PRERECORDED RECORDS AND TAPES',
  'PRESENTATION GRAPHICS (MANUFACTURERS)',
  'Presentation Software',
  'Presentations',
  'PRESERVES, JAMS & JELLIES (MFRS) ',
  'PRESS-PHOTO SERVICE',
  'PRESSED AND BLOWN GLASS',
  'PRESSED WOOD FUEL (MANUFACTURERS)',
  'PRESSES-POWER (MANUFACTURERS)',
  'PRESSING GARMENT',
  'PRESSING MACHINES-CLOTHES (WHOLESALE)',
  'PRESSING MACHINES-CLOTHES-REPAIRING',
  'PRESSING SHOPS',
  'PRESSURE CLEANING EQUIPMENT (WHOLESALE)',
  'PRESSURE GROUTING CONTRACTORS',
  'PRESSURE SENSITIVE MATERIALS-MFRS',
  'PRESSURIZATION EQUIPMENT (MFRS)',
  'PRESTRESSING EQUIP & MATERIALS (MFRS)',
  'PRETZELS (WHOLESALE)',
  'PRETZELS-MANUFACTURERS',
  'PRETZELS-RETAIL',
  'PREVENTIVE MEDICINE',
  'Price Comparison',
  'PRICE MARKING EQUIPMENT & SUPLS (WHOL)',
  'PRICING SYSTEMS (WHOLESALE)',
  'PRIMARY ALUMINUM',
  'PRIMARY BATTERIES',
  'PRIMARY BATTERIES-DRY & WET (MFRS)',
  'Primary Battery Manufacturing',
  'PRIMARY COPPER',
  'Primary Education',
  'PRIMARY METAL PRODUCTS',
  'PRIMARY NONFERROUS METALS',
  'PRIMARY PRODUCTION OF ALUMINUM (MFRS)',
  'PRIMARY SMELTING & REFINING OF COPPER',
  'PRIMARY SMELTING/REFINING-NONFERROUS MTL',
  'Primary/Secondary Education',
  'PRINT BLOCKS (MANUFACTURERS)',
  'PRINT CUTTERS (MANUFACTURERS)',
  'PRINTED & ETCHED CIRCUITS-MFRS',
  'Printed Circuit Assembly (Electronic Assembly) Manufacturing',
  'PRINTED CIRCUIT BOARDS',
  'PRINTERS',
  'PRINTERS-BOOKS',
  'PRINTERS-BUSINESS FORMS',
  'PRINTERS-CELLOPHANE, FOIL, ETC ',
  'PRINTERS-FINISHING',
  'PRINTERS-GLASS, METAL, PLASTIC, ETC ',
  'PRINTERS-NEWSPAPERS',
  "PRINTERS' SERVICES",
  'Printing',
  'PRINTING AND WRITING PAPER',
  'Printing and Writing Paper Merchant Wholesalers',
  'PRINTING BROKERS',
  'PRINTING CONSULTANTS',
  'PRINTING EQUIPMENT (WHOLESALE)',
  'PRINTING EQUIPMENT-MANUFACTURERS',
  'PRINTING EQUIPMENT-REPAIRING',
  'PRINTING INK',
  'PRINTING INK (MANUFACTURERS)',
  'Printing Ink Manufacturing',
  'Printing Machinery and Equipment Manufacturing',
  'PRINTING PLATE MOLDING EQUIPMENT (MFRS)',
  'PRINTING PLATES (WHOLESALE)',
  'PRINTING PLATES-PLASTIC (MANUFACTURERS)',
  'PRINTING SUPPLIES (WHOLESALE)',
  'PRINTING SUPPLIES-MANUFACTURERS',
  'PRINTING TRADES MACHINERY',
  'PRINTMAKING WORKSHOPS',
  'Privacy',
  'Privacy and Security',
  'Private Cloud',
  'Private Households',
  'PRIVATE HOUSEHOLDS',
  'Private Mail Centers',
  'Private Social Networking',
  'PRO MARKETING EXEC EMPLOYERS',
  'PRO STANDARDS REVIEW ORGANIZATIONS',
  'PROBATE SERVICE',
  'PROBATION SERVICES',
  'PROCESS CONTROL INSTRUMENTS',
  'PROCESS SERVERS',
  'Process, Physical Distribution, and Logistics Consulting Services ',
  'PROCESSING',
  'Procurement',
  'PRODUCE-BROKERS',
  'Product Design',
  'PRODUCT DESIGN/DEVELOPMENT CONSULTANTS',
  'PRODUCT DEVELOPMENT & MARKETING',
  'Product Management',
  'Product Research',
  'Product Search',
  'PRODUCTION CONTROL SYSTEMS (MFRS)',
  'Productivity Tools',
  'PRODUCTS OF PETROLEUM & COAL NEC (MFRS)',
  'PRODUCTS OF PURCHASED GLASS',
  'Professional and Management Development Training',
  'Professional Employer Organizations',
  'PROFESSIONAL EQUIPMENT',
  'PROFESSIONAL EQUIPMENT NEC (WHOLESALE)',
  'Professional Networking',
  'PROFESSIONAL ORGANIZATIONS',
  'PROFESSIONAL ORGANIZATIONS',
  'Professional Organizations',
  'Professional Services',
  'PROFESSIONAL SPORTS CLUBS & PROMOTERS',
  'Professional Training & Coaching',
  'Program Development',
  'PROGRAM SERVICE-EDUCATIONAL',
  'PROGRAMMED INSTRUCTION',
  'PROGRAMMING',
  'PROGRAMS (MANUFACTURERS)',
  'PROJECT MANAGEMENT',
  'Project Management',
  'PROJECTION APPARATUS (WHOLESALE)',
  'PROJECTORS (WHOLESALE)',
  'Promoters of Performing Arts, Sports, and Similar Events with Facilities ',
  'Promoters of Performing Arts, Sports, and Similar Events without Facilities ',
  'PROMOTIONS & FUND RAISING',
  'PROOFREADING',
  'PROPANE (LP) GAS',
  'PROPANE GAS EQUIPMENT (WHOLESALE)',
  'PROPANE-BULK & BOTTLE-DELIVERY',
  'PROPELLERS',
  'PROPELLERS (AIRPLANE-MANUFACTURERS)',
  'PROPELLERS-MFRS',
  'PROPELLERS-REPAIRING',
  'Property Development',
  'PROPERTY IDENTIFICATION SERVICE',
  'Property Insurance',
  'PROPERTY MAINTENANCE',
  'Property Management',
  'PROTECTION DEVICES-SELF DEFENSE',
  'PROTOTYPES (MANUFACTURERS)',
  'PROVISIONS-WHOLESALE',
  'PROXY SOLICITORS',
  'Psychiatric and Substance Abuse Hospitals',
  'PSYCHIATRIC HOSPITALS',
  'PSYCHIATRIC HOSPITALS',
  'PSYCHIATRIC SOCIAL WORKERS',
  'PSYCHIATRIC TREATMENT FACILITIES',
  'PSYCHIATRY-ADULT CHILD & ADOLESCENT',
  'PSYCHIC MEDIUMS',
  'PSYCHIC SCIENTIFIC RESEARCH CENTERS',
  'PSYCHOANALYSTS',
  'PSYCHOLOGICAL EXAMINERS',
  'PSYCHOLOGIST INFORMATION/REFERRAL SVCS',
  'PSYCHOLOGISTS',
  'Psychology',
  'PSYCHOTHERAPISTS',
  'PUBLIC ADMINISTRATORS',
  'PUBLIC BUILDING AND RELATED FURNITURE',
  'PUBLIC BUILDING/RELATED FURNITURE (MFRS)',
  'Public Finance Activities',
  'PUBLIC GOLF COURSES',
  'PUBLIC OPINION ANALYSTS',
  'PUBLIC ORDER AND SAFETY',
  'Public Policy',
  'Public Relations',
  'Public Relations Agencies',
  'Public Relations and Communications',
  'PUBLIC RELATIONS COUNSELORS',
  'PUBLIC RELATIONS SERVICES',
  'Public Safety',
  'PUBLIC SPEAKING INSTRUCTION',
  'Public Transportation',
  'PUBLIC UTILITY CONSULTANTS',
  'PUBLICATION CONSULTANTS',
  'PUBLICATIONS (MANUFACTURERS)',
  'PUBLICITY SERVICE',
  'PUBLISHERS',
  'PUBLISHERS-ART',
  'PUBLISHERS-AUDIO',
  'PUBLISHERS-BOOK',
  'PUBLISHERS-BROCHURE',
  'PUBLISHERS-COMPUTER SOFTWARE',
  'PUBLISHERS-DIRECTORY & GUIDE',
  'PUBLISHERS-LEGISLATIVE JUD/PUB RECORD',
  'PUBLISHERS-MAGAZINE',
  'PUBLISHERS-MICROFORM',
  'PUBLISHERS-PERIODICAL',
  'PUBLISHERS-REPRESENTATIVES',
  'PUBLISHERS-SHIPPING SERVICE',
  'Publishing',
  'PUBLISHING CONSULTANTS',
  'PUBLISHING-DESKTOP',
  'PUBS',
  'PUDDING-MANUFACTURERS',
  'PULLERS-BEARING, GEAR, WHEEL, ETC (MFRS) ',
  'PULLEY COVERINGS (MANUFACTURERS)',
  'PULLEYS (WHOLESALE)',
  'PULP & PULP PRODUCTS (MANUFACTURERS)',
  'PULP MILLS',
  'Pulp Mills',
  'PULPWOOD (MANUFACTURERS)',
  'PUMICE (MANUFACTURERS)',
  'PUMPING CONTRACTORS',
  'PUMPS (WHOLESALE)',
  'PUMPS & PUMPING EQUIPMENT (MFRS)',
  'PUMPS AND PUMPING EQUIPMENT',
  'PUMPS-MANUFACTURERS',
  'PUMPS-RENTING',
  'PUMPS-REPAIRING',
  'PUMPS-RETAIL',
  'PUNCH BOARDS',
  'PUNCH PRESSES (MANUFACTURERS)',
  'PUNCHING & SHEARING MACHINERY (MFRS)',
  'PUPPETS & MARIONETTES (WHOLESALE)',
  'PURCHASING SERVICE',
  'PURIFIERS-INDUSTRIAL (MANUFACTURERS)',
  'PURSE KITS (MANUFACTURERS)',
  'PUSHCARTS (MANUFACTURERS)',
  'PUTTY (MANUFACTURERS)',
  'PUTTY-MANUFACTURERS',
  'PUZZLES (MANUFACTURERS)',
  'PYROMETERS (MANUFACTURERS)',
  'Q&A',
  'QR Codes',
  'QUAIL FARMS',
  'Quality Assurance',
  'QUALITY CONTROL CONSULTANTS',
  'Quantified Self',
  'Quantum Computing',
  'QUARRIES',
  'QUARTZ (MANUFACTURERS)',
  'QUILTING',
  'QUILTING MACHINES',
  'QUILTING MATERIALS & SUPPLIES',
  'QUILTING MATERIALS & SUPPLIES-WHOLESALE',
  'QUILTING-MANUFACTURERS',
  'QUILTING-WHOLESALE',
  'QUILTS',
  'QUILTS-MANUFACTURERS',
  'QUILTS-RENOVATING',
  'RABBIS',
  'RABBITS',
  'RACE BOOKS',
  'RACE TRACK EQUIPMENT & SUPPLIES',
  'RACE TRACK EQUIPMENT & SUPPLIES-MFRS',
  'RACE TRACKS',
  'Racetracks',
  'RACING',
  'Racing',
  'RACING FUELS',
  'RACING INFORMATION (MANUFACTURERS)',
  'RACK MERCHANDISE SERVICE',
  'RACKS (WHOLESALE)',
  'RACKS-INDUSTRIAL (WHOLESALE)',
  'RACKS-MANUFACTURERS',
  'RACQUETBALL COURTS-PRIVATE',
  'RACQUETBALL COURTS-PUBLIC',
  'RACQUETBALL EQUIPMENT & SUPPLIES',
  'RACQUETBALL EQUIPMENT & SUPPLIES-MFRS',
  'RACQUETBALL HANDBALL/SQUASH CRT CONSTR',
  'RACQUETS-RESTRINGING & REPAIRING',
  'RADAR DETECTORS-DEALERS',
  'RADAR EQUIPMENT & SUPPLIES (WHOLESALE)',
  'RADAR-MANUFACTURERS & SERVICE',
  'RADIANT BARRIERS',
  'RADIANT HEATING & COOLING SYSTEMS',
  'RADIATOR COVERS & ENCLOSURES',
  'RADIATORS-AUTOMOTIVE-RETAIL',
  'RADIATORS-AUTOMOTIVE-WHOLESALE',
  'RADIATORS-COOLANTS & SOLVENTS',
  'RADIATORS-HEATING (MANUFACTURERS)',
  'RADIATORS-INDUSTRIAL',
  'RADIATORS-INDUSTRIAL-REBUILDING/REPAIR',
  'RADIO',
  'RADIO',
  'RADIO AND T.V. COMMUNICATIONS EQUIPMENT',
  'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing',
  'RADIO AND TELEVISION REPAIR',
  'RADIO BROADCASTING STATIONS',
  'RADIO COMM EQUIP & SYSTEMS-REPAIRING',
  'RADIO COMMUNICATION EQUIP & SYSTEMS-WHOL',
  'RADIO COMMUNICATIONS EQUIP-MOBILE (WHOL)',
  'RADIO COMMUNICATIONS EQUIP/SYSTS-RENTAL',
  'RADIO DEALERS-RETAIL',
  'RADIO DIRECTION FINDERS (MANUFACTURERS)',
  'RADIO ELECTRONIC CONSULTANTS',
  'RADIO EQUIPMENT & PARTS-MANUFACTURERS',
  'RADIO EQUIPMENT-AMATEUR',
  'RADIO INTERFERENCE CONSULTANTS',
  'Radio Networks',
  'RADIO PAGING/SIGNALING EQPT SYSTS (WHOL)',
  'RADIO PROGRAM PRODUCERS',
  'RADIO PROGRAM SURVEYS',
  'RADIO STATION EQUIPMENT (WHOLESALE)',
  'RADIO STATION EQUIPMENT-MANUFACTURERS',
  'RADIO STATION REPRESENTATIVES',
  'Radio Stations',
  'RADIO STATIONS & BROADCASTING COMPANIES',
  'RADIO SUPPLIES & PARTS-MANUFACTURERS',
  'RADIO-WHOLESALE',
  'RADIO/TV BROADCASTING/COMM EQUIP (MFRS)',
  'RADIOACTIVE MATERIALS (MANUFACTURERS)',
  'RADIOACTIVITY INSTRUMENTS SUPLS/SVC-MFRS',
  'RADIOACTIVITY INSTRUMENTS SUPLS/SVC-WHOL',
  'RADIOLOGISTS',
  'RADIOLOGY SCHOOLS',
  'RADIOS-CITIZENS BAND',
  'RADIOS-CITIZENS BAND-MANUFACTURERS',
  'RADIOS-CITIZENS BAND-WHOLESALE',
  'RADIOTELEPHONE COMMUNICATION',
  'RADIOTELEPHONE COMMUNICATIONS',
  'RADIOTHERAPY',
  'RADIUM',
  'RADON TESTING & CORRECTION',
  'RAFFIA',
  'RAFT TRIPS',
  'RAFTS-DEALERS',
  'RAGS (WHOLESALE)',
  'RAILINGS',
  'RAILINGS-MANUFACTURERS',
  'RAILINGS-WHOLESALE',
  'Railroad',
  'RAILROAD CAR LEASING',
  'RAILROAD CARS-MANUFACTURERS',
  'RAILROAD CARS-USED',
  'RAILROAD CONTRACTORS',
  'RAILROAD EQUIP & SUPPLIES-REPAIRING',
  'RAILROAD EQUIPMENT',
  'RAILROAD EQUIPMENT (MANUFACTURERS)',
  'RAILROAD EQUIPMENT & SUPPLIES (WHOL)',
  'Railroad Manufacture',
  'RAILROAD PROPERTY LESSORS',
  'Railroad Rolling Stock Manufacturing',
  'RAILROAD SWITCHING & TERMINAL ESTBLSHMNT',
  'RAILROAD TIES',
  'RAILROAD TIES-MANUFACTURERS',
  'RAILROAD TIES-WHOLESALE',
  'RAILROADS',
  'RAILROADS',
  'RAILROADS-MODEL',
  'RAILROADS-TICKET AGENCIES',
  'RAILS & ACCESSORIES (MANUFACTURERS)',
  'RAINWEAR',
  'RAINWEAR-WHOLESALE',
  'RANCH SALES',
  'RANCHES',
  'Ranching',
  'RANGE HOODS & CANOPIES',
  'RANGE HOODS & CANOPIES-MANUFACTURERS',
  'RANGE HOODS & CANOPIES-WHOLESALE',
  'RANGES & OVENS-SERVICE & REPAIR',
  'RANGES & OVENS-USED',
  'RANGES & STOVES-DEALERS',
  'RANGES & STOVES-RENTING & LEASING',
  'RANGES & STOVES-SERVICE & REPAIR',
  'RANGES & STOVES-SUPPLIES & PARTS (WHOL)',
  'RANGES & STOVES-WHOLESALE',
  'RATTAN REEDS & WILLOWS',
  'RATTAN REEDS & WILLOWS-WHOLESALE',
  'RAVIOLI (WHOLESALE)',
  'RAVIOLI-MANUFACTURERS',
  'RAW CANE SUGAR',
  'RAYON FABRICS-WHOLESALE',
  'RAYON-MANUFACTURERS',
  'RAZORS & RAZOR BLADES (WHOLESALE)',
  'Reading Apps',
  'READING IMPROVEMENT INSTRUCTION',
  'READING ROOMS',
  'Ready-Mix Concrete Manufacturing',
  'READY-MIXED CONCRETE',
  'READY-MIXED CONCRETE-MANUFACTURERS',
  'REAL ESTATE',
  'Real Estate',
  'REAL ESTATE AGENTS AND MANAGERS',
  'REAL ESTATE APPRAISERS',
  'REAL ESTATE BUYERS & BROKERS',
  'REAL ESTATE BUYERS REPRESENTATIVES',
  'REAL ESTATE CONSULTANTS',
  'Real Estate Credit',
  'REAL ESTATE DEVELOPERS',
  'REAL ESTATE EXCHANGERS',
  'REAL ESTATE INFORMATION REPORTS',
  'REAL ESTATE INSPECTION',
  'Real Estate Investment',
  'REAL ESTATE INVESTMENT TRUSTS',
  'REAL ESTATE INVESTMENT TRUSTS',
  'REAL ESTATE INVESTMENTS',
  'REAL ESTATE LOANS',
  'REAL ESTATE MAINTENANCE PROTECTION PLANS',
  'REAL ESTATE MANAGEMENT',
  'REAL ESTATE RESEARCH SERVICES',
  'REAL ESTATE SCHOOLS',
  'REAL ESTATE SUPPORT SERVICES',
  'REAL ESTATE SYNDICATORS',
  'REAL ESTATE TAX SEARCHERS',
  'REAL ESTATE VIDEO SERVICES',
  'REAL ESTATE-RENTAL SERVICE',
  'REAL ESTATE-TIME SHARING',
  'REAL PROPERTY LESSORS',
  'Real Time',
  'Recipes',
  'RECIPROCAL BILLING',
  'Reconstituted Wood Product Manufacturing',
  'RECONSTITUTED WOOD PRODUCTS',
  'RECONSTITUTED WOOD PRODUCTS (MFRS)',
  'RECORD AND PRERECORDED TAPE STORES',
  'Record Production and Distribution',
  'RECORDED INFORMATION',
  'RECORDERS-SOUND & VIDEO',
  'RECORDERS-SOUND/VIDEO EQUIP/SUPLS (MFRS)',
  'RECORDING INSTR IND/SCIENTIFIC (WHOL)',
  'RECORDING INSTRUMENTS IND/SCIENTFC-MFRS',
  'RECORDING STUDIO EQPT-SOUND/VIDEO (WHOL)',
  'RECORDING STUDIOS',
  'RECORDS TAPES & COMPACT DISCS-RETAIL',
  'RECORDS TAPES & COMPACT DISCS-WHOLESALE',
  'RECORDS TAPES DISCS-MFR EQUIP/SUPL (MFR)',
  'RECORDS-MANAGEMENT CONSULTANTS',
  'RECORDS-PHONOGRAPH-MANUFACTURERS',
  'RECORDS-PHONOGRAPH-MFRS EQPT/SUPL (WHOL)',
  'RECORDS-PHONOGRAPH-USED & RARE',
  'RECORDS-STORED',
  'Recreation',
  'RECREATION CENTERS',
  'RECREATION PROGRAM CONSULTANTS',
  'Recreational and Vacation Camps (except Campgrounds)',
  'Recreational Facilities and Services',
  'Recreational Goods Rental',
  'RECREATIONAL VEHICLE DEALERS',
  'Recreational Vehicle Dealers',
  'RECREATIONAL VEHICLE PARKS',
  'RECREATIONAL VEHICLE SEATING-MFRS',
  'RECREATIONAL VEHICLES',
  'Recreational Vehicles',
  'RECREATIONAL VEHICLES & CAMPERS-MFRS',
  'RECREATIONAL VEHICLES-EQUIP-WHOLESALE',
  'RECREATIONAL VEHICLES-EQUIP, PARTS-MFRS ',
  'RECREATIONAL VEHICLES-EQUIP/PARTS/SVC',
  'RECREATIONAL VEHICLES-RENTING & LEASING',
  'RECREATIONAL VEHICLES-REPAIRING & SVC',
  'RECREATIONAL VEHICLES-STORAGE',
  'RECREATIONAL VEHICLES-TRANSPORTING',
  'RECREATIONAL VEHICLES-USED',
  'RECREATIONAL VEHICLES-WHOLESALE',
  'Recruiting',
  'RECRUITING-SPORTS',
  'RECRUITING-US ARMED FORCES',
  'RECTIFIERS (MANUFACTURERS)',
  'RECTIFIERS-ELECTRIC (WHOLESALE)',
  'RECTIFIERS-ELECTRIC-MANUFACTURERS',
  'Recyclable Material Merchant Wholesalers',
  'RECYCLABLES-PICK UP SERVICE',
  'RECYCLED PRODUCTS (WHOLESALE)',
  'Recycling',
  'RECYCLING CENTERS (WHOLESALE)',
  'RECYCLING CONSULTANTS',
  'RECYCLING EDUCATION INFO & TRAINING SVC',
  'RECYCLING EQUIPMENT & SYSTEMS',
  'RECYCLING EQUIPMENT-MANUFACTURERS',
  'RECYCLING TONER CARTRIDGES',
  'REDUCING EQUIPMENT (WHOLESALE)',
  'REELS (MANUFACTURERS)',
  'REFEREES IN BANKRUPTCY',
  'REFERRAL SERVICES',
  'REFINED PETROLEUM PIPELINES',
  'REFINED PETROLEUM PIPELINES',
  'REFINERY EQUIPMENT (WHOLESALE)',
  'REFINING',
  'REFLECTORS (MANUFACTURERS)',
  'REFLEXOLOGISTS',
  'REFORESTATION SERVICE & EQUIPMENT',
  'REFRACTORIES',
  'REFRACTORY MATERIALS (WHOLESALE)',
  'REFRESHMENT STANDS',
  'REFRIGERANT RECOVERY',
  'Refrigerated Warehousing and Storage',
  'REFRIGERATED WAREHOUSING AND STORAGE',
  'REFRIGERATING EQUIP SUPLS & PARTS (WHOL)',
  'REFRIGERATING EQUIP SUPLS & PARTS-MFRS',
  'REFRIGERATING EQUIP-COML (WHOLESALE)',
  'REFRIGERATING EQUIP-COML-USED (WHOL)',
  'REFRIGERATING EQUIP-COMMERCIAL-SERVICE',
  'REFRIGERATING EQUIPMENT-COMMERCIAL-MFRS',
  'REFRIGERATION & AIR COND-SERVICE & RPR',
  'REFRIGERATION AND HEATING EQUIPMENT',
  'REFRIGERATION CONTRACTORS',
  'REFRIGERATION EQUIPMENT AND SUPPLIES',
  'Refrigeration Equipment and Supplies Merchant Wholesalers',
  'REFRIGERATION FREEZERS (WHOLESALE)',
  'REFRIGERATION SERVICE AND REPAIR',
  'REFRIGERATORS & FREEZERS-DEALERS',
  'REFRIGERATORS & FREEZERS-RENTING/LEASE',
  'REFRIGERATORS & FREEZERS-SERVICE/REPAIR',
  'REFRIGERATORS & FREEZERS-USED',
  'REFRIGERATORS & FREEZERS-WHOLESALE',
  'REFRIGERATORS-MANUFACTURERS',
  'REFRIGERATORS/FREEZERS-SUPLS/PARTS-MFRS',
  'REFRIGERATORS/FREEZERS-SUPLS/PRTS (WHOL)',
  'REFUGEE PROGRAMS-RESETTLEMENT/HEALTH SVC',
  'REFUSE DISPOSAL-DEPOTS & PLANTS',
  'REFUSE SYSTEMS',
  'REFUSE SYSTEMS',
  'REGISTERED AGENTS',
  'REGULATION',
  'REGULATION',
  'REGULATION',
  'Regulation and Administration of Communications, Electric, Gas, and Other Utilities ',
  'Regulation and Administration of Transportation Programs',
  'REGULATION OF AGRICULTURAL MARKETING',
  'Regulation of Agricultural Marketing and Commodities',
  'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors ',
  'Rehabilitation',
  'REHABILITATION SERVICES',
  'REIKI',
  'Reinsurance Carriers',
  'Relay and Industrial Control Manufacturing',
  'RELAYS (MANUFACTURERS)',
  'RELAYS & INDUSTRIAL CONTROLS (MFRS)',
  'RELAYS AND INDUSTRIAL CONTROLS',
  'Religion',
  'RELIGIOUS COUNSELING',
  'RELIGIOUS GOODS',
  'RELIGIOUS GOODS-MANUFACTURERS',
  'RELIGIOUS GOODS-WHOLESALE',
  'Religious Institutions',
  'RELIGIOUS ORGANIZATIONS',
  'RELIGIOUS ORGANIZATIONS',
  'Religious Organizations',
  'RELIGIOUS SCHOOLS',
  'RELIGIOUS SCIENCE PRACTITIONERS',
  'RELOCATION SERVICE',
  'Remediation Services',
  'REMNANTS',
  'REMODELING & REPAIRING BLDG CONTRACTORS',
  'REMOTE SENSING ANALYSIS',
  'Rendering and Meat Byproduct Processing',
  'RENDERING COMPANIES',
  'Renewable Energy',
  'Renewables & Environment',
  'Rental',
  'RENTAL AGENCIES',
  'RENTAL OF RAILROAD CARS',
  'Rental Property',
  'RENTAL SERVICE-STORES & YARDS',
  'RENTAL SUPPLIES-MANUFACTURERS',
  'RENTAL SUPPLIES-WHOLESALE',
  'REPAIR SERVICES',
  'REPAIR SHOPS & RELATED SERVICES NEC',
  'REPOSSESSING SERVICE',
  'Repossession Services',
  'REPROGRAPHIC SERVICES',
  'REPTILES',
  'Reputation',
  'RESCUE EQUIPMENT & SUPPLIES',
  'RESCUE SQUADS',
  'Research',
  'Research and Development in Biotechnology (except Nanobiotechnology)',
  'Research and Development in Nanotechnology',
  'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology) ',
  'Research and Development in the Social Sciences and Humanities',
  'RESEARCH SERVICE',
  'RESEARCHERS-INDEPENDENT',
  'Reservations',
  'RESIDENT BUYERS',
  'Residential',
  'RESIDENTIAL CARE',
  'RESIDENTIAL CARE',
  'RESIDENTIAL CARE HOMES',
  'RESIDENTIAL CONSTRUCTION',
  'RESIDENTIAL DESIGNERS',
  'Residential Electric Lighting Fixture Manufacturing',
  'Residential Intellectual and Developmental Disability Facilities',
  'RESIDENTIAL LIGHTING FIXTURES',
  'Residential Mental Health and Substance Abuse Facilities',
  'Residential Property Managers',
  'Residential Remodelers',
  'RESINS (WHOLESALE)',
  'RESINS-MANUFACTURERS',
  'RESISTORS (MANUFACTURERS)',
  'RESORTS',
  'Resorts',
  'RESOURCE & REFERRALS',
  'RESPIRATORS (WHOLESALE)',
  'RESPIRATORS-MANUFACTURERS',
  'RESPIRATORY THERAPISTS',
  'RESPIRATORY THERAPY',
  'REST HOMES',
  'RESTAURANT CLEANING',
  'RESTAURANT DESIGN & PLANNING SERVICE',
  'RESTAURANT EQUIPMENT & SUPLS-USED (WHOL)',
  'RESTAURANT EQUIPMENT & SUPPLIES (WHOL)',
  'RESTAURANT EQUIPMENT & SUPPLIES-MFRS',
  'RESTAURANT EQUIPMENT & SUPPLIES-RENTING',
  'RESTAURANT EQUIPMENT & SUPPLIES-RETAIL',
  'RESTAURANT EQUIPMENT CLEANING',
  'RESTAURANT EQUIPMENT-REPAIRING & SVC',
  'RESTAURANT FIXTURES (WHOLESALE)',
  'RESTAURANT FIXTURES-USED (WHOLESALE)',
  'RESTAURANT FLUE CLEANING',
  'RESTAURANT HOLDING COMPANIES',
  'RESTAURANT INFORMATION & REFERRAL SVC',
  'RESTAURANT MANAGEMENT',
  'RESTAURANTS',
  'Restaurants',
  'RESTAURANTS - EATING PLACES',
  'RESTAURANTS-FOOD DELIVERY',
  'RESTROOM SANITATION',
  'RESUME SERVICE',
  'Retail',
  'RETAIL BAKERIES',
  'Retail Bakeries',
  'RETAIL NURSERIES AND GARDEN STORES',
  'RETAIL SHOPS',
  'Retail Technology',
  'RETAINING WALLS',
  'RETICLES (MANUFACTURERS)',
  'Retirement',
  'RETIREMENT & LIFE CARE CMNTY/HOMES INFO',
  'RETIREMENT APARTMENTS & HOTELS',
  'RETIREMENT COMMUNITIES & HOMES',
  'RETIREMENT PLANNING SERVICES',
  'RETREAT HOUSES',
  'RETRIEVAL SYSTEMS-AUTOMATED',
  'Reupholstery and Furniture Repair',
  'REUPHOLSTERY AND FURNITURE REPAIR',
  'REWEAVING & MENDING',
  'RFID',
  'RHINESTONE GOODS (WHOLESALE)',
  'RHINESTONE SETTERS',
  'RHINESTONES-MANUFACTURERS',
  'RHINESTONES-RETAIL',
  'RHINESTONES-WHOLESALE',
  'RIBBON CUTTING (WHOLESALE)',
  'RIBBON NOVELTIES (MANUFACTURERS)',
  'RIBBON PRINTING (MANUFACTURERS)',
  'RIBBONS (WHOLESALE)',
  'RIBBONS-MANUFACTURERS',
  'RICE',
  'RICE DRYERS',
  'Rice Farming',
  'RICE MILL EQUIPMENT (MANUFACTURERS)',
  'RICE MILLING',
  'Rice Milling',
  'RICE MILLS',
  'RICE PRODUCTION',
  'RICE PRODUCTS (MANUFACTURERS)',
  'RICE-WHOLESALE',
  'RIDE SHARE INFORMATION',
  'Ride Sharing',
  'RIDING ACADEMIES',
  'RIDING APPAREL & EQUIPMENT',
  'RIDING APPAREL & EQUIPMENT-MANUFACTURERS',
  'RIDING APPAREL & EQUIPMENT-WHOLESALE',
  'RIFLE & PISTOL RANGES',
  'RIFLE & PISTOL RANGES EQUIP/SUPLS (MFRS)',
  'RIGGERS',
  "RIGGERS' EQUIPMENT & SUPPLIES (WHOL)",
  "RIGGERS' EQUIPMENT & SUPPLIES-MFRS",
  'RIGHT OF WAY ACQUISITION CONTRACTORS',
  'RINGS-GLASS, METAL, PLASTIC, ETC (MFRS) ',
  'RINGS-RETAINING (WHOLESALE)',
  'RISC',
  'Risk Management',
  'RISK MANAGEMENT CONSULTANTS',
  'RIVER TRIPS',
  'RIVET-MANUFACTURERS',
  'RIVETING EQUIPMENT (MANUFACTURERS)',
  'RIVETS (WHOLESALE)',
  'ROAD BORING',
  'ROAD BUILDING CONTRACTORS',
  'ROAD BUILDING EQUIPMENT (WHOLESALE)',
  'ROAD MAINTENANCE',
  'ROAD MATERIALS (WHOLESALE)',
  'ROAD OILING',
  'ROAD SERVICE-AUTOMOTIVE',
  'ROASTED COFFEE',
  'Roasted Nuts and Peanut Butter Manufacturing',
  'ROBES & DRESSING GOWNS-MFRS',
  'ROBES AND DRESSING GOWNS',
  'ROBES CAPS & GOWNS-RETAIL',
  'ROBES-MANUFACTURERS',
  'ROBES-RETAIL',
  'ROBES-WHOLESALE',
  'ROBOTIC COMPONENTS',
  'Robotics',
  'ROBOTS (WHOLESALE)',
  'ROBOTS-MANUFACTURERS',
  'ROCK (WHOLESALE)',
  'ROCK LAWNS & ROCKSCAPING',
  'ROCK SHOPS',
  'RODEOS',
  'RODS-THREADED',
  'Roku',
  'ROLL FORMING (MANUFACTURERS)',
  'ROLL GRINDING',
  'Rolled Steel Shape Manufacturing',
  'ROLLERS-WOODEN METAL RUBBER (WHOLESALE)',
  'ROLLERS-WOODEN METAL RUBBER ETC-MFRS',
  'ROLLING DRAWING EXTRUDING-NONFERROUS MTL',
  'ROLLING DRAWING/EXTRUDING-COPPER (MFRS)',
  'Rolling Mill and Other Metalworking Machinery Manufacturing',
  'ROLLING MILL MACHINERY',
  'ROLLING MILL MACHINERY (MANUFACTURERS)',
  'ROLLS-RUBBER COVERED (MANUFACTURERS)',
  'ROOF CLEANING',
  'ROOF COATING',
  'ROOF COOLING SYSTEMS',
  'ROOF CURBS-DISTRIBUTORS',
  'ROOF CURBS-MANUFACTURERS',
  'ROOF DECKS',
  'ROOF INSPECTION SERVICE',
  'ROOF MAINTENANCE',
  'ROOF STRUCTURES',
  'ROOF STRUCTURES-MANUFACTURERS',
  'ROOF-SCREEN SUPPORT SYSTEMS',
  'ROOFING',
  'ROOFING',
  'ROOFING CONSULTANTS',
  'ROOFING CONTRACTORS',
  'Roofing Contractors',
  'ROOFING EQUIPMENT & SUPPLIES (WHOLESALE)',
  'ROOFING MATERIALS',
  'ROOFING MATERIALS-MANUFACTURERS',
  'ROOFING MATERIALS-METAL',
  'ROOFING MATERIALS-WHOLESALE',
  'ROOFING SERVICE CONSULTANTS',
  'ROOFING SIDING & SHEET METAL WORK',
  'ROOFING-MANUFACTURERS-MACHINERY (MFRS)',
  'Roofing, Siding, and Insulation Material Merchant Wholesalers ',
  'ROOM DIVIDERS (MANUFACTURERS)',
  'ROOMING & BOARDING HOUSES',
  'ROOMING AND BOARDING HOUSES',
  "Rooming and Boarding Houses, Dormitories, and Workers' Camps ",
  'ROOMMATE REFERRAL SERVICE',
  'ROPE (WHOLESALE)',
  'ROPE SPLICING (WHOLESALE)',
  'ROPE-MANUFACTURERS',
  'ROPE-RETAIL',
  'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills ',
  'ROPING CLUBS',
  'ROSIN (MANUFACTURERS)',
  'ROTARY CULTIVATING & TILLING',
  'ROTARY JOINTS (MANUFACTURERS)',
  'ROTOGRAVURE PRINTERS',
  'ROUSTABOUTS',
  'ROUTING SERVICE-AIR, HIGHWAY, MARINE ',
  'ROYALTIES-OIL & MINERAL',
  'RUBBER (MANUFACTURERS)',
  'RUBBER & PLASTIC FOOTWEAR-WHOL & MFRS',
  'RUBBER & PLASTIC STAMPS',
  'RUBBER & PLASTICS-FOOTWEAR (MFRS)',
  'RUBBER & PLASTICS-HOSE & BELTING (MFRS)',
  'RUBBER AND PLASTICS FOOTWEAR',
  'RUBBER AND PLASTICS HOSE AND BELTINGS',
  'Rubber and Plastics Hoses and Belting Manufacturing',
  'RUBBER CEMENT (MANUFACTURERS)',
  'RUBBER CLOTHING & FOOTWEAR (MFRS)',
  'RUBBER COATING (MANUFACTURERS)',
  'RUBBER MILL MACHINERY (MANUFACTURERS)',
  'Rubber Product Manufacturing for Mechanical Use',
  'RUBBER PRODUCTS-MANUFACTURERS',
  'RUBBER PRODUCTS-RETAIL',
  'RUBBER PRODUCTS-WHOLESALE',
  'RUBBER RECLAIMING (MANUFACTURERS)',
  'RUBBER RESEARCH & CONSULTING',
  'RUBBER ROLLS (MANUFACTURERS)',
  'RUBBER STAMPS-MANUFACTURERS',
  "RUBBER STAMPS-MFRS' EQUIP & SUPLS (MFRS)",
  'RUBBER STAMPS-WHOLESALE',
  'RUBBER WORKING MACHINERY (WHOLESALE)',
  'RUBBER-COMPOUNDING & MIXTURE (MFRS)',
  'RUBBER-CRUDE (WHOLESALE)',
  'RUBBER-FOAM & SPONGE (WHOLESALE)',
  'RUBBER-LATEX (MANUFACTURERS)',
  'RUBBER-MFRS SUPPLIES (MANUFACTURERS)',
  'RUBBER-SYNTHETIC (MANUFACTURERS)',
  'RUBBERBANDS (MANUFACTURERS)',
  'RUBBERIZED FABRICS (MANUFACTURERS)',
  'RUBBISH CONTAINERS-MANUFACTURERS',
  'RUBBISH GARBAGE & TRASH CHUTE CLEANING',
  'RUBBISH REMOVAL-CONTRS EQUIP (WHOL)',
  'Rugby',
  'RUGS',
  'RUGS-SOUTHWESTERN',
  'RULERS & YARDSTICKS (MANUFACTURERS)',
  'RULES (MANUFACTURERS)',
  'RUMMAGE SALE CENTERS',
  'RUNNING & JOGGING SUPPLIES',
  'RUST PREVENTIVES & REMOVERS (WHOLESALE)',
  'RUST PREVENTIVES-MANUFACTURERS',
  'RUST REMOVING',
  'RUSTPROOFING-INDUSTRIAL',
  'RV (Recreational Vehicle) Parks and Campgrounds',
  'SaaS',
  'SABOTAGE PREVENTION SERVICE',
  'SACHETS (MANUFACTURERS)',
  'SADDLERY & HARNESS',
  'SADDLERY & HARNESS-MANUFACTURERS',
  'SADDLERY & HARNESS-WHOLESALE',
  'SAFE DEPOSIT BOX-RENTAL',
  'SAFE DEPOSIT BOX-SALES (WHOLESALE)',
  'SAFES & VAULTS (WHOLESALE)',
  'SAFES & VAULTS-DEALERS',
  'SAFES & VAULTS-MANUFACTURERS',
  'SAFES & VAULTS-MOVERS',
  'SAFES & VAULTS-OPENING & REPAIRING',
  'SAFES & VAULTS-USED (WHOLESALE)',
  'SAFETY CANS (MANUFACTURERS)',
  'SAFETY CHILD EDUCATION',
  'SAFETY CONSULTANTS',
  'SAFETY EQUIPMENT & CLOTHING (WHOL)',
  'SAFETY EQUIPMENT & CLOTHING-MFRS',
  'SAIL FABRICS (MANUFACTURERS)',
  'SAILBOATS & SAILBOAT SUPPLIES (WHOL)',
  'Sailing',
  'SAILING INSTRUCTION',
  'SAILMAKERS',
  'SAIMIN (MANUFACTURERS)',
  'SALAD PRODUCTS-MANUFACTURERS',
  'SALADS (WHOLESALE)',
  'Sales',
  'SALES & ORDER BOOKS (MANUFACTURERS)',
  'Sales and Marketing',
  'Sales Automation',
  'SALES COUNSELORS',
  'Sales Financing',
  'SALES ORGANIZATIONS',
  'SALES PROMOTION SERVICE',
  'SALES TRAINING',
  'SALT',
  'SALT MINERS',
  'SALT PROCESSING',
  'SALT-WHOLESALE',
  'SALTED & ROASTED NUTS & SEEDS (MFRS)',
  'SALTED AND ROASTED NUTS AND SEEDS',
  'SALVAGE & SURPLUS MERCHANDISE',
  'SALVAGE-MARINE',
  'SALVAGE-YARDS (WHOLESALE)',
  'Same Day Delivery',
  'SAMPLE CARDS & BOOKS',
  'SAMPLE CARDS & BOOKS-WHOLESALE',
  'SAMPLE CASES (MANUFACTURERS)',
  'SANCTUARIES-WILD LIFE',
  'SAND & GRAVEL (WHOLESALE)',
  'SAND & GRAVEL HANDLING EQUIPMENT (WHOL)',
  'SAND & GRAVEL-MFRS',
  'SANDBLASTING',
  'SANDBLASTING EQUIP & SUPPLIES (WHOL)',
  'SANDBLASTING EQUIPMENT & SUPPLIES-MFRS',
  'SANDBLASTING EQUIPMENT-RENTING',
  'SANDING MACHINES-RENTAL',
  'SANDPAPER (MANUFACTURERS)',
  'SANDWICHES',
  'SANDWICHES-MANUFACTURERS',
  'SANDWICHES-WHOLESALE',
  'SANITARIUMS',
  'SANITARY FOOD CNTNRS-EX FOLDING (MFRS)',
  'SANITARY FOOD CONTAINERS',
  'SANITARY NAPKIN MACHINES (MANUFACTURERS)',
  'SANITARY NAPKINS-WHOLESALE',
  'Sanitary Paper Product Manufacturing',
  'SANITARY PAPER PRODUCTS',
  'SANITARY PAPER PRODUCTS (MANUFACTURERS)',
  'SANITARY PRODUCTS SUPPLIES & SVC (WHOL)',
  'SANITARY SERVICES',
  'SANITARY SERVICES NEC',
  'SANITATION CONSULTANTS',
  'SANITATION SERVICES',
  'SASH BALANCES & WEIGHTS (MANUFACTURERS)',
  'SASH OPERATING DEVICES',
  'SASH-WOOD-MANUFACTURERS',
  'SATELLITE COMMS SERVICES-COMMON CARRIER',
  'Satellite Communication',
  'SATELLITE DESCRAMBLING/PROGRAMMING SVC',
  'SATELLITE DISH-MANUFACTURERS',
  'SATELLITE DISH-WHOLESALE',
  'SATELLITE DOWNLINKING',
  'SATELLITE EQUIP & SYSTEMS-SVC & REPAIR',
  'SATELLITE EQUIPMENT & SYSTEMS-MFRS',
  'SATELLITE EQUIPMENT & SYSTEMS-RETAIL',
  'SATELLITE EQUIPMENT & SYSTEMS-WHOLESALE',
  'Satellite Telecommunications',
  'SAUCES (MANUFACTURERS)',
  'SAUERKRAUT (MANUFACTURERS)',
  'SAUNA EQUIPMENT & SUPPLIES',
  'SAUNA EQUIPMENT & SUPPLIES-MANUFACTURERS',
  'SAUNA EQUIPMENT & SUPPLIES-WHOLESALE',
  'SAUSAGE CASINGS (MANUFACTURERS)',
  'SAUSAGE MAKERS',
  'SAUSAGE-MANUFACTURERS',
  'SAUSAGES',
  'SAUSAGES AND OTHER PREPARED MEATS',
  'SAUSAGES-WHOLESALE',
  'SAUSAGES/OTHER PREPARED MEAT PROD (MFRS)',
  'SAVINGS & LOAN ASSNS-REPRESENTATIVES',
  'SAVINGS & LOAN ASSOCIATIONS',
  'SAVINGS INSTITUTIONS',
  'Savings Institutions',
  'SAVINGS INSTITUTIONS-EXCEPT FEDERAL',
  'SAVINGS PLANS',
  'Saw Blade and Handtool Manufacturing',
  'SAW BLADES AND HANDSAWS',
  'SAWDUST & SHAVINGS (WHOLESALE)',
  'SAWMILL CONSULTANTS',
  'SAWMILL EQUIPMENT & SUPPLIES (WHOLESALE)',
  'SAWMILL EQUIPMENT & SUPPLIES-MFRS',
  'Sawmill, Woodworking, and Paper Machinery Manufacturing ',
  'SAWMILLS',
  'Sawmills',
  'SAWMILLS & PLANING MILLS-GENERAL',
  'SAWMILLS AND PLANING MILLS',
  'SAWS',
  'SAWS-MANUFACTURERS',
  'SAWS-RENTING',
  'SAWS-SHARPENING & REPAIRING',
  'SAWS-TIMBER',
  'SCAFFOLDING-RENTING',
  'Scale and Balance Manufacturing',
  'SCALES (WHOLESALE)',
  'SCALES & BALANCES-EX LABORATORY (MFRS)',
  'SCALES AND BALANCES',
  'SCALES-MANUFACTURERS',
  'SCALES-PUBLIC',
  'SCALES-RENTING',
  'SCALES-REPAIRING',
  'SCALES-USED (WHOLESALE)',
  'SCALLOP CUTTING',
  'SCALP TREATMENT',
  'SCANNING EQUIPMENT (WHOLESALE)',
  'SCANNING SERVICE',
  'SCARVES-WEARING APPAREL',
  'SCENERY STUDIOS',
  'Scenic and Sightseeing Transportation, Land',
  'Scenic and Sightseeing Transportation, Other',
  'Scenic and Sightseeing Transportation, Water',
  'Scheduled Freight Air Transportation',
  'Scheduled Passenger Air Transportation',
  'Scheduling',
  'SCHIFFLI MACHINE EMBROIDERIES',
  'SCHIFFLI MACHINE EMBROIDERIES (MFRS)',
  'SCHOLARSHIP PROGRAMS',
  'School and Employee Bus Transportation',
  'SCHOOL BAGS-MANUFACTURERS',
  'SCHOOL BUSES',
  'SCHOOL DISTRICTS-PUBLIC',
  'SCHOOL FURNITURE & EQUIPMENT (WHOL)',
  'SCHOOL FURNITURE & EQUIPMENT-RETAIL',
  'SCHOOL INFORMATION',
  'SCHOOL INSTRUCTIONAL MATERIALS',
  'SCHOOL PSYCHOLOGISTS',
  'SCHOOL SUPPLIES (WHOLESALE)',
  'SCHOOL SUPPLIES-MANUFACTURERS',
  'SCHOOL SUPPLIES-RETAIL',
  'SCHOOLS',
  'SCHOOLS & EDUCATIONAL SERVICES NEC',
  'SCHOOLS AND EDUCATIONAL SERVICES',
  'SCHOOLS FOR BLIND',
  'SCHOOLS FOR THE DEAF',
  'SCHOOLS WITH SPECIAL ACADEMIC EDUCATION',
  'SCHOOLS WITH SPECIAL VOCATIONAL EDUC',
  'SCHOOLS-ACCOUNTING',
  'SCHOOLS-ACROBATIC',
  'SCHOOLS-BUSINESS & SECRETARIAL',
  'SCHOOLS-BUSINESS & VOCATIONAL',
  'SCHOOLS-CHIROPRACTIC',
  'SCHOOLS-COLLEGE BOARD PREPARATION',
  'SCHOOLS-COMPUTERS',
  'SCHOOLS-COOKING',
  'SCHOOLS-ELECTRONICS',
  'SCHOOLS-FASHION',
  'SCHOOLS-GENERAL INTEREST',
  'SCHOOLS-INDUSTRIAL TECHNICAL & TRADE',
  'SCHOOLS-LEGAL SECRETARIAL',
  'SCHOOLS-MANAGEMENT DEVELOPMENT',
  'SCHOOLS-MEDICAL',
  'SCHOOLS-MEDICAL & DENTAL-ASSISTANTS/TECH',
  'SCHOOLS-MEDICAL SECRETARIAL',
  'SCHOOLS-MENTALLY RETARDED',
  'SCHOOLS-NURSERY & KINDERGARTEN ACADEMIC',
  'SCHOOLS-PERFORMING ARTS',
  'SCHOOLS-PREPARATORY',
  'SCHOOLS-SPEECH DEFECT CORRECTION',
  'SCHOOLS-TRAVEL AGENTS',
  'SCHOOLS-UNIVERSITIES & COLLEGES ACADEMIC',
  'Science and Engineering',
  'SCIENCE OF MIND PRACTITIONERS',
  'SCIENTIFIC APPARATUS & INSTRUMENTS-MFRS',
  'SCIENTIFIC APPARATUS & INSTRUMENTS-WHOL',
  'SCIENTIFIC INSTRUMENT DESIGNERS',
  'SCIENTISTS-CONSULTING',
  'SCIENTOLOGISTS',
  'SCOREBOARDS (WHOLESALE)',
  'SCOUT SUPPLIES',
  'SCRAP AND WASTE MATERIALS',
  'SCRAP FILM (MANUFACTURERS)',
  'SCRAP METAL PRODUCTS-MFRS',
  'SCRAP METALS & IRON (WHOLESALE)',
  'SCRAP METALS-PROCESSING/RECYCLING (WHOL)',
  'SCRAP PROCESSING EQUIPMENT (MFRS)',
  'SCRAP RUBBER (WHOLESALE)',
  'SCRAPBOOK ALBUMS & SUPPLIES',
  'SCREEN DOOR & WINDOW-WHOLESALE',
  'SCREEN ENCLOSURES',
  'SCREEN PRINTING',
  'SCREEN PRINTING EQUIPMENT & SUPLS (WHOL)',
  'SCREEN PRINTING-LABEL REMOVAL',
  'SCREENING & SIFTING EQUIPMENT (MFRS)',
  'SCREENS-DECORATIVE',
  'SCREENS-DOOR & WINDOW',
  'SCREENS-DOOR & WINDOW-MANUFACTURERS',
  'SCREENS-MANUFACTURERS',
  "SCREENS-MANUFACTURERS' EQUIP/SUPL (WHOL)",
  'SCREENS-REPAIRING',
  'SCREENS-WHOLESALE',
  'SCREW MACHINE PRODUCTS',
  'SCREW MACHINE PRODUCTS (MANUFACTURERS)',
  'SCREW MACHINES & EQUIPMENT (MFRS)',
  'SCREWS (WHOLESALE)',
  'SCREWS-MANUFACTURERS',
  'SCREWS-RETAIL',
  'SCRIMSHAW',
  'SCRIPT PREPARATION SERVICE',
  'SCUBA DIVING EQUIPMENT-WHOLESALE',
  'SCUBA DIVING TOURS',
  'SCULPTORS',
  'SCULPTORS-ARCHITECTURAL',
  "SCULPTORS' EQUIPMENT & SUPPLIES",
  'SCULPTURES-METAL',
  'SEA WALL CONTRACTORS',
  'Seafood',
  'SEAFOOD PACKERS',
  'Seafood Product Preparation and Packaging',
  'SEAFOOD-RETAIL',
  'SEAFOOD-WHOLESALE',
  'SEALERS-ASPHALT, CONCRETE, ETC (WHOL) ',
  'SEALS-HERMETIC-DISTRIBUTORS',
  'SEALS-HERMETIC-MANUFACTURERS',
  'SEALS-MECHANICAL (WHOLESALE)',
  'SEALS-METAL (MANUFACTURERS)',
  'SEALS-NOTARY & CORP (WHOLESALE)',
  'SEALS-O-RING (WHOLESALE)',
  'SEALS-O-RING-MANUFACTURERS',
  'SEALS-OIL, GREASE, ETC (MANUFACTURERS) ',
  'SEALS-OIL, GREASE, ETC-WHOLESALE ',
  'SEALS-SECURITY (MANUFACTURERS)',
  'SEARCH AND NAVIGATION EQUIPMENT',
  'SEARCH DETECTION/NAV SYSTS/INSTR (MFRS)',
  'Search Engine',
  'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing ',
  'SEARCHERS OF RECORDS',
  'SEARCHLIGHTS (WHOLESALE)',
  'SEAT BELTS',
  'SEAT BELTS-MANUFACTURERS',
  'SEAT BELTS-WHOLESALE',
  'SEATING COMPANIES (WHOLESALE)',
  'SECOND HAND STORES',
  'Secondary Education',
  'Secondary Market Financing',
  'SECONDARY NONFERROUS METALS',
  'SECONDARY SMELTING & REFINING-NONFERROUS',
  'Secondary Smelting and Alloying of Aluminum',
  'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum) ',
  'SECRETARIAL & COURT REPORTING SERVICES',
  'SECRETARIAL AND COURT REPORTING',
  'SECURITIES',
  'Securities and Commodity Exchanges',
  'Securities Brokerage',
  'Security',
  'SECURITY AND COMMODITY EXCHANGES',
  'SECURITY AND COMMODITY SERVICE',
  'Security and Investigations',
  'SECURITY BROKERS AND DEALERS',
  'SECURITY CONTROL EQUIP & SYSTEMS-MFRS',
  'SECURITY CONTROL EQUIP & SYSTEMS-WHOL',
  'SECURITY GUARD & PATROL SERVICE',
  'SECURITY GUARD SCHOOLS',
  'Security Guards and Patrol Services',
  'SECURITY SYSTEMS',
  'SECURITY SYSTEMS CONSULTANTS',
  'SECURITY SYSTEMS SERVICES',
  'Security Systems Services (except Locksmiths)',
  'SECURITY SYSTS-COMMUNICATIONS/COMPUTER',
  'SEED & GRAIN CLEANING',
  'SEED CLEANING EQUIPMENT (WHOLESALE)',
  'SEEDING & FERTILIZING CONTRACTORS',
  'SEEDS & BULBS-RETAIL',
  'SEEDS & BULBS-WHOLESALE',
  'SEEDS-CERTIFIED',
  'SEEDS-CHINESE PRESERVED',
  'SEEDS-COATING (MANUFACTURERS)',
  'SEEDS-WAREHOUSE',
  'SEISMIC DATA BROKERS',
  'SEISMOGRAPH EQUIPMENT & SUPPLIES (MFRS)',
  'SEISMOGRAPH SERVICE',
  'SELF DEFENSE WEAPONS (WHOLESALE)',
  'SELF HELP GROUPS',
  'SELF HELP-BOOKS TAPES ETC',
  'SELF IMPRVMNT/MOTIVATION-TRAINING MTRLS',
  'SELF-ESTEEM PROGRAMS',
  'Self-Storage',
  'SEM',
  'Semantic Search',
  'Semantic Web',
  'Semiconductor',
  'Semiconductor and Related Device Manufacturing',
  'SEMICONDUCTOR DEVICES (MANUFACTURERS)',
  'SEMICONDUCTOR DEVICES-WHOLESALE',
  'Semiconductor Machinery Manufacturing',
  "SEMICONDUCTOR-MFRS' EQUIP/SUPLS (WHOL)",
  'Semiconductors',
  'SEMICONDUCTORS & RELATED DEVICES (MFRS)',
  'SEMICONDUCTORS AND RELATED DEVICES',
  'SEMIVITREOUS TABLE AND KITCHENWARE',
  'SENIOR CITIZENS COUNSELING',
  'SENIOR CITIZENS HOUSING',
  'SENIOR CITIZENS SERVICE',
  'SENIOR CITIZENS SERVICE ORGANIZATIONS',
  'SENIOR CITIZENS SVC ORGS-DISC MERCHANTS',
  'Sensor',
  'SENSORS',
  'SEO',
  'SEPARATORS (MANUFACTURERS)',
  'Septic Tank and Related Services',
  'SEPTIC TANKS',
  'SEPTIC TANKS & SYSTEMS-WHOLESALE',
  'SEPTIC TANKS CONSULTANTS',
  'SEPTIC TANKS-MANUFACTURERS',
  'SEPTIC TANKS-TREATMENT SUPPLIES',
  'SEPTIC TANKS/SYSTEMS-CLEANING/REPAIRING',
  'SEQUINS (MANUFACTURERS)',
  'Serious Games',
  'SERUMS & VACCINES (MANUFACTURERS)',
  'SERVICE BUREAUS',
  'SERVICE ESTABLISHMENT EQUIPMENT',
  'Service Establishment Equipment and Supplies Merchant Wholesalers',
  'Service Industry',
  'SERVICE INDUSTRY MACHINERY',
  'SERVICE INDUSTRY MACHINERY NEC (MFRS)',
  'SERVICE STATION BUILDERS',
  'SERVICE STATION EQUIPMENT (WHOLESALE)',
  'SERVICE STATION EQUIPMENT-INSTALLING',
  'SERVICE STATION EQUIPMENT-MANUFACTURERS',
  'SERVICE STATION EQUIPMENT-SERVICING',
  'SERVICE STATION SUPPLIES (WHOLESALE)',
  'SERVICE STATIONS-GASOLINE & OIL',
  'SERVICES',
  'SERVICES ALLIED TO MOTION PICTURES',
  'SERVICES ALLIED TO MOTION PICTURES',
  'SERVICES ALLIED WITH EXCH OF SEC NEC',
  'Services for the Elderly and Persons with Disabilities',
  'SERVICES NEC',
  'SERVOMECHANISM SYSTEMS & EQUIP (MFRS)',
  'SETTLEMENT COMPANIES',
  'SETTLEMENT HOUSES',
  'SETUP PAPERBOARD BOXES',
  'SEWAGE DISPOSAL SYSTEMS',
  'SEWAGE TREATMENT CONSULTANTS',
  'SEWAGE TREATMENT EQUIPMENT',
  'SEWAGE TREATMENT EQUIPMENT-MANUFACTURERS',
  'Sewage Treatment Facilities',
  'SEWAGE TREATMENT PLANT CONTRACTORS',
  'SEWER & DRAIN CLEANING-SERVICE/REPAIR',
  'SEWER & DRAIN PIPE (WHOLESALE)',
  'SEWER CLEANING EQUIPMENT & SUPLS (WHOL)',
  'SEWER CLEANING EQUIPMENT & SUPPLIES-MFRS',
  'SEWER COMPANIES-UTILITY',
  'SEWER CONSTRUCTION CONSULTANTS',
  'SEWER CONTRACTORS',
  'SEWER PIPE-RETAIL',
  'SEWER SYSTEM INSPECTION/REHABILITATION',
  'SEWER TAPPERS',
  'SEWER THAWING',
  'SEWERAGE SYSTEMS',
  'SEWING',
  'SEWING CABINETS',
  'SEWING CONTRACTORS',
  'SEWING INSTRUCTION',
  'SEWING KITS & CASES (MANUFACTURERS)',
  'SEWING MACHINES PARTS & SUPPLIES-MFRS',
  'SEWING MACHINES PARTS/SUPLS-WHOLESALE',
  'SEWING MACHINES-HOUSEHOLD',
  'SEWING MACHINES-INDUSTRIAL/COML (WHOL)',
  'SEWING MACHINES-MANUFACTURERS',
  'SEWING MACHINES-RENTING',
  'SEWING MACHINES-REPAIRING',
  'SEWING MACHINES-SUPPLIES & ATTACHMENTS',
  'SEWING SHOPS-CUSTOM',
  'Sewing, Needlework, and Piece Goods Stores ',
  'Sex Industry',
  'Sex Tech',
  'SEXUAL ASSAULT & ABUSE CRISIS SERVICES',
  'SEXUAL COUNSELING & THERAPY',
  'SHACKLES (MANUFACTURERS)',
  'SHAFTING (MANUFACTURERS)',
  'Sharing Economy',
  'SHARPENING EQUIPMENT & STONES',
  'SHARPENING SERVICE',
  'SHAVERS-ELECTRIC (WHOLESALE)',
  'SHAVERS-ELECTRIC-REPAIRING',
  'SHEARS & SCISSORS',
  'SHEARS-MANUFACTURERS',
  'SHEAVES (MANUFACTURERS)',
  'SHEDS-TOOL & UTILITY',
  'SHEEP & GOATS',
  'SHEEP AND GOATS',
  'Sheep Farming',
  'SHEEP SHEARING',
  'SHEEPSKIN SPECIALTIES',
  'SHEEPSKIN SPECIALTIES-MANUFACTURERS',
  'SHEEPSKINS',
  'SHEET METAL FABRICATORS',
  'SHEET METAL WORK CONTRACTORS',
  'Sheet Metal Work Manufacturing',
  'SHEET METAL WORK-WHOLESALE',
  'SHEET METAL WORKERS',
  'SHEET METAL WORKING EQUIP & SUPLS (WHOL)',
  'SHEET METALWORK',
  'SHELF REACHERS (WHOLESALE)',
  'SHELLAC-MANUFACTURERS',
  'SHELLFISH',
  'SHELLFISH',
  'Shellfish Farming',
  'Shellfish Fishing',
  'SHELLS-DECORATIVE',
  'SHELLS-DECORATIVE-MANUFACTURERS',
  'SHELLS-MARINE (MANUFACTURERS)',
  'SHELLS-MARINE-WHOLESALE',
  'SHELTERED CARE HOMES',
  'SHELTERED WORKSHOPS',
  'SHELTERS',
  'SHELTERS FOR BATTERED WOMEN',
  'SHELVING (WHOLESALE)',
  'SHELVING-COMMERCIAL & INDUSTRIAL (WHOL)',
  'SHELVING-HOUSEHOLD & DECORATIVE',
  'SHELVING-MANUFACTURERS',
  'SHERIFF',
  'SHIATSU THERAPY',
  'SHIELDING-MAGNETIC ELECTROSTATIC (MFRS)',
  'SHIMS (MANUFACTURERS)',
  'SHINGLE STAIN',
  'SHINGLES & SHAKES',
  'SHINGLES & SHAKES-MANUFACTURERS',
  'SHINGLES-WHOLESALE',
  'SHIP BROKERS',
  'SHIP BUILDERS & REPAIRERS',
  'Ship Building and Repairing',
  'SHIP BUILDING EQUIP & SUPPLIES (MFRS)',
  'SHIP CEILING (MANUFACTURERS)',
  'SHIP CHANDLERS (WHOLESALE)',
  'SHIP CLEANING',
  'SHIP FUELING',
  'SHIP JOINERS',
  'SHIP MOORING LINE SERVICE',
  'SHIP OWNERS & OPERATORS',
  'SHIP REGISTERS',
  'SHIP WRECKERS',
  'SHIP YARDS',
  'Shipbuilding',
  'SHIPBUILDING AND REPAIRING',
  'SHIPPERS COOPERATIVE ORGANIZATIONS',
  'Shipping',
  'SHIPPING AGENTS',
  'Shipping Broker',
  'SHIPPING CONSULTANTS',
  'SHIPPING MASTERS',
  'SHIPPING ROOM SUPPLIES (WHOLESALE)',
  'SHIPPING SERVICE SUPPLIES (WHOLESALE)',
  'SHIRTINGS (WHOLESALE)',
  'SHIRTS-CUSTOM MADE',
  'SHIRTS-MANUFACTURERS',
  'SHIRTS-REPAIRING',
  'SHIRTS-RETAIL',
  'SHIRTS-WHOLESALE',
  'SHOCK ABSORBERS',
  'SHOCK ABSORBERS-MANUFACTURERS',
  'SHOCK ABSORBERS-WHOLESALE',
  'SHOE & BOOT REPAIRING',
  'SHOE BAGS (MANUFACTURERS)',
  'SHOE CONTRACT STITCHING',
  'SHOE COUNTERS (MANUFACTURERS)',
  'SHOE COVERS-MANUFACTURERS',
  'SHOE DYERS',
  'SHOE FABRICS (WHOLESALE)',
  'SHOE FILLERS (MANUFACTURERS)',
  'SHOE FINDINGS & SUPPLIES (WHOLESALE)',
  'SHOE FINDINGS & SUPPLIES-MANUFACTURERS',
  'SHOE LACES',
  'SHOE LININGS (MANUFACTURERS)',
  'SHOE MACHINERY-MANUFACTURERS',
  'SHOE ORNAMENTS',
  'SHOE PATTERNS (MANUFACTURERS)',
  'SHOE REPAIR AND SHOESHINE PARLORS',
  'SHOE REPAIRING EQUIPMENT & SUPLS (WHOL)',
  'SHOE SHINING',
  'SHOE SHINING EQUIPMENT & SUPPLIES',
  'SHOE STORE SUPPLIES',
  'SHOE STORES',
  'Shoe Stores',
  'SHOE TREES (MANUFACTURERS)',
  'Shoes',
  'SHOES-CUSTOM MADE',
  'SHOES-MANUFACTURERS',
  'SHOES-MANUFACTURERS SUPPLIES (MFRS)',
  'SHOES-MANUFACTURERS SUPPLIES-WHOLESALE',
  'SHOES-ORTHOPEDIC',
  'SHOES-ORTHOPEDIC-MANUFACTURERS',
  'SHOES-ORTHOPEDIC-REPAIRING',
  'SHOES-OUTDOORS',
  'SHOES-RETAIL',
  'SHOES-SAFETY',
  'SHOES-USED',
  'SHOES-WHOLESALE',
  'SHOOK-MANUFACTURERS',
  'SHOOKS (MANUFACTURERS)',
  'SHOPLIFTING PREVENTION DEVICES',
  "SHOPPER'S INFORMATION SERVICE",
  "SHOPPERS' NEWS PUBLICATIONS",
  'Shopping',
  'SHOPPING CARTS & BASKETS-WHOLESALE',
  'SHOPPING CARTS, BASKETS, & BAGS-MFRS ',
  'SHOPPING CENTER DEVELOPERS',
  'SHOPPING CENTERS & MALLS',
  'Shopping Mall',
  'SHOPPING SERVICE-BUSINESS',
  'SHOPPING SERVICE-PERSONAL',
  'SHORES-ADJUSTABLE',
  'SHORING',
  'Short Line Railroads',
  'SHORT-TERM BUSINESS CREDIT',
  'SHORTENING (MANUFACTURERS)',
  'SHORTENING TBL OILS MARGARINE ETC (MFR)',
  'SHOT PEENING (MANUFACTURERS)',
  'SHOULDER PADS (MANUFACTURERS)',
  'SHOULDER STRAPS (MANUFACTURERS)',
  'SHOVELS-POWER (MANUFACTURERS)',
  'SHOW CARDS',
  'SHOW CASES (WHOLESALE)',
  'SHOW CASES-MFRS',
  'Showcase, Partition, Shelving, and Locker Manufacturing ',
  'SHOWER CURTAINS (WHOLESALE)',
  'SHOWER DOORS & ENCLOSURES',
  'SHOWER DOORS & ENCLOSURES-MANUFACTURERS',
  'SHOWER DOORS & ENCLOSURES-WHOLESALE',
  'SHOWERS-PORTABLE',
  'SHOWROOM EQUIP & SUPPLIES (WHOLESALE)',
  'SHREDDERS-MANUFACTURERS',
  'SHRIMP-AQUACULTURE',
  'SHRIMP-PACKERS',
  'SHRIMP-RETAIL',
  'SHRIMP-WHOLESALE',
  'SHUFFLEBOARDS',
  'SHUTTERS',
  'SHUTTERS-MANUFACTURERS',
  'SHUTTERS-WHOLESALE',
  'SHUTTLE SERVICE',
  'SIDEWALK CONTRACTORS',
  'SIDEWALK-DOORS & LIGHTS (MANUFACTURERS)',
  'SIDING CONTRACTORS',
  'Siding Contractors',
  'SIDING MATERIALS',
  'SIDING MATERIALS-MANUFACTURERS',
  'SIDING MATERIALS-WHOLESALE',
  'SIGHTSEEING TOURS',
  'SIGN CONTRACTORS',
  'Sign Manufacturing',
  'SIGN PAINTERS',
  'SIGNAL SYSTEMS (WHOLESALE)',
  'SIGNALS-MANUFACTURERS',
  'SIGNS (MANUFACTURERS)',
  'SIGNS & ADVERTISING SPECIALTIES (MFRS)',
  'SIGNS AND ADVERTISING SPECIALTIES',
  'SIGNS-EQUIPMENT & SUPPLIES (WHOLESALE)',
  'SIGNS-ERECTORS & HANGERS',
  'SIGNS-MACHINES & SUPPLIES (WHOLESALE)',
  'SIGNS-MAINTENANCE & REPAIRING',
  'SIGNS-NEON (MANUFACTURERS)',
  'SIGNS-RENTING',
  'SIGNS-WHOLESALE',
  'SILENCERS-INDUSTRIAL',
  'SILICA',
  'SILICA GEL (MANUFACTURERS)',
  'SILICON (MANUFACTURERS)',
  'SILICONE PRODUCTS (WHOLESALE)',
  'SILICONES (WHOLESALE)',
  'SILK SCREEN PRINTING',
  'SILK WARPERS (MANUFACTURERS)',
  'SILKS-MANUFACTURERS',
  'SILKS-WHOLESALE',
  'SILOS',
  'SILOS-EQUIPMENT & SUPPLIES (WHOLESALE)',
  'Silver Ore Mining',
  'SILVER ORES',
  'SILVER ORES',
  'SILVER RECOVERY',
  'SILVERWARE',
  'SILVERWARE AND PLATED WARE',
  'SILVERWARE CLEANING/REPAIRING/REPLATING',
  'SILVERWARE CONTAINERS (MANUFACTURERS)',
  'SILVERWARE-MANUFACTURERS',
  'SILVERWARE-PLTD/STAINLESS STL WARE (MFR)',
  'SILVERWARE-RENTAL',
  'SILVERWARE-USED',
  'SILVERWARE-WHOLESALE',
  'Simulation',
  'SIMULATORS',
  'SIMULATORS & TRAINING DEVICES-MFRS',
  'SINGING TELEGRAMS',
  'SINGLE-FAMILY HOUSING CONSTRUCTION',
  'SINGLES ORGANIZATIONS',
  'SINKS & SINK TOPS-WHOLESALE',
  'SIRENS & HORNS (WHOLESALE)',
  'SISAL',
  'SITE DEVELOPMENT CONTRACTORS',
  'SITE PLANNERS',
  'Site Preparation Contractors',
  'SITTING SERVICES',
  'SKATE SHARPENING',
  'SKATEBOARD PARKS & RINKS',
  'SKATEBOARDS & EQUIPMENT',
  'SKATEBOARDS & EQUIPMENT-MANUFACTURERS',
  'SKATEBOARDS & EQUIPMENT-WHOLESALE',
  'SKATING APPAREL',
  'SKATING EQUIPMENT & SUPPLIES',
  'SKATING EQUIPMENT & SUPPLIES-MFRS',
  'SKATING EQUIPMENT & SUPPLIES-WHOLESALE',
  'SKATING INSTRUCTION',
  'SKATING RINKS',
  'SKATING RINKS-INSTALLATION, EQUIP/SUPLS ',
  'SKI & SNOW REPORTS',
  'SKI APPAREL',
  'SKI LIFT & SKI TOW EQUIP & SUPLS (WHOL)',
  'Skiing',
  'SKIING CENTERS & RESORTS',
  'SKIING EQUIPMENT-MANUFACTURERS',
  'SKIING EQUIPMENT-RENTAL',
  'SKIING EQUIPMENT-REPAIRING',
  'SKIING EQUIPMENT-RETAIL',
  'SKIING EQUIPMENT-WHOLESALE',
  'Skiing Facilities',
  'SKIING INSTRUCTION',
  'SKIING ORGANIZATIONS',
  'SKIING TOURS',
  'Skill Assessment',
  'SKILLED NURSING CARE FACILITIES',
  'SKILLED NURSING CARE FACILITIES',
  'SKIN & SCUBA DIVING EQUIPMENT-MFRS',
  'SKIN DIVING EQUIPMENT',
  'SKIN TREATMENTS',
  'SKIP TRACING',
  'SKYDIVING & PARACHUTE JUMPING INSTRCTNS',
  'SKYLIGHTS',
  'SKYLIGHTS-MANUFACTURERS',
  'SKYLIGHTS-WHOLESALE',
  'SLABJACKING CONTRACTORS',
  'SLAG (MANUFACTURERS)',
  'SLATE (WHOLESALE)',
  'SLEDS (MANUFACTURERS)',
  'SLEEP TEACHING',
  'SLEEPING BAGS-MANUFACTURERS',
  'SLEEPING BAGS-WHOLESALE',
  'SLICING MACHINES (WHOLESALE)',
  'SLIDE RULES (WHOLESALE)',
  'SLIDE RULES-WHOLESALE',
  'SLIDES & FILM STRIPS',
  'SLIDES & FILM STRIPS-WHOLESALE',
  'SLIDES COMPUTER GRAPHICS ETC',
  'SLINGS (MANUFACTURERS)',
  'SLIP COVERS',
  'SLIP COVERS-MANUFACTURERS',
  'SLIP COVERS-WHOLESALE',
  'SLIPPERS',
  'SLIPPERS-MANUFACTURERS',
  'SLIPPERS-WHOLESALE',
  'SLITTING MACHINES (MANUFACTURERS)',
  'SLOT CAR RACE COURSES',
  'SLOT MACHINES-SALES & SERVICE',
  'SLOTTED ANGLE MATERIALS',
  'SLUSH MACHINES (WHOLESALE)',
  'Small and Medium Businesses',
  'SMALL ARMS',
  'SMALL ARMS AMMUNITION',
  'SMALL ARMS AMMUNITION (MANUFACTURERS)',
  'Small Arms Ammunition Manufacturing',
  'SMALL ARMS-MANUFACTURERS',
  'Small Arms, Ordnance, and Ordnance Accessories Manufacturing ',
  'SMALL CLAIMS ASSISTANCE',
  'Small Electrical Appliance Manufacturing',
  'Smart Building',
  'Smart Cities',
  'Smart Home',
  'SMELTERS & REFINERS EQUIP/SUPLS (WHOL)',
  'SMELTERS & REFINERS-BASE METALS',
  'SMELTERS & REFINERS-PRECIOUS METALS',
  'SMOCKING',
  'SMOCKS-MANUFACTURERS',
  'SMOKE ABATEMENT EQUIPMENT',
  'SMOKE ODOR COUNTERACTING',
  'SMOKE STACKS (MANUFACTURERS)',
  'SMOKED FOODS',
  'SMOKERS INFORMATION & TREATMENT CENTERS',
  'SMOKING & CURING SERVICE',
  'SMS',
  'Snack and Nonalcoholic Beverage Bars',
  'Snack Food',
  'SNACK PRODUCTS',
  'SNORING TREATMENT & DEVICES',
  'SNORKELING',
  'SNOW BALL MACHINES (WHOLESALE)',
  'SNOW BALL MACHINES EQUIP/SUPPLIES (MFRS)',
  'SNOW MAKING MACHINES (MANUFACTURERS)',
  'SNOW MELTING SYSTEMS',
  'SNOW REMOVAL EQUIPMENT-RETAIL',
  'SNOW REMOVAL EQUIPMENT-WHOLESALE',
  'SNOW REMOVAL SERVICE',
  'SNOWBOARDS-RETAIL',
  'SNOWBOARDS-WHOLESALE & MFRS',
  'SNOWCAT RENTALS',
  'SNOWMOBILE CLUBS',
  'SNOWMOBILE PARTS & ACCESSORIES-RETAIL',
  'SNOWMOBILE PARTS & ACCESSORIES-WHOLESALE',
  'SNOWMOBILE-EQUIPMENT/SUPPLIES-MFRS',
  'SNOWMOBILE-RENTING & LEASING',
  'SNOWMOBILE-REPAIRING & MAINTENANCE',
  'SNOWMOBILES',
  'SNOWSHOES',
  'SNOWSHOES-MANUFACTURERS',
  'SNS',
  'SNUFF (WHOLESALE)',
  'Soap and Other Detergent Manufacturing',
  'SOAP AND OTHER DETERGENTS',
  'SOAP DISPENSERS (WHOLESALE)',
  'SOAPS & DETERGENTS (WHOLESALE)',
  'SOAPS & DETERGENTS-MANUFACTURERS',
  'Soccer',
  'SOCCER CLUBS',
  'SOCCER EQUIPMENT & SUPPLIES',
  'Social',
  'Social Assistance',
  'Social Bookmarking',
  'Social CRM',
  'Social Entrepreneurship',
  'Social Impact',
  'Social Media',
  'Social Media Advertising',
  'Social Media Management',
  'Social Media Marketing',
  'Social Network',
  'Social News',
  'Social Recruiting',
  'SOCIAL RESEARCH CONSULTANTS',
  'SOCIAL SECURITY COUNSELORS & REPS',
  'SOCIAL SERVICE & WELFARE ORGANIZATIONS',
  'SOCIAL SERVICES',
  'SOCIAL SERVICES INFO & REFERRAL PROGRAMS',
  'SOCIAL SERVICES NEC',
  'SOCIAL SETTLEMENTS',
  'Social Shopping',
  'SOCIAL WORKERS',
  'SOCIAL WORKERS-CLINICAL',
  'SOCKS-RETAIL',
  'SOD & SODDING SERVICE',
  'SOD & SODDING SERVICE-WHOLESALE',
  'SOD CUTTING MACHINES',
  'SODA ASH (MANUFACTURERS)',
  'SODA BICARBONATE (MANUFACTURERS)',
  'SODA FOUNTAIN EQUIP-REPAIRING (WHOL)',
  'SODA FOUNTAIN EQUIPMENT (WHOLESALE)',
  'SODA FOUNTAIN SHOPS',
  'SODA FOUNTAIN SUPPLIES (WHOLESALE)',
  'SODA FOUNTAINS-RESIDENTIAL (MFRS)',
  'SODA WATER-MANUFACTURERS',
  'SODIUM SILICATE (WHOLESALE)',
  'Soft Drink Manufacturing',
  'SOFT DRINK PRODUCTS-MANUFACTURERS',
  'SOFT DRINKS (WHOLESALE)',
  'SOFTBALL CLUBS',
  'Software',
  'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing ',
  'Software Engineering',
  'Software Publishers',
  'SOFTWOOD VENEER & PLYWOOD (MFRS)',
  'SOFTWOOD VENEER AND PLYWOOD',
  'Softwood Veneer and Plywood Manufacturing',
  'SOIL',
  'SOIL ANALYSIS',
  'SOIL CONDITIONERS (WHOLESALE)',
  'SOIL CONSERVATION SERVICE',
  'SOIL CONSULTANTS',
  'SOIL PREPARATION SERVICES',
  'Soil Preparation, Planting, and Cultivating ',
  'SOIL SOLIDIFICATION',
  'SOIL STABILIZATION',
  'SOIL TESTING',
  'SOIL TESTING EQUIPMENT (WHOLESALE)',
  'SOILS-POTTING',
  'SOILS-POTTING-WHOLESALE',
  'Solar',
  'Solar Electric Power Generation',
  'SOLAR ENERGY EQPT/SYST-SUPL/PARTS (WHOL)',
  'SOLAR ENERGY EQUIP & SYSTEMS-SVC & RPR',
  'SOLAR ENERGY EQUIPMENT-MANUFACTURERS',
  'SOLAR ENERGY EQUIPMENT-WHOLESALE',
  'SOLAR ENERGY RESEARCH & DEVELOPMENT',
  'SOLAR HEATING CONSULTANTS',
  'SOLAR HEATING CONTRACTORS',
  'SOLAR HEATING SYSTEMS',
  'SOLAR ROOMS & ADDITIONS',
  'SOLARIUMS SUNROOMS & ATRIUMS',
  'SOLDER (MANUFACTURERS)',
  'SOLDERING EQUIPMENT (WHOLESALE)',
  'SOLDERING FLUXES (MANUFACTURERS)',
  'SOLDERING SERVICE',
  'SOLENOIDS (WHOLESALE)',
  'SOLID SURFACE MATERIALS',
  'Solid Waste Collection',
  'Solid Waste Combustors and Incinerators',
  'Solid Waste Landfill',
  'SOLVENTS (WHOLESALE)',
  'SOLVENTS-MANUFACTURERS',
  'SONAGRAM & ULTRASOUND DIAGNOSIS',
  'SORORITIES',
  'SORTING EQUIPMENT',
  'SOUND CONTROL STRUCTURES & EQUIPMENT',
  'SOUND EFFECT LIBRARIES',
  'SOUND EFFECT RECORDS',
  'Sound Recording Studios',
  'SOUND SYSTEM CONSULTANTS',
  'SOUND SYSTEMS & EQUIPMENT (WHOLESALE)',
  'SOUND SYSTEMS & EQUIPMENT-MANUFACTURERS',
  'SOUND SYSTEMS & EQUIPMENT-RENTING',
  'SOUND SYSTEMS & EQUIPMENT-REPAIRING',
  'SOUP KITCHENS',
  'SOUP-MANUFACTURERS',
  'SOUVENIRS-MANUFACTURERS',
  'SOUVENIRS-RETAIL',
  'SOUVENIRS-WHOLESALE',
  'SOY-MANUFACTURERS',
  'Soybean and Other Oilseed Processing',
  'Soybean Farming',
  'SOYBEAN OIL MILLS',
  'SOYBEAN OIL MILLS',
  'SOYBEAN PRODUCTS (WHOLESALE)',
  'SOYBEANS',
  'SOYBEANS',
  'SOYFOODS',
  'SPACE COMPONENTS & SYSTEMS (MFRS)',
  'SPACE PLANNERS',
  'SPACE PROPULSION UNITS AND PARTS',
  'SPACE RESEARCH & DEVELOPMENT',
  'SPACE RESEARCH AND TECHNOLOGY',
  'Space Research and Technology',
  'Space Travel',
  'SPACE VEHICLE EQUIPMENT',
  'Spam Filtering',
  'SPARK ARRESTORS (WHOLESALE)',
  'SPARK PLUGS',
  'SPAS (HEALTH)',
  'SPAS-BEAUTY & DAY',
  'SPAS-BUILDERS',
  'SPEAKERS-DEALERS',
  'SPEAKERS-MANUFACTURERS',
  'SPEAKERS-REBUILDING & REPAIRING',
  'SPEAKERS-WHOLESALE',
  'SPEAKING-VOICE IMPROVEMENT',
  'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing ',
  'SPECIAL DIES AND FIXTURES',
  'SPECIAL DIES/TOOLS FXTRS/IND MOLDS (MFR)',
  'SPECIAL INDUSTRY MACHINERY',
  'SPECIAL INDUSTRY MACHINERY NEC (MFRS)',
  'SPECIAL INTEREST LIBRARIES',
  'Special Needs Transportation',
  'SPECIAL PRODUCT SAWMILLS',
  'SPECIAL PRODUCT SAWMILLS NEC',
  'SPECIAL TRADE CONTRACTORS',
  'SPECIAL WAREHOUSING & STORAGE NEC',
  'SPECIAL WAREHOUSING AND STORAGE',
  'Specialized Freight (except Used Goods) Trucking, Local ',
  'Specialized Freight (except Used Goods) Trucking, Long-Distance ',
  'Specialty (except Psychiatric and Substance Abuse) Hospitals',
  'Specialty Canning',
  'SPECIALTY CLNG PLSHNG/SANITATION (MFR)',
  'SPECIALTY HOSPITALS',
  'SPECIALTY HOSPITALS-EXCEPT PSYCHIATRIC',
  'SPECIALTY OUTPATIENT CLINICS',
  'SPECIALTY OUTPATIENT CLINICS NEC',
  'SPECIFICATIONS CONSULTANTS',
  'SPEECH & HEARING THERAPY',
  'SPEECH & LANGUAGE PATHOLOGY INFO SVC',
  'SPEECH PATHOLOGISTS',
  'Speech Recognition',
  'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing ',
  'SPEED CHANGERS',
  'SPEED CHANGERS-IND-DRIVES & GEARS (MFRS)',
  'SPEED REDUCERS (MANUFACTURERS)',
  'SPEEDOMETERS (WHOLESALE)',
  'SPEEDOMETERS-REPAIRING',
  'SPERM BANKS',
  'Spice and Extract Manufacturing',
  'SPICES',
  'SPICES-MANUFACTURERS',
  'SPICES-WHOLESALE',
  'SPILL CONTROL EQUIP & MATERIALS (MFRS)',
  'SPINDLES (MANUFACTURERS)',
  'SPINNING & SPINNING WHEEL SUPLS (WHOL)',
  'SPINOLOGISTS',
  'SPIRITUALISTS',
  'SPONGES-MANUFACTURERS',
  'SPONGES-RETAIL',
  'SPONGES-WHOLESALE',
  'Sponsorship',
  'SPOOLS (MANUFACTURERS)',
  'SPORTING AND ATHLETIC GOODS',
  'Sporting and Athletic Goods Manufacturing',
  'SPORTING AND RECREATION GOODS',
  'SPORTING AND RECREATIONAL CAMPS',
  'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
  'SPORTING CLAYS',
  'Sporting Goods',
  'SPORTING GOODS AND BICYCLE SHOPS',
  'Sporting Goods Stores',
  'SPORTING GOODS-MANUFACTURERS',
  'SPORTING GOODS-REPAIRING',
  'SPORTING GOODS-RETAIL',
  'SPORTING GOODS-USED',
  'SPORTING GOODS-WHOLESALE',
  'Sports',
  'SPORTS ADVISORY SERVICE',
  'Sports and Recreation Instruction',
  'SPORTS CLUBS',
  'SPORTS CLUBS',
  'SPORTS CONSULTANTS',
  'SPORTS INFORMATION',
  'SPORTS MEDICINE & INJURIES',
  'SPORTS PROMOTERS MANAGERS & RECRUITERS',
  'SPORTS TEAMS',
  'Sports Teams and Clubs',
  'SPORTS-MOTIVATIONAL TRAINING',
  'SPORTS-TUTORING',
  'SPORTSWEAR-MENS-MANUFACTURERS',
  'SPORTSWEAR-RETAIL',
  'SPORTSWEAR-WHOLESALE',
  'SPORTSWEAR-WOMENS-MANUFACTURERS',
  'SPOUTS-POURING (MANUFACTURERS)',
  'SPRAY BOOTHS (WHOLESALE)',
  'SPRAY NOZZLES-INDUSTRIAL (MANUFACTURERS)',
  'SPRAY PAINTING & FINISHING',
  'SPRAYING EQUIPMENT-MANUFACTURERS',
  'SPRAYING EQUIPMENT-RENTING',
  'SPRAYING EQUIPMENT-WHOLESALE',
  'SPRAYING-HORTICULTURAL',
  'SPRAYING-INSECT CONTROL',
  'Spring Manufacturing',
  'SPRINGS-AUTO-SALES & SVC-MANUFACTURERS',
  'SPRINGS-AUTO-SALES & SVC-WHOLESALE',
  'SPRINGS-AUTOMOTIVE-SALES & SERVICE',
  'SPRINGS-COIL, FLAT, ETC-DISTRIBUTORS ',
  'SPRINGS-MANUFACTURERS',
  'SPRINGS-PRECISION (MANUFACTURERS)',
  'SPRINKLER ALARM SYSTEMS (WHOLESALE)',
  'SPRINKLER SUPERVISORY SERVICE',
  'SPRINKLERS IRRIGATION DESIGNERS',
  'SPRINKLERS-AUTOMATIC-FIRE (WHOLESALE)',
  'SPRINKLERS-AUTOMATIC-FIRE-MANUFACTURERS',
  'SPRINKLERS-GARDEN & LAWN-MANUFACTURERS',
  'SPRINKLERS-GARDEN & LAWN-PARTS/SUPPLIES',
  'SPRINKLERS-GARDEN & LAWN-RETAIL',
  'SPRINKLERS-GARDEN & LAWN-WHOLESALE',
  'SPROCKETS (WHOLESALE)',
  'SQUARE DANCE APPAREL',
  'SQUARE DANCE CALLERS',
  'SQUARE DANCING',
  'SQUASH COURTS-PRIVATE',
  'SQUASH COURTS-PUBLIC',
  'SQUASH EQUIPMENT & SUPLS-RETAIL',
  'SQUEEGEES (MANUFACTURERS)',
  'STABILIZERS',
  'STABLES',
  'STADIUMS ARENAS & ATHLETIC FIELDS',
  'Staffing Agency',
  'Staffing and Recruiting',
  'STAGE LIGHTING CONSULTANTS',
  'STAGE LIGHTING EQUIPMENT (MANUFACTURERS)',
  'STAGES',
  'STAIN REPELLANTS (MANUFACTURERS)',
  'STAINLESS STEEL (MANUFACTURERS)',
  'STAINPROOFING',
  'STAINS',
  'STAIR BUILDERS',
  'STAIR CUSHIONS (MANUFACTURERS)',
  'STAIR EQUIPMENT (MANUFACTURERS)',
  'STAIR TREADS',
  'STAIRS (MANUFACTURERS)',
  'STAIRS-DISAPPEARING (MANUFACTURERS)',
  'STAIRS-IRON & STEEL (MANUFACTURERS)',
  'STAIRS-RESURFACING',
  'STAKES (WHOLESALE)',
  'STAMP DISPENSING MACHINES',
  'STAMP PADS (MANUFACTURERS)',
  'STAMPING',
  'STAMPING-COLD, GOLD, HOT, ETC (MFRS) ',
  'STAMPINGS-NON-METALLIC (MANUFACTURERS)',
  'STAMPS (TOOL & DIE) (MANUFACTURERS)',
  'STAMPS (WHOLESALE)',
  'STAMPS FOR COLLECTORS',
  'STAMPS-MANUFACTURERS',
  'STANCHIONS (WHOLESALE)',
  'STAPLES STAPLERS & TACKERS (WHOLESALE)',
  'STAPLES STAPLERS & TACKERS-MANUFACTURERS',
  'STARCH (MANUFACTURERS)',
  'STARTERS-AUTOMOTIVE-REPAIRING',
  'STARTERS-ENGINE',
  'STARTERS-ENGINE-MANUFACTURERS',
  'STARTERS-ENGINE-WHOLESALE',
  'STATE COMMERCIAL BANKS',
  'STATE COMMERCIAL BANKS',
  'STATE CREDIT UNIONS',
  'STATE GOVERNMENT-AGRICULTURAL PROGRAMS',
  'STATE GOVERNMENT-CONSERVATION DEPTS',
  'STATE GOVERNMENT-COURTS',
  'STATE GOVERNMENT-ECONOMIC PROGRAM ADM',
  'STATE GOVERNMENT-EDUCATION PROGRAMS',
  'STATE GOVERNMENT-ENVIRONMENTAL PROGRAMS',
  'STATE GOVERNMENT-EXECUTIVE OFFICES',
  'STATE GOVERNMENT-FINANCE & TAXATION',
  'STATE GOVERNMENT-FIRE PROTECTION',
  'STATE GOVERNMENT-GENERAL OFFICES',
  'STATE GOVERNMENT-HOUSING PROGRAMS',
  'STATE GOVERNMENT-INTERNATIONAL AFFAIRS',
  'STATE GOVERNMENT-LEGAL COUNSEL',
  'STATE GOVERNMENT-LIBRARIES',
  'STATE GOVERNMENT-LICENSING & INSPECTION',
  'STATE GOVERNMENT-NATIONAL SECURITY',
  'STATE GOVERNMENT-POLICE',
  'STATE GOVERNMENT-PUBLIC HEALTH PROGRAMS',
  'STATE GOVERNMENT-PUBLIC ORDER & SAFETY',
  'STATE GOVERNMENT-SOCIAL/HUMAN RESOURCES',
  'STATE GOVERNMENT-TRANSPORTATION PROGRAMS',
  'STATE GOVERNMENT-VETERANS AFFAIRS ADMIN',
  'STATE GOVT-CORRECTIONAL INSTITUTIONS',
  'STATE GOVT-REG & ADM-COMMS & UTILITIES',
  'STATE GOVT-SPACE RESEARCH & TECHNOLOGY',
  'STATE GOVT-URBAN PLANNING & DEVELOPMENT',
  'STATIC CONTROLS (MANUFACTURERS)',
  'STATIONERS-RETAIL',
  'STATIONERY AND OFFICE SUPPLIES',
  'Stationery and Office Supplies Merchant Wholesalers',
  'Stationery Product Manufacturing',
  'STATIONERY PRODUCTS',
  'STATIONERY STORES',
  'STATIONERY-COMMERCIAL (WHOLESALE)',
  'STATIONERY-MANUFACTURERS',
  'STATIONERY-TABLETS/RELATED PRODS (MFRS)',
  'STATIONERY-WHOLESALE',
  'STATISTICAL SERVICE',
  'STATUARY',
  'STATUARY-MANUFACTURERS SUPPLIES (WHOL)',
  'STAVES & STAVE MILLS',
  'STEAM & AIR CONDITIONING SUPPLY',
  'STEAM AND AIR-CONDITIONING SUPPLY',
  'Steam and Air-Conditioning Supply',
  'STEAM BATH EQUIPMENT & SUPLS (WHOLESALE)',
  'STEAM CLEANING EQUIPMENT (WHOLESALE)',
  'STEAM CLEANING EQUIPMENT-MANUFACTURERS',
  'STEAM CLEANING EQUIPMENT-RENTING',
  'STEAM CLEANING-AUTOMOTIVE',
  'STEAM CLEANING-INDUSTRIAL',
  'STEAM CLEANING-RESIDENTIAL',
  'STEAM ENERGY COMPANIES',
  'STEAM FITTERS',
  "STEAM FITTERS' SUPPLIES (WHOLESALE)",
  'STEAM SPECIALTIES (WHOLESALE)',
  'STEAM THAWING CONTRACTORS',
  'STEAM TRAPS (WHOLESALE)',
  'STEAMSHIP AGENCIES',
  'STEAMSHIP COMPANIES',
  'STEAMSHIP CONFERENCES',
  'STEAMSHIP TERMINAL COMPANIES',
  'STEEL & BRASS STAMPS (MANUFACTURERS)',
  'STEEL ALLOY (WHOLESALE)',
  'STEEL BROKERS',
  'STEEL DETAILERS-REINFORCING',
  'STEEL DETAILERS-STRUCTURAL',
  'STEEL ERECTORS',
  'STEEL FABRICATORS',
  'STEEL FOUNDRIES',
  'Steel Foundries (except Investment)',
  'STEEL INVESTMENT FOUNDRIES',
  'Steel Investment Foundries',
  'STEEL JOIST (MANUFACTURERS)',
  'STEEL MILL SERVICES',
  'STEEL MILLS',
  'STEEL PIPE & TUBES (MANUFACTURERS)',
  'STEEL PIPE AND TUBES',
  'STEEL PLATE WORK',
  'STEEL PROCESSING',
  'STEEL PROCESSING/FABRICATING EQUIP (MFR)',
  'STEEL PROCESSING/FABRICATING EQUIP-WHOL',
  'STEEL PRODUCTS (WHOLESALE)',
  'STEEL REINFORCING',
  'STEEL REINFORCING SUPPLIES (MFRS)',
  'STEEL RULE DIES (WHOLESALE)',
  'STEEL SPRING-EXCEPT WIRE (MANUFACTURERS)',
  'STEEL SPRINGS',
  'STEEL STAMPS (MANUFACTURERS)',
  'STEEL STRAIGHTENING (MANUFACTURERS)',
  'STEEL STRAPPING (MANUFACTURERS)',
  'STEEL WIRE AND RELATED PRODUCTS',
  'Steel Wire Drawing',
  'STEEL WOOL (MANUFACTURERS)',
  'STEEL WORKS/BLAST FURNACES/ROLLING MLS',
  'STEEL-BAR, SHEET, STRIP, TUBE ETC (MFRS) ',
  'STEEL-CABLE (MANUFACTURERS)',
  'STEEL-DISTRIBUTORS & WAREHOUSES',
  'STEEL-STRUCTURAL (MANUFACTURERS)',
  'STEEL-TOOL (MANUFACTURERS)',
  'STEEL-USED (WHOLESALE)',
  'STEEL-WIREDRAWING/STL NAILS/SPIKES (MFR)',
  'STEEPLE JACKS',
  'STEEPLES & SPIRES',
  'STEERING SYSTEMS & EQUIPMENT',
  'STEERING SYSTEMS & EQUIPMENT-MFRS',
  'STEERING SYSTEMS & EQUIPMENT-REPAIRING',
  'STEM Education',
  'STENCIL CUTTING MACHINES (WHOLESALE)',
  'STENCIL-DECORATIVE',
  'STENCILING',
  'STENCILS & STENCIL SUPPLIES (WHOLESALE)',
  'STENCILS & STENCIL SUPPLIES-MFRS',
  'STENOGRAPHERS-PUBLIC',
  'STENOGRAPHIC MACHINES & SUPPLIES (WHOL)',
  'STEPS (MANUFACTURERS)',
  'STEREO & HI-FI EQUIPMENT INSTALLATION',
  'STEREOGRAPHIC MACHINES & SUPPLIES',
  'STEREOPHONIC & HIGH FIDELITY EQPT-RENT',
  'STEREOPHONIC & HIGH FIDELITY EQUIP-DLRS',
  'STEREOPHONIC & HIGH FIDELITY EQUIP-MFRS',
  'STEREOPHONIC & HIGH FIDELITY EQUIP-SVC',
  'STEREOPHONIC & HIGH FIDELITY EQUIP-WHOL',
  'STEREOPHONIC/HIGH FIDELITY PARTS (WHOL)',
  'STERILIZING APPARATUS (WHOLESALE)',
  'STERILIZING APPARATUS-MANUFACTURERS',
  'STEVEDORING CONTRACTORS',
  'STICKERS',
  'STOCK & BOND BROKERS',
  'STOCK & BOND TRANSFER AGENTS',
  'STOCK CERTIFICATES (MANUFACTURERS)',
  'STOCK CUTS',
  'STOCK EXCHANGES',
  'Stock Exchanges',
  'STOCK QUOTATIONS BOARDS & SYSTEMS (WHOL)',
  'STOCK YARDS',
  'STOKERS (WHOLESALE)',
  'STONE & BRICK-SIMULATED',
  'STONE CONTRACTORS',
  'STONE CRUSHING EQUIPMENT (WHOLESALE)',
  'STONE CUTTERS',
  'STONE PRODUCTS',
  'STONE PRODUCTS-NATURAL-MANUFACTURERS',
  'STONE SETTING CONTRACTORS',
  'STONE WALL BUILDERS',
  'STONE-BUILDING',
  'STONE-CAST',
  'STONE-CAST-MANUFACTURERS',
  'STONE-CRUSHED',
  'STONE-CRUSHED-WHOLESALE',
  'STONE-CUT (MANUFACTURERS)',
  'STONE-NATURAL',
  'STONE-NATURAL-WHOLESALE',
  'STONEWARE',
  'STOOLS (WHOLESALE)',
  'STOP WATCHES',
  'STORAGE',
  'STORAGE BATTERIES',
  'Storage Battery Manufacturing',
  'STORAGE CONTAINERS',
  'STORAGE-HOUSEHOLD & COMMERCIAL',
  'STORAGE-INDUSTRIAL & COMMERCIAL',
  'STORAGE-LIQUID & BULK',
  'STORAGE-PORTABLE BUILDINGS',
  'STORE DESIGNERS & PLANNERS',
  'STORE FIXTURES (WHOLESALE)',
  'STORE FIXTURES-MANUFACTURERS',
  'STORE FIXTURES-USED',
  'STORE FRONT CLEANING',
  'STORE FRONTS',
  'STORE FRONTS-MANUFACTURERS',
  'STORE FRONTS-MATERIALS & SUPPLIES (WHOL)',
  'STORM SHELTER CONTRACTORS & DEALERS',
  'STORM SHELTERS',
  'STORM WINDOWS & DOORS',
  'STORM WINDOWS & DOORS-MANUFACTURERS',
  'STORM WINDOWS & DOORS-REPAIRING',
  'STORM WINDOWS & DOORS-WHOLESALE',
  'STOVE-PIPE (MANUFACTURERS)',
  'STOVES & RANGES-MANUFACTURERS',
  'STOVES-WOOD COAL ETC-DISTRIBUTORS',
  'STOVES-WOOD COAL ETC-MANUFACTURERS',
  'STRAINERS (WHOLESALE)',
  'STRAPPING & STRAPPING EQUIPMENT (WHOL)',
  'STRAPPING & STRAPPING EQUIPMENT-MFRS',
  'STRAW GOODS (WHOLESALE)',
  'STRAWBALE CONSTRUCTION',
  'Strawberry Farming',
  'STRAWS-DRINKING (MANUFACTURERS)',
  'STREET CLEANING',
  'STREET CLEANING EQUIPMENT (MFRS)',
  'STREET LIGHTING CONTRACTORS',
  'STREET LIGHTING STANDARDS',
  'STRESS MANAGEMENT SERVICES',
  'STRESS RELIEF EQUIP & SERVICE-IND (MFRS)',
  'STRETCH WRAP FILM (WHOLESALE)',
  'STRIPE PAINTERS',
  'STRIPERS-MECHANICAL',
  'STRIPERS-MECHANICAL-MANUFACTURERS',
  'STRUCTURAL CLAY PRODUCTS',
  'STRUCTURAL CLAY PRODUCTS NEC (MFRS)',
  'STRUCTURAL INTEGRATION',
  'STRUCTURAL METAL WORK-MISC (MFRS)',
  'Structural Steel and Precast Concrete Contractors',
  'STRUCTURAL STEEL ERECTION',
  'STRUCTURAL WOOD MACHINES NEC (MFRS)',
  'STRUCTURAL WOOD MEMBERS',
  'STRUCTURAL WOOD MEMBERS NEC (MFRS)',
  'STRUTS',
  'STUCCO CONTRACTORS',
  'STUCCO-DISTRIBUTORS',
  'STUCCO-MANUFACTURERS',
  'STUDENT EXCHANGE PROGRAMS',
  'STUDENT HOUSING',
  'STUDIO RENTAL',
  'SUBDIVIDERS AND DEVELOPERS',
  'Subscription Service',
  'SUBSTANCE ABUSE CENTERS',
  'Sugar Beet Farming',
  'SUGAR REFINERS',
  'SUGAR-BROKERS & WHOLESALERS',
  'SUGAR-RAW-MANUFACTURERS',
  'SUGARCANE & SUGAR BEETS',
  'SUGARCANE AND SUGAR BEETS',
  'Sugarcane Farming',
  'SUICIDE PREVENTION SERVICE',
  'SULKIES (MANUFACTURERS)',
  'SULPHUR',
  'SUN LAMPS',
  'SUN ROOMS SOLARIUMS ATRIUMS ETC BLDRS',
  'SUN TAN PRODUCTS (WHOLESALE)',
  'SUN TAN SUPPLIES',
  'SUNDRIES STORES',
  'SUNDRIES-WHOLESALE',
  'SUNFLOWER-MANUFACTURERS',
  'SUNGLASSES & SUN GOGGLES',
  'SUNGLASSES & SUN GOGGLES-MANUFACTURERS',
  'SUNGLASSES & SUN GOGGLES-WHOLESALE',
  'SUPERCHARGERS (WHOLESALE)',
  'SUPERMARKET EQUIPMENT SALES & SERVICE',
  'Supermarkets',
  'Supermarkets and Other Grocery (except Convenience) Stores',
  'SUPPLIERS',
  'Supply Chain Management',
  'Support Activities for Animal Production',
  'Support Activities for Coal Mining',
  'Support Activities for Forestry',
  'Support Activities for Metal Mining',
  'Support Activities for Nonmetallic Minerals (except Fuels) Mining',
  'Support Activities for Oil and Gas Operations',
  'Support Activities for Printing',
  'Support Activities for Rail Transportation',
  'SUPPORT GROUPS',
  'SURETY INSURANCE',
  'Surface Active Agent Manufacturing',
  'SURFACE ACTIVE AGENTS',
  'SURFACE ACTIVE AGENTS (MANUFACTURERS)',
  'SURFACE PLATES',
  'SURFACE PREPARATION & EQUIPMENT',
  'SURFBOARDS',
  'SURFBOARDS-MANUFACTURERS',
  'SURFBOARDS-WHOLESALE',
  'Surfing',
  'SURFING EQUIPMENT',
  'SURGE PROTECTION EQUIPMENT (MFRS)',
  'Surgical and Medical Instrument Manufacturing',
  'SURGICAL AND MEDICAL INSTRUMENTS',
  'Surgical Appliance and Supplies Manufacturing',
  'SURGICAL APPLIANCES',
  'SURGICAL APPLIANCES AND SUPPLIES',
  'SURGICAL APPLIANCES-MANUFACTURERS',
  'SURGICAL CENTERS',
  'SURGICAL CORSETS (WHOLESALE)',
  'SURGICAL DRESSINGS-MANUFACTURERS',
  'SURGICAL INSTRUMENTS-MANUFACTURERS',
  'SURGICAL SUPPLIES (WHOLESALE)',
  'SURGICAL/MED INSTRUMENTS/APPARATUS (MFR)',
  'SURPLUS & SALVAGE MERCHANDISE-MFRS',
  'SURPLUS & SALVAGE MERCHANDISE-WHOLESALE',
  'SURVEILLANCE EQUIPMENT (WHOLESALE)',
  'SURVEY REPORTS',
  'Surveying and Mapping (except Geophysical) Services',
  'SURVEYING INSTRUMENTS (WHOLESALE)',
  'SURVEYING INSTRUMENTS-MANUFACTURERS',
  'SURVEYING SERVICES',
  'SURVEYORS-AERIAL',
  'SURVEYORS-CARGO',
  'SURVEYORS-CITY',
  'SURVEYORS-CONSTRUCTION',
  'SURVEYORS-HYDROGRAPHIC',
  'SURVEYORS-INDUSTRIAL',
  'SURVEYORS-LAND',
  'SURVEYORS-MARINE',
  'SURVEYORS-MINERAL',
  'SURVEYORS-QUANTITY',
  'SURVEYORS-WATER RIGHTS',
  'SURVIVAL & LIFE SUPPORT EQUIPMENT (WHOL)',
  'SURVIVAL PRODUCTS & SUPPLIES',
  'SURVIVAL TRAINING',
  'Sustainability',
  'SWAGING MACHINES (MANUFACTURERS)',
  'SWAP MEETS & SHOPS',
  'SWATCHING',
  'SWEAT SHIRTS (WHOLESALE)',
  'SWEATERS-MANUFACTURERS',
  'SWEATERS-RETAIL',
  'SWEATERS-TRIMMING SERVICE',
  'SWEATERS-WHOLESALE',
  'SWEEPERS-INDUSTRIAL',
  'SWEEPERS-POWER (WHOLESALE)',
  'SWEEPING COMPOUNDS (MANUFACTURERS)',
  'SWEEPING SERVICE-POWER',
  'Swimming',
  'SWIMMING INSTRUCTION',
  'SWIMMING POOL CONTRS DEALERS & DESIGNERS',
  'SWIMMING POOL COPING PLASTERING & TILING',
  'SWIMMING POOL COVERS-MANUFACTURERS',
  'SWIMMING POOL COVERS/ENCLOSURES-WHOL',
  'SWIMMING POOL ENCLOSURES',
  'SWIMMING POOL ENCLOSURES-MANUFACTURERS',
  'SWIMMING POOL EQUIPMENT & SUPLS (WHOL)',
  'SWIMMING POOL EQUIPMENT & SUPLS-RETAIL',
  'SWIMMING POOL EQUIPMENT & SUPPLIES-MFRS',
  'SWIMMING POOL MANAGEMENT',
  'SWIMMING POOL-DISTRIBUTORS',
  'SWIMMING POOL-MANUFACTURERS',
  'SWIMMING POOL-SERVICE',
  'SWIMMING POOLS-PRIVATE',
  'SWIMMING POOLS-PUBLIC',
  'SWIMMING SUITS-WHOLESALE',
  'SWIMWEAR & ACCESSORIES-MANUFACTURERS',
  'SWIMWEAR & ACCESSORIES-RETAIL',
  'SWIMWEAR & ACCESSORIES-WHOLESALE',
  'SWINE INSEMINATION SVC',
  'SWITCHES-ELECTRIC (WHOLESALE)',
  'SWITCHES-ELECTRIC-MANUFACTURERS',
  'SWITCHES-PRESSURE & TEMPERATURE (WHOL)',
  'SWITCHES-TIME (WHOLESALE)',
  'SWITCHGEAR AND SWITCHBOARD APPARATUS',
  'Switchgear and Switchboard Apparatus Manufacturing',
  'SWITCHGEAR/SWITCHBOARD APPARATUS (MFRS)',
  'SWITCHING AND TERMINAL SERVICES',
  'SWIVELS (WHOLESALE)',
  'SYNAGOGUES',
  'SYNTHETIC & BLENDED FABRICS-MFRS',
  'SYNTHETIC & BLENDED FABRICS-WHOLESALE',
  'Synthetic Dye and Pigment Manufacturing',
  'SYNTHETIC FIBERS (MANUFACTURERS)',
  'SYNTHETIC RUBBER',
  'Synthetic Rubber Manufacturing',
  'SYRUPS-MANUFACTURERS',
  'SYRUPS-WHOLESALE',
  'SYSTEMS CONSULTANTS',
  'T-SHIRTS-CUSTOM PRINTED',
  'T-SHIRTS-MANUFACTURERS',
  'T-SHIRTS-RETAIL',
  'T-SHIRTS-WHOLESALE',
  'TABLE CLOTHS & COVERS',
  'TABLE PADS (WHOLESALE)',
  'TABLE PARTS-MANUFACTURERS',
  'Table Tennis',
  'TABLE TENNIS EQUIPMENT & SUPLS (WHOL)',
  'TABLE TENNIS FACILITIES',
  'TABLE TOPS',
  'TABLE TOPS-MANUFACTURERS',
  'TABLE TOPS-WHOLESALE',
  'TABLES',
  'TABLES-CUTTING & WORK (WHOLESALE)',
  'TABLES-FOLDING (WHOLESALE)',
  'TABLES-FOLDING-MANUFACTURERS',
  'TABLES-RENTING',
  'TABLET MAKERS-PHARM-FOOD ETC (MFRS)',
  'TABULATING SERVICE',
  'TACHOMETERS',
  'TACK',
  'TAG STRINGING (MANUFACTURERS)',
  'TAGS-MANUFACTURERS',
  'TAGS-WHOLESALE',
  'TAILORING INSTRUCTION',
  'TAILORS',
  'TAILORS-ALTERATION & REPAIRING',
  "TAILORS-MEN'S",
  'TAILORS-TRIMMINGS & SUPPLIES (WHOL)',
  'TAILORS-WHOLESALE',
  'TALC (MANUFACTURERS)',
  'TALENT AGENCIES & CASTING SERVICES',
  'TALLOW (MANUFACTURERS)',
  'TAMALES (WHOLESALE)',
  'TANK CARS (WHOLESALE)',
  'TANK CLEANING',
  'TANK CLEANING EQUIP-MFRS & SUPLS (MFRS)',
  'TANK ERECTION',
  'TANK HEADS (MANUFACTURERS)',
  'TANK INSTALLATION',
  'TANK LINING & COATING',
  'TANK MEASURING DEVICES (MANUFACTURERS)',
  'TANK REMOVAL',
  'TANK STRAPPING & CALIBRATION',
  'TANK TESTING & INSPECTION',
  'TANK TRUCK SERVICE',
  'TANK WAGON SERVICE',
  'TANKS (WHOLESALE)',
  'TANKS & TANK COMPONENTS (MANUFACTURERS)',
  'TANKS AND TANK COMPONENTS',
  'TANKS-ABANDONED-FILLED',
  'TANKS-CONCRETE (WHOLESALE)',
  'TANKS-FIBER, GLASS, PLASTIC, ETC (WHOL) ',
  'TANKS-MANUFACTURERS',
  'TANKS-METAL (WHOLESALE)',
  'TANKS-RENTAL',
  'TANKS-REPAIRING',
  'TANKS-USED (WHOLESALE)',
  'TANKS-WOODEN (MANUFACTURERS)',
  'TANNERS (MANUFACTURERS)',
  'TANNERS-OIL (MANUFACTURERS)',
  'TANNING EQUIPMENT-HOME & OFFICE (WHOL)',
  'TANNING SALONS',
  'TANNING SALONS EQUIP & SUPPLIES-WHOL',
  'TANNING SALONS EQUIPMENT-MANUFACTURERS',
  'TANTALUM (MANUFACTURERS)',
  'TAPE APPLYING & DISPENSING MACHS (MFRS)',
  'TAPE RECORDERS/PLAYERS-SOUND-MFRS',
  'TAPE RECORDERS/PLAYERS-SOUND-SUPLS/PRTS',
  'TAPE RECORDERS/PLAYERS-SOUND-SVC & RPR',
  'TAPE RECORDERS/PLAYERS-SOUND-WHOLESALE',
  'TAPE RECORDERS/PLAYERS-SOUND/VIDEO EQPT',
  'TAPES (WHOLESALE)',
  'TAPES-ADHESIVE (WHOLESALE)',
  'TAPES-ADHESIVE-DISPENSERS (WHOLESALE)',
  'TAPES-INDUSTRIAL-MANUFACTURERS',
  'TAPES-LABELING (MANUFACTURERS)',
  'TAPES-MASKING (WHOLESALE)',
  'TAPES-MEASURING-MANUFACTURERS',
  'TAPES-PRESSURE SENSITIVE (MANUFACTURERS)',
  'TAPES-SOUND & VIDEO-PRERECORDED',
  'TAPESTRIES',
  'TAPESTRIES-WHOLESALE',
  'TAPPING COMPOUNDS (WHOLESALE)',
  'TAPS & DIES (WHOLESALE)',
  'TAR & TAR PRODUCTS (WHOLESALE)',
  'TARIFF BUREAUS',
  'TARPAULINS-MANUFACTURERS',
  'TARPAULINS-WHOLESALE',
  'Task Management',
  'TASSELS (MANUFACTURERS)',
  'TATTOOING',
  'TATTOOING EQUIPMENT & SUPPLIES (WHOL)',
  'TATTOOS-REMOVED',
  'TAX CONSULTANTS',
  'Tax Preparation Services',
  'TAX PUBLICATIONS (MANUFACTURERS)',
  'TAX RETURN PREPARATION & FILING',
  'TAX RETURN PREPARATION SERVICES',
  'TAX RETURN TRANSMISSION-ELECTRONIC',
  'TAX SHELTERED INVESTMENTS',
  'Taxi Service',
  'Taxi Service',
  'TAXICAB BROKERS',
  'TAXICABS',
  'TAXICABS & TRANSPORTATION SERVICE',
  'TAXIDERMISTS',
  'TAXIDERMY INSTRUCTION',
  'Tea',
  'TEA BAG PACKERS (WHOLESALE)',
  'TEA ROOMS',
  'TEA-WHOLESALE',
  "TEACHERS' AGENCIES",
  'TEACHING MACHINES & SYSTEMS (MFRS)',
  'TEAR GAS EQUIPMENT & SUPPLIES (WHOL)',
  'TECHNICAL ILLUSTRATIONS',
  'TECHNICAL MANUAL PREPARATION',
  'Technical Support',
  'TECHNICAL WRITING',
  'TECHNOLOGISTS-APPLIED SCIENCES',
  'TECHNOLOGY ASSISTANCE PROGRAMS',
  'TEEN CLUBS',
  'TEEN HOT LINES',
  'Teenagers',
  'TEFLON (MANUFACTURERS)',
  'TELECOMMUNICATION EQUIP/SYST-WHOL/MFRS',
  'Telecommunications',
  'TELECOMMUNICATIONS CONSULTANTS',
  'TELECOMMUNICATIONS CONTRACTORS',
  'TELECOMMUNICATIONS EQUIP-DISABLED (WHOL)',
  'TELECOMMUNICATIONS EQUIPMENT',
  'Telecommunications Resellers',
  'TELECOMMUNICATIONS SERVICES',
  'TELECONFERENCING EQUIPMENT',
  'TELECONFERENCING SERVICE',
  'TELEGRAM SERVICE',
  'TELEGRAPH AND OTHER COMMUNICATIONS',
  'TELEGRAPH SERVICE',
  'Telemarketing Bureaus and Other Contact Centers',
  'TELEMARKETING SERVICES',
  'TELEMETERING SYSTEMS & EQUIPMENT (WHOL)',
  'TELEPHONE & TELEGRAPH APPARATUS (MFRS)',
  'TELEPHONE & TELEVISION CABLE CONTRACTORS',
  'TELEPHONE AND TELEGRAPH APPARATUS',
  'Telephone Answering Services',
  'TELEPHONE ANSWERING SYSTS/EQUIP (WHOL)',
  'Telephone Apparatus Manufacturing',
  'TELEPHONE AUTO ANSWER EQUIP-SERVICING',
  'TELEPHONE AUTO ANSWER EQUIPMENT-MFRS',
  'TELEPHONE BOOTHS (MANUFACTURERS)',
  'TELEPHONE CALLING CARDS-WHOL & MFRS',
  'TELEPHONE COMMUNICATION',
  'TELEPHONE COMPANIES',
  'TELEPHONE COMPANIES-NON-WIRE LINE',
  'TELEPHONE CONSULTANTS',
  'TELEPHONE DIRECTORY ADVERTISING',
  'TELEPHONE EQUIP/SYSTS-SUPLS/PARTS (WHOL)',
  'TELEPHONE EQUIPMENT & SUPPLIES',
  'TELEPHONE EQUIPMENT & SYSTEMS-MFRS',
  'TELEPHONE EQUIPMENT & SYSTEMS-RENTING',
  'TELEPHONE EQUIPMENT & SYSTEMS-SVC/REPAIR',
  'TELEPHONE EQUIPMENT & SYSTEMS-WHOLESALE',
  'TELEPHONE LINE PROVIDERS-TOLL NUMBERS',
  'TELEPHONE RECORDED ANNOUNCEMENTS',
  'TELEPHONE TRAINING',
  'TELEPHONE-COIN & CARD OPERATED (WHOL)',
  'TELEPHONE-INSTALLATIONS',
  'TELEPHONE-WIRING & REPAIR',
  'Teleproduction and Other Postproduction Services',
  'TELESCOPES',
  'TELESCOPES-MANUFACTURERS',
  'TELETYPEWRITER COMMS EQUIPMENT (WHOL)',
  'TELETYPEWRITER COMMUNICATIONS SERVICE',
  'TELETYPEWRITER SUPPLIES (WHOLESALE)',
  'TELEVISION & RADIO BROADCASTING SCHOOLS',
  'TELEVISION & RADIO SUPPLIES & PARTS-RTL',
  'TELEVISION & RADIO SUPPLIES & PARTS-WHOL',
  'TELEVISION & RADIO-DEALERS',
  'TELEVISION & RADIO-MANUFACTURERS',
  'TELEVISION & RADIO-SERVICE REPAIR',
  'TELEVISION & RADIO-WHOLESALE',
  'TELEVISION ANTENNA SYSTEMS-MANUFACTURERS',
  'TELEVISION ANTENNA SYSTEMS-WHOLESALE',
  'Television Broadcasting',
  'TELEVISION BROADCASTING STATIONS',
  'TELEVISION MASTER ANTENNA SYSTEMS-MFRS',
  'TELEVISION MASTER ANTENNA SYSTEMS-WHOL',
  'TELEVISION PICTURE TUBES-MANUFACTURERS',
  'TELEVISION PROGRAM DISTRIBUTORS',
  'TELEVISION PROGRAM PRODUCERS',
  'TELEVISION PROGRAM PRODUCERS-SVCS/SUPLS',
  'TELEVISION SERVICE-CONSULTING',
  'TELEVISION STATION EQUIPMENT (WHOL)',
  'TELEVISION STATION EQUIPMENT-MFRS',
  'TELEVISION STATION PLANNING',
  'TELEVISION STATION REPRESENTATIVES',
  'TELEVISION STATIONS & BROADCASTING CO',
  'TELEVISION STUDIO EQUIPMENT (WHOLESALE)',
  'TELEVISION SYST-CLOSED CIRCUIT TLCSTNG',
  'TELEVISION SYSTS/EQPT-CLSD CRCT (WHOL)',
  'TELEVISION TABLES & STANDS',
  'TELEVISION TRANSMISSION SVC',
  'TELEVISION-CABLE & CATV',
  'TELEVISION-CABLE & CATV-EQUIP & SUPLS',
  'TELEVISION-CABLE & CATV-MANUFACTURERS',
  'TELEVISION-CABLE & CATV-WHOLESALE',
  'TELEVISION-CABLE-MANUFACTURERS',
  'TELEVISION-DEALERS-USED',
  'TELEVISION-GIANT SCREEN',
  'TELEVISION-MANUFACTURERS',
  'TELEVISION-MOBILE PRODUCTION VEHICLES',
  'TELEVISION-RENTAL',
  'TELEVISION-SATELLITE ANTENNA SYSTEMS',
  'TELEVISION-TAPE PRODUCERS & STUDIOS',
  'TELEVISION-WHOLESALE',
  'TELEX EQUIPMENT & SUPPLIES (WHOLESALE)',
  'TEMPERATURE MEASURING MATERIALS (MFRS)',
  'TEMPLATES (MANUFACTURERS)',
  'Temporary Help Services',
  'Temporary Shelters',
  'TENANT REFERENCE CHECKING SERVICE',
  'Tennis',
  'TENNIS COURT CONSTRUCTION',
  'TENNIS COURT ENCLOSURES',
  'TENNIS COURT SUPPLIES & REPAIR (WHOL)',
  'TENNIS COURTS-PRIVATE',
  'TENNIS COURTS-PUBLIC',
  'TENNIS EQUIPMENT & SUPPLIES-MFRS',
  'TENNIS EQUIPMENT & SUPPLIES-RETAIL',
  'TENNIS INSTRUCTION',
  'TENNIS RACKET STRINGING MACHINES (MFRS)',
  'TENNIS SUPPLIES-WHOLESALE',
  'TENTS-MANUFACTURERS',
  'TENTS-RETAIL',
  'TENTS-WHOLESALE',
  'TERMINAL & SERVICE FACILITIES',
  'TERMINALS-PHOSPHATE (MANUFACTURERS)',
  'TERMINALS-RIVER & MARINE',
  'TERRA COTTA (WHOLESALE)',
  'TERRA COTTA-MANUFACTURERS',
  'TERRACING',
  'TERRAZZO (WHOLESALE)',
  'TERRAZZO EQUIPMENT & SUPPLIES (WHOL)',
  'TERRAZZO MOSAIC WORK',
  'TERRY CLOTH (MANUFACTURERS)',
  'Test and Measurement',
  'TEST BORINGS',
  'TEST PREPARATION INSTRUCTION',
  'TEST PUBLISHERS-VOCATIONAL/EDUCATIONAL',
  'TESTING',
  'TESTING (ACADEMIC)',
  'TESTING (NON-DESTRUCTIVE)',
  'TESTING (TOXIC MATERIAL & GASES)',
  'TESTING APPARATUS-MANUFACTURERS',
  'TESTING APPARATUS-WHOLESALE',
  'TESTING EQUIPMENT-ENVIRONMENTAL',
  'Testing Laboratories',
  'TESTING LABORATORIES',
  'Text Analytics',
  'Textbook',
  'Textile and Fabric Finishing Mills',
  'Textile Bag and Canvas Mills',
  'TEXTILE BAGS',
  'TEXTILE BAGS (MANUFACTURERS)',
  'TEXTILE BROKERS',
  'TEXTILE CONSULTANTS',
  'TEXTILE CONVERTING (WHOLESALE)',
  'TEXTILE CONVERTING-MANUFACTURERS',
  'TEXTILE DESIGNERS',
  'TEXTILE DYERS (WHOLESALE)',
  'TEXTILE FIBERS (MANUFACTURERS)',
  'TEXTILE FINISHERS',
  'TEXTILE FINISHING (MANUFACTURERS)',
  'TEXTILE GOODS',
  'TEXTILE GOODS NEC (MANUFACTURERS)',
  'TEXTILE JOBBERS',
  'TEXTILE MACHINERY',
  'TEXTILE MACHINERY & PARTS (WHOLESALE)',
  'TEXTILE MACHINERY & PARTS-MANUFACTURERS',
  'TEXTILE MANUFACTURERS SUPPLIES',
  'TEXTILE MILL SUPPLIES-WHOLESALE',
  'TEXTILE MILLS',
  'TEXTILE PRINTERS (WHOLESALE)',
  "TEXTILE-MANUFACTURER'S REPRESENTATIVES",
  'TEXTILE-MANUFACTURERS',
  'Textiles',
  'TEXTILES-RETAIL',
  'TEXTILES-WHOLESALE',
  'THAWING SERVICE',
  'Theater Companies and Dinner Theaters',
  'Theatre',
  'THEATRE & STAGE SETS-DESIGN/CONSTRUCTION',
  'THEATRE CONSULTANTS',
  'THEATRE PARTIES-ARRANGED',
  'THEATRES',
  'THEATRES-DINNER',
  'THEATRES-LIVE',
  'THEATRICAL AGENCIES',
  'THEATRICAL EQUIPMENT & SUPPLIES',
  'THEATRICAL EQUIPMENT & SUPPLIES-MFRS',
  'THEATRICAL MAKE-UP',
  'THEATRICAL MANAGERS & PRODUCERS',
  'THEATRICAL PRODUCERS AND SERVICES',
  'THEATRICAL SCHOOLS',
  'THEATRICAL TALENT INVENTORY SERVICE',
  'THEATRICAL/STAGE LIGHTING EQUIP (WHOL)',
  'THEATRICAL/STAGE LIGHTING-MANUFACTURERS',
  'THEOLOGICAL SCHOOLS',
  'Therapeutics',
  'THERAPISTS-CORRECTIVE',
  'THERAPY-GROUP',
  'THERMOCOUPLES (MANUFACTURERS)',
  'THERMOELECTRIC EQUIPMENT (MANUFACTURERS)',
  'THERMOGRAPHERS',
  'THERMOMETER WELLS (MANUFACTURERS)',
  'THERMOMETERS (WHOLESALE)',
  'THERMOMETERS-MANUFACTURERS',
  'THERMOSTATIC BIMETALS (MANUFACTURERS)',
  'THERMOSTATS (WHOLESALE)',
  'THESIS SERVICE',
  'Think Tanks',
  'Third Party Administration of Insurance and Pension Funds',
  'THREAD (WHOLESALE)',
  'THREAD MILLS',
  'THREAD-CUTTING-LACE, FABRIC ETC (MFRS) ',
  'THREAD-MANUFACTURERS',
  'THREAD-ROLLING CUTNG/GRNDNG EQUIP (MFRS)',
  'THREAD-ROLLING, CUTTING/GRINDING (MFRS) ',
  'THRESHOLDS (WHOLESALE)',
  'THRIFT & LOAN COMPANIES',
  'THRIFT SHOPS',
  'THROWING AND WINDING MILLS',
  'TICKET ISSUING MACHINES (MANUFACTURERS)',
  'TICKET SERVICE',
  'Ticketing',
  'TICKETS & COUPONS-PRINTERS/SUPLS (MFRS)',
  'TICKING (MANUFACTURERS)',
  'TIE FACTORIES',
  'TIE RACKS',
  'TIES-WIRE & PLASTIC (MANUFACTURERS)',
  'TIGHTS-MANUFACTURERS',
  'TILE',
  'Tile and Terrazzo Contractors',
  'TILE-CERAMIC-CONTRACTORS & DEALERS',
  'TILE-CERAMIC-DISTRIBUTORS',
  'TILE-CERAMIC-MANUFACTURERS',
  'TILE-CERAMIC-REPAIRING & REFINISHING',
  'TILE-CUSTOM DESIGN',
  'TILE-MARBLE-DISTRIBUTORS',
  'TILE-MARBLE-MANUFACTURERS',
  'TILE-NON-CERAMIC-CONTRACTORS & DEALERS',
  'TILE-NON-CERAMIC-DISTRIBUTORS',
  'Timber',
  'TIMBER & TIMBERLAND COMPANIES (WHOL)',
  'TIMBER CONNECTORS',
  'TIMBER CRUISERS',
  'TIMBER FRAME HOMES',
  'Timber Tract Operations',
  'TIMBER TRACTS',
  'TIMBER TRACTS',
  'TIMBERS (WHOLESALE)',
  'TIME & TEMPERATURE ANNOUNCEMENTS',
  'TIME CARDS RACKS & SUPPLIES-MFRS',
  'TIME LOCKS',
  'TIME MANAGEMENT TRAINING',
  'TIME OF DAY SERVICE',
  'TIME RECORDERS',
  'TIME RECORDERS & SYSTEMS-MANUFACTURERS',
  'TIME RECORDERS-WHOLESALE',
  'TIME STAMPS (MANUFACTURERS)',
  'Timeshare',
  'TIMING DEVICES-MANUFACTURERS',
  'TIMING DEVICES-WHOLESALE',
  'TIN (MANUFACTURERS)',
  'TIN EDGING (MANUFACTURERS)',
  'TIN PLATE (MANUFACTURERS)',
  'TINNING',
  'TINWARE',
  'TIRE & INNER TUBE-MANUFACTURERS',
  'TIRE ADDITIVES & SEALANTS (WHOLESALE)',
  'Tire and Tube Merchant Wholesalers',
  'TIRE CHANGING EQUIPMENT (WHOLESALE)',
  'TIRE CORD & FABRICS (MANUFACTURERS)',
  'TIRE CORD AND FABRICS',
  'Tire Dealers',
  'TIRE DISPOSAL (WHOLESALE)',
  'Tire Manufacturing (except Retreading)',
  'Tire Retreading',
  'TIRE RETREADING AND REPAIR SHOPS',
  'TIRE SERVICE',
  'TIRE-CUSTOMIZING & MODIFICATION',
  'TIRE-DEALERS-RETAIL',
  'TIRE-DEALERS-USED (WHOLESALE)',
  'TIRE-DISTRIBUTORS',
  'TIRE-MANUFACTURERS',
  'TIRE-RETREADING & REPAIRING',
  'TIRE-RETREADING/REPAIR-EQPT/SUPLS (WHOL)',
  'TIRE-RETREADING/REPAIRING-EQPT/SUPL-MFRS',
  'TIRES AND INNER TUBES',
  'TIRES AND TUBES',
  'TIRES-SOLID',
  'TITANIUM (MANUFACTURERS)',
  'Title Abstract and Settlement Offices',
  'TITLE ABSTRACT OFFICES',
  'TITLE COMPANIES',
  'TITLE EXAMINERS',
  'TITLE INSURANCE',
  'TITLING SERVICE-TV & MOTION PICTURE',
  'Tizen',
  'TOBACCO',
  'Tobacco',
  'Tobacco and Tobacco Product Merchant Wholesalers',
  'TOBACCO AND TOBACCO PRODUCTS',
  'TOBACCO BUYERS',
  'Tobacco Farming',
  'TOBACCO GROWERS',
  "TOBACCO GROWERS' EQUIP & SUPPLIES (MFRS)",
  'TOBACCO LEAF-WHOLESALE',
  'TOBACCO MACHINERY (MANUFACTURERS)',
  'Tobacco Manufacturing',
  'TOBACCO PLANTS',
  'TOBACCO PRODUCTS-MANUFACTURERS',
  'TOBACCO REHANDLERS (WHOLESALE)',
  'TOBACCO STEMMING & REDRYING',
  'TOBACCO STEMMING AND REDRYING',
  'Tobacco Stores',
  'TOBACCO STORES AND STANDS',
  'TOBACCO WAREHOUSES',
  'TOILET ARTICLES',
  'TOILET PARTITIONS',
  'Toilet Preparation Manufacturing',
  'TOILET PREPARATIONS',
  'TOILET PREPARATIONS (WHOLESALE)',
  'TOILET SEATS-WHOLESALE',
  'TOILETS-COMPOSTING (MANUFACTURERS)',
  'TOILETS-INCINERATING (MANUFACTURERS)',
  'TOILETS-PORTABLE',
  'TOILETS-PORTABLE-MANUFACTURERS',
  'TOLL BRIDGES',
  'TOMATOES-WHOLESALE',
  'TONGS (MANUFACTURERS)',
  'TOOL & DIE MAKERS',
  'TOOL & DIE MAKERS EQUIP & SUPLS (WHOL)',
  'TOOL & UTILITY SHEDS',
  'TOOL CHESTS KITS BAGS ETC-WHOLESALE',
  'TOOL CHESTS KITS/BAGS ETC-MANUFACTURERS',
  'TOOL DESIGNERS',
  'TOOL GRINDING-INDUSTRIAL',
  'TOOLS (WHOLESALE)',
  'TOOLS-CARBIDE (WHOLESALE)',
  'TOOLS-CARBIDE-COATING (MANUFACTURERS)',
  'TOOLS-CUTTING (WHOLESALE)',
  'TOOLS-CUTTING-RETAIL',
  'TOOLS-ELECTRIC (WHOLESALE)',
  'TOOLS-ELECTRIC-RENTING',
  'TOOLS-ELECTRIC-REPAIRING & PARTS',
  'TOOLS-HAND',
  'TOOLS-HAND-MANUFACTURERS',
  'TOOLS-HOUSEHOLD',
  'TOOLS-HYDRAULIC (WHOLESALE)',
  'TOOLS-MANUFACTURERS',
  'TOOLS-NEW & USED',
  'TOOLS-PNEUMATIC (WHOLESALE)',
  'TOOLS-PNEUMATIC-MANUFACTURERS',
  'TOOLS-PNEUMATIC-REPAIRING',
  'TOOLS-POWDER ACTUATED (WHOLESALE)',
  'TOOLS-POWDER ACTUATED-MANUFACTURERS',
  'TOOLS-PRECISION (MANUFACTURERS)',
  'TOOLS-RENTING',
  'TOOLS-REPAIRING & PARTS',
  'TOOLS-STORED',
  'TOOLS-USED',
  'TOOTH POWDERS & PASTES-MANUFACTURERS',
  'TOOTH POWDERS & PASTES-WHOLESALE',
  'TOOTHPICKS-WHOLESALE',
  'TOP AND BODY REPAIR AND PAINT SHOPS',
  'TOPSOIL',
  'TOPSOIL-MANUFACTURERS',
  'TORTILLA & TAMALE MAKING EQUIP (MFRS)',
  'Tortilla Manufacturing',
  'TORTILLAS (WHOLESALE)',
  'Totalizing Fluid Meter and Counting Device Manufacturing',
  'TOTALIZING FLUID MTRS/CNTNG DEVICE (MFR)',
  'TOTE BOXES PANS & TRAYS',
  'Tour Operator',
  'Tour Operators',
  'TOUR OPERATORS',
  'Tourism',
  'TOURIST ACCOMMODATIONS',
  'TOURIST ATTRACTIONS',
  'TOURIST HOMES',
  'TOURIST INFORMATION',
  'TOURS-OPERATORS & PROMOTERS',
  'TOURS-SOUND TAPE SYSTEMS',
  'TOURS-WHOLESALE',
  'TOW BARS',
  'TOW BOAT SERVICE',
  'TOWEL CABINETS',
  'TOWEL SUPPLY SERVICE',
  'TOWELS-CLOTH-MANUFACTURERS',
  'TOWELS-CLOTH-RETAIL',
  'TOWELS-CLOTH-WHOLESALE',
  'TOWERS (MANUFACTURERS)',
  'TOWERS-RADIO',
  'TOWERS-REPAIRING',
  'TOWING AND TUGBOAT SERVICE',
  'TOWING EQUIPMENT-AUTOMOTIVE (WHOLESALE)',
  'TOWING EQUIPMENT-AUTOMOTIVE-MFRS',
  'TOWING-MARINE',
  'TOWING-TRUCK',
  'TOWING-TRUCK & AUTOMOTIVE-REFERRAL SVC',
  'TOWNHOUSE MAINTENANCE CONTRACTORS',
  'TOWNHOUSES',
  'TOXICOLOGISTS',
  'Toy and Hobby Goods and Supplies Merchant Wholesalers',
  'TOY CONSULTANTS',
  'Toys',
  'TOYS AND HOBBY GOODS AND SUPPLIES',
  'TOYS-MANUFACTURERS',
  'TOYS-MANUFACTURERS SUPPLIES (MFRS)',
  'TOYS-RETAIL',
  'TOYS-WHOLESALE',
  'TRACKS & ATHLETIC FIELDS CONSTRUCTION',
  'TRACTOR EQUIPMENT & PARTS (WHOLESALE)',
  'TRACTOR EQUIPMENT & PARTS-USED (WHOL)',
  'TRACTOR-DEALERS (WHOLESALE)',
  'TRACTOR-DISTRIBUTORS',
  'TRACTOR-RENTING & LEASING',
  'TRACTOR-REPAIRING & SERVICE',
  'TRACTORS-LAWN & GARDEN-DEALERS',
  'TRADE CLEARING EXCHANGES',
  'TRADE FAIRS & SHOWS',
  'TRADE SCHOOLS',
  'Trade Shows',
  'TRADEMARK AGENTS & CONSULTANTS',
  'Trading Platform',
  'TRADING POSTS',
  'TRAFFIC & PARKING CONSULTANTS',
  'TRAFFIC CONTROL SERVICES',
  'TRAFFIC REPORTS',
  'TRAFFIC SIGNS SIGNALS & EQUIP-MFRS',
  'TRAFFIC SIGNS SIGNALS & EQUIP-WHOL',
  'TRAFFIC SURVEYS',
  'TRAILER HITCHES',
  'TRAILER HITCHES-MANUFACTURERS',
  'TRAILER HITCHES-WHOLESALE',
  'TRAILER PARKS AND CAMPSITES',
  'TRAILER RENTING & LEASING',
  'TRAILER-MANUFACTURERS & DESIGNERS',
  'TRAILER-SALES',
  'TRAILER-STORAGE',
  'TRAILERS TRUCK RETAIL',
  'TRAILERS-AUTOMOBILE UTILITY SPORTS ETC',
  'TRAILERS-AUTOMOBILE UTILITY-MFRS',
  'TRAILERS-AUTOMOBILE UTILITY-WHOLESALE',
  'TRAILERS-BOAT',
  'TRAILERS-BOAT-MANUFACTURERS',
  'TRAILERS-BOAT-RENTING',
  'TRAILERS-BOAT-WHOLESALE',
  'TRAILERS-CAMPING & TRAVEL',
  'TRAILERS-CAMPING & TRAVEL-MANUFACTURERS',
  'TRAILERS-CAMPING & TRAVEL-RENTING',
  'TRAILERS-CAMPING & TRAVEL-REPAIRING',
  'TRAILERS-CAMPING & TRAVEL-STORAGE',
  'TRAILERS-CAMPING & TRAVEL-WHOLESALE',
  'TRAILERS-CARGO IMPLEMENT LIVESTOCK',
  'TRAILERS-COMMERCIAL-EQUIP/PARTS (WHOL)',
  'TRAILERS-EQUIPMENT & PARTS',
  'TRAILERS-EQUIPMENT & PARTS-MANUFACTURERS',
  'TRAILERS-EQUIPMENT & PARTS-WHOLESALE',
  'TRAILERS-HORSE (WHOLESALE)',
  'TRAILERS-HORSE-MANUFACTURERS',
  'TRAILERS-HORSE-RENTING',
  'TRAILERS-INDUSTRIAL-MANUFACTURERS',
  'TRAILERS-LIVESTOCK (WHOLESALE)',
  'TRAILERS-MOTORCYCLES (MANUFACTURERS)',
  'TRAILERS-OFFICE (WHOLESALE)',
  'TRAILERS-OFFICE-RENTING',
  'TRAILERS-REPAIRING & SERVICE',
  'TRAILERS-SNOWMOBILE',
  'TRAILERS-TRANSPORTING',
  'TRAILERS-TRUCK (WHOLESALE)',
  'TRAILERS-TRUCK-MANUFACTURERS',
  'TRAILERS-TRUCK-RENTING & LEASING',
  'TRAILERS-TRUCK-USED (WHOLESALE)',
  'Training',
  'TRAINING CONSULTANTS',
  'TRAINING DEVICES (WHOLESALE)',
  'TRAINING PROGRAMS & SERVICES',
  'TRAINS',
  'TRAINS-TICKET AGENCIES',
  'TRAMPOLINE EQUIPMENT & SUPPLIES',
  'TRAMPOLINE EQUIPMENT & SUPPLIES-MFRS',
  'Transaction Processing',
  'TRANSCRIBERS-COURT',
  'TRANSCRIBING SERVICE-TAPE DISC ETC',
  'TRANSDUCERS (MANUFACTURERS)',
  'TRANSFER CASES',
  'TRANSFERS-DRY (MANUFACTURERS)',
  'TRANSFORMERS-MANUFACTURERS',
  'TRANSFORMERS-RETAIL',
  'TRANSFORMERS-WHOLESALE',
  'TRANSIT LINES',
  'TRANSITION HOUSES',
  'Translation and Interpretation Services',
  'Translation and Localization',
  'Translation Service',
  'TRANSLATORS & INTERPRETERS',
  "TRANSLATORS' SYSTEMS & EQUIPMENT (WHOL)",
  'TRANSMISSIONS-AUTOMOBILE',
  'TRANSMISSIONS-AUTOMOBILE-MANUFACTURERS',
  'TRANSMISSIONS-AUTOMOBILE-PARTS',
  'TRANSMISSIONS-AUTOMOBILE-WHOLESALE',
  'TRANSMISSIONS-MARINE',
  'TRANSMISSIONS-PARTS & SUPPLIES-MFRS',
  'TRANSMISSIONS-TRUCK TRACTOR ETC',
  'TRANSPORTATION',
  'Transportation',
  'TRANSPORTATION AUTHORITIES',
  'TRANSPORTATION CONSULTANTS',
  'TRANSPORTATION EQUIPMENT',
  'TRANSPORTATION EQUIPMENT & SUPLS (WHOL)',
  'TRANSPORTATION EQUIPMENT AND SUPPLIES',
  'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers',
  'TRANSPORTATION EQUIPMENT NEC (MFRS)',
  'TRANSPORTATION EQUIPMENT-LEASING',
  'TRANSPORTATION LINES',
  'TRANSPORTATION SERVICES',
  'TRANSPORTATION SERVICES',
  'TRANSPORTATION SERVICES NEC',
  'TRANSPORTATION SHARING SERVICE',
  'Transportation/Trucking/Railroad',
  'TRAP & SKEET RANGES',
  'TRAP & SKEET RANGES EQUIP & SUPL (WHOL)',
  'TRAPPING EQUIPMENT & SUPPLIES',
  'TRAPPING EQUIPMENT & SUPPLIES-MFRS',
  'Travel',
  'TRAVEL ACCESSORIES',
  'Travel Accommodations',
  'Travel Agencies',
  'TRAVEL AGENCIES',
  'TRAVEL AGENCIES & BUREAUS',
  'Travel Agency',
  'Travel and Tourism',
  'TRAVEL CLUBS',
  'TRAVEL CONSULTANTS',
  'TRAVEL ESCORTS',
  'TRAVEL MARKETING',
  'TRAVEL RESEARCH',
  'Travel Trailer and Camper Manufacturing',
  'TRAVEL TRAILERS AND CAMPERS',
  "TRAVELERS' ADVISORY SERVICE",
  "TRAVELERS' CHECKS-ISSUED",
  "TRAVELERS' HLTH COUNSELING/VACCINATIONS",
  'TRAWLS (MANUFACTURERS)',
  'TRAYS (MANUFACTURERS)',
  'TREE CONSULTANTS',
  'Tree Nut Farming',
  'TREE NUTS',
  'TREE NUTS',
  'TREE PLANTING KITS',
  'TREE SERVICE',
  'TREE SERVICE EQUIPMENT & SUPPLIES (MFRS)',
  'TREES',
  'TRELLISES (MANUFACTURERS)',
  'TRENCH BOXES (WHOLESALE)',
  'TRENCHING & UNDERGROUND CONTRACTORS',
  'TRENCHING MACHINES-RENTING',
  'TRICKS & JOKES',
  'TRIMMERS-INDUSTRIAL (MANUFACTURERS)',
  'TRIMMINGS-WHOLESALE',
  'TROLLEYS-CHARTER',
  'TROPHIES AWARDS & MEDALS',
  'TROPHIES-MANUFACTURERS',
  'TROPHIES-WHOLESALE',
  'TROPHY CABINETS',
  'TROPHY ENGRAVERS',
  'TROPHY PARTS & SUPPLIES-WHOLESALE',
  'TROPICAL FISH-RETAIL',
  'TROPICAL FISH-WHOLESALE',
  'TROPICAL FOLIAGE SERVICE',
  'TRUCK & BUS BODIES (MANUFACTURERS)',
  'TRUCK ACCESSORIES (WHOLESALE)',
  'TRUCK AIR CONDITIONING EQUIP-REPAIRING',
  'TRUCK AIR CONDITIONING EQUIPMENT',
  'TRUCK AND BUS BODIES',
  'TRUCK BODIES & EQUIPMENT (WHOLESALE)',
  'TRUCK BODIES-MANUFACTURERS',
  'TRUCK BODIES-REPAIRING',
  'TRUCK BUMPERS & TAILGATES (WHOLESALE)',
  'TRUCK CANOPIES, CAPS & SHELLS ',
  'TRUCK CAPS & SHELLS-MANUFACTURERS',
  'TRUCK CAPS & SHELLS-WHOLESALE',
  'TRUCK CHASSIS ALTERATIONS & EQUIPMENT',
  'TRUCK DRIVER-LEASING',
  'TRUCK DRIVING INSTRUCTION',
  'TRUCK EQUIPMENT & PARTS-MANUFACTURERS',
  'TRUCK EQUIPMENT & PARTS-USED & REBUILT',
  'TRUCK EQUIPMENT & PARTS-WHOLESALE',
  'TRUCK LUBRICATING SERVICE',
  'TRUCK REFRIGERATION EQUIPMENT (WHOL)',
  'TRUCK REFRIGERATION EQUIPMENT-REPAIRING',
  'TRUCK RENTAL AND LEASING',
  'TRUCK RENTING & LEASING',
  'TRUCK SCALES',
  'TRUCK SLEEPERS-MANUFACTURERS',
  'TRUCK STOPS & PLAZAS',
  'TRUCK TERMINALS',
  'TRUCK TESTING STATIONS',
  'Truck Trailer Manufacturing',
  'TRUCK TRAILER-PARKING & STORAGE',
  'TRUCK TRAILERS',
  'TRUCK WASHING/CLEANING EQUIP/SUPLS-MFRS',
  'TRUCK-DEALERS',
  'TRUCK-DEALERS-USED',
  'TRUCK-DISTRIBUTORS',
  'TRUCK-LOADING & UNLOADING',
  'TRUCK-MANUFACTURERS',
  'TRUCK-PAINTING & LETTERING',
  'TRUCK-PERMIT SERVICE',
  'TRUCK-REPAIRING & SERVICE',
  'TRUCK-SEAT REPAIRING',
  'TRUCK-TRAILER (MANUFACTURERS)',
  'TRUCK-TRANSPORT PREPARATION',
  'TRUCK-TRANSPORTING',
  'TRUCK-WASHING & CLEANING',
  'TRUCK-WASHING/CLEANING EQPT/SUPLS (WHOL)',
  'TRUCK-WRECKING (WHOLESALE)',
  'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing ',
  'TRUCKING',
  'TRUCKING',
  'TRUCKING CONSULTANTS',
  'TRUCKING MANAGEMENT',
  'TRUCKING TERMINAL FACILITIES',
  'TRUCKING WATER',
  'TRUCKING-CONTAINERIZED FREIGHT',
  'TRUCKING-CONTRACT HAULING',
  'TRUCKING-DUMP',
  'TRUCKING-EXEMPT COMMODITY',
  'TRUCKING-HEAVY HAULING',
  'TRUCKING-LIQUID & DRY BULK',
  'TRUCKING-LOCAL CARTAGE',
  'TRUCKING-MOTOR FREIGHT',
  'TRUCKING-OVERDIMENSIONAL LOAD',
  'TRUCKING-REFRIGERATED',
  'TRUCKING-TRANSPORTATION BROKERS',
  'TRUCKS-INDUSTRIAL (WHOLESALE)',
  'TRUCKS-INDUSTRIAL-REPAIRING',
  'TRUNKS-DEALERS',
  'TRUNKS-MANUFACTURERS',
  'Truss Manufacturing',
  'TRUSSES (MANUFACTURERS)',
  'TRUSSES-CONSTRUCTION',
  'TRUSSES-CONSTRUCTION-MANUFACTURERS',
  'TRUSSES-MEDICAL & SURGICAL (WHOLESALE)',
  'TRUST COMPANIES',
  'TRUST SERVICE',
  'Trust, Fiduciary, and Custody Activities ',
  'TRUSTEES',
  'TRUSTS',
  'Trusts, Estates, and Agency Accounts ',
  'TRUSTS: EDUCATIONAL',
  'TUBE CLEANING & EXPANDING EQUIP (MFRS)',
  'TUBE CUTTING',
  'TUBE FITTINGS (WHOLESALE)',
  'TUBE-BENDING & FABRICATING',
  'TUBING (MANUFACTURERS)',
  'TUBING-COILS (MANUFACTURERS)',
  'TUBING-METAL (WHOLESALE)',
  'TUBING-METAL-FABRICATORS',
  'TUBING-METAL-MACHINERY (MANUFACTURERS)',
  'TUCK POINTING EQUIPMENT & SUPPLIES',
  'TUCKING (MANUFACTURERS)',
  'TUFTING',
  'TUFTING MACHINERY (MANUFACTURERS)',
  'TUGBOAT & BARGE BROKERS',
  'TUMBLING EQUIP & SUPPLIES (MFRS)',
  'TUNGSTEN (MANUFACTURERS)',
  'TUNNELING CONTRACTORS',
  'Turbine and Turbine Generator Set Units Manufacturing',
  'TURBINE SEAL',
  'TURBINES AND TURBINE GENERATOR SETS',
  'TURBINES-MANUFACTURERS',
  'TURBINES-WHOLESALE',
  'TURBOCHARGERS (WHOLESALE)',
  'TURF',
  'TURKEY FARMS',
  'Turkey Production',
  'TURKEYS AND TURKEY EGGS',
  'TURNBUCKLES (MANUFACTURERS)',
  'TURNSTILES (MANUFACTURERS)',
  'TURNTABLES (WHOLESALE)',
  'TURPENTINE (MANUFACTURERS)',
  'TURQUOISE (WHOLESALE)',
  'TUTORING',
  'Tutoring',
  'TUXEDOS',
  'TV',
  'TV Production',
  'TV PROGRAM CONTESTANT & TICKET INFO',
  'TV STATIONS & BROADCASTING CO CNSLNTS',
  'TWINES & CORDAGE (WHOLESALE)',
  'TWINES & CORDAGE-MANUFACTURERS',
  'Twitter',
  'TWO-WAY RADIO-EQUIPMENT & SUPPLIES',
  'TYING MACHINES (MANUFACTURERS)',
  'TYPE METALS (MANUFACTURERS)',
  'TYPE-FOUNDERS (MANUFACTURERS)',
  'TYPE-RUBBER (MANUFACTURERS)',
  'TYPESETTING',
  'TYPESETTING (MANUFACTURERS)',
  'TYPESETTING EQUIPMENT (WHOLESALE)',
  'TYPESETTING MACHINES-MANUFACTURERS',
  'TYPEWRITERS',
  'TYPEWRITERS-MANUFACTURERS',
  'TYPEWRITERS-REPAIRING',
  'TYPEWRITERS-SUPPLIES & ATTACHMENTS',
  'TYPEWRITERS-SUPPLIES & ATTACHMENTS-MFRS',
  'TYPEWRITERS-SUPPLIES & ATTACHMENTS-WHOL',
  'TYPEWRITERS-USED',
  'TYPEWRITERS-WHOLESALE',
  'TYPING SERVICE',
  'TYPOGRAPHERS',
  'U.S. POSTAL SERVICE',
  'Ultimate Frisbee',
  'ULTRASONIC EQUIPMENT & SUPPLIES (WHOL)',
  'ULTRASONIC INSPECTION SERVICE',
  'ULTRASOUND IMAGING-MEDICAL',
  'ULTRAVIOLET RAY LAMPS & APPARATUS (WHOL)',
  'UMBRELLAS',
  'UMBRELLAS-MANUFACTURERS',
  'UMBRELLAS-SUPPLIES (WHOLESALE)',
  'UMBRELLAS-WHOLESALE',
  'UNDERGROUND WIRE & CABLE LAYING CONTRS',
  'Underserved Children',
  'UNDERWEAR (MANUFACTURERS)',
  'UNDERWEAR TRIMMINGS (MANUFACTURERS)',
  "UNDERWEAR-CHILDREN'S",
  'UNDERWEAR-MENS-MANUFACTURERS',
  'UNDERWEAR-MENS-WHOLESALE',
  'UNDERWEAR-WHOLESALE',
  'UNEMPLOYMENT INSURANCE CONSULTANTS',
  'Unified Communications',
  'UNIFORM FABRICS (MANUFACTURERS)',
  'UNIFORM RENTAL SERVICE',
  'UNIFORM SUPPLY SERVICE',
  'UNIFORMS',
  'UNIFORMS-ACCESSORIES',
  'UNIFORMS-ACCESSORIES-WHOLESALE',
  'UNIFORMS-MANUFACTURERS',
  "UNIFORMS-MEN'S & WOMEN'S-WHOLESALE",
  'UNINTERRUPTIBLE POWER SYSTEMS',
  'UNIONS-INDUSTRIAL',
  'UNIT INVESTMENT TRUSTS-CERTIFICATE OFCS',
  'UNIVERSAL JOINTS (WHOLESALE)',
  'Universities',
  'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing',
  'Unlaminated Plastics Profile Shape Manufacturing',
  'UNSUPPORTED PLASTICS FILM AND SHEET',
  'UNSUPPORTED PLASTICS PROFILE SHAPES',
  'UNSUPPORTED PLASTICS-FILM/SHEET (MFRS)',
  'UPHOLSTERED HOUSEHOLD FURNITURE',
  'Upholstered Household Furniture Manufacturing',
  'UPHOLSTERERS',
  'UPHOLSTERERS SUPPLIES (WHOLESALE)',
  'UPHOLSTERERS SUPPLIES-MANUFACTURERS',
  'UPHOLSTERERS-WHOLESALE',
  'UPHOLSTERY CLEANING EQUIP/SUPLS (WHOL)',
  'UPHOLSTERY FABRICS-RETAIL',
  'UPHOLSTERY FABRICS-WHOLESALE',
  'URANIUM',
  'URANIUM EXPLORATION',
  'URANIUM RADIUM VANADIUM ORES',
  'Uranium-Radium-Vanadium Ore Mining',
  'URANIUM-RADIUM-VANADIUM ORES',
  'URBAN AND COMMUNITY DEVELOPMENT',
  'URETHANE & URETHANE PRODUCTS-MFRS',
  'Urethane and Other Foam Product (except Polystyrene) Manufacturing',
  'Usability Testing',
  'USED CAR DEALERS',
  'Used Car Dealers',
  'Used Household and Office Goods Moving',
  'USED MERCHANDISE STORES',
  'Used Merchandise Stores',
  'USHER SERVICE',
  'UTILITIES',
  'Utilities',
  'UTILITIES-HOLDING COMPANIES',
  'UTILITIES-UNDERGROUND-CABLE LOCATING SVC',
  'UTILITIES-UNDRGRND-CBL PIPE/WIRE (WHOL)',
  'UTILITY BILL CONSULTANTS',
  'UTILITY CONTRACTORS',
  'UTILITY MANAGEMENT',
  'UTILITY TRAILER RENTAL',
  'UX Design',
  'Vacation Rental',
  'VACATION RENTALS',
  'VACATION TIME SHARING PLANS',
  'VACUUM BOTTLES & JUGS (MANUFACTURERS)',
  'VACUUM CLEANERS-HOUSEHOLD-DEALERS',
  'VACUUM CLEANERS-INDUSTRIAL & COML (WHOL)',
  'VACUUM CLEANERS-RENTING',
  'VACUUM CLEANERS-REPAIRING & PARTS',
  'VACUUM CLEANERS-SUPLS & PARTS-WHOLESALE',
  'VACUUM CLEANERS-SUPPLIES & PARTS',
  'VACUUM CLEANERS-SUPPLIES & PARTS-MFRS',
  'VACUUM CLEANERS-WHOLESALE',
  'VACUUM CLEANING',
  'VACUUM CLEANING CONTRS-BLDG INTERIOR',
  'VACUUM CLEANING SYSTEMS (WHOLESALE)',
  'VACUUM CLEANING SYSTEMS-REPAIR & PARTS',
  'VACUUM CLEANING-INDUSTRIAL (WHOLESALE)',
  'VACUUM EQUIPMENT & SYSTEMS (WHOLESALE)',
  'VACUUM EQUIPMENT & SYSTEMS-MFRS',
  'VACUUM PLATING (MANUFACTURERS)',
  'VALANCES (WHOLESALE)',
  'VALET SERVICE',
  'VALVE ACTUATORS (WHOLESALE)',
  'VALVES & PIPE FITTINGS NEC (MFRS)',
  'VALVES AND PIPE FITTINGS',
  'VALVES-MANUFACTURERS',
  'VALVES-REPAIRING',
  'VALVES-RETAIL',
  'VALVES-USED',
  'VALVES-WHOLESALE',
  'VAN & TRUCK CONVERSIONS & ACCESSORIES',
  'VAN ACCESSORIES',
  'VAN CONVERSIONS-MANUFACTURERS',
  'VAN CONVERSIONS-WHOLESALE',
  'VANS-DEALERS',
  'VANS-RENTING & LEASING',
  'VAPORIZERS',
  'VARIABLE SPEED DRIVES (WHOLESALE)',
  'VARIETY STORE MERCHANDISE-WHOLESALE',
  'VARIETY STORES',
  'VARIETY STORES',
  'VEG OIL MILLS-EX CORN SOYBEAN (MFRS)',
  'VEGETABLE CONCENTRATES (MANUFACTURERS)',
  'VEGETABLE GROWERS',
  'VEGETABLE JUICES',
  'VEGETABLE OIL MILLS',
  'VEGETABLES & MELONS',
  'VEGETABLES AND MELONS',
  'VEGETABLES-PROCESSED',
  'VEHICLE EMISSION TESTING-SVC & REPAIR',
  'VEHICULAR LIGHTING EQUIPMENT',
  'VEHICULAR LIGHTING EQUIPMENT (MFRS)',
  'VEILING',
  'VEIN REMOVAL',
  'VELVETS & VELVETEENS-WHOLESALE',
  'Vending and Concessions',
  'VENDING BROKERS',
  'VENDING CARTS (MANUFACTURERS)',
  'Vending Machine Operators',
  'VENDING MACHINES',
  'VENDING MACHINES SUPPLIES & PARTS (WHOL)',
  'VENDING MACHINES SUPPLIES & PARTS-MFRS',
  'VENDING MACHINES-MANUFACTURERS',
  'VENDING MACHINES-WHOLESALE',
  'VENETIAN BLINDS-CLEANING',
  'VENETIAN BLINDS-EQUIPMENT & SUPPLIES',
  'VENETIAN BLINDS-RETAIL',
  'VENETIAN BLINDS-WHOLESALE',
  'VENTILATING CONTRACTORS',
  'VENTILATING EQUIPMENT (WHOLESALE)',
  'VENTILATING EQUIPMENT-MANUFACTURERS',
  'VENTILATING SYSTEMS-CLEANING',
  'VENTILATING SYSTEMS-PLASTIC-MFRS',
  'VENTRILOQUISTS',
  'VENTURE CAPITAL',
  'Venture Capital',
  'Venture Capital & Private Equity',
  'VENTURE CAPITAL COMPANIES',
  'VERMICULITE (MANUFACTURERS)',
  'VERTICAL BLINDS-CLEANING & REPAIRING',
  'VERTICAL BLINDS-INSTALLATION/SERVICE',
  'VERTICAL BLINDS-WHOLESALE',
  'Vertical Search',
  'VESSEL DOCUMENTATION',
  'VESSELS-PRESSURE (MANUFACTURERS)',
  'VETERANS & MILITARY INFORMATION/SVCS',
  "VETERANS' & MILITARY ORGANIZATIONS",
  'VETERINARIAN EMERGENCY SERVICES',
  'VETERINARIAN REFERRAL/INFORMATION SVCS',
  'VETERINARIANS',
  'VETERINARIANS EQUIPMENT & SUPLS (WHOL)',
  'VETERINARIANS EQUIPMENT & SUPLS-MFRS',
  'VETERINARIANS-DERMATOLOGISTS',
  'VETERINARIANS-DVM DENTISTRY',
  'VETERINARIANS-DVM-NEUROLOGISTS',
  'VETERINARIANS-LABORATORY ANIMAL MED',
  'VETERINARIANS-OPTHALMOLOGISTS',
  'VETERINARIANS-RADIOLOGISTS',
  'VETERINARIANS-SURGEONS',
  'Veterinary',
  'VETERINARY MEDICAL LABORATORIES',
  'VETERINARY PHARMACIES & SERVICES',
  'VETERINARY SERVICES',
  'Veterinary Services',
  'VETERINARY SERVICES FOR LIVESTOCK',
  'VIALS (MANUFACTURERS)',
  'VIATICAL SETTLEMENTS',
  'VIBRATION CONTROL',
  'VIBRATION ISOLATION',
  'VIBRATION MEASUREMENT SERVICE',
  'Video',
  'Video Advertising',
  'VIDEO CAMERAS-WHOLESALE & MFRS',
  'Video Chat',
  'VIDEO CONFERENCING',
  'Video Conferencing',
  'Video Editing',
  'VIDEO EQUIPMENT',
  'VIDEO EQUIPMENT SUPPLIES & PARTS',
  'VIDEO EQUIPMENT-MANUFACTURERS',
  'VIDEO EQUIPMENT-SECURITY & IND SYSTEMS',
  'VIDEO EQUIPMENT-SERVICE & REPAIR',
  'VIDEO EQUIPMENT-WHOLESALE',
  'VIDEO GAMEROOMS',
  'VIDEO GAMES',
  'Video Games',
  'VIDEO GAMES & EQUIPMENT-SALES & RENTAL',
  'VIDEO GAMES-MANUFACTURERS',
  'VIDEO GAMES-RENTING & LEASING',
  'VIDEO GAMES-SERVICE & REPAIR',
  'VIDEO GAMES-SUPPLIES & PARTS',
  'VIDEO GAMES-WHOLESALE',
  'Video on Demand',
  'VIDEO POKER',
  'VIDEO PRODUCTION & TAPING SERVICE',
  'VIDEO RECORDERS & PLAYERS',
  'VIDEO RECORDERS & PLAYERS-MANUFACTURERS',
  'VIDEO RECORDERS & PLAYERS-RENTING',
  'VIDEO RECORDERS & PLAYERS-SERVICE REPAIR',
  'VIDEO RECORDERS & PLAYERS-SUPLS & PARTS',
  'VIDEO RECORDERS & PLAYERS-WHOLESALE',
  'Video Streaming',
  'Video Tape and Disc Rental',
  'VIDEO TAPE DUPLICATION SERVICE',
  'VIDEO TAPE EDITING',
  'VIDEO TAPE RENTAL',
  'VIDEO TAPE-RENTAL',
  'VIDEO TAPES & DISCS-MANUFACTURERS',
  'VIDEO TAPES & DISCS-RENTING & LEASING',
  'VIDEO TAPES & DISCS-WHOLESALE',
  'VIDEO TAPES DISCS & CASSETTES',
  'VIDEO TRANSMISSION SVC',
  'VIDEO-DISTRIBUTORS',
  'VIDEOGRAPHY',
  'VIDEOTEXT SERVICES',
  'VILLAS',
  'VINEGAR (MANUFACTURERS)',
  'VINEYARD PRODUCTS & SUPPLIES (WHOL)',
  'VINEYARDS',
  'VINTAGE CLOTHING STORES',
  'VINYL',
  'VINYL LAYERS (INSTALLERS)',
  "VINYL LAYERS' EQUIPMENT & SUPLS (WHOL)",
  'VINYL-DEALERS',
  'VINYL-MANUFACTURERS',
  'VINYL-REPAIR SERVICE',
  'VINYL-WHOLESALE',
  'VIOLINS',
  'Virtual Assistant',
  'Virtual Currency',
  'Virtual Desktop',
  'Virtual Goods',
  'Virtual Reality',
  'Virtual Workforce',
  'Virtual World',
  'Virtualization',
  'VISES (WHOLESALE)',
  'VISORS (MANUFACTURERS)',
  'VISUAL CONTROL SYSTEMS (WHOLESALE)',
  'Visual Search',
  'VITAMIN PRODUCTS-MANUFACTURERS',
  'VITAMINS',
  'VITAMINS & FOOD SUPPLEMENTS-WHOLESALE',
  'VITAMINS-RAW MATERIALS',
  'VITREOUS CHINA TABLE AND KITCHENWARE',
  'VITREOUS CHINA-TBL/KTCHN ARTICLES (MFRS)',
  'VITREOUS PLUMBING FIXTURES',
  'Vocational Education',
  'VOCATIONAL GUIDANCE',
  'Vocational Rehabilitation Services',
  'VOCATIONAL SCHOOLS',
  'VOCATIONAL SCHOOLS NEC',
  'VOICE MAIL MESSAGING SERVICE',
  'VOICE MESSAGING EQUIPMENT & SERVICES',
  'VoIP',
  'Volley Ball',
  'VOLLEYBALL CLUBS',
  'VOLTAGE STABILIZERS (MANUFACTURERS)',
  'Voluntary Health Organizations',
  'VOLUNTEER WORKERS PLACEMENT SERVICE',
  'VOTER REGISTRATION',
  'VOTING MACHINES & EQUIPMENT (WHOL)',
  'WADDING-MANUFACTURERS',
  'WAISTBANDS (MANUFACTURERS)',
  'WAITER & WAITRESS SERVICES',
  'WAKE-UP CALL SERVICE',
  'WALL CLEANING',
  'WALL COATINGS',
  'WALL COATINGS-CONTRACTORS',
  'WALL FINISHES',
  'WALL PRINTING',
  'WALLBOARD & PLASTERBOARD',
  'WALLBOARD & PLASTERBOARD-MANUFACTURERS',
  'WALLBOARD & PLASTERBOARD-WHOLESALE',
  'WALLCOVERING CONTRS EQUIP & SUPLS (WHOL)',
  'WALLPAPER DESIGNERS',
  'WALLPAPER REMOVAL CONTRACTORS',
  'WALLPAPER REMOVING EQUIPMENT',
  'WALLPAPER REMOVING MACHINES-RENTING',
  'WALLPAPERS & WALLCOVERINGS-EQUIP & SUPL',
  'WALLPAPERS & WALLCOVERINGS-INSTALLATION',
  'WALLPAPERS & WALLCOVERINGS-RETAIL',
  'WALLPAPERS & WALLCOVERINGS-WHOLESALE',
  'WALLPAPERS-MANUFACTURERS',
  'WALLS',
  'WARDROBES (MANUFACTURERS)',
  'Warehouse Clubs and Supercenters',
  'WAREHOUSES',
  'WAREHOUSES-AUTOMOTIVE (WHOLESALE)',
  'WAREHOUSES-BONDED',
  'WAREHOUSES-COLD STORAGE',
  'WAREHOUSES-COLD STORAGE-EQPT/SUPL (WHOL)',
  'WAREHOUSES-COMMODITY & MERCHANDISE',
  'WAREHOUSES-COTTON',
  'WAREHOUSES-MERCHANDISE & SELF STORAGE',
  'WAREHOUSES-MINI & SELF STORAGE',
  'WAREHOUSES-PRIVATE & PUBLIC',
  'WAREHOUSES-REPRESENTATIVES',
  'WAREHOUSES-SELF STORAGE',
  'WAREHOUSES-STEEL & HEAVY MACHINERY',
  'Warehousing',
  'WAREHOUSING-FIELD',
  'WARM AIR HEATING AND AIR CONDITIONING',
  'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers',
  'WARNING SYSTEMS',
  'WARRANTY PROGRAMS-EXTENDED',
  'WASHERS',
  'WASHING MACHINES DRYERS/IRONERS-DEALERS',
  'WASHING MACHINES DRYERS/IRONERS-MFRS',
  'WASHING MACHINES DRYERS/IRONERS-RENTING',
  'WASHING MACHINES DRYERS/IRONERS-SVC/RPR',
  'WASHING MACHINES DRYERS/IRONERS-USED',
  'WASHING MACHINES DRYERS/IRONERS-WHOL',
  'WASHING MACHINES-INDUSTRIAL (WHOLESALE)',
  'WASHING MACHS DRYERS/IRONERS-COIN (WHOL)',
  'WASHING MACHS DRYERS/IRONERS-SUPL (WHOL)',
  'WASTE (WHOLESALE)',
  'WASTE CONSULTANTS',
  'WASTE CONTAINERS',
  'WASTE CONTAINERS-MANUFACTURERS',
  'WASTE DISPOSAL',
  'WASTE DISPOSAL EQUIPMENT (WHOLESALE)',
  'WASTE DISPOSAL-HAZARDOUS',
  'Waste Management',
  'WASTE MATERIALS',
  'WASTE RDCTN DSPSL/RECYCLE SVC-IND (WHOL)',
  'WASTE REDUCTION & DISPOSAL EQUIP-IND',
  'WASTE REDUCTION DISPOSAL EQUIP-IND-MFRS',
  'WASTE REMOVAL-MEDICAL',
  'WASTE WATER DISPOSAL',
  'WASTE WATER TREATMENT',
  'WASTE-COTTON WOOL SYNTHETIC ETC (WHOL)',
  'WASTE-GREASE (WHOLESALE)',
  'WASTE-HAZARDOUS-TRANSPORTING',
  'WASTE-OIL FURNACES-SALES & SVCS (WHOL)',
  'WASTE-PAPER (WHOLESALE)',
  'WASTE-PAPER-BUYERS',
  'WASTE-PLASTICS (WHOLESALE)',
  'WATCH',
  'WATCH CASES (MANUFACTURERS)',
  'WATCH CLOCK & JEWELRY-REPAIR',
  'WATCH CRYSTALS (MANUFACTURERS)',
  'WATCH DIALS (MANUFACTURERS)',
  'WATCH INSPECTORS',
  'WATCH STRAPS & BANDS',
  'WATCH STRAPS & BANDS-MANUFACTURERS',
  'WATCH STRAPS & BANDS-WHOLESALE',
  'WATCHES AND PARTS',
  'WATCHES CLCKS CLOCKWORK ETC/PARTS (MFRS)',
  'WATCHES-DEALERS',
  'WATCHES-MANUFACTURERS',
  'WATCHES-REPAIRING',
  'WATCHES-WHOLESALE',
  "WATCHMAKERS' EQUIPMENT & SUPLS (WHOL)",
  "WATCHMEN'S EQUIPMENT & SYSTEMS",
  'WATER',
  'Water',
  'WATER & SEWAGE COMPANIES-UTILITY',
  'WATER ANALYSIS',
  'Water and Sewer Line and Related Structures Construction',
  'WATER BEDS',
  'WATER BEDS-MANUFACTURERS',
  'WATER BEDS-SERVICE & REPAIR',
  'WATER BEDS-SUPPLIES & PARTS (WHOL)',
  'WATER BEDS-WHOLESALE',
  'WATER COMPANIES-BOTTLED, BULK, ETC ',
  'WATER CONDITIONERS (WHOLESALE)',
  'WATER CONSERVATION',
  'WATER CONSERVATION PRODUCTS & SERVICES',
  'WATER COOLERS EQUIPMENT & SUPLS (WHOL)',
  'WATER COOLERS FOUNTAINS & FILTERS (WHOL)',
  'WATER COOLERS FOUNTAINS/FILTERS-REPAIR',
  'WATER COOLERS-MANUFACTURERS',
  'WATER COOLERS-RENTING',
  'WATER DAMAGE RESTORATION-RESIDENTIAL',
  'WATER DEALERS',
  'WATER DEIONIZATION',
  'WATER DETECTION/PROTECTION SYSTS (MFRS)',
  'WATER DISTILLED-MANUFACTURERS',
  'WATER GARDENS',
  'WATER HAULING SERVICE',
  'WATER HEATERS-DEALERS',
  'WATER HEATERS-INDUSTRIAL (WHOLESALE)',
  'WATER HEATERS-MANUFACTURERS',
  'WATER HEATERS-PARTS & SUPPLIES (WHOL)',
  'WATER HEATERS-RENTAL',
  'WATER HEATERS-REPAIRING',
  'WATER HEATERS-WHOLESALE',
  'WATER JET CUTTING',
  'WATER MAIN CONTRACTORS',
  'WATER PARK RIDES',
  'WATER PASSENGER TRANSPORTATION',
  'WATER POLLUTION CONTROL',
  'WATER POLLUTION MEASURING SERVICE',
  'WATER PRESSURE CLEANING',
  'Water Purification',
  'WATER PURIFICATION CONSULTING SERVICES',
  'WATER PURIFICATION-CHEMICAL (WHOLESALE)',
  'WATER PURIFICATION/FILTRATION EQPT-MFRS',
  'WATER PURIFICATION/FILTRATION EQPT-WHOL',
  'WATER RIGHTS',
  'WATER SCALE REMOVERS (MANUFACTURERS)',
  'WATER SKIING EQUIPMENT & SUPPLIES',
  'WATER SKIING EQUIPMENT & SUPPLIES-MFRS',
  'WATER SKIING INSTRUCTION',
  'WATER SOFTENING EQUIP SVC/SUPLS-RENTING',
  'WATER SOFTENING EQUIP SVC/SUPLS-WHOL',
  'WATER SOFTENING EQUIPMENT SVC & SUPLS',
  'WATER SOFTENING/COND EQUIP SVC/SUPL-MFRS',
  'WATER SPORTS EQUIPMENT-RENTAL',
  'WATER SUPPLY',
  'Water Supply and Irrigation Systems',
  'WATER SUPPLY SYSTEMS',
  'WATER SYSTEMS',
  'WATER TESTING',
  'Water Transportation',
  'WATER TRANSPORTATION OF FREIGHT',
  'WATER TRANSPORTATION OF FREIGHT NEC',
  'WATER TRANSPORTATION SERVICES',
  'WATER TRANSPORTATION SERVICES NEC',
  'WATER TREATMENT EQUIP SVC & SUPLS',
  'WATER TREATMENT EQUIP SVC & SUPLS-MFRS',
  'WATER TREATMENT EQUIP SVC & SUPLS-WHOL',
  'WATER TREATMENT MANAGEMENT PLANT',
  'WATER WELL ABANDONMENT SERVICE',
  'WATER WELL CASINGS (WHOLESALE)',
  'WATER WELL DRILLING',
  'WATER WELL DRILLING & SERVICE',
  'WATER WELL DRILLING EQUIP & SUPLS-MFRS',
  'WATER WELL DRILLING EQUIP & SUPLS-WHOL',
  'WATER WELL LOCATING',
  'WATER WHEELS',
  'WATER WORKS CONTRACTORS',
  'WATER WORKS EQUIPMENT & SUPPLIES-MFRS',
  'WATER-DISTILLED (WHOLESALE)',
  'WATER-PASSENGER TRANSPORTATION NEC',
  'WATER-SEWER PIPELINE & COMMS/POWER LINE',
  'WATERCRAFT-PERSONAL',
  'WATERERS-LIVESTOCK (MANUFACTURERS)',
  'WATERFALLS-ARTIFICIAL',
  'WATERPROOF OUTERWEAR',
  'WATERPROOF OUTERWEAR (MANUFACTURERS)',
  'WATERPROOFING CONTRACTORS',
  'WATERPROOFING MATERIALS (WHOLESALE)',
  'WATERPROOFING MATERIALS-MANUFACTURERS',
  'WATERPROOFING-TEXTILE (MANUFACTURERS)',
  'WAVE RUNNERS',
  'WAX (WHOLESALE)',
  'WAX APPLICATORS (MANUFACTURERS)',
  'Wealth Management',
  'Wearables',
  'WEATHER FORECAST SERVICE',
  'WEATHER INSTRUMENTS (WHOLESALE)',
  'WEATHER INSTRUMENTS-MFRS',
  'WEATHER MODIFICATION SERVICE',
  'WEATHER RESEARCH',
  'WEATHER STRIPPING CONTRACTORS',
  'WEATHER STRIPPING MATERIALS (WHOLESALE)',
  'WEATHER STRIPS',
  'WEATHER STRIPS-MANUFACTURERS',
  'WEATHER STRIPS-WHOLESALE',
  'WEATHER VANES',
  'WEAVING & MENDING',
  'WEAVING & MENDING EQUIPMENT & SUPPLIES',
  'WEAVING-LOOM',
  'Web Apps',
  'Web Browsers',
  'Web Design',
  'Web Development',
  'Web Hosting',
  'WEB SITE DESIGN SERVICE',
  'WEBBING',
  'WebOS',
  'Wedding',
  'WEDDING ANNOUNCEMENTS & INVITATIONS',
  'WEDDING CHAPELS',
  'WEDDING CONSULTANTS PLANNING/ARRANGING',
  'WEDDING FLOWERS',
  'WEDDING PHOTOGRAPHERS',
  'WEDDING SUPPLIES & SERVICES',
  'WEDDING SUPPLIES & SERVICES-MFRS',
  'WEDDING SUPPLIES-WHOLESALE',
  'WEDDING-VIDEO TAPING SERVICES',
  'WEED CONTROL EQUIPMENT & SUPPLIES',
  'WEED CONTROL SERVICE',
  'WEFT KNIT FABRIC MILLS',
  'WEFT KNIT FABRIC MILLS',
  'WEIGHERS',
  'WEIGHT CONTROL PREPARATION',
  'WEIGHT CONTROL SERVICES',
  'WEIGHTS',
  'WELCOMING SERVICE FOR NEWCOMERS',
  'WELDING',
  'Welding and Soldering Equipment Manufacturing',
  'WELDING APPARATUS',
  'WELDING EQUIPMENT & SUPPLIES (WHOLESALE)',
  'WELDING EQUIPMENT & SUPPLIES-MFRS',
  'WELDING EQUIPMENT-RENTING',
  'WELDING EQUIPMENT-REPAIRING',
  'WELDING INSPECTION & CONSULTING',
  'WELDING REPAIR',
  'WELDING-HOUSEHOLD/MISC ITEMS-REPAIRING',
  'WELDING-INDUSTRIAL & COMMERCIAL',
  'WELDING-SPECIFICATIONS CONSULTANTS',
  'WELL CONTRACTORS-ARTESIAN',
  'WELL DRILLING',
  'WELL DRILLING EQUIPMENT & SUPLS (WHOL)',
  'WELL MAINTENANCE SERVICE',
  'WELL PUMPS',
  'WELL TESTING',
  'Wellness',
  'WELLNESS PROGRAMS',
  'WELLPOINT SYSTEMS (WHOLESALE)',
  'WELTING (MANUFACTURERS)',
  'WESTERN APPAREL',
  'WESTERN APPAREL-MANUFACTURERS',
  'WESTERN APPAREL-WHOLESALE',
  'WET CORN MILLING',
  'WET CORN MILLING',
  'Wet Corn Milling',
  'WETLANDS CONSULTANTS',
  'WHALE WATCHING',
  'WHEAT',
  'WHEAT',
  'Wheat Farming',
  'WHEEL & RIM REPAIRING',
  'WHEEL ALIGNMENT-FRAME & AXLE SVC-AUTO',
  'WHEEL ALIGNMENT-FRAME SVC-EQUIP-MFRS',
  'WHEEL ALIGNMENT-FRM/AXLE SVC-EQPT (WHOL)',
  'WHEEL CHAIR LIFTS & RAMPS (WHOLESALE)',
  'WHEEL CHAIR VAN LIFTS',
  'WHEEL CHAIRS & SCOOTERS',
  'WHEEL CHAIRS-MANUFACTURERS',
  'WHEEL CHAIRS-RENTING',
  'WHEEL CHAIRS-REPAIRING',
  'WHEEL CHAIRS-WHOLESALE',
  'WHEEL SUSPENSION SYSTEMS',
  'WHEELBARROWS',
  'WHEELS & WHEEL COVERS',
  'WHEELS-INDUSTRIAL (WHOLESALE)',
  'WHEELS-MANUFACTURERS',
  'WHEELS-WHOLESALE',
  'WHIPPED CREAM DISPENSERS (WHOLESALE)',
  'WHIRLPOOL BATH EQUIP & SUPLS-WHOLESALE',
  'WHIRLPOOL BATH EQUIP & SUPPLIES-MFRS',
  'WHIRLPOOL BATH EQUIPMENT & SUPPLIES',
  'WHISTLES (MANUFACTURERS)',
  'WHITE METAL CASTINGS',
  'WHITE METAL CASTINGS EQUIPMENT (MFRS)',
  'WHITEWASHING',
  'WHOLESALE',
  'Wholesale',
  'WHOLESALE CLUBS',
  'Wholesale Trade Agents and Brokers',
  'WHOLESALERS',
  'WICKER PRODUCTS',
  'WIGS & HAIRPIECES-MANUFACTURERS',
  'WIGS TOUPEES & HAIRPIECES',
  'WIGS-WHOLESALE',
  'WILD RICE-WHOLESALE',
  'WILDLIFE CONSERVATION',
  'WILDLIFE SERVICES',
  'WINCHES (WHOLESALE)',
  'Wind Electric Power Generation',
  'Wind Energy',
  'WIND ENERGY SYSTEMS (WHOLESALE)',
  'WIND SURFING',
  'WIND SURFING EQUIPMENT',
  'WINDING MACHINERY',
  'WINDMILLS (MANUFACTURERS)',
  'WINDOW CLEANING',
  'WINDOW CLEANING EQUIPMENT & SUPLS (WHOL)',
  'WINDOW COVERINGS-DEALERS',
  'WINDOW REPLACEMENT',
  'WINDOW SHADES EQUIP & SUPLS-WHOLESALE',
  'WINDOW SHADES EQUIPMENT & SUPPLIES',
  'WINDOW SHADES-CLEANING & REPAIRING',
  'WINDOW SHADES-MANUFACTURERS',
  'WINDOW SHADES-WHOLESALE',
  'WINDOW TINTING MATERIALS',
  'WINDOW TINTING/COATING MTRLS-WHOLESALE',
  'Window Treatment Stores',
  'WINDOW TREATMENTS',
  'WINDOW WELL COVERS (WHOLESALE)',
  'WINDOWS',
  'Windows',
  'Windows Phone',
  'WINDOWS-GLASS BLOCK',
  'WINDOWS-LOUVERED',
  'WINDOWS-MANUFACTURERS',
  'WINDOWS-METAL',
  'WINDOWS-PLASTIC',
  'WINDOWS-PLASTIC-MANUFACTURERS',
  'WINDOWS-REPAIRING',
  'WINDOWS-USED',
  'WINDOWS-VINYL',
  'WINDOWS-WHOLESALE',
  'WINDOWS-WOOD',
  'WINDOWS-WOOD-MANUFACTURERS',
  'WINDOWS-WOOD-WHOLESALE',
  'WINDSHIELD WIPERS',
  'WINDSHIELD WIPERS-MANUFACTURERS',
  'WINDSOCKS',
  'Wine and Distilled Alcoholic Beverage Merchant Wholesalers',
  'WINE AND DISTILLED BEVERAGES',
  'Wine and Spirits',
  'WINE BROKERS',
  'WINE CONSULTANTS',
  "WINE MAKERS' EQUIPMENT & SUPPLIES",
  'WINE STORAGE',
  'WINE STORAGE EQUIPMENT-WHOLESALE',
  'WINERIES',
  'Wineries',
  'Winery',
  'WINERY EQUIPMENT & SUPPLIES (WHOLESALE)',
  'WINES',
  'WINES BRANDY & BRANDY SPIRITS (MFRS)',
  'WINES-RETAIL',
  'WINES-SACRAMENTAL (WHOLESALE)',
  'WINES-WHOLESALE',
  'WIPING CLOTHS (WHOLESALE)',
  'WIPING CLOTHS-MANUFACTURERS',
  'WIRE (WHOLESALE)',
  'WIRE & CABLE MACHINERY-MANUFACTURERS',
  'WIRE & CABLE MARKERS (MANUFACTURERS)',
  'WIRE & CABLE-ELECTRIC (WHOLESALE)',
  'WIRE & CABLE-NON-ELECTRIC (WHOLESALE)',
  'WIRE CLOTH (MANUFACTURERS)',
  'WIRE CLOTH-WHOLESALE',
  'WIRE DRAWING EQUIPMENT (MANUFACTURERS)',
  'WIRE FORMING & WORKING EQUIPMENT (WHOL)',
  'WIRE FORMS & SPECIALTIES (MFRS)',
  'WIRE GOODS (MANUFACTURERS)',
  'WIRE HARNESSES-ELECTRICAL (WHOLESALE)',
  'WIRE HARNESSES-ELECTRICAL-MANUFACTURERS',
  'WIRE MESH WORK',
  'WIRE PRODUCTS (WHOLESALE)',
  'WIRE PRODUCTS-MANUFACTURERS',
  'WIRE ROPE (WHOLESALE)',
  'WIRE ROPE FITTINGS (WHOLESALE)',
  'WIRE ROPE-MANUFACTURERS',
  'WIRE SPOOLING & COILING (MANUFACTURERS)',
  'WIRE SPRINGS',
  'WIRE STRIPPERS (MANUFACTURERS)',
  'WIRE WORK (WHOLESALE)',
  'WIRE WRAPPING SERVICE',
  'WIRE-INSULATED (WHOLESALE)',
  'WIRE-MANUFACTURERS',
  'WIRE-STRAIGHTENING & CUTTING',
  'Wired Telecommunications',
  'Wired Telecommunications Carriers',
  'Wireless',
  'Wireless Telecommunications Carriers (except Satellite)',
  'WIRELINE SERVICE',
  'WIRING & CABLING-HOME & OFFICE-VOICE',
  'WITCH HAZEL DISTILLERIES',
  "WOMEN'S",
  "Women's",
  "WOMEN'S ACCESSORIES-WHOLESALE",
  "WOMEN'S ACCESSORY AND SPECIALTY STORES",
  "WOMEN'S AND CHILDREN'S CLOTHING",
  "WOMEN'S AND CHILDREN'S UNDERWEAR",
  "WOMEN'S AND MISSES' BLOUSES AND SHIRTS",
  "WOMEN'S AND MISSES' OUTERWEAR",
  "WOMEN'S AND MISSES' SUITS AND COATS",
  "WOMEN'S APPAREL-CONTRACT MANUFACTURERS",
  "WOMEN'S APPAREL-MANUFACTURERS",
  "WOMEN'S APPAREL-RETAIL",
  "WOMEN'S APPAREL-WHOLESALE",
  "WOMEN'S CLOTHING STORES",
  "Women's Clothing Stores",
  "WOMEN'S COATS & SUITS-MANUFACTURERS",
  "WOMEN'S COATS & SUITS-WHOLESALE",
  "WOMEN'S EXCHANGES",
  "WOMEN'S FOOTWEAR",
  "Women's Handbag and Purse Manufacturing",
  "WOMEN'S HANDBAGS & PURSES (MFRS)",
  "WOMEN'S HANDBAGS AND PURSES",
  "WOMEN'S HOSIERY",
  "WOMEN'S MISSES/JRS OUTERWEAR NEC (MFRS)",
  "WOMEN'S ORGANIZATIONS & SERVICES",
  "WOMEN'S SPECIALTY SHOPS",
  "Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers ",
  'WOMENS FOOTWEAR-EXCEPT ATHLETIC (MFRS)',
  'WOMENS FULL-LENGTH/KNEE-LNTH HSRY (MFRS)',
  'WOMENS MISSES JR SUITS SKRTS/COATS (MFR)',
  'WOMENS MISSES/CHILDRENS UNDERWEAR (MFRS)',
  'Women�s, Girls�, and Infants� Cut and Sew Apparel Manufacturing ',
  'WOOD (TREE SERVICE & LANDSCAPING)',
  'WOOD BENDING (WHOLESALE)',
  'WOOD BURNING EQUIPMENT',
  'WOOD BURNING STOVES',
  'WOOD CARVING',
  'Wood Container and Pallet Manufacturing',
  'WOOD CONTAINERS',
  'WOOD CONTAINERS NEC (MANUFACTURERS)',
  'WOOD FINISHING & REFINISHING SERVICE',
  'WOOD FINISHING SUPPLIES (WHOLESALE)',
  'WOOD HOUSEHOLD FURNITURE',
  'Wood Kitchen Cabinet and Countertop Manufacturing',
  'WOOD KITCHEN CABINETS',
  'WOOD OFFICE FURNITURE',
  'Wood Office Furniture Manufacturing',
  'WOOD PALLETS AND SKIDS',
  'WOOD PARTITIONS AND FIXTURES',
  'WOOD PELLETS-FUEL',
  'Wood Preservation',
  'WOOD PRESERVATIVES',
  'WOOD PRESERVING',
  'WOOD PRESERVING (PRODUCTS)',
  'Wood Processing',
  'WOOD PRODUCTS',
  'WOOD PRODUCTS',
  'WOOD PRODUCTS NEC (MANUFACTURERS)',
  'WOOD PRODUCTS-MANUFACTURERS',
  'WOOD RESTORING PRODUCTS (WHOLESALE)',
  'WOOD SAWING',
  'WOOD SPECIALTIES',
  'WOOD TELEVISION AND RADIO CABINETS',
  'Wood Window and Door Manufacturing',
  'WOOD-FLOOR (MANUFACTURERS)',
  'WOOD-HEATING',
  'WOOD-HOUSEHOLD FURN-EX UPHOLSTERED (MFR)',
  'WOOD-HOUSEHOLD FURN-UPHOLSTERED (MFRS)',
  'WOOD-MANUFACTURERS',
  'WOOD-OFFICE FURNITURE (MANUFACTURERS)',
  'WOOD-TECHNOLOGISTS',
  'WOOD-TURNING',
  'WOOD-TV RADIO PHONO/SEWING CABNTS (MFRS)',
  'WOOD-WASTE & RECYCLING (WHOLESALE)',
  'WOODENWARE',
  'WOODWORK REFINISHING',
  'WOODWORKERS',
  "WOODWORKERS' EQUIPMENT & SUPPLIES (WHOL)",
  'WOODWORKING EQUIPMENT & SUPPLIES-MFRS',
  'WOODWORKING MACHINERY',
  'WOODWORKING MACHINERY (MANUFACTURERS)',
  'WOODWORKING MACHINERY EQUIP/SUPLS (MFRS)',
  'WOOL (WHOLESALE)',
  'WOOL BROKERS',
  'WOOL GARNETTING',
  'WOOL SCOURING',
  'WOOLEN GOODS-WHOLESALE',
  'WOOLEN MILLS',
  'WOOLENS-RETAIL',
  'WORD PROCESSING EQUIPMENT & SUPLS (WHOL)',
  'WORD PROCESSING EQUIPMENT-MAINTENANCE',
  'WORD PROCESSING SERVICE',
  'WORD PROCESSING SYSTEMS-CONSLNTS/DESIGN',
  'WORK BENCHES (WHOLESALE)',
  'WORK CLOTHING-RETAIL',
  'WORK CLOTHING-WHOLESALE',
  "WORKMEN'S COMPENSATION CONSULTANTS",
  'WORLD TRADE CENTERS',
  'WORSTED GOODS (WHOLESALE)',
  'WOVENS-YARN DYED FABRIC-MANUFACTURERS',
  'WREATHS',
  'WRECKER SERVICE',
  'WRECKER SERVICE EQUIPMENT',
  'WRECKERS-DEALERS',
  'WRECKING AND DEMOLITION WORK',
  'WRITERS',
  'WRITERS-BUSINESS',
  'Writing and Editing',
  'WRITING CONSULTANTS',
  'X-RAY APPARATUS & SUPPLIES (WHOLESALE)',
  'X-RAY APPARATUS & SUPPLIES-MANUFACTURERS',
  'X-RAY APPARATUS & TUBES (MANUFACTURERS)',
  'X-RAY APPARATUS AND TUBES',
  'X-RAY APPARATUS-REPAIRING',
  'X-RAY DUPLICATING SERVICE',
  'X-RAY INSPECTION SERVICE',
  'X-RAY LABORATORIES',
  'X-RAY LABORATORIES-DENTAL',
  'X-RAY LABORATORIES-INDUSTRIAL',
  'X-RAY LABORATORIES-MEDICAL',
  'X-RAY PROTECTION (WHOLESALE)',
  'Xbox',
  'YACHT BROKERS',
  'YACHT DOCUMENTATION',
  'YACHT FURNISHINGS-MANUFACTURERS',
  'YACHT HARBORS',
  'YACHT PROVISIONING (WHOLESALE)',
  'YACHT RIGGING',
  'YACHTS',
  'YACHTS-MANUFACTURERS',
  'YACHTS-REPAIRING',
  'YARD SIGNS',
  'YARN SPINNING MILLS',
  'YARN-MANUFACTURERS',
  'YARN-RETAIL',
  'YARN-TEXTURIZING THROWING/WINDING MILLS',
  'YARN-WHOLESALE',
  'YEAST (WHOLESALE)',
  'YELLOW PAGES-PUBLISHERS',
  'YOGA INSTRUCTION',
  'YOGURT',
  'YOGURT EQUIPMENT-SALES & SERVICE (WHOL)',
  'YOGURT-MANUFACTURERS',
  'YOGURT-WHOLESALE',
  'Young Adults',
  'YOUTH HOMES',
  'YOUTH ORGANIZATIONS & CENTERS',
  'YOUTH ORGANIZATIONS-SUPPLIES',
  'YURTS (MANUFACTURERS)',
  'ZINC (WHOLESALE)',
  'ZINC OXIDE (MANUFACTURERS)',
  'ZINC OXIDE-MANUFACTURERS',
  'ZIPPER MACHINERY (MANUFACTURERS)',
  'ZIPPERS (WHOLESALE)',
  'ZIPPERS-MANUFACTURERS',
  'ZIPPERS-REPAIRING',
  'ZIRCONIUM (MANUFACTURERS)',
  'ZONING CONSULTANTS',
  'ZOOS',
  'Zoos and Botanical Gardens',
];

export const jobsFunction = [
  'Business Development',
  'Community and Social Services',
  'Consulting',
  'Education',
  'Engineering and Technical',
  'Finance',
  'Healthcare and Medical',
  'Human Resources',
  'Information Technology',
  'Legal',
  'Marketing',
  'Media and Communications and PR',
  'Operations',
  'Program & Product Management',
  'Purchasing and Buyers',
  'Real Estate',
  'Research',
  'Sales',
];

export const managemensLevels = ['M level', 'D level', 'VP level', 'C level'];

export const stateList = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const USACitiesByState = {
  'New York': [
    'New York',
    'Buffalo',
    'Rochester',
    'Yonkers',
    'Syracuse',
    'Albany',
    'New Rochelle',
    'Mount Vernon',
    'Schenectady',
    'Utica',
    'White Plains',
    'Hempstead',
    'Troy',
    'Niagara Falls',
    'Binghamton',
    'Freeport',
    'Valley Stream',
  ],
  California: [
    'Los Angeles',
    'San Diego',
    'San Jose',
    'San Francisco',
    'Fresno',
    'Sacramento',
    'Long Beach',
    'Oakland',
    'Bakersfield',
    'Anaheim',
    'Santa Ana',
    'Riverside',
    'Stockton',
    'Chula Vista',
    'Irvine',
    'Fremont',
    'San Bernardino',
    'Modesto',
    'Fontana',
    'Oxnard',
    'Moreno Valley',
    'Huntington Beach',
    'Glendale',
    'Santa Clarita',
    'Garden Grove',
    'Oceanside',
    'Rancho Cucamonga',
    'Santa Rosa',
    'Ontario',
    'Lancaster',
    'Elk Grove',
    'Corona',
    'Palmdale',
    'Salinas',
    'Pomona',
    'Hayward',
    'Escondido',
    'Torrance',
    'Sunnyvale',
    'Orange',
    'Fullerton',
    'Pasadena',
    'Thousand Oaks',
    'Visalia',
    'Simi Valley',
    'Concord',
    'Roseville',
    'Victorville',
    'Santa Clara',
    'Vallejo',
    'Berkeley',
    'El Monte',
    'Downey',
    'Costa Mesa',
    'Inglewood',
    'Carlsbad',
    'San Buenaventura (Ventura)',
    'Fairfield',
    'West Covina',
    'Murrieta',
    'Richmond',
    'Norwalk',
    'Antioch',
    'Temecula',
    'Burbank',
    'Daly City',
    'Rialto',
    'Santa Maria',
    'El Cajon',
    'San Mateo',
    'Clovis',
    'Compton',
    'Jurupa Valley',
    'Vista',
    'South Gate',
    'Mission Viejo',
    'Vacaville',
    'Carson',
    'Hesperia',
    'Santa Monica',
    'Westminster',
    'Redding',
    'Santa Barbara',
    'Chico',
    'Newport Beach',
    'San Leandro',
    'San Marcos',
    'Whittier',
    'Hawthorne',
    'Citrus Heights',
    'Tracy',
    'Alhambra',
    'Livermore',
    'Buena Park',
    'Menifee',
    'Hemet',
    'Lakewood',
    'Merced',
    'Chino',
    'Indio',
    'Redwood City',
    'Lake Forest',
    'Napa',
    'Tustin',
    'Bellflower',
    'Mountain View',
    'Chino Hills',
    'Baldwin Park',
    'Alameda',
    'Upland',
    'San Ramon',
    'Folsom',
    'Pleasanton',
    'Union City',
    'Perris',
    'Manteca',
    'Lynwood',
    'Apple Valley',
    'Redlands',
    'Turlock',
    'Milpitas',
    'Redondo Beach',
    'Rancho Cordova',
    'Yorba Linda',
    'Palo Alto',
    'Davis',
    'Camarillo',
    'Walnut Creek',
    'Pittsburg',
    'South San Francisco',
    'Yuba City',
    'San Clemente',
    'Laguna Niguel',
    'Pico Rivera',
    'Montebello',
    'Lodi',
    'Madera',
    'Santa Cruz',
    'La Habra',
    'Encinitas',
    'Monterey Park',
    'Tulare',
    'Cupertino',
    'Gardena',
    'National City',
    'Rocklin',
    'Petaluma',
    'Huntington Park',
    'San Rafael',
    'La Mesa',
    'Arcadia',
    'Fountain Valley',
    'Diamond Bar',
    'Woodland',
    'Santee',
    'Lake Elsinore',
    'Porterville',
    'Paramount',
    'Eastvale',
    'Rosemead',
    'Hanford',
    'Highland',
    'Brentwood',
    'Novato',
    'Colton',
    'Cathedral City',
    'Delano',
    'Yucaipa',
    'Watsonville',
    'Placentia',
    'Glendora',
    'Gilroy',
    'Palm Desert',
    'Cerritos',
    'West Sacramento',
    'Aliso Viejo',
    'Poway',
    'La Mirada',
    'Rancho Santa Margarita',
    'Cypress',
    'Dublin',
    'Covina',
    'Azusa',
    'Palm Springs',
    'San Luis Obispo',
    'Ceres',
    'San Jacinto',
    'Lincoln',
    'Newark',
    'Lompoc',
    'El Centro',
    'Danville',
    'Bell Gardens',
    'Coachella',
    'Rancho Palos Verdes',
    'San Bruno',
    'Rohnert Park',
    'Brea',
    'La Puente',
    'Campbell',
    'San Gabriel',
    'Beaumont',
    'Morgan Hill',
    'Culver City',
    'Calexico',
    'Stanton',
    'La Quinta',
    'Pacifica',
    'Montclair',
    'Oakley',
    'Monrovia',
    'Los Banos',
    'Martinez',
  ],
  Illinois: [
    'Chicago',
    'Aurora',
    'Rockford',
    'Joliet',
    'Naperville',
    'Springfield',
    'Peoria',
    'Elgin',
    'Waukegan',
    'Cicero',
    'Champaign',
    'Bloomington',
    'Arlington Heights',
    'Evanston',
    'Decatur',
    'Schaumburg',
    'Bolingbrook',
    'Palatine',
    'Skokie',
    'Des Plaines',
    'Orland Park',
    'Tinley Park',
    'Oak Lawn',
    'Berwyn',
    'Mount Prospect',
    'Normal',
    'Wheaton',
    'Hoffman Estates',
    'Oak Park',
    'Downers Grove',
    'Elmhurst',
    'Glenview',
    'DeKalb',
    'Lombard',
    'Belleville',
    'Moline',
    'Buffalo Grove',
    'Bartlett',
    'Urbana',
    'Quincy',
    'Crystal Lake',
    'Plainfield',
    'Streamwood',
    'Carol Stream',
    'Romeoville',
    'Rock Island',
    'Hanover Park',
    'Carpentersville',
    'Wheeling',
    'Park Ridge',
    'Addison',
    'Calumet City',
  ],
  Texas: [
    'Houston',
    'San Antonio',
    'Dallas',
    'Austin',
    'Fort Worth',
    'El Paso',
    'Arlington',
    'Corpus Christi',
    'Plano',
    'Laredo',
    'Lubbock',
    'Garland',
    'Irving',
    'Amarillo',
    'Grand Prairie',
    'Brownsville',
    'Pasadena',
    'McKinney',
    'Mesquite',
    'McAllen',
    'Killeen',
    'Frisco',
    'Waco',
    'Carrollton',
    'Denton',
    'Midland',
    'Abilene',
    'Beaumont',
    'Round Rock',
    'Odessa',
    'Wichita Falls',
    'Richardson',
    'Lewisville',
    'Tyler',
    'College Station',
    'Pearland',
    'San Angelo',
    'Allen',
    'League City',
    'Sugar Land',
    'Longview',
    'Edinburg',
    'Mission',
    'Bryan',
    'Baytown',
    'Pharr',
    'Temple',
    'Missouri City',
    'Flower Mound',
    'Harlingen',
    'North Richland Hills',
    'Victoria',
    'Conroe',
    'New Braunfels',
    'Mansfield',
    'Cedar Park',
    'Rowlett',
    'Port Arthur',
    'Euless',
    'Georgetown',
    'Pflugerville',
    'DeSoto',
    'San Marcos',
    'Grapevine',
    'Bedford',
    'Galveston',
    'Cedar Hill',
    'Texas City',
    'Wylie',
    'Haltom City',
    'Keller',
    'Coppell',
    'Rockwall',
    'Huntsville',
    'Duncanville',
    'Sherman',
    'The Colony',
    'Burleson',
    'Hurst',
    'Lancaster',
    'Texarkana',
    'Friendswood',
    'Weslaco',
  ],
  Pennsylvania: [
    'Philadelphia',
    'Pittsburgh',
    'Allentown',
    'Erie',
    'Reading',
    'Scranton',
    'Bethlehem',
    'Lancaster',
    'Harrisburg',
    'Altoona',
    'York',
    'State College',
    'Wilkes-Barre',
  ],
  Arizona: [
    'Phoenix',
    'Tucson',
    'Mesa',
    'Chandler',
    'Glendale',
    'Scottsdale',
    'Gilbert',
    'Tempe',
    'Peoria',
    'Surprise',
    'Yuma',
    'Avondale',
    'Goodyear',
    'Flagstaff',
    'Buckeye',
    'Lake Havasu City',
    'Casa Grande',
    'Sierra Vista',
    'Maricopa',
    'Oro Valley',
    'Prescott',
    'Bullhead City',
    'Prescott Valley',
    'Marana',
    'Apache Junction',
  ],
  Florida: [
    'Jacksonville',
    'Miami',
    'Tampa',
    'Orlando',
    'St. Petersburg',
    'Hialeah',
    'Tallahassee',
    'Fort Lauderdale',
    'Port St. Lucie',
    'Cape Coral',
    'Pembroke Pines',
    'Hollywood',
    'Miramar',
    'Gainesville',
    'Coral Springs',
    'Miami Gardens',
    'Clearwater',
    'Palm Bay',
    'Pompano Beach',
    'West Palm Beach',
    'Lakeland',
    'Davie',
    'Miami Beach',
    'Sunrise',
    'Plantation',
    'Boca Raton',
    'Deltona',
    'Largo',
    'Deerfield Beach',
    'Palm Coast',
    'Melbourne',
    'Boynton Beach',
    'Lauderhill',
    'Weston',
    'Fort Myers',
    'Kissimmee',
    'Homestead',
    'Tamarac',
    'Delray Beach',
    'Daytona Beach',
    'North Miami',
    'Wellington',
    'North Port',
    'Jupiter',
    'Ocala',
    'Port Orange',
    'Margate',
    'Coconut Creek',
    'Sanford',
    'Sarasota',
    'Pensacola',
    'Bradenton',
    'Palm Beach Gardens',
    'Pinellas Park',
    'Coral Gables',
    'Doral',
    'Bonita Springs',
    'Apopka',
    'Titusville',
    'North Miami Beach',
    'Oakland Park',
    'Fort Pierce',
    'North Lauderdale',
    'Cutler Bay',
    'Altamonte Springs',
    'St. Cloud',
    'Greenacres',
    'Ormond Beach',
    'Ocoee',
    'Hallandale Beach',
    'Winter Garden',
    'Aventura',
    'Ponte Vedra',
  ],
  Indiana: [
    'Indianapolis',
    'Fort Wayne',
    'Evansville',
    'South Bend',
    'Carmel',
    'Bloomington',
    'Fishers',
    'Hammond',
    'Gary',
    'Muncie',
    'Lafayette',
    'Terre Haute',
    'Kokomo',
    'Anderson',
    'Noblesville',
    'Greenwood',
    'Elkhart',
    'Mishawaka',
    'Lawrence',
    'Jeffersonville',
    'Columbus',
    'Portage',
  ],
  Ohio: [
    'Columbus',
    'Cleveland',
    'Cincinnati',
    'Toledo',
    'Akron',
    'Dayton',
    'Parma',
    'Canton',
    'Youngstown',
    'Lorain',
    'Hamilton',
    'Springfield',
    'Kettering',
    'Elyria',
    'Lakewood',
    'Cuyahoga Falls',
    'Middletown',
    'Euclid',
    'Newark',
    'Mansfield',
    'Mentor',
    'Beavercreek',
    'Cleveland Heights',
    'Strongsville',
    'Dublin',
    'Fairfield',
    'Findlay',
    'Warren',
    'Lancaster',
    'Lima',
    'Huber Heights',
    'Westerville',
    'Marion',
    'Grove City',
  ],
  'North Carolina': [
    'Charlotte',
    'Raleigh',
    'Greensboro',
    'Durham',
    'Winston-Salem',
    'Fayetteville',
    'Cary',
    'Wilmington',
    'High Point',
    'Greenville',
    'Asheville',
    'Concord',
    'Gastonia',
    'Jacksonville',
    'Chapel Hill',
    'Rocky Mount',
    'Burlington',
    'Wilson',
    'Huntersville',
    'Kannapolis',
    'Apex',
    'Hickory',
    'Goldsboro',
  ],
  Michigan: [
    'Detroit',
    'Grand Rapids',
    'Warren',
    'Sterling Heights',
    'Ann Arbor',
    'Lansing',
    'Flint',
    'Dearborn',
    'Livonia',
    'Westland',
    'Troy',
    'Farmington Hills',
    'Kalamazoo',
    'Wyoming',
    'Southfield',
    'Rochester Hills',
    'Taylor',
    'Pontiac',
    'St. Clair Shores',
    'Royal Oak',
    'Novi',
    'Dearborn Heights',
    'Battle Creek',
    'Saginaw',
    'Kentwood',
    'East Lansing',
    'Roseville',
    'Portage',
    'Midland',
    'Lincoln Park',
    'Muskegon',
  ],
  Tennessee: [
    'Memphis',
    'Nashville-Davidson',
    'Knoxville',
    'Chattanooga',
    'Clarksville',
    'Murfreesboro',
    'Jackson',
    'Franklin',
    'Johnson City',
    'Bartlett',
    'Hendersonville',
    'Kingsport',
    'Collierville',
    'Cleveland',
    'Smyrna',
    'Germantown',
    'Brentwood',
  ],
  Massachusetts: [
    'Boston',
    'Worcester',
    'Springfield',
    'Lowell',
    'Cambridge',
    'New Bedford',
    'Brockton',
    'Quincy',
    'Lynn',
    'Fall River',
    'Newton',
    'Lawrence',
    'Somerville',
    'Waltham',
    'Haverhill',
    'Malden',
    'Medford',
    'Taunton',
    'Chicopee',
    'Weymouth Town',
    'Revere',
    'Peabody',
    'Methuen',
    'Barnstable Town',
    'Pittsfield',
    'Attleboro',
    'Everett',
    'Salem',
    'Westfield',
    'Leominster',
    'Fitchburg',
    'Beverly',
    'Holyoke',
    'Marlborough',
    'Woburn',
    'Chelsea',
  ],
  Washington: [
    'Seattle',
    'Spokane',
    'Tacoma',
    'Vancouver',
    'Bellevue',
    'Kent',
    'Everett',
    'Renton',
    'Yakima',
    'Federal Way',
    'Spokane Valley',
    'Bellingham',
    'Kennewick',
    'Auburn',
    'Pasco',
    'Marysville',
    'Lakewood',
    'Redmond',
    'Shoreline',
    'Richland',
    'Kirkland',
    'Burien',
    'Sammamish',
    'Olympia',
    'Lacey',
    'Edmonds',
    'Bremerton',
    'Puyallup',
  ],
  Colorado: [
    'Denver',
    'Colorado Springs',
    'Aurora',
    'Fort Collins',
    'Lakewood',
    'Thornton',
    'Arvada',
    'Westminster',
    'Pueblo',
    'Centennial',
    'Boulder',
    'Greeley',
    'Longmont',
    'Loveland',
    'Grand Junction',
    'Broomfield',
    'Castle Rock',
    'Commerce City',
    'Parker',
    'Littleton',
    'Northglenn',
  ],
  'District of Columbia': ['Washington'],
  Maryland: [
    'Baltimore',
    'Frederick',
    'Rockville',
    'Gaithersburg',
    'Bowie',
    'Hagerstown',
    'Annapolis',
  ],
  Kentucky: [
    'Louisville/Jefferson County',
    'Lexington-Fayette',
    'Bowling Green',
    'Owensboro',
    'Covington',
  ],
  Oregon: [
    'Portland',
    'Eugene',
    'Salem',
    'Gresham',
    'Hillsboro',
    'Beaverton',
    'Bend',
    'Medford',
    'Springfield',
    'Corvallis',
    'Albany',
    'Tigard',
    'Lake Oswego',
    'Keizer',
  ],
  Oklahoma: [
    'Oklahoma City',
    'Tulsa',
    'Norman',
    'Broken Arrow',
    'Lawton',
    'Edmond',
    'Moore',
    'Midwest City',
    'Enid',
    'Stillwater',
    'Muskogee',
  ],
  Wisconsin: [
    'Milwaukee',
    'Madison',
    'Green Bay',
    'Kenosha',
    'Racine',
    'Appleton',
    'Waukesha',
    'Eau Claire',
    'Oshkosh',
    'Janesville',
    'West Allis',
    'La Crosse',
    'Sheboygan',
    'Wauwatosa',
    'Fond du Lac',
    'New Berlin',
    'Wausau',
    'Brookfield',
    'Greenfield',
    'Beloit',
  ],
  Nevada: [
    'Las Vegas',
    'Henderson',
    'Reno',
    'North Las Vegas',
    'Sparks',
    'Carson City',
  ],
  'New Mexico': [
    'Albuquerque',
    'Las Cruces',
    'Rio Rancho',
    'Santa Fe',
    'Roswell',
    'Farmington',
    'Clovis',
  ],
  Missouri: [
    'Kansas City',
    'St. Louis',
    'Springfield',
    'Independence',
    'Columbia',
    "Lee's Summit",
    "O'Fallon",
    'St. Joseph',
    'St. Charles',
    'St. Peters',
    'Blue Springs',
    'Florissant',
    'Joplin',
    'Chesterfield',
    'Jefferson City',
    'Cape Girardeau',
  ],
  Virginia: [
    'Virginia Beach',
    'Norfolk',
    'Chesapeake',
    'Richmond',
    'Newport News',
    'Alexandria',
    'Hampton',
    'Roanoke',
    'Portsmouth',
    'Suffolk',
    'Lynchburg',
    'Harrisonburg',
    'Leesburg',
    'Charlottesville',
    'Danville',
    'Blacksburg',
    'Manassas',
  ],
  Georgia: [
    'Atlanta',
    'Columbus',
    'Augusta-Richmond County',
    'Savannah',
    'Athens-Clarke County',
    'Sandy Springs',
    'Roswell',
    'Macon',
    'Johns Creek',
    'Albany',
    'Warner Robins',
    'Alpharetta',
    'Marietta',
    'Valdosta',
    'Smyrna',
    'Dunwoody',
  ],
  Nebraska: ['Omaha', 'Lincoln', 'Bellevue', 'Grand Island'],
  Minnesota: [
    'Minneapolis',
    'St. Paul',
    'Rochester',
    'Duluth',
    'Bloomington',
    'Brooklyn Park',
    'Plymouth',
    'St. Cloud',
    'Eagan',
    'Woodbury',
    'Maple Grove',
    'Eden Prairie',
    'Coon Rapids',
    'Burnsville',
    'Blaine',
    'Lakeville',
    'Minnetonka',
    'Apple Valley',
    'Edina',
    'St. Louis Park',
    'Mankato',
    'Maplewood',
    'Moorhead',
    'Shakopee',
  ],
  Kansas: [
    'Wichita',
    'Overland Park',
    'Kansas City',
    'Olathe',
    'Topeka',
    'Lawrence',
    'Shawnee',
    'Manhattan',
    'Lenexa',
    'Salina',
    'Hutchinson',
  ],
  Louisiana: [
    'New Orleans',
    'Baton Rouge',
    'Shreveport',
    'Lafayette',
    'Lake Charles',
    'Kenner',
    'Bossier City',
    'Monroe',
    'Alexandria',
  ],
  Hawaii: ['Honolulu'],
  Alaska: ['Anchorage'],
  'New Jersey': [
    'Newark',
    'Jersey City',
    'Paterson',
    'Elizabeth',
    'Clifton',
    'Trenton',
    'Camden',
    'Passaic',
    'Union City',
    'Bayonne',
    'East Orange',
    'Vineland',
    'New Brunswick',
    'Hoboken',
    'Perth Amboy',
    'West New York',
    'Plainfield',
    'Hackensack',
    'Sayreville',
    'Kearny',
    'Linden',
    'Atlantic City',
  ],
  Idaho: [
    'Boise City',
    'Nampa',
    'Meridian',
    'Idaho Falls',
    'Pocatello',
    'Caldwell',
    "Coeur d'Alene",
    'Twin Falls',
  ],
  Alabama: [
    'Birmingham',
    'Montgomery',
    'Mobile',
    'Huntsville',
    'Tuscaloosa',
    'Hoover',
    'Dothan',
    'Auburn',
    'Decatur',
    'Madison',
    'Florence',
    'Gadsden',
  ],
  Iowa: [
    'Des Moines',
    'Cedar Rapids',
    'Davenport',
    'Sioux City',
    'Iowa City',
    'Waterloo',
    'Council Bluffs',
    'Ames',
    'West Des Moines',
    'Dubuque',
    'Ankeny',
    'Urbandale',
    'Cedar Falls',
  ],
  Arkansas: [
    'Little Rock',
    'Fort Smith',
    'Fayetteville',
    'Springdale',
    'Jonesboro',
    'North Little Rock',
    'Conway',
    'Rogers',
    'Pine Bluff',
    'Bentonville',
  ],
  Utah: [
    'Salt Lake City',
    'West Valley City',
    'Provo',
    'West Jordan',
    'Orem',
    'Sandy',
    'Ogden',
    'St. George',
    'Layton',
    'Taylorsville',
    'South Jordan',
    'Lehi',
    'Logan',
    'Murray',
    'Draper',
    'Bountiful',
    'Riverton',
    'Roy',
  ],
  'Rhode Island': [
    'Providence',
    'Warwick',
    'Cranston',
    'Pawtucket',
    'East Providence',
    'Woonsocket',
  ],
  Mississippi: [
    'Jackson',
    'Gulfport',
    'Southaven',
    'Hattiesburg',
    'Biloxi',
    'Meridian',
  ],
  'South Dakota': ['Sioux Falls', 'Rapid City'],
  Connecticut: [
    'Bridgeport',
    'New Haven',
    'Stamford',
    'Hartford',
    'Waterbury',
    'Norwalk',
    'Danbury',
    'New Britain',
    'Meriden',
    'Bristol',
    'West Haven',
    'Milford',
    'Middletown',
    'Norwich',
    'Shelton',
  ],
  'South Carolina': [
    'Columbia',
    'Charleston',
    'North Charleston',
    'Mount Pleasant',
    'Rock Hill',
    'Greenville',
    'Summerville',
    'Sumter',
    'Goose Creek',
    'Hilton Head Island',
    'Florence',
    'Spartanburg',
  ],
  'New Hampshire': ['Manchester', 'Nashua', 'Concord'],
  'North Dakota': ['Fargo', 'Bismarck', 'Grand Forks', 'Minot'],
  Montana: ['Billings', 'Missoula', 'Great Falls', 'Bozeman'],
  Delaware: ['Wilmington', 'Dover'],
  Maine: ['Portland'],
  Wyoming: ['Cheyenne', 'Casper'],
  'West Virginia': ['Charleston', 'Huntington'],
  Vermont: ['Burlington'],
};

export const cityList = [
  'Aberdeen',
  'Abilene',
  'Akron',
  'Albany',
  'Albuquerque',
  'Alexandria',
  'Allentown',
  'Amarillo',
  'Anaheim',
  'Anchorage',
  'Ann Arbor',
  'Antioch',
  'Apple Valley',
  'Appleton',
  'Arlington',
  'Arvada',
  'Asheville',
  'Athens',
  'Atlanta',
  'Atlantic City',
  'Augusta',
  'Aurora',
  'Austin',
  'Bakersfield',
  'Baltimore',
  'Barnstable',
  'Baton Rouge',
  'Beaumont',
  'Bel Air',
  'Bellevue',
  'Berkeley',
  'Bethlehem',
  'Billings',
  'Birmingham',
  'Bloomington',
  'Boise',
  'Boise City',
  'Bonita Springs',
  'Boston',
  'Boulder',
  'Bradenton',
  'Bremerton',
  'Bridgeport',
  'Brighton',
  'Brownsville',
  'Bryan',
  'Buffalo',
  'Burbank',
  'Burlington',
  'Cambridge',
  'Canton',
  'Cape Coral',
  'Carrollton',
  'Cary',
  'Cathedral City',
  'Cedar Rapids',
  'Champaign',
  'Chandler',
  'Charleston',
  'Charlotte',
  'Chattanooga',
  'Chesapeake',
  'Chicago',
  'Chula Vista',
  'Cincinnati',
  'Clarke County',
  'Clarksville',
  'Clearwater',
  'Cleveland',
  'College Station',
  'Colorado Springs',
  'Columbia',
  'Columbus',
  'Concord',
  'Coral Springs',
  'Corona',
  'Corpus Christi',
  'Costa Mesa',
  'Dallas',
  'Daly City',
  'Danbury',
  'Davenport',
  'Davidson County',
  'Dayton',
  'Daytona Beach',
  'Deltona',
  'Denton',
  'Denver',
  'Des Moines',
  'Detroit',
  'Downey',
  'Duluth',
  'Durham',
  'El Monte',
  'El Paso',
  'Elizabeth',
  'Elk Grove',
  'Elkhart',
  'Erie',
  'Escondido',
  'Eugene',
  'Evansville',
  'Fairfield',
  'Fargo',
  'Fayetteville',
  'Fitchburg',
  'Flint',
  'Fontana',
  'Fort Collins',
  'Fort Lauderdale',
  'Fort Smith',
  'Fort Walton Beach',
  'Fort Wayne',
  'Fort Worth',
  'Frederick',
  'Fremont',
  'Fresno',
  'Fullerton',
  'Gainesville',
  'Garden Grove',
  'Garland',
  'Gastonia',
  'Gilbert',
  'Glendale',
  'Grand Prairie',
  'Grand Rapids',
  'Grayslake',
  'Green Bay',
  'GreenBay',
  'Greensboro',
  'Greenville',
  'Gulfport-Biloxi',
  'Hagerstown',
  'Hampton',
  'Harlingen',
  'Harrisburg',
  'Hartford',
  'Havre de Grace',
  'Hayward',
  'Hemet',
  'Henderson',
  'Hesperia',
  'Hialeah',
  'Hickory',
  'High Point',
  'Hollywood',
  'Honolulu',
  'Houma',
  'Houston',
  'Howell',
  'Huntington',
  'Huntington Beach',
  'Huntsville',
  'Independence',
  'Indianapolis',
  'Inglewood',
  'Irvine',
  'Irving',
  'Jackson',
  'Jacksonville',
  'Jefferson',
  'Jersey City',
  'Johnson City',
  'Joliet',
  'Kailua',
  'Kalamazoo',
  'Kaneohe',
  'Kansas City',
  'Kennewick',
  'Kenosha',
  'Killeen',
  'Kissimmee',
  'Knoxville',
  'Lacey',
  'Lafayette',
  'Lake Charles',
  'Lakeland',
  'Lakewood',
  'Lancaster',
  'Lansing',
  'Laredo',
  'Las Cruces',
  'Las Vegas',
  'Layton',
  'Leominster',
  'Lewisville',
  'Lexington',
  'Lincoln',
  'Little Rock',
  'Long Beach',
  'Lorain',
  'Los Angeles',
  'Louisville',
  'Lowell',
  'Lubbock',
  'Macon',
  'Madison',
  'Manchester',
  'Marina',
  'Marysville',
  'McAllen',
  'McHenry',
  'Medford',
  'Melbourne',
  'Memphis',
  'Merced',
  'Mesa',
  'Mesquite',
  'Miami',
  'Milwaukee',
  'Minneapolis',
  'Miramar',
  'Mission Viejo',
  'Mobile',
  'Modesto',
  'Monroe',
  'Monterey',
  'Montgomery',
  'Moreno Valley',
  'Murfreesboro',
  'Murrieta',
  'Muskegon',
  'Myrtle Beach',
  'Naperville',
  'Naples',
  'Nashua',
  'Nashville',
  'New Bedford',
  'New Haven',
  'New London',
  'New Orleans',
  'New York',
  'New York City',
  'Newark',
  'Newburgh',
  'Newport News',
  'Norfolk',
  'Normal',
  'Norman',
  'North Charleston',
  'North Las Vegas',
  'North Port',
  'Norwalk',
  'Norwich',
  'Oakland',
  'Ocala',
  'Oceanside',
  'Odessa',
  'Ogden',
  'Oklahoma City',
  'Olathe',
  'Olympia',
  'Omaha',
  'Ontario',
  'Orange',
  'Orem',
  'Orlando',
  'Overland Park',
  'Oxnard',
  'Palm Bay',
  'Palm Springs',
  'Palmdale',
  'Panama City',
  'Pasadena',
  'Paterson',
  'Pembroke Pines',
  'Pensacola',
  'Peoria',
  'Philadelphia',
  'Phoenix',
  'Pittsburgh',
  'Plano',
  'Pomona',
  'Pompano Beach',
  'Port Arthur',
  'Port Orange',
  'Port Saint Lucie',
  'Port St. Lucie',
  'Portland',
  'Portsmouth',
  'Poughkeepsie',
  'Providence',
  'Provo',
  'Pueblo',
  'Punta Gorda',
  'Racine',
  'Raleigh',
  'Rancho Cucamonga',
  'Reading',
  'Redding',
  'Reno',
  'Richland',
  'Richmond',
  'Richmond County',
  'Riverside',
  'Roanoke',
  'Rochester',
  'Rockford',
  'Roseville',
  'Round Lake Beach',
  'Sacramento',
  'Saginaw',
  'Saint Louis',
  'Saint Paul',
  'Saint Petersburg',
  'Salem',
  'Salinas',
  'Salt Lake City',
  'San Antonio',
  'San Bernardino',
  'San Buenaventura',
  'San Diego',
  'San Francisco',
  'San Jose',
  'Santa Ana',
  'Santa Barbara',
  'Santa Clara',
  'Santa Clarita',
  'Santa Cruz',
  'Santa Maria',
  'Santa Rosa',
  'Sarasota',
  'Savannah',
  'Scottsdale',
  'Scranton',
  'Seaside',
  'Seattle',
  'Sebastian',
  'Shreveport',
  'Simi Valley',
  'Sioux City',
  'Sioux Falls',
  'South Bend',
  'South Lyon',
  'Spartanburg',
  'Spokane',
  'Springdale',
  'Springfield',
  'St. Louis',
  'St. Paul',
  'St. Petersburg',
  'Stamford',
  'Sterling Heights',
  'Stockton',
  'Sunnyvale',
  'Syracuse',
  'Tacoma',
  'Tallahassee',
  'Tampa',
  'Temecula',
  'Tempe',
  'Thornton',
  'Thousand Oaks',
  'Toledo',
  'Topeka',
  'Torrance',
  'Trenton',
  'Tucson',
  'Tulsa',
  'Tuscaloosa',
  'Tyler',
  'Utica',
  'Vallejo',
  'Vancouver',
  'Vero Beach',
  'Victorville',
  'Virginia Beach',
  'Visalia',
  'Waco',
  'Warren',
  'Washington',
  'Waterbury',
  'Waterloo',
  'West Covina',
  'West Valley City',
  'Westminster',
  'Wichita',
  'Wilmington',
  'Winston',
  'Winter Haven',
  'Worcester',
  'Yakima',
  'Yonkers',
  'York',
  'Youngstown',
];

export const revenueList = [
  'Under $1 Million',
  '$1 Million - $10 Million',
  '$10 Million - $50 Million',
  '$50 Million - $100 Million',
  '$100 Million - $200 Million',
  '$200 Million - $1 Billion',
  'Over $1 Billion',
];

export const employeesRangelist = [
  '1 to 10',
  '10 to 50',
  '50 to 200',
  '200 to 500',
  '500 to 1,000',
  '1,000 to 5,000',
  '5,000 to 10,000',
  'Over 10,000',
];

export const getStateAbbreviation = (stateName) => {
  // Create an object with state name abbreviations
  const stateAbbreviations = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
  };

  const formattedStateName = stateName
    .toLowerCase()
    .replace(/\b\w/g, (l) => l.toUpperCase());

  if (stateAbbreviations[formattedStateName]) {
    return stateAbbreviations[formattedStateName];
  } else {
    return 'Unknown';
  }
};

import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import stringConstants from '../../utils/stringConstants.json';
import { sortingTable } from '../../utils/sortingTable';
import routes from '../../utils/routes.json';
import Table from '../GenericTable';
import TableSkeleton from '../commons/TableSkeleton';
import activityService from '../../services/activity.service';
import { dateWithoutTZ, isPermissionAllowed } from '../../utils/Utils';
import { Link } from 'react-router-dom';
import MaterialIcon from '../commons/MaterialIcon';
import MoreActions from '../MoreActions';
import TableActions from '../commons/TableActions';
import { ActivityDetail } from './activityDetail';
import { ShortDescription } from '../ShortDescription';
import OwnerAvatar from './OwnerAvatar';
import DeleteModal from '../modal/DeleteModal';
import ChecklistStatus from '../checklist/ChecklistStatus';
import TableRowHover from '../commons/TableRowHover';
const constants = stringConstants.allActivities;
const AllActivitiesTable = ({
  paginationPage,
  order,
  setActivatedTab,
  setOrder,
  pagination,
  showLoading,
  dataInDB,
  setPagination,
  selectedData = [],
  setSelectedData,
  setShowDeleteOrgModal,
  deleteResults,
  tabType,
  showDeleteOrgModal,
  setDeleteResults,
  handleClearSelection,
  selectAll,
  getData,
  cloneData,
  setCloneData,
  setErrorMessage,
  setSuccessMessage,
  allData,
  isFilterCheck,
  setSelectAll,
  handleEditActivity,
  getStats,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [activityObj, setActivityObj] = useState();
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    getData(tabType);
  }, [pagination?.page, order, isFilterCheck?.filter]);
  const changePaginationPage = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  useEffect(() => {
    paginationPage?.page === 1 && changePaginationPage(1);
  }, [paginationPage]);
  const activityDetail = async (content) => {
    const { data } = await activityService.getSingleActivity(
      content.id ? content.id : content
    );
    setActivityObj(data);
    setIsShow(true);
  };
  const markAsDone = async (id) => {
    try {
      await activityService.markAsCompleted(id);
      getData('task');
      setActivatedTab({
        task: 1,
      });
      setSuccessMessage(constants.completed);
    } catch (error) {
      setErrorMessage(constants.errorUpdatedActivity);
    }
  };

  const GetOwnersAction = (task) => {
    if (task) {
      return (
        <div className="align-items-end more-owners gap-1 align-items-center">
          {task?.owners?.map((item) => (
            <div key={item?.id}>
              <OwnerAvatar item={item} isMultiple={true} />
            </div>
          ))}
        </div>
      );
    }
  };
  const GetCreateByOwners = (task) => {
    if (task) {
      return <OwnerAvatar item={task} />;
    }
  };
  const getAction = (content) => {
    if (content?.organization) {
      return (
        <Link
          to={`${routes.companies}/${content?.organization?.id}/organization/profile`}
          className="text-black fw-bold d-inline-flex align-items-center gap-1"
        >
          {content?.organization && (
            <>
              <MaterialIcon icon="domain" /> {content?.organization?.name}
            </>
          )}
        </Link>
      );
    } else if (content?.contact) {
      const name = `${content?.contact?.first_name} ${content?.contact?.last_name}`;
      return (
        <Link
          to={`${routes.contacts}/${content?.contact?.id}/profile`}
          className="text-black fw-bold d-inline-flex align-items-center gap-1"
        >
          {content && (
            <>
              <MaterialIcon icon="people" /> {name}
            </>
          )}
        </Link>
      );
    } else if (content?.deal) {
      return (
        <Link
          to={`${routes.dealsPipeline}/${content?.deal?.id}`}
          className="text-black fw-bold d-inline-flex align-items-center gap-1"
        >
          {content?.deal && (
            <>
              <MaterialIcon icon="monetization_on" /> {content?.deal?.name}
            </>
          )}
        </Link>
      );
    }
  };
  const tableActions = [
    {
      id: 1,
      title: 'Edit',
      icon: 'edit',
      permission: {
        collection: 'activities',
        action: 'edit',
      },
      onClick: (e) => {
        setIsShow(false);
        handleEditActivity(e);
      },
    },
    {
      id: 1,
      title: 'Clone',
      permission: {
        collection: 'activities',
        action: 'create',
      },
      icon: 'content_copy',
      onClick: (e) => {
        setIsShow(false);
        setCloneData(true);
        handleEditActivity(e);
      },
    },
  ];
  const columns = [
    {
      key: 'name',
      orderBy: 'name',
      component: 'Activity Name ',
    },
    {
      key: 'description',
      orderBy: '',
      component: 'Description ',
      width: '195px',
    },
    {
      key: 'created_by',
      orderBy: '',
      component: 'Created By',
    },
    {
      key: '',
      orderBy: '',
      component: 'Owner',
    },
    {
      key: 'end_date',
      orderBy: 'end_date',
      component: 'Due Date',
    },
    {
      key: 'done',
      orderBy: 'done',
      component: 'Status',
    },
    {
      key: 'priority',
      orderBy: 'priority',
      component: 'Priority',
    },
    {
      key: 'related_to',
      orderBy: '',
      component: 'Related To',
    },
    {
      key: 'action',
      orderBy: '',
      component: '',
    },
  ];
  const data = allData?.map((activity) => ({
    ...activity,
    dataRow: [
      {
        key: 'name',
        component: (
          <div className="d-flex gap-1 align-items-center">
            <MaterialIcon
              filled={true}
              icon={
                activity?.type === 'task'
                  ? 'task_alt'
                  : activity?.type === 'event'
                  ? 'event'
                  : 'call'
              }
              clazz="text-primary ml-n1"
            />
            <span className={`text-truncate text-wrap fw-bold`}>
              <ShortDescription
                content={activity?.name}
                limit={20}
                classnames={`fw-bold ${
                  activity?.done ? 'text-line-through' : ''
                }`}
              />
            </span>
          </div>
        ),
      },
      {
        key: 'description',
        component: (
          <ShortDescription
            content={activity?.description}
            limit={30}
            btnShow={true}
          />
        ),
      },
      {
        key: 'created_by',
        component: GetCreateByOwners(activity),
      },
      {
        key: 'owner',
        component: GetOwnersAction(activity),
      },
      {
        key: 'end_date',
        component: <span>{dateWithoutTZ(activity?.start_date)}</span>,
      },
      {
        key: 'done',
        component: (
          <ChecklistStatus
            item={{ status: activity?.done ? 'Completed' : 'In Progress' }}
          />
        ),
      },
      {
        key: 'priority',
        component: (
          <span>
            {activity?.priority ? (
              <div className="d-flex text-red align-items-center">
                <MaterialIcon
                  filled={true}
                  icon="flag"
                  clazz="text-red fs-7 ml-n1"
                />
                High
              </div>
            ) : (
              'Normal'
            )}
          </span>
        ),
      },
      {
        key: 'related_to',
        component: getAction(activity),
      },
      {
        key: 'action',
        component: (
          <TableRowHover onClick={() => activityDetail(activity)}>
            <TableActions
              item={{ ...activity, title: name }}
              actions={tableActions}
            />
            {isPermissionAllowed('activities', 'edit') && (
              <a className={`icon-hover-bg cursor-pointer`}>
                <MoreActions
                  icon="more_vert"
                  items={[
                    {
                      permission: {
                        collection: 'activities',
                        action: 'edit',
                      },
                      id: 'edit',
                      icon: 'task_alt',
                      name: 'Mark as done',
                      className: activity.done ? 'd-none' : '',
                    },
                  ]}
                  onHandleEdit={() => markAsDone(activity.id)}
                  toggleClassName="w-auto p-0 h-auto"
                />
              </a>
            )}
          </TableRowHover>
        ),
      },
    ],
  }));

  const sortTable = ({ name }) => {
    if (name === 'action') return null;
    sortingTable({ name, order, setOrder });
  };
  const loader = () => {
    if (showLoading) return <TableSkeleton cols={6} rows={10} />;
  };

  const deleteOrganizations = async () => {
    await Promise.all(
      selectedData?.map(async (item) => {
        try {
          await activityService.deleteActivity(
            selectedData.length > 0 ? item : deleteResults?.id
          );
          setSuccessMessage(`Activities has been deleted.`);
          setShowDeleteOrgModal(false);
          handleClearSelection();
          setSelectedData([]);
        } catch (err) {
          setErrorMessage(err.message);
        }
      })
    );
    getData('all');
    setActivatedTab({
      all: 1,
    });
    setShowDeleteOrgModal(false);
  };
  const openDeleteModal = async () => {
    setShowDeleteOrgModal(true);
    await deleteOrganizations();
  };

  useEffect(() => {
    if (!showDeleteOrgModal) {
      handleClearSelection();
    }
  }, [!showDeleteOrgModal]);
  return (
    <div>
      <Card className="mb-5">
        <Card.Body className="p-0">
          <div className="table-responsive-md datatable-custom">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper no-footer"
            >
              {showLoading ? (
                loader()
              ) : (
                <>
                  <Table
                    stickyColumn="stickyColumn"
                    columns={columns}
                    data={data}
                    checkbox
                    setDeleteResults={setDeleteResults}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    paginationInfo={pagination}
                    onPageChange={changePaginationPage}
                    emptyDataText="No records in this view."
                    title="Task"
                    dataInDB={dataInDB}
                    showTooltip={showTooltip}
                    setShowTooltip={setShowTooltip}
                    sortingTable={sortTable}
                    sortingOrder={order}
                    onClick={activityDetail}
                    stats={getStats}
                  />
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      {showDeleteOrgModal && (
        <DeleteModal
          type="task"
          showModal={showDeleteOrgModal}
          setShowModal={setShowDeleteOrgModal}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          event={openDeleteModal}
          data={allData}
          results={deleteResults}
          setResults={setDeleteResults}
          showReport={showDeleteReport}
          setShowReport={setShowDeleteReport}
          modified={modified}
          setSelectAll={setSelectAll}
          setModified={setModified}
          constants={constants?.delete}
        />
      )}
      {isShow && (
        <ActivityDetail
          activityDetail={activityDetail}
          isShow={isShow}
          setIsShow={setIsShow}
          data={activityObj}
          cloneData={cloneData}
          markAsDone={markAsDone}
          tableActions={tableActions}
        />
      )}
    </div>
  );
};

export default AllActivitiesTable;

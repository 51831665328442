import { Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import GenericTable from '../../../components/GenericTable';
import { cubeService } from '../../../services';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import { reportPages } from '../../../utils/constants';
import DatePickerTraining from '../../../components/dealsBreakdown/DatePickerTraining';
import Loading from '../../../components/Loading';

export const AssignmentProgress = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCSVBtn, setShowCSVBtn] = useState(false);
  const [reportPage, setReportPage] = useState(reportPages[0]);

  const [dateRange, setDateRange] = useState({});
  const getData = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type: 'AssignmentProgress',
      ...(Object.keys(dateRange)?.length > 0
        ? dateRange?.key === 'All Time'
          ? {}
          : { startDate: dateRange?.startDate, endDate: dateRange?.endDate }
        : {}),

      ...(reportPage?.key !== 'all' ? { limit: parseInt(reportPage.key) } : {}),
    });
    setShowCSVBtn(results?.length > 0);
    setData(results);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [dateRange, reportPage]);
  const rows = data?.map((result, index) => {
    return {
      id: index,
      dataRow: [
        {
          key: 'assignment',
          component: <span className="fw-bold">{result?.assignment}</span>,
        },
        {
          key: 'date',
          component: result?.dueDate,
        },
        {
          key: 'assigned',
          component: `${result?.peopleAssigned} people`,
        },
        {
          key: 'completed',
          component: `${result?.assignmentCompleted}`,
        },
        {
          key: 'progress',
          component: `${result?.assignmentInProgress}`,
        },
        {
          key: 'not_started',
          component: `${result?.assignmentNotStarted}`,
        },
        {
          key: 'overdue',
          component: result?.assignmentOverDue,
        },
      ],
    };
  });

  const columns = [
    {
      key: 'assignmentName',
      component: 'Assignment Name',
    },
    {
      key: 'dueDate',
      component: 'Due Date',
    },
    {
      key: 'peopleAssigned',
      component: 'People Assigned',
      tooltipTitle: '# of people assigned',
    },
    {
      key: 'completed',
      component: 'Completed',
      tooltipTitle: 'Number of people who have completed the assignment.',
    },
    {
      key: 'inProgress',
      component: 'In Progress',
      tooltipTitle: 'Number of people are still working on the assignment.',
    },
    {
      key: 'notStarted',
      component: 'Not Started',
      tooltipTitle: 'Number of people who have not started the assignment.',
    },
    {
      key: 'overdue',
      component: 'Overdue',
      tooltipTitle:
        'Number of individuals who have not completed this assignment by the due date.',
    },
  ];

  const updatedRows = data?.map((item) => {
    return {
      assignmentName: item?.assignment,
      dueDate: item?.dueDate,
      peopleAssigned: item?.peopleAssigned,
      completed: item?.assignmentCompleted,
      inProgress: item?.assignmentInProgress,
      notStarted: item?.assignmentNotStarted,
      overdue: item?.assignmentOverDue,
    };
  });

  return (
    <>
      <Card>
        <Card.Header
          className={`justify-content-between ${showCSVBtn ? 'mr-5' : ''}`}
        >
          <h4 className="card-title text-hover-primary py-2 mb-0 pt-2 mt-1">
            Assignment Statistics{' '}
          </h4>
          <div
            className={`${
              showCSVBtn ? 'mr-5 pr-5' : ''
            } d-flex align-items-center`}
          >
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              disablePastDate
              extraClass="mx-1"
            />
            <ButtonFilterDropdown
              buttonText="Timeline"
              options={reportPages}
              btnToggleStyle="btn-md"
              filterOptionSelected={reportPage}
              handleFilterSelect={(e, item) => {
                setReportPage(item);
              }}
            />
          </div>
        </Card.Header>
        <div>
          {loading ? (
            <div className="py-5 text-center w-100">
              <Loading />
            </div>
          ) : (
            <div>
              <div>
                <GenericTable
                  checkbox={false}
                  data={rows}
                  exportToCSV={showCSVBtn}
                  tableData={updatedRows}
                  fileName="AssignmentStatistics"
                  columns={columns}
                  usePagination={false}
                  noDataInDbValidation={true}
                />
              </div>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

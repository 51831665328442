import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import AutoComplete from '../AutoComplete';
import { useModuleContext } from '../../contexts/moduleContext';
import contactService from '../../services/contact.service';

export const AssignContacts = ({
  organizationId,
  selectedContact,
  setSelectedContact,
}) => {
  const [contacts, setContacts] = useState([]);
  const { moduleMap } = useModuleContext();

  const [savingContact, setSavingContact] = useState(false);

  const handleSelect = async (item) => {
    const newContacts = [...selectedContact, item];
    setSelectedContact(newContacts);
  };
  const getContacts = async () => {
    setSavingContact(true);
    try {
      const data = await contactService.getContactsByorganizationId(
        { organizationId },
        { page: 1, limit: 100 }
      );
      const UpdatedContacts = data?.contacts?.map((item) => {
        const name = `${item?.first_name} ${
          item?.last_name !== null ? item?.last_name : ''
        }`;
        return {
          ...item,
          title: name,
        };
      });

      setContacts(UpdatedContacts || []);
    } catch (e) {
      console.log(e);
    } finally {
      setSavingContact(false);
    }
  };
  useEffect(() => {
    if (organizationId) {
      getContacts();
    }
  }, [organizationId]);
  const clearState = () => {
    setSelectedContact([]);
  };
  return (
    <>
      <FormGroup row className="align-items-center mt-3">
        <Label md={4} className="text-right font-size-sm col-form-label">
          Assign Contacts
        </Label>
        <Col md={8} className="pl-0">
          <AutoComplete
            id="assign_checklist"
            placeholder={`Search for ${moduleMap.contact.singular}`}
            name="assign_checklist"
            data={contacts}
            loading={savingContact}
            onChange={(items, itemToRemove) => {
              const allOption = items.find((t) => t.id === -1);
              if (allOption) {
                setSelectedContact(contacts.filter((t) => t.id !== -1));
              } else {
                setSelectedContact(items.filter((t) => t.id !== -1));
              }
            }}
            clearState={(e) => clearState(e)}
            customKey="title"
            isMultiple={true}
            selected={selectedContact}
            onHandleSelect={(item) => handleSelect(item)}
          />
        </Col>
      </FormGroup>
    </>
  );
};

import { Drawer } from '@mui/material';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import Loading from '../Loading';

const RightSlidingDrawer = ({
  open,
  toggleDrawer,
  position = 'right',
  withCard,
  header,
  body,
  footer,
  isLoading,
  containerWidth = 1030,
  children,
  modalClass,
  modalBodyClass = 'pt-0 pb-0',
}) => {
  return (
    <>
      {withCard ? (
        <Drawer
          anchor={position}
          open={open}
          onClose={toggleDrawer}
          style={{ zIndex: 122 }}
        >
          <Card
            className={`p-0 shadow-0 rounded-0 h-100 ${modalClass}`}
            style={{ minWidth: containerWidth }}
          >
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <CardHeader className="bg-gray-5">{header}</CardHeader>
                <CardBody className={`overflow-y-auto h-100 ${modalBodyClass}`}>
                  {body}
                </CardBody>
                <CardFooter className="bg-gray-5">{footer}</CardFooter>
              </>
            )}
          </Card>
        </Drawer>
      ) : (
        <Drawer
          anchor={position}
          open={open}
          classes={modalClass}
          onClose={toggleDrawer}
        >
          {children}
        </Drawer>
      )}
    </>
  );
};

export default RightSlidingDrawer;

import React, { useState } from 'react';
import { Card, CardHeader } from 'reactstrap';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import { reportPages } from '../../../utils/constants';
import UserStatisticsLesson from '../../Insights/reportRenders/UserStatisticsLesson';
import { getTomorrowDate } from '../../../utils/Utils';
import DatePickerTraining from '../../../components/dealsBreakdown/DatePickerTraining';

const TrainingLeaderBoardTranscriptComponent = ({ dashboard }) => {
  const [csvBtnLoad, setShowCSVBtn] = useState(false);

  const [reportPage, setReportPage] = useState(reportPages[0]);
  const tomorrowDate = getTomorrowDate();

  const [dateRange, setDateRange] = useState({
    key: 'All Time',
    startDate: new Date(1970, 0, 1),
    endDate: tomorrowDate,
  });
  return (
    <Card className={`overflow-x-hidden overflow-y-auto h-auto`}>
      <CardHeader>
        <div
          className={`d-flex justify-content-between align items center w-100 ${
            csvBtnLoad ? 'mr-5' : ''
          }`}
        >
          <h4 className="card-title text-hover-primary mb-0 pt-2 pb-2">
            {dashboard?.name}
          </h4>
          <div
            className={`d-flex align-items-center ${
              csvBtnLoad ? 'mr-5 pr-5' : ''
            }`}
          >
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              disablePastDate
              extraClass="mx-1"
            />
            <ButtonFilterDropdown
              buttonText="Timeline"
              btnToggleStyle="btn-md"
              options={reportPages}
              filterOptionSelected={reportPage}
              handleFilterSelect={(e, item) => {
                setReportPage(item);
              }}
            />
          </div>
        </div>
      </CardHeader>
      <div className="p-0">
        <UserStatisticsLesson
          dateRange={dateRange}
          reportLimit={reportPage}
          setShowCSVBtn={setShowCSVBtn}
        />{' '}
      </div>
    </Card>
  );
};

export default TrainingLeaderBoardTranscriptComponent;

import React, { useState } from 'react';
import ActivitiesChecklist from '../../components/checklist/ActivitiesChecklist';
import { ChecklistFilters } from '../../utils/constants';
import AlertWrapper from '../../components/Alert/AlertWrapper';
import Alert from '../../components/Alert/Alert';
import useCreateChecklist from '../../components/checklist/useCreateChecklist';
import { isModuleAllowed, isPermissionAllowed } from '../../utils/Utils';
import ButtonIcon from '../../components/commons/ButtonIcon';
import LayoutHead from '../../components/commons/LayoutHead';
import { useTenantContext } from '../../contexts/TenantContext';
import AnimatedTabs from '../../components/commons/AnimatedTabs';
import Heading from '../../components/heading';
import ChecklistFilterTabsDropdown from '../../components/commons/ChecklistFilterTabsDropdown';
import { PermissionsConstants } from '../../utils/permissions.constants';
const tabsData = [
  {
    tabId: 1,
    title: 'All',
    key: 'all',
  },
  {
    tabId: 2,
    title: 'Not Started',
    key: 'pending',
  },
  {
    tabId: 3,
    title: 'In Progress',
    key: 'inProgress',
  },
  {
    tabId: 4,
    title: 'Overdue',
    key: 'overdue',
  },
  {
    tabId: 5,
    title: 'Completed',
    key: 'completed',
  },
];
const AllChecklist = () => {
  const { tenant } = useTenantContext();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [checklistCreate, setChecklistCreate] = useState(false);
  const [refreshChecklist, setRefreshChecklist] = useState(0);
  const [checklistFilterSelected, setChecklistFilterSelected] = useState({});
  const [getByFilter, setGetByFilter] = useState(tabsData[0]?.key);
  const [activeTab, setActiveTab] = useState(tabsData[0]?.tabId);
  const [openChecklistFilter, setOpenChecklistFilter] = useState(false);
  const [filterTabs, setFilterTabs] = useState('filters');
  const [checklistFilter, setChecklistFilter] = useState('owners');
  const [checklistFilterOptionSelected, setChecklistFilterOptionSelected] =
    useState(ChecklistFilters[1]);
  const [isChecklistFilterCheck, setIsChecklistFilterCheck] = useState(
    ChecklistFilters[1]
  );

  const [checklistCount, setCheckListCount] = useState({ count: 0 });
  const { setShowModal: setShowCreateChecklist, ChecklistCreateModal } =
    useCreateChecklist(
      () => {
        setRefreshChecklist((prevState) => prevState + 1);
      },
      { setErrorMessage, setSuccessMessage }
    );
  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
      setGetByFilter(tab?.key);
      if (isChecklistFilterCheck?.key !== 'all') {
        setIsChecklistFilterCheck(ChecklistFilters[1]);
      }
    }
  };
  const handleChecklistFilterSelect = (item) => {
    setActiveTab(1);
    setGetByFilter(tabsData[0]?.key);
    setIsChecklistFilterCheck(item);
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <div className="justify-content-between button-pannel d-flex w-100 mt-3 mx-3 align-items-center">
        <Heading pageHeaderDivider="mb-0 w-auto" useBc={true} showGreeting>
          <AnimatedTabs
            tabsData={tabsData}
            activeTab={activeTab}
            toggle={toggle}
          />
        </Heading>
        <LayoutHead
          allRegister={`${checklistCount?.count || 0} checklists`}
          extraClasses={'all-activities'}
        >
          <ChecklistFilterTabsDropdown
            options={ChecklistFilters}
            openFilter={openChecklistFilter}
            btnToggleStyle="py-2 btn-sm"
            setOpenFilter={setOpenChecklistFilter}
            filterOptionSelected={isChecklistFilterCheck}
            filterSelected={checklistFilterSelected}
            filterTabs={filterTabs}
            handleFilterSelect={(e, item) => handleChecklistFilterSelect(item)}
            setFilterOptionSelected={setChecklistFilterOptionSelected}
            setFilterSelected={setIsChecklistFilterCheck}
            setFilterTabs={setFilterTabs}
            onHandleFilterOrg={setChecklistFilter}
            extraClasses={'dropdown-menu-right'}
          />
          <div className="d-flex gap-1 align-items-center">
            {isModuleAllowed(
              tenant?.modules,
              PermissionsConstants.Checklist.checklist
            ) && isPermissionAllowed('checklists', 'create') ? (
              <>
                <ButtonIcon
                  label="Checklist"
                  icon="add"
                  onClick={() => setShowCreateChecklist(true)}
                  classnames="btn-sm px-3"
                  color="primary"
                />
              </>
            ) : (
              ''
            )}
          </div>
        </LayoutHead>
      </div>
      <div className="w-100 px-3">
        <ActivitiesChecklist
          checklistCreate={checklistCreate}
          refresh={refreshChecklist}
          filterOptionSelected={checklistFilterOptionSelected}
          setFilterOptionSelected={setChecklistFilterOptionSelected}
          filterSelected={checklistFilterSelected}
          activeTabId={getByFilter}
          checklistFilter={checklistFilter}
          isChecklistFilterCheck={isChecklistFilterCheck}
          setFilterSelected={setChecklistFilterSelected}
          setIsChecklistFilterCheck={setIsChecklistFilterCheck}
          setCheckListCount={setCheckListCount}
          setChecklistCreate={setChecklistCreate}
        />
      </div>
      <ChecklistCreateModal
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      />
    </>
  );
};

export default AllChecklist;

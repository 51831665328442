import { Card, CardBody, Col, Row } from 'reactstrap';
import React from 'react';
import GreenRedCheckItem from '../../blocks/GreenRedCheckItem';
import { TreasuryReportSections } from '../../../reports/reports.constants';
import CutoutWidget from './CutoutWidget';

const BLOCK_DATA = [
  'Improved working capital',
  'Enhanced security',
  'Fast and accurate reconciliation',
  'Reduced cost',
  'Incentives for early payment',
  'More convenience',
];

const Items = ({ items }) => {
  return (
    <div className="d-flex flex-column px-5">
      {items.map((item, index) => (
        <GreenRedCheckItem
          key={item}
          section={TreasuryReportSections.Fraud}
          bordered=""
          textWrap={`text-nowrap font-weight-normal`}
          item={[
            item,
            {
              isChecked: true,
              key: item,
              value: 1,
              itemValue: 1,
            },
          ]}
        />
      ))}
    </div>
  );
};

const ImproveVendorRelationshipWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <Card className="report-widget">
      <CardBody className="justify-content-center align-items-center d-flex w-100">
        <div className="flex-fill">
          <h5 className="text-left mb-1 d-flex align-items-center gap-1 ">
            {widgetConfig.heading}
          </h5>
          <p
            className={`${
              whenPrinting ? 'fs-9' : 'font-size-sm2'
            } mb-1 text-left px-2`}
          >
            {widgetConfig.description}
          </p>

          <div className="px-2">
            <CutoutWidget
              widgetConfig={widgetConfig}
              whenPrinting={whenPrinting}
              cutoutConfig={{
                icon: 'data_check',
                text: `Benefits <br /> Include`,
                bg: 'report-widget-inner-section-soft bg-white',
                iconConfig: {
                  fg: 'text-primary',
                  bg: 'bg-primary-soft',
                  icon: 'data_check',
                },
              }}
            >
              <Row
                className={`w-100 position-relative ${
                  whenPrinting ? 'p-1' : 'p-4'
                }`}
              >
                <Col className="px-0">
                  <Items items={BLOCK_DATA.slice(0, 3)} />
                </Col>
                <Col md={1}>
                  <span className="line fti-item etpr ivr" />
                </Col>
                <Col className="px-0">
                  <Items items={BLOCK_DATA.slice(3, 6)} />
                </Col>
              </Row>
            </CutoutWidget>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ImproveVendorRelationshipWidget;

import { Col, Row } from 'reactstrap';
import MaterialIcon from '../../commons/MaterialIcon';
import { formatUSPhoneNumber } from '../../../utils/Utils';
import ActionButtons from './tabs/ActionButtons';
import React from 'react';

const BankContact = ({
  acnt,
  handleEditBankContact,
  handleDeleteBankContact,
}) => {
  return (
    <Row
      key={acnt.id}
      className="fs-7 bg-hover-gray mx-0 py-2 px-0 cursor-pointer border-bottom border-white"
    >
      <Col
        md={6}
        className={`${
          handleEditBankContact ? 'pl-2' : 'pl-0'
        } align-self-center`}
      >
        <div className="d-flex align-items-center gap-1">
          <div>
            <h6 className="mb-0 font-size-sm">{acnt.name}</h6>
            <p className="mb-0 fs-8">{acnt.title}</p>
          </div>
        </div>
      </Col>
      <Col md={6} className="align-self-center">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <div className="d-flex align-items-center gap-1">
              <div className="p-1 border rounded-circle d-flex align-items-center justify-content-center">
                <MaterialIcon
                  icon="mail"
                  rounded
                  filled
                  clazz="text-gray-dark"
                  size="fs-7"
                />
              </div>
              <span className="fs-7">{acnt.email}</span>
            </div>
            <div className="d-flex align-items-center mt-1 gap-1">
              <div className="p-1 border rounded-circle d-flex align-items-center justify-content-center">
                <MaterialIcon
                  icon="phone"
                  rounded
                  filled
                  size="fs-7"
                  clazz="text-gray-dark"
                />
              </div>
              <span className="text-capitalize fs-7">
                {formatUSPhoneNumber(acnt.phone)}
              </span>
            </div>
          </div>
          {handleEditBankContact || handleDeleteBankContact ? (
            <ActionButtons
              onEdit={() => handleEditBankContact(acnt)}
              onDelete={() => handleDeleteBankContact(acnt)}
            />
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

export default BankContact;

import React, { useState, useEffect } from 'react';

import SimpleModal from '../../modal/SimpleModal';
import contactService from '../../../services/contact.service';
import stringConstants from '../../../utils/stringConstants.json';
import AutoComplete from '../../AutoComplete';
import { usePagesContext } from '../../../contexts/pagesContext';
import AddPeople from '../../peoples/AddPeople';
import { extractNameParts } from '../../../utils/Utils';

const constants = stringConstants.deals.organizations.profile;

const AddContact = ({
  organizationId,
  moduleMap,
  getContacts,
  showAddContactModal,
  setShowAddContactModal,
  children,
  organization,
  setSuccessMessage,
  setErrorMessage,
}) => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState('');
  const [relatedOrg, setRelatedOrg] = useState({});
  const [data, setData] = useState([]);
  const [isNewContact, setIsNewContact] = useState(false);
  const [newContact, setNewContact] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [, setOpenList] = useState(false);
  const [refreshState, setRefreshState] = useState(0);
  const { pageContext, setPageContext } = usePagesContext();

  const searchContacts = async () => {
    try {
      const contacts = await contactService.getContact(
        { organization_id: organizationId, search: filter },
        { limit: 10, page: 1 }
      );
      const { data } = await contactService.getContact(
        { search: filter },
        { limit: 10 + contacts.data.contacts.length, page: 1 }
      );
      const DATA = data.contacts.filter((d) => {
        return !contacts.data.contacts.some((c) => {
          return c.id === d.id;
        });
      });
      setData(
        DATA?.map((u) => ({ ...u, name: `${u.first_name} ${u.last_name}` }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (item) => {
    setSelectedItem(item.id);
    setRelatedOrg(item);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const selectedContact = selectedItem;

      await contactService.linkOrganization(selectedContact, organizationId);

      setSuccessMessage(
        constants.messageSaveSuccess.replace(/Contact/g, moduleMap)
      );
      getContacts();
    } catch (error) {
      setErrorMessage(
        constants.messageSaveFailed.replace(/Contact/g, moduleMap)
      );
    } finally {
      setIsLoading(false);
      // clearing out context so when user open share popup it will load updated
      setPageContext({ ...pageContext, CompanyContacts: null });
      setShowAddContactModal(false);
    }
  };

  const handleCloseAddContactModal = () => {
    setShowAddContactModal(false);
  };

  const handleCloseContactModal = () => {
    setShowContactModal(false);
  };

  useEffect(() => {
    if (showAddContactModal) {
      searchContacts();
    } else {
      setSelectedItem(null);
      getContacts();
      setFilter('');
    }
  }, [showAddContactModal, refreshState]);

  useEffect(() => {
    if (filter) {
      searchContacts();
    }
  }, [filter]);

  return (
    <>
      <SimpleModal
        onHandleCloseModal={handleCloseContactModal}
        open={showContactModal}
        modalTitle={'Contact Unlinked'}
        moduleMap={moduleMap}
        buttonLabel={`Yes`}
        handleSubmit={() => {
          handleCloseContactModal();
          handleSubmit();
        }}
      >
        <p className="mb-0 font-size-sm2">
          This contact is already assigned to a{' '}
          <b>{relatedOrg?.organization?.name || ''}</b> Company, it will be
          unlinked with that company.
        </p>
      </SimpleModal>

      <SimpleModal
        onHandleCloseModal={handleCloseAddContactModal}
        open={showAddContactModal}
        modalTitle={`Add ${moduleMap}`}
        buttonLabel={`Add ${moduleMap}`}
        moduleMap={moduleMap}
        isLoading={isLoading}
        buttonsDisabled={!selectedItem}
        handleSubmit={() => {
          if (relatedOrg?.organization?.name) {
            setShowContactModal(true);
          } else {
            handleSubmit();
          }
        }}
      >
        <AutoComplete
          id="assigned_user_id"
          placeholder={`Search for ${moduleMap}`}
          name="assigned_user_id"
          showAvatar={true}
          loading={false}
          type={moduleMap?.toLowerCase()}
          onChange={(e) => {
            setFilter(e?.target?.value);
          }}
          data={data}
          showIcon={false}
          onHandleSelect={(item) => {
            handleSelect(item);
          }}
          customKey="name"
          showEmail={true}
          createItem={(data) => {
            const nameParts = extractNameParts(data);
            setNewContact({
              ...nameParts,
              organization,
              organization_id: organizationId,
            });
            setShowAddContactModal(false);
            setIsNewContact(true);
          }}
        />
      </SimpleModal>

      {children}

      {isNewContact && (
        <AddPeople
          openPeople={isNewContact}
          setOpenPeople={setIsNewContact}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          linkContact={true}
          setOpenList={setOpenList}
          organization={organization}
          setRefreshState={setRefreshState}
          contactData={newContact}
          organizationId={organizationId}
        />
      )}
    </>
  );
};

export default AddContact;

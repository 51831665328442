import { Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import SearchOptionDropdown from '../../../components/commons/SearchOptionDropdown';
import teamsService from '../../../services/teams.service';
import { AssignmentSnapChatWidget } from './AssignmentSnapChatWidget';
const AssignmentStatsTooltip = {
  AssignmentCompleted: '% of all assignments that have been completed',
  AssignmentNotStarted:
    '% of all assignments with users who have received the assignment but have not taken action',
  totalAssignments:
    'An assignment may consist of one lesson or a bundled set of lessons. Each assignment is created by an administrator and given a unique name and due date',
};
export const AssignmentOrgSnapChat = ({
  insightName,
  assignmentStatsNames = {},
}) => {
  const [queryFilter, setQueryFilter] = useState([]);
  const [show, setShow] = useState(false);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await teamsService.getTeams({ page: 1, limit: 1000 });
      setTeams(data);
      const ids = data?.map((team) => team.id);
      setQueryFilter([...ids]);
    })();
  }, []);

  const handleOptionSelect = (event) => {
    const { value } = event.target;
    setQueryFilter((prevState) => {
      const isSelected = prevState.includes(value);
      const updatedSelectedData = isSelected
        ? prevState.filter((item) => item !== value && !!item)
        : [...prevState, value];
      return updatedSelectedData;
    });
  };
  return (
    <>
      <Card>
        <Card.Header className={`justify-content-between`}>
          <h4 className="card-title text-hover-primary py-2 mb-0 pt-2 mt-1">
            {insightName}
          </h4>
          {insightName === 'Assignments Organization Snapshot' ||
          insightName === '' ? (
            <></>
          ) : (
            <div className="w-33">
              <SearchOptionDropdown
                data={teams}
                show={show}
                label="Team"
                scrollable="pr-3 text-left"
                setShow={setShow}
                customKey={'id'}
                handleOptionSelected={handleOptionSelect}
                selectedData={queryFilter}
                setSelectedData={setQueryFilter}
              />
            </div>
          )}
        </Card.Header>
        <div className="my-4">
          <AssignmentSnapChatWidget
            queryFilter={queryFilter}
            assignmentStatsNames={assignmentStatsNames}
            allSelectedData={teams}
            assignmentStatsTooltip={AssignmentStatsTooltip}
            headingClass="font-size-lg"
            countClass={'font-size-4em text-left'}
            subHeadingClass="font-size-xl"
            customClass="col-md-4 pb-3 pr-2 pl-2"
          />
        </div>
      </Card>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { isPermissionAllowed } from '../../../utils/Utils';
import AddNote from '../../peopleProfile/contentFeed/AddNote';
import { NoteItem } from '../../steps/NotesItem';
import { FormGroup } from 'react-bootstrap';
import { Col, Label } from 'reactstrap';
import NoDataFound from '../../commons/NoDataFound';
import feedService from '../../../services/feed.service';
import IconTextLoader from '../../loaders/IconText';
import { useProfileContext } from '../../../contexts/profileContext';

export const AddNoteChecklist = ({ checklist }) => {
  const [openNote, setOpenNote] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allNotes, setAllNotes] = useState([]);
  const [refreshRecentFiles, setRefreshRecentFiles] = useState(false);
  const { profileInfo } = useProfileContext();

  const getNotes = async () => {
    setIsLoading(true);
    try {
      const notes = await feedService.getNote(
        { organizationChecklistId: checklist?.organizationChecklistId },
        { page: 1, limit: 15 }
      );
      setAllNotes(notes?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRefreshRecentFiles(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (checklist?.organizationChecklistId) {
      getNotes();
    }
  }, [checklist?.organizationChecklistId, refreshRecentFiles]);
  return (
    <>
      {isPermissionAllowed('notes', 'create') &&
        isPermissionAllowed('checklists', 'edit') && (
          <FormGroup row className="align-items-center border-top mt-3">
            <Label
              md={12}
              className="text-left font-size-sm font-weight-bold pb-0 px-0"
            >
              Notes
            </Label>
            <Col md={12} className="px-0">
              <AddNote
                getNotes={() => getNotes()}
                from={openNote}
                organizationChecklistId={checklist?.organizationChecklistId}
                setOverlay={setOpenNote}
                setRefreshRecentFiles={setRefreshRecentFiles}
                placeholder={() => (
                  <div
                    className="text-muted"
                    style={{
                      backgroundColor: 'white',
                      position: 'absolute',
                      marginLeft: '1px',
                    }}
                  >
                    {openNote ? '' : "What's this note about?"}
                  </div>
                )}
              />
            </Col>
          </FormGroup>
        )}
      {isPermissionAllowed('notes', 'view') && allNotes?.length ? (
        <>
          {isLoading ? (
            <IconTextLoader count={3} />
          ) : (
            allNotes?.map((item) => (
              <NoteItem
                data={item}
                organizationChecklistId={checklist?.organizationChecklistId}
                refreshFeed={setRefreshRecentFiles}
                getNotes={() => getNotes()}
                key={item?.id}
                me={profileInfo}
                isOwner={
                  profileInfo?.role?.admin_access ||
                  item?.created_by === profileInfo?.id
                }
                feedId={item?.id}
              />
            ))
          )}
        </>
      ) : (
        <NoDataFound
          icon="subject"
          title={
            <div className="font-normal font-size-sm2 text-gray-search">
              This record doesn&apos;t have any notes
            </div>
          }
          containerStyle="text-gray-search py-6"
        />
      )}
    </>
  );
};

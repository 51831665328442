import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Table from '../GenericTable';
import { isToFixedNoRound, setDateFormat } from '../../utils/Utils';
import routes from '../../utils/routes.json';
import IdfOwnersHeader from '../idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import userService from '../../services/user.service';
import TableSkeleton from '../commons/TableSkeleton';
import TableRowHover from '../commons/TableRowHover';
import Avatar from '../../components/Avatar';
import stageService from '../../services/stage.service';
import Skeleton from 'react-loading-skeleton';
import { ProgressBar } from 'react-bootstrap';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';

function calculateStageProgress(stages, currentStage) {
  const totalStages = stages.length;
  const currentFoundStage = stages.find(
    (stage) => stage.id === currentStage.id
  );
  if (!currentFoundStage) {
    return 0;
  }
  const currentPosition = currentFoundStage.position;
  return Math.round((currentPosition / totalStages) * 100);
}
const StageProgressBar = ({ stages = [], stage = {} }) => {
  // get all the pipeline stages and then dynamic calculate the current stage
  const [perc, setPerc] = useState(0);
  const [loading, setLoading] = useState(false);
  const loadStageProgress = async () => {
    try {
      setLoading(true);
      setPerc(calculateStageProgress(stages, stage));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadStageProgress();
  }, []);
  return (
    <>
      {loading ? (
        <Skeleton height="10" width={80} />
      ) : (
        <ProgressBar
          now={parseInt(perc)}
          className="w-100 rounded"
          variant="primary"
        />
      )}
    </>
  );
};
const DealTable = ({
  data = [],
  paginationInfo,
  onPageChange,
  handleEdit,
  selectedCourses,
  setSelectedCourses,
  onClickRow,
  service,
  showLoading,
  onAddDeal,
  dataInDB,
  sortingTable,
  sortingOrder,
  selectedPipeline,
  viewType,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [me, setMe] = useState(null);
  const [stages, setStages] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    const me = await userService
      .getUserInfo()
      .catch((err) => console.error(err));

    setMe(me);
  };

  const columns = [
    {
      key: 'title',
      component: 'Title',
    },
    {
      key: 'value',
      component: 'value',
    },
    {
      key: 'organization',
      component: 'organization',
    },
    {
      key: 'stages',
      component: 'stage',
      width: '200px',
    },
    {
      key: 'contactPerson',
      component: 'Contact Person',
    },
    {
      key: 'expectedCloseDate',
      component: 'Closing Date',
    },
    {
      key: 'owner',
      component: 'Owners',
    },
  ];

  const loadStages = async () => {
    try {
      const data = await stageService.getStages(selectedPipeline.id);
      setStages(data || []);
    } catch (e) {
      setStages([]);
    }
  };
  const rows = data.map((item) => {
    const {
      id,
      name,
      amount,
      organization,
      contact,
      stage,
      date_closed,
      assigned_user,
      owners,
    } = item;

    const isPrincipalOwner =
      me && item
        ? me?.role?.admin_access || assigned_user?.id === me?.id
        : false;

    const response = {
      ...item,
      dataRow: [
        {
          key: 'title',
          component: (
            <Link
              to={`${routes.dealsPipeline}/${item.id}`}
              className="text-black fw-bold"
            >
              {name}
            </Link>
          ),
        },
        {
          key: 'value',
          component: <span>{isToFixedNoRound(amount, 2)}</span>,
        },
        {
          key: 'organization',
          component: (
            <div className="d-flex gap-1 align-items-center">
              <Avatar
                user={organization}
                classModifiers="bg-primary-soft text-primary"
                sizeIcon="fs-6"
                defaultSize="xs"
                type={organization?.name ? 'organization' : 'contact'}
              />
              {organization?.name}
            </div>
          ),
        },
        {
          key: 'stages',
          component: (
            <div className="d-flex align-items-center">
              <div className="flex-fill w-50">
                <StageProgressBar stages={stages} stage={stage} />
              </div>
              <span className="flex-fill w-50 pl-2">
                <TextOverflowTooltip text={stage?.name} />{' '}
              </span>
            </div>
          ),
        },
        {
          key: 'contactPerson',
          component: (
            <span>
              {contact?.first_name} {contact?.last_name}
            </span>
          ),
        },
        {
          key: 'expectedCloseDate',
          component: (
            <span>
              {date_closed ? setDateFormat(date_closed, 'MM/DD/YYYY') : ''}
            </span>
          ),
        },
        {
          key: 'owner',
          component: (
            <>
              <TableRowHover
                onClick={() => {
                  history.push(`${routes.dealsPipeline}/${item.id}`);
                }}
              />
              <IdfOwnersHeader
                mainOwner={assigned_user}
                service={service}
                serviceId={id}
                listOwners={owners}
                isClickable={false}
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                }}
                defaultSize="xs"
                maxOwners={3}
                isprincipalowner={isPrincipalOwner}
                small
                addBtnBgGray
              />
            </>
          ),
        },
      ],
    };
    return response;
  });

  useEffect(() => {
    if (selectedPipeline?.id && viewType === 'list') {
      loadStages();
    }
  }, [viewType, selectedPipeline]);
  return (
    <div className="table-responsive-md datatable-custom">
      {showLoading ? (
        <TableSkeleton cols={6} rows={10} />
      ) : (
        <Table
          stickyColumn="stickyColumn1"
          usePagination
          selectedData={selectedCourses}
          setSelectedData={setSelectedCourses}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          columns={columns}
          data={rows}
          headClass="bg-gray-table-head border-top"
          onPageChange={onPageChange}
          onHandleEdit={handleEdit}
          onClick={onClickRow}
          paginationInfo={paginationInfo}
          toggle={onAddDeal}
          emptyDataText="No opportunities available yet"
          title="Opportunities"
          dataInDB={dataInDB}
          sortingTable={sortingTable}
          sortingOrder={sortingOrder}
          noDataInDbValidation={true}
        />
      )}
    </div>
  );
};

export default DealTable;

import * as chrono from 'chrono-node';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import DatePicker from '../../../components/dealsBreakdown/DatePicker';
import Loading from '../../../components/Loading';
import { InsightsQuery } from './InsightsQuery';
import { DATE_FORMAT } from '../../../utils/Utils';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import { reportPages } from '../../../utils/constants';

export const InsightsTable = ({
  dateRange = {
    startDate: new Date(1970, 0, 1),
    endDate: new Date(),
  },
  filters = [], // optional filters to be displayed such as User filtering
  insight,
  insightName,
  render,
  exportToCSV,
  setDateRange,
}) => {
  const [reportPage, setReportPage] = useState(reportPages[0]);
  const [getCSVBtn, setGetCSVBtn] = useState(false);
  const [getCSVBtnAccess, setGetCSVBtnAccess] = useState(false);

  const format = DATE_FORMAT;
  if (!dateRange || !setDateRange) {
    [dateRange, setDateRange] = useState(dateRange);
  }

  const [query, setQuery] = useState(insight);

  const isQueryReady = () => {
    return query && Object.keys(query).length > 0;
  };

  const compareRelativeTime = (timeDateRange) => {
    const parsedRange =
      chrono.parseDate(timeDateRange) || moment().subtract(6, 'months');
    const parsedStartDate = moment(parsedRange).format(format);
    const now = moment().format(format);
    const relativeRange = {
      isSame: false,
      startDate: parsedStartDate,
      endDate: now,
    };

    if (dateRange) {
      const startDate = moment(dateRange.startDate).format(format);
      const endDate = moment(dateRange.endDate).format(format);
      relativeRange.isSame = startDate === parsedStartDate && endDate === now;
    }
    return relativeRange;
  };

  const getDateType = () => {
    const { startDate, endDate } = dateRange;
    const startDay = moment(startDate);
    const endDay = moment(endDate);

    return endDay.diff(startDay, 'days') > 31 ? 'month' : 'day';
  };

  useEffect(() => {
    setQuery(insight);
  }, [insightName]);

  useEffect(() => {
    if (!isQueryReady()) {
      return;
    }

    if (!query.timeDimensions) {
      const relativeDate = chrono.parseDate('from 90 day ago to now');
      setDateRange({
        startDate: moment(relativeDate).format(format),
        endDate: moment().format(format),
      });
    } else {
      setDateRange({
        startDate: moment(dateRange.startDate || new Date(1970, 0, 1)).format(
          format
        ),
        endDate: moment(dateRange.endDate || new Date()).format(format),
      });
    }
  }, [query]);

  useEffect(() => {
    if (!isQueryReady() || !dateRange) {
      return;
    }

    const [{ dateRange: timeDateRange, granularity }] = query.timeDimensions;

    const startDate = moment(dateRange.startDate).format(format);
    const endDate = moment(dateRange.endDate).format(format);

    if (timeDateRange) {
      if (
        Array.isArray(timeDateRange) &&
        timeDateRange.length === 2 &&
        startDate === timeDateRange[0] &&
        endDate === timeDateRange[1]
      ) {
        return;
      } else if (typeof timeDateRange === 'string') {
        const relativeTime = compareRelativeTime(timeDateRange);
        if (relativeTime.isSame) {
          return;
        }
      }
    }
    const dateType = getDateType();
    if (granularity && granularity !== dateType) {
      query.timeDimensions[0].granularity = dateType;
    }

    setQuery({
      ...query,
      timeDimensions: [
        {
          ...query.timeDimensions[0],
          dateRange: [
            moment(dateRange.startDate).format(format),
            moment(dateRange.endDate).format(format),
          ],
        },
      ],
    });
  }, [dateRange]);

  useEffect(() => {
    if (!isQueryReady() || !reportPage) {
      return;
    }
    if (reportPage.key === 'all') {
      delete query.limit;
      setQuery({
        ...query,
      });
    } else {
      setQuery({
        ...query,
        limit: parseInt(reportPage.key),
      });
    }
  }, [reportPage]);

  if (!isQueryReady() || !dateRange) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <Card.Header className="justify-content-between">
          <h4 className="card-title text-hover-primary mb-0">{insightName}</h4>

          {filters.map((Filter) => {
            return (
              <>
                <div className="w-35">
                  <Filter
                    setGetCSVBtnAccess={setGetCSVBtnAccess}
                    isQueryReady={isQueryReady}
                    query={query}
                    setQuery={setQuery}
                  />
                </div>
              </>
            );
          })}

          <div
            className={`d-sm-flex justify-content-sm-end align-items-sm-center ${
              (filters?.length === 0 && getCSVBtn && exportToCSV) ||
              (getCSVBtnAccess && getCSVBtn && exportToCSV)
                ? ' mr-5 pr-5'
                : ''
            }`}
          >
            <DatePicker
              range={dateRange}
              setRange={setDateRange}
              disablePastDate
              extraClass="mx-1"
            />
            <ButtonFilterDropdown
              buttonText="Timeline"
              options={reportPages}
              filterOptionSelected={reportPage}
              handleFilterSelect={(e, item) => {
                setReportPage(item);
              }}
              btnToggleStyle={
                (filters?.length === 0 && getCSVBtn && exportToCSV) ||
                (getCSVBtnAccess && getCSVBtn && exportToCSV)
                  ? 'mr-5'
                  : ''
              }
            />
          </div>
        </Card.Header>
        <InsightsQuery
          setGetCSVBtn={setGetCSVBtn}
          query={query}
          render={render}
          setQuery={setQuery}
        />
      </Card>
    </>
  );
};

import RightSlidingDrawer from '../../modal/RightSlidingDrawer';
import React, { useEffect, useState } from 'react';
import { ChecklistStatuses } from '../../../utils/checklist.constants';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import AnimatedTabs from '../../commons/AnimatedTabs';
import {
  DATE_FORMAT,
  checkDueDate,
  createBlobObject,
  getFileSize,
  isModuleAllowed,
  isPermissionAllowed,
  overflowing,
  parseRRuleString,
  removeBodyScroll,
  setDateFormat,
} from '../../../utils/Utils';
import ChecklistStatus from '../../checklist/ChecklistStatus';
import ChecklistItems from '../../checklist/ChecklistItems';
import NoDataFoundTitle from '../NoDataFoundTitle';
import NoDataFound from '../../commons/NoDataFound';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import MaterialIcon from '../../commons/MaterialIcon';
import checklistService from '../../../services/checklist.service';
import Loading from '../../Loading';
import filesService from '../../../services/files.service';
import FileIcon from '../../fileIcon/FileIcon';
import {
  CHOOSE_IMAGE_FILE,
  ChecklistFilters,
  FILE_DOESNT_EXIST,
  MAX_WEIGHT,
  MAX_WEIGHT_ERROR_MESSAGE,
} from '../../../utils/constants';
import assetsService from '../../../services/assets.service';
import AutoComplete from '../../AutoComplete';
import contactService from '../../../services/contact.service';
import { useModuleContext } from '../../../contexts/moduleContext';
import { Skeleton } from '@mui/material';
import IconTextLoader from '../../loaders/IconText';
import Avatar from '../../Avatar';
import TooltipComponent from '../../lesson/Tooltip';
import DeleteConfirmationModal from '../../modal/DeleteConfirmationModal';
import { getClientPortalToken } from '../../../layouts/constants';
import { AddNoteChecklist } from './AddNoteChecklist';
import AuditActivityTimeline from '../../ActivityTimeline/AuditActivityTimeline';
import DragDropUploadFile from '../../commons/DragDropUploadFile';
import ButtonIcon from '../../commons/ButtonIcon';
import { FormatTextForDisplay } from '../../commons/FormattedTextarea';
import { useTenantContext } from '../../../contexts/TenantContext';
import { PermissionsConstants } from '../../../utils/permissions.constants';
import MoreActions from '../../MoreActions';
import organizationService from '../../../services/organization.service';

const ownerTitle = (profile) => {
  if (profile?.external) {
    return profile?.email;
  }
  return `${profile?.first_name} ${profile?.last_name}`;
};

const Overview = ({
  organization,
  checklist,
  getOwner = [],
  setErrorMessage,
  setSuccessMessage,
  refresh,
  setRefresh,
  getChecklistProgress,
  getChecklistData,
  activityChecklist = false,
}) => {
  const clientPortalToken = getClientPortalToken();

  const [contacts, setContacts] = useState([]);
  const [contactLoading, setContactLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState([]);
  const { moduleMap } = useModuleContext();
  const [items, setItems] = useState([]);

  const [assignContacts, setAssignContacts] = useState([]);
  const [progress, setProgress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [savingContact, setSavingContact] = useState(false);
  const [componentsToDelete, setComponentsToDelete] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dueDate = checkDueDate(checklist?.initialDueDate);
  const [progressLoading, setProgressLoading] = useState(false);

  const DeleteFieldBody = ({ text }) => {
    return (
      <div>
        <div className="d-flex justify-content-center align-items-center">
          <MaterialIcon icon="report_problem" clazz="font-size-4em" />
        </div>
        <hr />
        <h4>{text}</h4>
        <ul className="list-disc">
          {componentsToDelete.map((item) => (
            <li className="font-weight-medium ml-4" key={item?.id}>
              <p className="mb-1">{item?.title}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const getOrganizationChecklistContacts = async () => {
    setLoading(true);
    try {
      const { data } = await checklistService.getOrganizationChecklistContacts({
        limit: 100,
        page: 1,
        organizationChecklistId: checklist?.organizationChecklistId,
      });
      const contactsGetById = data?.data?.map(async (item) => {
        return await contactService.getContactById(item?.contactId);
      });
      const contactsData = await Promise.all(contactsGetById);
      const updatedContacts = contactsData?.map((item, index) => {
        const name = `${item?.first_name} ${
          item?.last_name !== null ? item?.last_name : ''
        }`;
        return {
          ...item,
          title: name,
          organizationChecklistContactId:
            data?.data?.[index]?.organizationChecklistContactId,
        };
      });
      setAssignContacts(updatedContacts);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contacts?.length > 0 || assignContacts?.length > 0) {
      const filteredContacts = contacts?.filter((item) => {
        return !assignContacts?.some((contact) => contact?.id === item?.id);
      });
      setContacts(filteredContacts);
    }
  }, [assignContacts?.length]);

  const handleUnAssignedContactChecklist = async () => {
    try {
      const data = await checklistService.deleteCheckListContact(
        componentsToDelete[0]?.organizationChecklistContactId
      );
      if (data?.status === 200) {
        setSuccessMessage('Successfully Unassigned Contact');
      }
      getContacts();
      getOrganizationChecklistContacts();
    } catch (e) {
      console.log(e);
    } finally {
      setOpenDeleteModal(false);
    }
  };
  const getContacts = async () => {
    setSavingContact(true);
    try {
      const data = await contactService.getContactsByorganizationId(
        { organizationId: checklist?.organizationId },
        { page: 1, limit: 100 }
      );
      const UpdatedContacts = data?.contacts?.map((item) => {
        const name = `${item?.first_name} ${
          item?.last_name !== null ? item?.last_name : ''
        }`;
        return {
          ...item,
          title: name,
        };
      });

      setContacts(UpdatedContacts || []);
    } catch (e) {
      console.log(e);
    } finally {
      setSavingContact(false);
    }
  };
  useEffect(() => {
    if (checklist?.organizationId) {
      getContacts();
    }
  }, []);

  const createContact = async () => {
    setContactLoading(true);
    try {
      const contactData = selectedContact?.map(async (contact) => {
        const data = await checklistService.addContactChecklist({
          organizationChecklistId: checklist?.organizationChecklistId,
          contactId: contact?.id,
        });
        return data;
      });
      await Promise.all(contactData);
      setSuccessMessage('Checklist Assign to Contact');
      getOrganizationChecklistContacts();
    } catch (e) {
      if (e.response?.status === 409) {
        setErrorMessage('Please Select the Other Contact');
      }
    } finally {
      setSelectedContact([]);
      setContactLoading(false);
    }
  };
  const updatedDate = moment(checklist?.initialDueDate).format(DATE_FORMAT);
  const handleSelect = async (item) => {
    const newContacts = [...selectedContact, item];
    setSelectedContact(newContacts);
  };

  const getChecklistItems = async (id) => {
    try {
      const { data } = await checklistService.getCheckListItemsById(id, {
        limit: 100,
        page: 1,
      });
      setItems(data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (checklist?.checklistId) {
      getChecklistItems(checklist?.checklistId);
      getOrganizationChecklistContacts();
    }
  }, [checklist?.checklistId]);

  const clearState = () => {
    setSelectedContact([]);
  };
  const removeItem = (item) => {
    setComponentsToDelete([item]);
    setOpenDeleteModal(true);
  };
  useEffect(() => {
    if (items?.length === progress?.length && activityChecklist) {
      getChecklistData();
    }
  }, [progress?.length]);
  const getProgress = async () => {
    setProgressLoading(true);
    const checklistItemsProgress =
      await checklistService.getOrgCheckListProgress({
        limit: 100,
        page: 1,
        organizationChecklistId: checklist?.organizationChecklistId,
      });
    const getChecklistProgress = checklistItemsProgress?.data?.data?.find(
      (item) => {
        return (
          item?.organizationChecklistId === checklist?.organizationChecklistId
        );
      }
    );
    setProgress(getChecklistProgress);
    setRefresh(false);
    setProgressLoading(false);
  };
  useEffect(() => {
    if (checklist?.organizationChecklistId) {
      getProgress();
    }
  }, [checklist?.organizationChecklistId, refresh]);
  const recurrencePattern = parseRRuleString(checklist?.recurrencePattern);
  const statusProps = {
    classnames: clientPortalToken?.contact_id
      ? progress?.clientProgress === 100
        ? ChecklistStatuses.Completed.color
        : ChecklistStatuses.InProgress.color
      : progress?.internalProgress === 100
      ? ChecklistStatuses.Completed.color
      : progress?.internalProgress === 0 || progress?.internalProgress === null
      ? ChecklistStatuses.Pending.color
      : ChecklistStatuses.InProgress.color,
    item: {
      status: clientPortalToken?.contact_id
        ? progress?.clientProgress === 100
          ? 'Completed'
          : progress?.clientProgress === 0 || progress?.clientProgress === null
          ? 'Pending'
          : 'In Progress'
        : progress?.progress === 100
        ? 'Completed'
        : progress?.progress === 0 || progress?.progress === null
        ? ChecklistStatuses.NotStarted.text
        : 'In Progress',
    },
  };
  return (
    <>
      <Card className="mb-3 rounded-0">
        <CardBody>
          {checklist?.organizationChecklistId && (
            <div className="pb-3 position-relative">
              {isPermissionAllowed('checklists', 'edit') && (
                <FormGroup row className="align-items-center mt-3">
                  <Col md={4} className="text-left fw-bold font-size-sm">
                    Assign Contacts
                  </Col>
                  <Col md={8}>
                    <AutoComplete
                      id="assigned_user_id"
                      placeholder={`Search for ${moduleMap.organization.singular} ${moduleMap.contact.singular}`}
                      name="assigned_user_id"
                      data={contacts}
                      loading={savingContact}
                      onChange={(items, itemToRemove) => {
                        const allOption = items.find((t) => t.id === -1);
                        if (allOption) {
                          setSelectedContact(
                            contacts.filter((t) => t.id !== -1)
                          );
                        } else {
                          setSelectedContact(items.filter((t) => t.id !== -1));
                        }
                      }}
                      clearState={(e) => clearState(e)}
                      customKey="title"
                      isMultiple={true}
                      selected={selectedContact}
                      onHandleSelect={(item) => handleSelect(item)}
                    />
                  </Col>
                </FormGroup>
              )}
              {contactLoading ? (
                <a className="icon-hover-bg cursor-default d-block ml-auto">
                  <Spinner className="spinner-grow-xs" />
                </a>
              ) : (
                <>
                  {selectedContact?.length > 0 && (
                    <span className="ml-auto input-buttons text-right shadow rounded">
                      <TooltipComponent title="Reset Contacts">
                        <MaterialIcon
                          icon="close"
                          clazz={`${
                            selectedContact?.length === 0 ? 'd-none' : ''
                          } fs-6 bg-soft-primary rounded-circle p-1 cursor-pointer`}
                          onClick={() => clearState()}
                        />
                      </TooltipComponent>
                      <TooltipComponent title="Assign Contacts">
                        <MaterialIcon
                          icon="check"
                          clazz={`${
                            selectedContact?.length === 0 ? 'd-none' : ''
                          } fs-6 bg-success text-white rounded-circle p-1 cursor-pointer`}
                          onClick={() => createContact()}
                        />
                      </TooltipComponent>
                    </span>
                  )}
                </>
              )}
            </div>
          )}
          <Row className="mx-0 mb-3">
            <Col md={8} className="p-0 offset-4">
              <div className="overflow-y-auto" style={{ maxHeight: 200 }}>
                {loading ? (
                  <IconTextLoader count={3} />
                ) : (
                  <>
                    {assignContacts?.length > 0 ? (
                      assignContacts?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="d-flex align-items-center justify-content-between pl-2 py-2"
                          >
                            <div className="d-flex align-items-center gap-1">
                              <Avatar user={item} defaultSize="sm" />
                              <div>
                                <p className="font-size-sm mb-0 font-weight-medium">
                                  {item.first_name} {item.last_name}
                                </p>
                                {(item?.email_work || item?.email_home) && (
                                  <p
                                    className="mb-0 position-relative text-muted fs-7"
                                    style={{ top: -2 }}
                                  >
                                    {item?.email_work || item?.email_home}
                                  </p>
                                )}
                              </div>
                            </div>
                            {isPermissionAllowed('checklists', 'delete') && (
                              <a
                                className="cursor-pointer icon-hover-bg"
                                onClick={() => removeItem(item)}
                              >
                                <TooltipComponent
                                  placement="left"
                                  title="Delete Contact From Checklist"
                                >
                                  <MaterialIcon
                                    icon="delete"
                                    rounded
                                    clazz="fs-5"
                                  />
                                </TooltipComponent>
                              </a>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <NoDataFound
                        containerStyle="h-auto"
                        icon="people"
                        iconStyle="text-gray-500 font-size-3em"
                        titleStyle="text-gray-500 font-size-sm"
                        title={'No contact has been assigned'}
                      />
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row className="align-items-center pb-2">
            <Col md={4}>
              <h5 className="mb-0">Progress</h5>
            </Col>
            <Col md={8}>
              {progressLoading ? (
                <Skeleton height={10} width="100%" />
              ) : (
                <div className="d-flex w-100 align-items-center gap-2">
                  <div
                    className="flex-fill"
                    style={{
                      borderRadius: 'var(--borderRadius)',
                      background: 'var(--lightGreyColor)',
                    }}
                  >
                    <ProgressBar
                      style={{ height: 8 }}
                      now={
                        clientPortalToken?.contact_id
                          ? progress?.clientProgress
                          : progress?.progress || 0
                      }
                      max={100}
                      className={
                        'progress-bar-2 checklist-progress w-100 rounded'
                      }
                      key={checklist?.checklistId}
                    />
                  </div>
                  <ChecklistStatus {...statusProps} />
                </div>
              )}
            </Col>
          </Row>
          <Row className="align-items-center py-2">
            <Col md={4}>
              <div className="d-flex align-items-center gap-2">
                <h5 className="mb-0">Due Date</h5>
              </div>
            </Col>
            <Col md={8}>
              <div
                className={`d-flex align-items-center gap-1 ${
                  progress?.progress !== 100 && dueDate === 'text-red'
                    ? 'text-red'
                    : ''
                }`}
              >
                {progress?.progress !== 100 && dueDate === 'text-red' && (
                  <span className="position-relative" style={{ top: 2 }}>
                    <MaterialIcon icon="flag" rounded filled clazz="text-red" />
                  </span>
                )}
                {updatedDate}
              </div>
            </Col>
          </Row>
          <Row className="align-items-center py-2">
            <Col md={4}>
              <h5 className="mb-0">Recurring</h5>
            </Col>
            <Col md={8} className="text-capitalize">
              {recurrencePattern?.INTERVAL === '6'
                ? 'Semi Annually'
                : recurrencePattern?.INTERVAL === '3'
                ? 'Quarterly'
                : recurrencePattern?.FREQ?.toLowerCase()}
            </Col>
          </Row>
          <Row className="align-items-center py-2">
            <Col md={4}>
              <h5 className="mb-0">Related to</h5>
            </Col>
            <Col md={8}>{organization?.name}</Col>
          </Row>
          <Row className="align-items-baseline py-2">
            <Col md={4}>
              <h5 className="mb-0">Message</h5>
            </Col>
            <Col md={8}>
              {checklist?.message ? (
                <FormatTextForDisplay text={checklist?.message} />
              ) : (
                <NoDataFound
                  icon="message"
                  iconStyle="font-size-3em"
                  containerStyle="text-gray-search my-1 py-1"
                  title={<NoDataFoundTitle clazz="fs-7" str={`No message`} />}
                />
              )}
            </Col>
          </Row>
          <ChecklistItems
            checklist={checklist}
            getChecklistItems={getChecklistItems}
            getChecklistData={getChecklistData}
            activityChecklist={activityChecklist}
            setRefresh={setRefresh}
            setErrorMessage={setErrorMessage}
            getChecklistProgress={getChecklistProgress}
          />
          <AddNoteChecklist checklist={checklist} />
        </CardBody>
        <DeleteConfirmationModal
          showModal={openDeleteModal}
          setShowModal={setOpenDeleteModal}
          setSelectedCategories={setComponentsToDelete}
          event={handleUnAssignedContactChecklist}
          itemsConfirmation={componentsToDelete}
          itemsReport={[]}
          customBody={
            <DeleteFieldBody text="it's a contact that is assigned to the checklist that we are going to delete." />
          }
        />
      </Card>
    </>
  );
};

const ChecklistTimeline = ({ checklist, organization }) => {
  return (
    <Card>
      <CardBody className="p-0 pt-3">
        <AuditActivityTimeline
          resourceId=""
          parentResourceId={checklist.organizationChecklistId}
          closeSidePanel={null}
          checklist={checklist}
          customClass="pt-3"
          organization={organization}
          timelineExport={true}
        />
      </CardBody>
    </Card>
  );
};

const ChecklistFiles = ({
  organization,
  checklist,
  activeTab,
  setErrorMessage,
}) => {
  const [fileData, setFileData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openedItemId, setOpenedItemId] = useState(null);
  const [fileInput, setFileInput] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const getChecklistItems = async () => {
    setIsLoading(true);
    try {
      const { data } = await checklistService.getCheckListFiles(
        checklist?.organizationChecklistId,
        {
          limit: 100,
          page: 1,
        }
      );
      const updatedData = data?.data.filter((item) => {
        return item?.fileId !== null;
      });
      if (data?.data?.length > 0) {
        const filesData = updatedData?.map(async (item) => {
          const data = await filesService.getFile(item?.fileId);
          return data?.data;
        });
        let organizationChecklistItemData = await Promise.all(filesData);

        organizationChecklistItemData = organizationChecklistItemData?.map(
          (item, index) => {
            return {
              ...item,
              organizationChecklistFileId:
                data?.data[index]?.organizationChecklistFileId,
            };
          }
        );
        setFileData(organizationChecklistItemData);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (activeTab === 3) getChecklistItems(checklist?.organizationChecklistId);
  }, [activeTab]);
  const onOpen = async (id) => {
    if (!openLoading) {
      setOpenLoading(true);
      await assetsService.openFile(id).catch((_) => {
        setErrorMessage(FILE_DOESNT_EXIST);
      });
      setOpenLoading(false);
      setOpenedItemId(null);
    }
  };

  const onRemoveFile = () => {
    setFileInput(null);
  };
  const onLoadFile = (e) => {
    const loadedFile = e.target.files[0];
    setFileInput(loadedFile);
  };

  const handleSubmit = async () => {
    setIsFileLoading(true);

    const form = new FormData();

    const formBlob = await createBlobObject(fileInput);

    const { size } = formBlob || {};

    if (size > MAX_WEIGHT) {
      setIsLoading(false);
      return setErrorMessage(MAX_WEIGHT_ERROR_MESSAGE);
    }
    form.append('isPublic', false);
    form.append('file', formBlob, fileInput.name);
    form.append('organizationChecklistId', checklist?.organizationChecklistId);

    try {
      await checklistService.createOrgCheckListFile(
        checklist?.organizationChecklistId,
        form
      );
      setFileInput(null);
    } catch (error) {
      setErrorMessage(error?.response?.data?.errors[0]?.message);
    } finally {
      setIsFileLoading(false);
      getChecklistItems();
    }
  };

  const deleteFile = async (id) => {
    await checklistService.deleteCheckListOrgFile(id);
    getChecklistItems();
  };

  return (
    <>
      <div className="overflow-hidden card p-3">
        <h4>Files</h4>
        {isPermissionAllowed('checklists', 'edit') && (
          <DragDropUploadFile
            file={fileInput}
            setFile={(loadedFile) => {
              setFileInput(loadedFile);
            }}
            name="checklistFile"
            displayFormats={[]}
            isLoading={isFileLoading}
            onLoadFile={onLoadFile}
            chooseFileText={CHOOSE_IMAGE_FILE}
            onRemoveFile={onRemoveFile}
          />
        )}
        {fileInput?.name && (
          <div className="text-right my-3">
            <ButtonIcon
              classnames="btn-sm"
              color="primary"
              onclick={handleSubmit}
              label="Upload"
              loading={isFileLoading}
            />
          </div>
        )}
        {fileData.length > 0 ? (
          <h5>{`${fileData?.length || 0} files uploaded`}</h5>
        ) : null}
        {isLoading ? (
          <div className="d-flex align-items-center gap-2 w-100 mt-3">
            <Skeleton variant="rounded" width={70} height={80} />
            <Skeleton variant="rounded" width={'100%'} height={80} />
          </div>
        ) : fileData?.length > 0 ? (
          fileData?.map((item) => (
            <Card key={item}>
              <CardBody>
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex align-items-center">
                    <FileIcon
                      info={item}
                      size="sm"
                      customClass={'pl-0 pr-0'}
                      onClick={() => {
                        onOpen(item?.id);
                        setOpenedItemId(item?.id);
                      }}
                    />
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        onOpen(item?.id);
                        setOpenedItemId(item?.id);
                      }}
                    >
                      <h5 className="d-flex align-items-center mb-1 text-block cursor-pointer">
                        <div>
                          {item?.filename_download}{' '}
                          {openedItemId === item?.id && (
                            <Spinner
                              color="primary"
                              size="sm"
                              className="ml-1"
                            />
                          )}
                        </div>
                      </h5>
                      <ul className="list-inline list-separator text-muted font-size-xs">
                        <li className="list-inline-item">
                          {Object.keys(organization)?.length > 0 ? (
                            <>
                              {organization?.name}
                              <span className="text-muted mx-2">•</span>
                            </>
                          ) : (
                            <>
                              {organization.createdByContact
                                ? 'Client Portal'
                                : `${organization.contact.first_name} ${organization.contact.last_name}`}
                              <span className="text-muted mx-2">•</span>
                            </>
                          )}
                          {item?.updated_at &&
                            `Updated ${setDateFormat(
                              item?.updated_at,
                              'MMM DD YYYY h:mm A'
                            )}  `}
                          {getFileSize(item?.filesize)}
                        </li>
                      </ul>
                    </div>{' '}
                  </div>
                  <MoreActions
                    customClass="d-none"
                    permission={{
                      collection: 'checklists',
                      action: 'delete',
                    }}
                    items={[
                      {
                        id: 'remove',
                        icon: 'delete',
                        name: 'Delete File',
                        className: `${
                          item?.organizationChecklistFileId
                            ? 'd-block'
                            : 'd-none'
                        }`,
                      },
                    ]}
                    onHandleRemove={() =>
                      deleteFile(item?.organizationChecklistFileId)
                    }
                    toggleClassName="w-auto p-0 h-auto"
                  />{' '}
                </div>
              </CardBody>
            </Card>
          ))
        ) : (
          <NoDataFound
            icon="note_stack"
            iconSymbol={true}
            description="This record doesn’t have any files"
          />
        )}
      </div>
    </>
  );
};

const ChecklistTabs = {
  Overview: 1,
  Timeline: 2,
  Files: 3,
};

const ViewChecklist = ({
  openModal,
  setOpenModal,
  getChecklistData,
  checklist = {},
  setSuccessMessage,
  setErrorMessage,
  setIsChecklistFilterCheck,
  isChecklistFilterCheck = {},
}) => {
  const [activeTab, setActiveTab] = useState(ChecklistTabs.Overview);
  const [isLoading, setIsLoading] = useState(false);
  const [checklistData, setChecklistData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [getChecklistProgress, setChecklistProgress] = useState({});
  const [getOwner, setGetOwner] = useState([]);
  const [organization, setOrganization] = useState({});
  const { tenant } = useTenantContext();
  const getChecklistDetail = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await checklistService.getCheckListsById(id);
      let orgChecklistProgress = {};
      if (checklist?.organizationChecklistId) {
        orgChecklistProgress = await checklistService.getOrgCheckListProgress({
          limit: 100,
          page: 1,
          organizationChecklistId: checklist?.organizationChecklistId,
        });
      }
      let orgChecklistData = {};
      if (checklist?.organizationChecklistId) {
        orgChecklistData = await checklistService.getOrgCheckListsById(
          checklist?.organizationChecklistId
        );
        const getOrganization = await organizationService.getOrganizationById(
          orgChecklistData?.data?.organizationId
        );
        setOrganization(getOrganization);
        if (orgChecklistProgress?.data?.data?.length === 0)
          await checklistService.createOrgCheckListProgress({
            organizationChecklistId: checklist?.organizationChecklistId,
            dueDate: orgChecklistData?.data?.initialDueDate,
          });
      }
      if (Object.keys(orgChecklistData?.data)?.length > 0) {
        const getOrgProgress = orgChecklistProgress?.data?.data?.find(
          (item) => {
            return (
              orgChecklistData?.data?.organizationChecklistId ===
              item?.organizationChecklistId
            );
          }
        );
        setChecklistProgress(getOrgProgress);
      }

      setChecklistData({
        ...data,
        initialDueDate: orgChecklistData?.data?.initialDueDate,
        organizationChecklistId: checklist?.organizationChecklistId,
        organizationId: checklist?.organizationId,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getOwners = async () => {
    const { data } = await checklistService.getChecklistOwners({
      organizationChecklistId: checklist?.organizationChecklistId,
      limit: 10,
      page: 1,
    });
    setGetOwner(data?.data);
  };

  useEffect(() => {
    if (openModal) {
      getChecklistDetail(checklist?.checklistId);
      getOwners();
    }
  }, [openModal]);

  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
    }
  };
  const tabsData = [
    {
      title: 'Overview',
      key: ChecklistTabs.Overview,
      component: (
        <Overview
          organization={organization}
          refresh={refresh}
          setRefresh={setRefresh}
          checklist={checklistData}
          getOwner={getOwner}
          getChecklistProgress={getChecklistProgress}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          setOpenModal={setOpenModal}
        />
      ),
      tabId: ChecklistTabs.Overview,
      module: PermissionsConstants.Checklist.checklist,
    },
    {
      title: 'Timeline',
      key: ChecklistTabs.Timeline,
      component: (
        <ChecklistTimeline
          checklist={checklistData}
          organization={organization}
          activeTab={activeTab}
        />
      ),
      module: `accounts_companies_timeline`,
      tabId: ChecklistTabs.Timeline,
    },
    {
      title: 'Files',
      key: ChecklistTabs.Files,
      component: (
        <ChecklistFiles
          activeTab={activeTab}
          organization={organization}
          setErrorMessage={setErrorMessage}
          checklist={checklistData}
        />
      ),
      module: `accounts_companies_files`,
      tabId: ChecklistTabs.Files,
    },
  ];

  useEffect(() => {
    if (openModal) {
      removeBodyScroll();
    } else {
      overflowing();
    }
  }, [openModal]);

  const loader = () => {
    if (isLoading) return <Loading />;
  };
  const containerWidth = 710;
  const allowedOrgChecklistTabs = tabsData?.filter((item) => {
    return isModuleAllowed(tenant?.modules, item?.module);
  });
  return (
    <>
      <RightSlidingDrawer
        open={openModal}
        containerWidth={containerWidth}
        toggleDrawer={() => {
          setOpenModal(false);
          getChecklistData();
          if (isChecklistFilterCheck?.filter) {
            setIsChecklistFilterCheck(ChecklistFilters[0]);
          }
        }}
      >
        {isLoading ? (
          <div
            className="p-0 h-100 shadow-none border-0 d-flex align items-center justify-content-center checklist-content"
            style={{ width: containerWidth }}
          >
            {loader()}
          </div>
        ) : (
          <Card
            className="p-0 h-100 shadow-none border-0 checklist-content"
            style={{ width: containerWidth }}
          >
            <CardHeader className="justify-content-between gap-2 align-items-start">
              <div>
                <h3 className="mb-0 text-capitalize">{checklistData?.title}</h3>
                {getOwner?.length > 0 && (
                  <div className="mt-2">
                    <div className={'d-flex align-items-center gap-1'}>
                      <Avatar
                        user={getOwner[0]?.user}
                        defaultSize={'xs'}
                        sizeIcon={'avatar-light xs'}
                      />
                      <Label className="font-size-sm font-weight-normal mb-0">
                        {ownerTitle(getOwner[0]?.user)}
                      </Label>
                    </div>
                  </div>
                )}
              </div>
              <a
                className="cursor-pointer icon-hover-bg"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenModal(false);
                  getChecklistData();
                }}
              >
                <MaterialIcon icon="close" rounded clazz="fs-5" />
              </a>
            </CardHeader>
            <CardBody className="h-100 bg-gray-5 overflow-y-auto">
              <AnimatedTabs
                tabsData={allowedOrgChecklistTabs}
                activeTab={activeTab}
                toggle={toggle}
                tabActiveClass={'active mb-0'}
              />
              {tabsData.find((item) => item.tabId === activeTab)?.component}
            </CardBody>
          </Card>
        )}
      </RightSlidingDrawer>
    </>
  );
};

export default ViewChecklist;

import GenericTable from '../../../components/GenericTable';
import React from 'react';
import { ChecklistStatuses } from '../../../utils/checklist.constants';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import { DATE_FORMAT } from '../../../utils/Utils';
import ChecklistStatus from '../../../components/checklist/ChecklistStatus';

const ChecklistActivityReport = (
  results = [],
  extraResults,
  query,
  insight,
  handleSortByData,
  handleCallUserComponent
) => {
  const { data } = results[0];

  const rows = data.map((result, index) => {
    return {
      id: index,
      dataRow: [
        {
          key: 'Checklist.title',
          component: (
            <div
              className="d-flex text-wrap align-items-center fw-bold"
              onClick={() =>
                handleCallUserComponent(result['Checklist.checklistId'])
              }
            >
              {result['Checklist.title']}
            </div>
          ),
        },
        {
          key: 'OrganizationChecklist.count',
          component: `${result['OrganizationChecklist.count']} Companies`,
        },
        {
          key: 'Status',
          component: (
            <ChecklistStatus
              item={{
                status:
                  result['OrganizationChecklistProgress.avgOfProgress'] === 100
                    ? ChecklistStatuses.Completed.text
                    : result['OrganizationChecklistProgress.avgOfProgress'] >
                        0 &&
                      result['OrganizationChecklistProgress.avgOfProgress'] !==
                        100
                    ? ChecklistStatuses.InProgress.text
                    : result['OrganizationChecklistProgress.avgOfProgress'] ===
                      0
                    ? ChecklistStatuses.NotStarted.text
                    : ChecklistStatuses.Pending.text,
              }}
            />
          ),
        },
        {
          key: 'OrganizationChecklistProgress.maxOfLastAttempt',
          component: moment(
            result['OrganizationChecklistProgress.maxOfLastAttempt']
          ).format(DATE_FORMAT),
        },
        {
          key: 'OrganizationChecklistProgress.avgOfProgress',
          component: (
            <div
              className="d-flex align-items-center gap-1"
              style={{ width: '87%' }}
            >
              <ProgressBar
                style={{ height: 8, width: '85%' }}
                now={Math.round(
                  result['OrganizationChecklistProgress.avgOfProgress']
                )}
                max={100}
                className={'progress-bar-2 checklist-progress rounded'}
              />{' '}
              <span style={{ width: '15%' }}>
                {Math.round(
                  result['OrganizationChecklistProgress.avgOfProgress']
                )}
                %
              </span>
            </div>
          ),
        },
      ],
    };
  });

  return (
    <div>
      <h4 className="text-hover-primary mb-0 pt-4 pb-2 px-4">
        Active Checklists
      </h4>
      <div>
        <GenericTable
          checkbox={false}
          data={rows}
          exportToCSV={false}
          columns={[
            {
              key: 'Checklist.title',
              component: <span>Checklist Name</span>,
              width: '30%',
              orderBy: 'Checklist.title',
            },
            {
              key: 'OrganizationChecklist.count',
              component: <span>Assigned</span>,
              orderBy: 'OrganizationChecklist.count',
            },
            {
              key: 'Status',
              component: <span>Status</span>,
              orderBy: 'OrganizationChecklistProgress.avgOfProgress',
            },
            {
              key: 'OrganizationChecklistProgress.maxOfLastAttempt',
              component: <span>Last Update</span>,
              orderBy: 'OrganizationChecklistProgress.maxOfLastAttempt',
            },
            {
              key: 'Progress',
              component: <span>Progress</span>,
              orderBy: 'OrganizationChecklistProgress.avgOfProgress',
            },
          ]}
          usePagination={false}
          noDataInDbValidation={true}
          sortingTable={handleSortByData}
        />
      </div>
    </div>
  );
};

export default ChecklistActivityReport;

import React, { useContext, useEffect, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useHistory } from 'react-router';
import Heading from '../components/heading';
import { TabsContext } from '../contexts/tabsContext';
import Security from './Security';
import Profile from './Profile';
import AnimatedTabs from '../components/commons/AnimatedTabs';
import useEnvSettings from '../hooks/useEnvSettings';

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState(1);
  const history = useHistory();
  const { activatedTab, setActivatedTab } = useContext(TabsContext);
  const { disablePasswordChanges } = useEnvSettings();

  const ProfileTab = {
    title: 'Profile',
    component: <Profile setActiveTab={setActiveTab} />,
    tabId: 1,
  };
  const SecurityTab = {
    title: 'Security',
    component: <Security />,
    tabId: 2,
  };
  useEffect(() => {
    if (activatedTab[location.pathname]) {
      setActiveTab(activatedTab[location.pathname]);
    }
    const tab = new URLSearchParams(history.location.search).get('tab');
    if (tab === 'Security') {
      setActiveTab(2);
    }
  }, []);

  let tabsData = [ProfileTab];

  if (!disablePasswordChanges) {
    tabsData = [ProfileTab, SecurityTab];
  }
  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);

      setActivatedTab({
        [location.pathname]: tab.tabId,
      });
    }
  };

  return (
    <>
      <Heading pageHeaderDivider="mb-0" useBc={true} showGreeting>
        <AnimatedTabs
          activeTab={activeTab}
          tabsData={tabsData}
          tabClasses="px-4 pt-1"
          borderClasses="border-b"
          toggle={toggle}
        />
      </Heading>
      <TabContent>
        <TabPane className="position-relative">
          {tabsData.find((item) => item.tabId === activeTab)?.component}
        </TabPane>
      </TabContent>
    </>
  );
};

export default ProfilePage;

import React, { useContext, useEffect, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import Categories from '../views/settings/Resources/Categories';
import Heading from '../components/heading';
import ManageLessons from '../pages/ManageLessons';
import { TabsContext } from '../contexts/tabsContext';
import Courses from '../views/settings/Resources/Courses';
import AnimatedTabs from '../components/commons/AnimatedTabs';
import PageTitle from '../components/commons/PageTitle';
import Assignments from '../components/Assignments/Assignments';
import { useProfileContext } from '../contexts/profileContext';
import { isModuleAllowed } from '../utils/Utils';
import { useTenantContext } from '../contexts/TenantContext';

const Resources = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(1);
  const [itemsOption, setItemsOptions] = useState([]);
  const { activatedTab, setActivatedTab } = useContext(TabsContext);
  const { profileInfo } = useProfileContext();
  const { tenant } = useTenantContext();

  useEffect(() => {
    if (activatedTab[location.pathname]) {
      setActiveTab(activatedTab[location.pathname]);
    }
  }, []);

  const tabsData = [
    {
      title: 'Lessons',
      module: 'settings_learn',
      component: <ManageLessons />,
      icon: 'list_alt',
      tabId: 1,
      permission: {
        collection: 'lessons',
        action: 'view',
      },
    },
    {
      title: 'Courses',
      module: 'settings_learn',
      component: <Courses />,
      icon: 'summarize',
      tabId: '3',
      permission: {
        collection: 'courses',
        action: 'view',
      },
    },
    {
      title: 'Categories',
      module: 'settings_learn',
      component: <Categories />,
      icon: 'category',
      tabId: 2,
      permission: {
        collection: 'categories',
        action: 'view',
      },
    },
    {
      title: 'Assign',
      component: <Assignments />,
      tabId: 5,
      byModule: 'learns_assignments',
    },
  ];
  const getCurrentUser = async () => {
    try {
      if (profileInfo.role.owner_access) {
        setItemsOptions(tabsData);
      } else {
        const newItems = tabsData.filter(
          (item) => item.title !== 'Customization'
        );

        setItemsOptions(newItems);
      }
      if (
        !isModuleAllowed(tenant.modules, 'learns_lesson_catalog') &&
        !profileInfo.role.admin_access
      ) {
        const filteredData = tabsData.filter(
          (item) =>
            item.title !== 'Lessons' &&
            item.title !== 'Courses' &&
            item.title !== 'Categories'
        );
        setItemsOptions(filteredData);
        setActiveTab(5);
      }
      if (profileInfo?.role?.admin_access) {
        const hideAssigmentTab = tabsData.filter(
          (item) => item.title !== 'Assign'
        );
        setItemsOptions(hideAssigmentTab);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);

      setActivatedTab({
        [location.pathname]: tab.tabId,
      });
    }
  };
  useEffect(() => {
    getCurrentUser();
  }, [profileInfo]);

  const isAdminArea = document.URL.includes('training');
  return (
    <>
      <PageTitle page={itemsOption.find((t) => t.tabId === activeTab)?.title} />
      <Heading title="Resources" useBc showGreeting={false}>
        <AnimatedTabs
          tabsData={itemsOption}
          activeTab={activeTab}
          tabClasses={!isAdminArea ? 'px-4 pt-1' : ''}
          borderClasses={!isAdminArea ? 'border-b' : ''}
          toggle={toggle}
          permissionCheck={true}
        />
      </Heading>
      <TabContent>
        <TabPane className="position-relative">
          {tabsData.find((item) => item.tabId === activeTab)?.component}
        </TabPane>
      </TabContent>
    </>
  );
};

export default Resources;

import { isMatchInCommaSeperated } from './Utils';

// will add more permissions over the time, currently handling Resources view and its import/export actions based on tenant modules list
export const PermissionsConstants = {
  Resources: {
    // a.k.a Prospecting
    collection: 'prospects',
    create: 'create',
    view: 'view',
    import: 'prospecting_peoples_import',
    export: 'prospecting_peoples_export',
    import_: 'resources_import',
    export_: 'resources_export',
    ProspectingPeople: 'prospecting_peoples', // the tabs in Prospecting left menu
    ProspectingCompany: 'prospecting_company', // the tabs in Prospecting left menu
  },
  Reports: {
    Engagement: 'engagement',
    WorkingCapital: 'engagement_working_capital',
    WorkingCapitalLoanOverview: 'engagement_working_capital_loan_overview', // adding as permission so that we can turn it on/off from tenant modal
    Treasury: 'engagement_treasury_management',
    Merchant: 'engagement_merchant_services',
    Fraud: 'engagement_fraud',
    WidgetsLibrary: 'engagement_widgets_library',
    AccountStructure: 'engagement_account_structure',
  },
  ModulesReportNames: {
    WorkingCapital: 'engagement_working_capital',
    Treasury: 'engagement_treasury_management',
    Merchant: 'engagement_merchant_services',
    Fraud: 'engagement_fraud',
    AccountStructure: 'engagement_account_structure',
  },
  Training: {
    MyStats: 'my_training_stats',
  },
  AIAssist: {
    Assist: 'ai_assist', // main side menu module
    Write: 'ai_assist_write', // inner tab
    Summarize: 'ai_assist_summarize', // inner tab
    Ask: 'ai_assist_ask', // inner tab
  },
  Checklist: {
    checklist: 'checklist',
  },
  CAccounts: {
    Accounts: 'accounts',
    Companies: 'accounts_companies',
    Contacts: 'accounts_contacts',
    Timeline: 'accounts_companies_timeline',
    Notes: 'accounts_companies_notes',
    Files: 'accounts_companies_files',
    RelatedCompanies: 'accounts_companies_related_companies',
    Sidebar: 'accounts_companies_sidebar',
    PreCall: 'accounts_companies_sidebar_generate_pre-call_plan',
  },
  Contacts: {
    Timeline: 'accounts_contacts_timeline',
    Notes: 'accounts_contacts_notes',
    Files: 'accounts_contacts_files',
    Sidebar: 'accounts_contacts_sidebar',
    PreCall: 'accounts_contacts_sidebar_generate_pre-call_plan',
  },
  Learn: {
    KnowledgeAssessment: 'learns_knowledge_assessment',
    AIAdvisor: 'learns_ai_advisor',
  },
  ClientPortal: 'client_portal',
};

export const checkPermission = (modules, permissions) => {
  return permissions.filter((key) => {
    return isMatchInCommaSeperated(modules, key);
  });
};

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { capitalize } from '../../utils/Utils';

const TooltipComponent = ({
  title = '',
  placement = 'bottom',
  children,
  tooltipTextFont,
  titleLeftAlign,
  image = '',
  capitalizeText = true,
}) => {
  return (
    <>
      {title ? (
        <OverlayTrigger
          placement={placement}
          overlay={
            <Tooltip
              className={`font-weight-normal ${tooltipTextFont ? '' : ''} ${
                titleLeftAlign ? 'tooltip-left-align' : 'tooltip-left-align'
              }`}
            >
              {image ? <div className="mb-2">{image}</div> : ''}
              {capitalizeText ? capitalize(title) : title}
            </Tooltip>
          }
        >
          {children}
        </OverlayTrigger>
      ) : null}
    </>
  );
};

export default TooltipComponent;

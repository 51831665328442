import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { CardSection } from '../../layouts/CardLayout';
import { DemoTenants } from '../../reports/reports.constants';
import ButtonFilterDropdown from '../../commons/ButtonFilterDropdown';
import useIsTenant from '../../../hooks/useIsTenant';
import useEngagementReportTabs from '../../reports/EngagementReportTabs';

const Report = ({ children, className }) => (
  <Col xs={12} className={`${className || `p-0`}`}>
    {children}
  </Col>
);

const AddDataReport = ({ organizationId, profileInfo, getProfileInfo }) => {
  const [showResults, setShowResults] = useState(false);
  useEffect(() => {
    setShowResults(true);
  }, []);

  const { isExcelBank } = useIsTenant();
  const [demoTenant, setDemoTenant] = useState(
    isExcelBank ? DemoTenants[0] : {}
  );

  const {
    EngagementReportTiles,
    isDefaultView,
    selectedTab,
    EngagementReportDropdown,
  } = useEngagementReportTabs({
    organizationId,
    getProfileInfo,
    profileInfo,
    selectedTenant: demoTenant,
  });
  return (
    <>
      <CardSection className="m-0 p-0">
        {isExcelBank && (
          <div className="py-1 px-3 text-center w-100 justify-content-center">
            <div className="d-flex align-items-center shadow p-1 mb-2 bg-gray-200 card justify-content-center gap-2">
              <ButtonFilterDropdown
                buttonText="Select"
                filterOptionSelected={demoTenant}
                options={DemoTenants}
                withTooltip=""
                handleFilterSelect={(e, item) => {
                  setDemoTenant(item);
                }}
              />
            </div>
          </div>
        )}
        {showResults && (
          <Report className={`p-0`}>
            <Row className={`m-0 pt-0 align-items-center`}>
              <Col xl={9} lg={12} md={12} sm={12} className="px-0">
                {isDefaultView ? null : (
                  <div className="pl-3">
                    <EngagementReportDropdown />
                  </div>
                )}
              </Col>
              <Col
                className={`text-right d-flex align-items-center justify-content-end pr-3`}
              />
            </Row>
            <div className="position-relative">
              <>
                {isDefaultView ? (
                  <EngagementReportTiles />
                ) : (
                  <>{selectedTab.component(profileInfo, demoTenant)}</>
                )}
              </>
            </div>
          </Report>
        )}
      </CardSection>
    </>
  );
};

export default AddDataReport;

import { Card } from 'react-bootstrap';
import Table from '../GenericTable';
import React, { useEffect, useState } from 'react';
import checklistService from '../../services/checklist.service';
import {
  DATE_FORMAT,
  checkDueDate,
  getYesterdayDate,
  isPermissionAllowed,
} from '../../utils/Utils';
import moment from 'moment';
import TableSkeleton from '../commons/TableSkeleton';
import ViewChecklist from '../fields/modals/ViewChecklist';
import { paginationDefault } from '../../utils/constants';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import { ChecklistStatuses } from '../../utils/checklist.constants';
import MaterialIcon from '../commons/MaterialIcon';
import Skeleton from 'react-loading-skeleton';
import OwnerAvatar from '../ActivitiesTable/OwnerAvatar';
import { sortingTable } from '../../utils/sortingTable';
import { useProfileContext } from '../../contexts/profileContext';
import ChecklistStatus from './ChecklistStatus';
import TableRowHover from '../commons/TableRowHover';
import Avatar from '../Avatar';

const ChecklistOwner = ({ checklist }) => {
  const [loader, setLoader] = useState(false);
  const [checklistOwner, setChecklistOwner] = useState([]);
  const getChecklistOwner = async () => {
    try {
      setLoader(true);
      const { data } = await checklistService.getChecklistOwners({
        organizationChecklistId: checklist?.organizationChecklistId,
        limit: 10,
        page: 1,
      });
      setChecklistOwner(data?.data || []);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getChecklistOwner();
  }, []);

  return (
    <>
      {loader ? (
        <Skeleton width={100} height={10} />
      ) : (
        <div>
          {checklistOwner?.map((owner) => (
            <OwnerAvatar key={owner} item={owner?.user} isMultiple={true} />
          ))}
        </div>
      )}
    </>
  );
};

const ActivitiesChecklist = ({
  activeTabId,
  refresh,
  setCheckListCount,
  isChecklistFilterCheck,
  checklistFilter,
  setIsChecklistFilterCheck,
}) => {
  const { profileInfo } = useProfileContext();
  const [openView, setOpenView] = useState(false);
  const [row, setRow] = useState({});
  const [pagination, setPagination] = useState(paginationDefault);
  const [dataInDB, setDataInDB] = useState(false);
  const [checkLists, setCheckLists] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [order, setOrder] = useState([['initialDueDate', 'DESC']]);
  const yesterdayDate = getYesterdayDate();

  const getChecklistData = async () => {
    setShowLoading(true);
    const filters = isChecklistFilterCheck?.filters
      ? isChecklistFilterCheck.filters.reduce(
          (obj, item) => {
            obj[item.key] = item.value;
            return obj;
          },
          { ownerId: profileInfo?.id }
        ) // Initialize with ownerId
      : '';
    try {
      const { data } = await checklistService.getOrgCheckLists({
        ...pagination,
        ...filters,
        ...(activeTabId !== 'all' &&
        activeTabId !== '' &&
        activeTabId !== 'overdue'
          ? {
              'organizationChecklistProgress[status]': activeTabId,
            }
          : activeTabId === 'overdue'
          ? {
              'organizationChecklistProgress[status]': [
                'inProgress',
                'pending',
              ],
              'initialDueDateRange[end]': yesterdayDate,
            }
          : ''),
        ...(checklistFilter?.checklistId
          ? { checklistId: checklistFilter?.checklistId }
          : ''),
        order,
        limit: 25,
      });
      const checklistData = {
        data: data?.data,
        pagination: data?.pagination,
      };
      setPagination(checklistData?.pagination);
      setCheckListCount(checklistData?.pagination);
      setDataInDB(Boolean(data?.pagination?.totalPages));
      setCheckLists(checklistData?.data);
    } catch (error) {
      console.log('Error fetching checklists:', error);
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    if (activeTabId || refresh > 0) {
      getChecklistData();
    }
  }, [
    activeTabId,
    refresh,
    order,
    pagination?.page,
    isChecklistFilterCheck?.filters,
    checklistFilter,
  ]);

  const loader = () => {
    if (showLoading) return <TableSkeleton cols={6} rows={10} />;
  };
  const columns = [
    {
      key: 'company_name',
      orderBy: 'organization.name',
      component: 'Company Name',
      width: '245px',
    },
    {
      key: 'checklist_name',
      orderBy: 'checklist.title',
      component: 'Assigned Checklist',
    },
    {
      key: 'owner',
      orderBy: '',
      component: 'Owner',
    },
    {
      key: 'status',
      component: 'Status',
      orderBy: 'organizationChecklistProgresses.completedAt',
    },
    {
      key: 'completed_at',
      orderBy: 'organizationChecklistProgresses.completedAt',
      component: 'Completed At',
    },
    {
      key: 'due_date',
      orderBy: 'initialDueDate',
      component: 'Due Date',
    },
  ];
  const data = checkLists?.map((item) => ({
    ...item,
    dataRow: [
      {
        key: 'company_name',
        component: (
          <div className="d-flex gap-1 align-items-center">
            <Avatar
              user={item?.organization}
              classModifiers="bg-primary-soft text-primary"
              sizeIcon="fs-6"
              defaultSize="xs"
              type={'organization'}
            />
            {item?.organization?.name}
          </div>
        ),
      },
      {
        key: 'checklist_name',
        component: <span className="text-black">{item?.checklist?.title}</span>,
      },
      {
        key: 'owner',
        component: <ChecklistOwner checklist={item} />,
      },
      {
        key: 'status',
        component: (
          <ChecklistStatus
            item={{
              status: item?.organizationChecklistProgresses?.every(
                (progress) => progress?.progress === 100
              )
                ? ChecklistStatuses.Completed.text
                : item?.organizationChecklistProgresses?.every(
                    (progress) =>
                      progress?.progress > 0 &&
                      item?.organizationChecklistProgresses?.every(
                        (progress) => progress?.progress < 100
                      )
                  )
                ? ChecklistStatuses.InProgress.text
                : item?.organizationChecklistProgresses?.every(
                    (progress) => progress?.progress === 0
                  )
                ? ChecklistStatuses.NotStarted.text
                : '',
            }}
          />
        ),
      },
      {
        key: 'completed_at',
        component: (
          <span className="text-black">
            {item?.organizationChecklistProgresses?.every(
              (progress) => progress?.progress === 100
            )
              ? moment(
                  item?.organizationChecklistProgresses[0]?.completedAt
                ).format(DATE_FORMAT)
              : '--'}
          </span>
        ),
      },
      {
        key: 'due_date',
        component: (
          <>
            <TableRowHover
              bgColor={
                !item?.organizationChecklistProgresses[0]?.completedAt &&
                checkDueDate(item?.initialDueDate)
                  ? 'bg-red-soft'
                  : ''
              }
            />
            <span
              className={`${
                !item?.organizationChecklistProgresses[0]?.completedAt &&
                checkDueDate(item?.initialDueDate) === 'text-red'
                  ? 'text-red'
                  : ''
              }`}
            >
              {!item?.organizationChecklistProgresses[0]?.completedAt &&
              checkDueDate(item?.initialDueDate) ? (
                <span className="position-relative" style={{ top: 5 }}>
                  <MaterialIcon icon="flag" rounded filled clazz="text-red" />
                </span>
              ) : null}{' '}
              {moment(item?.initialDueDate).format(DATE_FORMAT)}
            </span>
          </>
        ),
      },
    ],
  }));

  const handleActivityRowClick = (item) => {
    setRow(item);
    setOpenView(true);
  };

  const changePaginationPage = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const sortTable = ({ name }) => {
    if (name === 'action') return null;
    sortingTable({ name, order, setOrder: (val) => setOrder([val]) }, true);
  };
  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <Card className="mb-5">
        <Card.Body className="p-0">
          <div className="table-responsive-md datatable-custom">
            {showLoading ? (
              loader()
            ) : (
              <Table
                stickyColumn="stickyColumn1"
                columns={columns}
                data={data}
                paginationInfo={pagination}
                emptyDataText="No records in this view."
                title="Checklist"
                onPageChange={changePaginationPage}
                dataInDB={dataInDB}
                onClick={handleActivityRowClick}
                sortingTable={sortTable}
                sortingOrder={order}
                permission={{ collection: 'checklists' }}
                usePagination={true}
              />
            )}
          </div>
        </Card.Body>
      </Card>
      {isPermissionAllowed('checklists', 'view') && (
        <ViewChecklist
          openModal={openView}
          setOpenModal={setOpenView}
          setIsChecklistFilterCheck={setIsChecklistFilterCheck}
          isChecklistFilterCheck={isChecklistFilterCheck}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          checklist={row}
          activityChecklist={true}
          getChecklistData={getChecklistData}
          organization={{ name: row?.name }}
          readonly={true}
        />
      )}
    </>
  );
};
export default ActivitiesChecklist;

import { useState, useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';

import mapService from '../../../services/map.service';
import AutoComplete from '../../AutoComplete';

const IdfSearchDirections = ({
  label,
  value,
  onChange,
  validationConfig,
  fieldState,
  isFieldsObj,
  name,
  setIsFieldsObj,
  clearState,
  addActivity,
  onHandleSelect,
  selected,
  fromNavBar = false,
  ...restProps
}) => {
  const [dataLocations, setDataLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (value?.address_street || value) {
      onDirections(value?.address_street || value);
    }
  }, [value?.address_street || value]);

  const onDirections = async (value) => {
    setLoading(true);
    const data = await mapService
      .getGoogleAddress(value)
      .catch((err) => console.log(err));

    setLoading(false);
    setDataLocations(data);
  };
  return (
    <FormGroup>
      {label && <Label className="mb-0 form-label">{label}</Label>}
      <AutoComplete
        id={`search_directions_${name}`}
        placeholder="Search Address"
        name={name}
        customKey="name"
        loading={loading}
        validationConfig={validationConfig}
        fieldState={fieldState}
        clearState={(e) => clearState(e)}
        showAvatar={false}
        onChange={(e) => onDirections(e.target.value)}
        data={dataLocations}
        onHandleSelect={(item) => {
          onHandleSelect(item);
        }}
        selected={selected}
        {...restProps}
      />
    </FormGroup>
  );
};

export default IdfSearchDirections;

// all the pages titles that we wanna scrap in the account statement pdf
import TreasuryFrame from '../../assets/png/reports/TM-Card.svg';
import TreasuryFrameIcon from '../../assets/png/reports/Treasury_Icon.svg';
import WCFrame from '../../assets/png/reports/WC-Card.svg';
import WCFrameIcon from '../../assets/png/reports/WorkingCapital_Icon.svg';
import MerchantFrame from '../../assets/png/reports/MS-Card.svg';
import MerchantFrameIcon from '../../assets/png/reports/Merchant_Icon.svg';
import FraudFrame from '../../assets/png/reports/FI-Card.svg';
import FraudFrameIcon from '../../assets/png/reports/Fraud_Icon.svg';
import AccountStructureFrame from '../../assets/png/reports/AS-Card.svg';
import AccountStructureIcon from '../../assets/png/reports/AS-Icon.svg';

export const Pages = {
  RelationshipSummary: {
    title: 'Relationship Summary',
    headers: [
      'Account',
      'Name',
      'Investable Balance',
      'Required Balance',
      'Excess / Deficit',
      'Total Activity',
    ],
  },
  BalanceSummary: {
    title: 'Balance Summary',
    headers: ['Title', 'Value'],
  },
  ResultsSummary: { title: 'Results Summary', headers: ['Title', 'Value'] },
  ServiceDetails: {
    title: 'Service Details',
    headers: [
      'Service Description',
      'Volume',
      'Price',
      'Total Fee',
      'Required Balance',
    ],
  },
  // for SVB
  ServiceInformation: {
    title: 'Service Information',
    headers: [
      'Service Information',
      'Volume',
      'Unit Price',
      'Service Charge',
      'Balance Pay Methd',
    ],
  },
  HistoricalSummary: {
    title: 'Historical Summary',
    headers: [
      'Month',
      'Average Ledger Balance',
      'Average Positive Collected Balance',
      'Investable Balance',
      'Excess / Deficit Balance',
      'Earnings Credit Amount',
      'Analysis Based Fees',
      'Fees Based Fees',
      'Service Charges Due',
    ],
  },
  LastTwelveMonth: {
    title: 'Last Twelve Month History',
    headers: [
      'MM/YY',
      'Average Ledger Balance',
      'Balance Supporting Services',
      'Service Balance Required',
      'Excess / Deficit Balance',
      'Earnings Allowance',
      'Analyzed Charges',
      'Ttl Fees Analyzed Stmt',
    ],
  },
};

export const EstimatedTotalPayablesPoints = {
  Payables: [
    'TPA Book Transfer Via Wire',
    'TPA Drawdown Wire',
    'TPA Multibank Wire',
    'TPA Outgoing Domestic Wire',
    'TPA Outgoing Intl Wire-FX-Our',
    'TPA Outgoing Intl Wire-FX-Shared',
    'TPA Outgoing Intl Wire-Out',
    'TPA Outgoing Intl Wire-Shared',
    'Automated Drawdown Wire',
    'Automated Standing Transfer',
    'Book Transfer Via Wire',
    'Domestic Outgoing Wire Non-Repet',
    'Intl Automated Standing Transfer',
    'Intl Outgoing Wire Consumer',
    'Intl Outgoing Wire-FX-Our',
    'Intl Outgoing Wire-FX-Shared',
    'Intl Outgoing Wire-Our',
    'Intl Outgoing Wire-Shared',
    'Outgoing Wire Repetitive',
    'ACH Items Originated',
    'ACH GS Item',
    'TPA ACH Item',
    'TPA ACH GS Item',
    'Debit',
    'ACH Items Originated Same Day',
    'Electronic Debit',
    'Controlled Disbursement Debits',
    'IP / IP Web Check Issue Printing',
    'IP / IP Web ACH Item Charge',
    'IP / IP Web ACH GS Item',
    'TPA RTP Outgoing Payment',
    'IP / IP Web Auto Domestic Wire',
    'IP / IP Web Book Transfer Via Wire',
    'Domestic Outgoing Wire Non-Repet',
    'Positive Pay Checks',
  ],
};
// covers B,
export const PayablesDataPointsV2 = {
  // B
  'TPA Wire': [
    'TPA Book Transfer Via Wire',
    'TPA Drawdown Wire',
    'TPA Multibank Wire',
    'TPA Outgoing Domestic Wire',
    'TPA Outgoing Intl Wire-FX-Our',
    'TPA Outgoing Intl Wire-FX-Shared',
    'TPA Outgoing Intl Wire-Out',
    'TPA Outgoing Intl Wire-Shared',
    'Automated Drawdown Wire',
    'Automated Standing Transfer',
    'Book Transfer Via Wire',
    'Domestic Outgoing Wire Non-Repet',
    'Intl Automated Standing Transfer',
    'Intl Outgoing Wire Consumer',
    'Intl Outgoing Wire-FX-Our',
    'Intl Outgoing Wire-FX-Shared',
    'Intl Outgoing Wire-Our',
    'Intl Outgoing Wire-Shared',
    'Outgoing Wire Repetitive',
  ],
  // C
  'ACH Origination': [
    'ACH Items Originated',
    'ACH GS Item',
    'TPA ACH Item',
    'TPA ACH GS Item',
    'ACH Items Originated Same Day',
  ],
  // D
  'Checks Paid': [
    'Electronic Debit',
    'Controlled Disbursement Debits',
    'Positive Pay Checks',
  ],
  // E
  'Integrated Payables ACH': [
    'IP Service Maintenance',
    'IP Web Service Maintenance',
    'IP Web Account Maintenance',
    'IP Web Submitted Files',
    'SWIFT FileAct Reporting (4)',
    'IP / IP Web Check Issue Printing',
    'IP / IP Web ACH Item Charge',
    'IP / IP Web ACH GS Item',
  ],
};

// umhmm for comerica
export const PayablesDataPointsV2Comerica = {
  // B
  'TPA Wire': [
    'TPA Book Transfer Via Wire',
    'TPA Drawdown Wire',
    'TPA Multibank Wire',
    'TPA Outgoing Domestic Wire',
    'TPA Outgoing Intl Wire-FX-Our',
    'TPA Outgoing Intl Wire-FX-Shared',
    'TPA Outgoing Intl Wire-Out',
    'TPA Outgoing Intl Wire-Shared',
    'Automated Drawdown Wire',
    'Automated Standing Transfer',
    'Book Transfer Via Wire',
    'Domestic Outgoing Wire Non-Repet',
    'Intl Automated Standing Transfer',
    'Intl Outgoing Wire Consumer',
    'Intl Outgoing Wire-FX-Our',
    'Intl Outgoing Wire-FX-Shared',
    'Intl Outgoing Wire-Our',
    'Intl Outgoing Wire-Shared',
    'Outgoing Wire Repetitive',
    'IP / IP Web Auto Domestic Wire',
    'IP / IP Web Book Transfer Via Wire',
    'IP / IP Web Out Intl Wire-Shared',
    'IP / IP Web Auto Multibank Wire',
    'IP / IP Web Outgoing Intl Wire-Our',
    'IP / IP Web Out Intl Wire-FX-Shared',
    'IP / IP Web Out Intl Wire-FX-Our',
  ],
  // C
  'ACH Origination': [
    'Electronic Debit',
    'ACH Items Originated',
    'ACH GS Item',
    'TPA ACH Item',
    'TPA ACH GS Item',
    'ACH Items Originated Same Day',
    'IP / IP Web ACH GS Item',
    'IP / IP Web ACH Item Charge',
  ],
  // D
  'Checks Paid': [
    'Debit',
    'Controlled Disbursement Debits',
    'Positive Pay Checks',
    'IP / IP Web Check Issue Printing',
  ],
  // E
  'Integrated Payables ACH': [
    'IP Service Maintenance',
    'IP Web Service Maintenance',
    'IP Web Account Maintenance',
    'IP Web Submitted Files',
    'SWIFT FileAct Reporting (4)',
    'IP / IP Web Check Issue Printing',
    'IP / IP Web ACH Item Charge',
    'IP / IP Web ACH GS Item',
  ],
};

export const PayablesDataPoints = {
  Checks: [
    'Electronic Debit',
    'Controlled Disbursement Debits',
    'IP / IP Web Check Issue Printing',
  ],
  ACH: [
    'ACH Items Originated Same Day',
    'ACH Items Originated',
    'ACH GS Item',
    'IP / IP Web ACH Item Charge',
    'IP / IP Web ACH GS Item',
  ],
  Wires: [
    'TPA Wire/Account Transfer Service',
    'TPA Book Transfer Via Wire',
    'TPA Drawdown Wire',
    'TPA Multibank Wire',
    'TPA Outgoing Domestic Wire',
    'TPA Outgoing Intl Wire-FX-Our',
    'TPA Outgoing Intl Wire-FX-Shared',
    'TPA Outgoing Intl Wire-Out',
    'TPA Outgoing Intl Wire-Shared',
    'Automated Drawdown Wire',
    'Automated Standing Transfer',
    'Book Transfer Via Wire',
    'Domestic Outgoing Wire Non-Repet',
    'Intl Automated Standing Transfer',
    'Intl Outgoing Wire Consumer',
    'Intl Outgoing Wire-FX-Our',
    'Intl Outgoing Wire-FX-Shared',
    'Intl Outgoing Wire-Our',
    'Intl Outgoing Wire-Shared',
    'Outgoing Wire Repetitive Automated Drawdown Wire',
    'IP / IP Web Auto Domestic Wire',
    'IP / IP Web Auto Multibank Wire',
    'IP / IP Web Book Transfer Via Wire',
    'IP / IP Web Out Intl Wire-FX-Our',
    'IP / IP Web Out Intl Wire-FX-Shared',
    'IP / IP Web Out Intl Wire-Shared',
    'IP / IP Web Outgoing Intl Wire-Our',
  ],
};

export const EstimatedTotalReceivablesPoints = {
  Receivables: [
    'Domestic Incoming Wire',
    'International Incoming Wire',
    'Electronic Credit',
    'ACH Intl Transaction Received',
    'Items Deposited',
  ],
};

export const ReceivablesDataPointsV2 = {
  // G
  'Checks Paid': ['Domestic Incoming Wire', 'International Incoming Wire'],
  // H
  'Integrated Payables ACH': [
    'Electronic Credit',
    'ACH Intl Transaction Received',
    'Credit',
    'Items Deposited',
  ],
  // I
  'Positive Pay Usage': [
    'ARP Maintenance-Full Img Pos Pay',
    'Image Positive Pay Maint Wo Recon',
    'Positive Pay Maintenance',
    'Web Positive Pay Maintenance',
  ],
  // J
  'ACH Positive Pay Usage': ['ACH Positive Pay Monthly Maint'],
};

export const PaymentRisksDataPoints = {
  // G
  'Incoming Wires': ['Domestic Incoming Wire', 'International Incoming Wire'],
  // H
  'Incoming ACH/Check': [
    'Electronic Credit',
    'ACH Intl Transaction Received',
    'Items Deposited',
  ],
  // I
  'Cash Vault': ['Cash Vault Currency Deposited'],
  // J
  'Integrated Receivables': [
    'INR Monthly Maintenance',
    'INR Items',
    'INR Data Transmission',
    'INR Data Transmission With Images',
    'INR File Download',
    'INR File Download Items',
  ],
};

export const ReceivablesDataPoints = {
  Checks: [
    'Remote Deposit Item',
    'BDC Mobile Per Item Fee',
    'Credit',
    'Electronic Credit',
  ],
  ACH: ['Electronic Credit', 'ACH Intl Transaction Received'],
  Wires: ['Domestic Incoming Wire', 'International Incoming Wire'],
};

export const InputType = {
  Currency: 1,
  Comma: 2,
};

export const ReportTypes = {
  Treasury: 'Treasury',
  WorkingCapital: 'WorkingCapital',
  Merchant: 'Merchant',
  AccountStructure: 'AccountStructure',
  Fraud: 'Fraud',
  KnowledgeAssessment: 'KnowledgeAssessment',
};

export const PayableBlockTypes = {
  DSO: {
    key: 'days_sales_out',
    rmaKey: 'arAvgDays',
    rmaKeyBestInClass: 'arBestInClassDays',
    short: 'DSO',
    title: 'Days Sales Outstanding',
  },
  DPO: {
    key: 'days_payable_out',
    rmaKey: 'apAvgDays',
    rmaKeyBestInClass: 'apBestInClassDays',
    short: 'DPO',
    title: 'Days Payable Outstanding',
  },
};

export const ActionTypes = {
  ADD: 1,
  UPDATE: 2,
  REMOVE: 3,
  CLEAR: 4,
};

export const CenturyBankMapping = {
  PayablesDataPoints: {
    'TPA Wire': [
      // B
      'Outgoing Wire Domestic - online',
      'Outgoing International Wire - online',
      'Outgoing International Wire transfer-transfermate',
      'Outgoing FX Wire Transfer-Transfermate',
      'Outgoing wire Domestic - Agent Assisted',
      'Outgoing International Wire transfer - Branch',
      'Drawdown Request',
    ],
    // C
    'ACH Origination': [
      'ACH Originated Item',
      'Payables Hub ACH Transaction Fee',
      'ACH Originated Item-Same Day',
      'Payables Hub Same Day ACH Fee',
    ],
    // D
    'Checks Paid': ['Paid Checks', 'Payables Hub Check Outsourcing Fee'],
    // E
    'Integrated Payables ACH': [
      'Payables Hub Monthly Base',
      'Payables Hub Same Day File Fee',
      'Payables Hub-Late ACH File Fee',
    ],
  },
  EstimatedTotalPayablesPoints: {
    Payables: [
      'Outgoing Wire Domestic - online',
      'Outgoing International Wire - online',
      'Outgoing International Wire transfer-transfermate',
      'Outoing FX Wire Transfer-Transfermate',
      'Outgoing wire Domestic - Agent Assisted',
      'Outgoing International Wire transfer - Branch',
      'Drawdown Request',
      'ACH Originated Item',
      'Payables Hub ACH Transaction Fee',
      'ACH Originated Item-Same Day',
      'Payables Hub Same Day ACH Fee',
      'Paid Checks',
      'Payables Hub Check Outsourcing Fee',
    ],
  },
  ReceivablesDataPoints: {
    // G
    'Checks Paid': ['Incoming wire transfer (Domestic/International)'],
    // H
    'Integrated Payables ACH': [
      'Checks Deposited On-Us',
      'Checks deposited Not On-Us',
      'Xpress Deposit Item',
      'ACH debit received fee',
      'ACH credit received fee',
    ],
    // I
    'Positive Pay Usage': [
      'Cash Vault Monthly Base Fee',
      'Currency/coin deposit(per$100)',
      'Cash Vault Deposit',
    ],
    // J
    'ACH Positive Pay Usage': [
      'AR File Transmission',
      'R-hub Transmission Fee',
      'R-Hub Monthly Maintenance Fee',
      'R-hub Transaction Fee',
    ],
  },
  EstimatedTotalReceivablesPoints: {
    Receivables: [
      'Checks Deposited On-Us',
      'Checks deposited not On-Us',
      'Xpress Deposit Item',
      'Incoming wire transfer (Domestic/International)',
      'ACH debit received fee',
      'ACH credit received fee',
    ],
  },
  PaymentRisksDataPoints: {
    // G
    'Incoming Wires': [
      'Fraud Control Item Reviewed',
      'Fraud Control Monthly Base',
    ],
    // H
    'Incoming ACH/Check': [
      'Fraud Control Item Reviewed',
      'Fraud Control Monthly Base',
    ],
  },
};

export const SVBMapping = {
  PayablesDataPoints: {
    // B
    'TPA Wire': [
      'Out WT Dom',
      'Out WT Dom Rep',
      'Out WT Dom Online',
      'Out WT Dom Online w/Email',
      'Out WT Dom w/Email',
      'Out WT Dom Rep w/Email',
    ],
    // C
    'ACH Origination': [
      'ACH Credit Originated',
      'ACH Debit Received',
      'Bill Pay Excess Transaction',
      'BPP Trans 100 Incl',
      'BPP Trans 25 Incl',
    ],
    // D
    'Checks Paid': ['Checks Paid', 'CDA Per Item'],
    // E
    'Integrated Payables ACH': [
      'Out WT Intl FX Online',
      'Out WT Intl USD',
      'Out WT Intl FX',
      'Out WT Intl USD Online',
      'Out WT Intl USD Online Email',
      'Out WT Intl USD w/Email',
    ],
  },
  EstimatedTotalPayablesPoints: {
    Payables: [
      'Out WT Dom',
      'Out WT Dom Rep',
      'Out WT Dom Online',
      'Out WT Dom Online w/Email',
      'Out WT Dom w/Email',
      'Out WT Dom Rep w/Email',
      'ACH Credit Originated',
      'ACH Debit Received',
      'Bill Pay Excess Transaction',
      'BPP Trans 100 Incl',
      'BPP Trans 25 Incl',
      'Checks Paid',
      'CDA Per Item',
      'Out WT Intl FX Online',
      'Out WT Intl USD',
      'Out WT Intl FX',
      'Out WT Intl USD Online',
      'Out WT Intl USD Online Email',
      'Out WT Intl USD w/Email',
    ],
  },
  ReceivablesDataPoints: {
    // G
    'Checks Paid': ['In Wire Transfer', 'In Wire w/Email'],
    // H
    'Integrated Payables ACH': ['ACH Credit Received', 'ACH Debit Originated'],
    // I
    'Positive Pay Usage': [
      'Checks Deposited',
      'Lockbox Automated Per Item',
      'Lockbox Non-automated per Item',
      'Mobile Deposit Item',
      'RDC Checks Deposited',
      'RLB Checks Deposited',
    ],
    // J
    'ACH Positive Pay Usage': ['Tag IR Monthly Service'],
  },
  EstimatedTotalReceivablesPoints: {
    // K
    Receivables: [
      'In Wire Transfer',
      'In Wire w/Email',
      'ACH Credit Received',
      'ACH Debit Originated',
      'Checks Deposited',
      'Lockbox Automated Per Item',
      'Lockbox Non-automated per Item',
      'Mobile Deposit Item',
      'RDC Checks Deposited',
      'RLB Checks Deposited',
    ],
  },
  PaymentRisksDataPoints: {
    'Incoming Wires': [
      'Check PosPay w/o Payee Monthly',
      'Check PosPay w/Payee Monthly',
      'Check Reverse PosPay Monthly',
      'CDA Positive Pay Account Maint',
      'CDA Positive Payee Monthly Fixed',
      'Check Block All Monthly',
      'FCS Check Block All Monthly',
    ],
    'Incoming ACH/Check': [
      'ACH PosPay Cr Monthly',
      'ACH PosPay Dr Monthly',
      'ACH Block Account Maint',
      'FCS ACH Cr Block All Monthly',
      'FCS ACH Dr Block All Monthly',
      'ACH Filter Monthly Maintenance',
    ],
  },
};

export const DemoTenantsKeys = {
  eb: 'eb',
  cb: 'cb',
  sb: 'sb',
  svb: 'svb',
};

export const DemoTenants = [
  { key: DemoTenantsKeys.eb, name: 'EB', tip: 'Excel Bank' }, // uses comerica mapping in mozilla
  { key: DemoTenantsKeys.cb, name: 'CB', tip: 'Comerica Bank' }, // uses comerica mapping in mozilla
  { key: DemoTenantsKeys.sb, name: 'SB', tip: 'Synovus Bank' }, // uses comerica mapping in mozilla
  { key: DemoTenantsKeys.svb, name: 'SVB', tip: 'Silicon Valley Bank' }, // uses svb mapping in mozilla
];

export const getDemoTenantByKey = (key) =>
  DemoTenants.find((dm) => dm.key === key);

export const PDF_PAGE_HEIGHT = 892; // 8.5 / 11; // Letter paper size aspect ratio

export const PayableKeys = [
  ['Checks', 1, false], // false/true means allow editing
  ['ACH', 2, false],
  ['Wires', 3, false],
];
export const ReceivableKeys = [
  ['ACH/Checks', 1, false],
  ['Wires', 2, false],
  ['Cash Vault', 3, true],
];

export const TreasuryReportSections = {
  Overview: 'Overview',
  Payables: 'Payables',
  Receivables: 'Receivables',
  Fraud: 'Fraud',
  FeeSummary: 'FeeSummary',
  Glossary: 'Glossary',
};

export const WorkingCapitalReportSections = {
  Overview: 'Opportunity',
  Payables: 'Account Payable',
  Receivables: 'Account Receivables',
  LoanOverview: 'Loan Overview',
  Glossary: 'Glossary',
};

export const FraudReportSections = {
  FraudWhyItMatters: 'Fraud - Why It Matters',
  WhatCanYouDo: 'What Can You Do',
  PrioritizeElectronicPayments: 'Prioritize Electronic Payments',
};

export const getVideoUrlWithTenant = (tenant, videoId) => {
  const domain = tenant.domainUrl || tenant.domain;
  if (!domain) {
    // fallback to browser url and get from it
    return `${location.protocol}//${location.host}/video/${videoId}?tenant_id=${tenant.id}`;
  }
  return `${domain}/video/${videoId}?tenant_id=${tenant.id}`;
};

export const EngagementReportConfig = {
  [ReportTypes.Treasury]: {
    PageConfig: {
      [TreasuryReportSections.Overview]: {
        page: 2,
        icon: 'account_balance',
        enabled: true,
      },
      [TreasuryReportSections.Payables]: {
        page: 3,
        icon: 'price_check',
        enabled: true,
      },
      [TreasuryReportSections.Receivables]: {
        page: 4,
        icon: 'price_check',
        enabled: true,
      },
      [TreasuryReportSections.Fraud]: {
        page: 5,
        icon: 'price_check',
        enabled: true,
      },
      [TreasuryReportSections.FeeSummary]: {
        page: 6,
        icon: 'price_check',
        enabled: true,
      },
      [TreasuryReportSections.Glossary]: {
        page: 7,
        icon: 'menu_book',
        enabled: true,
      },
    },
    SectionKeys: [
      TreasuryReportSections.Overview,
      TreasuryReportSections.Payables,
      TreasuryReportSections.Receivables,
      TreasuryReportSections.Fraud,
      TreasuryReportSections.FeeSummary,
      TreasuryReportSections.Glossary,
    ],
  },
  [ReportTypes.WorkingCapital]: {
    PageConfig: {
      [WorkingCapitalReportSections.LoanOverview]: {
        page: 1,
        icon: 'menu_book',
        enabled: false,
      },
      [WorkingCapitalReportSections.Overview]: {
        page: 2,
        icon: 'price_check',
        enabled: true,
      },
      [WorkingCapitalReportSections.Payables]: {
        page: 3,
        icon: 'arrow_upward',
        enabled: true,
      },
      [WorkingCapitalReportSections.Receivables]: {
        page: 4,
        icon: 'arrow_downward',
        enabled: true,
      },
      [WorkingCapitalReportSections.Glossary]: {
        page: 5,
        icon: 'menu_book',
        enabled: true,
      },
    },
    SectionKeys: [
      WorkingCapitalReportSections.LoanOverview,
      WorkingCapitalReportSections.Overview,
      WorkingCapitalReportSections.Payables,
      WorkingCapitalReportSections.Receivables,
      WorkingCapitalReportSections.Glossary,
    ],
  },
  [ReportTypes.Merchant]: {},
  [ReportTypes.Fraud]: {
    PageConfig: {
      [FraudReportSections.FraudWhyItMatters]: {
        page: 1,
        icon: 'menu_book',
        enabled: true,
      },
      [FraudReportSections.WhatCanYouDo]: {
        page: 2,
        icon: 'price_check',
        enabled: true,
      },
      [FraudReportSections.PrioritizeElectronicPayments]: {
        page: 3,
        icon: 'arrow_upward',
        enabled: true,
      },
    },
    SectionKeys: [
      FraudReportSections.FraudWhyItMatters,
      FraudReportSections.WhatCanYouDo,
      FraudReportSections.PrioritizeElectronicPayments,
    ],
  },
  [ReportTypes.AccountStructure]: {},
};

export const ReportTileStyles = {
  [ReportTypes.Treasury]: {
    frame: TreasuryFrame,
    icon: TreasuryFrameIcon,
    styleClass: 'treasury',
  },
  [ReportTypes.WorkingCapital]: {
    frame: WCFrame,
    icon: WCFrameIcon,
    styleClass: 'working-capital',
  },
  [ReportTypes.Merchant]: {
    frame: MerchantFrame,
    icon: MerchantFrameIcon,
    styleClass: 'merchant',
  },
  [ReportTypes.Fraud]: {
    frame: FraudFrame,
    icon: FraudFrameIcon,
    styleClass: 'fraud',
  },
  [ReportTypes.AccountStructure]: {
    frame: AccountStructureFrame,
    icon: AccountStructureIcon,
    styleClass: 'fraud',
  },
};

export const ReportFormErrorFields = {
  name: {
    isShow: false,
    message: 'Company name is required.',
  },
  naics: {
    isShow: false,
    message: 'NAICS is required.',
  },
  reportDate: {
    isShow: false,
    message: 'Report date is required.',
  },
};

export const LEARN_OVERVIEW_CONSTANT_KEY = 'Learn Overview';

import React, { useEffect, useState } from 'react';
import { Card, FormCheck } from 'react-bootstrap';
import MaterialIcon from '../commons/MaterialIcon';
import TeamService from '../../services/teams.service';
import CreateTeamsModal from './CreateTeamsModal';
import stringConstants from '../../utils/stringConstants.json';
import LayoutHead from '../commons/LayoutHead';
import userService from '../../services/user.service';
import { DeactivateTeamModal } from './DeactivateTeamModal';
import NoDataFound from '../commons/NoDataFound';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils/Utils';
import { TeamsColumns } from './ManageTeams.constants';
import Table from '../GenericTable';
import TooltipComponent from '../lesson/Tooltip';
import Skeleton from 'react-loading-skeleton';
import { paginationDefault } from '../../utils/constants';
import { DataFilters } from '../DataFilters';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import useViewTeamMembersModal from './useViewTeamMembersModal';
import { usePagesContext } from '../../contexts/pagesContext';
import TableSkeleton from '../commons/TableSkeleton';
import PillFilters from '../commons/PillFilters';

const constants = stringConstants.settings.teams;
const limit = 10;
const FilterStatuses = [
  {
    id: 'active',
    label: 'Active',
    status: true,
  },
  {
    id: 'inactive',
    label: 'Inactive',
    status: false,
  },
];
const TeamManager = ({ team }) => {
  const [loader, setLoader] = useState(false);
  const [manager, setManager] = useState({});
  const { pageContext, setPageContext } = usePagesContext();
  const getTeamManager = async () => {
    try {
      setLoader(true);
      const { data: managers } = await TeamService.getTeamMembersById(team.id, {
        page: 1,
        limit: 1,
        isManager: true,
      });
      setManager(managers?.length ? managers[0] : {});
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTeamManager();
  }, []);

  useEffect(() => {
    if (pageContext.UpdatedTeamMember) {
      if (pageContext.UpdatedTeamMember.team.id === team.id) {
        setManager(pageContext.UpdatedTeamMember.manager);
        setPageContext({ ...pageContext, UpdatedTeamMember: null });
      }
    }
  }, [pageContext.UpdatedTeamMember]);
  return (
    <>
      {loader ? (
        <Skeleton width={100} height={10} />
      ) : (
        <div>{manager?.user?.name || '--'}</div>
      )}
    </>
  );
};
export const TeamPage = ({ paginationPage, setPaginationPage }) => {
  const teamObj = {
    name: '',
    members: [],
    description: '',
  };
  const defaultTeamPagination = { page: 1, limit: 5 };
  const [pagination, setPagination] = useState(paginationDefault);
  const [teamPagination, setTeamPagination] = useState(defaultTeamPagination);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [teamUsers, setTeamUsers] = useState([]);
  const [, setActiveTap] = useState('');
  const [showCreateGroupModal, setShowCreateTeamModal] = useState(false);
  const [selectedEditData, setSelectedEditData] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [, setDeleteRoleData] = useState('');
  const [selectedData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [, setOpenModal] = useState(false);
  const [isUserId, setIsUserId] = useState('');
  const [isDeactivate, setIsDeactivate] = useState('');
  const [isActivate, setIsActivate] = useState(false);
  const [isDeactivateTeamName, setIsDeactivateTeamName] = useState('');
  const [isUserDataShow, setIsUserDataShow] = useState([]);
  const [teamLoading, setTeamLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getTeamMember, setIsGetTeamMember] = useState([]);
  const [members, setMembers] = useState([]);
  const [teamForm, setTeamForm] = useState(teamObj);
  const [filter, setFilter] = useState({ isActive: true });
  const [teamFilter, setTeamFilter] = useState(FilterStatuses[0]);
  const getTeams = async () => {
    setShowLoading(true);
    try {
      const result = await TeamService.getTeams({
        ...pagination,
        filter,
      });
      setTeams(result.data);
      setShowLoading(false);
      setPagination(result.pagination);
      setActiveTap('');
    } catch (error) {
      setErrorMessage('Unable to fetch teams.');
    }
  };
  const {
    ViewTeamMembersModal,
    setShowModal: setShowViewMembersModal,
    setTeam,
  } = useViewTeamMembersModal({ refreshTeams: getTeams });
  const changePaginationPage = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };
  const getUsers = async () => {
    const result = await userService.getUsers(
      { status: 'active' },
      { ...pagination },
      limit
    );
    setUsers(result.data);
  };

  const onTeamLoadMore = () => {
    setTeamLoading(true);
    setTeamPagination((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }));
  };

  useEffect(() => {
    getTeams();
  }, [filter, pagination?.page]);

  const closeModal = async () => {
    setShowCreateTeamModal(false);
    setIsDeactivate(false);
    setIsActivate(false);
    setActiveTap('');
  };

  const handleModelShow = async () => {
    setShowCreateTeamModal(true);
    getUsers();
  };

  const handleDeleteModelShow = async (item) => {
    setDeleteRoleData({ ...item, title: item.name });
    setOpenModal(true);
  };

  const handleShowDeavtivateModal = (item) => {
    setIsDeactivateTeamName(item);
    setIsActivate(false);
    setIsDeactivate(true);
  };
  const handleShowActivateModal = (item) => {
    setIsDeactivateTeamName(item);
    setIsActivate(true);
  };

  const handleEditModelShow = async (item) => {
    // calling get users
    const usersResponse = await userService.getUsers(
      { status: 'active' },
      { ...pagination },
      limit
    );
    const teamById = await TeamService.getTeamById(item.id);
    const userData = await TeamService.getTeamMembersById(item.id, {});
    // making user id array
    const usersToSelectList = userData?.data?.map((u) => u.userId);
    const isManagers = userData?.data
      ?.filter((f) => f.isManager === false)
      .map((u) => u.userId);

    // comparing above user id array with actual list that is shown with checkboxes
    // if found then only select that object property to true to show checked in UI
    const checkedUsers = [...usersResponse?.data?.users].map((user) => ({
      ...user,
      isChecked: usersToSelectList.includes(user.id),
    }));
    const checkedMembers = [...usersResponse?.data?.users].map((user) => ({
      ...user,
      isChecked: isManagers.includes(user.id),
    }));
    // updating users so that state and UI get updated too
    setMembers(checkedMembers);
    setUsers(checkedUsers);

    const permissionArray = userData?.data
      .map((group) => {
        return {
          userId: group.userId,
          isManager: group.isManager,
        };
      })
      .filter((f) => f.isManager === false);
    setIsGetTeamMember(permissionArray);
    setIsUserDataShow(userData?.data);
    setShowCreateTeamModal(true);
    setTeamForm(item);
    teamById &&
      setSelectedEditData({
        name: teamById?.name || '',
        description: teamById?.description || '',
        id: teamById?.id || '',
        isActive: isActivate,
      });
  };

  const handleActiveDeactiveTeam = async () => {
    setIsLoading(true);
    try {
      await TeamService.updateTeam(
        isDeactivateTeamName.id || selectedEditData.id,
        {
          isActive: isActivate,
        }
      );
      setIsLoading(false);
      setShowCreateTeamModal(false);
      closeModal();
      if (isActivate) {
        setSuccessMessage(constants.edit.activated);
      } else {
        setSuccessMessage(constants.edit.TeamUpdateSuccess);
      }
      getTeams();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  const handleUpdateTeam = async () => {
    setIsLoading(true);
    try {
      await TeamService.updateTeam(
        isDeactivateTeamName.id || selectedEditData.id,
        {
          name: isDeactivateTeamName.name || teamForm?.name,
          description:
            isDeactivateTeamName.description || teamForm?.description,
          isActive: isActivate,
          members: teamForm?.members,
        }
      );
      await TeamService.updateTeamMemberById(
        teamForm?.members,
        selectedEditData.id
      );
      setIsLoading(false);
      setIsDeactivate('');
      setIsActivate(false);
      setShowCreateTeamModal(false);
      setSelectedEditData('');
      setTeamForm({
        name: '',
        description: '',
        members: [],
      });
      setMembers([]);
      setIsGetTeamMember([]);
      if (isActivate) {
        setSuccessMessage(constants.edit.activated);
      } else {
        setSuccessMessage(constants.edit.TeamUpdateSuccess);
      }
      getTeams();
    } catch (error) {
      setErrorMessage(constants.edit.TeamUpdateFailed);
    }
  };

  const createTeam = async (data) => {
    setIsLoading(true);
    try {
      await TeamService.CreateTeam(data);
      getTeams();
      setSuccessMessage(constants.create.TeamCreatedSuccess);
      setIsLoading(false);
      setShowCreateTeamModal(false);
    } catch (error) {
      if (error.response.status === 409) {
        setErrorMessage(constants.create?.TeamCreatedAleready);
      } else {
        setErrorMessage(constants.create?.TeamCreatedFailed);
      }
    } finally {
      setIsLoading(false);
      setShowCreateTeamModal(false);
    }
  };

  const tableActions = (item) => {
    return [
      {
        id: 1,
        title: 'Edit',
        icon: 'edit',
        onClick: () => handleEditModelShow(item),
      },
      {
        id: 3,
        title: item.isActive ? 'Deactivate' : 'Activate',
        icon: item.isActive ? 'block' : 'refresh',
        onClick: () =>
          item.isActive
            ? handleShowDeavtivateModal(item)
            : handleShowActivateModal(item),
        style: 'ml-3 text-danger',
      },
      {
        id: 4,
        title: 'Delete',
        icon: 'delete',
        onClick: () => handleDeleteModelShow(item),
        style: 'ml-3 text-danger',
      },
    ];
  };

  const loader = () => {
    if (showLoading) return <TableSkeleton cols={5} rows={10} />;
  };
  const Title = () => {
    return <div className="text-gray-search font-size-md">No teams exist</div>;
  };

  const teamsData = teams?.map((team, i) => {
    return {
      ...team,
      dataRow: [
        {
          key: 'team',
          component: (
            <div className="d-flex align-items-center gap-2 pl-0">
              <div className="fw-bold">{team.name}</div>
              <TooltipComponent title={team.description}>
                <MaterialIcon icon="info" />
              </TooltipComponent>
            </div>
          ),
        },
        {
          key: 'team_manager',
          component: <TeamManager team={team} />,
        },
        {
          key: '',
          component: <span>{team.totalUsers || '--'}</span>,
        },
        {
          key: 'last_updated',
          component: <span>{moment(team.updatedAt).format(DATE_FORMAT)}</span>,
        },
        {
          key: '',
          component: (
            <div
              className="d-flex align-items-center"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <FormCheck
                type="switch"
                id={`toggle-team-status-${i}`}
                label=""
                checked={team.isActive}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  if (team.isActive) {
                    handleShowDeavtivateModal(team);
                  } else {
                    handleShowActivateModal(team);
                  }
                }}
              />
              <span>{team.isActive ? 'Active' : 'Inactive'}</span>
            </div>
          ),
        },
      ],
    };
  });

  return (
    <>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <ViewTeamMembersModal />
      <Card className="shadow-none border-0 rounded-0">
        <Card.Header className="border-0 w-100 justify-content-between px-4 py-2">
          <PillFilters
            items={FilterStatuses}
            currentFilter={teamFilter}
            onFilterChange={(newFilter) => {
              setFilter({ ...filter, isActive: newFilter.status });
              setPagination(paginationDefault);
              setTeamFilter(newFilter);
            }}
          />
          <LayoutHead
            onHandleCreate={handleModelShow}
            buttonLabel={constants.edit.add}
            selectedData={selectedData}
            headingTitle=""
            dataInDB={teams}
            headingText=""
            onDelete={setOpenModal.bind(true)}
          >
            <div className="flex-fill">
              <DataFilters
                filterSelected={filter}
                searchClasses="col-md-12"
                setFilterSelected={setFilter}
                searchPlaceholder="Search Team"
                paginationPage={pagination}
                setPaginationPage={setPagination}
              />
            </div>
          </LayoutHead>
        </Card.Header>
        <Card.Body className="p-0">
          {showLoading ? (
            loader()
          ) : (
            <>
              {teams.length === 0 ? (
                <NoDataFound
                  icon="groups"
                  containerStyle="text-gray-search my-6 py-6"
                  title={<Title />}
                />
              ) : (
                <Table
                  columns={TeamsColumns}
                  data={teamsData}
                  paginationInfo={pagination}
                  headClass="bg-gray-table-head border-top"
                  onClick={(row) => {
                    const team = { ...row };
                    delete team.dataRow;
                    setTeam(team);
                    setShowViewMembersModal(true);
                  }}
                  usePagination
                  emptyDataText="No teams available yet."
                  onPageChange={(newPage) =>
                    changePaginationPage(newPage, setPaginationPage)
                  }
                  title="Teams"
                  dataInDB={teams}
                  actions={({ item }) => tableActions(item)}
                />
              )}
            </>
          )}
          <DeactivateTeamModal
            handleShowModal={isDeactivate}
            handleHideModal={closeModal}
            handleUpdateTeam={handleActiveDeactiveTeam}
            isTeamName={isDeactivateTeamName}
            isActivate={isActivate}
            isLoading={isLoading}
          />
          <CreateTeamsModal
            showModal={showCreateGroupModal}
            setShowModal={closeModal}
            data={teamUsers?.users}
            createGroup={createTeam}
            setErrorMessage={setErrorMessage}
            isUserId={isUserId}
            getTeams={getTeams}
            setIsUserId={setIsUserId}
            onChangeDrop={(e) =>
              e?.target ? setTeamUsers(e.target.value) : null
            }
            isUserDataShow={isUserDataShow}
            userData={users?.users}
            setSelectedEditData={setSelectedEditData}
            updateTeam={handleUpdateTeam}
            selectData={selectedEditData}
            teamPagination={teamPagination}
            onTeamLoadMore={onTeamLoadMore}
            teamLoading={teamLoading}
            isLoading={isLoading}
            members={members?.users}
            setTeamForm={setTeamForm}
            teamForm={teamForm}
            getTeamMember={getTeamMember}
            setIsGetTeamMember={setIsGetTeamMember}
          />
        </Card.Body>
      </Card>
    </>
  );
};

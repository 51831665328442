import React, { useContext } from 'react';
import {
  Accordion,
  AccordionContext,
  useAccordionToggle,
} from 'react-bootstrap';
import { CardHeader } from 'reactstrap';
import MaterialIcon from '../commons/MaterialIcon';

const ReportAccordionToggle = ({
  children,
  eventKey,
  callback,
  dontExpand,
  setCurrentAccordionKey,
}) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    if (eventKey === currentEventKey) {
      setCurrentAccordionKey(null);
    } else {
      callback && callback(eventKey);
    }
  });
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Accordion.Toggle
      eventKey={eventKey}
      as={CardHeader}
      onClick={decoratedOnClick}
      className={`p-3 cursor-pointer ${
        isCurrentEventKey ? 'bg-gray-5' : 'bg-white'
      }`}
    >
      <h6
        className={`mb-0 d-flex align-items-center gap-1 w-100 font-size-sm ${
          isCurrentEventKey ? 'font-weight-bold' : 'font-weight-semi-bold'
        }`}
      >
        {children}
        {!dontExpand && (
          <MaterialIcon
            icon={isCurrentEventKey ? 'expand_less' : 'expand_more'}
            clazz="font-size-2xl ml-auto"
          />
        )}
      </h6>
    </Accordion.Toggle>
  );
};

export default ReportAccordionToggle;

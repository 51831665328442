import { Skeleton } from '@mui/material';
import React from 'react';

export const LoadingDetailSkeleton = () => {
  return (
    <div className="w-100">
      <div>
        <div className="d-flex align-items-center gap-2 mb-3">
          <Skeleton variant="circular" width={60} height={60} />
          <div>
            <Skeleton variant="text" width={200} height={20} />
            <Skeleton variant="text" width={170} height={15} />
          </div>
        </div>
        <Skeleton variant="rectangular" width={'100%'} height={118} />
      </div>
      <div className="row mt-3" style={{ height: '60vh' }}>
        <div className="col-md-4 d-flex flex-wrap gap-3 pr-1">
          <Skeleton variant="rectangular" width={'100%'} height={'57.5%'} />
          <Skeleton variant="rectangular" width={'100%'} height={'40%'} />
        </div>
        <div className="col-md-8">
          <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
        </div>
      </div>
    </div>
  );
};

import { Card, CardBody } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import {
  formatNumberV2,
  isToFixedNoRound,
  parseCurrency,
} from '../../../../utils/Utils';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment/moment';
const regex = /^(\d+\.\d{0,10})%/;

const BalancesAndEarningsCreditWidget = ({ widgetConfig, whenPrinting }) => {
  const report = widgetConfig?.data;
  const [feesMode, setFeesMode] = useState({
    key: 1,
    label: 'Total Fees Paid',
    isSelected: true,
  });
  const value9 = parseCurrency(report?.value9 || '$0');
  const value5 = parseCurrency(report?.value5 || '$0');
  const [, setPaidAndOffset] = useState({
    paid: value9 > value5 ? '$0' : value5 - value9,
    offset: value9 > value5 ? '$0' : value5 - value9,
  });
  const getFormattedPercentage = (value, ignoreMatching) => {
    let result = '0%';
    if (value) {
      if (ignoreMatching) {
        const percentage = parseFloat(value);
        const formattedPercentage = percentage.toFixed(2);
        result = `${formattedPercentage}%`;
      } else {
        const roundPercentage = value.match(regex);
        if (roundPercentage) {
          const roundedPercentage = parseFloat(roundPercentage[1]);
          result = `${roundedPercentage.toFixed(2)}%`;
        }
      }
    }
    return result;
  };

  const [formattedPercentage, setFormattedPercentage] = useState(
    getFormattedPercentage(report?.value6)
  );

  useEffect(() => {
    setFormattedPercentage(getFormattedPercentage(`${report?.value6}`, true));
  }, [report?.value6]);

  useEffect(() => {
    if (report?.feesMode) {
      if (report.feesMode.key === 1) {
        // fees paid
        setPaidAndOffset({
          paid: value9 > value5 ? '$0' : value5 - value9,
          offset: value9 > value5 ? '$0' : value5 - value9,
        });
      } else {
        // fees offset
        setPaidAndOffset({
          paid: value9 > value5 ? '$0' : value5 - value9,
          offset: value9 > value5 ? value5 : value5 - value9,
        });
      }
      setFeesMode(report.feesMode);
    }
  }, [report?.feesMode]);
  return (
    <Card className="report-widget">
      <CardBody className="justify-content-center align-items-center d-flex">
        <div className="flex-fill">
          <h5 className="text-left mb-1 d-flex align-items-center gap-1">
            Balances and Earnings Credit
          </h5>
          <Row className={`align-items-center position-relative px-2`}>
            <Col md={5} className="position-relative">
              <div
                className="border-2 report-widget-gray-section"
                style={{
                  height: 180,
                  width: '100%',
                }}
              >
                <div className="position-absolute abs-center-xy">
                  <div className={`mb-1 fw-bolder text-black`}>
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                      <h1 className="mb-0 widget-heading-color font-weight-bold">
                        {report?.totalFees === '$0'
                          ? '$0'
                          : parseCurrency(report?.totalFees || '$0') < 99999
                          ? isToFixedNoRound(parseCurrency(report?.totalFees))
                          : formatNumberV2(parseCurrency(report?.totalFees))}
                      </h1>
                    </div>
                  </div>
                  <p className="fs-9 sub-text text-center mb-0">
                    {feesMode?.label}
                  </p>
                </div>
              </div>
            </Col>
            <Col className="text-left text-rpt-heading font-size-sm2 ml-0">
              <p className="mb-2 font-size-sm2">
                You paid
                <span className="rounded px-1">
                  <b>
                    {report?.totalFees === '$0' ? (
                      '$0 '
                    ) : (
                      <>{isToFixedNoRound(parseCurrency(report.totalFees))} </>
                    )}
                    in fees
                  </b>
                </span>
                in {moment(report.value2).format('MMMM YYYY')}.
              </p>
              <p className="mb-2 rounded font-size-sm2 py-2 px-0">
                Your total bank fees were{' '}
                <b>{isToFixedNoRound(parseCurrency(report.value5))}</b>. Your
                balances earn {formattedPercentage} towards these fees. This
                month, your earnings allowance was{' '}
                <b>{isToFixedNoRound(parseCurrency(report.value9))}</b> based on
                your balances of{' '}
                <b>{isToFixedNoRound(parseCurrency(report.value7))}</b>.
              </p>
              <p className="mb-0 font-size-sm2 rounded py-2 px-0">
                To cover all your bank fees, you would need to have had{' '}
                <b>{isToFixedNoRound(parseCurrency(report.value8))}</b> in
                collected balances.
              </p>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default BalancesAndEarningsCreditWidget;

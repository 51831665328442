import React, { useEffect, useState } from 'react';
import ButtonIcon from './commons/ButtonIcon';
import { CardBody } from 'reactstrap';
import { Skeleton } from '@mui/material';
import ActivityChecklist from './checklist/ActivityChecklist';
import {
  RIGHT_PANEL_WIDTH,
  getYesterdayDate,
  isModuleAllowed,
  isPermissionAllowed,
} from '../utils/Utils';
import { useTenantContext } from '../contexts/TenantContext';
import checklistService from '../services/checklist.service';
import RightPanelModal from './modal/RightPanelModal';
import AlertWrapper from './Alert/AlertWrapper';
import Alert from './Alert/Alert';
import { AddChecklistOrg } from './checklist/AddChecklistOrg';
import NoDataFound from './commons/NoDataFound';
import { Card } from 'react-bootstrap';
import ButtonFilterDropdown from './commons/ButtonFilterDropdown';
import InfiniteScroll from 'react-infinite-scroll-component';
const checklistFiltersList = [
  { key: 'all', name: 'All Checklists' },
  { key: 'pending', name: 'Not Started Checklists' },
  { key: 'inProgress', name: 'In Progress Checklists' },
  { key: 'overdue', name: 'Overdue Checklists' },
  { key: 'completed', name: 'Completed Checklists' },
];
export const ChecklistsOrganization = ({
  organization,
  dataType,
  step,
  organizationId,
  activeTab,
}) => {
  const { tenant } = useTenantContext();
  const [checklists, setCheckLists] = useState([]);
  const [checklistLoading, setChecklistLoading] = useState(false);
  const [orgChecklists, setOrgCheckLists] = useState([]);
  const [isChecklistShow, setChecklistShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [activityFilter, setActivityFilter] = useState(checklistFiltersList[0]);
  const yesterdayDate = getYesterdayDate();
  const [hasMore, setHasMore] = useState(true);
  const [limit, setLimit] = useState(10);
  const getChecklistData = async () => {
    setChecklistLoading(true);
    try {
      const organizationChecklists = await checklistService.getOrgCheckList(
        organization?.id,
        {
          limit,
          page: 1,
          ...(activityFilter?.key !== 'all' &&
          activityFilter?.key !== '' &&
          activityFilter?.key !== 'overdue'
            ? {
                'organizationChecklistProgress[status]': activityFilter?.key,
              }
            : activityFilter?.key === 'overdue'
            ? {
                'organizationChecklistProgress[status]': [
                  'inProgress',
                  'pending',
                ],
                'initialDueDateRange[end]': yesterdayDate,
              }
            : ''),
        }
      );
      const { data } = await checklistService.getCheckLists({
        limit,
        page: 1,
      });
      setCheckLists(data?.data);
      let getChecklistByOwners = [];
      getChecklistByOwners = organizationChecklists?.data?.data?.map(
        async (item) => {
          const getOwners = await checklistService.getChecklistOwners({
            organizationChecklistId: item?.organizationChecklistId,
            limit,
            page: 1,
          });
          return getOwners?.data?.data;
        }
      );
      getChecklistByOwners = await Promise.all(getChecklistByOwners);
      getChecklistByOwners = organizationChecklists?.data?.data?.map(
        (item, index) => {
          return {
            ...item,
            user: getChecklistByOwners?.flat()?.[index]?.user,
          };
        }
      );
      setOrgCheckLists([...getChecklistByOwners]);
      if (limit <= organizationChecklists?.data?.pagination?.count) {
        setHasMore(getChecklistByOwners.length > 0);
        setLimit((prevLimit) => prevLimit + 6);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.errors[0]?.message);
    } finally {
      setChecklistLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === 4) {
      getChecklistData();
    }
  }, [activeTab, activityFilter]);
  return (
    <Card className="border-0">
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <Card.Header className="border-top-0 pt-0 px-3">
        <div className="d-flex align-items-center justify-content-between text-right w-100">
          <ButtonFilterDropdown
            buttonText="Checklist"
            options={checklistFiltersList}
            filterOptionSelected={activityFilter}
            handleFilterSelect={(e, item) => {
              setActivityFilter(item);
            }}
            menuClass="drop-menu-card"
          />
          {checklists?.length > 0 &&
          isModuleAllowed(tenant?.modules, 'checklist') &&
          isPermissionAllowed('checklists', 'create') ? (
            <ButtonIcon
              color="outline-primary"
              classnames="btn-sm rounded-pill pr-3 font-size-sm font-weight-medium"
              icon="add"
              onclick={() => setChecklistShow(true)}
              label={'Checklist'}
            />
          ) : (
            ''
          )}
        </div>
      </Card.Header>
      {!step && (
        <CardBody className={`p-0 ${step ? 'shadow-none' : ''}`}>
          {isModuleAllowed(tenant?.modules, 'checklist') && organization?.id ? (
            <>
              {checklistLoading ? (
                <div className="p-3">
                  <div className="d-flex align-items-center gap-2">
                    <Skeleton variant="circular" width={45} height={45} />
                    <div className="w-100 mt-3">
                      <Skeleton variant="rounded" width={'100%'} height={15} />
                      <div className="mt-2">
                        <Skeleton variant="rounded" width={'75%'} height={15} />
                      </div>
                      <div className="mt-2">
                        <Skeleton variant="rounded" width={'50%'} height={15} />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <Skeleton variant="circular" width={45} height={45} />
                    <div className="w-100 mt-3">
                      <Skeleton variant="rounded" width={'100%'} height={15} />
                      <div className="mt-2">
                        <Skeleton variant="rounded" width={'75%'} height={15} />
                      </div>
                      <div className="mt-2">
                        <Skeleton variant="rounded" width={'50%'} height={15} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : orgChecklists?.length > 0 ? (
                <InfiniteScroll
                  dataLength={orgChecklists.length}
                  next={getChecklistData}
                  hasMore={hasMore}
                >
                  <ActivityChecklist
                    getChecklistData={getChecklistData}
                    organization={organization}
                    checklists={orgChecklists}
                    setErrorMessage={setErrorMessage}
                    setSuccessMessage={setSuccessMessage}
                  />
                </InfiniteScroll>
              ) : (
                <NoDataFound
                  icon={'checklist'}
                  title={
                    <div className="font-normal font-size-sm2 text-gray-search">
                      This record doesn&apos;t have any checklist
                    </div>
                  }
                  containerStyle="text-gray-search my-6 py-6"
                />
              )}
            </>
          ) : (
            ''
          )}
        </CardBody>
      )}

      {isChecklistShow && (
        <RightPanelModal
          showModal={isChecklistShow}
          setShowModal={() => setChecklistShow()}
          showOverlay={true}
          containerBgColor={'pb-0'}
          containerWidth={RIGHT_PANEL_WIDTH}
          containerPosition={'position-fixed'}
          headerBgColor="bg-gray-5"
          Title={
            <div className="d-flex py-2 align-items-center text-capitalize">
              <h3 className="mb-0">Add Checklist</h3>
            </div>
          }
        >
          <AddChecklistOrg
            organizationId={organizationId}
            isChecklistShow={isChecklistShow}
            getChecklistsData={getChecklistData}
            checklistData={orgChecklists}
            organization={organization}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            setChecklistShow={setChecklistShow}
          />
        </RightPanelModal>
      )}
    </Card>
  );
};

import React, { useEffect, useState } from 'react';
import { overflowing } from '../../../utils/Utils';
import SimpleModalCreation from '../../../components/modal/SimpleModalCreation';
import InputValidation from '../../../components/commons/InputValidation';
import { Col, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';

const InviteFormGroup = ({
  label,
  component,
  alignLabel = 'align-items-center',
}) => {
  return (
    <FormGroup className={`mb-0 ${alignLabel} mt-0`} row>
      <Label md={3} className="text-right py-0 font-size-sm">
        {label}
      </Label>
      <Col md={9} className="pl-0 py-0">
        {component}
      </Col>
    </FormGroup>
  );
};

const PipelineNamePriceEditModal = ({
  show,
  setShow,
  deal,
  onHandleSubmit,
}) => {
  //   const [saving, setSaving] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    isLoading,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const [dealData, setDealData] = useState({ ...deal });

  useEffect(() => {
    if (show) {
      setValue('name', deal.name);
      setValue('amount', deal.amount);
    }
  }, [show]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setDealData({
      ...dealData,
      [name]: value,
    });
    setValue(name, value);
  };

  const onSubmit = async (dealFormData) => {
    onHandleSubmit(dealFormData);
  };

  return (
    <>
      <SimpleModalCreation
        modalHeaderClasses="flex-fill"
        modalTitle="Edit Opportunity Information"
        saveButtonStyle="btn btn-primary btn-sm d-flex align-items-center"
        open={show}
        editFields={true}
        isLoading={isLoading}
        handleSubmit={handleSubmit(onSubmit)}
        onHandleCloseModal={() => {
          overflowing();
          setShow(!show);
        }}
      >
        <div className="d-flex flex-column gap-3">
          <InviteFormGroup
            label="Deal Name"
            component={
              <InputValidation
                name="name"
                type="input"
                placeholder="Deal Name"
                value={dealData.name || ''}
                errorDisplay="mb-0"
                classNames="mr-2"
                validationConfig={{
                  required: 'Deal Name is required.',
                  inline: false,
                  borderLeft: true,
                  onChange: handleOnChange,
                }}
                errors={errors}
                register={register}
              />
            }
          />

          <InviteFormGroup
            label="Amount"
            component={
              <InputValidation
                name="amount"
                type="input"
                placeholder="Amount"
                value={dealData.amount || ''}
                errorDisplay="mb-0"
                classNames="mr-2"
                validationConfig={{
                  onChange: handleOnChange,
                }}
                errors={errors}
                register={register}
              />
            }
          />
        </div>
      </SimpleModalCreation>
    </>
  );
};

export default PipelineNamePriceEditModal;

import GenericTable from '../../../components/GenericTable';
import { NO_DATA_AVAILABLE } from '../../../utils/constants';

const LearnDashboardActivityDetail = ({ data, title, width = 'w-33' }) => {
  const rows = data?.map((result, index) => {
    const name = `${result?.name !== null ? result?.name : '--'}`;
    return {
      id: index,
      dataRow: [
        {
          key: 'name',
          component: (
            <span
              className="fw-bold pl-0 text-capitalize d-inline-block"
              style={{ whiteSpace: 'break-spaces' }}
            >
              {name}
            </span>
          ),
        },
        {
          key: 'completed',
          component:
            `${
              result?.AssignmentCompleted !== null
                ? `${Math.round(
                    (result?.AssignmentCompleted * 100) /
                      result?.totalAssignments
                  )}%`
                : '--'
            }` || '--',
        },
        {
          key: 'overdue',
          component:
            `${
              result?.AssignmentOverDue !== null
                ? `${Math.round(
                    (result?.AssignmentOverDue * 100) / result?.totalAssignments
                  )}%`
                : '--'
            }` || '--',
        },
      ],
    };
  });

  const columns = [
    {
      key: 'name',
      component: 'Team',
    },
    {
      key: 'completed',
      component: 'Completed',
      tooltipTitle: '% of all assignments complete',
    },
    {
      key: 'overdue',
      component: 'Overdue',
      tooltipTitle: '% of all assignments overdue',
    },
  ];

  return (
    <div className={width}>
      <div className={`card learn-dashboard-activity-table`}>
        <div className="card-body p-0">
          <h4
            className={`card-title p-3 text-hover-primary font-weight-medium font-size-md`}
          >
            {title}
          </h4>
          <div>
            <GenericTable
              headClass=""
              tableSize="table-xs"
              checkbox={false}
              data={rows}
              emptyDataText={NO_DATA_AVAILABLE}
              columns={columns}
              usePagination={false}
              noDataInDbValidation={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnDashboardActivityDetail;

import { Col, FormControl } from 'react-bootstrap';
import React from 'react';
import ValidationErrorText from './ValidationErrorText';
import { FormGroup, Label } from 'reactstrap';
import { formatPhone } from '../../utils/Utils';
const isNotEmptyOrSpaces = (value) => value?.trim() !== '';

// generic component with validation logic with react-hook-form, will be using this or customize it more as we move forward
// right now, using it in create category modal
const InputValidationAdvance = ({
  name,
  type,
  placeholder,
  value,
  register,
  validationConfig,
  errors,
  classNames,
  inputClass,
  fieldType,
  label,
  errorDisplay,
  errorMessage = '',
  labelAlign = '',
  ...rest
}) => {
  return (
    <div className="position-relative w-100">
      <FormGroup row className="py-1">
        {label && (
          <Label
            md={3}
            className={`text-right mb-0 font-size-sm ${labelAlign}`}
          >
            {label}
          </Label>
        )}
        <Col md={9} className="pl-0">
          <FormControl
            as={type}
            type={fieldType}
            placeholder={`${placeholder} ${
              validationConfig?.inline ? '*' : ''
            }`}
            value={
              fieldType === 'PHONE'
                ? formatPhone(value[name])
                : value[name] || ''
            }
            name={name}
            {...rest}
            maxLength={fieldType === 'PHONE' ? 14 : ''}
            className={`${classNames} ${inputClass} ${
              errors[name] ? 'border-danger' : ''
            }`}
            {...register(name, {
              ...validationConfig,
              validate: validationConfig?.validateSpaces
                ? {
                    notEmptyOrSpaces: isNotEmptyOrSpaces,
                  }
                : null,
            })}
          />
          {errors[name]?.type === 'required' && (
            <ValidationErrorText
              text={errors[name]?.message || `${placeholder} is required.`}
              extraClass={errorDisplay}
            />
          )}
          {errors[name]?.type === 'maxLength' && (
            <ValidationErrorText
              text={errors[name]?.message}
              extraClass={errorDisplay}
            />
          )}
          {errors[name]?.type === 'pattern' && (
            <ValidationErrorText
              text={errors[name]?.message || errorMessage}
              extraClass={errorDisplay}
            />
          )}
          {errors[name]?.type === 'notEmptyOrSpaces' && (
            <ValidationErrorText
              text="Field cannot be empty or contain only spaces."
              extraClass={errorDisplay}
            />
          )}
        </Col>
      </FormGroup>
    </div>
  );
};

export default InputValidationAdvance;

import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Badge,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap';

import Avatar from '../Avatar';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import Heading from '../heading';
import userService from '../../services/user.service';
import organizationService from '../../services/organization.service';
import stringConstants from '../../utils/stringConstants.json';
import routes from '../../utils/routes.json';
import IdfOwnersHeader from '../idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import filesService from '../../services/files.service';
import ModalAvatar from '../modal/ModalAvatar';
import {
  base64ToBlob,
  isPermissionAllowed,
  isValidUrl,
} from '../../utils/Utils';
import SendOrDownloadModal from './SendOrDownloadModal';
import DeleteModal from '../modal/DeleteModal';
import { useProfileContext } from '../../contexts/profileContext';
import PageTitle from '../commons/PageTitle';
import ContactCompanyDeleteMenu from '../commons/ContactCompanyDeleteMenu';
import MaterialIcon from '../commons/MaterialIcon';
import useClientSharePopup from '../ClientShare/useClientSharePopup';
import checklistService from '../../services/checklist.service';
const globalStrings = stringConstants.global.avatar;
const organizationConstants = stringConstants.deals.organizations;
const Header = ({
  contact,
  withFollowers,
  moduleMap,
  mainOwner,
  service,
  data,
  showContactsModal,
  setShowContactsModal,
  refreshOwners,
  setRefresOwners,
  isPrincipalOwner,
  getProfileInfo,
  organization,
  setOpenCloneModal,
  me,
  ...props
}) => {
  const { organizationId, contactId, id: dealId } = useParams();
  const serviceId = organizationId || contactId || dealId;
  const [owners, setOwners] = useState([]);
  const [, setCount] = useState(0);
  const [followersInfo, setFollowersInfo] = useState([]);
  const [userId, setUserId] = useState('');
  const { profileInfo } = useProfileContext();
  const [isFollowing, setIsFollowing] = useState(false);
  const [isOwner, setIsOwner] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openOwnerModal, setOpenOwnerModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [organizationInfo, setOrganizationInfo] = useState({});
  const [ownerInfo, setOwnerInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [openModalAvatar, setOpenModalAvatar] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [toast, setToast] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [allOwners, setAllOwners] = useState([]);
  const [deleteResults, setDeleteResults] = useState([]);
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [modified, setModified] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [colorToast, setColorToast] = useState('success');
  const [spinner, setSpinner] = useState(false);
  const [, setCheckLists] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setOrganizationInfo(data);
    setAllOrganizations([data]);
    setSelectedData([data.id]);
  }, [data]);

  useEffect(() => {
    if (!openModal && isDeleted) {
      setTimeout(() => {
        history.push(`${routes.caccounts}#contacts`);
      }, 1000);
    }
  }, [openModal]);

  const handleDelete = async () => {
    try {
      const response = await organizationService.deleteOrganizations([
        organizationInfo.id,
      ]);
      setDeleteResults(response);
      setIsDeleted(response[0].result === 'success');
      setShowDeleteReport(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const deleteOwner = async () => {
    try {
      const response = await organizationService.removeOwner(
        props.organizationId,
        ownerInfo.id
      );
      setDeleteResults([{ id: ownerInfo.id, msg: '', result: 'success' }]);
      setIsDeleted(response.status === 200);
      setShowDeleteReport(true);
      setRefresOwners(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const setDeleteOwner = (profile) => {
    setOwnerInfo(profile.user);
    setAllOwners([profile.user]);
    setSelectedOwner([profile.user.id]);
    setOpenOwnerModal(true);
  };

  const updateOrganizationAvatar = async (avatarId, src) => {
    try {
      await organizationService.updateOrganization(organizationInfo.id, {
        avatar: avatarId,
      });
      setOrganizationInfo((prev) => ({
        ...prev,
        avatar: avatarId,
        avatarSrc: src,
      }));
      setOpenModalAvatar(false);
      setSuccessMessage(
        avatarId
          ? globalStrings.uploadSuccessOrg.replace(/Company/g, moduleMap)
          : globalStrings.removedSuccessOrg.replace(/Company/g, moduleMap)
      );
    } catch (e) {
      setOrganizationInfo((prev) => ({
        ...prev,
        avatar: null,
        avatarSrc: null,
      }));
      setOpenModalAvatar(false);
      if (e.response.status !== 403) {
        setErrorMessage(globalStrings.uploadError);
      }
    } finally {
      setLoading(false);
    }
  };

  const onHandleSaveAvatar = async ({ src, name }) => {
    setLoading(true);
    // onUploadAvatar
    const form = new FormData();
    form.append('file', await base64ToBlob(src), name);
    const avatarResult = await userService.uploadAvatar(form).catch((_) => {
      setErrorMessage(globalStrings.uploadError);
    });
    const result = avatarResult?.data;
    if (result?.data?.id) {
      await updateOrganizationAvatar(result.data.id, src);
    }
  };

  const removeFile = async () => {
    if (organizationInfo?.avatar && isValidUrl(organizationInfo?.avatar)) {
      updateOrganizationAvatar(null);
      setOrganizationInfo((prev) => ({
        ...prev,
        avatar: null,
        avatarSrc: null,
      }));
    } else {
      filesService
        .removeFileById(organizationInfo.avatar)
        .then(() => {
          updateOrganizationAvatar(null);
          setOrganizationInfo((prev) => ({
            ...prev,
            avatar: null,
            avatarSrc: null,
          }));
        })
        .catch(() => {
          setErrorMessage(globalStrings.uploadError);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getUserId = () => {
    const id = profileInfo?.id;
    if (id) {
      setUserId(id);
    }
  };

  useEffect(() => {
    if (serviceId) onGetOwners();
  }, [serviceId, refreshOwners, isFollowing]);

  const ownersService = async (pagination) => {
    return await service
      .getOwners(serviceId, pagination)
      .catch((err) => console.log(err));
  };

  const putItemsFirst = ({ findFunction, array }) => [
    ...array.filter(findFunction),
    ...array.filter((item) => !findFunction(item)),
  ];
  useEffect(() => {
    setOwners([
      ...putItemsFirst({
        array: [...owners],
        findFunction: (rc) => {
          return rc.user?.id === mainOwner?.id;
        },
      }),
    ]);
  }, [mainOwner]);

  useEffect(() => {
    if (
      mainOwner &&
      (owners.length === 0 || owners[0].user_id !== mainOwner?.id)
    ) {
      setOwners([
        {
          organization_id: withFollowers,
          user: { ...mainOwner, type: 'primaryOwner' },
          user_id: mainOwner?.id,
        },
        ...owners,
      ]);
    }
  }, [owners]);

  const checkFollower = async () => {
    const result = await organizationService.checkFollowing(serviceId, userId);
    const IsOwner =
      owners.filter((owner) => {
        return owner.user_id === userId && owner.user.type !== 'follower';
      }).length > 0;
    setIsOwner(IsOwner);
    setIsFollowing(result);
  };

  useEffect(() => {
    getFollowers();
  }, []);

  useEffect(() => {
    if (userId) {
      checkFollower();
    }
  }, [userId, followersInfo]);

  useEffect(() => {
    getUserId();
  }, [profileInfo]);

  const onGetOwners = async (pagination = { page: 1, limit: 10 }) => {
    const requests = [];
    requests.push(ownersService(pagination));
    if (withFollowers) {
      requests.push(organizationService.getFollowers(withFollowers, {}));
    }
    const resp = await Promise.all(requests);
    const owners = resp[0];

    const {
      data,
      pagination: { count },
    } = owners || {};

    const contacts = withFollowers && (resp[1].data || []);
    const ownersList = data.map((ow) => ({
      ...ow,
      user: { ...ow.user, type: 'owner' },
    }));
    const contactsList =
      (contacts &&
        contacts.map((fo) => ({
          ...fo,
          user: { ...fo.user, type: 'follower' },
        }))) ||
      [];
    setOwners([...ownersList, ...contactsList]);
    setCount(count);
  };

  const getFollowers = async () => {
    const result = await organizationService.getFollowers(serviceId, {});
    setFollowersInfo(result?.data);
  };

  const stopFollowing = async () => {
    setSpinner(true);
    await organizationService
      .stopFollowing(serviceId, userId)
      .catch(() =>
        setErrorMessage('There is something wrong in Stop Following!')
      );
    await getFollowers();
    setSpinner(false);
  };

  const startFollowing = async () => {
    setSpinner(true);
    await organizationService
      .startFollowing(serviceId, userId)
      .catch(() =>
        setErrorMessage('There is something wrong in Start Following!')
      );
    await getFollowers();
    setSpinner(false);
  };

  const { ClientSharePopup, show, setShow } = useClientSharePopup(
    organizationId,
    organizationInfo,
    setShowContactsModal
  );
  const getChecklistData = async () => {
    try {
      const organizationChecklists = await checklistService.getOrgCheckList(
        organization?.id,
        { limit: 10, page: 1 }
      );
      let organizationChecklistData = [];

      if (organizationChecklists?.data?.data?.length > 0) {
        const checklistDataPromises = organizationChecklists?.data?.data?.map(
          async (item) => {
            const checklistData = await checklistService.getCheckListsById(
              item?.checklistId
            );
            return checklistData?.data;
          }
        );

        organizationChecklistData = await Promise.all(checklistDataPromises);
      }

      setCheckLists([...organizationChecklistData]);
    } catch (error) {
      console.error('Error fetching checklist data:', error);
    }
  };
  useLayoutEffect(() => {
    if (organization?.id) {
      getChecklistData();
    }
  }, [organization?.id]);
  return (
    <>
      <PageTitle
        page={organizationInfo.name || `${moduleMap} Profile`}
        pageModule="Contacts"
      />
      <div className="page-header organization-header mb-0 pb-0 ">
        {openModal && (
          <DeleteModal
            type="organizations"
            showModal={openModal}
            setShowModal={setOpenModal}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            event={handleDelete}
            data={allOrganizations}
            results={deleteResults}
            setResults={setDeleteResults}
            showReport={showDeleteReport}
            setShowReport={setShowDeleteReport}
            modified={modified}
            setModified={setModified}
            constants={organizationConstants.delete}
            resetSeletedData={false}
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
          />
        )}

        {openOwnerModal && (
          <DeleteModal
            type="owners"
            showModal={openOwnerModal}
            setShowModal={setOpenOwnerModal}
            selectedData={selectedOwner}
            setSelectedData={setSelectedOwner}
            event={deleteOwner}
            data={allOwners}
            results={deleteResults}
            setResults={setDeleteResults}
            showReport={showDeleteReport}
            setShowReport={setShowDeleteReport}
            modified={modified}
            setModified={setModified}
            constants={organizationConstants.ownerDelete}
            resetSeletedData={false}
          />
        )}

        <ModalAvatar
          open={openModalAvatar}
          onHandleClose={() => setOpenModalAvatar(false)}
          userInfo={organizationInfo}
          onRemove={removeFile}
          loading={loading}
          onSaveAvatar={onHandleSaveAvatar}
          type="organization"
        />

        <div className="row align-items-end">
          <div className="col-sm mb-sm-0">
            <Heading
              useBc
              title={organizationInfo.name || `${moduleMap} Profile`}
              showGreeting={false}
            />
            <div className="media mb-3">
              {isPermissionAllowed('organizations', 'edit') && (
                <label
                  className="avatar avatar-xl avatar-circle border-solid border-width-2 border-primary-soft avatar-uploader mr-3"
                  htmlFor="avatarUploader"
                  onClick={() => setOpenModalAvatar(true)}
                >
                  <Avatar
                    classModifiers="max-wh bg-primary-soft text-primary"
                    sizeIcon="font-size-2xl"
                    user={organizationInfo}
                    type="organization"
                  />
                  <span className="avatar-uploader-trigger">
                    <i className="material-symbols-rounded avatar-uploader-icon shadow-soft">
                      photo_camera
                    </i>
                  </span>
                </label>
              )}
              <div className="media-body">
                <div className="row align-items-center no-gutters">
                  <div className="header-profile col-lg mb-3 mb-lg-0">
                    <h1 className="page-header-title">
                      {organizationInfo.name || `${moduleMap} Profile`}
                    </h1>
                    <div className="row align-items-center no-gutters mt-2">
                      <div className="col-auto d-flex align-items-center w-100 profile-options">
                        {organizationInfo.is_customer ? (
                          <Badge
                            color={`success`}
                            style={{ fontSize: '12px' }}
                            className="text-uppercase mr-3"
                          >
                            {
                              stringConstants.deals.organizations.profile
                                .customerTitle
                            }
                          </Badge>
                        ) : (
                          <Badge
                            id={organizationInfo?.label?.id}
                            style={{
                              fontSize: '12px',
                              backgroundColor: `${organizationInfo?.label?.color}`,
                            }}
                            className="text-uppercase mr-3"
                          >
                            {organizationInfo?.label?.name}
                          </Badge>
                        )}
                        {isPermissionAllowed('organizations', 'create') ? (
                          <IdfOwnersHeader
                            className="mx-0"
                            mainOwner={mainOwner}
                            service={organizationService}
                            serviceId={data?.id}
                            refreshOwners={refreshOwners}
                            setRefresOwners={setRefresOwners}
                            isprincipalowner={false}
                            onClick={setDeleteOwner}
                            headerType="organization"
                            defaultSize="sm"
                          />
                        ) : (
                          <IdfOwnersHeader
                            className="mx-0"
                            mainOwner={mainOwner}
                            service={organizationService}
                            serviceId={data?.id}
                            refreshOwners={refreshOwners}
                            setRefresOwners={setRefresOwners}
                            isprincipalowner={false}
                            headerType="organization"
                            defaultSize="sm"
                          />
                        )}

                        {isOwner || isFollowing ? (
                          <>
                            <div
                              className={`d-flex ml-2 gap-1 px-2 rounded border btn-white align-items-center ${
                                isOwner ? 'disabled' : ''
                              }`}
                            >
                              <span className="material-symbols-rounded fs-5">
                                visibility
                              </span>
                              <button
                                className={`btn border-0 btn-sm ${
                                  isOwner ? 'disabled' : ''
                                }`}
                                onClick={!isOwner ? stopFollowing : () => {}}
                              >
                                Stop following
                              </button>
                              <span
                                style={{ color: 'green', fontSize: '15px' }}
                                className="material-symbols-rounded fs-5 px-2 p-1 bg-green rounded text-white ml-1"
                              >
                                check
                              </span>
                            </div>
                            {spinner === true && (
                              <Spinner
                                color="black"
                                size="sm"
                                className="m-1"
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {isPermissionAllowed('organizations', 'create') && (
                              <>
                                <button
                                  className="btn d-flex align-items-center btn-white btn-sm ml-2"
                                  onClick={startFollowing}
                                >
                                  <span className="material-symbols-rounded fs-5 mr-1">
                                    add
                                  </span>
                                  Start following
                                </button>
                                {spinner === true && (
                                  <Spinner
                                    color="black"
                                    size="sm"
                                    className="m-1"
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <Dropdown
                    isOpen={show}
                    toggle={() => setShow(!show)}
                    direction={'down'}
                  >
                    <DropdownToggle
                      className="font-weight-500 d-flex align-items-center mr-2 font-size-sm btn-sm pl-3 pr-4 ps-2 section-owners-header"
                      color="success"
                    >
                      <MaterialIcon
                        icon={'upload'}
                        clazz="pr-1 font-size-em"
                        rounded
                      />
                      Share
                    </DropdownToggle>

                    <DropdownMenu
                      right
                      className="py-0"
                      style={{ minWidth: '25.4375rem', zIndex: 100 }}
                    >
                      <ClientSharePopup />
                    </DropdownMenu>
                  </Dropdown>
                  <ContactCompanyDeleteMenu
                    isPrincipalOwner={mainOwner?.id === profileInfo?.id}
                    setOpenModal={setOpenModal}
                    permission={{
                      collection: 'organizations',
                      action: 'delete',
                    }}
                    setOpenCloneModal={setOpenCloneModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showShareModal && (
        <SendOrDownloadModal
          contact={contact}
          showModal={showShareModal}
          setShowModal={setShowShareModal}
          getProfileInfo={getProfileInfo}
          setToast={setToast}
          setColorToast={setColorToast}
          organizationId={organizationInfo.id}
          profileInfo={data}
        />
      )}

      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <AlertWrapper>
        <Alert message={toast} setMessage={setToast} color={colorToast} />
      </AlertWrapper>
    </>
  );
};

export default Header;

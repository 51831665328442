import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../../utils/routes.json';

import dealService from '../../../services/deal.service';
import { DEAL_CONTACT, EMPTY_CURRENCY } from '../../../utils/constants';
import AlertWrapper from '../../Alert/AlertWrapper';
import DealForm from '../../deals/DealForm';
import Alert from '../../../components/Alert/Alert';
import fieldService from '../../../services/field.service';
import RightPanelModal from '../../modal/RightPanelModal';
import { useForm } from 'react-hook-form';
import { RIGHT_PANEL_WIDTH, overflowing } from '../../../utils/Utils';
import { groupBy } from 'lodash';
import pipelineServices from '../../../services/pipeline.services';
import { removeCustomFieldsFromActivityForm } from '../../../views/Deals/contacts/utils';
import { useModuleContext } from '../../../contexts/moduleContext';
import organizationService from '../../../services/organization.service';
import { usePagesContext } from '../../../contexts/pagesContext';

const AddDeal = ({
  onGetDeals,
  organization = {},
  children,
  setOpenDeal,
  contactProfile = {},
  openDeal = false,
  errorMessage,
  setErrorMessage = () => {},
  successMessage,
  setSuccessMessage = () => {},
  fromNavbar,
  dealData = {},
  setOpenList,
  searchValue,
  initialDeals,
  selectedStage,
  pipeline,
  isEdited,
  getDeal,
}) => {
  const dealsObj = {
    name: '',
    currency: 'USD',
    tenant_deal_stage_id: '',
    contact_organization_id: '',
    date_closed: '',
    assigned_user_id: '',
  };
  const [cloneData, setCloneData] = useState({ ...dealData });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [selectOrganization, setSelectOrganization] = useState({});
  const [selectContactPerson, setSelectContactPerson] = useState({});
  const [selectedPipeline, setSelectedPipeline] = useState({});
  const [pipelineStages, setPipelineStages] = useState([]);
  const [selectTitle, setSelectTitle] = useState('');
  const [pipelines, setPipelines] = useState([]);
  const [dealFormData, setDealFormData] = useState(dealsObj);
  const [isLoading, setIsLoading] = useState(false);
  const [addProductsClicked, setAddProductsClicked] = useState(false);
  const [isFieldsData, setIsFieldsData] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [selectedPipelineStage, setSelectedPipelineStage] = useState(
    dealData?.tenant_deal_stage || {}
  );
  const [allOrganizations, setAllOrganizations] = useState([]);
  const { moduleMap } = useModuleContext();
  const { pageContext, setPageContext } = usePagesContext();
  const [ownerData, setOwnerData] = useState([]);
  const [searchOrg, setSearchOrg] = useState({
    search: '',
  });
  const [searchContact, setSearchContact] = useState({
    search: '',
  });
  const currentView = 'deal';
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getFieldState,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: dealsObj,
  });
  const groupBySection = (fieldsList) => {
    setIsFieldsData(groupBy(fieldsList, 'section'));
  };
  const getFields = async () => {
    setIsLoading(true);
    const { data } = await fieldService.getFields(currentView, {
      preferred: true,
    });
    if (data.length > 0) {
      groupBySection(data);
      setIsLoading(false);
    } else {
      const { data } = await fieldService.createDefaultFields(currentView);
      groupBySection(data);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (openDeal === true) {
      getFields();
      if (dealData?.id) {
        setCloneData(dealData);
      }
    }
  }, [openDeal]);

  const toggle = () => {
    setOpenDeal(!openDeal);
    setOpenList && setOpenList(false);
    overflowing();
    setCustomFields([]);
  };
  const onClose = () => {
    setSelectOrganization();
    setSelectContactPerson();
    toggle();
    setIsFieldsData([]);
    overflowing();
    setAddProductsClicked(false);
    setCustomFields([]);
    const dealsReset = {
      name: '',
      currency: 'USD',
      tenant_deal_stage_id: '',
      contact_organization_id: '',
      date_closed: '',
      assigned_user_id: '',
    };
    reset(setDealFormData(dealsReset));
  };
  const onHandleSubmit = async () => {
    setLoading(true);
    delete dealFormData?.deal_products;
    delete dealFormData?.id;
    delete dealFormData?.modified_user_id;
    delete dealFormData?.created_by;
    if (dealFormData.amount && !dealFormData.currency) {
      setLoading(false);
      setErrorMessage(EMPTY_CURRENCY);
      return setError(EMPTY_CURRENCY);
    }
    dealFormData.sales_stage = dealFormData.deal_type || 'cold';
    const parsedAmount = parseInt(dealFormData.amount);
    const dataDeal = {
      ...dealFormData,
      amount: Number.isFinite(parsedAmount) ? parsedAmount : 0, // default 0 api requires that
      date_closed: new Date(dealFormData.date_closed),
      position: 0,
    };
    const updateFields = removeCustomFieldsFromActivityForm(
      dataDeal,
      customFields
    );

    if (isEdited) {
      try {
        await dealService.updateDeal(dealData.id, updateFields);
        getDeal('', true);
        setOpenDeal(false);
        setSuccessMessage(`${moduleMap.deal.singular} Updated.`);
        setPageContext({
          ...pageContext,
          DealDateClose: new Date(dealFormData.date_closed),
          RefreshTimeline: pageContext.RefreshTimeline
            ? pageContext.RefreshTimeline + 1
            : 1,
        });
      } catch (err) {
        setErrorMessage(err.messgae);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      const newDeal = await dealService
        .createDeal(updateFields)
        .catch((err) => {
          setErrorMessage(err.messgae);
          setError(err.message);
        });
      if (newDeal) {
        const owners = ownerData?.map(async (item) => {
          return await dealService.addOwner(newDeal?.data?.id, item?.user?.id);
        });
        await Promise.all(owners);
        await Promise.all(
          customFields?.map(async (item) => {
            await new Promise((resolve) => {
              if (item?.value !== '')
                dealService
                  .updateCustomField(newDeal?.data?.id, item)
                  .then(resolve);
            });
          })
        );
        setSelectedPipeline();
        setPipelineStages();
        setSelectTitle();
        setPipelines();
        setSelectOrganization();
        setSelectContactPerson();
        setSearchOrg({
          search: '',
        });
        setSearchContact({
          search: '',
        });
        onClose();
        setAddProductsClicked(false);
        setSuccessMessage(DEAL_CONTACT);
        setSuccess(DEAL_CONTACT);
        history.push(`${routes.dealsPipeline}/${newDeal?.data?.id}`);
        if (fromNavbar) {
          history.push(`${routes.dealsPipeline}/${newDeal?.data?.id}`);
        }
      }
      setTimeout(() => {
        setLoading(false);
        onGetDeals && onGetDeals();
      }, 3000);
    }
  };

  useEffect(() => {
    (async () => {
      const { data } = await pipelineServices.getPipelines();
      const updatedPipelines = data?.map((p) => ({ ...p, key: p.id }));
      setPipelines(updatedPipelines);
      // when open this from navbar look for default pipeline first, if found select it
      // if (fromNavbar) {
      const defaultPipeline = updatedPipelines.find((p) => p.isDefault);
      setSelectedPipeline(
        pipeline?.id
          ? pipeline
          : updatedPipelines?.length
          ? defaultPipeline || updatedPipelines[0]
          : {}
      );
    })();
  }, [openDeal]);

  const [containerWidth, setContainerWidth] = useState(RIGHT_PANEL_WIDTH);
  useEffect(() => {
    const groups = Object.keys(isFieldsData);
    if (groups.length) {
      for (const grp of groups) {
        const field = isFieldsData[grp];
        field.forEach((item) => {
          const { columnName, key } = item;
          const fieldName = columnName
            ? columnName.toLowerCase()
            : key?.toLowerCase().replace(/\s+/g, '');
          setValue(fieldName, dealFormData[fieldName]);
        });
      }
    }
  }, [isFieldsData, openDeal]);

  const onGetOrganzations = async () => {
    if (searchOrg?.search) {
      const response = await organizationService
        .getOrganizations(searchOrg, { limit: 10 })
        .catch((err) => err);

      const { organizations } = response?.data;
      setAllOrganizations(organizations?.filter((o) => !!o.name));
    }
  };
  useEffect(() => {
    if (searchOrg?.search !== '') {
      onGetOrganzations();
    }
  }, [searchOrg]);
  return (
    <>
      {openDeal && (
        <RightPanelModal
          showModal={openDeal}
          setShowModal={() => onClose()}
          showOverlay={true}
          containerBgColor={'pb-0'}
          containerWidth={containerWidth}
          containerPosition={'position-fixed'}
          headerBgColor="bg-gray-5"
          Title={
            <div className="d-flex py-2 align-items-center">
              {moduleMap.deal && (
                <h3 className="mb-0">{`${isEdited ? 'Update' : 'Add'} ${
                  moduleMap.deal.singular
                }`}</h3>
              )}
            </div>
          }
        >
          {moduleMap.deal && (
            <DealForm
              moduleData={moduleMap}
              moduleMap={moduleMap.deal.singular}
              setDealFormData={setDealFormData}
              dealFormData={dealFormData}
              searchValue={searchValue}
              isprincipalowner="true"
              pipeline={pipeline}
              addProductsClicked={addProductsClicked}
              setAddProductsClicked={setAddProductsClicked}
              prevalue="true"
              isEdited={isEdited}
              register={register}
              allOrganizations={allOrganizations}
              handleSubmit={handleSubmit(onHandleSubmit)}
              errors={errors}
              ownerData={ownerData}
              setOwnerData={setOwnerData}
              dealData={cloneData}
              organization={
                Object.keys(contactProfile)?.length > 0
                  ? contactProfile?.organization
                  : Object.keys(organization)?.length > 0
                  ? organization
                  : cloneData?.organization
              }
              loading={loading}
              contactProfile={
                Object.keys(contactProfile)?.length > 0
                  ? contactProfile
                  : dealData?.contact
              }
              selectOrganization={selectOrganization}
              setSelectOrganization={setSelectOrganization}
              selectContactPerson={selectContactPerson}
              setSelectContactPerson={setSelectContactPerson}
              isLoading={isLoading}
              openDeal={openDeal}
              setValue={setValue}
              getFieldState={getFieldState}
              control={control}
              pipelines={pipelines}
              searchOrg={searchOrg}
              setSearchOrg={setSearchOrg}
              searchContact={searchContact}
              setSearchContact={setSearchContact}
              fields={isFieldsData}
              onClose={onClose}
              setSelectedPipelineStage={setSelectedPipelineStage}
              customDataFields={customFields}
              setCustomDataFields={setCustomFields}
              selectedPipeline={selectedPipeline}
              setSelectedPipeline={setSelectedPipeline}
              pipelineStages={pipelineStages}
              setPipelineStages={setPipelineStages}
              selectTitle={
                selectTitle !== '' ? selectTitle : selectedStage?.name
              }
              setSelectTitle={setSelectTitle}
              initialDeals={initialDeals}
              fromNavbar={fromNavbar}
              selectedStage={selectedPipelineStage || selectedStage}
              setContainerWidth={setContainerWidth}
            />
          )}
        </RightPanelModal>
      )}
      {children}

      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={error || errorMessage}
          setMessage={setError || setErrorMessage}
        />
        <Alert
          color="success"
          message={success || successMessage}
          setMessage={setSuccess || setSuccessMessage}
        />
      </AlertWrapper>
    </>
  );
};

export default AddDeal;

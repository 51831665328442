import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Form, FormGroup, Spinner } from 'reactstrap';

import { ALL_LABEL } from '../../../utils/constants';
import { labels } from '../contacts/Contacts.constants';
import {
  DATE_FORMAT_EJS,
  DATE_FORMAT_EJS_UPDATED,
  emailRegex,
  formatHHMMSS,
  urlRegex,
  valueNumberValidator,
  isToFixedNoRound,
  setDateFormat,
  DATE_FORMAT,
  formatHHMM,
  DATE_FORMAT_MONTH,
} from '../../../utils/Utils';
import ReactDatepicker from '../../../components/inputs/ReactDatpicker';
import { renderComponent } from '../../../components/peoples/constantsPeople';
import { useForm } from 'react-hook-form';
import ControllerValidation from '../../../components/commons/ControllerValidation';
import { PricingField } from '../../../components/PricingFieldComponent';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import IdfFormInput from '../../../components/idfComponents/idfFormInput/IdfFormInput';
import { CheckboxInput } from '../../../components/layouts/CardLayout';
import stringConstants from '../../../utils/stringConstants.json';
import DropdownSelect from '../../../components/DropdownSelect';
import AddPicklistOptions from '../../../components/peopleProfile/contentFeed/AddPicklistOptions';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import Avatar from '../../../components/Avatar';
import { Link } from 'react-router-dom';
import routes from '../../../utils/routes.json';
import IdfTooltip from '../../../components/idfComponents/idfTooltip';
import NewKindOfSearchDropdown from '../../../components/commons/NewKindOfSearchDropdown';
import dealService from '../../../services/deal.service';
import contactService from '../../../services/contact.service';
import { usePagesContext } from '../../../contexts/pagesContext';

const constants = stringConstants.deals.organizations.profile;

const maxPrice = 99999999.0;

const EditableRow = ({
  field,
  editMode,
  loading,
  setEditMode,
  dealFormData,
  children,
}) => {
  const { columnName, key, field_type } = field;
  const fieldName = columnName || key?.toLowerCase().replace(/\s+/g, '');

  const isAnyOtherEditModeActive = (currentKey) => {
    return Object.keys(editMode).some((k) => k !== currentKey && editMode[k]);
  };
  const editableClass = !isAnyOtherEditModeActive(key) ? 'editable-field' : '';

  const noHoverBgClass = editMode[key] && 'no-hover';

  return (
    <div className={`pipeline-overview-row ${noHoverBgClass} ${editableClass}`}>
      <p>{key}</p>
      {editMode[key] ? (
        <div className={`relative ${fieldName}`}>
          {children}
          {editMode[key] && (
            <div
              className="text-right position-absolute"
              style={{ top: 4, right: 0 }}
            >
              <div className="d-flex align-items-center bg-white px-2 z-index-99 py-1">
                {!loading ? (
                  <>
                    <a className="cursor-pointer icon-hover-bg">
                      <button type="submit">
                        <MaterialIcon
                          icon="check_circle"
                          clazz="text-green fs-4 text-success"
                          filled
                        />
                      </button>
                    </a>
                    <a
                      className="cursor-pointer icon-hover-bg"
                      onClick={() => setEditMode({ ...editMode, [key]: false })}
                    >
                      <MaterialIcon
                        icon="cancel"
                        clazz="text-gray-400 fs-4"
                        filled
                      />{' '}
                    </a>
                  </>
                ) : (
                  <Spinner className="spinner-grow-xs" />
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <p>
            {field_type !== 'DATE' &&
              field_type !== 'PICKLIST' &&
              field_type !== 'PICKLIST_MULTI' &&
              field_type !== 'CHECKBOX' &&
              field_type !== 'TIME' &&
              (dealFormData[fieldName]
                ? columnName === 'amount'
                  ? isToFixedNoRound(dealFormData[fieldName], 2)
                  : dealFormData[fieldName]
                : '--')}

            {field_type === 'DATE' &&
              setDateFormat(dealFormData[fieldName], DATE_FORMAT)}

            {(field_type === 'PICKLIST' || field_type === 'PICKLIST_MULTI') &&
              dealFormData[fieldName]?.length &&
              dealFormData[fieldName].map((item) => item.value).join(',')}

            {field_type === 'CHECKBOX' &&
              (dealFormData[fieldName] ? 'True' : 'False')}

            {field_type === 'TIME' &&
              setDateFormat(dealFormData[fieldName], formatHHMM)}
          </p>

          <div className="position-absolute right-0 edit-div">
            <button
              className="btn btn-icon btn-sm hover-bg-none"
              onClick={() => setEditMode({ ...editMode, [key]: true })}
            >
              <MaterialIcon icon="edit" size="fs-5" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const EditableContactPersonInline = ({
  label,
  deal,
  fieldKey,
  editMode,
  setEditMode,
  dealContact,
  setDealContact,
  dealFormData,
  dispatch,
  setSuccessMessage,
  children,
}) => {
  const { pageContext, setPageContext } = usePagesContext();
  const isAnyOtherEditModeActive = (currentKey) => {
    return Object.keys(editMode).some((k) => k !== currentKey && editMode[k]);
  };
  const [searchUser, setSearchUser] = useState({ search: '' });
  const [savingContact, setSavingContact] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const handleSelected = async (item) => {
    setDealContact({
      ...item,
      name: `${item?.first_name} ${item?.last_name}`,
    });
  };
  const editableClass = !isAnyOtherEditModeActive(fieldKey)
    ? 'editable-field'
    : '';

  const noHoverBgClass = editMode[fieldKey] && 'no-hover';
  const onInputSearch = (e) => {
    const { value } = e.target || {};
    setSearchUser({
      ...searchUser,
      search: value,
    });
  };
  const getContacts = async () => {
    const searchResults = await contactService.getContact(
      { search: searchUser.search, deleted: false },
      { page: 1, limit: 10 }
    );
    const { data } = searchResults || {};
    const filteredUsersData = data?.contacts?.map((cnt) => ({
      ...cnt,
      name: `${cnt.first_name} ${cnt.last_name}`,
    }));
    setUsersData(filteredUsersData);
  };

  useEffect(() => {
    editMode[fieldKey] && getContacts();
  }, [searchUser?.search, editMode]);

  const handleSaveContact = async () => {
    const isContactEmpty = Object.keys(dealContact).length <= 1;
    const isSameContact =
      dealContact?.id &&
      dealFormData?.contact_person_id &&
      dealContact.id === dealFormData.contact_person_id;
    if (isContactEmpty || isSameContact) {
      setEditMode({ ...editMode, [fieldKey]: false });
      return;
    }
    try {
      setSavingContact(true);
      setEditMode({ ...editMode, [fieldKey]: false });
      dispatch({
        ...dealFormData,
        contact: dealContact,
        contact_person_id: dealContact.id,
      });
      await dealService.updateDeal(deal.id, {
        contact_person_id: dealContact.id,
      });
      setPageContext({
        ...pageContext,
        RefreshTimeline: pageContext.RefreshTimeline
          ? pageContext.RefreshTimeline + 1
          : 1,
      });
      setSuccessMessage('Contact updated');
    } catch (e) {
      console.log(e);
    } finally {
      setSavingContact(false);
    }
  };
  return (
    <div className={`pipeline-overview-row ${noHoverBgClass} ${editableClass}`}>
      <p>{label}</p>
      {editMode[fieldKey] ? (
        <div className={`relative ${fieldKey} w-50`}>
          <div className="w-100 flex-fill">
            <NewKindOfSearchDropdown
              preData={usersData}
              placeholder="Add contact"
              currentSelected={dealContact}
              onItemSelect={handleSelected}
              onInputSearch={onInputSearch}
            />
          </div>
          {editMode[fieldKey] && (
            <div
              className="text-right position-absolute"
              style={{ top: 4, right: -4 }}
            >
              <div className="d-flex align-items-center bg-white px-2 z-index-99 py-1">
                {!savingContact ? (
                  <>
                    <a className="cursor-pointer icon-hover-bg">
                      <button type="submit" onClick={handleSaveContact}>
                        <MaterialIcon
                          icon="check_circle"
                          clazz="text-green fs-4 text-success"
                          filled
                        />
                      </button>
                    </a>
                    <a
                      className="cursor-pointer icon-hover-bg"
                      onClick={() => {
                        setDealContact(
                          deal?.contact_person_id
                            ? {
                                ...deal.contact,
                                name: `${deal?.contact?.first_name} ${deal?.contact?.last_name}`,
                              }
                            : {}
                        );
                        setEditMode({ ...editMode, [fieldKey]: false });
                      }}
                    >
                      <MaterialIcon
                        icon="cancel"
                        clazz="text-gray-400 fs-4"
                        filled
                      />{' '}
                    </a>
                  </>
                ) : (
                  <Spinner className="spinner-grow-xs" />
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {children}
          <div className="position-absolute right-0 edit-div">
            <button
              className="btn btn-icon btn-sm hover-bg-none"
              onClick={() => setEditMode({ ...editMode, [fieldKey]: true })}
            >
              <MaterialIcon icon="edit" size="fs-5" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const InlineItem = ({
  index,
  fieldType,
  moduleMap,
  fields,
  dealFormData,
  onHandleCustomField,
  amountHandler,
  onChange,
  errors,
  control,
  register,
  getFieldState,
  editMode,
  setEditMode,
  onHandleCustomCheckBox,
  onHandlePicklistSingle,
  selectPicklistMultiValue,
  onHandlePicklistMulti,
  onHandleCustomDate,
  onChangeClosingDate,
  selectPicklistValue,
  loading,
}) => {
  return (
    <div key={`fields-${index}`}>
      {fieldType === 'Additional Information' && (
        <h5 className="pb-0 pt-3 px-2">
          {moduleMap ? fieldType.replace(/Deal/g, moduleMap) : fieldType}
        </h5>
      )}
      {fields[fieldType]?.length > 0 &&
        fields[fieldType]?.map((item) => {
          const {
            field_type,
            columnName,
            key,
            mandatory,
            isCustom,
            id,
            value_type,
            value_option,
          } = item;
          const fieldName =
            columnName || key?.toLowerCase().replace(/\s+/g, '');
          return (
            <>
              {key !== 'Company' &&
              key !== 'Pipeline & Stage' &&
              key !== 'Contact Person' &&
              field_type !== 'CHECKBOX' &&
              field_type !== 'PHONE' &&
              field_type !== 'CURRENCY' &&
              field_type !== 'DATE' &&
              field_type !== 'PICKLIST' &&
              field_type !== 'PICKLIST_MULTI' &&
              field_type !== 'TIME' ? (
                <div key={item?.id}>
                  <EditableRow
                    field={item}
                    editMode={editMode}
                    loading={loading}
                    setEditMode={setEditMode}
                    dealFormData={dealFormData}
                  >
                    {renderComponent(field_type, {
                      value: dealFormData,
                      className: 'text-capitalize',
                      inputClass: mandatory
                        ? 'border-left-4 border-left-danger'
                        : '',
                      validationConfig: {
                        required: mandatory,
                        inline: false,
                        onChange: (e) =>
                          isCustom
                            ? onHandleCustomField(e, id, value_type)
                            : fieldName === 'amount'
                            ? amountHandler(e)
                            : onChange(e),
                        pattern:
                          field_type === 'EMAIL'
                            ? {
                                value: emailRegex,
                                message: 'Please enter a valid email.',
                              }
                            : field_type === 'URL'
                            ? {
                                value: urlRegex,
                                message: 'Please enter a valid URL',
                              }
                            : '',
                      },
                      errors,
                      register,
                      errorDisplay: 'mb-0',
                      fieldType:
                        field_type.toLowerCase() === 'url'
                          ? 'text'
                          : field_type.toLowerCase(),
                      type: field_type === 'TEXT' ? 'textarea' : 'input',
                      name: fieldName,
                      placeholder: field_type === 'NUMBER' ? '0' : key,
                      autoFocus: editMode[key],
                    })}
                  </EditableRow>
                </div>
              ) : (
                ''
              )}
              {field_type === 'CHECKBOX' && (
                <FormGroup row className="py-1">
                  <EditableRow
                    field={item}
                    editMode={editMode}
                    loading={loading}
                    setEditMode={setEditMode}
                    dealFormData={dealFormData}
                  >
                    <Col md={9} className="pl-0">
                      <CheckboxInput
                        id={fieldName}
                        onChange={(e) => {
                          if (isCustom) {
                            onHandleCustomCheckBox(e, id);
                          } else {
                            onChange(e);
                          }
                        }}
                        label={item?.key}
                        name={fieldName}
                        checked={dealFormData[fieldName]}
                      />
                    </Col>
                  </EditableRow>
                </FormGroup>
              )}
              {field_type === 'PICKLIST' && (
                <EditableRow
                  field={item}
                  editMode={editMode}
                  loading={loading}
                  setEditMode={setEditMode}
                  dealFormData={dealFormData}
                >
                  <FormGroup row className="py-1 align-items-center">
                    <Col md={9} className="pl-0">
                      <div className="date-picker input-time w-100">
                        <ControllerValidation
                          name={fieldName}
                          errors={errors}
                          form={dealFormData}
                          errorDisplay="mb-0"
                          control={control}
                          validationConfig={{
                            required: mandatory ? `${key} is required.` : '',
                          }}
                          renderer={({ field }) => (
                            <DropdownSelect
                              data={value_option.map((item, i) => {
                                return {
                                  id: i,
                                  name: item.value,
                                };
                              })}
                              onHandleSelect={(item) => {
                                onHandlePicklistSingle(item, id, fieldName);
                              }}
                              select={selectPicklistValue(
                                fieldName,
                                value_option,
                                id
                              )}
                              placeholder="Select Option"
                              customClasses={'w-100 overflow-y-auto max-h-300'}
                              validationConfig={{
                                required: mandatory,
                              }}
                              fieldState={getFieldState(fieldName)}
                            />
                          )}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                </EditableRow>
              )}
              {field_type === 'PICKLIST_MULTI' && (
                <EditableRow
                  field={item}
                  editMode={editMode}
                  loading={loading}
                  setEditMode={setEditMode}
                  dealFormData={dealFormData}
                >
                  <FormGroup row className="py-1 align-items-center">
                    <Col md={9} className="pl-0">
                      <div className="date-picker input-time w-100">
                        <ControllerValidation
                          name={fieldName}
                          errors={errors}
                          form={dealFormData}
                          errorDisplay="mb-0"
                          control={control}
                          validationConfig={{
                            required: mandatory ? `${key} is required.` : '',
                          }}
                          renderer={({ field }) => (
                            <AddPicklistOptions
                              dropdownList={value_option.filter(
                                (item) => item.value !== '-None-'
                              )}
                              validationConfig={{
                                required: mandatory,
                              }}
                              fieldState={getFieldState(fieldName)}
                              placeholder={'Add Options'}
                              value={selectPicklistMultiValue(
                                fieldName,
                                value_option,
                                id,
                                fieldName
                              )}
                              setValue={(e) =>
                                onHandlePicklistMulti(e, id, fieldName)
                              }
                              tooltip={constants.tooltipTagInput}
                              labelSize="full"
                              onChange={(e) => {}}
                            />
                          )}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                </EditableRow>
              )}
              {field_type === 'PHONE' && (
                <FormGroup row className="py-1">
                  <EditableRow
                    field={item}
                    editMode={editMode}
                    loading={loading}
                    setEditMode={setEditMode}
                    dealFormData={dealFormData}
                  >
                    <Col md={9} className="pl-0">
                      <IdfFormInput
                        className="mb-0 w-100"
                        placeholder={item?.key}
                        value={dealFormData}
                        name={item?.columnName}
                        maxLength={14}
                        onChange={(e) => onChange(e)}
                        autocomplete="off"
                      />
                    </Col>
                  </EditableRow>
                </FormGroup>
              )}
              {field_type === 'DATE' && (
                <EditableRow
                  field={item}
                  editMode={editMode}
                  loading={loading}
                  setEditMode={setEditMode}
                  dealFormData={dealFormData}
                >
                  <FormGroup row className="py-1 align-items-center">
                    <Col md={9} className="pl-0">
                      <div className="date-picker input-time w-100">
                        <ControllerValidation
                          name={fieldName}
                          errors={errors}
                          form={dealFormData}
                          errorDisplay="mb-0"
                          control={control}
                          validationConfig={{
                            required: mandatory ? `${key} is required.` : '',
                          }}
                          renderer={({ field }) => (
                            <ReactDatepicker
                              id={fieldName}
                              name={fieldName}
                              {...field}
                              format={DATE_FORMAT_EJS}
                              autoComplete="off"
                              todayButton="Today"
                              validationConfig={{
                                required: mandatory
                                  ? `${key} is required.`
                                  : '',
                              }}
                              fieldState={getFieldState(fieldName)}
                              value={
                                dealFormData[fieldName] &&
                                new Date(dealFormData[fieldName])
                              }
                              className="form-control"
                              placeholder={DATE_FORMAT_EJS_UPDATED}
                              onChange={(date) =>
                                isCustom
                                  ? onHandleCustomDate(date, id, fieldName)
                                  : onChangeClosingDate(date, fieldName)
                              }
                            />
                          )}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                </EditableRow>
              )}
              {field_type === 'TIME' && (
                <EditableRow
                  field={item}
                  editMode={editMode}
                  loading={loading}
                  setEditMode={setEditMode}
                  dealFormData={dealFormData}
                >
                  <FormGroup row className="py-1 align-items-center">
                    <Col md={9} className="pl-0">
                      <div className="date-picker input-time w-100">
                        <TimePickerComponent
                          id={`start-time`}
                          cssClass="e-custom-style"
                          openOnFocus={true}
                          value={dealFormData[fieldName] || '12:00 PM'}
                          format="hh:mm a"
                          placeholder="Start Time"
                          onChange={(e) =>
                            onHandleCustomField(e, id, value_type, field_type)
                          }
                        />
                      </div>
                    </Col>
                  </FormGroup>
                </EditableRow>
              )}
              {field_type === 'CURRENCY' && (
                <EditableRow field={item}>
                  <PricingField
                    name={fieldName}
                    placeholder={key}
                    errors={errors}
                    register={register}
                    onChange={(e) =>
                      isCustom
                        ? onHandleCustomField(e, id, value_type, field_type)
                        : onChange(e)
                    }
                    validationConfig={{
                      required: mandatory,
                      inline: false,
                    }}
                    value={dealFormData}
                  />
                </EditableRow>
              )}
            </>
          );
        })}
    </div>
  );
};

const PipelineForm = ({
  moduleMap,
  moduleData,
  editMode,
  setEditMode,
  deal,
  onHandleSubmit,
  fields,
  loading,
  setLoading,
  customDataFields,
  setCustomDataFields,
  dealFormData,
  dispatch,
  isPrincipalOwner,
  setSuccessMessage,
}) => {
  const [, setSelectStage] = useState(ALL_LABEL);
  const { pageContext, setPageContext } = usePagesContext();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getFieldState,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: deal,
  });

  useEffect(() => {
    function createEditField(fields) {
      const editField = {};

      // Iterate over each array in the fields object
      Object.values(fields).forEach((array) => {
        array.forEach((item) => {
          editField[item.key] = false;
        });
      });

      return editField;
    }

    const editValues = createEditField(fields);
    setEditMode(editValues);
  }, [fields]);

  useEffect(() => {
    const groups = Object.keys(fields);
    if (groups.length) {
      for (const grp of groups) {
        const field = fields[grp];
        field.forEach((item) => {
          const { columnName, key } = item;
          const fieldName = columnName
            ? columnName.toLowerCase()
            : key?.toLowerCase().replace(/\s+/g, '');
          setValue(fieldName, deal[fieldName]);
        });
      }
    }
  }, [fields]);
  useEffect(() => {
    if (deal) {
      getStage();
    }
  }, [deal]);

  const getStage = () => {
    const stage = labels.find((label) => label.name === deal.deal_type);

    setSelectStage(stage?.title);
  };

  const onSubmit = async () => {
    setLoading(true);
    const newDealFormData = {
      ...dealFormData,
      sales_stage: dealFormData.deal_type,
    };

    onHandleSubmit(newDealFormData, () => {
      setPageContext({
        ...pageContext,
        RefreshTimeline: pageContext.RefreshTimeline
          ? pageContext.RefreshTimeline + 1
          : 1,
      });
    });
    reset(newDealFormData);
  };

  const onChangeClosingDate = (selectedDate, fieldName) => {
    dispatch({
      ...dealFormData,
      [fieldName]: selectedDate,
    });
    setValue(fieldName, selectedDate);
  };

  const amountHandler = (e) => {
    let { value, name } = e.target;

    value = valueNumberValidator(value, 2, maxPrice);

    e.target.value = value;
    dispatch({ ...dealFormData, [name]: value });
  };
  const onChange = (e) => {
    const { value, name } = e.target;
    dispatch({ ...dealFormData, [name]: value });
  };
  const onHandleCustomField = (e, id, value_type, field_type) => {
    const target = e.target;
    let value = '';
    if (value_type === 'string' && target.value !== '') {
      value = target.value;
    }
    if (value_type === 'number' && target.value !== '') {
      value = parseInt(target.value);
    }
    if (field_type === 'CURRENCY') {
      value = `$${target.value}`;
    } else if (field_type === 'TIME') {
      value = moment(value).format(formatHHMMSS);
    }
    let updated = false;
    dispatch({ ...dealFormData, [target.name]: target.value });
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return {
          field_id: id,
          value,
        };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      setCustomDataFields([...fieldData, { field_id: id, value }]);
    }
    setValue(field_type, field_type);
  };
  const onHandleCustomCheckBox = (e, id) => {
    const target = e.target;
    dispatch({ ...dealFormData, [target.name]: target.checked });

    const isDuplicate = customDataFields.some((field) => field.field_id === id);

    if (!isDuplicate) {
      setCustomDataFields([
        ...customDataFields,
        { field_id: id, value: target.checked },
      ]);
    }
    setValue(target.name, target.checked);
  };
  const onHandleCustomDate = (date, id, fieldName) => {
    if (date === '') {
      setCustomDataFields([...customDataFields, { field_id: id, value: '' }]);
    } else {
      setCustomDataFields([
        ...customDataFields,
        { field_id: id, value: new Date(date) },
      ]);
    }
    setValue(fieldName, new Date(date));
    dispatch({ ...dealFormData, [fieldName]: new Date(date) });
  };

  const onHandlePicklistSingle = (item, id, fieldName) => {
    let picked;
    if (item.name === '-None-') {
      picked = '';
    } else {
      picked = [{ value: item.name }];
    }
    let updated = false;
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return { field_id: id, value: picked, key: fieldName };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      setCustomDataFields([
        ...customDataFields,
        { field_id: id, value: picked, key: fieldName },
      ]);
    }
    dispatch({ ...dealFormData, [fieldName]: picked });
    setValue(fieldName, picked);
  };

  const selectPicklistValue = (fieldName, value_option, id) => {
    if (dealFormData[fieldName] === '') {
      return '-None-';
    }
    if (
      dealFormData[fieldName] &&
      dealFormData[fieldName][0] &&
      typeof dealFormData[fieldName][0].value === 'string'
    ) {
      return dealFormData[fieldName][0].value;
    }

    return '-None-';
  };

  const onHandlePicklistMulti = (val, id, fieldName) => {
    let picked;
    if (val.length === 0) {
      picked = '';
    } else {
      picked = val;
    }
    let updated = false;
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return { field_id: id, value: picked, key: fieldName };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      const tempCustom = [
        ...customDataFields,
        { field_id: id, value: picked, key: fieldName },
      ];
      setCustomDataFields(tempCustom);
    }
    dispatch({ ...dealFormData, [fieldName]: picked });
    setValue(fieldName, picked);
  };
  const selectPicklistMultiValue = (fieldName, value_option, id) => {
    if (dealFormData[fieldName] === '') {
      return [];
    }
    if (dealFormData[fieldName]) {
      return dealFormData[fieldName];
    }

    return [];
  };
  const [dealContact, setDealContact] = useState(
    deal?.contact_person_id
      ? {
          ...deal.contact,
          name: `${deal?.contact?.first_name} ${deal?.contact?.last_name}`,
        }
      : { name: 'Add contact' }
  );

  const OverviewFields = {
    'Deal Information': fields['Deal Information']?.filter((item) => {
      const { key } = item;
      return (
        key !== 'Company' &&
        key !== 'Pipeline & Stage' &&
        key !== 'Contact Person'
      );
    }),
  };
  const AdditionalFields = {
    'Additional Information': fields['Additional Information'],
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body bg-light pt-0 px-3">
        <h5 className="pb-0 px-2">Opportunity Overview</h5>
        {Object.keys(OverviewFields).map((fieldType, index) => {
          return (
            <InlineItem
              key={index}
              index={index}
              fieldType={fieldType}
              moduleMap={moduleMap}
              fields={fields}
              dealFormData={dealFormData}
              onHandleCustomField={onHandleCustomField}
              amountHandler={amountHandler}
              onChange={onChange}
              errors={errors}
              control={control}
              register={register}
              getFieldState={getFieldState}
              editMode={editMode}
              setEditMode={setEditMode}
              loading={loading}
              onHandleCustomCheckBox={onHandleCustomCheckBox}
              onHandlePicklistSingle={onHandlePicklistSingle}
              selectPicklistMultiValue={selectPicklistMultiValue}
              onHandlePicklistMulti={onHandlePicklistMulti}
              onHandleCustomDate={onHandleCustomDate}
              onChangeClosingDate={onChangeClosingDate}
              selectPicklistValue={selectPicklistValue}
            />
          );
        })}

        <h5 className="pb-0 px-2 pt-3">Primary Contact</h5>
        <EditableContactPersonInline
          label="Contact Person"
          deal={deal}
          fieldKey="contact_person_id"
          editMode={editMode}
          setEditMode={setEditMode}
          dealContact={dealContact}
          setDealContact={setDealContact}
          dealFormData={dealFormData}
          dispatch={dispatch}
          setSuccessMessage={setSuccessMessage}
        >
          {dealContact?.id ? (
            <Link to={`${routes.contacts}/${dealContact?.id}/profile`}>
              {`${dealContact?.first_name} ${dealContact?.last_name}`}
            </Link>
          ) : (
            '--'
          )}
        </EditableContactPersonInline>

        {Object.keys(AdditionalFields).map((fieldType, index) => {
          return (
            <InlineItem
              key={index}
              index={index}
              fieldType={fieldType}
              moduleMap={moduleMap}
              fields={fields}
              dealFormData={dealFormData}
              onHandleCustomField={onHandleCustomField}
              amountHandler={amountHandler}
              onChange={onChange}
              errors={errors}
              control={control}
              register={register}
              setEditMode={setEditMode}
              loading={loading}
              getFieldState={getFieldState}
              editMode={editMode}
              onHandleCustomCheckBox={onHandleCustomCheckBox}
              onHandlePicklistSingle={onHandlePicklistSingle}
              selectPicklistMultiValue={selectPicklistMultiValue}
              onHandlePicklistMulti={onHandlePicklistMulti}
              onHandleCustomDate={onHandleCustomDate}
              onChangeClosingDate={onChangeClosingDate}
              selectPicklistValue={selectPicklistValue}
            />
          );
        })}

        <div className="pipeline-overview-row">
          <p className="d-flex align-items-center gap-1 pt-4 w-100">
            <IdfTooltip
              text={`${deal?.assigned_user?.first_name} ${deal?.assigned_user?.last_name}`}
            >
              <Avatar user={deal?.assigned_user} defaultSize="xs" />
            </IdfTooltip>
            Last Modified on{' '}
            <span className="font-weight-semi-bold">
              {setDateFormat(deal?.date_modified, DATE_FORMAT_MONTH)}
            </span>{' '}
          </p>
        </div>
      </div>
    </Form>
  );
};

export default PipelineForm;

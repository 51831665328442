import MaterialIcon from '../commons/MaterialIcon';
import React, { useEffect, useState } from 'react';
import ViewChecklist from '../fields/modals/ViewChecklist';
import moment from 'moment';
import {
  DATE_FORMAT,
  DATE_FORMAT_TIME_WO_SEC,
  checkDueDate,
  isPermissionAllowed,
  overflowing,
} from '../../utils/Utils';
import MoreActions from '../MoreActions';
import checklistService from '../../services/checklist.service';
import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';
import TooltipComponent from '../lesson/Tooltip';
import { ChecklistStatuses } from '../../utils/checklist.constants';
import ChecklistStatus from './ChecklistStatus';

const ActivityChecklist = ({
  organization,
  checklists,
  getChecklistData,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const [viewChecklist, setViewChecklist] = useState(false);
  const [checklistDetail, setChecklistDetail] = useState(false);
  const [componentsToDelete, setComponentsToDelete] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const DeleteFieldBody = ({ text }) => {
    return (
      <div>
        <div className="d-flex justify-content-center align-items-center">
          <MaterialIcon icon="report_problem" clazz="font-size-4em" />
        </div>
        <hr />
        <h4>{text}</h4>
        <ul className="list-disc">
          {componentsToDelete.map((item) => (
            <li className="font-weight-medium ml-4" key={item?.checklistId}>
              <p className="mb-1">{item?.checklist?.title}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const handleChecklistView = (item) => {
    setChecklistDetail(item);
    setViewChecklist(true);
  };
  useEffect(() => {
    if (!viewChecklist) {
      overflowing();
    }
  }, [!viewChecklist]);
  const handleOrgChecklistDelete = async () => {
    try {
      await checklistService.checklistOrgDelete(
        componentsToDelete[0]?.organizationChecklistId
      );
      setSuccessMessage('Checklist UnAssigned');
      getChecklistData();
    } catch (e) {
      console.log(e);
    } finally {
      setOpenDeleteModal(false);
    }
  };
  const removeItem = (item) => {
    setComponentsToDelete([item]);
    setOpenDeleteModal(true);
  };
  return (
    <div className="min-h-120">
      {checklists?.map((item) => (
        <>
          <div
            className={`cursor-pointer p-3 hover-actions bg-hover-gray ${
              checklists?.length > 1 ? 'border-bottom' : ''
            }`}
          >
            <div className="d-flex align-items-start gap-2">
              <MaterialIcon
                icon="checklist"
                clazz="fs-4"
                onClick={() => handleChecklistView(item)}
              />
              <div
                className="flex-fill"
                onClick={() => handleChecklistView(item)}
              >
                <div>
                  <div
                    className="d-flex gap-1 align-items-center"
                    onClick={() => handleChecklistView(item)}
                  >
                    <p className="mb-0 text-capitalize fw-bold">
                      {item?.checklist?.title}
                    </p>
                    <span className="text-muted font-size-xs">
                      {moment(item?.checklist?.createdAt).format(
                        DATE_FORMAT_TIME_WO_SEC
                      )}
                    </span>
                    {item?.user?.name ? (
                      <>
                        <span className="mx-1">•</span>
                        <span className="font-size-xs">{item?.user?.name}</span>
                      </>
                    ) : (
                      <></>
                    )}

                    <span className="mx-1">•</span>
                    <span className="font-size-xs">{organization?.name}</span>
                  </div>
                </div>
                <div>
                  <p
                    className={`font-size-sm2 mb-0 d-flex align-items-center ${
                      item?.organizationChecklistProgresses?.every(
                        (progress) => progress?.progress < 100
                      ) && checkDueDate(item?.initialDueDate) === 'text-red'
                        ? 'text-red'
                        : ''
                    }`}
                  >
                    {item?.organizationChecklistProgresses?.every(
                      (progress) => progress?.progress < 100
                    ) &&
                      checkDueDate(item?.initialDueDate) === 'text-red' && (
                        <TooltipComponent title="Not completed">
                          <MaterialIcon
                            filled
                            clazz="text-size-md position-relative"
                            icon="flag"
                          />
                        </TooltipComponent>
                      )}
                    {moment(item?.initialDueDate).format(DATE_FORMAT)}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2">
                <ChecklistStatus
                  item={{
                    status: item?.organizationChecklistProgresses?.every(
                      (progress) => progress?.progress === 100
                    )
                      ? ChecklistStatuses.Completed.text
                      : item?.organizationChecklistProgresses?.every(
                          (progress) =>
                            progress?.progress > 0 &&
                            item?.organizationChecklistProgresses?.every(
                              (progress) => progress?.progress < 100
                            )
                        )
                      ? ChecklistStatuses.InProgress.text
                      : item?.organizationChecklistProgresses?.every(
                          (progress) => progress?.progress === 0
                        )
                      ? ChecklistStatuses.NotStarted.text
                      : '',
                  }}
                />
                {item?.organizationChecklistId &&
                  isPermissionAllowed('checklists', 'delete') && (
                    <MoreActions
                      icon="more_vert"
                      items={[
                        {
                          id: 'remove',
                          icon: 'delete',
                          name: 'Delete',
                          permission: {
                            collection: 'checklists',
                            action: 'delete',
                          },
                        },
                      ]}
                      onHandleRemove={(e) => {
                        removeItem(item);
                      }}
                      toggleClassName="w-auto p-0 h-auto"
                    />
                  )}
              </div>
            </div>
          </div>
        </>
      ))}
      <DeleteConfirmationModal
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        setSelectedCategories={setComponentsToDelete}
        event={handleOrgChecklistDelete}
        itemsConfirmation={componentsToDelete}
        itemsReport={[]}
        customBody={
          <DeleteFieldBody text="Are you sure you want delete following checklist?" />
        }
      />
      {viewChecklist && isPermissionAllowed('checklists', 'view') && (
        <ViewChecklist
          openModal={viewChecklist}
          getChecklistData={getChecklistData}
          setOpenModal={setViewChecklist}
          checklist={checklistDetail}
          setSuccessMessage={setSuccessMessage}
          organization={organization}
          setErrorMessage={setErrorMessage}
        />
      )}
    </div>
  );
};

export default ActivityChecklist;

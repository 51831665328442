import React, { useEffect, useState } from 'react';
import auditService from '../../services/audit.service';
import moment from 'moment';
import { TransitionGroup } from 'react-transition-group';
import MaterialIcon from '../commons/MaterialIcon';
import Skeleton from 'react-loading-skeleton';
import ButtonIcon from '../commons/ButtonIcon';
import Collapse from '@mui/material/Collapse';
import { DATE_FORMAT, DATE_FORMAT_TIME, capitalize } from '../../utils/Utils';
import NoDataFound from '../commons/NoDataFound';
import userService from '../../services/user.service';
import contactService from '../../services/contact.service';
import { useParams } from 'react-router-dom';
import { usePagesContext } from '../../contexts/pagesContext';

const AuditActivityTimeline = ({
  resourceId,
  parentResourceId,
  extraFilters = {},
  checklist,
  organization,
  timelineExport,
  customClass,
  closeSidePanel = () => {},
  refresh,
}) => {
  const [auditLogs, setAuditLogs] = useState([]);
  const [auditData, setAuditData] = useState([]);

  const [loading, setLoading] = useState(false);
  const { pageContext } = usePagesContext();
  const [csvLoading, setCSVLoading] = useState(false);
  const { organizationId } = useParams();
  const [feedPagination, setFeedPagination] = useState({ page: 1, limit: 10 });
  const [paginationLoader, setPaginationLoader] = useState(false);

  const mergeAudits = (setOne, setTwo) => {
    const combinedSet = [...setOne];
    for (let i = 0; i < setTwo.length; i++) {
      const date = setTwo[i].date;
      const matchingIndex = combinedSet.findIndex((data) => data.date === date);
      if (matchingIndex !== -1) {
        combinedSet[matchingIndex].logs = [
          ...combinedSet[matchingIndex].logs,
          ...setTwo[i].logs,
        ];
      } else {
        combinedSet.push(setTwo[i]);
      }
    }
    return combinedSet;
  };

  const fetchAudits = async () => {
    if (!paginationLoader) {
      setLoading(true);
    }
    try {
      const params = {};
      if (resourceId !== '') {
        params.resourceId = resourceId;
      }
      if (parentResourceId !== '') {
        params.parentResourceId = parentResourceId;
      }
      Object.assign(params, extraFilters);
      const { data, pagination } = await auditService.getAudits(
        params,
        feedPagination
      );
      setAuditData(data);
      // Filter out items with resourceType
      const filteredData = data.filter(
        (entry) =>
          entry.resourceType !== 'external' &&
          !(
            entry.resourceType === 'organizationChecklistItemProgress' &&
            entry.action === 'create'
          ) &&
          !(
            entry.resourceType === 'organizationChecklistProgress' &&
            entry.action === 'create'
          ) &&
          !(
            entry.resourceType === 'organizationChecklistItemProgress' &&
            entry.action === 'update'
          )
      );

      // Grouping feed data on createdAt
      const groupedData = filteredData.reduce((groups, item) => {
        const date = item.createdAt.split('T')[0];
        const existingGroup = groups.find((group) => group.date === date);
        if (existingGroup) {
          existingGroup.logs.push(item);
        } else {
          groups.push({ date, logs: [item] });
        }
        return groups;
      }, []);

      if (paginationLoader) {
        const mergedLogs = mergeAudits(auditLogs, groupedData);
        setAuditLogs(mergedLogs);
      } else {
        setAuditLogs(groupedData);
      }
      setFeedPagination(pagination);
    } catch (error) {
      console.error('Error fetching audits:', error);
    } finally {
      setLoading(false);
      setPaginationLoader(false);
    }
  };

  useEffect(() => {
    fetchAudits();
  }, [
    feedPagination?.page,
    organizationId,
    refresh,
    pageContext?.RefreshTimeline,
  ]);

  const AuditResourceTypes = {
    activity: { name: 'activity', icon: 'phone' },
    activityRequest: { name: 'activity request', icon: 'mail' },
    activityOwner: { name: 'activity owner', icon: 'person' },
    assignment: { name: 'assignment', icon: 'Assignment' },
    checklist: { name: 'checklist', icon: 'Checklist' },
    checklistItem: { name: 'checklist item', icon: 'Checklist' },
    contact: { name: 'contact', icon: 'person' },
    contactFollower: { name: 'contact follower', icon: 'person' },
    contactOwner: { name: 'Owner', icon: 'person' },
    course: { name: 'course', icon: 'book_2' },
    deal: { name: 'opportunity', icon: 'monetization_on' },
    dealFollower: { name: 'opportunity dollower', icon: 'person' },
    dealOwner: { name: 'owner', icon: 'person' },
    external: { name: 'External', icon: 'link' },
    field: { name: 'field', icon: 'text_fields' },
    file: { name: 'file', icon: 'attachment' },
    note: { name: 'note', icon: 'insert_comment' },
    organization: { name: 'organization', icon: 'corporate_fare' },
    organizationChecklist: {
      name: 'checklist',
      icon: 'Checklist',
    },
    organizationChecklistContact: {
      name: 'Organization Checklist Contact',
      icon: 'person',
    },
    organizationFollower: { name: 'Organization Follower', icon: 'person' },
    organizationOwner: { name: 'owner', icon: 'person' },
    organizationReport: { name: 'Organization Report', icon: 'Report' },
    product: { name: 'product', icon: 'Inventory' },
    report: { name: 'report', icon: 'Report' },
    team: { name: 'team', icon: 'groups' },
    teamMember: { name: 'teamMember', icon: 'person' },
    user: { name: 'user', icon: 'person' },
    comment: { name: 'comment', icon: 'insert_comment' },
    category: { name: 'category', icon: 'Category' },
    organizationChecklistProgress: {
      name: 'Organization Checklist Progress',
      icon: 'person_raised_hand',
    },
    organizationChecklistItemProgress: {
      name: 'Organization Checklist Item Progress',
      icon: 'gesture_select',
    },
    organizationChecklistOwner: {
      name: 'owner',
      icon: 'person',
    },
    organizationChecklistFile: {
      name: 'file',
      icon: 'upload_file',
    },
    dealProduct: {
      name: 'opportunity product',
      icon: 'shopping_cart',
    },
    stage: {
      name: 'stage',
      icon: 'stacked_bar_chart',
    },
  };

  const TimelineHead = ({ createdAt }) => {
    return (
      <div className="timeline-head font-weight-medium">
        {moment(createdAt).format('MMM DD, YYYY')}{' '}
      </div>
    );
  };
  const findObjectsByType = (data, targetType) => {
    return data.filter((obj) => obj.type === targetType);
  };
  const ChangeLog = ({ item }) => {
    const { changeLog } = item;
    const { associations } = changeLog;

    const firstPart = associations.filter((asc) => asc.type !== 'parent');
    const secondPart = associations.filter((asc) => asc.type === 'parent');

    const splitActorName = item?.actorDisplayValue
      ? item.actorDisplayValue.split(' ')
      : [];
    const actorName =
      splitActorName.length > 1
        ? capitalize(splitActorName[0]) + ' ' + capitalize(splitActorName[1])
        : '';

    const actorMessage = (
      <>
        <span className="text-black font-weight-semi-bold">{actorName}</span>{' '}
        {item.action + 'd'} {''}
        {item.resourceType && item.resourceType !== undefined
          ? AuditResourceTypes[item?.resourceType]?.name
          : ''}
      </>
    );

    const associationMsg = firstPart
      .reduce((acc, curr) => {
        const action = curr.type;
        const name = curr.resources[0].displayValue;
        acc.push(`${action} ${name}`);
        return acc;
      }, [])
      .join(', ');

    let pTypeValue;
    let associationResources = '';
    if (secondPart.length > 0 && secondPart[0].resources.length > 0) {
      associationResources = secondPart[0].resources
        .reduce((acc, curr) => {
          const name = curr.displayValue;
          pTypeValue = curr.type; // Set pTypeValue here
          acc.push(name);
          return acc;
        }, [])
        .join(', ');
    }

    const checklistItem = findObjectsByType(
      associations[0].resources,
      'checklistItem'
    );
    const checklist = findObjectsByType(associations[0].resources, 'checklist');
    const organization = findObjectsByType(
      associations[0].resources,
      'organization'
    );
    const contact = findObjectsByType(associations[0].resources, 'contact');
    const organizationChecklist = findObjectsByType(
      associations[0].resources,
      'organizationChecklist'
    );
    return (
      <>
        {item.resourceType === 'organizationChecklistItemProgress' &&
        item.action === 'complete' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  {changeLog?.update?.status?.toDisplayValue ===
                  'acknowledged' ? (
                    <MaterialIcon symbols={true} icon={'visibility'} />
                  ) : changeLog?.update?.status?.toDisplayValue ===
                    'uploaded' ? (
                    <MaterialIcon symbols={true} icon={'description'} />
                  ) : changeLog?.update?.status?.toDisplayValue === 'signed' ? (
                    <MaterialIcon symbols={true} icon={'draw'} />
                  ) : changeLog?.update?.status?.toDisplayValue ===
                    'watched' ? (
                    <MaterialIcon symbols={true} icon={'play_circle'} />
                  ) : (
                    ''
                  )}

                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      completed {checklistItem[0]?.displayValue} on checklist{' '}
                      {checklist[0]?.displayValue} in company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0]?.displayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'organizationChecklistContact' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon
                    symbols={true}
                    icon={AuditResourceTypes[item.resourceType]?.icon}
                  />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      {item.action === 'delete' ? 'Unassigned' : 'Assigned'}{' '}
                      {contact[0]?.displayValue}{' '}
                      {item.action === 'delete' ? 'from' : 'to'}{' '}
                      {checklist[0]?.displayValue} in company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0]?.displayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'organizationChecklist' &&
          item.action === 'delete' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon symbols={true} icon={'playlist_remove'} />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      {item.action + 'd'} a {checklist[0].type}{' '}
                      {checklist[0].displayValue} from company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0].displayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'organizationChecklist' &&
          item.action === 'create' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon symbols={true} icon={'playlist_add'} />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      {item.action + 'd'} a checklist{' '}
                      {checklist[0].displayValue} in company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0].displayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'organizationChecklistOwner' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon
                    symbols={true}
                    icon={AuditResourceTypes[item.resourceType]?.icon}
                  />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      {item.action + 'd'} an owner on Checklist{' '}
                      {checklist[0].displayValue} in company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0].displayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'organizationChecklistFile' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon
                    symbols={true}
                    icon={AuditResourceTypes[item.resourceType]?.icon}
                  />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      uploaded a file in Checklist {checklist[0].displayValue}{' '}
                      of company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0].displayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'note' &&
          item.resourceDisplayValue === 'note mention' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon
                    symbols={true}
                    icon={AuditResourceTypes[item.resourceType]?.icon}
                  />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      {actorMessage} {associationMsg} on{' '}
                      {AuditResourceTypes[pTypeValue]?.name}{' '}
                      <span className="text-black font-weight-semi-bold">
                        {associationResources}
                      </span>{' '}
                      {organizationChecklist[0]?.displayValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {item.resourceDisplayValue !== 'note direct mention' &&
            item.resourceType !== 'checklistItem' &&
            item.resourceType !== 'organizationChecklistProgress' &&
            !item?.changeLog?.update?.tenant_deal_stage_id &&
            !item?.changeLog?.update?.status &&
            item?.resourceType !== 'dealProduct' ? (
              <div className="timeline-section">
                <div className="timeline-item">
                  <span className="timeline-item-time text-muted">
                    {moment(item.createdAt).format('h:mm A')}
                  </span>
                  <div className="timeline-item-detail">
                    <div className="d-flex align-items-center position-relative gap-2">
                      <MaterialIcon
                        symbols={true}
                        icon={AuditResourceTypes[item.resourceType]?.icon}
                      />
                      <div className="timeline-item-description font-size-sm">
                        <div className="text-wrap">
                          {actorMessage} {associationMsg}{' '}
                          {item?.resourceType === 'pipelineStage'
                            ? 'stage'
                            : ''}{' '}
                          on {AuditResourceTypes[pTypeValue]?.name}{' '}
                          <span className="text-black font-weight-semi-bold">
                            {associationResources}
                          </span>{' '}
                          {organizationChecklist[0]?.displayValue}
                        </div>
                        <div className="text-muted text-break">
                          {item.resourceDisplayValue}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {item?.changeLog?.update &&
                typeof item?.changeLog?.update === 'object' &&
                Object.keys(item?.changeLog?.update)?.length > 0 &&
                item?.resourceType !== 'dealProduct' ? (
                  <div className="timeline-section">
                    <div className="timeline-item">
                      <span className="timeline-item-time text-muted">
                        {moment(item.createdAt).format('h:mm A')}
                      </span>
                      <div className="timeline-item-detail">
                        <div className="d-flex align-items-center position-relative gap-2">
                          <MaterialIcon
                            symbols={true}
                            icon={AuditResourceTypes[item.resourceType]?.icon}
                          />
                          <div className="timeline-item-description font-size-sm">
                            {item?.changeLog?.update?.tenant_deal_stage_id ? (
                              <div className="text-wrap">
                                <b>{actorName}</b> {item?.action}d{' '}
                                <b>
                                  {
                                    item?.changeLog?.update
                                      ?.tenant_deal_stage_id?.displayValue
                                  }
                                </b>
                                {' to '}
                                <b>
                                  {
                                    item?.changeLog?.update
                                      ?.tenant_deal_stage_id?.toDisplayValue
                                  }
                                </b>
                                {' on '}
                                <span className="text-black font-weight-semi-bold">
                                  {associationResources}
                                </span>{' '}
                                {organizationChecklist[0]?.displayValue}
                              </div>
                            ) : item?.changeLog?.update?.status ? (
                              <div className="text-wrap">
                                <b>{actorName}</b> {item?.action}d{' '}
                                {item?.changeLog?.update?.status?.displayValue}
                                {' deal '}
                                <b>
                                  {
                                    item?.changeLog?.update?.status
                                      ?.toDisplayValue
                                  }
                                </b>
                                {' on '}
                                <span className="text-black font-weight-semi-bold">
                                  {associationResources}
                                </span>{' '}
                                {organizationChecklist[0]?.displayValue}
                              </div>
                            ) : (
                              ''
                            )}
                            <div className="text-muted text-break">
                              {item.resourceDisplayValue}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {item?.resourceType === 'dealProduct' ? (
                      <div className="timeline-section">
                        <div className="timeline-item">
                          <span className="timeline-item-time text-muted">
                            {moment(item.createdAt).format('h:mm A')}
                          </span>
                          <div className="timeline-item-detail">
                            <div className="d-flex align-items-center position-relative gap-2">
                              <MaterialIcon
                                symbols={true}
                                icon={
                                  AuditResourceTypes[item.resourceType]?.icon
                                }
                              />
                              <div className="timeline-item-description font-size-sm">
                                <div className="text-wrap">
                                  <b>{actorName}</b> {item?.action}d a deal
                                  product on{' '}
                                  <span className="text-black font-weight-semi-bold">
                                    {associationResources}
                                  </span>{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const TimelineItem = ({ item }) => {
    const splitActorName = item?.actorDisplayValue
      ? item.actorDisplayValue.split(' ')
      : [];
    const actorName =
      splitActorName.length > 1
        ? capitalize(splitActorName[0]) + ' ' + capitalize(splitActorName[1])
        : '';
    return (
      <>
        {item?.changeLog !== null && item?.changeLog?.associations ? (
          <ChangeLog item={item} />
        ) : (
          <>
            {item.resourceType === 'file' && item.action === 'create' ? (
              <div className="timeline-section">
                <div className="timeline-item">
                  <span className="timeline-item-time text-muted">
                    {moment(item.createdAt).format('h:mm A')}
                  </span>
                  <div className="timeline-item-detail">
                    <div className="d-flex align-items-center position-relative gap-2">
                      <MaterialIcon
                        symbols={true}
                        icon={AuditResourceTypes[item.resourceType]?.icon}
                      />
                      <div className="timeline-item-description font-size-sm">
                        <div className="text-wrap">
                          <span className="text-black font-weight-semi-bold">
                            {actorName}
                          </span>{' '}
                          upload a {AuditResourceTypes[item.resourceType]?.name}
                        </div>
                        <div className="text-muted text-break">
                          {item.resourceDisplayValue !== null
                            ? item.resourceDisplayValue
                            : item.resourceDisplayValue}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {typeof item?.changeLog?.update?.isActive === 'object' ? (
                  <div className="timeline-section">
                    <div className="timeline-item">
                      <span className="timeline-item-time text-muted">
                        {moment(item.createdAt).format('h:mm A')}
                      </span>
                      <div className="timeline-item-detail">
                        <div className="d-flex align-items-center position-relative gap-2">
                          <MaterialIcon
                            symbols={true}
                            icon={AuditResourceTypes[item.resourceType]?.icon}
                          />
                          <div className="timeline-item-description font-size-sm">
                            <div className="text-wrap">
                              <b>{actorName}</b>{' '}
                              {item?.changeLog?.update?.isActive
                                ?.toDisplayValue === false
                                ? 'deactivated'
                                : 'activated'}{' '}
                              <span className="text-black font-weight-semi-bold">
                                {item.resourceDisplayValue !== null
                                  ? item.resourceDisplayValue
                                  : item.resourceDisplayValue}{' '}
                              </span>{' '}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="timeline-section">
                    <div className="timeline-item">
                      <span className="timeline-item-time text-muted">
                        {moment(item.createdAt).format('h:mm A')}
                      </span>
                      <div className="timeline-item-detail">
                        <div className="d-flex align-items-center position-relative gap-2">
                          <MaterialIcon
                            symbols={true}
                            icon={AuditResourceTypes[item.resourceType]?.icon}
                          />
                          <div className="timeline-item-description font-size-sm">
                            <div className="text-wrap">
                              <span className="text-black font-weight-semi-bold">
                                {actorName}
                              </span>{' '}
                              {item.action}d{' '}
                              {AuditResourceTypes[item.resourceType]?.name}
                            </div>
                            <div className="text-muted text-break">
                              {item.resourceDisplayValue !== null
                                ? item.resourceDisplayValue
                                : item.resourceDisplayValue}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const headers = [
    {
      key: 'Date / Time',
      value: 'createdAt',
    },
    {
      key: 'Name',
      value: 'actorDisplayValue',
    },
    {
      key: 'Email',
      value: 'email',
    },
    {
      key: 'Checklist Type',
      value: 'actorType',
    },
    {
      key: 'Checklist Item',
      value: 'checklistItem',
    },
    {
      key: 'Action Status',
      value: 'changeLog',
    },
  ];

  const exportDataToCSV = async (data, companyName, checklistName) => {
    setCSVLoading(true);
    try {
      const flatLogs = data.flatMap((item) => item.logs); // Flatten the array of logs
      const rowPromises = flatLogs.map(async (log) => {
        let contactData = {};
        if (log?.actorType === 'contact') {
          contactData = await contactService.getContactById(log?.actorId);
        } else {
          contactData = await userService.getUserById(log?.actorId);
        }
        const rowValues = headers.map((header) => {
          let value = log;

          if (header.value === 'actorType') {
            value = ['acknowledged', 'uploaded', 'signed', 'watched'].includes(
              value?.changeLog?.update?.status?.toDisplayValue
            )
              ? value && value[header.value] === 'user'
                ? 'Internal'
                : value && value[header.value] === 'contact'
                ? 'External'
                : '-'
              : '-';
          } else if (header.value === 'email') {
            value = contactData?.email || contactData?.email_home || '-';
          } else if (header.value === 'createdAt') {
            value =
              value && value[header.value]
                ? moment(value[header.value]).format(DATE_FORMAT_TIME)
                : '-';
          } else if (header.value === 'changeLog') {
            value =
              value &&
              ['acknowledged', 'uploaded', 'signed', 'watched'].includes(
                value?.changeLog?.update?.status?.toDisplayValue
              )
                ? 'Completed'
                : value?.resourceType === 'organizationChecklistFile' &&
                  value?.action === 'create'
                ? `File uploaded`
                : value?.resourceType === 'note'
                ? `Note ${value?.action}`
                : value?.resourceType === 'organizationChecklistContact'
                ? `Contact ${
                    value?.action === 'create' ? 'assigned' : 'unassigned'
                  }`
                : value?.resourceType === 'organizationChecklistFile' &&
                  value?.action === 'delete'
                ? 'File deleted'
                : value?.resourceType === 'organizationChecklistOwner' &&
                  value?.action === 'create'
                ? 'Owner Created'
                : '';
          } else if (header.value === 'checklistItem') {
            if (
              value &&
              value?.changeLog?.associations &&
              value?.changeLog?.associations[0]?.type === 'parent'
            ) {
              const checklistResource =
                value?.changeLog?.associations[0]?.resources?.find(
                  (resource) => resource.type === 'checklistItem'
                );
              value = checklistResource ? checklistResource.displayValue : '-';
            } else {
              value = '-';
            }
          } else {
            const nestedKeys = header.value.split('.');
            nestedKeys.forEach((key) => {
              if (value && key in value) {
                value = value[key];
              } else {
                value = '-';
              }
            });
          }
          return value;
        });
        return rowValues.join(',');
      });

      // Filter out undefined values from rowPromises
      const filteredRowPromises = rowPromises.filter(
        (rowPromise) => rowPromise
      );

      // Ensure all promises resolve
      const csvRows = await Promise.all(filteredRowPromises);

      const companyTitle = [{ Title: 'Company Name', Value: `${companyName}` }];
      const checklistTitle = [
        { Title: 'Checklist Name', Value: `${checklistName}` },
      ];
      const csvContent =
        checklistTitle?.map((obj) => Object.values(obj).join(',')).join('\n') +
        '\n' +
        companyTitle?.map((obj) => Object.values(obj).join(',')).join('\n') +
        '\n' +
        '\n' +
        headers.map((header) => `${header.key}`).join(',') +
        '\n' +
        csvRows?.join('\n');

      // Create a Blob with the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      const CurrentDate = moment(new Date()).format(DATE_FORMAT);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute(
        'download',
        `${companyName}-${checklistName}-${CurrentDate}.csv`
      );
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    } finally {
      setCSVLoading(false);
    }
  };

  return (
    <>
      <div className="overflow-hidden px-3 tab-item">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className={`mb-3 ${customClass}`}>History</h4>
          {timelineExport && (
            <ButtonIcon
              label="Export"
              onclick={() =>
                exportDataToCSV(auditLogs, organization?.name, checklist?.title)
              }
              loading={csvLoading}
              color="outline-primary"
              classnames="btn-sm mb-2"
            />
          )}
        </div>
        {loading ? (
          <div>
            <Skeleton count={3} height={10} className={'mb-2'} />
          </div>
        ) : (
          <>
            <div className="timeline-wrapper mb-4">
              <ul className="timeline-list">
                {auditLogs.map((group, index) => (
                  <div className="timeline-section" key={index}>
                    <TransitionGroup appear={true}>
                      <TimelineHead createdAt={group.date} />{' '}
                      {group.logs.map((item) => (
                        <Collapse key={item.auditId}>
                          <TimelineItem
                            item={item}
                            closeSidePanel={closeSidePanel}
                          />
                        </Collapse>
                      ))}
                    </TransitionGroup>
                  </div>
                ))}
              </ul>
              {auditData?.length === feedPagination?.limit &&
                feedPagination.page < feedPagination?.totalPages && (
                  <ButtonIcon
                    classnames="view-more-btn button-pill btn-pill btn-sm"
                    color="primary"
                    label="View more"
                    loading={paginationLoader}
                    onclick={() => {
                      setPaginationLoader(true);
                      setFeedPagination((prevState) => ({
                        ...prevState,
                        page: prevState.page + 1,
                      }));
                    }}
                  />
                )}
            </div>
          </>
        )}
        {!loading && parentResourceId && auditLogs.length === 0 && (
          <NoDataFound
            icon="edit_note"
            iconStyle="font-size-3em"
            containerStyle="text-gray-search my-1 py-1"
            title={'No timeline items'}
          />
        )}
      </div>
    </>
  );
};

export default AuditActivityTimeline;

import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import Search from '../../manageUsers/Search';
import teamsService from '../../../services/teams.service';
import assignmentService from '../../../services/assignment.service';
import Skeleton from 'react-loading-skeleton';
import MaterialIcon from '../../commons/MaterialIcon';
import NoDataFound from '../../commons/NoDataFound';
import userService from '../../../services/user.service';
import ButtonIcon from '../../commons/ButtonIcon';

const AssigneesTab = ({
  onAssigneesDataChange,
  dataToEdit,
  isEdit,
  selectedAssignees,
  setSelectedAssignees,
}) => {
  const defaultPagination = { page: 1, limit: 15 };
  const [pagination, setPagination] = useState(defaultPagination);
  const [peopleTeams, setPeopleTeams] = useState('people');
  const [search, setSearch] = useState('');
  const [loader, setLoader] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const handleAssigneeSelection = (assigneeName, itemType, itemID) => {
    const selectedItem = selectedAssignees.find((item) => {
      if (itemType === 'team') {
        return item.teamId === itemID && item.type === itemType;
      } else {
        return item.userId === itemID && item.type === itemType;
      }
    });

    if (selectedItem) {
      const data = selectedAssignees.filter((item) => item !== selectedItem);
      setSelectedAssignees(data);
      onAssigneesDataChange(data);
    } else {
      let itemToAdd = {};
      if (itemType === 'team') {
        itemToAdd = { teamId: itemID, name: assigneeName, type: itemType };
      } else {
        itemToAdd = { userId: itemID, name: assigneeName, type: itemType };
      }

      setSelectedAssignees([itemToAdd, ...selectedAssignees]);
      onAssigneesDataChange([itemToAdd, ...selectedAssignees]);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const [peopleResponse, teamsResponse] = await Promise.all([
        userService.getUsers(
          { status: 'active', search },
          {
            page: pagination.page,
            limit: pagination.limit,
          }
        ),
        teamsService.getTeams({
          page: pagination.page,
          limit: pagination.limit,
          search,
          isActive: true,
        }),
      ]);
      const mergedPeopleTeamsData = [
        ...peopleResponse?.data?.users.map((item) => ({
          ...item,
          type: 'people',
        })),
        ...teamsResponse?.data.map((item) => ({ ...item, type: 'team' })),
      ];

      setPagination(
        peopleTeams === 'people'
          ? peopleResponse?.data?.pagination
          : teamsResponse?.pagination
      );

      const filteredData = mergedPeopleTeamsData.filter((item) =>
        peopleTeams.includes(item.type)
      );

      setLoader(false);

      if (loadMore) {
        const uniqueData = new Set([...assignees, ...filteredData]);
        const uniqueUsersTeams = [...uniqueData];
        setAssignees(uniqueUsersTeams);
      } else {
        setAssignees(filteredData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [search, peopleTeams, pagination?.page]);

  useEffect(() => {
    if (isEdit) {
      const fetchAllPeopleTeams = async () => {
        // Use Promise.all to make both API calls in parallel
        const [peopleResponse, teamsResponse] = await Promise.all([
          userService.getUsers(
            { status: 'active', search },
            { page: pagination.page, limit: 1000 }
          ),
          teamsService.getTeams({
            page: pagination.page,
            limit: 1000,
            isActive: true,
          }),
        ]);
        const mergedPeopleTeams = [
          ...peopleResponse?.data?.users,
          ...teamsResponse?.data,
        ];

        const getTeams = await assignmentService.getTeamAssignment(
          dataToEdit.assignmentId,
          {
            page: pagination.page,
            limit: pagination.limit,
          }
        );
        const getUsers = await assignmentService.getUserAssignments(
          dataToEdit.assignmentId,
          {
            page: pagination.page,
            limit: 1000, // set default limit to 100 for hot fix, we have to load more on scroll
          }
        );

        function findObjectsByKey(
          mainArray,
          objectsToFind,
          key,
          type,
          nameProperty
        ) {
          return mainArray
            .map((item1) => {
              const matchedObject = objectsToFind.find(
                (item2) => item2[key] === item1.id
              );
              return matchedObject
                ? { name: item1[nameProperty], [key]: item1.id, type }
                : null;
            })
            .filter(Boolean);
        }

        const matchedTeams = findObjectsByKey(
          mergedPeopleTeams,
          getTeams.data,
          'teamId',
          'team',
          'name'
        );
        const matchedUsers = findObjectsByKey(
          mergedPeopleTeams,
          getUsers.data,
          'userId',
          'people',
          'name'
        );
        setSelectedAssignees([...matchedTeams, ...matchedUsers]);
      };

      fetchAllPeopleTeams();
    }
  }, []);

  return (
    <div className="content-box-wrp">
      <h2 className="mb-4">Who should be assigned?</h2>
      <ButtonGroup className="p-0 modal-report-tabs gap-1">
        <Button
          color="primary"
          outline
          onClick={() => {
            if (peopleTeams !== 'people') {
              setSearch('');
              setAssignees([]);
              setPeopleTeams('people');
              setPagination(defaultPagination);
            }
          }}
          active={peopleTeams === 'people'}
          className="rounded-pill border-0"
        >
          People
        </Button>
        <Button
          color="primary"
          outline
          onClick={() => {
            if (peopleTeams !== 'team') {
              setSearch('');
              setAssignees([]);
              setPeopleTeams('team');
              setPagination(defaultPagination);
            }
          }}
          active={peopleTeams === 'team'}
          className="rounded-pill border-0"
        >
          Teams
        </Button>
      </ButtonGroup>

      <Search
        classnames="col-xs col-md-12 p-0 mb-2 mt-3"
        searchPlaceholder={'Search people and teams'}
        value={search}
        onHandleChange={(e) => {
          if (e.target.value !== '') {
            setLoadMore(false);
            setPeopleTeams(['people', 'team']);
          } else {
            setLoadMore(true);
            setAssignees([]);
            setPeopleTeams('people');
          }
          setPagination(defaultPagination);
          setSearch(e.target.value);
        }}
      />
      {loading ? (
        <div className="pt-2">
          <Skeleton count={10} height={20} className={'mb-2'} />
        </div>
      ) : (
        <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
          <div className="card content-box">
            {assignees && assignees.length > 0 ? (
              assignees.map((singleAssignee, index) => {
                const assigneeName = singleAssignee.name;
                const itemId = singleAssignee.id;
                let itemType = '';
                if (Array.isArray(peopleTeams)) {
                  itemType = singleAssignee.type;
                } else {
                  itemType = peopleTeams === 'team' ? 'team' : 'people';
                }

                const isActive = selectedAssignees.find((item) => {
                  if (itemType === 'team') {
                    return item.teamId === itemId && item.type === itemType;
                  } else {
                    return item.userId === itemId && item.type === itemType;
                  }
                });

                return (
                  <div
                    key={index}
                    className={`pl-3 pr-3 pt-2 pb-2 gap-2 border-bottom cursor-pointer d-flex justify-content-between bg-hover-gray text-wrap ${
                      isActive ? 'bg-primary-soft text-primary' : ''
                    }`}
                    onClick={() =>
                      handleAssigneeSelection(assigneeName, itemType, itemId)
                    }
                  >
                    {assigneeName}
                    <div className="d-flex align-items-center gap-1 item-count w-25 justify-content-end flex-shrink-0">
                      {singleAssignee.type === 'people' ? (
                        <small className="text-muted">PEOPLE</small>
                      ) : (
                        <small className="text-muted">
                          TEAM ({singleAssignee.totalUsers} People)
                        </small>
                      )}
                      {isActive && <MaterialIcon clazz="" icon="close" />}
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <NoDataFound
                  icon="sticky_note_2"
                  title={
                    <div className="font-normal font-size-sm2 text-gray-search">
                      Records not found
                    </div>
                  }
                  containerStyle="text-gray-search py-6"
                />
              </>
            )}
          </div>
        </div>
      )}
      {pagination.page < pagination?.totalPages && (
        <ButtonIcon
          classnames="button-pill btn-pill mt-3 btn-sm"
          color="primary"
          label="Load more"
          loading={loader}
          onclick={() => {
            setLoadMore(true);
            setLoader(true);
            setLoading(false);
            setPagination((prevState) => ({
              ...prevState,
              page: prevState.page + 1,
            }));
          }}
        />
      )}
    </div>
  );
};

export default AssigneesTab;

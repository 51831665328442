import React, { useEffect, useState } from 'react';

import FullScreenSpinner from '../components/FullScreeenSpinner';
import { useTenantContext } from '../contexts/TenantContext';
import BrandLogoIcon from '../components/sidebar/BrandLogoIcon';
import useTenantTheme from '../hooks/useTenantTheme';
import AppVersion from '../components/commons/AppVersion';
import useIsCommonLogin from '../hooks/useIsCommonLogin';

export const PublicLayout = ({ children, title }) => {
  const [mounted, setMounted] = useState(false);
  const { isCommonLogin } = useIsCommonLogin();
  const { tenant } = useTenantContext();

  useTenantTheme();

  useEffect(() => {
    tenant?.id && !mounted && setMounted(true);
  }, [tenant]);

  if (mounted || isCommonLogin) {
    return (
      <main id="content" role="main" className="main public-layout">
        <div className="container py-8 py-sm-7">
          <div className="d-flex justify-content-center mt-5 mb-5">
            {isCommonLogin ? (
              <div className="text-center">
                <img
                  className="z-index-2 size-logo-login"
                  src={'/img/logo.svg'}
                  alt={`Identifee Logo`}
                />
              </div>
            ) : (
              <BrandLogoIcon tenant={tenant} height={53} width={160} />
            )}
          </div>
          {title && <h3 className="text-center mb-3">{title}</h3>}
          {children}
        </div>
        <AppVersion color="text-muted" />
      </main>
    );
  } else return <FullScreenSpinner />;
};

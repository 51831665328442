import React, { useEffect, useState } from 'react';
import { Card, CardHeader } from 'reactstrap';
import { ReportSkeletonLoader } from '../../Insights/ReportSkeletonLoader';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import { reportPages } from '../../../utils/constants';
import LessonStatistics from '../../Insights/reportRenders/LessonStatistics';
import { cubeService } from '../../../services';
import DatePickerTraining from '../../../components/dealsBreakdown/DatePickerTraining';
import NoDataFound from '../../../components/commons/NoDataFound';
import { getTomorrowDate } from '../../../utils/Utils';

const TrainingLeassonStatistics = ({
  dashboard,
  selectedComponent = {},
  componentHeight = 'h-100',
}) => {
  const tomorrowDate = getTomorrowDate();

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: tomorrowDate,
  });
  const [reportPage, setReportPage] = useState(reportPages[0]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const getLessonsStats = async () => {
    setLoading(true);
    try {
      const results = await cubeService.loadCustomAnalytics({
        type: 'LessonStatistics',
        ...(reportPage?.key !== 'all'
          ? { limit: parseInt(reportPage.key) }
          : {}),
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
      });
      setData(results);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLessonsStats();
  }, [selectedComponent, dateRange, reportPage]);
  return (
    <Card className={`overflow-x-hidden overflow-y-auto ${componentHeight}`}>
      <CardHeader>
        <div className="d-flex justify-content-between align items center w-100">
          <h4 className="card-title text-hover-primary mb-0 pt-2 pb-2">
            {dashboard?.name}
          </h4>
          <div
            className={`d-flex align-items-center ${
              data?.length > 0 ? 'mr-5 pr-5' : ''
            }`}
          >
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              disablePastDate
            />
            <ButtonFilterDropdown
              buttonText="Timeline"
              options={reportPages}
              filterOptionSelected={reportPage}
              handleFilterSelect={(e, item) => {
                setReportPage(item);
              }}
              btnToggleStyle={`btn-md ${data?.length > 0 ? 'mr-5' : ''}`}
            />
          </div>
        </div>
      </CardHeader>
      <div className="px-0">
        {loading ? (
          <div className="w-100 d-block px-1 py-3">
            <ReportSkeletonLoader rows={15} />
          </div>
        ) : (
          <div className="p-0">
            {data?.length === 0 ? (
              <NoDataFound title="No data found" />
            ) : (
              <LessonStatistics results={data} />
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

export default TrainingLeassonStatistics;

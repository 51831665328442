import { Dropdown, Tab, Tabs } from 'react-bootstrap';
import AutoComplete from '../AutoComplete';
import React, { useState, useEffect } from 'react';
import MaterialIcon from './MaterialIcon';
import ButtonFilterDropdown from './ButtonFilterDropdown';
import checklistService from '../../services/checklist.service';

const ChecklistFilterTabsDropdown = ({
  options,
  filterTabs,
  filterOptionSelected,
  setFilterOptionSelected,
  handleFilterSelect,
  filterSelected,
  setFilterSelected,
  setFilterTabs,
  openFilter,
  setOpenFilter,
  onHandleFilterOrg,
  defaultSelection,
  extraClasses,
}) => {
  const filterKeys = options.map((k) => k.key);
  const [filterSearch, setFilterSearch] = useState({
    title: !filterKeys.includes(filterOptionSelected?.key)
      ? filterOptionSelected?.title || ''
      : '',
  });
  const [usersData, setUsersData] = useState([]);

  const getFilterUsers = async (search = '') => {
    const { data } = await checklistService.getCheckLists({
      limit: 100,
      page: 1,
      ...(search || ''),
    });
    setUsersData(data?.data);
  };

  const onAutocompleteChange = async (e) => {
    const { value } = e.target;
    setFilterSearch({ title: value });
    getFilterUsers(value);
    if (!value) {
      setFilterOptionSelected(defaultSelection);
      setFilterSelected({
        ...filterSelected,
        filter: { checklistId: null },
      });
    }
  };

  useEffect(() => {
    if (filterTabs === 'owners') {
      getFilterUsers();
    }
  }, [filterTabs]);

  return (
    <ButtonFilterDropdown
      filterOptionSelected={filterOptionSelected}
      options={options}
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      handleFilterSelect={handleFilterSelect}
    >
      <Dropdown.Menu
        className={`p-0 z-index-250 ${extraClasses}`}
        style={{ minWidth: 320 }}
      >
        <Tabs
          fill
          justify
          id="controlled-tab-example"
          activeKey={filterTabs}
          onSelect={(k) => {
            setFilterTabs(k);
          }}
          className="mb-1 w-100 idf-tabs"
        >
          <Tab
            eventKey="owners"
            title={
              <span className="d-flex justify-content-center gap-1 align-items-center">
                <MaterialIcon icon="person" /> <span> Checklists </span>
              </span>
            }
          >
            <div className="p-3 position-relative">
              <AutoComplete
                id={'searchForChecklist'}
                placeholder={'Search for Checklist'}
                name={'searchForChecklist'}
                showAvatar={false}
                loading={false}
                onChange={onAutocompleteChange}
                data={usersData}
                showIcon={false}
                onHandleSelect={(item) => {
                  onHandleFilterOrg(item);
                  setFilterSearch(item);
                }}
                customKey="title"
                selected={filterSearch?.title || ''}
              />
              {filterSearch.title && (
                <a
                  onClick={(e) => {
                    onHandleFilterOrg({});
                    setFilterSearch({ title: '' });
                  }}
                  className="position-absolute cursor-pointer icon-hover-bg abs-center-y right-0 mr-4"
                >
                  <MaterialIcon symbols icon="cancel" />
                </a>
              )}
            </div>
          </Tab>

          <Tab
            eventKey="filters"
            title={
              <span className="d-flex justify-content-center gap-1 align-items-center">
                <MaterialIcon icon="filter_list" />
                <span> Filters </span>
              </span>
            }
          >
            <div className="py-1 idf-dropdown-item-list">
              {options.map((option) => (
                <Dropdown.Item
                  key={option.id}
                  href="#"
                  onClick={(e) => handleFilterSelect(e, option)}
                  className="px-3"
                >
                  <div className="d-flex align-items-center justify-content-between py-1">
                    <span
                      className={
                        filterOptionSelected.key === option.key ? 'fw-bold' : ''
                      }
                    >
                      {option.name}
                    </span>
                    {filterOptionSelected.key === option.key && (
                      <MaterialIcon icon="check" clazz="fw-bold" />
                    )}
                  </div>
                </Dropdown.Item>
              ))}
            </div>
          </Tab>
        </Tabs>
      </Dropdown.Menu>
    </ButtonFilterDropdown>
  );
};

export default ChecklistFilterTabsDropdown;

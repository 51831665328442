import ButtonIcon from '../commons/ButtonIcon';
import React from 'react';

const AddNewReportButton = ({
  reports,
  readOnly,
  addView,
  handleManualReport,
}) => {
  return (
    <>
      {!readOnly && !addView ? (
        <>
          {reports.length > 0 ? (
            <ButtonIcon
              icon="add"
              label="Create New Report"
              onclick={handleManualReport}
              classnames="btn-sm"
            />
          ) : (
            <ButtonIcon
              icon="add"
              label="Create New Report"
              onclick={handleManualReport}
              classnames="btn-sm"
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AddNewReportButton;

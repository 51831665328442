import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { overflowing, removeBodyScroll } from '../../utils/Utils';
import ButtonIcon from '../commons/ButtonIcon';
import MaterialIcon from '../commons/MaterialIcon';

export default function SimpleModalCreation({
  children,
  modalTitle,
  modalHeaderClasses,
  open,
  handleSubmit,
  buttonsDisabled,
  onHandleCloseModal,
  errorMessage,
  setErrorMessage,
  isLoading,
  editFields,
  saveButton,
  cancelButtonText = 'Cancel',
  deleteButton,
  deleteButtonLoading,
  successMessage,
  loading,
  setSuccessMessage,
  customModal,
  bodyClassName = 'mb-0 p-3',
  bankTeam = false,
  toggle,
  noFooter = false,
  saveButtonStyle = 'btn-primary',
  footerStyle = 'border-top',
  cancelLoading,
  ...rest
}) {
  return (
    <Modal
      onOpened={() => {
        removeBodyScroll();
      }}
      onClosed={() => {
        overflowing();
      }}
      isOpen={open}
      fade={false}
      {...rest}
      className={customModal}
    >
      <ModalHeader
        tag="h3"
        toggle={onHandleCloseModal}
        className={`${
          modalTitle ? 'p-3 text-capitalize' : 'p-0'
        } ${modalHeaderClasses}`}
      >
        {modalTitle}
      </ModalHeader>
      <ModalBody
        className={`${bodyClassName} ${modalTitle ? 'border-top' : ''}`}
      >
        {children}
      </ModalBody>

      {(bankTeam === false || noFooter === false) && (
        <ModalFooter
          className={`px-3 ${footerStyle} ${
            deleteButton ? 'justify-content-between' : 'justify-content-end'
          }`}
        >
          {deleteButton && (
            <>
              {!deleteButton.type ? (
                <ButtonIcon
                  icon={deleteButton.icon}
                  label={deleteButton.label}
                  loading={deleteButtonLoading}
                  onclick={deleteButton.onClick}
                  color="outline-danger"
                  classnames={`btn-sm pull-left ${
                    deleteButton.show ? '' : 'd-none'
                  }`}
                />
              ) : (
                <>
                  {deleteButton.type === 'label' ? (
                    <div className="d-flex fs-7 text-gray align-items-center gap-1">
                      <MaterialIcon
                        icon={deleteButton.icon}
                        filled
                        rounded
                        clazz="fs-6"
                      />{' '}
                      <span>{deleteButton.text}</span>
                    </div>
                  ) : (
                    <div className="d-flex fs-7 text-gray align-items-center gap-1">
                      <div className="d-flex align-items-center gap-1">
                        <MaterialIcon
                          icon={deleteButton.icon}
                          filled
                          rounded
                          clazz="fs-6"
                        />{' '}
                        <span>{deleteButton.text}</span>
                      </div>
                      <ButtonIcon
                        icon={deleteButton.deleteIcon}
                        label={deleteButton.label}
                        loading={deleteButtonLoading}
                        onclick={deleteButton.onClick}
                        color="outline-danger"
                        classnames={`btn-sm pull-left ${
                          deleteButton.show ? '' : 'd-none'
                        }`}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <div className="d-flex gap-1 justify-content-end align-items-center">
            {onHandleCloseModal && (
              <button
                className="btn btn-white btn-sm"
                data-dismiss="modal"
                onClick={onHandleCloseModal}
                disabled={isLoading}
              >
                {cancelLoading ? (
                  <Spinner className="spinner-grow-xs" />
                ) : (
                  <span>{cancelButtonText}</span>
                )}
              </button>
            )}
            {handleSubmit && (
              <button
                type="button"
                className={`btn btn-sm d-flex justify-content-center min-w-100 ${saveButtonStyle}`}
                onClick={handleSubmit}
                disabled={isLoading || buttonsDisabled}
              >
                {isLoading ? (
                  <Spinner className="spinner-grow-xs" />
                ) : (
                  <span>{editFields ? 'Update' : saveButton || 'Save'}</span>
                )}
              </button>
            )}
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
}

import MaterialIcon from '../commons/MaterialIcon';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { ReportTypes } from './reports.constants';
import useReportHeaderFooter from '../../hooks/useReportHeaderFooter';
import { ReportCovers } from './report.covers';

const ReportTitles = {
  [ReportTypes.Treasury]: 'Treasury Management Report',
  [ReportTypes.WorkingCapital]: 'Working Capital Analysis Report',
  [ReportTypes.Merchant]: 'Merchant Services Insight Report',
  [ReportTypes.Fraud]: 'Fraud Insights Report',
  [ReportTypes.AccountStructure]: 'Account Structure Report',
};
const DynamicSvg = ({ type }) => {
  const svgRef = useRef(null);
  const [coverImage, setCoverImage] = useState(null);

  const calculateColor = (colorType) => {
    const primaryColorHsl = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--primaryColorHsl');
    switch (colorType) {
      case 'verylight':
        return `hsl(${primaryColorHsl}, 85%, 80%)`;
      case 'medium':
        return `hsl(${primaryColorHsl}, 85%, 70%)`;
      case 'dark':
        return `hsl(${primaryColorHsl}, 80%, 30%)`;
      case 'light':
        return `hsl(${primaryColorHsl}, 85%, 60%)`;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (svgRef?.current) {
      svgRef.current.querySelectorAll('[fill]').forEach((path) => {
        const colorType = path.getAttribute('fill');
        const dynamicColor = calculateColor(colorType);
        if (dynamicColor) {
          path.setAttribute('fill', dynamicColor);
        }
      });
      const svgContent = new XMLSerializer().serializeToString(svgRef.current);
      const svg64 = btoa(svgContent);
      const b64start = 'data:image/svg+xml;base64,';
      const image64 = b64start + svg64;
      setCoverImage(image64);
    }
  }, [svgRef]);
  return (
    <>
      {coverImage ? (
        <img
          alt="report-cover"
          src={coverImage}
          style={{
            width: 682,
            height: 892,
            imageRendering: 'crisp-edges',
          }}
        />
      ) : (
        <svg
          ref={svgRef}
          width="793"
          zoomAndPan="magnify"
          viewBox="0 0 594.95996 842.24997"
          height="1122"
        >
          {ReportCovers[type]}
        </svg>
      )}
    </>
  );
};
const ReportCoverPattern = ({ children, type }) => {
  return (
    <div
      className="position-relative border px-0 w-100 bg-white"
      style={{ height: 892 }}
    >
      <DynamicSvg type={type} />
      {children}
    </div>
  );
};

const NaicMidLabel = ({ left, label, value, textColor }) => {
  return (
    <div
      className={`position-absolute text-rpt-heading d-flex align-items-center`}
      style={{ top: '88%', left }}
    >
      <p className={`mb-0 fs-9 font-weight-light ${textColor}`}>{label}:</p>
      <p className={`pl-1 fs-9 font-weight-light ${textColor} mb-0`}>{value}</p>
    </div>
  );
};
const ReportCover = ({
  name,
  date,
  type,
  selectedTenant,
  report,
  organization,
}) => {
  const left = 45;
  const { reportFooterImage } = useReportHeaderFooter(selectedTenant);
  const textColor = 'text-black text-left';
  return (
    <ReportCoverPattern type={type}>
      <img
        src={reportFooterImage}
        className="position-absolute"
        style={{ top: '17%', left, width: 150, objectFit: 'contain' }}
      />
      <div
        className={`position-absolute ${textColor}`}
        style={{ top: '35%', left }}
      >
        <p
          className={`font-weight-medium mb-2 d-flex align-items-center gap-1 ${textColor}`}
        >
          <MaterialIcon icon="calendar_today" clazz={textColor} />{' '}
          <span className="font-size-sm2 ml-2">
            {moment(date).format('MMMM YYYY')}
          </span>
        </p>
        <h1
          className={`font-size-2p5em font-weight-bolder mb-2 ${textColor}`}
          style={{ maxWidth: 350, lineHeight: 1.2 }}
        >
          {ReportTitles[type]}
        </h1>
      </div>
      <div
        className={`position-absolute ${textColor}`}
        style={{ top: '75%', left }}
      >
        <p className={`mb-0 fs-9 font-weight-medium ${textColor}`}>
          Prepared for:
        </p>
        <h4 className={`font-size-md font-weight-semi-bold ${textColor}`}>
          {name}
        </h4>
      </div>
      {report?.mid && (
        <NaicMidLabel
          label="MID"
          value={report?.mid}
          left={left}
          textColor={textColor}
        />
      )}
    </ReportCoverPattern>
  );
};

export default ReportCover;

import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import { Dropdown } from 'react-bootstrap';
import {
  endOfDay,
  startOfMonth,
  endOfMonth,
  endOfYear,
  startOfYear,
  subYears,
  addMonths,
  startOfDay,
} from 'date-fns';

import { DATE_FORMAT, setDateFormat } from '../../utils/Utils';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
const currentDate = new Date();
const defineds = {
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),

  startOfThisYear: startOfYear(new Date()),
  endOfThisYear: endOfYear(new Date()),

  startOfLastYear: startOfYear(subYears(currentDate, 1)),
  endOfLastYear: endOfYear(subYears(currentDate, 1)),

  startOfLast30Days: endOfDay(addMonths(new Date(), -1)),
  endOfLast30Days: startOfDay(new Date()),
};

const DatePicker = ({
  range,
  setRange,
  disablePastDate,
  selectedDate,
  disableDefaultRange,
  extraClass = '',
}) => {
  const [dateRange, setDateRange] = useState({
    startDate:
      range && range.startDate
        ? moment(addMonths(new Date(), -1)).utc().toDate()
        : !disableDefaultRange
        ? moment(new Date(1970, 0, 1)).utc().toDate()
        : undefined,
    endDate:
      range && range.endDate
        ? moment(new Date()).utc().toDate()
        : !disableDefaultRange
        ? defineds.endOfLast30Days
        : defineds.endOfToday,
    key: 'selection',
  });

  const staticRanges = [
    {
      key: 'All Time',
      label: 'All Time',
      startDate: startOfYear(new Date(1970, 0, 1)),
      endDate: endOfDay(new Date()),
    },
    {
      key: 'Last 30 Days',
      label: 'Last 30 Days',
      startDate: defineds.startOfLast30Days,
      endDate: defineds.endOfLast30Days,
    },
    {
      key: 'This Year',
      label: 'This Year',
      startDate: defineds.startOfThisYear,
      endDate: defineds.endOfThisYear,
    },
    {
      key: 'Last Year',
      label: 'Last Year',
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
    },
    {
      key: 'Custom',
      label: 'Custom',
      customRange: true,
    },
  ];
  const [selectDate, setSelectedDate] = useState(
    selectedDate ? staticRanges[1] : staticRanges[0]
  );

  const applyChanges = (item) => {
    setRange({
      startDate: item?.startDate || dateRange.startDate,
      endDate: item?.endDate || dateRange.endDate,
    });
  };

  useEffect(() => {
    applyChanges();
  }, []);

  return (
    <div className="d-flex align-items-center gap-2">
      {selectDate?.customRange && (
        <Dropdown>
          <Dropdown.Toggle
            className={`btn btn-sm btn-ghost-secondary dropdown-toggle border-0 ${extraClass}`}
          >
            <span className="material-symbols-rounded fs-5">date_range</span>
            {range?.startDate && range?.endDate && (
              <span className="js-daterangepicker-predefined-preview ml-1">
                {setDateFormat(range?.startDate, DATE_FORMAT)} -{' '}
                {setDateFormat(range?.endDate, DATE_FORMAT)}
              </span>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu className="pt-0">
            <div className="tests report-date">
              <DateRangePicker
                showDateDisplay={false}
                staticRanges={[]}
                inputRanges={[]}
                rangeColors={['var(--secondaryColor)']}
                ranges={[dateRange]}
                onChange={(item) => {
                  setDateRange({
                    ...item.selection,
                    endDate: endOfDay(item.selection.endDate),
                  });
                }}
                months={1}
                maxDate={disablePastDate ? new Date() : undefined}
                direction="vertical"
              />
              <hr style={{ marginTop: 0, paddingTop: 0 }} />
              <div className="drp-buttons">
                <Dropdown.Item
                  className="d-flex align-items-center gap-2"
                  bsPrefix="deal"
                >
                  <button
                    className="cancelBtn btn btn-sm btn-white"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      applyChanges();
                    }}
                    className="applyBtn btn btn-sm btn-primary"
                    type="button"
                  >
                    Apply
                  </button>
                </Dropdown.Item>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <ButtonFilterDropdown
        buttonText="Date"
        customKeys={['key', 'label']}
        options={staticRanges}
        icon="date_range"
        filterOptionSelected={
          Object.keys(selectDate)?.length > 0 ? selectDate : staticRanges[0]
        }
        handleFilterSelect={(e, item) => {
          setSelectedDate(item);
          applyChanges(item);
        }}
        btnToggleStyle="mr-2"
      />
    </div>
  );
};

DatePicker.defaultProps = {
  disablePastDate: false,
  disableDefaultRange: false,
};

export default DatePicker;

import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import PipelineForm from './PipelineForm';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import dealService from '../../../services/deal.service';
import {
  DEAL_UPDATED,
  SOMETHING_IS_WRONG,
  CANT_UPDATE_OVERVIEW_INFO,
} from '../../../utils/constants';
import fieldService from '../../../services/field.service';
import { groupBy } from 'lodash';
import { removeCustomFieldsFromActivityForm } from '../contacts/utils';

const PipelineOverview = ({
  deal,
  getDeal,
  isPrincipalOwner,
  moduleMap,
  setRefresOwners,
  moduleData,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isFieldsData, setIsFieldsData] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [dealFormData, dispatch] = useState({});
  useEffect(() => {
    dispatch(deal);
  }, [deal]);
  const groupBySection = (fieldsList) => {
    setIsFieldsData(groupBy(fieldsList, 'section'));
  };
  const currentView = 'deal';
  const getFields = async () => {
    const { data } = await fieldService.getFields(currentView, {
      usedField: true,
    });
    groupBySection(data);
  };
  const onHandleSubmit = async (dealFormData, refreshTimeline = () => {}) => {
    const {
      name,
      amount,
      currency,
      tenant_deal_stage_id,
      date_closed,
      assigned_user_id,
      sales_stage,
    } = dealFormData || {};

    const parsedAmount = parseInt(amount);

    const formOverview = {
      name,
      amount: Number.isFinite(parsedAmount) ? parsedAmount : undefined,
      currency,
      tenant_deal_stage_id,
      date_closed: date_closed
        ? moment(date_closed).tz('utc').startOf('day').toISOString()
        : undefined,
      assigned_user_id,
      sales_stage,
    };
    const updateFields = removeCustomFieldsFromActivityForm(
      formOverview,
      customFields
    );
    const resp = await dealService
      .updateDeal(deal.id, updateFields)
      .catch(() => setErrorMessage(SOMETHING_IS_WRONG));
    await Promise.all(
      customFields?.map(async (item) => {
        if (item.value === '$' || item.value === '') {
          await dealService.deleteCustomField(data.id, item.field_id);
        } else {
          await new Promise((resolve) => {
            dealService.updateCustomField(deal?.id, item).then(resolve);
          });
        }
      })
    );
    if (resp?.response?.data?.errors[0]?.message) {
      setEditMode(false);
      return setErrorMessage(CANT_UPDATE_OVERVIEW_INFO);
    }
    const { data } = resp || {};

    if (data.length) {
      setSuccessMessage(DEAL_UPDATED);
      getDeal('', true);
      setEditMode(false);
    }
    refreshTimeline && refreshTimeline();
    setRefresOwners(true);
    setLoading(false);
  };
  useEffect(() => {
    getFields();
  }, []);
  return (
    <>
      <PipelineForm
        moduleMap={moduleMap}
        moduleData={moduleData}
        editMode={editMode}
        setEditMode={setEditMode}
        deal={deal}
        loading={loading}
        setLoading={setLoading}
        dealFormData={dealFormData}
        dispatch={dispatch}
        customDataFields={customFields}
        setCustomDataFields={setCustomFields}
        fields={isFieldsData}
        onHandleSubmit={onHandleSubmit}
        isPrincipalOwner={isPrincipalOwner}
        setSuccessMessage={setSuccessMessage}
      />
      <AlertWrapper>
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
    </>
  );
};

export default PipelineOverview;

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Header from '../../../components/peopleProfile/Header';
import contactService from '../../../services/contact.service';
import Deals from '../../../components/peopleProfile/deals/Deals';
import Overview from '../../../components/peopleProfile/overview/Overview';
import RightBar from '../../../components/organizationProfile/overview/RightBar';
import Organization from '../../../components/peopleProfile/organization/Organization';
import AddContent from '../../../components/peopleProfile/AddContent';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import Alert from '../../../components/Alert/Alert';
import userService from '../../../services/user.service';
import fieldService from '../../../services/field.service';
import { useModuleContext } from '../../../contexts/moduleContext';
import AddPeople from '../../../components/peoples/AddPeople';
import {
  DetailTabs,
  getAccountsChildPermissions,
  isModuleAllowed,
} from '../../../utils/Utils';
import { useTenantContext } from '../../../contexts/TenantContext';
import { LoadingDetailSkeleton } from '../../../components/LoadingDetailComponent';
import feedService from '../../../services/feed.service';
import activityService from '../../../services/activity.service';
const TABS = DetailTabs;

const Profile = () => {
  const history = useHistory();
  const { contactId, activityId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const [refreshRecentFiles, setRefreshRecentFiles] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [refreshOwners, setRefresOwners] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [, setActivityIdOpen] = useState(activityId);
  const [me, setMe] = useState(null);
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [, setOpenList] = useState(false);
  const { tenant } = useTenantContext();
  const { moduleMap } = useModuleContext();
  const [tabCounts, setTabCounts] = useState({
    [TABS.Timeline]: 0,
    [TABS.Notes]: 0,
    [TABS.Activities]: 0,
    [TABS.Files]: 0,
  });
  const isPrincipalOwner =
    me && profileInfo
      ? me?.role?.admin_access ||
        me?.role?.owner_access ||
        profileInfo?.assigned_user?.id === me?.id
      : false;

  const loadCounts = async () => {
    const updatedType = getAccountsChildPermissions(tenant.modules, 'contacts');
    const queryParams = {
      page: 1,
      limit: 1,
      self: true,
    };

    const contactTabs = [
      {
        tabId: TABS.Timeline,
        module: `${updatedType}_timeline`,
        count: 0,
        countApiCall: () => Promise.resolve({ pagination: { count: 0 } }),
      },
      {
        tabId: TABS.Notes,
        module: `${updatedType}_notes`,
        count: 0,
        countApiCall: () =>
          feedService.getNote(
            { contactId, organizationId: null, dealId: null },
            queryParams
          ),
      },
      {
        tabId: TABS.Activities,
        module: `activities_contact_activities`,
        count: 0,
        countApiCall: () =>
          activityService.getActivity(
            { contactId, organizationId: null, dealId: null },
            queryParams
          ),
      },
      {
        tabId: TABS.Files,
        module: `${updatedType}_files`,
        count: 0,
        countApiCall: () =>
          feedService.getFiles(
            { contactId, organizationId: null, dealId: null },
            queryParams
          ),
      },
    ];
    const enabledTabs = contactTabs?.filter((item) => {
      return isModuleAllowed(tenant?.modules, item?.module);
    });

    const countCalls = enabledTabs.map((cl) => cl.countApiCall());
    const responses = await Promise.all(countCalls);
    const Counts = {};
    enabledTabs.forEach((tb, index) => {
      Counts[tb.tabId] = responses[index]?.pagination?.count || 0;
    });
    setTabCounts(Counts);
  };
  useEffect(() => {
    getCurrentUser();
    loadCounts();
  }, [profileInfo]);

  useEffect(() => {
    if (refreshOwners) {
      setRefresOwners(false);
    }
  }, [refreshOwners]);

  useEffect(() => {
    getProfileInfo();
  }, []);

  useEffect(() => {
    if (refreshRecentFiles) {
      getProfileInfo();
      setRefreshRecentFiles(false);
    }
  }, [refreshRecentFiles]);

  useEffect(() => {
    getProfileInfo();
  }, [contactId]);

  const getCurrentUser = async () => {
    const me = await userService
      .getUserInfo()
      .catch((err) => console.error(err));

    setMe(me);
  };

  const goToHome = () => {
    history.push('/');
  };

  const getProfileInfo = async (message) => {
    setIsLoading(true);
    if (message) {
      setActivityIdOpen('');
      setSuccessMessage(message);
    }
    const { data } = await fieldService.getFields('contact', {
      usedField: true,
    });
    const {
      data: { data: customFields },
    } = await contactService.getCustomField(contactId, {
      page: 1,
      limit: 50,
    });
    let customValues = {};
    Promise.all([
      contactService.getContactById(contactId),
      contactService.getFieldByContact(contactId, {}),
    ])
      .then(([result, response]) => {
        if (!result) {
          goToHome();
        }
        const fields = response?.data?.sort((a, b) => {
          return a.field.order - b.field.order;
        });
        data.forEach((field) => {
          if (field.isCustom) {
            customFields.forEach((item) => {
              if (field.key === item.field.key && field.field_type !== 'DATE') {
                customValues = {
                  ...customValues,
                  [field.key?.toLowerCase().replace(/\s+/g, '')]:
                    field.field_type === 'CURRENCY'
                      ? item.value.substring(1)
                      : item.value,
                };
              } else if (
                field.key === item.field.key &&
                field.field_type === 'DATE'
              ) {
                customValues = {
                  ...customValues,
                  [field.key?.toLowerCase().replace(/\s+/g, '')]: new Date(
                    item.value
                  ),
                };
              }
            });
          }
        });
        setProfileInfo({ ...result, ...customValues, fields });

        setIsLoading(false);
      })
      .catch(() => {
        goToHome();
      });
  };
  return (
    <>
      {isLoading ? (
        <div className="splitted-content-fluid position-relative container-fluid overflow-hidden">
          <LoadingDetailSkeleton />
        </div>
      ) : (
        <>
          <div className="splitted-content-fluid container-fluid content-with-insights">
            <AlertWrapper>
              <Alert
                color="success"
                message={successMessage}
                setMessage={setSuccessMessage}
              />
              <Alert
                message={errorMessage}
                setMessage={setErrorMessage}
                color="danger"
              />
            </AlertWrapper>

            {moduleMap.contact && (
              <Header
                moduleMap={moduleMap.contact.singular}
                contactId={contactId}
                data={profileInfo}
                setOpenCloneModal={setOpenCloneModal}
                refreshOwners={refreshOwners}
                setRefresOwners={setRefresOwners}
                isPrincipalOwner={isPrincipalOwner}
              />
            )}
            <hr className="mt-0" />
            <div className="row">
              <div className="col-lg-4">
                {moduleMap.contact && (
                  <Overview
                    moduleMap={moduleMap}
                    labelType="contact"
                    data={profileInfo}
                    getProfileInfo={getProfileInfo}
                    isPrincipalOwner={isPrincipalOwner}
                  />
                )}
                {moduleMap.contact && (
                  <Organization
                    data={profileInfo.organization}
                    moduleMap={moduleMap.organization.singular}
                    contactId={contactId}
                    getProfileInfo={getProfileInfo}
                    isPrincipalOwner={isPrincipalOwner}
                  />
                )}

                {moduleMap.deal && (
                  <Deals
                    moduleMap={moduleMap.deal}
                    contactProfile={profileInfo}
                    contactId={contactId}
                  />
                )}
              </div>

              <div className="col-lg-8 pl-0 profile-col-right">
                <div>
                  {moduleMap.task && (
                    <AddContent
                      moduleMap={moduleMap}
                      dataType="contact"
                      type="contacts"
                      activityType="contact"
                      contactId={contactId}
                      contactInfo={profileInfo}
                      getProfileInfo={getProfileInfo}
                      organizationId={profileInfo?.organization?.id}
                      contactIs={'profile'}
                      isContact
                      me={me}
                      refreshRecentFiles={refreshRecentFiles}
                      setRefreshRecentFiles={setRefreshRecentFiles}
                      tabCounts={tabCounts}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {isModuleAllowed(
            tenant?.modules,
            getAccountsChildPermissions(tenant?.modules, 'contacts_sidebar')
          ) && (
            <RightBar
              profileInfo={profileInfo?.organization}
              isPeople={true}
              isLoading={isLoading}
              moduleName={getAccountsChildPermissions(
                tenant?.modules,
                'contacts_sidebar_generate_pre-call_plan'
              )}
            />
          )}
          {openCloneModal && (
            <AddPeople
              moduleMap={moduleMap}
              openPeople={openCloneModal}
              setOpenPeople={setOpenCloneModal}
              setOpenList={setOpenList}
              contactData={profileInfo}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
            />
          )}
        </>
      )}
    </>
  );
};

export default Profile;

import { useEffect, useState } from 'react';
import { cubeService } from '../../../services';
import Loading from '../../../components/Loading';
import InsightStatItem from '../InsightStatItem';
import NoDataFound from '../../../components/commons/NoDataFound';
const AssignmentStats = {
  AssignmentCompleted: 'Assignments Completed',
  AssignmentInProgress: 'Assignments In Progress',
  AssignmentNotStarted: 'Assignments Not Started',
  AssignmentOverDue: 'Assignments Overdue',
  totalAssignments: 'Total Assignments',
};
export const AssignmentSnapChatWidget = ({
  assignmentStatsNames = {},
  queryFilter = [],
  param = 'teams',
  allSelectedData = [],
  type = 'AssignmentStats',
  headingClass,
  assignmentStatsTooltip = {},
  countClass,
  subHeadingClass,
  customClass = 'col-md-3 pb-3 pr-2',
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const getData = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type,
      ...(queryFilter?.length > 0 ? { [param]: queryFilter } : {}),
    });
    setData(results);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [queryFilter]);

  return (
    <>
      <div className="w-100 px-3">
        <div className="mx-0 row pt-3 px-0">
          {loading ? (
            <Loading />
          ) : data?.length > 0 ? (
            Object.entries(data[0]).map(([key, value]) => (
              <>
                {((type === 'AssignmentDetailStats' &&
                  key !== 'totalAssignments') ||
                  type !== 'AssignmentDetailStats') &&
                  key !== 'uniqueTotalAssignments' && (
                    <InsightStatItem
                      heading={
                        Object.keys(assignmentStatsNames)?.length > 0
                          ? assignmentStatsNames[key]
                          : AssignmentStats[key]
                      }
                      customClass={customClass}
                      tooltipTitle={assignmentStatsTooltip[key]}
                      tooltipData={assignmentStatsTooltip}
                      headingClass={headingClass}
                      countClass={countClass}
                      subHeadingClass={subHeadingClass}
                      stat={
                        type === 'AssignmentDetailStats' &&
                        key === 'peopleAssigned'
                          ? data[0]?.peopleAssigned
                          : type !== 'AssignmentDetailStats' &&
                            type === 'AssignmentStats' &&
                            key === 'totalAssignments'
                          ? data[0]?.uniqueTotalAssignments
                          : value || 0
                      }
                      hideAverage
                    />
                  )}
              </>
            ))
          ) : (
            <NoDataFound title="Data no found" />
          )}
        </div>
      </div>
    </>
  );
};

import { Card, CardBody } from 'reactstrap';
import React, { useRef, useEffect, useState } from 'react';
import { formatNumber, sortObjectKeys } from '../../../../utils/Utils';
import { Col, Row } from 'react-bootstrap';
import ChartDonutWidget from '../../../../views/Overview/dashboard/components/ChartDonutWidget';
import * as d3 from 'd3';
import TextOverflowTooltip from '../../../commons/TextOverflowTooltip';
const NumberWithItemsText = ({ item }) => {
  return (
    <div className="d-flex align-items-center text-left justify-content-start">
      <p className="mb-0 font-size-sm2 mb-0">{item}</p>
      <p className="font-size-sm2 mb-0">&nbsp;items</p>
    </div>
  );
};

const getColorForValue = (value) => {
  const lowerKey = value.toLowerCase();
  if (lowerKey.includes('ach')) {
    return 'rgba(1,183,91,255)'; // Green
  } else if (lowerKey.includes('check')) {
    return 'rgba(255,43,44,255)'; // Red
  } else if (lowerKey.includes('wire')) {
    return 'rgba(255,217,80,255)'; // Yellow
  } else {
    return 'rgba(128, 128, 128, 0.6)'; // Gray
  }
};

const DonutChart = ({ chartData }) => {
  const svgRef = useRef();
  let data = [];
  const allItemValuesAreZero = Object.values(chartData).every(
    (item) => item.itemValue === 0
  );
  if (allItemValuesAreZero) {
    data.push({
      id: 1,
      label: 'No Data',
      count: 1,
      color: getColorForValue('no'),
    });
  } else {
    data = Object.keys(chartData).map((key, index) => {
      return {
        id: index + 1,
        label: chartData[key].key,
        count: chartData[key].itemValue,
        color: getColorForValue(chartData[key].key), // Dynamically get color based on the label
      };
    });
  }
  const [dataset, setDataset] = useState(data);

  useEffect(() => {
    const WIDTH = 90;
    const HEIGHT = 90;
    const radius = Math.min(WIDTH, HEIGHT) / 2;

    const svg = d3
      .select(svgRef.current)
      .attr('width', WIDTH)
      .attr('height', HEIGHT);

    const container = svg
      .select('g')
      .attr('transform', `translate(${WIDTH / 2}, ${HEIGHT / 2})`);

    const arc = d3.arc().innerRadius(25).outerRadius(radius);

    const pie = d3
      .pie()
      .value((d) => d.count)
      .sort(null);

    const paths = container
      .selectAll('path')
      .data(pie(dataset), (d) => d.data.id);

    paths
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => d.data.color) // Use dynamic color from the dataset
      .each(function (d) {
        this._current = d;
      });

    paths.exit().remove();

    paths
      .transition()
      .duration(750)
      .attrTween('d', function (d) {
        const interpolate = d3.interpolate(this._current, d);
        this._current = interpolate(0);
        return (t) => arc(interpolate(t));
      });

    paths.on('click', (event, clickedDatum) => {
      setDataset(dataset.filter((d) => d.id !== clickedDatum.data.id));
    });
  }, [dataset]);

  return (
    <svg ref={svgRef}>
      <g></g>
    </svg>
  );
};

const getChartData = (data) => {
  const labels = [];
  const chartData = [];
  const backgroundColor = [];

  Object.keys(data).forEach((key) => {
    labels.push(key);
    chartData.push(data[key].itemValue);
    backgroundColor.push(getColorForValue(key));
  });

  return {
    labels,
    datasets: [
      {
        label: 'Items',
        data: chartData,
        backgroundColor,
      },
    ],
  };
};

const EstimatedPayableReceiveableWidget = ({
  widgetConfig,
  whenPrinting,
  selectedTenant,
}) => {
  delete widgetConfig?.data?.paymentMethodsUsed['Automated Payable Solution'];
  delete widgetConfig?.data?.typesOfReceivables[
    'Automated Receivables Solution'
  ];
  delete widgetConfig?.data?.paymentMethodsUsed['Automated Payables Solution'];
  delete widgetConfig?.data?.typesOfReceivables[
    'Automated Receivable Solution'
  ];

  const chartOptionsConfig = {
    legendDisplay: false, // Hide the legend above the graph
  };

  const payablesChartData = getChartData(
    widgetConfig?.data?.paymentMethodsUsed
  );
  const receivablesChartData = getChartData(
    widgetConfig?.data?.typesOfReceivables
  );

  return (
    <Card className="report-widget">
      <CardBody
        className={`d-flex align-items-center rounded-lg justify-content-center ${
          whenPrinting ? 'pr-0 pl-3' : 'px-3'
        } py-0`}
      >
        <Row className="w-100 position-relative flex-fill">
          <Col className={`${whenPrinting ? 'pl-3' : 'px-0'}`}>
            <Card className="border-0 no-border shadow-none position-relative h-100">
              <CardBody className="d-flex flex-column align-items-start pr-0">
                <h5 className="text-left mb-1 d-flex w-100 align-items-center gap-1">
                  Current Payables Profile
                </h5>
                <Row
                  className={`${
                    !whenPrinting ? 'pl-4 pr-2' : 'pl-2'
                  } mb-1 flex-fill w-100 pt-2`}
                >
                  <div
                    className={`${
                      whenPrinting
                        ? 'max-w-80 max-h-80 pr-2'
                        : 'max-w-120 max-h-120 etpr-chart-align'
                    } mx-auto`}
                  >
                    {whenPrinting ? (
                      <DonutChart
                        chartData={widgetConfig?.data?.paymentMethodsUsed}
                      />
                    ) : (
                      <ChartDonutWidget
                        data={{
                          ...payablesChartData,
                          type: 'doughnut',
                          legendPosition: 'top',
                        }}
                        name="payables"
                        optionsConfig={chartOptionsConfig}
                        legendPosition="top"
                        dataLabelsConfig={{ display: false }}
                      />
                    )}
                  </div>
                  <div
                    className={`flex-grow-1 ${whenPrinting ? 'pl-4' : 'pl-2'}`}
                  >
                    {Object.entries(
                      sortObjectKeys(widgetConfig?.data?.paymentMethodsUsed) ||
                        {}
                    ).map((entry, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between align-items-center font-size-sm2 pb-2"
                      >
                        <div className="d-flex gap-2 align-items-center">
                          <div
                            className="filled-circle"
                            style={{
                              backgroundColor: getColorForValue(entry[1].key),
                            }}
                          ></div>
                          <span
                            className={`fs-8 sub-text text-left font-weight-medium`}
                          >
                            <TextOverflowTooltip
                              text={entry[1].key}
                              textStyle="fs-8 sub-text text-left font-weight-medium text-wrap"
                              maxLength={8}
                            />
                          </span>
                        </div>
                        <span className="fs-8 sub-text">
                          {formatNumber(entry[1].itemValue, 0).replace('$', '')}{' '}
                          {parseInt(entry[1].itemValue) > 1 ? 'items' : 'item'}
                        </span>
                      </div>
                    ))}
                    <div className="d-flex justify-content-between align-items-center w-100 mt-3 font-weight-bold">
                      <p className="mb-1 text-left font-size-sm2">Total</p>
                      <NumberWithItemsText
                        item={formatNumber(
                          widgetConfig?.data?.estimatedTotalPayables,
                          0
                        ).replace('$', '')}
                      />
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <span className="line fti-item etpr chart" />
          <Col className={`${whenPrinting ? 'pr-0' : ''}`}>
            <Card className="border-0 no-border shadow-none h-100">
              <CardBody className="d-flex flex-column align-items-start px-0">
                <h5 className="text-left mb-1 w-100 d-flex align-items-center gap-1">
                  Current Receivables Profile
                </h5>
                <Row
                  className={`${
                    !whenPrinting ? 'pl-4' : 'pl-2'
                  } mb-1 pt-2 flex-fill w-100`}
                >
                  <div
                    className={`${
                      whenPrinting
                        ? 'max-w-80 max-h-80 pr-2'
                        : 'max-w-120 max-h-120 etpr-chart-align'
                    } mx-auto`}
                  >
                    {whenPrinting ? (
                      <>
                        <DonutChart
                          chartData={widgetConfig?.data?.typesOfReceivables}
                        />
                      </>
                    ) : (
                      <ChartDonutWidget
                        data={{
                          ...receivablesChartData,
                          type: 'doughnut',
                          legendPosition: 'top',
                        }}
                        optionsConfig={chartOptionsConfig}
                        legendPosition="top"
                        name="receivable"
                        dataLabelsConfig={{ display: false }}
                      />
                    )}
                  </div>
                  <div
                    className={`flex-grow-1 ${whenPrinting ? 'pl-4' : 'pl-2'}`}
                  >
                    {Object.entries(
                      sortObjectKeys(widgetConfig?.data?.typesOfReceivables) ||
                        {}
                    ).map((entry, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between align-items-center font-size-sm2 pb-2"
                      >
                        <div className="d-flex gap-2 align-items-center">
                          <div
                            className="filled-circle"
                            style={{
                              backgroundColor: getColorForValue(entry[1].key),
                            }}
                          ></div>
                          <span className="fs-8 sub-text text-left font-weight-medium text-wrap">
                            <TextOverflowTooltip
                              text={entry[1].key}
                              textStyle="fs-8 sub-text text-left font-weight-medium text-wrap"
                            />
                          </span>
                        </div>
                        <span className="fs-8 sub-text">
                          {formatNumber(entry[1].itemValue, 0).replace('$', '')}{' '}
                          {parseInt(entry[1].itemValue) > 1 ? 'items' : 'item'}
                        </span>
                      </div>
                    ))}
                    <div className="d-flex justify-content-between align-items-center mt-3 font-weight-bold">
                      <p className="text-left mb-1 font-size-sm2 ">Total</p>
                      <NumberWithItemsText
                        item={formatNumber(
                          widgetConfig?.data?.estimatedTotalReceivables,
                          0
                        ).replace('$', '')}
                      />
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default EstimatedPayableReceiveableWidget;

import { Col } from 'react-bootstrap';

const ItemName = (itemUserProps) => {
  return (
    <Col
      className={`item-user text-capitalize ${
        itemUserProps.bigmx ? 'mx-3' : 'mx-0'
      } w-100`}
      onClick={itemUserProps.onClick}
    >
      <div className="d-flex align-items-center">
        {itemUserProps.itemIcon &&
          itemUserProps.showIcon &&
          itemUserProps.name && (
            <span className="material-symbols-rounded fs-5 m-1 ">
              {itemUserProps.itemIcon}
            </span>
          )}
        <div className="mb-0 fs-7 font-weight-medium text-gray-900 w-100">
          {itemUserProps.name}
          {itemUserProps.showEmail && itemUserProps.email && (
            <p className="mb-0 fs-8 text-lowercase font-weight-normal">
              {itemUserProps.email}
            </p>
          )}
        </div>
      </div>
      {itemUserProps.address && <h5>{itemUserProps.address}</h5>}
    </Col>
  );
};

export default ItemName;

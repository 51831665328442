import React from 'react';

const ReportSectionIncludedLabel = ({ section, reportPages }) => {
  const isEnabled = reportPages[section]?.enabled;
  return (
    <>
      {isEnabled ? (
        <span className="badge badge-success">Included</span>
      ) : (
        <span className="badge badge-danger">Excluded</span>
      )}
    </>
  );
};

export default ReportSectionIncludedLabel;

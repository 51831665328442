import { useEffect, useRef, useState } from 'react';
import useOutsideClickDropDown from '../../hooks/useOutsideClickDropDown';
import { Input } from 'reactstrap';
import IconTextLoader from '../loaders/IconText';
import MaterialIcon from './MaterialIcon';

const NewKindOfSearchDropdown = ({
  cache,
  cacheKey,
  currentSelected,
  dataReadKey = 'name',
  onItemSelect,
  fieldState = {},
  validationConfig = {},
  errorClass,
  dataService,
  dataServiceApi,
  preData,
  readOnly = false,
  placeholder,
  onInputSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(currentSelected || {});
  const [dropdownDirection, setDropdownDirection] = useState('below');

  const menuRef = useRef();
  const buttonRef = useRef();
  const searchRef = useRef();

  useOutsideClickDropDown(menuRef, isOpen, setIsOpen);

  useEffect(() => {
    if (isOpen) {
      searchRef.current.focus();
      determineDropdownPosition();
      if (preData) {
        setData(preData);
      } else {
        fetchData();
      }
    } else {
      setSearchTerm('');
    }
  }, [isOpen, preData]);

  const fetchData = async () => {
    if (cache[cacheKey]) {
      setData(cache[cacheKey]);
      return;
    }
    setIsLoading(true);
    try {
      const response = await dataService[dataServiceApi]({});
      const { data: apiData } = response || {};
      cache[cacheKey] = apiData;
      setData(apiData);
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const determineDropdownPosition = () => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    const spaceBelow = viewportHeight - buttonRect.bottom;
    const spaceAbove = buttonRect.top;

    // if there is less space below open dropdown above the field
    if (spaceBelow < 300 && spaceAbove > spaceBelow) {
      setDropdownDirection('above');
    } else {
      setDropdownDirection('below');
    }
  };

  const filteredData = data.filter((item) =>
    item[dataReadKey].toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleItemClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedItem(item);
    setIsOpen(false);
    onItemSelect(item);
  };

  const handleToggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const renderData = () => {
    if (isLoading) {
      return (
        <div className="p-3">
          <IconTextLoader count={3} />
        </div>
      );
    }
    if (filteredData.length > 0) {
      return filteredData.map((item) => (
        <div
          key={item.id}
          onClick={(e) => handleItemClick(e, item)}
          className={`py-2 fs-7 bg-hover-gray text-black btn-outline-primary px-3 ${
            item.id === selectedItem.id ? 'bg-primary-soft' : ''
          }`}
        >
          {item[dataReadKey]}
        </div>
      ));
    }
    return (
      <p className="mb-0 text-center w-100 p-2 text-muted">No options found</p>
    );
  };

  useEffect(() => {
    if (onInputSearch) {
      onInputSearch({ target: { value: searchTerm } });
    }
  }, [searchTerm]);
  return (
    <div
      className="position-relative cursor-pointer"
      onClick={handleToggleDropdown}
    >
      <div
        ref={buttonRef}
        className={`border w-100 d-flex justify-content-between align-items-center z-index-2 bg-white py-2 px-3 rounded
        ${errorClass} ${
          validationConfig?.required
            ? 'border-left-4 border-left-danger rounded'
            : ''
        } ${
          fieldState?.invalid && !fieldState?.error?.ref?.value
            ? 'border rounded border-danger'
            : ''
        }`}
        style={{ color: readOnly && placeholder && '#999ead' }}
      >
        {readOnly && !selectedItem[dataReadKey]
          ? placeholder
          : selectedItem[dataReadKey]}
        <MaterialIcon icon="keyboard_arrow_down" className="ml-auto" />
      </div>
      {isOpen && (
        <div
          ref={menuRef}
          className={`position-absolute py-2 cursor-pointer w-100 shadow border rounded z-index-2 bg-white`}
          style={{
            maxHeight: searchTerm ? 'auto' : 300,
            [dropdownDirection === 'below' ? 'top' : 'bottom']: '100%',
          }}
        >
          {(selectedItem[dataReadKey] || currentSelected) && (
            <div
              className={`w-100 px-3 z-index-2 justify-content-between d-flex align-items-center bg-white rounded ${
                isOpen ? 'border-bottom pb-2' : ''
              } `}
            >
              {selectedItem[dataReadKey]}
              <MaterialIcon icon="keyboard_arrow_up" className="ml-auto" />
            </div>
          )}
          <div className="mt-2 position-relative px-3">
            <Input
              innerRef={searchRef}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
            <MaterialIcon
              icon="search"
              clazz="position-absolute"
              style={{ right: 25, top: 12 }}
            />
          </div>
          <div
            className="mt-2 d-flex flex-column border-top overflow-y-auto gap-1"
            style={{ maxHeight: 200 }}
          >
            {renderData()}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewKindOfSearchDropdown;

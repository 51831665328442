import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import IconTextLoader from '../loaders/IconText';
import NoDataFound from '../commons/NoDataFound';
import Avatar from '../Avatar';
import TooltipComponent from '../lesson/Tooltip';
import MaterialIcon from '../commons/MaterialIcon';
import contactService from '../../services/contact.service';
import { AlertMessageContext } from '../../contexts/AlertMessageContext';
import { useTenantContext } from '../../contexts/TenantContext';
import authService from '../../services/auth.service';
import { usePagesContext } from '../../contexts/pagesContext';

const ContactsListing = ({ show, organization, setShowContactsModal }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const { setSuccessMessage } = useContext(AlertMessageContext);
  const { tenant } = useTenantContext();
  const { clientPortalUrl } = tenant;
  const [sending, setSending] = useState({});
  const { pageContext, setPageContext } = usePagesContext();
  const getContactsByOrganization = async (orgId) => {
    const { data } = await contactService.getContact(
      { organization_id: orgId },
      { limit: 20, page: 1 }
    );
    return data.contacts?.map((u) => ({
      ...u,
      name: `${u.first_name} ${u.last_name}`,
    }));
  };
  const getContacts = async () => {
    try {
      setLoading(true);
      if (!pageContext?.CompanyContacts?.length) {
        const newData = await getContactsByOrganization(organization.id);
        setPageContext({ ...pageContext, CompanyContacts: newData });
        setList(newData);
      } else {
        if (
          pageContext?.CompanyContacts.every(
            (cn) => cn.organization_id === organization.id
          )
        ) {
          setList(pageContext.CompanyContacts);
        } else {
          const newData = await getContactsByOrganization(organization.id);
          setPageContext({ ...pageContext, CompanyContacts: newData });
          setList(newData);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const AddContactTitle = () => {
    return (
      <p className="font-size-sm mb-0">Add contact to share with client</p>
    );
  };

  const handleSendReport = async (item) => {
    try {
      setSending(item);
      const contact = {
        ...item,
        email:
          item?.email_work ||
          item?.email_home ||
          item?.email_fax ||
          item?.email_other,
      };
      await authService.guestToken(
        contact?.email,
        contact?.organization_id,
        clientPortalUrl
      );
      setSuccessMessage('E-mail sent successfully.');
    } catch (e) {
      console.error(e);
    } finally {
      setSending({});
    }
  };

  useEffect(() => {
    if (show && organization) {
      getContacts();
    }
  }, [organization, show]);
  return (
    <>
      {show ? (
        <Container className="px-3">
          <Row className="px-3">
            <Col md={12} className="p-0">
              <div className="overflow-y-auto" style={{ maxHeight: 250 }}>
                {loading ? (
                  <IconTextLoader count={3} />
                ) : (
                  <>
                    {list?.length === 0 ? (
                      <NoDataFound
                        title={<AddContactTitle />}
                        description=""
                        icon=""
                        iconStyle="font-size-3em"
                        iconRounded={true}
                        buttonIcon={'add'}
                        handleShow={() => setShowContactsModal(true)}
                        showButton={true}
                        label="Add Contact"
                        containerStyle="text-gray-900 text-center justify-content-center w-100 my-3 py-3"
                      />
                    ) : (
                      list?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="d-flex align-items-center py-2 justify-content-between"
                          >
                            <div className="d-flex align-items-center gap-1">
                              <Avatar user={item} />
                              <div>
                                <p className="font-size-sm mb-0 font-weight-medium">
                                  {item.first_name} {item.last_name}
                                </p>
                                {(item?.email_work || item?.email_home) && (
                                  <p
                                    className="mb-0 position-relative text-muted fs-7"
                                    style={{ top: -2 }}
                                  >
                                    {item?.email_work || item?.email_home}
                                  </p>
                                )}
                              </div>
                            </div>
                            {sending?.id === item.id ? (
                              <a className="icon-hover-bg cursor-default">
                                <Spinner className="spinner-grow-xs" />
                              </a>
                            ) : (
                              <a
                                className="cursor-pointer icon-hover-bg"
                                onClick={() => handleSendReport(item)}
                              >
                                <TooltipComponent title="Send Email">
                                  <MaterialIcon
                                    icon="forward_to_inbox"
                                    clazz="fs-5"
                                  />
                                </TooltipComponent>
                              </a>
                            )}
                          </div>
                        );
                      })
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
    </>
  );
};
const useOrganizationContacts = (organization, setShowContactsModal) => {
  const [show, setShow] = useState(true);
  const ContactsListingCallback = useCallback(() => {
    return (
      <ContactsListing
        show={show}
        setShow={setShow}
        organization={organization}
        setShowContactsModal={setShowContactsModal}
      />
    );
  }, [show, setShow]);

  return useMemo(
    () => ({
      show,
      setShow,
      Contacts: show ? ContactsListingCallback : () => {},
    }),
    [setShow, ContactsListingCallback]
  );
};

export default useOrganizationContacts;

import GenericTable from '../../../components/GenericTable';
import { setDateFormat } from '../../../utils/Utils';
import { NoUserSelected } from '../components/queries/NoUserSelected';

const LessonProgressByUser = (results, options, query) => {
  const { data } = results[0];
  const hasUserFilter = query.filters?.some(
    (filter) => filter.member === 'User.id'
  );
  if (!hasUserFilter) {
    return NoUserSelected();
  }

  const rows = data?.map((result, index) => {
    const rank = index + 1;

    return {
      id: index,
      dataRow: [
        {
          key: 'rank',
          component: (
            <div className="rank-container">
              <span className={`rank-${rank}`}>{rank}</span>
            </div>
          ),
        },
        {
          key: 'name',
          component: (
            <div className="d-flex text-wrap align-items-center">
              {result['Lesson.title']}
            </div>
          ),
        },
        {
          key: 'timesTaken',
          component: result['LessonProgress.count'],
        },
        {
          key: 'assigned',
          component: result['AssignmentUser.assignmentId']
            ? 'Yes'
            : result['AssignmentTeam.assignmentId']
            ? 'Yes'
            : 'No',
        },
        {
          key: 'dueAt',
          component: result['Assignment.dueAt']
            ? setDateFormat(result['Assignment.dueAt'], 'MM/DD/YYYY')
            : ' -- ',
        },
        {
          key: 'completed',
          component: result['LessonProgress.avgLessonsCompleted'] + '%',
        },
        {
          key: 'lastAttempted',
          component: setDateFormat(
            result['LessonProgress.maxOfLastAttempt'],
            'MM/DD/YYYY'
          ),
        },
      ],
    };
  });

  return (
    <div>
      <div>
        <GenericTable
          checkbox={false}
          data={rows}
          exportToCSV={true}
          tableData={data}
          fileName="LearnerTranscript:Lesson"
          columns={[
            {
              key: 'rank',
              component: 'Rank',
              width: '5%',
            },
            {
              key: 'Lesson.title',
              component: 'Lesson',
            },
            {
              key: 'LessonProgress.count',
              component: 'Times Taken',
            },
            {
              key: 'AssignmentUser.assignmentId',
              component: 'Assigned',
            },
            {
              key: 'Assignment.dueAt',
              component: 'Due At',
            },
            {
              key: 'LessonProgress.avgLessonsCompleted',
              component: 'Progress',
            },
            {
              key: 'LessonProgress.maxOfLastAttempt',
              component: 'Last Activity',
            },
          ]}
          usePagination={false}
          noDataInDbValidation={true}
        />
      </div>
    </div>
  );
};

export default LessonProgressByUser;

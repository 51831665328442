import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import stringConstants from '../../utils/stringConstants.json';
import { sortingTable } from '../../utils/sortingTable';
import routes from '../../utils/routes.json';
import Table from '../GenericTable';
import TableSkeleton from '../commons/TableSkeleton';
import activityService from '../../services/activity.service';
import { dateWithoutTZ } from '../../utils/Utils';
import { Link } from 'react-router-dom';
import MaterialIcon from '../commons/MaterialIcon';
import TableActions from '../commons/TableActions';
import { ActivityDetail } from './activityDetail';
import OwnerAvatar from './OwnerAvatar';
import { ShortDescription } from '../ShortDescription';
import DeleteModal from '../modal/DeleteModal';
import TableRowHover from '../commons/TableRowHover';
const constants = stringConstants.tasks;

const EventTable = ({
  paginationPage,
  tabType,
  isFilterCheck,
  order,
  setOrder,
  pagination,
  showLoading,
  dataInDB,
  setPagination,
  setActivatedTab,
  selectedData,
  setSelectedData,
  handleEditActivity,
  setShowDeleteOrgModal,
  deleteResults,
  showDeleteOrgModal,
  setDeleteResults,
  getData,
  setErrorMessage,
  setSuccessMessage,
  allData,
  cloneData,
  setCloneData,
  handleClearSelection,
  selectAll,
  setSelectAll,
  getStats,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [activityObj, setActivityObj] = useState();
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    getData(tabType);
  }, [pagination?.page, order, isFilterCheck?.filter]);

  const changePaginationPage = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };
  const activityDetail = async (content) => {
    const { data } = await activityService.getSingleActivity(
      content.id ? content.id : content
    );
    setActivityObj(data);
    setIsShow(true);
  };
  useEffect(() => {
    paginationPage?.page === 1 && changePaginationPage(1);
  }, [paginationPage]);
  const getAction = (content) => {
    if (content?.organization) {
      return (
        <Link
          to={`${routes.companies}/${content?.organization?.id}/organization/profile`}
          className="text-black fw-bold d-inline-flex align-items-center gap-1"
        >
          {content?.organization && (
            <>
              <MaterialIcon icon="domain" /> {content?.organization?.name}
            </>
          )}
        </Link>
      );
    } else if (content?.contact) {
      const name = `${content?.contact?.first_name} ${content?.contact?.last_name}`;
      return (
        <Link
          to={`${routes.contacts}/${content?.contact?.id}/profile`}
          className="text-black fw-bold d-inline-flex align-items-center gap-1"
        >
          {content && (
            <>
              <MaterialIcon icon="people" /> {name}
            </>
          )}
        </Link>
      );
    } else if (content?.deal) {
      return (
        <Link
          to={`${routes.dealsPipeline}/${content?.deal?.id}`}
          className="text-black fw-bold d-inline-flex align-items-center gap-1"
        >
          {content?.deal && (
            <>
              <MaterialIcon icon="monetization_on" /> {content?.deal?.name}
            </>
          )}
        </Link>
      );
    }
  };

  const GetOwnersAction = (task) => {
    if (task) {
      return (
        <div className="align-items-end more-owners gap-1 align-items-center">
          {task?.owners?.map((item) => (
            <div key={item?.id}>
              <OwnerAvatar item={item} isMultiple={true} />
            </div>
          ))}
        </div>
      );
    }
  };
  const columns = [
    {
      key: 'name',
      orderBy: 'name',
      component: 'Title ',
      width: 450,
    },
    {
      key: 'start_date',
      orderBy: 'start_date',
      component: 'From',
    },
    {
      key: 'end_date',
      orderBy: 'end_date',
      component: 'To',
    },
    {
      key: '',
      orderBy: '',
      component: 'Related To',
    },
    {
      key: '',
      orderBy: '',
      component: 'Host',
    },
    {
      key: 'action',
      orderBy: '',
      component: '',
    },
  ];

  const tableActions = [
    {
      id: 1,
      title: 'Edit',
      icon: 'edit',
      permission: {
        collection: 'activities',
        action: 'edit',
      },
      onClick: (e) => {
        setIsShow(false);
        handleEditActivity(e);
      },
    },
    {
      id: 1,
      title: 'Clone',
      permission: {
        collection: 'activities',
        action: 'create',
      },
      icon: 'content_copy',
      onClick: (e) => {
        setIsShow(false);
        setCloneData(true);
        handleEditActivity(e);
      },
    },
  ];
  const data = allData?.map((event) => ({
    ...event,
    dataRow: [
      {
        key: 'name',
        component: (
          <ShortDescription
            content={event?.name}
            limit={20}
            classnames={`fw-bold ${event?.done ? 'text-line-through' : ''}`}
          />
        ),
      },
      {
        key: 'start_date',
        component: <span>{dateWithoutTZ(event?.start_date)}</span>,
      },
      {
        key: 'end_date',
        component: <span>{dateWithoutTZ(event?.end_date)}</span>,
      },
      {
        key: 'related_to',
        component: getAction(event),
      },
      {
        key: 'owner',
        component: GetOwnersAction(event),
      },
      {
        key: 'action',
        component: (
          <TableRowHover onClick={() => activityDetail(event)}>
            <TableActions
              item={{ ...event, title: name }}
              actions={tableActions}
            />
          </TableRowHover>
        ),
      },
    ],
  }));

  const sortTable = ({ name }) => {
    if (name === 'action') return null;
    sortingTable({ name, order, setOrder });
  };
  const loader = () => {
    if (showLoading) return <TableSkeleton cols={6} rows={10} />;
  };

  const deleteOrganizations = async () => {
    const numEventsToDelete = selectedData.length;
    await Promise.all(
      selectedData?.map(async (item) => {
        try {
          await activityService.deleteActivity(
            selectedData.length > 0 ? item : deleteResults?.id
          );
          const successMessage =
            numEventsToDelete === 1
              ? '1 Event Deleted'
              : `${numEventsToDelete} Events Deleted`;
          setSuccessMessage(successMessage);
          setShowDeleteOrgModal(false);
          handleClearSelection();
          setSelectedData([]);
        } catch (err) {
          setErrorMessage(err.message);
        }
      })
    );
    getData('event');
    setActivatedTab({
      event: 3,
    });
    setShowDeleteOrgModal(false);
  };

  const openDeleteModal = async () => {
    setShowDeleteOrgModal(true);
    await deleteOrganizations();
  };

  useEffect(() => {
    if (!showDeleteOrgModal) {
      handleClearSelection();
    }
  }, [!showDeleteOrgModal]);
  return (
    <div>
      <Card className="mb-5">
        <Card.Body className="p-0">
          <div className="table-responsive-md datatable-custom">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper no-footer"
            >
              {showLoading ? (
                loader()
              ) : (
                <Table
                  stickyColumn="stickyColumn"
                  columns={columns}
                  checkbox
                  data={data}
                  setDeleteResults={setDeleteResults}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  paginationInfo={pagination}
                  onPageChange={changePaginationPage}
                  emptyDataText="No records in this view."
                  title="event"
                  dataInDB={dataInDB}
                  showTooltip={showTooltip}
                  setShowTooltip={setShowTooltip}
                  sortingTable={sortTable}
                  sortingOrder={order}
                  onClick={activityDetail}
                  stats={getStats}
                />
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      {showDeleteOrgModal && (
        <DeleteModal
          type="task"
          showModal={showDeleteOrgModal}
          setShowModal={setShowDeleteOrgModal}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          event={openDeleteModal}
          data={allData}
          results={deleteResults}
          setResults={setDeleteResults}
          showReport={showDeleteReport}
          setShowReport={setShowDeleteReport}
          modified={modified}
          setSelectAll={setSelectAll}
          setModified={setModified}
          constants={constants?.delete}
        />
      )}
      {isShow && (
        <ActivityDetail
          activityDetail={activityDetail}
          isShow={isShow}
          setIsShow={setIsShow}
          cloneData={cloneData}
          data={activityObj}
          tableActions={tableActions}
        />
      )}
    </div>
  );
};

export default EventTable;

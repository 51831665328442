import { ChecklistStatuses } from '../../utils/checklist.constants';
import React from 'react';
import MaterialIcon from '../commons/MaterialIcon';

const ChecklistStatus = ({ item, fontSize }) => {
  return (
    <span
      className={`badge pr-2 label d-inline-flex align-items-center justify-content-center rounded-pill text-capitalize ${fontSize} ${
        item?.status === 'Completed'
          ? ChecklistStatuses.Completed.color
          : item?.status === 'Not Started'
          ? ChecklistStatuses.Pending.color
          : ChecklistStatuses.InProgress.color
      } `}
    >
      <MaterialIcon filled size="fs-7" icon="fiber_manual_record" />{' '}
      <span>{item?.statusText || item?.status}</span>
    </span>
  );
};
export default ChecklistStatus;

import React, { useState, useEffect } from 'react';

import Alert from '../../Alert/Alert';
import AddContact from './AddContact';
import OrgContactsList from './OrgContactsList';
import AlertWrapper from '../../Alert/AlertWrapper';
import contactService from '../../../services/contact.service';
import EmptyDataButton from '../../emptyDataButton/EmptyDataButton';
import { isPermissionAllowed } from '../../../utils/Utils';
import ContactsTableModal from './ContactsTableModal';
import Skeleton from 'react-loading-skeleton';
import { CardBody } from 'reactstrap';
import ContactTableModal from './ContactTableModal';
import ButtonIcon from '../../commons/ButtonIcon';

const Contacts = ({
  organizationId = '',
  moduleMap,
  getProfileInfo,
  isPrincipalOwner,
  profileInfo,
  showContactsModal,
  setShowContactsModal,
  organization,
  mainOwner,
  viewType = 'list',
  mainModuleMap,
}) => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [dataInDB, setDataInDB] = useState(false);

  const contactLimit = viewType === 'table' ? 10 : 5;

  useEffect(() => {
    if (organizationId !== '') {
      getContacts();
    }
  }, [organizationId]);

  const getContacts = () => {
    contactService
      .getContactsByorganizationId({ organizationId }, { limit: contactLimit })
      .then((res) => {
        setContacts(res.contacts);
        setPagination(res.pagination);
        setLoading(false);
        setDataInDB(Boolean(res?.pagination?.totalPages));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getProfile = () => {
    getProfileInfo();
    getContacts();
  };

  useEffect(() => {
    if (showAddContactModal) {
      getContacts();
    }
  }, [showAddContactModal]);

  const onPageChange = (page) => {
    setPagination({ ...pagination, page });
  };
  return (
    <div className="card mt-3">
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <ContactsTableModal
        moduleMap={moduleMap}
        showModal={showAddContactModal}
        setShowModal={setShowAddContactModal}
        organizationId={organizationId}
        profileInfo={profileInfo}
      />
      {viewType === 'table' && (
        <AddContact
          organizationId={organizationId}
          getContacts={getContacts}
          moduleMap={moduleMap}
          organization={organization}
          setShowAddContactModal={setShowContactsModal}
          showAddContactModal={showContactsModal}
          onHandleShowAlertSuccess={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          onHandleShowAlertFailed={setErrorMessage}
        />
      )}

      {viewType === 'list' ? (
        <>
          <div
            className={`card-header px-3 py-2 ${loading && 'border-bottom-0'}`}
          >
            <h4 className="card-title">{moduleMap}</h4>
            <div className="ml-auto">
              <AddContact
                organizationId={organizationId}
                getContacts={getProfile}
                moduleMap={moduleMap}
                setShowAddContactModal={setShowContactsModal}
                organization={organization}
                showAddContactModal={showContactsModal}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
              >
                {!loading && isPermissionAllowed('contacts', 'create') && (
                  <button
                    className="btn btn-icon btn-sm rounded-circle"
                    onClick={() => setShowContactsModal(true)}
                  >
                    <i className="material-symbols-rounded">add</i>
                  </button>
                )}
              </AddContact>
            </div>
          </div>

          <div className="card-body pt-2 pb-3">
            {loading ? (
              <div>
                <Skeleton count={3} height={10} className={'mb-2'} />
              </div>
            ) : (
              <div className="list-group list-group-lg list-group-flush list-group-no-gutters py-1">
                {contacts.length === 0 && (
                  <AddContact
                    organizationId={organizationId}
                    getContacts={getContacts}
                    moduleMap={moduleMap}
                    organization={organization}
                    setShowAddContactModal={setShowContactsModal}
                    showAddContactModal={showContactsModal}
                    setSuccessMessage={setSuccessMessage}
                    setErrorMessage={setErrorMessage}
                  >
                    {isPermissionAllowed('contacts', 'create') && (
                      <EmptyDataButton
                        setOpenModal={setShowContactsModal}
                        message=""
                        buttonLabel={`Add ${moduleMap}`}
                      />
                    )}
                  </AddContact>
                )}
                {contacts?.slice(0, contactLimit)?.map((item) => (
                  <OrgContactsList
                    item={item}
                    key={item.id}
                    isPrincipalOwner={isPrincipalOwner}
                    mainOwner={mainOwner}
                  />
                ))}
              </div>
            )}
          </div>

          {pagination.count > 0 && (
            <div className="card-footer px-3">
              <button
                className="btn btn-white btn-sm d-inline-flex align-items-center"
                onClick={() => {
                  setShowAddContactModal(true);
                }}
              >
                View all
                <span className="material-symbols-rounded fs-5">
                  chevron_right
                </span>
              </button>
            </div>
          )}
        </>
      ) : (
        <CardBody className="p-0 py-3">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="px-3">{moduleMap}</h4>
            {isPermissionAllowed('contacts', 'create') && (
              <ButtonIcon
                onclick={() => setShowContactsModal(true)}
                icon="add"
                classnames="btn-sm mr-3 pl-2 pr-3 mb-2 ml-auto"
                label={mainModuleMap.contact.singular}
              />
            )}
          </div>
          <ContactTableModal
            data={contacts}
            paginationInfo={pagination}
            onPageChange={onPageChange}
            dataInDB={dataInDB}
            loading={loading}
            profileInfo={profileInfo}
          />
        </CardBody>
      )}
    </div>
  );
};

export default Contacts;

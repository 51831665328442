import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReportTileStyles, ReportTypes } from './reports.constants';
import { PdfFileNames } from './reports.helper.functions';
import { PermissionsConstants } from '../../utils/permissions.constants';
import TreasuryManagementReport from '../peopleProfile/contentFeed/TreasuryManagementReport';
import WorkingCapitalAnalysisReport from '../peopleProfile/contentFeed/WorkingCapitalAnalysisReport';
import MerchantReportAnalysisReport from '../peopleProfile/contentFeed/MerchantReportAnalysisReport';
import { isModuleAllowed } from '../../utils/Utils';
import { useTenantContext } from '../../contexts/TenantContext';
import FraudReport from '../peopleProfile/contentFeed/FraudReport';
import { Image, Dropdown } from 'react-bootstrap';
import MaterialIcon from '../commons/MaterialIcon';
import AccountStructureReport from '../peopleProfile/contentFeed/AccountStructureReport';

const ReportTile = ({ tab, onSelect }) => {
  const reportStyle = ReportTileStyles[tab.key];
  return (
    <a
      key={tab.id}
      style={{ maxWidth: 240, minWidth: 240 }}
      className={`${reportStyle.styleClass} report-tile flex-fill flex-column text-center p-3 cursor-pointer font-size-sm2 bg-primary-soft-hover`}
      onClick={() => onSelect(tab)}
    >
      <div className="position-relative m-auto" style={{ maxWidth: 110 }}>
        <Image src={reportStyle.frame} />
      </div>
      <h5 className="mb-0 text-center text-black cursor-pointer">{tab.name}</h5>
    </a>
  );
};
const ReportTiles = ({ tabs, onSelect }) => {
  const { tenant } = useTenantContext();
  // any report is allowed then create a nav menu
  const hasEngagementReports = tabs.some((tb) =>
    isModuleAllowed(tenant?.modules, tb.permission)
  );

  const activeReports = tabs?.filter((tb) => tb.isEnabled === true);

  return (
    <>
      {hasEngagementReports ? (
        <>
          <h4 className="text-center py-2">Select Engagement Report</h4>
          <nav
            className={`d-flex ${
              activeReports?.length > 2 ? '' : 'justify-content-center'
            } pb-5 mb-3 m-auto align-items-center flex-wrap gap-2`}
            style={{ maxWidth: 500 }}
          >
            {tabs.map((tab) => (
              <>
                {isModuleAllowed(tenant?.modules, tab.permission) ? (
                  <ReportTile key={tab.id} tab={tab} onSelect={onSelect} />
                ) : null}
              </>
            ))}
          </nav>
        </>
      ) : null}
    </>
  );
};

const ReportTilesDropdown = ({
  tabs,
  setTabs,
  setSelectedTab,
  setIsDefaultView,
  selectedTab,
}) => {
  const { tenant } = useTenantContext();
  const [show, setShow] = useState(false);
  // any report is allowed then create a nav menu
  const hasEngagementReports = tabs.some((tb) =>
    isModuleAllowed(tenant?.modules, tb.permission)
  );

  const onSelect = (tab) => {
    const newTabs = [...tabs].map((tb) =>
      tb.id === tab.id ? { ...tab, isActive: true } : { ...tb, isActive: false }
    );
    setSelectedTab(tab);
    setTabs(newTabs);
    setIsDefaultView(false);
  };

  const getActiveReport = () => {
    return tabs.find((tb) => tb.isActive === true);
  };
  return (
    <>
      {hasEngagementReports ? (
        <>
          <Dropdown show={show} onToggle={setShow}>
            <Dropdown.Toggle
              variant="white"
              size="sm"
              className={`btn btn-white bg-primary-soft text-black dropdown-toggle ${
                show ? 'bg-gray-200' : ''
              }`}
              id="dropdown-basic"
            >
              <div className="d-flex align-items-center text-left w-100">
                <MaterialIcon icon={'apps'} rounded clazz={'mr-1'} />
                {getActiveReport() ? getActiveReport()?.name : 'Select Reports'}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className={`p-0 py-1`} style={{ width: 300 }}>
              <div className={`overflow-y-auto`}>
                {tabs.map((tab) => (
                  <>
                    {isModuleAllowed(tenant?.modules, tab.permission) ? (
                      <Dropdown.Item
                        key={tab.key}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onSelect(tab);
                        }}
                        className={`py-1 px-2 ${
                          ReportTileStyles[tab.key].styleClass
                        }`}
                      >
                        <div className="d-flex align-items-center justify-content-between py-0">
                          <div className="d-flex gap-1 align-items-center">
                            <Image
                              src={ReportTileStyles[tab.key].icon}
                              clazz="mr-2"
                              width={40}
                              height={40}
                            />
                            <span className="font-weight-medium text-black">
                              {tab.name}
                            </span>
                          </div>
                          {tab.isActive && (
                            <MaterialIcon
                              icon="check"
                              clazz={`fw-bold ${
                                ReportTileStyles[tab.key].styleClass
                              }`}
                            />
                          )}
                        </div>
                      </Dropdown.Item>
                    ) : null}
                  </>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : null}
    </>
  );
};

const EngagementReportTiles = ({
  tabs,
  setTabs,
  setSelectedTab,
  setIsDefaultView,
}) => {
  const onSelect = (tab) => {
    const newTabs = [...tabs].map((tb) =>
      tb.id === tab.id ? { ...tab, isActive: true } : { ...tb, isActive: false }
    );
    setSelectedTab(tab);
    setTabs(newTabs);
    setIsDefaultView(false);
  };
  return <ReportTiles tabs={tabs} onSelect={onSelect} />;
};

const useEngagementReportTabs = ({
  organizationId,
  currentTab,
  selectedTenant,
  getProfileInfo,
  profileInfo,
}) => {
  const { tenant } = useTenantContext();
  const [organization, setOrganization] = useState({
    ...profileInfo,
    id: organizationId,
  });

  const ReportTabsList = [
    {
      id: 1,
      name: PdfFileNames[ReportTypes.Treasury],
      key: ReportTypes.Treasury,
      component: (org, localTenant, readOnly) => (
        <TreasuryManagementReport
          organization={org || organization}
          currentTab={currentTab}
          selectedTenant={localTenant}
          getProfileInfo={getProfileInfo}
          readOnly={readOnly}
        />
      ),
      permission: PermissionsConstants.ModulesReportNames.Treasury,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.Treasury
      ),
      isActive: false,
    },
    {
      id: 2,
      name: PdfFileNames[ReportTypes.WorkingCapital],
      key: ReportTypes.WorkingCapital,
      component: (org, localTenant, readOnly) => (
        <WorkingCapitalAnalysisReport
          organization={org || organization}
          currentTab={currentTab}
          selectedTenant={localTenant}
          getProfileInfo={getProfileInfo}
          readOnly={readOnly}
        />
      ),
      permission: PermissionsConstants.ModulesReportNames.WorkingCapital,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.WorkingCapital
      ),
      isActive: false,
    },
    {
      id: 3,
      name: PdfFileNames[ReportTypes.Fraud],
      permission: PermissionsConstants.ModulesReportNames.Fraud,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.Fraud
      ),
      key: ReportTypes.Fraud,
      icon: 'problem',
      component: (org, localTenant, readOnly) => (
        <FraudReport
          organization={org || organization}
          currentTab={currentTab}
          selectedTenant={localTenant}
          getProfileInfo={getProfileInfo}
          readOnly={readOnly}
        />
      ),
      isActive: false,
    },
    {
      id: 4,
      name: PdfFileNames[ReportTypes.Merchant],
      key: ReportTypes.Merchant,
      component: (org, localTenant, readOnly) => (
        <MerchantReportAnalysisReport
          organization={org || organization}
          currentTab={currentTab}
          selectedTenant={localTenant}
          getProfileInfo={getProfileInfo}
          readOnly={readOnly}
        />
      ),
      permission: PermissionsConstants.ModulesReportNames.Merchant,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.Merchant
      ),
      isActive: false,
    },
    {
      id: 5,
      name: PdfFileNames[ReportTypes.AccountStructure],
      key: ReportTypes.AccountStructure,
      component: (org, localTenant, readOnly) => (
        <AccountStructureReport
          organization={org || organization}
          currentTab={currentTab}
          selectedTenant={localTenant}
          getProfileInfo={getProfileInfo}
          readOnly={readOnly}
        />
      ),
      permission: PermissionsConstants.ModulesReportNames.AccountStructure,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.AccountStructure
      ),
      isActive: false,
    },
  ];
  // it should be the very first one that is enabled/available
  const enabledReports = ReportTabsList?.filter((tb) => tb.isEnabled);
  const firstEnabledTab = {};
  const [tab, setTab] = useState({});
  const [isDefaultView, setIsDefaultView] = useState(true);
  enabledReports.sort((a, b) => a.name.localeCompare(b.name));
  const [reportTabs, setReportTabs] = useState(
    enabledReports.map((mp) => ({
      ...mp,
      isActive: mp.key === firstEnabledTab.key,
    }))
  );

  // wait for organization full object gets loaded then update it locally
  useEffect(() => {
    if (profileInfo?.id) {
      setOrganization(profileInfo);
    }
  }, [profileInfo]);

  const ReportTilesCallback = useCallback(() => {
    return (
      <EngagementReportTiles
        tabs={reportTabs}
        setTabs={setReportTabs}
        selectedTab={tab}
        setSelectedTab={setTab}
        setIsDefaultView={setIsDefaultView}
      />
    );
  }, [tab, setTab]);

  const ReportDropdownCallback = useCallback(() => {
    return (
      <ReportTilesDropdown
        tabs={reportTabs}
        setTabs={setReportTabs}
        selectedTab={tab}
        setSelectedTab={setTab}
        setIsDefaultView={setIsDefaultView}
      />
    );
  }, [tab, setTab]);

  return useMemo(
    () => ({
      selectedTab: tab,
      EngagementReportTiles: ReportTilesCallback,
      EngagementReportDropdown: ReportDropdownCallback,
      isDefaultView,
      setIsDefaultView,
      ReportTabsList,
      enabledReports,
      firstEnabledTab,
    }),
    [setTab, ReportTilesCallback]
  );
};

export default useEngagementReportTabs;

import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import AuthService from '../services/auth.service';
import { PublicLayout } from '../layouts/PublicLayout';
import PageTitle from '../components/commons/PageTitle';
import { useTenantContext } from '../contexts/TenantContext';
import useLoginAlert from '../hooks/useLoginAlert';
import useIsCommonLogin from '../hooks/useIsCommonLogin';

export default function Reset() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const { tenant } = useTenantContext();
  const { LoginAlert, setDismissAlert } = useLoginAlert();
  const { isCommonLogin } = useIsCommonLogin();

  // if directly open the url and its a common login redirect user to login page
  useEffect(() => {
    if (isCommonLogin) {
      history.push('/');
    }
  }, []);
  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setIsLoading(true);
      await AuthService.requestPassword(tenant.id, email);
      setSuccess(true);
      setDismissAlert();
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <LoginAlert />
      <PublicLayout
        title={success ? 'Check Your Email' : 'Forgot Your Password?'}
      >
        <PageTitle
          page={success ? 'Check Your Email' : 'Forgot Your Password?'}
          pageModule=""
        />
        <div className="row justify-content-center">
          <div className="col-md-7 col-lg-5">
            <div className="card mb-5">
              <div className="card-body">
                {!success ? (
                  <form className="js-validate" onSubmit={handleSubmit}>
                    <div className="text-left">
                      <p>Having trouble logging in?</p>
                      <div className="mb-3">
                        <p>To reset you password, enter your email address.</p>
                      </div>
                    </div>

                    <div className="js-form-message form-group">
                      <label htmlFor="resetPasswordSrEmail" tabIndex="0">
                        Your Email
                      </label>

                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="resetPasswordSrEmail"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        tabIndex="1"
                        aria-label="Enter your email address"
                        required
                      />
                    </div>

                    <div className="d-flex align-items-center justify-content-between mt-3 gap-2">
                      <a
                        className="btn btn-md btn-white text-black font-size-sm2 font-weight-medium w-50"
                        onClick={() => history.push('/login')}
                      >
                        Cancel
                      </a>
                      <button
                        type="submit"
                        className="btn w-50 font-size-sm2 font-weight-medium btn-primary"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner className="spinner-grow-xs" />
                        ) : (
                          'Continue'
                        )}
                      </button>
                    </div>
                  </form>
                ) : (
                  <>
                    <p>
                      We’ve sent you an email with a link to finish resetting
                      your password.
                    </p>
                    <p>Can’t find the email? Try checking your spam folder.</p>
                    <p>
                      If you still can’t log in, have us{' '}
                      <a
                        onClick={() => {
                          setSuccess(false);
                          setEmail('');
                        }}
                        className="text-primary cursor-pointer font-weight-bold"
                      >
                        resend the email
                      </a>{' '}
                      or contact your {tenant?.name} administrator.
                    </p>
                    <div className="d-flex align-items-center justify-content-between mt-4 gap-2">
                      <button
                        className="btn btn-md btn-primary font-size-sm2 font-weight-medium text-white w-100"
                        onClick={() => history.push('/login')}
                      >
                        <i className="tio-chevron-left"></i> Return to Login
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </PublicLayout>
    </>
  );
}
